import React from 'react';
import { Field } from 'redux-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import styled from 'styled-components';
import { required } from '../../../../utils/validators';
import InlineFormRadioGroup from "../../../Auth/Registration/components/InlineFormRadioGroup";

const StyledFormControlLabel = styled(FormControlLabel)`
  span:last-child {
    margin-left: -5px;
  }
`;

const StyledInlineFormRadioGroup = styled(InlineFormRadioGroup)`
  label:last-child {
    margin-left: 30px;
  }
`;

const AwardVehicleChargeCreditCardPicker = ({ buyerHasCreditCard }) => (
  <>
    <Field
      name="charge_credit_card"
      component={StyledInlineFormRadioGroup}
      row
      validate={required}
    >
      <StyledFormControlLabel
        value="no"
        control={<Radio color="primary" />}
        label="No"
        error
      />
      <StyledFormControlLabel
        value="yes"
        control={<Radio color="primary" />}
        label="Yes"
        disabled={!buyerHasCreditCard}
      />
    </Field>
  </>
);

export default AwardVehicleChargeCreditCardPicker;
