import { NoImagePlaceholder, VehicleOptions } from 'components';
import styled from 'styled-components';
import ImagePreview from './ImagePreview';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const ImagePreviewWrapper = styled.div`
  position: relative;
`;

export const MobileDetails = ({ vehicle }) => {
  return (
    <Wrapper>
      <ImagePreviewWrapper>
        {vehicle.images[0] && vehicle.images[0].url_thumb ? (
          <ImagePreview vehicleId={vehicle.id} url={vehicle.images[0].url_thumb} />
        ) : (
          <NoImagePlaceholder />
        )}
      </ImagePreviewWrapper>
      <VehicleOptions currentVehicle={vehicle} />
    </Wrapper>
  );
};
