import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const SectionGroupTitle = styled(Typography)`
  && {
    color: #212121;
    font-weight: 500;
  }
`;

export default SectionGroupTitle;
