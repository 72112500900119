import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {SectionWrapper, SectionTitle} from './index';
import {getSliderTextValue, getWindShieldTextValue} from '../../../../utils';
import {COLOR_ERROR} from '../../../../constants';

const StyledAdaptiveWrapper = styled(SectionWrapper)`
  ${props =>
  props.mobileOnly
    ? `
    @media (min-width: 1280px) {
      display: none;
    }
  `
    : `
    @media (max-width: 1279px) {
      display: none;
    }
  `}
`;

const StyledRedText = styled.span`
  color: ${COLOR_ERROR};
  font-weight: 600;
`;

const VehicleCondition = ({currentVehicle, mobileOnly = false}) => (
  <StyledAdaptiveWrapper mobileOnly={mobileOnly}>
    <SectionTitle>Vehicle Condition</SectionTitle>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography paragraph variant="body1">
          <b>Windshield:</b> {getWindShieldTextValue(currentVehicle.windshield)}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Tires:</b> {getSliderTextValue(currentVehicle.tiers)}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Paint:</b> {getSliderTextValue(currentVehicle.paint)}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Interior:</b> {getSliderTextValue(currentVehicle.interior)}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Air Conditioning
            Blows:</b> {getSliderTextValue(currentVehicle.air_conditioning_blows, ["Cold", "Cool", "Warm", "", "N/A"])}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography paragraph variant="body1">
          <b>Engine Oil:</b> {getSliderTextValue(currentVehicle.engine_oil, ["Very Clean", "Clean", "Dirty", "Milky"])}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Engine
            Coolant:</b> {getSliderTextValue(currentVehicle.engine_coolant, ["Very Clean", "Clean", "Dirty", "Milky"])}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Check Engine Light:</b>{' '}
          {currentVehicle.check_engine_light ? (
            <StyledRedText>ON</StyledRedText>
          ) : (
            'OFF'
          )}
        </Typography>
        <Typography paragraph variant="body1">
          <b>ABS Light:</b>{' '}
          {currentVehicle.abs_light ? <StyledRedText>ON</StyledRedText> : 'OFF'}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Air Bag:</b>{' '}
          {currentVehicle.airbag_light ? (
            <StyledRedText>ON</StyledRedText>
          ) : (
            'OFF'
          )}
        </Typography>
      </Grid>
    </Grid>
  </StyledAdaptiveWrapper>
);

export default VehicleCondition;
