export const LIST_BANNERS_REQUEST = 'LIST_BANNERS_REQUEST';
export const LIST_BANNERS_SUCCESS = 'LIST_BANNERS_SUCCESS';
export const LIST_BANNERS_FAILURE = 'LIST_BANNERS_FAILURE';

export const listBanners = payload => ({
  request: { type: LIST_BANNERS_REQUEST, payload },
  success: { type: LIST_BANNERS_SUCCESS, payload },
  failure: { type: LIST_BANNERS_FAILURE, payload }
});

export const CREATE_BANNERS_REQUEST = 'CREATE_BANNERS_REQUEST';
export const CREATE_BANNERS_SUCCESS = 'CREATE_BANNERS_SUCCESS';
export const CREATE_BANNERS_FAILURE = 'CREATE_BANNERS_FAILURE';

export const addBanner = payload => ({
  request: { type: CREATE_BANNERS_REQUEST, payload },
  success: { type: CREATE_BANNERS_SUCCESS, payload },
  failure: { type: CREATE_BANNERS_FAILURE, payload }
});

export const LIST_ACTIVE_BANNERS_REQUEST = 'LIST_ACTIVE_BANNERS_REQUEST';
export const LIST_ACTIVE_BANNERS_SUCCESS = 'LIST_ACTIVE_BANNERS_SUCCESS';
export const LIST_ACTIVE_BANNERS_FAILURE = 'LIST_ACTIVE_BANNERS_FAILURE';

export const listActiveBanners = payload => ({
  request: { type: LIST_ACTIVE_BANNERS_REQUEST, payload },
  success: { type: LIST_ACTIVE_BANNERS_SUCCESS, payload },
  failure: { type: LIST_ACTIVE_BANNERS_FAILURE, payload }
});

export const DELETE_BANNERS_REQUEST = 'DELETE_BANNERS_REQUEST';
export const DELETE_BANNERS_SUCCESS = 'DELETE_BANNERS_SUCCESS';
export const DELETE_BANNERS_FAILURE = 'DELETE_BANNERS_FAILURE';

export const removeBanner = payload => ({
  request: { type: DELETE_BANNERS_REQUEST, payload },
  success: { type: DELETE_BANNERS_SUCCESS, payload },
  failure: { type: DELETE_BANNERS_FAILURE, payload }
});

export const CHANGE_BANNERS_REQUEST = 'CHANGE_BANNERS_REQUEST';
export const CHANGE_BANNERS_SUCCESS = 'CHANGE_BANNERS_SUCCESS';
export const CHANGE_BANNERS_FAILURE = 'CHANGE_BANNERS_FAILURE';

export const editBanner = payload => ({
  request: { type: CHANGE_BANNERS_REQUEST, payload },
  success: { type: CHANGE_BANNERS_SUCCESS, payload },
  failure: { type: CHANGE_BANNERS_FAILURE, payload }
});
