import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SectionWrapper, SectionTitle } from './index';
import { COLOR_SUCCESSFUL, COLOR_ERROR } from '../../../../constants';

const StyledGuaranteedText = styled.span`
  color: ${COLOR_SUCCESSFUL};
  margin-left: 4px;
  font-weight: 600;
`;

const StyledNotGuaranteedText = styled.span`
  color: ${COLOR_ERROR};
  margin-left: 4px;
  font-weight: 600;
`;

const VehicleSaleTermsSection = ({ isGuaranteed }) => (
  <SectionWrapper>
    <SectionTitle>Vehicle Sale Terms</SectionTitle>
    {isGuaranteed ? (
      <Typography>
        Vehicle is
        <StyledGuaranteedText>Green Light</StyledGuaranteedText>. Engine,
        transmission and rear end guaranteed for 24hrs (Vehicles being
        transported over 300 miles are guaranteed until 5pm on the Wednesday
        following the auction).
      </Typography>
    ) : (
      <Typography>
        Vehicle is being sold
        <StyledNotGuaranteedText>AS-IS</StyledNotGuaranteedText>: Buyer is
        responsible for viewing vehicle prior to bidding
      </Typography>
    )}
  </SectionWrapper>
);

export default VehicleSaleTermsSection;
