import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type MySalesState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
}

const initialState: MySalesState = {
  page: 1,
  perPage: 24,
  filters: {
  }
};

const salesHistorySlice = createSlice({
  name: 'salesHistory',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      setFilters(state, action: PayloadAction<Record<string, any>>) {
        state.page = 1
        state.filters = action.payload
      },
      resetFilters(state) {
        state.page = 1
        state.perPage = 24
        state.filters = {
        }
      }
    }
});

export const { setPage, setFilters, resetFilters } = salesHistorySlice.actions
export default salesHistorySlice;
