import React from 'react';
import styled from 'styled-components';
import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_STATUSES,
  SELLER_VEHICLE_LABELS,
  VEHICLE_STATUSES
} from '../../../../constants';
import { useSalesHistoryOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';
import { useSelector } from 'react-redux';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const BidStatus = ({ vehicle, status }) => {
  const isAwarded = status === VEHICLE_STATUSES.AWARDED;
  const easyPaid =
    vehicle.payment_method === PAYMENT_METHODS.EASY_PAY && vehicle.payment_status === PAYMENT_METHODS_STATUSES.APPROVED;

  const user = useSelector(state => state?.user?.user);

  const offerAuction = useSalesHistoryOfferAuction(vehicle.id);
  const offerAuctionStatus = auctionOfferStatuses(vehicle, offerAuction, user);

  const { label, color } = SELLER_VEHICLE_LABELS[offerAuctionStatus];

  return (
    <StyledStatus statusColor={color}>
      {label || 'None'}
      {isAwarded && easyPaid && ' (EasyPay)'}
    </StyledStatus>
  );
};

export default BidStatus;
