import styled from 'styled-components';
import { AdditionalInfoIcon } from '../../../icons/AdditionalInfoIcon';
import Tooltip from '../../../../../components/Tooltip';

const Wrapper = styled.div`
  cursor: pointer;
`;

type Props = {
  additionalInfo: string;
};

export const AdditionalSectionInfo = ({ additionalInfo }: Props) => {
  return (
    <Wrapper>
      <Tooltip isTextOverflown titleStyle="uploadVehicle" title={additionalInfo || ''}>
        <div>
          <AdditionalInfoIcon />
        </div>
      </Tooltip>
    </Wrapper>
  );
};
