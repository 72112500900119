import React from 'react';
import { connect } from 'react-redux';
import { AdminAwardBuyerModal } from './index';
import FinalizeSaleModal from '../../TransactionsList/components/FinalizeSaleModal';

const Modals = ({ vehicle }) => {
  if (vehicle) {
    const highestBid = vehicle.highest_bid;
    return (
      <>
        <AdminAwardBuyerModal
          initialValues={{ vehicle_id: vehicle.id }}
          vehicle={vehicle}
          highestBid={highestBid?.amount ? highestBid : null}
        />
        <FinalizeSaleModal vehicle={vehicle} form="finalizeSaleForm" />
      </>
    );
  }
  return <FinalizeSaleModal vehicle={vehicle} form="finalizeSaleForm" />;
};

const mapStateToProps = state => ({
  vehicle: state.auctions.auctionVehiclesModal
});

export default connect(mapStateToProps)(Modals);
