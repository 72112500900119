/* eslint-disable no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import BuyerRestrictedHeader from './BuyerRestrictedHeader';
import AdminRestrictedHeader from './AdminRestrictedHeader';
import SellerRestrictedHeader from './SellerrestrictedHeader';

const RoleRestrictedSection = ({ role }) => {
  if (role === 'buyer') {
    return <BuyerRestrictedHeader />;
  } else if (role === 'admin') {
    return <AdminRestrictedHeader />;
  } else if (role === 'seller') {
    return <SellerRestrictedHeader />;
  }
  return null;
};

export default connect(state => ({
  role: (state.user.user || {}).role
}))(RoleRestrictedSection);
