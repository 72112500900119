import { useEffect } from 'react';
import { DISABLE_REASONS, USER_STATUSES, roles } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle } from 'actions';
import { UpdateCreditCardModal } from 'components';

export const BuyerDisabledSection = () => {
  const user = useSelector(state => state.user?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const isBuyer = user && user.role === roles.BUYER;
    const isDisabledByCard =
      user && user.status === USER_STATUSES.DISABLED && user.disable_reason === DISABLE_REASONS.CARD;

    if (isBuyer && isDisabledByCard) {
      dispatch(modalsToggle('updateCreditCardModal'));
    }
  }, [dispatch, user]);

  if (user.role !== 'buyer') return null;
  return <UpdateCreditCardModal user={user} />;
};
