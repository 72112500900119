import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';

const StyledAppBar = styled(AppBar)`
  && {
    background: white;
  }
`;

const PageHeader = ({ children, ...props }) => (
  <StyledAppBar
    position="static"
    color="default"
    className="breadcrumbs-holder"
    {...props}
  >
    <div className="pageWrapper">
      <Toolbar disableGutters>{children}</Toolbar>
    </div>
  </StyledAppBar>
);

export default PageHeader;
