import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { FormLabel, Divider, MenuItem, Button } from '@material-ui/core';
import { required, maxLength, composeValidators } from '../../../../utils/validators';
import FieldWrapper from './FieldWrapper';
import { FormTextField } from '../../../../components/Form';
import { FLOORING_FORM_COMPANIES, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import OutlinedFormSelectField from 'components/Form/FinalForm/OutlinedFormSelectField';
import OutlinedFormTextField from 'components/Form/FinalForm/OutlinedFormTextField';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const StyledFlooringFormLabel = styled(FormLabel)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: #000;
    line-height: 16.41px;
  }
`;

const fieldComponent = ({ fields }) =>
  fields.map((field, index) => (
    <div key={index}>
      <Divider />
      <FieldWrapper>
        <StyledFlooringFormLabel component="legend">Flooring Form #{index + 2}</StyledFlooringFormLabel>
        <OutlinedFormSelectField
          name={`${field}.flooring_company`}
          label="Select Flooring Company"
          validate={required}
          options={FLOORING_FORM_COMPANIES.map(c => ({ value: c.value, label: c.name }))}
        />
        <OutlinedFormTextField
          name={`${field}.name_act`}
          label="Name on Acct"
          placeholder="Name on Acct"
          validate={composeValidators(required, maxStringLength)}
        />
        <OutlinedFormTextField
          name={`${field}.act`}
          label="Acct #"
          placeholder="Acct"
          validate={composeValidators(required, maxStringLength)}
        />
        <OutlinedFormTextField
          name={`${field}.auction_access`}
          label="Auction Access #"
          placeholder="Auction Access #"
          validate={composeValidators(required, maxStringLength)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Button color="primary" variant="outlined" fullWidth onClick={() => fields.remove(index)}>
          Remove
        </Button>
      </FieldWrapper>
    </div>
  ));

const AdditionalFlooringForms = ({ addItem, auction_access, name_act, pushMutator }) => (
  <>
    <FieldArray name="flooring_form.additional_forms" component={fieldComponent} />
    <Button
      color="primary"
      variant="outlined"
      onClick={() => pushMutator('flooring_form.additional_forms', { auction_access, name_act })}
    >
      Add Another Flooring Account
    </Button>
  </>
);

export default AdditionalFlooringForms;
