export const VEHICLES_PER_PAGE = 24;
export const VEHICLE_SALES_PER_PAGE = 25;
export const LOCATIONS_PER_PAGE = 25;
export const AUCTIONS_PER_PAGE = 25;
export const BIDS_PER_PAGE = 25;
export const BANNERS_PER_PAGE = 25;
export const FINANCIALS_INVOICES_PER_PAGE = 25;
export const CUSTOMERS_PER_PAGE = 25;
export const ACCOUNTS_PER_PAGE = 25;
export const ACCOUNT_LOGS_PER_PAGE = 25;
export const STATISTICS_PER_PAGE = 25;
export const TRANSACTIONS_PER_PAGE = 25;
export const LOGS_PER_PAGE = 25;

export const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const roles = {
  ADMIN: 'admin',
  BUYER: 'buyer',
  SELLER: 'seller'
};

export const HOURS = Array(24)
  .fill()
  .map((e, i) => i.toString().padStart(2, 0));

export const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
export const SECONDS = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_FULL = 'MM/DD/YYYY hh:mm A';
export const AUCTION_END_FORMATTING = 'MMMM DD, YYYY';

export const vehicleImageThumb = {
  width: 400,
  height: 300
};

export const vehicleImageOriginal = {
  width: 1600,
  height: 1200
};

export const REGISTRATION_MAX_STRING_LENGTH = 50;
export const ADDRESS_MAX_STRING_LENGTH = 100;

export const AVAILABLE_VEHICLE_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const USER_STATUSES = {
  NEED_TO_COMPLETE_SIGN_UP: 'need_to_complete_sign_up',
  ACTIVE: 'active',
  DISABLED: 'disabled',
  DOCS_TO_REVIEW: 'docs_to_review',
  DEACTIVATED: 'deactivated'
};

export const AUCTION_STATUSES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ENDED: 'ended'
};

export const REFERRERS = [
  {
    name: 'Tommy',
    value: 'tommy'
  },
  {
    name: 'Ed',
    value: 'ed'
  },
  {
    name: 'Mike',
    value: 'mike'
  },
  {
    name: 'Mark',
    value: 'mark'
  },
  {
    name: 'Jennifer',
    value: 'jennifer'
  },
  {
    name: 'Jessica',
    value: 'jessica'
  },
  {
    name: 'Teresa',
    value: 'teresa'
  },
  {
    name: 'Lea',
    value: 'lea'
  },
  {
    name: 'Chris',
    value: 'chris'
  }
];

export const SELLER_PURCHASE_OPTIONS = {
  cash: 'Cash',
  company_check: 'Company Check',
  cashiers_check: 'Cashiers Check',
  ach_flooring: 'ACH/Flooring'
};

export const DOCUMENT_NAMES = {
  dealer_bond: 'Dealer Bond/Other',
  resale_certificate: "Seller's Permit",
  city_business_license: 'City Business License',
  dmv_license: 'DMV Vehicle Dealer License',
  ach_form: 'ACH Form',
  ach_voided_check_image: 'ACH Voided Check',
  flooring_form: 'Flooring Form'
};

export const SELLER_DOCUMENT_NAMES = {
  dealer_bond: 'Dealer Bond/Other',
  resale_certificate: "Seller's Permit",
  city_business_license: 'City Business License',
  dmv_license: 'DMV Vehicle Dealer License',
  ach_form: 'ACH Form',
  flooring_form: 'Flooring Form'
};

export const BUYER_PURCHASE_METHODS = {
  CASH: 'cash',
  COMPANY_CHECK: 'company_check',
  CASHIERS_CHECK: 'cashiers_check',
  ACH: 'ach',
  FLOORING: 'flooring'
};

export const EASYPAY_REQUEST_BUY_FEE_METHODS = {
  INCLUDE_IN_ACH_FLOORING: 'include_in_ach_flooring',
  PAY_WITH_CREDIT_CARD: 'pay_with_credit_card'
};

export const FINALIZE_BUY_FEE_METHODS = {
  ACH: 'ach',
  FLOORING: 'flooring',
  CREDIT_CARD: 'credit_card'
};

export const SIGNUP_ACH_METHODS = {
  ENTER_ACH_INFORMATION: 'enter_ach_information',
  UPLOAD_VOIDED_CHECK: 'upload_voided_check'
};

export const DOCUMENT_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  UNCREATED: 'uncreated'
};

export const DOCUMENT_STATUSES_MAPPING = {
  pending: 'PENDING',
  approved: 'APPROVED',
  declined: 'DECLINED',
  uncreated: 'UNCREATED'
};

export const TRANSACTION_CODES = {
  1: 'Approved',
  2: 'Declined',
  3: 'Error',
  4: 'Held for Review'
};

export const CONTACT_FORM_TOPICS = {
  password: 'I need help with login',
  registration: 'I need help with registration',
  titleInquiry: 'I have a title inquiry',
  billingInquiry: 'I have a billing inquiry',
  vehicleQuestion: 'I have a question about a vehicle',
  other: 'Other'
};

export const DISABLE_REASONS = {
  CARD: 'Credit card failed',
  VEHICLE_NOT_PICKED_UP: 'Vehicle not picked up',
  NSF: 'Check NSF',
  INACTIVE: 'Inactive account',
  OTHER: 'Other'
};

export const ONBOARDING_STEPS = {
  SECOND_SELLER_STEP: 'second_seller_step',
  THIRD_SELLER_STEP: 'third_seller_step',
  SECOND_BUYER_STEP: 'second_buyer_step',
  THIRD_BUYER_STEP: 'third_buyer_step',
  FOURTH_BUYER_STEP: 'fourth_buyer_step',
  FIFTH_BUYER_STEP: 'fifth_buyer_step'
};

export const STATISTICS_PAGES = {
  vehicle_detail: '/vehicles'
};

export const INVOICE_TYPES = {
  BY_SOLD_VEHICLES: 'by_sold_vehicles',
  BY_UPLOADED_VEHICLES: 'by_uploaded_vehicles'
};

export const ADDITIONAL_NAVIGATION_SECONDS = 40;

export const TRANSACTION_TYPES = {
  ALL: 'all',
  AUCTION: 'auction',
  ADDITIONAL_FEE: 'additional_fee',
  EASY_PAY: 'easy_pay'
};

export const TRANSACTION_STATUS_REPRESANTATION = {
  successful: 'Charged',
  declined: 'Declined',
  voided: 'Voided',
  uncharged: 'UnCharged'
};

export const ADMIN_VEHICLE_BID_STATUSES = {
  IN_PROGRESS: 'in_progress',
  SELLER_COUNTERED: 'seller_countered',
  SELLER_COUNTER_SENT: 'seller_counter_sent',
  ADMIN_COUNTER_SENT: 'admin_counter_sent',
  SELLER_ACCEPTED: 'seller_accepted',
  UNDECIDED: 'undecided'
};

export const SELLER_SALES_BID_STATUSES = {
  AWARDED: 'awarded',
  ACTIVE: 'active',
  PULLED: 'pulled',
  PENDING_AUTOAXESS_REVIEW: 'pending_autoaxess_review',
  COUNTER_SENT_TO_BUYERS: 'counter_sent_to_buyers',
  PENDING: 'pending',
  NEED_SELLER_REVIEW: 'need_seller_review'
};

export const SELLER_SALES_HISTORY_BID_STATUSES = {
  AWARDED: 'awarded',
  PULLED: 'pulled',
  DRAFT: 'draft',
  UNDECIDED: 'undecided',
  COUNTERED: 'countered',
  PROCESSING: 'processing'
};

export const BUYER_VEHICLE_STATUSES = {
  DECLINED: 'declined',
  AWARDED: 'awarded',
  COUNTERED: 'countered',
  UNDECIDED: 'undecided',
  HIGH_BID: 'high_bid',
  OUT_BID: 'out_bid',
  SELLER_ACCEPTING_OFFERS: 'seller_accepting_offers'
};

export const COLOR_SUCCESSFUL = '#009F0F';
export const COLOR_PENDING = '#E47E00';
export const COLOR_ERROR = '#D50808';

export const PICKUP_INSTRUCTIONS_MAX_LENGTH = 300;

export const UPLOAD_TYPES = {
  AUTOMATED: 'automated',
  MANUAL: 'manual'
};

export const BID_TYPES = {
  NORMAL: 'normal',
  PROXY: 'proxy'
};

export const VEHICLE_TABS_TYPES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  ENDED: 'ended'
};

export const FLOORING_FORM_COMPANIES_NAMES = {
  next_gear: 'NextGear',
  afs: 'AFS',
  westlake_flooring: 'Westlake Flooring',
  afi: 'AFI',
  afc: 'AFC',
  kinetic_advantage: 'Kinetic Advantage',
  axle_funding: 'AXLE Funding'
};

export const FLOORING_FORM_COMPANIES = [
  { name: FLOORING_FORM_COMPANIES_NAMES.next_gear, value: 'next_gear' },
  { name: FLOORING_FORM_COMPANIES_NAMES.afs, value: 'afs' },
  { name: FLOORING_FORM_COMPANIES_NAMES.westlake_flooring, value: 'westlake_flooring' },
  { name: FLOORING_FORM_COMPANIES_NAMES.afi, value: 'afi' },
  { name: FLOORING_FORM_COMPANIES_NAMES.afc, value: 'afc' },
  { name: FLOORING_FORM_COMPANIES_NAMES.kinetic_advantage, value: 'kinetic_advantage' },
  { name: FLOORING_FORM_COMPANIES_NAMES.axle_funding, value: 'axle_funding' }
];

export const SNACK_MESSAGE_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info'
};

export const PAYMENT_METHODS = {
  EASY_PAY: 'easy_pay'
};

export const PAYMENT_METHODS_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined'
};

export const EASYPAY_PAYMENT_TYPES = {
  ACH: 'ach',
  FLOORING: 'flooring'
};

export const EASYPAY_PAYMENT_TYPES_FORMAT = {
  [EASYPAY_PAYMENT_TYPES.ACH]: 'ACH',
  [EASYPAY_PAYMENT_TYPES.FLOORING]: 'Flooring'
};

export const EASYPAY_PAYMENT_STATUSES_FORMAT = {
  [PAYMENT_METHODS_STATUSES.APPROVED]: 'Approved',
  [PAYMENT_METHODS_STATUSES.PENDING]: 'Pending Approval',
  [PAYMENT_METHODS_STATUSES.DECLINED]: 'Declined'
};

export const ADMIN_JOB_TITLES = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  SALESMAN: 'Salesman',
  LISTER: 'Lister',
  ADMIN_SELLER: 'Admin Seller'
};

export const BID_INCREMENT = 100;

export const MOBILE_BREAKPOINT = 576;
export const SIDE_PANEL_BREAKPOINT = 1280;

export * from './vehicleStatuses';
