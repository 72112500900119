import styled from 'styled-components';
import { getLabelFromDayNumber } from '../../utils/formatDate';
import { BlockIcon } from '../../../../../components/Icons/BlockIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  gap: 8px;
`;

const NoDataText = styled.div`
  display: flex;
  color: #a2a2a2;
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const NoDataBlock = ({ dayNumber }: { dayNumber: number }) => {
  return (
    <Wrapper>
      <span>{getLabelFromDayNumber(dayNumber)}</span>
      <NoDataText>
        <BlockIcon />
        <span>No Vehicles for today</span>
      </NoDataText>
    </Wrapper>
  );
};
