import styled from 'styled-components';
import {Typography} from '@material-ui/core';
import React from 'react'


const StyledTitle = styled(Typography)`
  && {
    color: #fff;
    padding: 5px;
    font-weight: bold;

    @media (max-width: 600px) {
      margin: 20px 0;
      margin-left: 70px;
    }
  }
`;


const OfficePhoneNumberBanner = () => (
  <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        textAlign: 'center',
        marginTop: '20px',
        position: 'relative',
        bottom: '-24px'
    }}
  >
    <StyledTitle>
      If you have questions/issues with the registration process, please call us at 925-315-4033
    </StyledTitle>
  </div>

)


export default OfficePhoneNumberBanner
