import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type UploadVehicleState = {
  activeSubsection: string | null;
  openedNotesDialog: string | null;
  openedCameraDialog: string | null;
  openedMobileImagesDialog: string | null;
  skipNextAutosave: boolean
}

const initialState: UploadVehicleState = {
  activeSubsection: null,
  openedNotesDialog: null,
  openedCameraDialog: null,
  openedMobileImagesDialog: null,
  skipNextAutosave: false
};

const uploadVehicleSlice = createSlice({
  name: 'uploadVehicle',
  initialState,
  reducers: {
    setSkipNextAutosave: (state, action: PayloadAction<boolean>) => {
      state.skipNextAutosave = action.payload
    },
    openNotesDialog: (state, action: PayloadAction<string>) => {
      state.openedNotesDialog = action.payload
    },
    closeNotesDialog: (state) => {
      state.openedNotesDialog = null
    },
    openCameraDialog: (state, action: PayloadAction<string>) => {
      state.openedCameraDialog = action.payload
    },
    closeCameraDialog: (state) => {
      state.openedCameraDialog = null
    },
     openMobileImagesDialog: (state, action: PayloadAction<string>) => {
      state.openedMobileImagesDialog = action.payload
    },
    closeMobileImagesDialog: (state) => {
      state.openedMobileImagesDialog = null
    }
  }
});

export const useOpenedNotesDialog = () => {
  const openedNotesDialog = useSelector<{ uploadVehicle: UploadVehicleState }, string | null>((state) => state.uploadVehicle.openedNotesDialog)
  return openedNotesDialog
}

export const useOpenedCameraDialog = () => {
  const openedCameraDialog = useSelector<{ uploadVehicle: UploadVehicleState }, string | null>((state) => state.uploadVehicle.openedCameraDialog)
  return openedCameraDialog
}

export const useOpenedMobileImagesDialog = () => {
  const openedMobileImagesDialog = useSelector<{ uploadVehicle: UploadVehicleState }, string | null>((state) => state.uploadVehicle.openedMobileImagesDialog)
  return openedMobileImagesDialog
}


export const { openMobileImagesDialog, closeMobileImagesDialog, openCameraDialog, closeCameraDialog, setSkipNextAutosave, openNotesDialog, closeNotesDialog } = uploadVehicleSlice.actions
export default uploadVehicleSlice;
