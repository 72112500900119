import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Typography, Grid, InputAdornment } from '@material-ui/core';
import { FormTextField } from '../../../../components/Form';
import { ModalWrapper } from '../../../../components';
import {
  BUYER_PURCHASE_METHODS,
  COLOR_ERROR,
  COLOR_PENDING,
  COLOR_SUCCESSFUL, EASYPAY_PAYMENT_TYPES, EASYPAY_PAYMENT_TYPES_FORMAT, EASYPAY_REQUEST_BUY_FEE_METHODS,
  FLOORING_FORM_COMPANIES_NAMES,
  PAYMENT_METHODS, PAYMENT_METHODS_STATUSES,
} from '../../../../constants';
import { modalsToggle, listBuyerFees, updateEasyPayTransaction } from '../../../../actions';
import FinalizeAchFlooringApprovalPicker from "./FinalizeAchFlooringApprovalPicker";
import FinalizeFeePaymentMethodPicker from "./FinalizeFeePaymentMethodPicker";

export function finalizeButtonConditionalDisable(vehicle) {
  return vehicle.isFinalized;
}

export function finalizeButtonConditionalRendering(vehicle) {
  return vehicle.toBeFinalized || vehicle.isFinalized;
}

export function finalizeDeclineButtonConditionalRendering(vehicle) {
  const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;
  return vehicle.isFinalized && easyPaid;
}

const modalName = 'finalizeSaleModal';

const StyledGrid = styled(Grid)`
  min-width: 500px;
  margin-top: 24px;
`;

class FinalizeSaleModal extends Component {
  componentDidMount() {
    const { getFees } = this.props;
    getFees();
  }

  handleSubmit = values => {
    const {
      vehicle,
      toggle,
      updateEasyPay,
      reset
    } = this.props;

    const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;

    const data = {
      id: vehicle.id,
      finalize_buy_fee_method: values.finalize_buy_fee_method,
      processing_fee: +values.processing_fee || 0,
      transportation_fee: +values.transportation_fee || 0,
    };

    if (easyPaid) {
      data.payment_status = values.payment_status;
    }

    updateEasyPay(data);
    toggle(modalName);
    reset();
  };

  initializePrice = () => {
    const { vehicle, fees, changeFormField, form, finalize_buy_fee_method } = this.props;
    let buyFee = 0;
    const awardPrice = vehicle.vehicle_price;
    if (awardPrice && fees) {
      const matchingFee = fees.find(fee => +awardPrice >= +fee.min_amount && +awardPrice <= +fee.max_amount);
      if (matchingFee && matchingFee.fee) {
        buyFee = matchingFee.fee;
      }
    }
    const initProcessingFee = (vehicle.buy_fee && (vehicle.buy_fee.processing_fee || vehicle.buy_fee.processing_fee === 0))
      ? vehicle.buy_fee.processing_fee : buyFee;
    const initTransportationFee = (vehicle.buy_fee && vehicle.buy_fee.transportation_fee) ? vehicle.buy_fee.transportation_fee : 0;
    changeFormField(form, 'processing_fee', initProcessingFee);
    changeFormField(form, 'transportation_fee', initTransportationFee);
    changeFormField(form, 'payment_status', PAYMENT_METHODS_STATUSES.APPROVED);
    if (finalize_buy_fee_method) changeFormField(form, 'finalize_buy_fee_method', '');
  }

  render() {
    const {
      vehicle,
      reset,
      transportation_fee: transportationFee,
      processing_fee: processingFee,
      handleSubmit
    } = this.props;

    if (!vehicle || !vehicle.awarded_user || !vehicle.awarded_user.dealership_name) return null;

    const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;

    const paymentType = EASYPAY_PAYMENT_TYPES_FORMAT[vehicle.payment_type] || 'N/A';
    const paymentCompany = vehicle.payment_company ? `(${FLOORING_FORM_COMPANIES_NAMES[vehicle.payment_company]})` : '';

    const easyPayApproved = vehicle.payment_status === PAYMENT_METHODS_STATUSES.APPROVED;
    const easyPayDeclined = vehicle.payment_status === PAYMENT_METHODS_STATUSES.DECLINED;
    const easyPayPendingApproval = !easyPayApproved && !easyPayDeclined;

    const requestedToIncludeBuyFee = vehicle.buy_fee
      && vehicle.buy_fee.request_buy_fee_method === EASYPAY_REQUEST_BUY_FEE_METHODS.INCLUDE_IN_ACH_FLOORING

    const requestedToPayBuyFeeByCreditCard = vehicle.buy_fee
      && vehicle.buy_fee.request_buy_fee_method === EASYPAY_REQUEST_BUY_FEE_METHODS.PAY_WITH_CREDIT_CARD

    let buyerPurchaseMethods;
    if (vehicle.awarded_user.buyer) {
      buyerPurchaseMethods = vehicle.awarded_user.buyer.purchase_methods || [];
    }
    const buyerHasAch = !!(buyerPurchaseMethods && buyerPurchaseMethods.find(method =>
      method.option_name === BUYER_PURCHASE_METHODS.ACH &&
      method.status === PAYMENT_METHODS_STATUSES.APPROVED
    ));
    const buyerHasFlooring = !!(buyerPurchaseMethods && buyerPurchaseMethods.find(method =>
      method.option_name === BUYER_PURCHASE_METHODS.FLOORING &&
      method.status === PAYMENT_METHODS_STATUSES.APPROVED
    ));
    const buyerHasCreditCard = !!(vehicle.awarded_user && vehicle.awarded_user.buyer
      && vehicle.awarded_user.buyer.customer_profile_id);
    const usdAmountInputProps = {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
      autoComplete: "off",
    };

    return (
      <ModalWrapper
        modalId={modalName}
        title="Finalize Sale"
        submitText="Finalize"
        handleSubmit={handleSubmit(this.handleSubmit)}
        cancelAdditionalAction={reset}
        onEnter={this.initializePrice}
      >
        <>
          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.ACH && easyPayPendingApproval &&
            <Typography style={{ color: COLOR_PENDING, display: 'inline' }}>
              {'ACH Pending Approval'}
            </Typography>
          }
          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.ACH && easyPayApproved &&
            <Typography style={{ color: COLOR_SUCCESSFUL, display: 'inline' }}>
              {'ACH Approved'}
            </Typography>
          }
          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.ACH && easyPayDeclined &&
            <Typography style={{ color: COLOR_ERROR, display: 'inline' }}>
              {'ACH Declined'}
            </Typography>
          }

          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.FLOORING && easyPayPendingApproval &&
            <Typography style={{ color: COLOR_PENDING, display: 'inline' }}>
              {'Flooring Pending Approval'}
            </Typography>
          }
          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.FLOORING && easyPayApproved &&
            <Typography style={{ color: COLOR_SUCCESSFUL, display: 'inline' }}>
              {'Flooring Approved'}
            </Typography>
          }
          {easyPaid && vehicle.payment_type === EASYPAY_PAYMENT_TYPES.FLOORING && easyPayDeclined &&
            <Typography style={{ color: COLOR_ERROR, display: 'inline' }}>
              {'Flooring Declined'}
            </Typography>
          }

          {!easyPaid &&
            <Typography style={{ color: COLOR_PENDING, display: 'inline' }}>
              {'Not EasyPaid'}
            </Typography>
          }
          {' - '}
          {vehicle.charge_completed &&
            <Typography style={{ color: COLOR_SUCCESSFUL, display: 'inline' }}>
              {'Fee charged'}
            </Typography>
          }
          {!vehicle.charge_completed &&
            <Typography style={{ color: COLOR_PENDING, display: 'inline' }}>
              {'Fee not charged'}
            </Typography>
          }
        </>

        <br />

        <Typography variant="body2">
          Car:
          {' '}
          {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
        </Typography>
        <Typography variant="body2">
          VIN:
          {vehicle.vin}
        </Typography>
        <Typography variant="body2">
          Seller:
          {' '}
          {vehicle.user.dealership_name}
        </Typography>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Buyer:
          </Grid>
          {vehicle.awarded_user.dealership_name}
        </StyledGrid>

        {easyPaid &&
          <StyledGrid container alignItems="center">
            <Grid item sm={5} xs={12}>
              ACH/Flooring Approval:
            </Grid>
            <FinalizeAchFlooringApprovalPicker />
            <a target="_blank" href={`/admin/customers/${vehicle.awarded_user.id}/docs`} rel="noopener noreferrer">
              View Docs
            </a>
          </StyledGrid>
        }

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Fee Payment Method:
          </Grid>
          {!easyPaid &&
            <FinalizeFeePaymentMethodPicker
              hasAch={buyerHasAch}
              hasFlooring={buyerHasFlooring}
              hasCreditCard={buyerHasCreditCard}
            />
          }
          {easyPaid && requestedToIncludeBuyFee &&
            <Typography>
              {paymentType}
              {' '}
              {paymentCompany}
            </Typography>
          }
          {easyPaid && requestedToPayBuyFeeByCreditCard &&
            <Typography>
              Pay with credit card
            </Typography>
          }
        </StyledGrid>

        <StyledGrid container>
          <Grid item sm={5} xs={12}>
            Award Price:
          </Grid>
          <Grid item sm={7} xs={12}>
            <Typography>
              $
              {vehicle.vehicle_price}
            </Typography>
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Processing Fee:
          </Grid>
          <Grid item sm={7} xs={12}>
            <Field
              name="processing_fee"
              component={FormTextField}
              label="Processing Fee"
              fullWidth
              type="number"
              InputProps={usdAmountInputProps}
            />
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            Transportation Fee:
          </Grid>
          <Grid item sm={7} xs={12}>
            <Field
              name="transportation_fee"
              component={FormTextField}
              label="Transportation Fee"
              fullWidth
              type="number"
              InputProps={usdAmountInputProps}
            />
          </Grid>
        </StyledGrid>

        <StyledGrid container>
          <Grid item sm={5} xs={12} />
          <Grid item sm={7} xs={12}>
            <hr style={{display: 'none'}} />
          </Grid>
        </StyledGrid>

        <StyledGrid container alignItems="center">
          <Grid item sm={5} xs={12}>
            TOTAL FEE:
          </Grid>
          <Grid item sm={7} xs={12}>
            <Typography color="primary">
              $
              {+processingFee + +transportationFee}
            </Typography>
          </Grid>
        </StyledGrid>

      </ModalWrapper>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.form);
  return {
    fees: state.financials.fees,
    award_price: selector(state, 'award_price'),
    processing_fee: selector(state, 'processing_fee'),
    transportation_fee: selector(state, 'transportation_fee'),
    finalize_buy_fee_method: selector(state, 'finalize_buy_fee_method'),
  };
}

const mapDispatchToProps = dispatch => ({
  getFees: async () => dispatch(listBuyerFees().request),
  toggle: key => dispatch(modalsToggle(key)),
  changeFormField: (form, field, value) => dispatch(change(form, field, value)),
  updateEasyPay: payload => dispatch(updateEasyPayTransaction(payload).request),
});

export default compose(
  reduxForm({
    initialValues: {
      payment_status: PAYMENT_METHODS_STATUSES.APPROVED,
      finalize_buy_fee_method: '',
      processing_fee: '0',
      transportation_fee: '0',
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FinalizeSaleModal);
