import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { SectionWrapper } from './index';
import RoleRestrictedSection from '../RoleRestrictedSection';
import BuyerBiddingSection from '../BuyerBiddingSection';
import BlueBookSection from './BlueBookSection';
import { roles } from '../../../../constants';

const StyledVehicleTitle = styled(Typography)`
  && {
    max-width: 555px;
    font-weight: 500;
    font-size: 32px;
    color: #212121;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1280px) {
      max-width: 100%;
      white-space: normal;
    }
  }
`;

const StyledVehicleVin = styled(Typography)`
  && {
    font-size: 18px;
    color: #212121;
    margin-top: 0;
    margin-bottom: 24px;
    span {
      font-weight: 500;
    }
  }
`;

const VehicleDetails = ({ vehicle, currentUser }) => (
  <SectionWrapper>
    <StyledVehicleTitle>
      {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
    </StyledVehicleTitle>
    <StyledVehicleVin>
      <span>VIN:</span>
      {` ${vehicle.vin}`}
    </StyledVehicleVin>
    <RoleRestrictedSection vehicle={vehicle} />
    <BlueBookSection
      bluebook_value={vehicle.bluebook_value}
      bluebook_mileage={vehicle.bluebook_mileage}
      mileage={vehicle.mileage}
      vin={vehicle.vin}
      currentUser={currentUser}
    />
    {/* TODO: CHECK PROPS */}
    {currentUser.role === roles.BUYER && (
      <BuyerBiddingSection vehicle={vehicle} />
    )}
  </SectionWrapper>
);

export default VehicleDetails;
