import styled from 'styled-components';
import { ListVehiclePoolRowFromServer } from './types';
import { ScheduleIcon } from '../../../Icons/ScheduleIcon';
import { useToggleSchedulePoolMutation } from '../../../../services/api/list-vehicles';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../Modals/ConfirmModal';
import { modalsToggle } from '../../../../actions';
import { getDayNumberFromDate } from '../../../../pages/Admin/ListerDashboard/utils/formatDate';
import moment from 'moment';

const ScheduledIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  cursor: pointer;
`;

const dayOfTheWeekMap: Record<number, string> = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday'
};

export const SchedulePool = ({ item }: { item: ListVehiclePoolRowFromServer }) => {
  const [togglePoolSchedule] = useToggleSchedulePoolMutation();

  const dispatch = useDispatch();

  const showScheduleButton = !!item.preferable_date && !!item.lister_id && item.listRequests.length > 0;

  if (!showScheduleButton) return null;

  const handleIconClick = () => {
    dispatch(modalsToggle(`schedule-pool-${item.id}`));
  };

  const confirmAction = () => {
    togglePoolSchedule(item.id);
    dispatch(modalsToggle(`schedule-pool-${item.id}`));
  };

  const stores = item.listRequests.map(e => e.user.dealership_name).join(', ');

  const confirmSchedulerText = `The following stores will be scheduled for each Tuesday unless cancelled:${stores}`;
  const confirmUnscheduleText = `Are you user you want to stop the schedule for the following stores ${stores}`;

  const dayOfTheWeek = getDayNumberFromDate(moment(item.preferable_date));

  const modalTitle = item.is_scheduled
    ? `Stopping ${item.lister?.first_name} schedule for ${dayOfTheWeekMap[dayOfTheWeek]}`
    : `Automatic schedule to ${item.lister?.first_name} for ${dayOfTheWeekMap[dayOfTheWeek]}`;

  return (
    <>
      <ScheduledIconWrapper onClick={handleIconClick}>
        <ScheduleIcon isActive={item.is_scheduled} />
      </ScheduledIconWrapper>
      <ConfirmModal
        title={modalTitle}
        text={item.is_scheduled ? confirmUnscheduleText : confirmSchedulerText}
        handleSubmit={confirmAction}
        submitText={'Confirm'}
        modalId={`schedule-pool-${item.id}`}
      />
    </>
  );
};
