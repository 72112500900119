import React from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography, List, ListItem } from '@material-ui/core';

import { PageWrapper } from '../../../components';
import { paragraphs, buyerTerms, sellerTerms } from './terms';

const StyledSection = styled(Paper)`
  && {
    max-width: 856px;
    border-radius: 0;
    margin: 24px auto 0;
    padding: 32px 110px 48px 110px;
    @media (max-width: 960px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 32px;
    margin: 40px 0;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
`;

const TypographyWithMarginBot = styled(Typography)`
  && {
    margin-bottom: 1em;
  }
`;

const TermsPage = ({ modalReady = false }) => (
  <PageWrapper>
    <StyledSection style={modalReady ? {padding: 0, margin: 0, boxShadow: 'none'} : {}}>
      {!modalReady && <StyledTitle variant="title">Terms &amp; Conditions</StyledTitle>}
      <Grid container spacing={32}>
        <Grid item xs={12}>
          {paragraphs.map((p, i) => (
            <Typography key={i} gutterBottom>
              {p}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TypographyWithMarginBot variant="display1">
            Buyer
          </TypographyWithMarginBot>
          {buyerTerms.map((term, i) => (
            <React.Fragment key={i}>
              {term.title && (
                <Typography variant="body2">{`${term.title}:`}</Typography>
              )}
              <List disablePadding>
                {term.items.map((item, j) => (
                  <ListItem disableGutters dense key={`${i}${j}`}>
                    <Typography>{`- ${item}`}</Typography>
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          <TypographyWithMarginBot variant="display1">
            Seller
          </TypographyWithMarginBot>
          {sellerTerms.map((term, i) => (
            <React.Fragment key={i}>
              {term.title && (
                <Typography variant="body2" gutterBottom>{`${
                  term.title
                }:`}</Typography>
              )}
              <List disablePadding>
                {term.items.map((item, j) => (
                  <ListItem disableGutters dense key={`${i}${j}`}>
                    <Typography>{`- ${item}`}</Typography>
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>
            This Web-site shall not be responsible for any items sold by
            Auction, for any damage to items during transit or during the
            inspection period, nor for misrepresentations and/or breaches of
            contract by either buyer and/or seller. This web-site shall not be
            responsible for the cost of procurement of substitute goods or any
            losses resulting from any goods purchased or obtained.
          </Typography>
          <Typography gutterBottom>
            This web-site assumes no liability for the content of the listings.
            However if you fall victim of a fraudulent transaction we will
            assist you as much as we can.
          </Typography>
          <Typography gutterBottom>
            These Terms of Service shall be governed by and constructed in
            accordance with the laws of the state of California and any disputes
            will be decided only by the Courts of Alameda County, California.
          </Typography>
          <Typography gutterBottom>
            This Agreement is effective starting on January 1st, 2010, for
            current users, and upon acceptance for new users. We may amend this
            Agreement at any time by posting the amended terms on the Sites.
            Except as stated elsewhere, all amended terms shall automatically be
            effective 30 days after they are initially posted on the Sites.
            Additionally, we will notify you through email. This Agreement may
            not be otherwise amended except in a writing signed by you and us.
            This Agreement sets forth the entire understanding and agreement
            between us with respect to the subject matter hereof.
          </Typography>
        </Grid>
      </Grid>
    </StyledSection>
  </PageWrapper>
);

export default TermsPage;
