import React from 'react';
import moment from 'moment';
import { reset, touch, reduxForm, SubmissionError } from 'redux-form';
import { StyledGrid, FormCreditCardPicker } from '../components';
import { updateProfile } from '../../../../actions/user';

const AddressSectionForm = ({ handleSubmit, isDisabled }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <FormCreditCardPicker isDisabled={isDisabled} />
    </StyledGrid>
  </form>
);

export default reduxForm({
  form: 'creditCardSectionForm',
  onSubmit: (values, dispatch) => {
    dispatch(
      touch(
        'creditCardSectionForm',
        'credit_card_number',
        'credit_card_expiry_date',
        'credit_card_cvc'
      )
    );
    const isValidDate = /^\d{2}\/\d{2}$/.test(
      values.credit_card_expiry_date || ''
    );
    const creditCardNumber = (values.credit_card_number || '').replace(
      /\s/g,
      ''
    );

    if (!values.credit_card_number) {
      // eslint-disable-next-line no-throw-literal
      throw new SubmissionError({
        credit_card_number: 'Credit Card Number is Required'
      });
    }
    if (creditCardNumber.length <= 12 || creditCardNumber.length >= 19) {
      // eslint-disable-next-line no-throw-literal
      throw new SubmissionError({
        credit_card_number:
          'Credit card length must be between 12 and 19 digits'
      });
    }
    if (!isValidDate) {
      // eslint-disable-next-line no-throw-literal
      throw new SubmissionError({
        credit_card_expiry_date: 'Date must be specified in MM/YY format'
      });
    }
    return new Promise((resolve, reject) =>
      dispatch(
        updateProfile({
          payload: {
            ...values,
            credit_card_number: (values.credit_card_number || '')
              .toString()
              .replace(/[\D]/g, ''),
            credit_card_expiry_date: values.credit_card_expiry_date
              ? moment(values.credit_card_expiry_date || '', 'MM/YY').format(
                  'YYYY-MM'
                )
              : ''
          },
          resolve,
          reject
        }).request
      )
    ).then(res => {
      localStorage.setItem('user', JSON.stringify(res.data));
      dispatch(reset('creditCardSectionForm'));
    });
  },
  enableReinitialize: true
})(AddressSectionForm);
