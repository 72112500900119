export const FILTER_TYPES = {
  NUMBER: 'number',
  STRING: 'string'
};

export const VIN = {
  name: 'vin',
  text: 'VIN',
  type: FILTER_TYPES.STRING
};

export const YEAR = {
  name: 'year',
  text: 'Year',
  operators: true,
  type: FILTER_TYPES.NUMBER
};

export const MAKE = {
  name: 'make',
  text: 'Make',
  type: FILTER_TYPES.STRING
};

export const MODEL = {
  name: 'model',
  text: 'Model',
  type: FILTER_TYPES.STRING
};

export const MILEAGE = {
  name: 'mileage',
  text: 'Mileage',
  operators: true,
  type: FILTER_TYPES.NUMBER
};

export const ENGINE = {
  name: 'engine',
  text: 'Engine', 
  type: FILTER_TYPES.STRING
};

export const TRANSMISSION = {
  name: 'transmission',
  text: 'Transmission',
  type: FILTER_TYPES.STRING
};

export const HIGH_BID = {
  name: 'high_bid',
  text: 'High Bid',
  operators: true,
  type: FILTER_TYPES.NUMBER
};

export const ADMIN_VEHICLE_LIST_DYNAMIC_FILTERS = [
  YEAR,
  MAKE,
  MODEL,
  MILEAGE,
  ENGINE,
  TRANSMISSION,
  HIGH_BID
];
