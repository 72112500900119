import styled from 'styled-components';
import { ListerSaveIcon } from '../../../../../components/Icons/ListerSaveIcon';
import { ListVehicleChat } from '../../../../../components/ListVehicles/components/Chat';
import { Dialog, Popover, withStyles } from '@material-ui/core';
import { useRef, useState } from 'react';
import { useGetChatMessagesQuery } from '../../../../../services/api/list-vehicles';
import { useOnClickOutside } from 'usehooks-ts';

const Wrapper = styled.div`
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  .vehicle-pool-paper {
    margin: 0;
    max-height: 900px;
  }

  > * .vehicle-scroll-paper {
    display: flex;
    align-items: baseline;
  }
`;

type Props = {
  requestId: number;
  classes?: Record<string, any>;
};

const styles = {
  scrollPaper: {
    alignItems: 'baseline',
    marginTop: '40px'
  }
};

export const ListerNotesBlock = withStyles(styles)(({ requestId, classes = {} }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: messages } = useGetChatMessagesQuery(requestId, {
    skip: !isOpen
  });

  const ref = useRef<any>(null);

  const showNotes = (e: any) => {
    window.scrollTo(0, 0);
    setIsOpen(true);
  };

  const hideNotes = () => {
    setIsOpen(false);
  };

  return (
    <Wrapper ref={ref}>
      <div onClick={showNotes}>
        <ListerSaveIcon isActive={isOpen} />
      </div>
      <StyledDialog
        classes={{ paperScrollPaper: 'vehicle-pool-paper', scrollPaper: classes.scrollPaper }}
        open={isOpen}
        onBackdropClick={hideNotes}
      >
        <ListVehicleChat listRequestId={requestId} messages={messages || []} />
      </StyledDialog>
    </Wrapper>
  );
});
