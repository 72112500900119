import { all, fork } from 'redux-saga/effects';

import socketSaga from './socket';
import vehiclesSaga from './vehicles';
import locationsSaga from './locations';
import auctionsSaga from './auctions';
import bidsSaga from './bids';
import userSaga from './user';
import financialsSaga from './financials';
import usersSaga from './users';
import regionsSaga from './regions';
import bannersSaga from './banners';
import statisticsSaga from './statistics';
import vehicleCreateSaga from './vehicles/vehicle-create';
import vehicleListSaga from './vehicles/vehicle-list';
import vehicleActionsSaga from './vehicles/vehicle-actions';
import logsSaga from './logs';

export default function* root() {
  yield all([
    fork(socketSaga),
    fork(vehiclesSaga),
    fork(userSaga),
    fork(locationsSaga),
    fork(auctionsSaga),
    fork(bidsSaga),
    fork(financialsSaga),
    fork(usersSaga),
    fork(regionsSaga),
    fork(bannersSaga),
    fork(statisticsSaga),
    fork(vehicleCreateSaga),
    fork(vehicleListSaga),
    fork(vehicleActionsSaga),
    fork(logsSaga)
  ]);
}
