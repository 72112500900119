import {
  LIST_SELLERS_INVOICES_REQUEST,
  LIST_SELLERS_INVOICES_SUCCESS,
  LIST_SELLERS_INVOICES_FAILURE,
  LIST_SELLERS_INVOICES_SET_PAGE,
  LIST_SELLERS_INVOICES_SET_PER_PAGE,
  LIST_SELLERS_INVOICES_SET_SELLER,
  LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST,
  LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS,
  LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE,
  CREATE_SELLER_INVOICE_RECORD_REQUEST,
  CREATE_SELLER_INVOICE_RECORD_SUCCESS,
  CREATE_SELLER_INVOICE_RECORD_FAILURE,
  SELLER_INVOICES_SET_DATE,
  LIST_SELLER_PAST_INVOICES_REQUEST,
  LIST_SELLER_PAST_INVOICES_SUCCESS,
  LIST_SELLER_PAST_INVOICES_FAILURE,
  LIST_BUYER_FEES_REQUEST,
  LIST_BUYER_FEES_SUCCESS,
  LIST_BUYER_FEES_FAILURE,
  UPDATE_BUYER_FEES_FAILURE,
  UPDATE_BUYER_FEES_REQUEST,
  UPDATE_BUYER_FEES_SUCCESS,
  GET_BUYER_FEE_REQUEST,
  GET_BUYER_FEE_SUCCESS,
  GET_BUYER_FEE_FAILURE,
  GENERATE_SELLER_INVOICE_FILE_REQUEST,
  GENERATE_SELLER_INVOICE_FILE_SUCCESS,
  GENERATE_SELLER_INVOICE_FILE_FAILURE,
  LIST_CURRENT_SELLER_INVOICES_REQUEST,
  LIST_CURRENT_SELLER_INVOICES_SUCCESS,
  LIST_CURRENT_SELLER_INVOICES_FAILURE,
  GET_DECLINED_TRANSACTIONS_REQUEST,
  GET_DECLINED_TRANSACTIONS_SUCCESS,
  GET_DECLINED_TRANSACTIONS_FAILURE,
  RETRY_FEE_CHARGING_REQUEST,
  RETRY_FEE_CHARGING_SUCCESS,
  RETRY_FEE_CHARGING_FAILURE,
  CHARGE_ADDITIONAL_FEES_REQUEST,
  CHARGE_ADDITIONAL_FEES_SUCCESS,
  CHARGE_ADDITIONAL_FEES_FAILURE,
  FILTER_SELLERS_INVOICES_REQUEST,
  FILTER_SELLERS_INVOICES_SUCCESS,
  FILTER_SELLERS_INVOICES_FAILURE,
  GET_DECLINED_TRANSACTIONS_SETPAGE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  TOTALS_INVOICES_REQUEST,
  TOTALS_INVOICES_SUCCESS,
  TOTALS_INVOICES_FAILURE,
  FINALIZE_SALE_SET_MODAL,
  GET_AUCTION_SUMMARY_REPORT_FAILURE,
  GET_AUCTION_SUMMARY_REPORT_REQUEST,
  GET_AUCTION_SUMMARY_REPORT_SUCCESS,
  GET_LISTER_SUMMARY_REPORT_REQUEST,
  GET_LISTER_SUMMARY_REPORT_SUCCESS,
  GET_LISTER_SUMMARY_REPORT_FAILURE,
} from '../constants/actionTypes';

export const listSellersInvoices = data => ({
  request: { type: LIST_SELLERS_INVOICES_REQUEST, payload: data },
  success: { type: LIST_SELLERS_INVOICES_SUCCESS, payload: data },
  failure: { type: LIST_SELLERS_INVOICES_FAILURE, payload: data }
});

export const filterSellersInvoices = data => ({
  request: { type: FILTER_SELLERS_INVOICES_REQUEST, payload: data },
  success: { type: FILTER_SELLERS_INVOICES_SUCCESS, payload: data },
  failure: { type: FILTER_SELLERS_INVOICES_FAILURE, payload: data }
});

export const listBuyerFees = data => ({
  request: { type: LIST_BUYER_FEES_REQUEST, payload: data },
  success: { type: LIST_BUYER_FEES_SUCCESS, payload: data },
  failure: { type: LIST_BUYER_FEES_FAILURE, payload: data }
});

export const getAuctionSummaryReport = data => ({
  request: { type: GET_AUCTION_SUMMARY_REPORT_REQUEST, payload: data },
  success: { type: GET_AUCTION_SUMMARY_REPORT_SUCCESS, payload: data },
  failure: { type: GET_AUCTION_SUMMARY_REPORT_FAILURE, payload: data }
});

export const getListerSummaryReport = data => ({
  request: { type: GET_LISTER_SUMMARY_REPORT_REQUEST, payload: data },
  success: { type: GET_LISTER_SUMMARY_REPORT_SUCCESS, payload: data },
  failure: { type: GET_LISTER_SUMMARY_REPORT_FAILURE, payload: data }
});

export const updateBuyerFees = data => ({
  request: { type: UPDATE_BUYER_FEES_REQUEST, payload: data },
  success: { type: UPDATE_BUYER_FEES_SUCCESS, payload: data },
  failure: { type: UPDATE_BUYER_FEES_FAILURE, payload: data }
});

export const getBuyerFee = data => ({
  request: { type: GET_BUYER_FEE_REQUEST, payload: data },
  success: { type: GET_BUYER_FEE_SUCCESS, payload: data },
  failure: { type: GET_BUYER_FEE_FAILURE, payload: data }
});

export const listSellerPastInvoices = data => ({
  request: { type: LIST_SELLER_PAST_INVOICES_REQUEST, payload: data },
  success: { type: LIST_SELLER_PAST_INVOICES_SUCCESS, payload: data },
  failure: { type: LIST_SELLER_PAST_INVOICES_FAILURE, payload: data }
});

export const getSingleInvoice = data => ({
  request: { type: LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST, payload: data },
  success: { type: LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS, payload: data },
  failure: { type: LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE, payload: data }
});

export const getTotalsInvoice = data => ({
  request: { type: TOTALS_INVOICES_REQUEST, payload: data },
  success: { type: TOTALS_INVOICES_SUCCESS, payload: data },
  failure: { type: TOTALS_INVOICES_FAILURE, payload: data }
});

export const createSingleInvoiceRecord = data => ({
  request: { type: CREATE_SELLER_INVOICE_RECORD_REQUEST, payload: data },
  success: { type: CREATE_SELLER_INVOICE_RECORD_SUCCESS, payload: data },
  failure: { type: CREATE_SELLER_INVOICE_RECORD_FAILURE, payload: data }
});

export const generateSellerInvoiceFile = data => ({
  request: { type: GENERATE_SELLER_INVOICE_FILE_REQUEST, payload: data },
  success: { type: GENERATE_SELLER_INVOICE_FILE_SUCCESS, payload: data },
  failure: { type: GENERATE_SELLER_INVOICE_FILE_FAILURE, payload: data }
});

export const listCurrentSellerInvoices = data => ({
  request: { type: LIST_CURRENT_SELLER_INVOICES_REQUEST, payload: data },
  success: { type: LIST_CURRENT_SELLER_INVOICES_SUCCESS, payload: data },
  failure: { type: LIST_CURRENT_SELLER_INVOICES_FAILURE, payload: data }
});

export const getDeclinedTransactions = data => ({
  request: { type: GET_DECLINED_TRANSACTIONS_REQUEST, payload: data },
  success: { type: GET_DECLINED_TRANSACTIONS_SUCCESS, payload: data },
  failure: { type: GET_DECLINED_TRANSACTIONS_FAILURE, payload: data }
});

export const DELETE_DECLINED_TRANSACTION_REQUEST = 'DELETE_DECLINED_TRANSACTION_REQUEST';
export const DELETE_DECLINED_TRANSACTION_SUCCESS = 'DELETE_DECLINED_TRANSACTION_SUCCESS';
export const DELETE_DECLINED_TRANSACTION_FAILURE = 'DELETE_DECLINED_TRANSACTION_FAILURE';

export const deleteDeclinedTransaction = data => ({
  request: { type: DELETE_DECLINED_TRANSACTION_REQUEST, payload: data },
  success: { type: DELETE_DECLINED_TRANSACTION_SUCCESS, payload: data },
  failure: { type: DELETE_DECLINED_TRANSACTION_FAILURE, payload: data }
});

export const retryFeeCharging = data => ({
  request: { type: RETRY_FEE_CHARGING_REQUEST, payload: data },
  success: { type: RETRY_FEE_CHARGING_SUCCESS, payload: data },
  failure: { type: RETRY_FEE_CHARGING_FAILURE, payload: data }
});

export const chargeAdditionalFees = data => ({
  request: { type: CHARGE_ADDITIONAL_FEES_REQUEST, payload: data },
  success: { type: CHARGE_ADDITIONAL_FEES_SUCCESS, payload: data },
  failure: { type: CHARGE_ADDITIONAL_FEES_FAILURE, payload: data }
});

export const sellersInvoicesSetPage = data => ({
  type: LIST_SELLERS_INVOICES_SET_PAGE,
  payload: data
});
export const declinedCardsSetPage = data => ({
  type: GET_DECLINED_TRANSACTIONS_SETPAGE,
  payload: data
});
export const sellersInvoicesSetPerPage = data => ({
  type: LIST_SELLERS_INVOICES_SET_PER_PAGE,
  payload: data
});
export const sellersInvoicesSetSeller = data => ({
  type: LIST_SELLERS_INVOICES_SET_SELLER,
  payload: data
});
export const sellersInvoicesSetDate = data => ({
  type: SELLER_INVOICES_SET_DATE,
  payload: data
});

export const LIST_TRANSACTIONS_REQUEST = 'LIST_TRANSACTIONS_REQUEST';
export const LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS';
export const LIST_TRANSACTIONS_FAILURE = 'LIST_TRANSACTIONS_FAILURE';

export const listTransactions = data => ({
  request: { type: LIST_TRANSACTIONS_REQUEST, payload: data },
  success: { type: LIST_TRANSACTIONS_SUCCESS, payload: data },
  failure: { type: LIST_TRANSACTIONS_FAILURE, payload: data }
});

export const FILTER_TRANSACTIONS_REQUEST = 'FILTER_TRANSACTIONS_REQUEST';
export const FILTER_TRANSACTIONS_SUCCESS = 'FILTER_TRANSACTIONS_SUCCESS';
export const FILTER_TRANSACTIONS_FAILURE = 'FILTER_TRANSACTIONS_FAILURE';

export const filterTransactions = data => ({
  request: { type: FILTER_TRANSACTIONS_REQUEST, payload: data },
  success: { type: FILTER_TRANSACTIONS_SUCCESS, payload: data },
  failure: { type: FILTER_TRANSACTIONS_FAILURE, payload: data }
});

export const LIST_TRANSACTIONS_SET_PAGE = 'LIST_TRANSACTIONS_SET_PAGE';

export const CHANGE_TRANSACTIONS_TYPE = 'CHANGE_TRANSACTIONS_TYPE';

export const changeTransactionsType = payload => ({
  type: CHANGE_TRANSACTIONS_TYPE,
  payload
});

export const transactionsSetPage = data => ({
  type: LIST_TRANSACTIONS_SET_PAGE,
  payload: data
});

export const LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_REQUEST =
  'LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_REQUEST';
export const LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_SUCCESS =
  'LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_SUCCESS';
export const LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_FAILURE =
  'LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_FAILURE';

export const getInvoiceByUploadedCars = data => ({
  request: {
    type: LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_REQUEST,
    payload: data
  },
  success: {
    type: LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_SUCCESS,
    payload: data
  },
  failure: {
    type: LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_FAILURE,
    payload: data
  }
});

export const LIST_EASYPAY_PAYMENTS_REQUEST = 'LIST_EASYPAY_PAYMENTS_REQUEST';
export const LIST_EASYPAY_PAYMENTS_SUCCESS = 'LIST_EASYPAY_PAYMENTS_SUCCESS';
export const LIST_EASYPAY_PAYMENTS_FAILURE = 'LIST_EASYPAY_PAYMENTS_FAILURE';

export const getEasyPayList = data => ({
  request: { type: LIST_EASYPAY_PAYMENTS_REQUEST, payload: data },
  success: { type: LIST_EASYPAY_PAYMENTS_SUCCESS, payload: data },
  failure: { type: LIST_EASYPAY_PAYMENTS_FAILURE, payload: data }
});

export const LIST_AWARDED_VEHICLES_REQUEST = 'LIST_AWARDED_VEHICLES_REQUEST';
export const LIST_AWARDED_VEHICLES_SUCCESS = 'LIST_AWARDED_VEHICLES_SUCCESS';
export const LIST_AWARDED_VEHICLES_FAILURE = 'LIST_AWARDED_VEHICLES_FAILURE';

export const getAwardedVehicleList = data => ({
  request: { type: LIST_AWARDED_VEHICLES_REQUEST, payload: data },
  success: { type: LIST_AWARDED_VEHICLES_SUCCESS, payload: data },
  failure: { type: LIST_AWARDED_VEHICLES_FAILURE, payload: data }
});

export const EASY_PAY_LIST_SET_PAGE = 'EASY_PAY_LIST_SET_PAGE';

export const easyPayListSetPage = data => ({
  type: EASY_PAY_LIST_SET_PAGE,
  payload: data
});

export const AWARDED_VEHICLES_LIST_SET_PAGE = 'AWARDED_VEHICLES_LIST_SET_PAGE';

export const awardedVehiclesListSetPage = data => ({
  type: AWARDED_VEHICLES_LIST_SET_PAGE,
  payload: data
});

export const FILTER_EASYPAY_PAYMENTS_REQUEST = 'FILTER_EASYPAY_PAYMENTS_REQUEST';
export const FILTER_EASYPAY_PAYMENTS_SUCCESS = 'FILTER_EASYPAY_PAYMENTS_SUCCESS';
export const FILTER_EASYPAY_PAYMENTS_FAILURE = 'FILTER_EASYPAY_PAYMENTS_FAILURE';

export const filterEasyPayList = data => ({
  request: { type: FILTER_EASYPAY_PAYMENTS_REQUEST, payload: data },
  success: { type: FILTER_EASYPAY_PAYMENTS_SUCCESS, payload: data },
  failure: { type: FILTER_EASYPAY_PAYMENTS_FAILURE, payload: data }
});

export const FILTER_AWARDED_VEHICLES_REQUEST = 'FILTER_AWARDED_VEHICLES_REQUEST';
export const FILTER_AWARDED_VEHICLES_SUCCESS = 'FILTER_AWARDED_VEHICLES_SUCCESS';
export const FILTER_AWARDED_VEHICLES_FAILURE = 'FILTER_AWARDED_VEHICLES_FAILURE';

export const filterAwardedVehiclesList = data => ({
  request: { type: FILTER_AWARDED_VEHICLES_REQUEST, payload: data },
  success: { type: FILTER_AWARDED_VEHICLES_SUCCESS, payload: data },
  failure: { type: FILTER_AWARDED_VEHICLES_FAILURE, payload: data }
});

export const UPDATE_EASY_PAY_TRANSACTION_REQUEST = 'UPDATE_EASY_PAY_TRANSACTION_REQUEST';
export const UPDATE_EASY_PAY_TRANSACTION_SUCCESS = 'UPDATE_EASY_PAY_TRANSACTION_SUCCESS';
export const UPDATE_EASY_PAY_TRANSACTION_FAILURE = 'UPDATE_EASY_PAY_TRANSACTION_FAILURE';

export const updateEasyPayTransaction = data => ({
  request: { type: UPDATE_EASY_PAY_TRANSACTION_REQUEST, payload: data },
  success: { type: UPDATE_EASY_PAY_TRANSACTION_SUCCESS, payload: data },
  failure: { type: UPDATE_EASY_PAY_TRANSACTION_FAILURE, payload: data }
});

export const updateTransaction = data => ({
  request: { type: UPDATE_TRANSACTION_REQUEST, payload: data },
  success: { type: UPDATE_TRANSACTION_SUCCESS, payload: data },
  failure: { type: UPDATE_TRANSACTION_FAILURE, payload: data }
});

export const finalizeSaleSetModal = data => ({
  type: FINALIZE_SALE_SET_MODAL,
  payload: data
});
