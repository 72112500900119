import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Section } from '../components';
import { PhoneNumberText } from '../../../../components';
import { selectedVehicleSelector } from '../../../../selectors';

const StyledSection = styled(Section)`
  padding-bottom: 50px;
`;

const SellerInformation = ({ vehicle }) => ( 
  <StyledSection title='Seller Information' sm={12} md={6}>
    {vehicle.user.dealership_name}
    <br />
    {vehicle.user.street}
    <br />
    {`${vehicle.user.city}, ${vehicle.user.state} ${vehicle.user.zip}`}
    <br />
    <PhoneNumberText text={vehicle.user.seller.phone_number} />
  </StyledSection>
);

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state)
});

export default connect(mapStateToProps)(SellerInformation);
