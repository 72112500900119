import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SectionWrapper, SectionTitle } from './index';

const StyledAdaptiveWrapper = styled(SectionWrapper)`
  ${props =>
    props.mobileOnly
      ? `
    @media (min-width: 1280px) {
      display: none;
    }
  `
      : `
    @media (max-width: 1279px) {
      display: none;
    }
  `}
`;

const VehicleInformation = ({ currentVehicle, mobileOnly = false }) => (
  <StyledAdaptiveWrapper mobileOnly={mobileOnly}>
    <SectionTitle>Vehicle Information</SectionTitle>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography paragraph variant="body1">
          <b>Trim:</b> {currentVehicle.trim}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Engine:</b> {currentVehicle.engine}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Exterior Color:</b> {currentVehicle.ext_color}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography paragraph variant="body1">
          <b>Transmission:</b> {currentVehicle.transmission}
        </Typography>
        <Typography paragraph variant="body1">
          <b>Drive Train:</b> {currentVehicle.drive_train}
        </Typography>
      </Grid>
    </Grid>
  </StyledAdaptiveWrapper>
);

export default VehicleInformation;
