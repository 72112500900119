import { Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import styled from 'styled-components';
import Timer from '../../../../components/Timer';
import { COLOR_SUCCESSFUL, COLOR_ERROR, roles } from '../../../../constants';
import { SectionWrapper } from './index';
import { getHighestBid, getVehicleTimer } from '../../../../utils/vehicle';
import { useEffect, useState } from 'react';

const StyledGridWrapper = styled(Grid)`
  @media (min-width: 600px) {
    > div:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
  }
`;

const SectionTitle = styled(Typography)`
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const ArrowDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 15.6016L5.97501 9.60156H18.025L12 15.6016Z" fill="#757575" />
  </svg>
);

const StyledGrid = styled(Grid)`
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
  &:not(:first) {
    border-left: 1px solid #dcdcdc;
  }
  @media (max-width: 599px) {
    text-align: left;
  }
`;

const StyledTextWithoutOverflow = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const TitleTextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledHighestBidder = styled(Typography)`
  display: inline;
  color: ${COLOR_SUCCESSFUL};
`;

const StyledOutbidded = styled(Typography)`
  display: inline;
  color: ${COLOR_ERROR};
`;

const StyledNumberFormat = styled(NumberFormat)`
  color: #06a000;
`;

const AuctionSummarySection = ({ currentUser, vehicle, bid, bidder, onTimerEnded, initiallyCollapsed }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (initiallyCollapsed) {
      setIsExpanded(false);
    }
  }, [initiallyCollapsed]);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const vehicleTimer = getVehicleTimer(vehicle);

  const currentTime = moment.utc();
  const vehicleProxyBid = (vehicle.proxy_bid || {}).amount || 0;
  const isUserProxyBidder = (vehicle.proxy_bid || {}).user_id === currentUser.id;
  const isVehicleProxyBidHigherThanBid = vehicleProxyBid > (bid || 0);
  const isVehicleTimerEnded =
    vehicle.end_time_spacing !== null &&
    moment(vehicle.auction.date_end)
      .utc()
      .add(vehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const highestBid = getHighestBid(vehicle);
  const isBuyerHighestBidder = highestBid?.user_id === currentUser.id;
  const isUserBuyer = currentUser.role === roles.BUYER;
  const isBuyerPlacedBid = vehicle.bids.find(vehicleBid => vehicleBid.user_id === currentUser.id);

  return (
    <SectionWrapper>
      <SectionTitle>
        <TitleTextWrapper>
          {isVehicleTimerEnded ? 'Auction Summary' : 'Auction In Progress'}
          {isBuyerPlacedBid && (
            <>
              <StyledHighestBidder component="span">
                {isUserBuyer && isBuyerHighestBidder && " (You're the top bidder)"}
              </StyledHighestBidder>
              <StyledOutbidded component="span">
                {isUserBuyer && !isBuyerHighestBidder && " (You've been outbid)"}
              </StyledOutbidded>
            </>
          )}
        </TitleTextWrapper>
        <IconWrapper onClick={toggleExpanded}>
          <ArrowDownIcon />
        </IconWrapper>
      </SectionTitle>
      {isExpanded && (
        <StyledGridWrapper container>
          <StyledGrid container direction="column" item xs={12} sm={4}>
            <Typography gutterBottom variant="caption">
              Current Bid
            </Typography>
            {bid ? (
              <>
                <StyledNumberFormat displayType="text" prefix="$" value={bid} thousandSeparator />
                {isUserProxyBidder && isVehicleProxyBidHigherThanBid && (
                  <Typography>
                    (
                    <NumberFormat displayType="text" prefix="$" value={vehicleProxyBid} thousandSeparator /> max)
                  </Typography>
                )}
              </>
            ) : (
              <span>$0</span>
            )}
          </StyledGrid>
          <StyledGrid container direction="column" item xs={12} sm={4}>
            <Typography gutterBottom variant="caption">
              Highest Bidder
            </Typography>
            <StyledTextWithoutOverflow title={bidder || 'N/A'}>{bidder || 'N/A'}</StyledTextWithoutOverflow>
          </StyledGrid>
          <StyledGrid container direction="column" item xs={12} sm={4}>
            <Typography gutterBottom variant="caption">
              Time Remaining
            </Typography>
            <Timer withColors onTimerEnded={onTimerEnded} toDate={vehicleTimer} />
          </StyledGrid>
        </StyledGridWrapper>
      )}
    </SectionWrapper>
  );
};

export default AuctionSummarySection;
