import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import {IconInfo} from "../../../Auth/Registration/components/InfoAlert";

const StyledTitle = styled(Typography)`
  margin-bottom: 10px;
`;

const StyledGrid = styled(Grid)`
  padding-bottom: 20px;
`;

const Section = ({ children, title, hoverInfo, ...rest }) => (
  <StyledGrid item {...rest}>
    <StyledTitle variant='display1'>
      {title}

      {hoverInfo && <>
        <span title={hoverInfo}>
          {' '} &nbsp;
          <IconInfo style={{verticalAlign: 'top'}} height="1em" width="1em" />
        </span>
      </>}

    </StyledTitle>
    <Typography>
      {children}
    </Typography>
  </StyledGrid>
);

export default Section;
