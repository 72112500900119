import React from 'react';
import FormTextField from '../FormTextField';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { ErrorIcon } from '../../Icons/ErrorIcon';
import FormFormattedField from '../FormFormattedField';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  getMaskProps?: (value: any) => any;
  validate?: FieldValidator<any>;
  maskProps?: any;
};

const OutlinedFormFormattedField = ({
  name,
  label,
  placeholder,
  getMaskProps = undefined,
  maskProps = undefined,
  validate = undefined
}: Props) => {
  return (
    <Field name={name} validate={validate}>
      {fieldProps => {
        const hasError = (fieldProps.meta.touched && fieldProps.meta.error) || fieldProps.meta.submitError;
        return (
          <FormFormattedField
            input={fieldProps.input}
            meta={fieldProps.meta}
            label={label}
            placeholder={placeholder}
            fullWidth
            includeBottomIndent
            Icon={hasError ? ErrorIcon : null}
            iconOptions={{
              position: 'end'
            }}
            maskProps={getMaskProps ? getMaskProps(fieldProps.input.value) : maskProps}
            styleVariant={hasError ? 'outlined_error' : 'outlined'}
          />
        );
      }}
    </Field>
  );
};

export default OutlinedFormFormattedField;
