import styled from 'styled-components';
import { ConfirmIcon } from '../../../Icons/ConfirmIcon';
import { BinIcon, EditIcon } from '../../../Icons';
import { SaveIcon } from '../../../Icons/SaveIcon';
import { DuplicateIcon } from '../../../Icons/DuplicateIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

type Action = () => Promise<any> | any;

type Props = {
  deleteAction?: Action;
  submitAction?: Action;
  editAction?: Action;
  saveAction?: Action;
  duplicateAction?: Action;
};

export const Actions = ({ submitAction, deleteAction, saveAction, duplicateAction, editAction }: Props) => {
  return (
    <Wrapper>
      {saveAction && (
        <IconWrapper onClick={saveAction}>
          <SaveIcon />
        </IconWrapper>
      )}

      {duplicateAction && (
        <IconWrapper onClick={duplicateAction}>
          <DuplicateIcon />
        </IconWrapper>
      )}

      {editAction && (
        <IconWrapper onClick={editAction}>
          <EditIcon />
        </IconWrapper>
      )}

      {deleteAction && (
        <IconWrapper onClick={deleteAction}>
          <BinIcon />
        </IconWrapper>
      )}

      {submitAction && (
        <IconWrapper onClick={submitAction}>
          <ConfirmIcon />
        </IconWrapper>
      )}
    </Wrapper>
  );
};
