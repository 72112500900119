import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';

type ScrollPositionState = {
  positions: Record<string, number>;
}

const initialState: ScrollPositionState = {
    positions: {}
};

const scrollPositionsSlice = createSlice({
  name: 'scrollPositions',
  initialState,
  reducers: {
      setScrollPosition(state, action: PayloadAction<{ [x: string]: number}>) {
        state.positions = {...state.positions, ...action.payload}
      }
    }
});

export const useGetScrollPosition = (route: string): number => {
    const state = useSelector((state: any) => state.scrollPositions?.positions)
    return state?.[route] ?? 0
}

export const { setScrollPosition } = scrollPositionsSlice.actions
export default scrollPositionsSlice;
