import styled from 'styled-components';
import { VehicleSectionImage } from '../../../../../../../types/vehicle';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openFullScreenWithId } from '../../../../../../../services/viewVehicleSlice/viewVehicleSlice';

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ overflowVisible?: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  overflow: hidden;
  overflow-x: ${props => (props.overflowVisible ? 'auto' : 'hidden')};
  width: 100%;
`;

const ImageWrapper = styled.div<{ url: string; hasNotes?: boolean }>`
  min-width: 129px;
  min-height: 101px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props =>
    props.hasNotes ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%),' : ''} url("${props =>
  props.url}"); 

  & > img {
    width: 129px;
    height: 101px;
  }
`;

const ShowMoreButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  top: 0;
  left: 0;
  width: 129px;
  height: 101px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  color: #fff;
  cursor: pointer;
`;

const ImageNotes = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  bottom: 3px;
  padding: 0 10px;
  display: flex;
  max-width: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${props => (props.isHovered ? 'normal' : 'nowrap')};
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }
`;

const moreImagesThreshold = 7;

export const SubSectionImages = ({ images }: { images: VehicleSectionImage[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [moreImagesButtonClicked, setMoreImagesButtonClicked] = useState(false);

  const handleShowMoreImagesClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMoreImagesButtonClicked(true);
    (e.target as any).scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  };

  return (
    <Wrapper>
      <Container overflowVisible={moreImagesButtonClicked} ref={ref}>
        {images.map((image, i) => {
          const renderShowMoreImagesButton =
            i === moreImagesThreshold - 1 && images.length > moreImagesThreshold && !moreImagesButtonClicked;

          return (
            <RenderImage
              image={image}
              renderShowMoreImagesButton={renderShowMoreImagesButton}
              leftoverImages={images.length - moreImagesThreshold}
              handleShowMoreImagesClicked={handleShowMoreImagesClicked}
            />
          );
        })}
      </Container>
    </Wrapper>
  );
};

type RenderImageProps = {
  renderShowMoreImagesButton: boolean;
  image: VehicleSectionImage;
  leftoverImages: number;
  handleShowMoreImagesClicked: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const RenderImage = ({
  image,
  leftoverImages,
  renderShowMoreImagesButton,
  handleShowMoreImagesClicked
}: RenderImageProps) => {
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);

  const openImageGallery = () => {
    if (renderShowMoreImagesButton) return;
    dispatch(openFullScreenWithId(image.id));
  };

  return (
    <ImageWrapper
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={openImageGallery}
      url={image.url}
      hasNotes={!!image.notes}
    >
      {renderShowMoreImagesButton && (
        <ShowMoreButton onClick={handleShowMoreImagesClicked}>
          <span>{`+${leftoverImages}`}</span>
        </ShowMoreButton>
      )}
      {!renderShowMoreImagesButton && image.notes && (
        <ImageNotes isHovered={isHovered}>
          <span>{image.notes}</span>
        </ImageNotes>
      )}
    </ImageWrapper>
  );
};
