import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import moment from 'moment';
import { convertToFormData } from '../../../utils/api';
import { User } from '../../../types/user';

type AskQuestionPayload = {
  vehicle: string;
  vehicleId: number;
  email: string;
  fullName: string;
  phone: string;
  message: string;
};

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<any, any>({
      query: body => ({
        url: '/auth/login',
        method: 'POST',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    forgotPassword: builder.mutation<any, any>({
      query: body => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    register: builder.mutation<any, any>({
      query: body => {
        const formData = convertToFormData(body);
        return {
          url: '/auth/register',
          method: 'POST',
          body: formData,
          formData: true
        };
      },
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    onboarding: builder.mutation<any, any>({
      query: body => {
        const formData = convertToFormData(body);
        return {
          url: '/auth/onboarding',
          method: 'POST',
          body: formData,
          formData: true
        };
      },
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    me: builder.query<User, void>({
      query: () => ({
        url: '/me'
      }),
      providesTags: ['Me'],
      transformResponse: (r: any) => r.data
    }),
    validateForm: builder.mutation<any, any>({
      query: body => ({
        method: 'POST',
        url: 'auth/validate',
        body
      }),
      transformErrorResponse: (r: any) => r.data,
      transformResponse: (r: any) => r.data
    }),
    askQuestion: builder.mutation<void, AskQuestionPayload>({
      query: body => ({
        url: '/auth/contact-question',
        method: 'POST',
        body
      })
    })
  }),
  overrideExisting: true
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useRegisterMutation,
  useOnboardingMutation,
  useMeQuery,
  useValidateFormMutation,
  useAskQuestionMutation
} = authApiSlice;
