import moment from 'moment';
import {
  LIST_SELLERS_INVOICES_REQUEST,
  LIST_SELLERS_INVOICES_SUCCESS,
  LIST_SELLERS_INVOICES_FAILURE,
  FILTER_SELLERS_INVOICES_REQUEST,
  FILTER_SELLERS_INVOICES_SUCCESS,
  LIST_SELLERS_INVOICES_SET_SELLER,
  LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST,
  LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS,
  LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE,
  CREATE_SELLER_INVOICE_RECORD_REQUEST,
  CREATE_SELLER_INVOICE_RECORD_SUCCESS,
  CREATE_SELLER_INVOICE_RECORD_FAILURE,
  LIST_SELLER_PAST_INVOICES_REQUEST,
  LIST_SELLER_PAST_INVOICES_SUCCESS,
  LIST_SELLER_PAST_INVOICES_FAILURE,
  SELLER_INVOICES_SET_DATE,
  LIST_BUYER_FEES_SUCCESS,
  UPDATE_BUYER_FEES_SUCCESS,
  GET_BUYER_FEE_REQUEST,
  GET_BUYER_FEE_SUCCESS,
  GET_BUYER_FEE_FAILURE,
  GENERATE_SELLER_INVOICE_FILE_REQUEST,
  GENERATE_SELLER_INVOICE_FILE_SUCCESS,
  GENERATE_SELLER_INVOICE_FILE_FAILURE,
  LIST_CURRENT_SELLER_INVOICES_REQUEST,
  LIST_CURRENT_SELLER_INVOICES_SUCCESS,
  LIST_CURRENT_SELLER_INVOICES_FAILURE,
  GET_DECLINED_TRANSACTIONS_REQUEST,
  GET_DECLINED_TRANSACTIONS_SUCCESS,
  GET_DECLINED_TRANSACTIONS_FAILURE,
  DISABLE_BUYER_SUCCESS,
  RETRY_FEE_CHARGING_REQUEST,
  RETRY_FEE_CHARGING_SUCCESS,
  RETRY_FEE_CHARGING_FAILURE,
  LIST_SELLERS_INVOICES_SET_PAGE,
  GET_DECLINED_TRANSACTIONS_SETPAGE,
  UPDATE_TRANSACTION_SUCCESS,
  TOTALS_INVOICES_SUCCESS,
  FINALIZE_SALE_SET_MODAL,
  GET_AUCTION_SUMMARY_REPORT_SUCCESS,
  GET_LISTER_SUMMARY_REPORT_SUCCESS,
} from '../constants/actionTypes';
import {
  TRANSACTIONS_PER_PAGE,
  TRANSACTION_TYPES,
  FINANCIALS_INVOICES_PER_PAGE
} from '../constants';
import {
  LIST_TRANSACTIONS_REQUEST,
  LIST_TRANSACTIONS_SUCCESS,
  LIST_TRANSACTIONS_FAILURE,
  FILTER_TRANSACTIONS_REQUEST,
  FILTER_TRANSACTIONS_SUCCESS,
  CHANGE_TRANSACTIONS_TYPE,
  LIST_TRANSACTIONS_SET_PAGE,
  DELETE_DECLINED_TRANSACTION_SUCCESS,
  LIST_EASYPAY_PAYMENTS_REQUEST,
  LIST_EASYPAY_PAYMENTS_SUCCESS,
  FILTER_EASYPAY_PAYMENTS_REQUEST,
  FILTER_EASYPAY_PAYMENTS_SUCCESS,
  EASY_PAY_LIST_SET_PAGE,
  UPDATE_EASY_PAY_TRANSACTION_SUCCESS,
  UPDATE_EASY_PAY_TRANSACTION_FAILURE,
  LIST_AWARDED_VEHICLES_REQUEST,
  LIST_AWARDED_VEHICLES_SUCCESS,
  AWARDED_VEHICLES_LIST_SET_PAGE,
  FILTER_AWARDED_VEHICLES_SUCCESS,
  FILTER_AWARDED_VEHICLES_REQUEST,
} from '../actions/financials';
import {
  ENABLE_USER_SUCCESS
} from '../actions/users';

const initialState = {
  loading: true,
  easy_pay_list: {
    loading: false,
    page: 1,
    perPage: TRANSACTIONS_PER_PAGE,
    rows: [],
    count: 0
  },
  awarded_vehicles_list: {
    loading: false,
    page: 1,
    perPage: TRANSACTIONS_PER_PAGE,
    rows: [],
    count: 0
  },
  invoices: {
    rows: [],
    count: 0
  },
  totalInvoices: {

  },
  declined_transactions: {
    page: 1,
    perPage: FINANCIALS_INVOICES_PER_PAGE,
    rows: [],
    error: null,
    count: 0
  },
  transactions: {
    page: 1,
    perPage: TRANSACTIONS_PER_PAGE,
    rows: [],
    count: 0
  },
  transactionsType: TRANSACTION_TYPES.AUCTION,
  page: 1,
  perPage: FINANCIALS_INVOICES_PER_PAGE,
  error: '',
  currentSeller: {},
  currentInvoice: [],
  currentInvoiceDate: moment()
    .subtract(1, 'month')
    .format('MM-YYYY'),
  pastInvoices: [],
  fees: [],
  buyerFees: {
    loading: false,
    fee: {},
    error: null
  },
  auction_summary_report: null,
  lister_summary_report: null,
  sellerGeneratedInvoice: null
};

const financials = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_TRANSACTIONS_SET_PAGE:
      return { ...state, page: payload };
    case LIST_TRANSACTIONS_REQUEST:
      return { ...state, loading: true };
    case LIST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: {
          ...state.transactions,
          count: payload.data.count,
          rows: [...state.transactions.rows, ...payload.data.rows]
        }
      };
    case LIST_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        transactions: (payload && payload.data) || {}
      };
    case FILTER_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        transactions: initialState.transactions
      };
    case FILTER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: {
          ...state.transactions,
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case UPDATE_EASY_PAY_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          rows: state.transactions.rows.map(transaction => transaction.id === payload.id ? { ...transaction, ...payload } : transaction)
        }
      };
    case UPDATE_EASY_PAY_TRANSACTION_FAILURE:
      payload.errorData = payload.response.data.data;
      return {
        ...state,
        transactions: {
          ...state.transactions,
          rows: state.transactions.rows.map(transaction =>
            transaction.id === payload.errorData.id ? { ...transaction, ...payload.errorData } : transaction
          )
        }
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          rows: state.transactions.rows.map(transaction => transaction.id === payload.id ? { ...transaction, ...payload } : transaction)
        }
      };
    case CHANGE_TRANSACTIONS_TYPE:
      return {
        ...state,
        transactionsType: payload
      };
    case LIST_SELLERS_INVOICES_REQUEST:
      return { ...state, loading: true };
    case TOTALS_INVOICES_SUCCESS:
      return { ...state, totalInvoices: payload };
    case LIST_SELLERS_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: {
          count: payload.data.count,
          rows: [...state.invoices.rows, ...payload.data.rows]
        }
      };
    case LIST_SELLERS_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
        invoices: (payload && payload.data) || {}
      };
    case LIST_SELLERS_INVOICES_SET_PAGE:
      return {
        ...state,
        page: payload
      };
    case GET_DECLINED_TRANSACTIONS_SETPAGE:
      return {
        ...state,
        declined_transactions: {
          ...state.declined_transactions,
          page: payload
        }
      };
    case FILTER_SELLERS_INVOICES_REQUEST:
      return { ...state, loading: true };
    case FILTER_SELLERS_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_SELLERS_INVOICES_SET_SELLER:
      return { ...state, currentSeller: payload };
    case LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST:
      return { ...state, loading: true };
    case LIST_SELLERS_INVOICES_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentInvoice: payload.data.invoiceData
      };
    case LIST_SELLERS_INVOICES_GET_SINGLE_FAILURE:
      return { ...state, currentInvoice: [] };
    case CREATE_SELLER_INVOICE_RECORD_REQUEST:
      return { ...state, loading: true, error: '' };
    case CREATE_SELLER_INVOICE_RECORD_SUCCESS: {
      return { ...state, loading: false };
    }
    case CREATE_SELLER_INVOICE_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.response.data.data[0].message
      };
    case LIST_SELLER_PAST_INVOICES_REQUEST:
      return { ...state, loading: true };
    case LIST_SELLER_PAST_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        pastInvoices: payload.data
      };
    case LIST_SELLER_PAST_INVOICES_FAILURE:
      return {
        ...state,
        loading: false,
        invoices: (payload && payload.data) || {}
      };
    case SELLER_INVOICES_SET_DATE:
      return { ...state, currentInvoiceDate: payload };
    case LIST_BUYER_FEES_SUCCESS:
      return { ...state, loading: false, fees: payload.data };
    case GET_AUCTION_SUMMARY_REPORT_SUCCESS:
      return { ...state, loading: false, auction_summary_report: payload.data };
    case GET_LISTER_SUMMARY_REPORT_SUCCESS:
      return { ...state, loading: false, lister_summary_report: payload.data };
    case UPDATE_BUYER_FEES_SUCCESS:
      return { ...state, loading: false, fees: payload.data };
    case GET_BUYER_FEE_REQUEST:
      return { ...state, buyerFees: { ...state.buyerFees, loading: true } };
    case GET_BUYER_FEE_SUCCESS:
      return {
        ...state,
        buyerFees: { ...state.buyerFees, loading: false, fee: payload.data[0] ?? {} }
      };
    case GET_BUYER_FEE_FAILURE:
      return {
        ...state,
        buyerFees: { ...state.buyerFees, loading: false, error: payload.data }
      };
    case GENERATE_SELLER_INVOICE_FILE_REQUEST:
      return {
        ...state,
        sellerGeneratedInvoice: null,
        loading: true,
        error: null
      };
    case GENERATE_SELLER_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        sellerGeneratedInvoice: payload.data,
        loading: false,
        error: null
      };
    case GENERATE_SELLER_INVOICE_FILE_FAILURE:
      return {
        ...state,
        sellerGeneratedInvoice: null,
        loading: false,
        error: payload.data
      };
    case LIST_CURRENT_SELLER_INVOICES_REQUEST:
      return { ...state, loading: true };
    case LIST_CURRENT_SELLER_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_CURRENT_SELLER_INVOICES_FAILURE:
      return { ...state, loading: false };
    case GET_DECLINED_TRANSACTIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DECLINED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        declined_transactions: {
          ...state.declined_transactions,
          rows: [...state.declined_transactions.rows, ...payload.data.rows],
          count: payload.data.count
        }
      };
    case GET_DECLINED_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.data
      };
    case DELETE_DECLINED_TRANSACTION_SUCCESS:
      return {
        ...state,
        declined_transactions: {
          ...state.declined_transactions,
          rows: state.declined_transactions.rows.filter(transaction => transaction.id !== payload.data.id)
        }
      }
    case DISABLE_BUYER_SUCCESS:
      return {
        ...state,
        declined_transactions: {
          ...state.declined_transactions,
          rows: state.declined_transactions.rows.map(row => {
            if (payload.data.id === row.transaction.user.id) {
              return {
                ...row,
                transaction: {
                  ...row.transaction,
                  user: payload.data
                }
              };
            }
            return row;
          })
        }
      };
    case ENABLE_USER_SUCCESS:
      return {
        ...state,
        declined_transactions: {
          ...state.declined_transactions,
          rows: state.declined_transactions.rows.map(row => {
            if (payload.data.id === row.transaction.user.id) {
              return {
                ...row,
                transaction: {
                  ...row.transaction,
                  user: payload.data
                }
              };
            }
            return row;
          })
        }
      };
    case RETRY_FEE_CHARGING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case RETRY_FEE_CHARGING_SUCCESS:
      return {
        ...state,
        loading: false,
        declined_transactions: {
          ...state.declined_transactions,
          rows: state.declined_transactions.rows.filter(({ transaction }) => transaction.transactionId !== payload.data.transactionId)
        }
      };
    case RETRY_FEE_CHARGING_FAILURE:
      if (Object.keys(payload.data).length === 0) {
        return { ...state, loading: false };
      }
      return {
        ...state,
        loading: false,
        declined_transactions: {
          ...state.declined_transactions,
          rows: state.declined_transactions.rows.map(transaction =>
            (transaction.user_id === payload.data.user_id && transaction.vehicle_id === payload.data.vehicle_id && transaction.transactionId === payload.data.transactionId)
              ? payload.data
              : transaction
          )
        }
      };
    case LIST_EASYPAY_PAYMENTS_REQUEST:
      return {
        ...state,
        easy_pay_list: {
          ...state.easy_pay_list,
          loading: true
        }
      };
    case LIST_EASYPAY_PAYMENTS_SUCCESS:
      return {
        ...state,
        easy_pay_list: {
          ...state.easy_pay_list,
          loading: false,
          count: payload.data.count,
          rows: [...state.easy_pay_list.rows, ...payload.data.rows]
        }
      };
    case LIST_AWARDED_VEHICLES_REQUEST:
      return {
        ...state,
        awarded_vehicles_list: {
          ...state.awarded_vehicles_list,
          loading: true
        }
      };
    case LIST_AWARDED_VEHICLES_SUCCESS:
      return {
        ...state,
        awarded_vehicles_list: {
          ...state.awarded_vehicles_list,
          loading: false,
          count: payload.data.count,
          rows: [...state.awarded_vehicles_list.rows, ...payload.data.rows]
        }
      };
    case FILTER_EASYPAY_PAYMENTS_REQUEST:
      return {
        ...state,
        easy_pay_list: {
          ...initialState.easy_pay_list,
          loading: true,
        }
      };
    case FILTER_AWARDED_VEHICLES_REQUEST:
      return {
        ...state,
        awarded_vehicles_list: {
          ...initialState.awarded_vehicles_list,
          loading: true,
        }
      };
    case FILTER_EASYPAY_PAYMENTS_SUCCESS:
      return {
        ...state,
        easy_pay_list: {
          ...state.easy_pay_list,
          loading: false,
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case FILTER_AWARDED_VEHICLES_SUCCESS:
      return {
        ...state,
        awarded_vehicles_list: {
          ...state.awarded_vehicles_list,
          loading: false,
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case EASY_PAY_LIST_SET_PAGE:
      return {
        ...state,
        easy_pay_list: {
          ...state.easy_pay_list,
          page: payload
        }
      };
    case AWARDED_VEHICLES_LIST_SET_PAGE:
      return {
        ...state,
        awarded_vehicles_list: {
          ...state.awarded_vehicles_list,
          page: payload
        }
      };
    case FINALIZE_SALE_SET_MODAL:
      return {
        ...state,
        finalizeSaleModal: payload
      };
    default:
      return state;
  }
};

export default financials;
