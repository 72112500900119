import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import HeaderTitle from '../components/HeaderTitle';
import { VEHICLE_STATUSES, BUYER_VEHICLE_STATUS, BUYER_VEHICLE_LABELS } from '../../../../../constants';
import { getHighestBid } from '../../../../../utils/vehicle';
import { useSelector } from 'react-redux';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
`;

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
    span {
      color: ${props => props.statusColor || '#000'};
    }
  }
`;

const CounterControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 16px;
`;

const CounterButton = styled(Button)`
  border-radius: 6px;
  width: 94px;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const NoHeaderStatuses = [BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT];

const StatusHeader = ({ vehicle }) => {
  const user = useSelector(state => state.user.user);
  const currentBid = getHighestBid(vehicle);
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);
  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  const headerTitle = (() => {
    if (status === VEHICLE_STATUSES.AWARDED || status === BUYER_VEHICLE_STATUS.DECLINED) {
      return 'Amount —';
    }
    if (status === BUYER_VEHICLE_STATUS.COUNTERED) {
      return 'Counter Amount —';
    }
    return 'Highest Bid —';
  })();

  const headerAmount = (() => {
    if (status === VEHICLE_STATUSES.AWARDED || status === BUYER_VEHICLE_STATUS.DECLINED) {
      return vehicle.price;
    }
    if (status === BUYER_VEHICLE_STATUS.COUNTERED) {
      return offerAuction.privateOffers.find(el => el.status === 'sent')?.amount ?? 0;
    }
    return currentBid.amount;
  })();

  const { label, color } = BUYER_VEHICLE_LABELS[status] ?? {};

  return (
    <StyledHeaderWrapper>
      <HeaderTitle
        titleJSX={
          !NoHeaderStatuses.includes(status) ? (
            <StyledHeaderTitle>
              {headerTitle} <NumberFormat displayType="text" prefix="$" value={headerAmount} thousandSeparator />
            </StyledHeaderTitle>
          ) : null
        }
        subTitleJSX={
          <StyledHeaderSubTitle statusColor={color}>
            Bid Status - <span>{label}</span>
          </StyledHeaderSubTitle>
        }
      />
    </StyledHeaderWrapper>
  );
};

export default StatusHeader;
