import styled from 'styled-components';
import './index.scss';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 240px;
  border-radius: 100%;
  background: transparent;
  position: relative;
`;

const Circle = styled.div`
  background: linear-gradient(125.44deg, #81c1f5 23.05%, #156398 105.86%);
  border-radius: 100%;
  width: 200px;
  height: 200px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 108px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const AnimationDiv = styled.div`
  animation: pulsate infinite 1s;
  width: 200px;
  height: 200px;
  content: '';
  border: 40px solid rgba(129, 193, 245, 0.5);
  border-radius: 100%;
  position: absolute;
  top: 20px;
  left: 20px;
`;

const AnimationDiv2 = styled.div`
  animation: pulsate2 infinite 1s;
  width: 200px;
  height: 200px;
  content: '';
  border: 20px solid rgba(21, 99, 152, 0.3);
  border-radius: 100%;
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const CountdownNumber = () => {
  return (
    <Container>
      <AnimationDiv />
      <AnimationDiv2 />
      <Circle>
        <span className="countdown-image">5</span>
        <span className="countdown-image">4</span>
        <span className="countdown-image">3</span>
        <span className="countdown-image">2</span>
        <span className="countdown-image">1</span>
      </Circle>
    </Container>
  );
};
