import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import ReactGA from 'react-ga';
import { MuiThemeProvider, createGenerateClassName, jssPreset, createMuiTheme } from '@material-ui/core/styles';
import App from './App';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-141030211-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#005a96'
    },
    secondary: {
      main: '#424242'
    }
  },
  typography: {
    body1: {
      color: '#616161',
      fontSize: '16px'
    },
    display1: {
      color: '#212121',
      fontWeight: 500,
      fontSize: '18px'
    },
    caption: {
      color: '#616161',
      fontWeight: 500
    }
  }
});

const Root = ({ store, history }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </JssProvider>
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
