import React from 'react';
import { getHighestBid } from '../../../../utils/vehicle';
import { ModalWrapper } from '../../../../components';
import { Vehicle } from '../../../../types/vehicle';
import { useDispatch } from 'react-redux';
import { modalsToggle, sellerAcceptBid } from '../../../../actions';
import { Typography } from '@material-ui/core';
import { formatCurrency } from '../../../../utils';

type Props = {
  vehicle: Vehicle;
};

export const AcceptHighBidModal = ({ vehicle }: Props) => {
  const highestBid = getHighestBid(vehicle);
  const dispatch = useDispatch();

  const bidderName = highestBid.bidder?.dealership_name ?? '';

  return (
    <ModalWrapper
      handleSubmit={() => {
        dispatch(sellerAcceptBid({ vehicle_id: vehicle.id }).request);
        // dispatch(modalsToggle('acceptCounterModal'));
      }}
      modalId={`acceptCounterModal-${vehicle.id}`}
    >
      <Typography variant="title">
        {`Are You sure you want to accept the highest bid of ${formatCurrency(highestBid.amount)} from ${bidderName}?`}
      </Typography>
    </ModalWrapper>
  );
};
