import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { BarCodeIcon } from '../../../icons/BarCodeIcon';
import { StatusLabel } from './Status';
import { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const LeftPart = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: baseline;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
`;

const VinRow = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  line-height: 14px;
  color: rgba(153, 153, 153, 1);
`;

const VinValue = styled.span`
  font-weight: 400;
  margin-right: 2px;
`;

const BarCodeIconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const BarCodeImageWrapper = styled.div`
  padding: 5px 11px;
  border: 1px solid rgba(153, 153, 153, 1);
  border-radius: 20px;
  position: absolute;
  top: -30px;
  background: #fff;
  right: -350px;
`;

export const VehicleInfoHeader = () => {
  const vehicle = useViewedVehicle();
  const [barcodeHovered, setBarcodeHovered] = useState(false);

  if (!vehicle) return null;

  return (
    <Wrapper>
      <LeftPart>
        <Title>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Title>
        <VinRow>
          <span>VIN: </span>
          <VinValue>{vehicle.vin}</VinValue>
          <BarCodeIconWrapper onMouseOver={() => setBarcodeHovered(true)} onMouseOut={() => setBarcodeHovered(false)}>
            <BarCodeIcon />
            {barcodeHovered && vehicle.vin_code_url && (
              <BarCodeImageWrapper>
                <img src={vehicle.vin_code_url} alt="vincode" />
              </BarCodeImageWrapper>
            )}
          </BarCodeIconWrapper>
        </VinRow>
      </LeftPart>
      <StatusLabel />
    </Wrapper>
  );
};
