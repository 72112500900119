import { ButtonBase, Icon } from '@material-ui/core';
import { PageHeader } from '../../../../components';
import CounterTabs from './CounterTabs';
import { useState } from 'react';
import { useScreen } from 'usehooks-ts';
import { ExpandedMenuItem, MenuItem } from 'components/Icons/MenuIcon';
import { MobileDashboardFilters } from './MobileFilters';
import { Line, MainContentWrapper, Wrapper } from 'components/HeaderUI';

const Header = () => {
  const [expanded, setIsExpanded] = useState(false);
  const { width } = useScreen();
  const isMobile = width < 567;

  const toggleExpanded = () => setIsExpanded(!expanded);

  return (
    <>
      <div style={{ height: '76px' }} />
      <Wrapper withBottomMargin isMobileExpanded={expanded && isMobile}>
        <MainContentWrapper>
          <CounterTabs />
          <span className="flex-grow" />
          <ButtonBase onClick={() => {}}>
            <Icon>refresh</Icon>
          </ButtonBase>
          {isMobile && (
            <ButtonBase onClick={toggleExpanded}>{expanded ? <ExpandedMenuItem /> : <MenuItem />}</ButtonBase>
          )}
        </MainContentWrapper>
        {isMobile && expanded && <Line />}
        {isMobile && expanded && <MobileDashboardFilters />}
      </Wrapper>
    </>
  );
};

export default Header;
