import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../../components/ModalWrapper';
import InvoiceDetail from '../../../../../components/InvoiceDetail';

const InvoiceByUploadedVehiclesModal = ({
  seller,
  invoiceData,
  invoiceDate,
  canAddRecord,
  handleSubmit,
  loading,
  ...props
}) => (
  <ModalWrapper
    title="Invoice By Unique VIN's"
    showTitle={false}
    handleSubmit={handleSubmit}
    modalId="InvoiceByUploadedVehiclesModal"
    submitText="Generate Invoice"
    showSubmit
    submitting={loading}
  >
    <InvoiceDetail
      sellerData={seller}
      invoiceData={invoiceData}
      invoiceDate={invoiceDate}
      canAddRecord={canAddRecord}
      {...props}
    />
  </ModalWrapper>
);

InvoiceByUploadedVehiclesModal.propTypes = {
  canAddRecord: PropTypes.bool,
  handleSubmit: PropTypes.func
};
InvoiceByUploadedVehiclesModal.defaultProps = {
  canAddRecord: false,
  handleSubmit: () => {}
};

const mapStateToProps = state => ({
  seller: state.financials.currentSeller,
  invoiceData: state.financials.currentInvoice,
  invoiceDate: state.financials.currentInvoiceDate,
  loading: state.financials.loading
});

export default connect(mapStateToProps)(InvoiceByUploadedVehiclesModal);
