import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  Button,
  Typography
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';
import { FormTextField, FormDatePicker } from '../Form';
import { DATE_FORMAT } from '../../constants';
import { createSingleInvoiceRecord } from '../../actions';
import { required as validateRequired } from '../../utils/validators';

import logoImg from '../../img/logo.svg';

const StyledGridHolder = styled(Grid)`
  width: 550px;
`;

const GutterGrid = styled(Grid)`
  padding: 20px 0;
`;

const StyledTableCell = styled(TableCell)`
  padding: 4px 10px 4px 10px;
`;

const StyledDateField = styled(Field)`
  max-width: 135px;
`;

const StyledTypographyWithRightPadding = styled(Typography)`
  padding-right: 5px;
`;

const formName = 'invoiceDetailForm';

class InvoiceDetail extends Component {
  componentDidMount() {}

  createRecord = values => {
    const { createInvoice, sellerData } = this.props;
    createInvoice({
      ...values,
      date: moment(values.date)
        .local()
        .format(),
      user_id: sellerData.id,
      type: 'custom'
    });
  };

  render() {
    const {
      handleSubmit,
      sellerData,
      invoiceData,
      invoiceDate,
      submitting,
      canAddRecord
    } = this.props;
    const total = (
      <NumberFormat
        value={invoiceData.reduce((sum, item) => +sum + +item.amount, 0)}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    );
    return (
      <StyledGridHolder container alignItems='center'>
        <Grid item xs={6}>
          <img src={logoImg} alt="autoaxess logo" />
          <Typography variant="body1">486 South K Street</Typography>
          <Typography variant="body1">Livermore, CA, 94550</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" gutterBottom className="text-right">
            <b>INVOICE</b>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className="text-right"
          >
            {`${invoiceDate}-${sellerData.id}`}
          </Typography>
          <Grid container alignItems='center' justify='flex-end'>
            <StyledTypographyWithRightPadding
              variant="body1"
              className="text-right"
            >
              {`Date: `}
            </StyledTypographyWithRightPadding>
            <StyledDateField
              component={FormDatePicker}
              name="invoice_date"
              placeholder="Date"
              dateFormat='MMM DD, YYYY'
            />
          </Grid>
        </Grid>
        <GutterGrid item xs={12} />
        <Grid item xs={6}>
          <Typography variant="body1" gutterBottom>
            Bill to:
          </Typography>
          <Typography variant="body2" gutterBottom>
            <b>{sellerData.dealership_name}</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" gutterBottom className="text-right">
            Due Date:
          </Typography>
          <Typography variant="body2" gutterBottom className="text-right">
            Balance Due:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" gutterBottom className="text-right">
            <StyledDateField
              component={FormDatePicker}
              name="due_date"
              placeholder="Due Date"
              dateFormat='MMM DD, YYYY'
            />
          </Typography>
          <Typography variant="body2" gutterBottom className="text-right">
            <b>{total}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell className="text-right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.map((item, i) => (
                <TableRow key={i}>
                  <StyledTableCell>
                    {moment.utc(item.date).format(DATE_FORMAT)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.description}
                    {item.vehicle && (
                      <>
                        <br />
                        VIN: {item.vehicle.vin}
                      </>
                    )}
                    {item.vehicle && item.vehicle.awarded_user && (
                      <>
                        <br />
                        Buyer: {item.vehicle.awarded_user.dealership_name}
                      </>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className="text-right">
                    <NumberFormat
                      value={item.amount}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
              {canAddRecord && (
                <TableRow>
                  <StyledTableCell>
                    <Field
                      component={FormDatePicker}
                      name="date"
                      placeholder="Date"
                      validate={[validateRequired]}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Field
                      component={FormTextField}
                      name="description"
                      placeholder="Description"
                      fullWidth
                      validate={[validateRequired]}
                    />
                  </StyledTableCell>
                  <StyledTableCell className="text-right">
                    <Field
                      component={FormTextField}
                      name="amount"
                      placeholder="Amount"
                      type="number"
                      fullWidth
                      validate={[validateRequired]}
                    />
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
            {canAddRecord && (
              <TableFooter>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell numeric>
                    <Button
                      size="small"
                      variant="outlined"
                      disabled={submitting}
                      onClick={handleSubmit(this.createRecord)}
                    >
                      Add
                    </Button>
                  </StyledTableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Grid>
        <GutterGrid item xs={12} />
        <Grid item xs={6} />
        <Grid item xs={3}>
          <Typography variant="body1" gutterBottom className="text-right">
            Subtotal:
          </Typography>
          <Typography variant="body2" gutterBottom className="text-right">
            Total:
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" gutterBottom className="text-right">
            {total}
          </Typography>
          <Typography variant="body2" gutterBottom className="text-right">
            <b>{total}</b>
          </Typography>
        </Grid>
      </StyledGridHolder>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createInvoice: data => dispatch(createSingleInvoiceRecord(data).request)
});

export default reduxForm({
  form: formName,
})(
  connect(
    null,
    mapDispatchToProps
  )(InvoiceDetail)
);
