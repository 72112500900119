import React from 'react';
import ModalWrapper from '../ModalWrapper';

const ReUploadModal = ({submitText, newText, title, handleSubmit, handlePressNew, modalId}) => (
  <ModalWrapper
    title={title}
    handleSubmit={handleSubmit}
    handlePressNew={handlePressNew}
    modalId={modalId}
    submitText={submitText}
    newText={newText}
    showNew={!!newText}
  />
);

export default ReUploadModal;
