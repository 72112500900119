import React from 'react';
import { ModalWrapper } from '../../../../components';
import TermsPage from "../../../Shared/TermsPage";

const termsAndConditionsModalId = 'termsAndConditionsModal';

const TermsAndConditionsModal  = () => (
  <ModalWrapper modalId={termsAndConditionsModalId} title="Terms & Conditions" cancelText="Close" showSubmit={false}>
    <TermsPage modalReady />
  </ModalWrapper>
);

export default TermsAndConditionsModal;
