import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CircularProgress, Icon, Typography, IconButton } from '@material-ui/core';
import { deleteVehicleImage, uploadVehicleImage } from '../../../../actions/vehicles';

const StyledLabel = styled.label`
  box-shadow: none;
  width: 100%;
  background: #dcdcdc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;
  align-items: center;
  padding: 16px;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledImageBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 230px;
  padding: 8px;
  background: url('${props => props.imageUrl}') no-repeat;
  background-position: center center;
  background-size: cover;
`;

const StyledTypography = styled(Typography)`
  text-transform: none;
`;

const StyledIconButton = styled(IconButton)`
  z-index: 1;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
`;

const StyledLoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

class PictureUploadComponent extends Component {
  state = { file: null };

  uploadFile = ({ target }) => {
    const [file] = target.files;
    this.setState({ file });
    const { itemKey, updateImg, vehicleId } = this.props;
    updateImg({
      key: itemKey,
      file,
      image_id: null,
      loading: true,
      imageUrl: URL.createObjectURL(file),
      vehicleId
    });
  };

  reUpload = () => {
    const { itemKey, updateImg, vehicleId } = this.props;
    const { file } = this.state;
    updateImg({
      key: itemKey,
      file,
      image_id: null,
      loading: true,
      imageUrl: URL.createObjectURL(file),
      vehicleId
    });
  };

  render() {
    const { image, label, deleteImage } = this.props;
    return image && (image.imageUrl || image.loading) ? (
      <StyledImageBlock imageUrl={image.imageUrl}>
        <StyledIconButton onClick={() => deleteImage(image.key)}>
          <Icon>delete</Icon>
        </StyledIconButton>
        {image.loading && (
          <StyledLoaderWrapper>
            <CircularProgress size={50} thickness={5} />
          </StyledLoaderWrapper>
        )}
        {image.failed && (
          <StyledLoaderWrapper>
            <StyledIconButton onClick={this.reUpload}>
              <Icon>refresh</Icon>
            </StyledIconButton>
          </StyledLoaderWrapper>
        )}
      </StyledImageBlock>
    ) : (
      <StyledLabel>
        <span />
        <i className="material-icons">add_photo_alternate</i>
        <StyledTypography>{label}</StyledTypography>
        <StyledInput
          type="file"
          accept="image/jpeg"
          multiple
          onClick={event => {
            // eslint-disable-next-line
            event.target.value = null;
          }}
          onChange={this.uploadFile}
        />
      </StyledLabel>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateImg: data => dispatch(uploadVehicleImage(data).request),
  deleteImage: key => dispatch(deleteVehicleImage({ key }))
});

export default connect(null, mapDispatchToProps)(PictureUploadComponent);
