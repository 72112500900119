import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type MySalesState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
}

const initialState: MySalesState = {
  page: 1,
  perPage: 48,
  filters: {}
};

const sellerVehiclesSlice = createSlice({
  name: 'sellerVehicles',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      setStatusFilter(state, action: PayloadAction<string>) {
        if (action.payload === '') { 
          delete state.filters.status
        } else {
          state.filters.status = action.payload
        }
        
        state.page = 1
      },
      resetFilters(state) {
        state.page = 1
        state.perPage = 24
        state.filters = {}
      }
    }
});

export const { setPage, setStatusFilter, resetFilters } = sellerVehiclesSlice.actions
export default sellerVehiclesSlice;
