import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { AddressSectionForm } from '../forms';

const AlternativeAddressSection = ({ resetForm, initialValues, submitForm }) => {
  const [isEditable, setIsEditable] = React.useState();

  const toggleEdit = () => setIsEditable(curr => !curr);
  const onCancel = () => {
    resetForm();
    toggleEdit();
  };

  const InputProps = isEditable ? {} : { disableUnderline: true, disabled: true };

  return (
    <SectionBlock
      title="Alternative Address"
      isEditable={isEditable}
      onCancel={onCancel}
      toggleEdit={toggleEdit}
      submit={submitForm}
    >
      <AddressSectionForm
        name="alternativeAddressSectionForm"
        InputProps={InputProps}
        initialValues={initialValues}
        onSubmitSuccess={toggleEdit}
      />
    </SectionBlock>
  );
};

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('alternativeAddressSectionForm')),
  resetForm: () => dispatch(reset('alternativeAddressSectionForm'))
});

export default connect(null, mapDispatchToProps)(AlternativeAddressSection);
