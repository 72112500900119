import React from 'react';
import styled from 'styled-components';
import {
  EASYPAY_PAYMENT_STATUSES_FORMAT,
  EASYPAY_PAYMENT_TYPES_FORMAT,
  FLOORING_FORM_COMPANIES_NAMES,
  PAYMENT_METHODS_STATUSES,
  COLOR_SUCCESSFUL,
  COLOR_PENDING,
  COLOR_ERROR,
} from '../../constants';
import { numberFormatString } from '../../utils';

const StyledStatus = styled.p`
  color: ${props => props.statusColor || '#000'};,
  p {
    margin: 2.5px 0;
  }
`;

const StyledDescription = styled.div`
  color: #757575;
  p {
    margin: 2.5px 0;
  }
`;

const EASYPAY_PAYMENT_STATUS_COLORS = {
  [PAYMENT_METHODS_STATUSES.APPROVED]: COLOR_SUCCESSFUL,
  [PAYMENT_METHODS_STATUSES.PENDING]: COLOR_PENDING,
  [PAYMENT_METHODS_STATUSES.DECLINED]: COLOR_ERROR
};

const EasyPayStatus = ({ statusHidden = false, amountHidden = false, vehicle }) => {
  let paymentType = EASYPAY_PAYMENT_TYPES_FORMAT[vehicle.payment_type] || 'N/A';
  const paymentDescriptionColor = EASYPAY_PAYMENT_STATUS_COLORS[vehicle.payment_status];
  const paymentStatus = vehicle.payment_status !== PAYMENT_METHODS_STATUSES.APPROVED ? EASYPAY_PAYMENT_STATUSES_FORMAT[vehicle.payment_status] : null;

  if (vehicle.payment_status !== PAYMENT_METHODS_STATUSES.DECLINED) {
    const paymentCompany = vehicle.payment_company ? `(${FLOORING_FORM_COMPANIES_NAMES[vehicle.payment_company]})` : '';
    paymentType = `${paymentType} ${paymentCompany}`;
  }

  return (
    <>
      {!statusHidden && (
        <StyledStatus statusColor="#009F0F">Awarded</StyledStatus>
      )}
      <StyledDescription>
        {!amountHidden && (
          <p>{`(${numberFormatString(vehicle.vehicle_price || 0)})`}</p>
        )}
        {!!(vehicle.buy_fee && vehicle.buy_fee.pending_bank_flooring_approval) &&
          <StyledStatus statusColor={COLOR_ERROR}>
            {paymentType}
            <br />
            {'Pending Bank/Flooring Approval'}
          </StyledStatus>
        }
        {!(vehicle.buy_fee && vehicle.buy_fee.pending_bank_flooring_approval) &&
          <StyledStatus statusColor={paymentDescriptionColor}>
            {paymentType}
            {paymentStatus ? ` - ${paymentStatus}` : ''}
          </StyledStatus>
        }
      </StyledDescription>
    </>
  );
};

export default EasyPayStatus;
