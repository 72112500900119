import { useSelector } from 'react-redux';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { Vehicle } from '../../../../types/vehicle';

export const useSalesHistoryOfferAuction = (vehicleId: number) => {
  const bids = useSelector((state: any) => state.vehicles.salesHistoryData.rows ?? []);
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: bids.map((el: Vehicle) => el.id) ?? [] },
    {
      skip: bids.length === 0
    }
  );

  const offerAuction = offerAuctions?.entities?.[vehicleId] ?? null;
  return offerAuction
};
