import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const StyledVehicleTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
`;

const StyledVehicleDescription = styled(Typography)`
  color: #757575;
  white-space: nowrap;
  font-size: 12px;
`;

const VehicleOptions = ({ currentVehicle }) => (
  <div>
    <StyledVehicleTitle>{`${currentVehicle.year} ${currentVehicle.make} ${currentVehicle.model}`}</StyledVehicleTitle>
    <StyledVehicleDescription>
      VIN
      {` ${currentVehicle.vin}`}
    </StyledVehicleDescription>
    <div>
      <StyledVehicleDescription>
        {`Value Without Miles Adj: `}
        <NumberFormat value={currentVehicle.bluebook_value} prefix="$" thousandSeparator displayType="text" />
      </StyledVehicleDescription>
      <StyledVehicleDescription>
        {` Blue Book® Lending Value: `}
        <NumberFormat value={currentVehicle.bluebook_mileage} prefix="$" thousandSeparator displayType="text" />
      </StyledVehicleDescription>
      <StyledVehicleDescription>
        {` Mileage: `}
        <NumberFormat value={currentVehicle.mileage} thousandSeparator displayType="text" />
      </StyledVehicleDescription>
    </div>
  </div>
);

export default VehicleOptions;
