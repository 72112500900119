import React from "react";
import styled from "styled-components";
import { AUTSlider, PageWrapper } from "../../../components";
import image1 from "../../../img/partner-wall/image 4.png";
import image2 from "../../../img/partner-wall/image 5.png";
import image3 from "../../../img/partner-wall/image 7.png";
import image4 from "../../../img/partner-wall/image 8.png";
import image5 from "../../../img/partner-wall/image 9.png";
import image6 from "../../../img/partner-wall/image 10.png";
import image7 from "../../../img/partner-wall/image 11.png";

const carsModalList = [
  {
    url: image1
  },
  {
    url: image2
  },
  {
    url: image3
  },
  {
    url: image4
  },
  {
    url: image5
  },
  {
    url: image6
  },
  {
    url: image7
  },
];

const PartnersSectionHolder = styled.section`
  padding: 58px 0 63px;

  @media only screen and (min-width: 1026px) {
    padding: 135px 0 40px;
  }
`;

const CarsModalsListItem = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 108px;

  img {
    transform: scale(0.35);
    width: auto !important;
  }
  
  @media only screen and (min-width: 1026px) {
    img {
      transform: scale(0.5);
    }
  }
`;

const SectionTitle = styled.h2`
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  @media only screen and (min-width: 1026px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const SectionDescription = styled.p`
  margin: 0 auto 24px;
  max-width: 542px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #54607D;

  @media only screen and (min-width: 1026px) {
    margin: 0 auto 60px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const PartnersSection = () => (
  <PartnersSectionHolder>
    <PageWrapper>
      <SectionTitle>Some of Our Customers</SectionTitle>
      <SectionDescription>Below is a small sampling of dealer groups wholesaling regularly on AutoAxess.</SectionDescription>
      <div className="partners">
        <AUTSlider
          settings={{
            responsive: [
              {
                breakpoint: 1026,
                settings: {
                  slidesToShow: 4,
                  autoplay: true
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  autoplay: true
                }
              }
            ],
            arrows: false,
            dots: false,
            draggable: false,
            slidesToShow: 7,
            slidesToScroll: 1,
          }}
        >
          {
            carsModalList.map(item => (
              <CarsModalsListItem key={item.url}>
                <img src={item.url} alt={item.url} />
              </CarsModalsListItem>
            ))
          }
        </AUTSlider>
      </div>
    </PageWrapper>
  </PartnersSectionHolder>
);

export default PartnersSection;
