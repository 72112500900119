import styled from 'styled-components';
import { DollarIcon, GasStationIcon, MoneyIcon } from './icons';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { formatCurrency } from '../../../../../../utils';

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const InfoLabel = styled.div<{ color: string }>`
  height: 130px;
  flex: 1 1 100%;
  border-radius: 30px;
  background: ${props => props.color};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 18px;
`;

const Value = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
  line-height: 18.75px;
`;

const Label = styled.span`
  font-size: 10px;
  margin-top: 4px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  max-width: 80px;
`;

const fieldMap = {
  mileage: {
    Icon: GasStationIcon,
    label: 'Mileage',
    color: 'rgba(57, 148, 222, 0.2)',
    getValue: (v: number) => (v ? formatCurrency(v).slice(1) : 'N/A')
  },
  bluebook_mileage: {
    Icon: MoneyIcon,
    label: 'Blue Book@ Lending Value',
    color: 'rgba(253, 183, 20, 0.2)',
    getValue: (v: number) => (v ? formatCurrency(v) : 'N/A')
  },
  bluebook_value: {
    Icon: DollarIcon,
    label: 'Value Without Miles Adj',
    color: 'rgba(21, 182, 132, 0.2)',
    getValue: (v: number) => (v ? formatCurrency(v) : 'N/A')
  }
};

export const BlueBookInfo = () => {
  const fields: (keyof typeof fieldMap)[] = ['bluebook_value', 'bluebook_mileage', 'mileage'];

  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  return (
    <Wrapper>
      {fields.map(field => {
        const { Icon, label, color, getValue } = fieldMap[field];
        const value = getValue(vehicle[field]);
        return (
          <InfoLabel color={color} key={field}>
            <Icon />
            <Value>{value}</Value>
            <Label>{label}</Label>
          </InfoLabel>
        );
      })}
    </Wrapper>
  );
};
