import React from 'react';
import { Field } from 'redux-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import InlineFormRadioGroup from '../../../Auth/Registration/components/InlineFormRadioGroup';
import { required } from '../../../../utils/validators';
import { FINALIZE_BUY_FEE_METHODS } from "../../../../constants";

const StyledRadio = <Radio color="primary" style={{padding: 5}} />

const FinalizeFeePaymentMethodPicker = ({hasAch, hasCreditCard}) => (
  <Field
    name="finalize_buy_fee_method"
    component={InlineFormRadioGroup}
    row
    validate={required}
  >
    <FormControlLabel
      control={StyledRadio}
      value={FINALIZE_BUY_FEE_METHODS.ACH}
      label="ACH"
      disabled={!hasAch}
    />
    <FormControlLabel
      control={StyledRadio}
      value={FINALIZE_BUY_FEE_METHODS.CREDIT_CARD}
      label="Credit Card"
      disabled={!hasCreditCard}
    />
  </Field>
);

export default FinalizeFeePaymentMethodPicker;
