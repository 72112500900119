import React, { Component } from 'react';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { CardSectionForm } from '../../pages/Shared/Profile/forms';

const StyledGrid = styled(Grid)`
  form {
    flex: 1 1 auto;
    margin: 5px 17.5px 0 0;
  }
  button {
    margin-top: 10px;
  }
`;

class UpdateCreditCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false
    };
  }

  toggleEditable = () => {
    const { isEditable } = this.state;
    this.setState({ isEditable: !isEditable });
  };

  render() {
    const { user, submitForm } = this.props;
    const { isEditable } = this.state;
    const extendedInitialValues = {
      credit_card_last_numbers: `**** **** **** ${
        user.buyer.credit_card_last_numbers
      }`
    };

    return (
      <ModalWrapper
        title="You are disabled! Please update your credit card."
        handleSubmit={submitForm}
        modalId="updateCreditCardModal"
        submitting={!isEditable}
      >
        <StyledGrid container justify="space-between">
          <CardSectionForm
            initialValues={extendedInitialValues}
            isDisabled={!isEditable}
          />
          {!isEditable && (
            <Button color="primary" onClick={this.toggleEditable}>
              edit
            </Button>
          )}
        </StyledGrid>
      </ModalWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('creditCardSectionForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(UpdateCreditCardModal);
