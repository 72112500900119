import { useGetSellersQuery } from 'services/api/list-vehicles';
import styled from 'styled-components';
import Select from 'react-select';
import { FieldRenderProps } from 'react-final-form';
import { useState } from 'react';

const RoundedLabel = styled.div<{ selectOpen: boolean }>`
  color: #757575;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 8px 12px;
  min-width: ${props => (props.selectOpen ? '150px' : 'none')};
  max-width: ${props => (props.selectOpen ? '100%' : 'fit-content')};
  font-size: 10px;
  font-weight: 400;
  position: relative;
  cursor: ${props => (props.selectOpen ? 'default' : 'pointer')};
`;

const StyledSelect = styled(Select)`
  & > div {
    background: #f5f5f5;
  }
  & .vehicle-pool-seller-select__control {
    border: none;
    border-radius: 0;
  }

  & .vehicle-pool-seller-select__control:hover {
    border: none;
    border-radius: 0;
  }

  & .vehicle-pool-seller-select__control--is-focused {
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  & .vehicle-pool-seller-select__dropdown-indicator {
    padding: 0;
    display: flex;
    align-items: center;
  }

  & .vehicle-pool-seller-select__control {
    min-height: unset;
  }

  & .vehicle-pool-seller-select__menu-list {
    padding: 0;
  }

  & .vehicle-pool-seller-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .vehicle-pool-seller-select__indicators,
  .vehicle-pool-seller-select__indicator {
    width: 12px;
    height: 12px;
  }

  & .vehicle-pool-seller-select__indicator-separator {
    display: none;
  }

  & .vehicle-pool-seller-select__dropdown-indicator,
  .vehicle-pool-seller-select__dropdown-indicator:hover {
    color: hsl(0, 0%, 40%);
  }

  & .vehicle-pool-seller-select__dropdown-indicator {
    color: hsl(0, 0%, 40%);
  }

  & .vehicle-pool-seller-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }

  & .vehicle-pool-seller-select__value-container {
    padding: 0;
    height: 12px;
  }

  & .vehicle-pool-seller-select__single-value {
  }

  & .vehicle-pool-seller-select__option {
    background: #fff;
    color: #000;
  }

  & .vehicle-pool-seller-select__placeholder {
    top: 50%;
  }
`;

export const CreateSellerButton = (props: FieldRenderProps<any, HTMLElement, any>) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const { data: sellers } = useGetSellersQuery();

  const sellerRequestsOptions = sellers?.map(el => ({
    value: String(el.id),
    label: el.dealership_name
  }));

  const openSelect = () => {
    if (selectOpen) return;
    setSelectOpen(true);
  };

  const handleSelect = (v: any) => {
    console.log(v, 'handle select');
    if (!v || (Array.isArray(v) && v.length === 0)) return;
    console.log(props.input.value, 'onchange');
    props.input.onChange([
      ...(props.input.value ?? []),
      {
        user_id: Number(v.value),
        dealership_name: v.label
      }
    ]);
    setSelectOpen(false);
  };

  return (
    <RoundedLabel selectOpen={selectOpen} onClick={openSelect}>
      {!selectOpen ? (
        <span>+</span>
      ) : (
        <StyledSelect
          placeholder=""
          classNamePrefix="vehicle-pool-seller-select"
          onChange={handleSelect}
          options={sellerRequestsOptions}
        />
      )}
    </RoundedLabel>
  );
};
