import React from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import ModalWrapper from '../ModalWrapper';
import { updateAdminNotes } from '../../actions/vehicles';
import FormTextField from '../Form/FormTextField';
import { maxLength, validateMinBid, validateReservePrice } from '../../utils/validators';
import { normalizeFormattedField } from '../../utils';
import { FormFormattedField } from '../Form';

const maxNotesLength = maxLength(50);

const StyledFieldRow = styled.div`
  display: flex;
  margin: 15px 0;
  gap: 8px;
  & > div {
    flex: 1 1 100%;
  }
`;

const NotesModal = ({ modalId, resetForm, handleSubmit, vehicle }) => (
  <ModalWrapper
    title="Notes & Reserve"
    modalId={modalId}
    submitText="Save"
    className="notes-dialog"
    cancelAdditionalAction={() => {
      resetForm('NotesModal');
    }}
    handleSubmit={handleSubmit}
    onClose={() => {
      resetForm('NotesModal');
    }}
  >
    <StyledFieldRow>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Admin notes</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="adminNotes"
            component={FormTextField}
            margin="dense"
            placeholder="Admin notes"
            type="text"
            fullWidth
            validate={maxNotesLength}
          />
        </Grid>
      </Grid>
    </StyledFieldRow>
    <StyledFieldRow>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Reserve Price</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="reserve_price"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Reserve price"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
            validate={validateReservePrice}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Starting Bid</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="starting_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Starting bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
            validate={validateMinBid}
            disabled={!!vehicle.highest_bid}
          />
        </Grid>
      </Grid>
    </StyledFieldRow>
    <StyledFieldRow>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Open Lane</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="open_lane_opening_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Opening Bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="open_lane_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="open_lane_sold"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Sold"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">ACV</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="acv_opening_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Opening Bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="acv_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="acv_sold"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Sold"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Other</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="other_opening_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Opening bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="other_bid"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Bid"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="other_sold"
            component={FormFormattedField}
            thousandSeparator
            allowNegative={false}
            fixedDecimalScale
            prefix="$"
            margin="dense"
            placeholder="Sold"
            type="text"
            fullWidth
            normalize={normalizeFormattedField}
          />
        </Grid>
      </Grid>
    </StyledFieldRow>
  </ModalWrapper>
);

export default reduxForm({
  form: 'NotesModal',
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    dispatch(
      updateAdminNotes({
        id: props.vehicle.id,
        admin_notes: values.adminNotes,
        reserve_price: values.reserve_price || null,
        starting_bid: values.starting_bid || 100,
        acv_offers: {
          opening_bid: Number(values.acv_opening_bid) || null,
          bid: Number(values.acv_bid) || null,
          sold: Number(values.acv_sold) || null
        },
        other_offers: {
          opening_bid: Number(values.other_opening_bid) || null,
          bid: Number(values.other_bid) || null,
          sold: Number(values.other_sold) || null
        },
        open_lane_offers: {
          opening_bid: Number(values.open_lane_opening_bid) || null,
          bid: Number(values.open_lane_bid) || null,
          sold: Number(values.open_lane_sold) || null
        }
      }).request
    );
    props.resetForm('NotesModal');
  }
})(NotesModal);
