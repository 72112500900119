import React from 'react';
import { connect } from 'react-redux';
import { Divider } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import StatusComponent from './StatusComponent';
import CounteredComponent from './CounteredComponent';
import { VEHICLE_STATUSES, AUCTION_STATUSES } from '../../../../../constants';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const BuyerRestrictionSection = ({ currentVehicle }) => {
  return (
    <>
      <StatusComponent vehicle={currentVehicle} />
      <StyledDivider />
    </>
  );
};

export default connect(state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
}))(BuyerRestrictionSection);
