import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import { Vehicle } from '../../../../types/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle, pullVehicle } from '../../../../actions';
import { openRelistDialog } from '../../../../services/auctionVehicles/auctionVehiclesSlice';
import { useExtendVehicleTimeMutation } from '../../../../services/api/admin-auction-vehicles';

type Props = {
  vehicle: Vehicle;
};

export const ExtendVehicleModal = ({ vehicle }: Props) => {
  // const dispatch = useDispatch();

  const [extendVehicleTime] = useExtendVehicleTimeMutation();
  const handleExtend = async () => {
    await extendVehicleTime({ vehicleId: vehicle.id })
  };

  return (
    <ConfirmModal
      modalId={`extendVehicleTime-${vehicle.id}`}
      title={`Are you sure you want to extend the vehicle’s time?`}
      submitText={'Extend'}
      handleSubmit={handleExtend}
    />
  );
};
