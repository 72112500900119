export const AutoaxessLogo = () => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>logo</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <radialGradient
        cx="53.2851577%"
        cy="45.7253196%"
        fx="53.2851577%"
        fy="45.7253196%"
        r="140.479621%"
        gradientTransform="translate(0.532852,0.457253),scale(1.000000,0.999867),rotate(90.000000),translate(-0.532852,-0.457253)"
        id="radialGradient-1"
      >
        <stop stop-color="#3B92CC" offset="0%"></stop>
        <stop stop-color="#005A96" offset="100%"></stop>
      </radialGradient>
    </defs>
    <g id="Designs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="1_1_1-1440-Index-Grid_view-3" transform="translate(-24.000000, -32.000000)">
        <g id="Header">
          <g id="logo" transform="translate(24.000000, 32.000000)">
            <g id="mark" fill="url(#radialGradient-1)">
              <path
                d="M13.3233111,7.92020574 C13.3233111,7.92020574 20.0295309,2.74256195 25.1735527,1.76992745 C30.3175744,0.797292937 28.0551952,6.31955639 26.6143739,8.5973857 C25.1735527,10.875215 16.2408836,23.3114395 8.16570244,28.0454376 C0.0905212815,32.7794358 -0.811954564,25.872664 1.67536428,21.3000759 C1.67536428,21.3000759 -2.45144732,27.7145007 2.24873384,31.0818477 C6.948915,34.4491946 14.9289875,27.4890833 17.6430575,24.9642688 C20.3571276,22.4394543 35.0751227,7.29451001 31.3918498,1.50578088 C25.8317652,-4.14287404 13.3233111,7.92020574 13.3233111,7.92020574 Z"
                id="Path-4"
              ></path>
              <path
                d="M13.3233111,7.92020574 C13.3233111,7.92020574 20.0295309,2.74256195 25.1735527,1.76992745 C30.3175744,0.797292937 28.0551952,6.31955639 26.6143739,8.5973857 C25.1735527,10.875215 16.2408836,23.3114395 8.16570244,28.0454376 C0.0905212815,32.7794358 -0.811954564,25.872664 1.67536428,21.3000759 C1.67536428,21.3000759 -2.45144732,27.7145007 2.24873384,31.0818477 C6.948915,34.4491946 14.9289875,27.4890833 17.6430575,24.9642688 C20.3571276,22.4394543 35.0751227,7.29451001 31.3918498,1.50578088 C25.8317652,-4.14287404 13.3233111,7.92020574 13.3233111,7.92020574 Z"
                id="Path-4-Copy"
                transform="translate(15.989917, 15.992044) scale(1, -1) translate(-15.989917, -15.992044) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
