import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledText = styled.span<{ maxWidth?: number }>`
  font-weight: 500;
  font-size: 12px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
`;

export const StyledLabel = styled(Typography)<{ isBold?: boolean }>`
  color: ${({ isBold }) => (isBold ? '#000' : '#757575')};
  white-space: nowrap;
  font-size: 12px;
  font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
`;

export const Wrapper = styled.div<{ justifyBetween?: boolean }>`
  display: flex;
  gap: 2px;
  min-width: 100px;
  align-items: center;
  justify-content: ${({ justifyBetween }) => (justifyBetween ? 'space-between' : 'flex-start')};

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
