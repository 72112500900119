import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  SEND_CONTACT_FORM_REQUEST,
  SEND_CONTACT_FORM_SUCCESS,
  SEND_CONTACT_FORM_FAILURE,
  SEND_QUESTION_FORM_REQUEST,
  SEND_QUESTION_FORM_SUCCESS,
  SEND_QUESTION_FORM_FAILURE,
  LIST_BIDS_SUCCESS,
  WS_BIDS_NEW_BID
} from '../constants/actionTypes';
import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_DOC_SUCCESS, DETAIL_PROFILE_SUCCESS } from '../actions/user';
import { LOGIN_AS_USER_SUCCESS } from '../actions/users';
import { getToken, getUser } from '../utils/auth';

const initialState = {
  token: getToken(),
  user: getUser(),
  contactForm: {
    loading: false,
    error: null
  },
  questionForm: {
    loading: false,
    error: null
  }
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USER_REQUEST:
      return state;
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    case CREATE_USER_FAILURE:
      return state;
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case LOGIN_AS_USER_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case DETAIL_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data
        }
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data
        }
      };
    case UPDATE_PROFILE_DOC_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          buyer: {
            ...state.user.buyer,
            docs: state.user.buyer.docs.find(doc => doc.id === payload.data.id)
              ? state.user.buyer.docs.map(doc => (doc.id === payload.data.id ? payload.data : doc))
              : state.user.buyer.docs.concat(payload.data)
          }
        }
      };
    case SEND_CONTACT_FORM_REQUEST:
      return {
        ...state,
        contactForm: {
          loading: true,
          error: null
        }
      };
    case SEND_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactForm: {
          loading: false,
          error: null
        }
      };
    case SEND_CONTACT_FORM_FAILURE:
      return {
        ...state,
        contactForm: {
          loading: false,
          error: payload
        }
      };
    case SEND_QUESTION_FORM_REQUEST:
      return {
        ...state,
        questionForm: {
          loading: true,
          error: null
        }
      };
    case SEND_QUESTION_FORM_SUCCESS:
      return {
        ...state,
        questionForm: {
          loading: false,
          error: null
        }
      };
    case SEND_QUESTION_FORM_FAILURE:
      return {
        ...state,
        questionForm: {
          loading: false,
          error: payload
        }
      };
    default:
      return state;
  }
};

export default userReducer;
