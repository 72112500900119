import styled from 'styled-components';
import { Field, useField } from 'react-final-form';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { useDispatch } from 'react-redux';
import { openMobileImagesDialog } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { NotesField } from '../../NotesField';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { RenderDamagedCheckbox } from './components/DamagedCheckbox';
import { cutTextElipsis } from '../../../../../utils';
import { useWindowSize } from 'usehooks-ts';
import { useHandleImageChange } from '../../../hooks/useHandleImageChange';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const ImageWrapper = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 1);
  justify-content: center;
  position: relative;
`;

export const LoadingSpin = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const ImageContainer = styled.div<{ largeImage?: boolean; isDragging?: boolean; withShadow?: boolean }>`
  height: ${props => (props.largeImage ? '330px' : '130px')};
  width: 100%;
  position: relative;
  opacity: ${props => (props.isDragging ? '0.7' : '1')};
  box-shadow: ${props => (props.withShadow ? '0px 4px 10px 0px rgba(0, 0, 0, 0.25)' : 'none')};
  touch-action: none;
`;

export const ImageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 22px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const ImageTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  text-align: center;
  color: #fff;
`;

export const NotesIconWrapper = styled.div`
  position: absolute;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LabelPlaceholder = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #fff;
  opacity: 0.6;
`;

export const DesktopImageComponent = ({
  name,
  renderDamageCheckbox
}: {
  name: string;
  renderDamageCheckbox: boolean;
}) => {
  const [localImage, setLocalImage] = useState<File | null>(null);

  const { handleImageChange, imageLoading } = useHandleImageChange(name);

  const isTiresAndWheelsSections = name.startsWith('tires_and_wheels');

  const {
    input: { value }
  } = useField(name);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: value.id,
    data: value
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <ImageContainer
      isDragging={isDragging}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      largeImage={isTiresAndWheelsSections}
    >
      <Field name={`${name}.url`} type="file">
        {props => {
          return (
            <ImageWrapper htmlFor={`${name}-upload`}>
              <RenderImage inputImage={props.input.value} localImage={localImage} isUploading={imageLoading} />
              <input
                id={`${name}-upload`}
                style={{ display: 'none' }}
                type="file"
                onChange={async e => {
                  const file = e.target?.files?.[0];
                  if (!file) return;
                  setLocalImage(file);
                  await handleImageChange(e.target?.files?.[0]);
                  setLocalImage(null);
                }}
              />
            </ImageWrapper>
          );
        }}
      </Field>
      <RenderLabel name={name} />
      <NotesIconWrapper>
        <NotesField name={name} />
      </NotesIconWrapper>
      {renderDamageCheckbox && <RenderDamagedCheckbox name={name} />}
    </ImageContainer>
  );
};

export const MobileImageComponent = ({
  name,
  renderDamageCheckbox
}: {
  name: string;
  renderDamageCheckbox: boolean;
}) => {
  const dispatch = useDispatch();

  const {
    input: { value }
  } = useField(name);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: value.id,
    data: value
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <ImageContainer isDragging={isDragging} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Field name={`${name}.url`} type="file">
        {props => {
          return (
            <ImageWrapper onClick={() => dispatch(openMobileImagesDialog(`${name}-single`))}>
              <RenderImage inputImage={props.input.value} localImage={null} isUploading={false} />
            </ImageWrapper>
          );
        }}
      </Field>
      <RenderLabel name={name} />
      <NotesIconWrapper>
        <NotesField name={name} />
      </NotesIconWrapper>
      {renderDamageCheckbox && <RenderDamagedCheckbox name={name} />}
    </ImageContainer>
  );
};

type RenderLabelProps = {
  name: string;
};

const RenderLabel = ({ name }: RenderLabelProps) => {
  const {
    input: { value: label }
  } = useField(`${name}.label`);

  const {
    input: { value: notes }
  } = useField(`${name}.notes`);

  if (!label && !notes) {
    return (
      <ImageTitleContainer>
        <LabelPlaceholder>Add comment</LabelPlaceholder>
      </ImageTitleContainer>
    );
  }

  const renderValue = [label, cutTextElipsis(notes, 3)].filter(Boolean).join(' - ');
  return (
    <ImageTitleContainer>
      <ImageTitle>{renderValue}</ImageTitle>
    </ImageTitleContainer>
  );
};

type RenderImageProps = {
  inputImage: string;
  localImage: File | null;
  isUploading: boolean;
};

const RenderImage = ({ inputImage, localImage, isUploading }: RenderImageProps) => {
  if (localImage) {
    return (
      <>
        <StyledImage src={URL.createObjectURL(localImage)} />
        {isUploading && (
          <LoadingSpin>
            <CircularProgress />
          </LoadingSpin>
        )}
      </>
    );
  }

  if (inputImage) {
    return <StyledImage src={inputImage} />;
  }

  return <ImagePlaceholderIcon />;
};
