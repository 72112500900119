import {
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  LIST_CUSTOMERS_SUCCESS,
  DETAIL_CUSTOMER_SUCCESS,
  DETAIL_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_DOC_SUCCESS,
  UPDATE_USER_DOC_FAILURE,
  UPDATE_USER_DOC_FILE_SUCCESS,
  DELETE_USER_DOC_SUCCESS,
  CUSTOMERS_SET_PAGE,
  LIST_CUSTOMERS_REQUEST,
  FILTER_CUSTOMERS_REQUEST,
  FILTER_CUSTOMERS_SUCCESS,
  LIST_ACCOUNTS_REQUEST,
  LIST_ACCOUNTS_SUCCESS,
  ACCOUNTS_SET_PAGE,
  LIST_REFERRERS_SUCCESS,
  LIST_ADMIN_SELLER_SELLERS_SUCCESS,
  LIST_ACCOUNT_LOGS_REQUEST,
  LIST_ACCOUNT_LOGS_SUCCESS,
  ACCOUNT_LOGS_SET_PAGE,
} from '../constants/actionTypes';

import { ACCOUNTS_PER_PAGE, CUSTOMERS_PER_PAGE, REFERRERS, ACCOUNT_LOGS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  items: [],
  error: '',
  page: 1,
  perPage: CUSTOMERS_PER_PAGE,
  accountsPage: 1,
  accountsPerPage: ACCOUNTS_PER_PAGE,
  accountLogsPage: 1,
  accountLogsPerPage: ACCOUNT_LOGS_PER_PAGE,
  customers: {
    rows: [],
    count: 0
  },
  accounts: {
    rows: [],
    count: 0
  },
  accountLogs: {
    rows: [],
    count: 0
  },
  adminSellerSellers: {
    rows: [],
    count: 0
  },
  customer: {},
  account: {},
  referrers: [...REFERRERS],
  selectedUser: {
    data: null,
    error: null
  }
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_USERS_REQUEST:
      return { ...state, loading: true };
    case LIST_USERS_SUCCESS:
      return { ...state, loading: false, items: payload.data.users };
    case LIST_USERS_FAILURE:
      return { ...state, loading: false };
    case LIST_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: {
          rows: [...state.customers.rows, ...payload.data.rows],
          count: payload.data.count
        }
      };
    case LIST_ACCOUNTS_REQUEST:
      return { ...state, loading: true };
    case LIST_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: {
          rows: [...state.accounts.rows, ...payload.data.rows],
          count: payload.data.count
        }
      };
    case LIST_ACCOUNT_LOGS_REQUEST:
      return { ...state, loading: true };
    case LIST_ACCOUNT_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        accountLogs: {
          rows: [...state.accountLogs.rows, ...payload.data.rows],
          count: payload.data.count
        }
      };
    case LIST_ADMIN_SELLER_SELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminSellerSellers: {
          rows: [...payload.data.rows],
          count: payload.data.count
        }
      };
    case LIST_REFERRERS_SUCCESS:
      return {
        ...state,
        referrers: [...payload.data],
      };
    case FILTER_CUSTOMERS_REQUEST:
      return { ...state, loading: true };
    case FILTER_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: {
          rows: payload.data.rows,
          count: payload.data.count
        }
      };
    case CUSTOMERS_SET_PAGE:
      return {
        ...state,
        page: payload,
        customers: {
          ...state.customers,
          rows: payload === 1 ? [] : state.customers.rows,
          count: payload === 1 ? 0 : state.customers.count
        }
      };
    case ACCOUNTS_SET_PAGE:
      return {
        ...state,
        accountsPage: payload,
        accounts: {
          ...state.accounts,
          rows: payload === 1 ? [] : state.accounts.rows,
          count: payload === 1 ? 0 : state.accounts.count
        }
      };
    case ACCOUNT_LOGS_SET_PAGE:
      return {
        ...state,
        accountLogsPage: payload,
        accountLogs: {
          ...state.accountLogs,
          rows: payload === 1 ? [] : state.accountLogs.rows,
          count: payload === 1 ? 0 : state.accountLogs.count
        }
      };
    case DETAIL_CUSTOMER_REQUEST:
      return { ...state, loading: true, customer: {} };
    case DETAIL_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customer: payload.data };
    case UPDATE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customer: payload.data };
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: {
          ...state.selectedUser,
          data: payload.data
        }
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        selectedUser: {
          ...state.selectedUser,
          error: payload
        }
      };
    case UPDATE_USER_DOC_SUCCESS:
      if (payload.data.name) {
        return {
          ...state,
          loading: false,
          selectedUser: {
            ...state.selectedUser,
            data: {
              ...state.selectedUser.data,
              buyer: {
                ...state.selectedUser.data.buyer,
                docs: [
                  ...state.selectedUser.data.buyer.docs.filter(
                    doc => doc.id !== payload.data.id
                  ),
                  payload.data
                ]
              }
            }
          }
        };
      }
      return {
        ...state,
        loading: false,
        selectedUser: {
          ...state.selectedUser,
          data: {
            ...state.selectedUser.data,
            buyer: {
              ...payload.data.buyer,
              docs: [...state.selectedUser.data.buyer.docs],
              purchase_methods: [
                ...state.selectedUser.data.buyer.purchase_methods.filter(
                  m => m.id !== payload.data.method.id
                ),
                payload.data.method
              ]
            }
          }
        }
      };
    case UPDATE_USER_DOC_FAILURE:
      return {
        ...state,
        loading: false,
        selectedUser: {
          ...state.selectedUser,
          error: payload
        }
      };
    case UPDATE_USER_DOC_FILE_SUCCESS:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          data: {
            ...state.selectedUser.data,
            buyer: {
              ...state.selectedUser.data.buyer,
              docs: [
                ...state.selectedUser.data.buyer.docs.slice(
                  0,
                  state.selectedUser.data.buyer.docs.findIndex(
                    doc => doc.id === payload.data.id
                  )
                ),
                payload.data,
                ...state.selectedUser.data.buyer.docs.slice(
                  state.selectedUser.data.buyer.docs.findIndex(
                    doc => doc.id === payload.data.id
                  ) + 1
                )
              ]
            }
          }
        }
      };
    case DELETE_USER_DOC_SUCCESS:
      if (payload.data.name) {
        return {
          ...state,
          selectedUser: {
            ...state.selectedUser,
            data: {
              ...state.selectedUser.data,
              buyer: {
                ...state.selectedUser.data.buyer,
                docs: [
                  ...state.selectedUser.data.buyer.docs.filter(
                    doc => doc.id !== payload.data.id
                  )
                ]
              }
            }
          }
        };
      }
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          data: {
            ...state.selectedUser.data,
            buyer: {
              ...state.selectedUser.data.buyer,
              purchase_methods: [
                ...state.selectedUser.data.buyer.purchase_methods.filter(
                  m => m.id !== payload.data.id
                )
              ]
            }
          }
        }
      };
    default:
      return state;
  }
};

export default usersReducer;
