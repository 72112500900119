import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';

const styles = theme => ({
  formControlLabelRoot: {
    root: {
      marginLeft: '0px',
      background: 'red'
    }
  },
  iOSSwitchBase: {
    '&$iOSChecked': {
      color: theme.palette.common.grey
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    }),
    width: 36
  },
  iOSChecked: {
    transform: 'translateX(24px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none'
    }
  },
  iOSBar: {
    borderRadius: 75,
    width: 56,
    height: 31,
    marginTop: -15,
    marginLeft: -30,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  iOSIcon: {
    backgroundColor: theme.palette.common.white,
    width: 24,
    height: 24,
    boxShadow: 'none'
  },
  iOSIconChecked: {
    boxShadow: 'none'
  }
});

let Switcher = ({ classes, ...rest }) => (
  <FormGroup row>
    <FormControlLabel
      style={{ marginLeft: '5px' }}
      control={
        <Switch
          {...rest}
          color="primary"
          classes={{
            switchBase: classes.iOSSwitchBase,
            bar: classes.iOSBar,
            icon: classes.iOSIcon,
            iconChecked: classes.iOSIconChecked,
            checked: classes.iOSChecked
          }}
          disableRipple
        />
      }
    />
  </FormGroup>
);

Switcher.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired
};

Switcher = withStyles(styles)(Switcher);

const WrappedSwitcher = ({ input, ...custom }) => (
  <Switcher {...custom} checked={input.value} onChange={input.onChange} />
);

export default WrappedSwitcher;
