import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { SectionWrapper, SectionTitle } from './index';
import { roles } from '../../../../constants';

const StyledDirectionLink = styled.a`
  font-weight: 500;
`;

const SellerInformationSection = ({ user, currentUser, directionLink }) => currentUser.role !== roles.SELLER ? (
  <SectionWrapper>
    <SectionTitle>Seller Information</SectionTitle>
    <Grid container justify="space-between">
      <div>
        <Typography>{user.dealership_name}</Typography>
        <Typography>{`${user.street},`}</Typography>
        <Typography>
          {user.city},
          {' '}
          {user.state}
          <span>&nbsp;&nbsp;</span>
          {user.zip}
        </Typography>
      </div>
      {currentUser.buyer ? (
        <Typography>
          <StyledDirectionLink
            rel="noopener noreferrer"
            target="_blank"
            href={directionLink(user, currentUser)}
          >
            Get Directions
          </StyledDirectionLink>
        </Typography>
      ) : null}
    </Grid>
    <Typography>
      <NumberFormat
        displayType="text"
        format="(###) ###-####"
        value={user.seller.phone_number}
      />
    </Typography>
  </SectionWrapper>
) : null;

export default SellerInformationSection;
