import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { getDeliveryEstimate } from '../../../../actions';
import { COLOR_SUCCESSFUL } from '../../../../constants';
import { SectionWrapper, SectionTitle } from './index';

import carArrive from '../../../../img/carArrive.png';

const CarArriveLogo = styled.img`
  height: 40px;
  width: 160px;
  object-fit: cover;
  margin: 7.5px 0 0 5px;
`;

const CarArriveWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledNumberFormat = styled(NumberFormat)`
  color: ${COLOR_SUCCESSFUL};
`;

const styles = {
  paddingLeft: {
    paddingLeft: '32px'
  },
  border: {
    borderRight: '1px solid #dcdcdc' },
  marginTop: {
    marginTop: '24px'
  },
  errorColor: {
    color: '#D50808'
  }
};

class RunbuggyBlock extends React.Component {
  componentDidMount() {
    const { getEstimate, vehicleId } = this.props;

    getEstimate(vehicleId);
  }

  RunbuggyBlock = (classes, estimate, error) => {
    const { currentVehicle, currentUser } = this.props;
    const fromToString = `Approximate shipping quote from ${
      currentVehicle.user.city
    }, ${currentVehicle.user.state} to ${currentUser.city}, ${
      currentUser.state
    }`;
    return (
      <SectionWrapper>
        <SectionTitle>Transportation Cost</SectionTitle>
        <Grid container justify="space-between">
          <Typography>{fromToString}</Typography>
          <Typography>
            {error && (
              <Typography className={classes.errorColor}>
                Invalid address
              </Typography>
            )}
            {estimate && (
              <StyledNumberFormat
                displayType="text"
                prefix="$"
                value={estimate}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
              />
            )}
            {!estimate && !error && <CircularProgress size={20} />}
          </Typography>
        </Grid>
        <CarArriveWrapper>
          <Typography>Provided by:</Typography>
          {' '}
          <a href="http://reports.carsarrive.com/majoraccounts/" target="_blank" rel="noopener noreferrer">
            <CarArriveLogo src={carArrive} alt="CarArrive logo" />
          </a>
        </CarArriveWrapper>
        <Typography>
          CarsArrive is North America’s largest hauler of used cars.
          <br />
          For new customers, please call us at <a href="tel:1-480-809-3357">480-809-3357</a> or email <a href="mailto:TransportAutoAxess@Carsarrive.com">TransportAutoAxess@Carsarrive.com</a>
        </Typography>
      </SectionWrapper>
    );
  };

  render() {
    const { classes, runbuggy } = this.props;
    const estimate = runbuggy.estimate
      ? runbuggy.estimate.data.estimated_price
      : null;
    return this.RunbuggyBlock(classes, estimate, runbuggy.error);
  }
}

const mapStateToProps = state => ({
  runbuggy: state.vehicles.selectedVehicle.runbuggy,
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
});

const mapDispatchToProps = dispatch => ({
  getEstimate: vehicleId => dispatch(getDeliveryEstimate(vehicleId).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RunbuggyBlock));
