import { useState } from 'react';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { InfoBlock } from '../InfoBlock';
import styled from 'styled-components';
import { AutoCheckReportIcon } from '../../icons/AutoCheckReportIcon';
import { ExpandIcon } from '../../../../../components/Icons/ExpandIcon';
import { CollapseIcon } from '../../../../../components/Icons/CollapseIcon';
import { Icon } from '@material-ui/core';
import {
  AccidentDamageIcon,
  AccidentIcon,
  AdditionalHistoryIcon,
  AuctionBrandIcon,
  InsuranceLossIcon,
  OdometerCheckIcon,
  OpenRecallCheckIcon,
  OwnerIcon,
  PreOwnedIcon,
  ServiceRepairIcon,
  StateTitleBrandIcon
} from './autoCheckReportIcons';

const Wrapper = styled.div`
  padding: 24px 13px 8px 29px;
  display: flex;
  flex-direction: column;
`;

const ViewFullReportButton = styled.a`
  border: 1px solid rgba(34, 90, 145, 1);
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  height: 24px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  align-self: center;
`;

export const AutoCheckReport = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  const openFullReport: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    window.open(`/vehicles/${vehicle.vin}/autocheck-report`, '_blank');
  };

  return (
    <InfoBlock>
      <Wrapper>
        <Row>
          <AutoCheckReportIcon />
          <ViewFullReportButton onClick={openFullReport}>
            <span>View full report</span>
          </ViewFullReportButton>
        </Row>
        <StyledIcon>{isExpanded ? <CollapseIcon /> : <ExpandIcon />}</StyledIcon>
      </Wrapper>
    </InfoBlock>
  );
};

const reportItems = [
  {
    Icon: OwnerIcon,
    label: 'Owner(s)'
  },
  {
    Icon: AccidentIcon,
    label: 'Accident(s)'
  },
  {
    Icon: StateTitleBrandIcon,
    label: 'State Title Brand'
  },
  {
    Icon: AuctionBrandIcon,
    label: 'Auction Brand /Issues'
  },
  {
    Icon: AccidentDamageIcon,
    label: 'Accident / Damage',
    subText: 'No Accidents or Damage Reported'
  },
  {
    Icon: OpenRecallCheckIcon,
    label: 'Open Recall Check'
  },
  {
    Icon: InsuranceLossIcon,
    label: 'Insurance Loss / Transfer'
  },
  {
    Icon: OdometerCheckIcon,
    label: 'Odometer Check'
  },
  {
    Icon: PreOwnedIcon,
    label: 'Certified Pre-Owned'
  },
  {
    Icon: ServiceRepairIcon,
    label: 'Service / Repair'
  },
  {
    Icon: AdditionalHistoryIcon,
    label: 'Additional History'
  }
];

const MainContent = ({ isExpanded }: { isExpanded: boolean }) => {};
