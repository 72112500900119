import {
  LIST_BIDS_FAILURE,
  LIST_BIDS_REQUEST,
  LIST_BIDS_SUCCESS,
  LIST_BIDS_CHANGE_AUCTION_STATUS,
  BIDS_SET_PAGE,
  FILTER_BIDS_FAILURE,
  FILTER_BIDS_REQUEST,
  FILTER_BIDS_SUCCESS,
  SET_ACTIVE_BID
} from '../constants/actionTypes';

export const listBids = data => ({
  request: { type: LIST_BIDS_REQUEST, payload: data },
  success: { type: LIST_BIDS_SUCCESS, payload: data },
  failure: { type: LIST_BIDS_FAILURE, payload: data }
});

export const listBidsChangeStatus = data => ({
  type: LIST_BIDS_CHANGE_AUCTION_STATUS,
  payload: data
});

export const filterBids = data => ({
  request: { type: FILTER_BIDS_REQUEST, payload: data },
  success: { type: FILTER_BIDS_SUCCESS, payload: data },
  failure: { type: FILTER_BIDS_FAILURE, payload: data }
});

export const DELETE_BID_REQUEST = 'DELETE_BID_REQUEST';
export const DELETE_BID_SUCCESS = 'DELETE_BID_SUCCESS';
export const DELETE_BID_FAILURE = 'DELETE_BID_FAILURE';

export const deleteBid = payload => ({
  request: { type: DELETE_BID_REQUEST, payload },
  success: { type: DELETE_BID_SUCCESS, payload },
  failure: { type: DELETE_BID_FAILURE, payload }
});

export const EDIT_BID_REQUEST = 'EDIT_BID_REQUEST';
export const EDIT_BID_SUCCESS = 'EDIT_BID_SUCCESS';
export const EDIT_BID_FAILURE = 'EDIT_BID_FAILURE';

export const editBid = payload => ({
  request: { type: EDIT_BID_REQUEST, payload },
  success: { type: EDIT_BID_SUCCESS, payload },
  failure: { type: EDIT_BID_FAILURE, payload }
});

export const EDIT_PROXY_BID_REQUEST = 'EDIT_PROXY_BID_REQUEST';
export const EDIT_PROXY_BID_SUCCESS = 'EDIT_PROXY_BID_SUCCESS';
export const EDIT_PROXY_BID_FAILURE = 'EDIT_PROXY_BID_FAILURE';

export const editProxyBid = payload => ({
  request: { type: EDIT_PROXY_BID_REQUEST, payload },
  success: { type: EDIT_PROXY_BID_SUCCESS, payload },
  failure: { type: EDIT_PROXY_BID_FAILURE, payload }
});

export const DELETE_PROXY_BID_REQUEST = 'DELETE_PROXY_BID_REQUEST';
export const DELETE_PROXY_BID_SUCCESS = 'DELETE_PROXY_BID_SUCCESS';
export const DELETE_PROXY_BID_FAILURE = 'DELETE_PROXY_BID_FAILURE';

export const deleteProxyBid = payload => ({
  request: { type: DELETE_PROXY_BID_REQUEST, payload },
  success: { type: DELETE_PROXY_BID_SUCCESS, payload },
  failure: { type: DELETE_PROXY_BID_FAILURE, payload }
});

export const bidsSetPage = data => ({ type: BIDS_SET_PAGE, payload: data });

export const setActiveBid = payload => ({
  type: SET_ACTIVE_BID,
  payload
});

export default {
  listBids,
  listBidsChangeStatus
};
