import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';

import { PageWrapper } from '../../../components';
import { generateRandomKey } from '../../../utils';
import sections from './sections';

const StyledSection = styled(Paper)`
  && {
    max-width: 856px;
    border-radius: 0;
    margin: 24px auto 0;
    padding: 32px 110px 48px 110px;
    @media (max-width: 960px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 32px;
    margin: 40px 0;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
`;

const TypographyWithMarginBot = styled(Typography)`
  && {
    margin-bottom: 1em;
  }
`;

const ListWithDisc = styled(List)`
  && {
    list-style-type: disc;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

const renderText = text => <Typography gutterBottom>{text}</Typography>;
const renderSubtitle = subtitle => (
  <Typography variant="subtitle1" gutterBottom>
    {subtitle}
  </Typography>
);
const renderCaption = caption => (
  <Typography variant="body2" gutterBottom>
    {caption}
  </Typography>
);
const renderList = list => (
  <List disablePadding>
    {list.map(listItem => (
      <StyledListItem dense key={generateRandomKey()}>
        <Typography variant="body1">{`- ${listItem}`}</Typography>
      </StyledListItem>
    ))}
  </List>
);
const renderListWithTitle = list => (
  <ListWithDisc key={generateRandomKey()}>
    {list.map(item => (
      <ListItem key={generateRandomKey()} disableGutters dense>
        <ListItemText disableTypography>
          <Typography variant="body2">{`- ${item.title}`}</Typography>
          {item.paragraphs.map(p => (
            <Typography key={generateRandomKey()} gutterBottom>
              {p}
            </Typography>
          ))}
        </ListItemText>
      </ListItem>
    ))}
  </ListWithDisc>
);

const PrivacyPage = () => (
  <PageWrapper>
    <StyledSection>
      <StyledTitle variant="title">Privacy Policy</StyledTitle>
      <Grid container spacing={32}>
        <Grid item>
          <Typography gutterBottom>
            Effective date: November 14, 2018
          </Typography>
          <Typography gutterBottom>
            autoaxess (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;)
            operates the www.autoaxess.com website (hereinafter referred to as
            the &quot;Service&quot;).
          </Typography>
          <Typography gutterBottom>
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </Typography>
          <Typography gutterBottom>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from
            www.autoaxess.com
          </Typography>
        </Grid>
        {sections.map(section => (
          <Grid item key={generateRandomKey()} xs={12}>
            <TypographyWithMarginBot variant="display1">
              {section.title}
            </TypographyWithMarginBot>
            {section.parts.map(part => (
              <React.Fragment>
                {part.text && renderText(part.text)}
                {part.subtitle && renderSubtitle(part.subtitle)}
                {part.caption && renderCaption(part.caption)}
                {part.list && renderList(part.list)}
                {part.listWithTitle && renderListWithTitle(part.listWithTitle)}
              </React.Fragment>
            ))}
          </Grid>
        ))}
      </Grid>
    </StyledSection>
  </PageWrapper>
);

export default PrivacyPage;
