import React, { useRef } from 'react';
import FormTextField from '../FormTextField';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { ErrorIcon } from '../../Icons/ErrorIcon';
import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  validate?: FieldValidator<any>;
  options: Array<{ value: string; label: string }>;
};

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  line-height: 14px;
  padding-left: 26px;
`;

const Placeholder = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: #8e8e8e;
`;

const OutlinedFormSelectField = ({ name, label, options, placeholder, validate = undefined }: Props) => {
  return (
    <Field name={name} validate={validate}>
      {fieldProps => {
        const hasError = (fieldProps.meta.touched && fieldProps.meta.error) || fieldProps.meta.submitError;
        return (
          <FormTextField
            input={fieldProps.input}
            meta={fieldProps.meta}
            label={label}
            placeholder={placeholder}
            fullWidth
            Icon={hasError ? ErrorIcon : null}
            iconOptions={{
              position: 'end'
            }}
            includeBottomIndent
            select
            SelectProps={{
              displayEmpty: true,
              renderValue: fieldProps.input.value !== '' ? undefined : () => <Placeholder>{placeholder}</Placeholder>,
              MenuProps: {
                style: {
                  maxHeight: 400
                }
              }
            }}
            styleVariant={hasError ? 'outlined_error' : 'outlined'}
          >
            {options.map(({ value, label }) => (
              <StyledMenuItem key={value} value={value}>
                {label}
              </StyledMenuItem>
            ))}
          </FormTextField>
        );
      }}
    </Field>
  );
};

export default OutlinedFormSelectField;
