import React from 'react';
import { Vehicle } from '../../types/vehicle';
import styled from 'styled-components';
import FormattedAmount from '../FormattedAmount';
import moment from 'moment';
import { numberFormatString } from '../../utils';

type Props = {
  vehicle: Vehicle;
  withDivider?: boolean;
  cutNames?: boolean;
  withTopDivider?: boolean;
};

const ContentWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-height: 70px;
  overflow-y: auto;
`;

type FormattedReservePrice = {
  operationType: string;
  amount: number;
  created_at: string | moment.Moment;
  changeAuthor: string;
};

const ContentRow = styled.div`
  display: flex;
  width: 400px;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  & > span.timestamp {
    flex: 1 1 34%;
  }
  & > span.author {
    text-transform: capitalize;
    flex: 1 1 34%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & > span.amount,
  span.operation-type {
    flex: 1 1 16%;
  }
`;

const RowText = styled.span<{ isBold?: boolean }>`
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  font-size: 12px;
  line-height: 20px;
  color: #757575;
`;

const formatTimestamp = (timestamp: string | moment.Moment) => {
  return moment(timestamp).format('ddd DD.MM.YY h:mm A');
};

const serializeHistoryItem = (item: any, operationType: string) => {
  const changeAuthor = item.changeAuthor
    ? `${item.changeAuthor.role}: ${item.changeAuthor.dealership_name || item.changeAuthor?.first_name}`
    : '';

  return {
    operationType,
    amount: item.reserve_price,
    created_at: item.created_at,
    changeAuthor
  };
};

const formatReservePriceHistory = (reservePriceHistory: any[]) => {
  const formattedData: FormattedReservePrice[] =
    reservePriceHistory.length > 0 ? [serializeHistoryItem(reservePriceHistory[0], 'set')] : [];

  for (let i = 1; i < reservePriceHistory.length; i++) {
    const prev = reservePriceHistory[i - 1];
    const curr = reservePriceHistory[i];

    if (!curr.reserve_price) {
      formattedData.push(serializeHistoryItem(curr, 'removed'));
    } else if (!prev.reserve_price && curr.reserve_price) {
      formattedData.push(serializeHistoryItem(curr, 'set'));
    } else {
      const deltaAmount = Math.abs(curr.reserve_price - prev.reserve_price);
      const deltaSign = curr.reserve_price > prev.reserve_price ? '+' : '-';

      formattedData.push(serializeHistoryItem(curr, `${deltaSign}${numberFormatString(deltaAmount)}`));
    }
  }

  return formattedData
};

export const ReservePriceHistory = ({ vehicle }: Props) => {
  return (
    <ContentWrapper>
      {formatReservePriceHistory(vehicle.reserve_price_history)
        .reverse()
        .map((item, i) => (
          <ContentRow>
            <RowText className="author">{item.changeAuthor}</RowText>
            <RowText className="operation-type" isBold>
              {item.operationType}
            </RowText>
            <RowText className="amount" isBold>
              {item.amount ? <FormattedAmount amount={item.amount} /> : '-'}
            </RowText>
            <RowText className="timestamp">{formatTimestamp(item.created_at)}</RowText>
          </ContentRow>
        ))}
    </ContentWrapper>
  );
};
