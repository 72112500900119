import React, { Component } from 'react';
import styled from 'styled-components';
import { Paper, Typography } from '@material-ui/core';

import { PageWrapper } from '../../../components';
import { ExpansionPanel } from './components';

import questions from '../../../constants/faqs';

const StyledSection = styled(Paper)`
  && {
    max-width: 856px;
    border-radius: 0;
    margin: 24px auto 0;
    padding: 32px 110px 48px 110px;
    @media (max-width: 960px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 32px;
    margin: 40px 0;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
`;

class FaqsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: ''
    };
  }

  expand = panel => (event, expanded) => {
    this.setState({ panel: expanded ? panel : false });
  };

  render() {
    const { panel } = this.state;
    return (
      <PageWrapper>
        <StyledSection>
          <StyledTitle variant="title">FAQ&#39;s</StyledTitle>
          <div>
            {questions.map(item => (
              <ExpansionPanel
                key={item.id}
                question={item.question}
                name={item.id}
                expanded={panel}
                handleChange={this.expand}
              >
                {item.answer}
              </ExpansionPanel>
            ))}
          </div>
        </StyledSection>
      </PageWrapper>
    );
  }
}

export default FaqsPage;
