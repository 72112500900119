import { OfferAuction, OfferStatus, Vehicle } from '../types/vehicle';
import { auctionOfferStatuses } from '../utils/statusCalculator';
import { ADMIN_VEHICLE_STATUS, BUYER_VEHICLE_STATUS, SELLER_VEHICLE_STATUS, VEHICLE_STATUSES } from '../constants';
import moment from 'moment';
import { useSelector } from 'react-redux';

type TimerConfig = {
  timer: null | moment.Moment;
  colorsConfig: {
    active?: boolean;
    state?: boolean;
    activeColor?: string;
    staleColor?: string;
  };
};

export const useTimerConfig = (vehicle: Vehicle, offerAuction?: OfferAuction | null): TimerConfig => {
  const activeOffer = offerAuction?.privateOffers?.find(offer => offer.status === OfferStatus.SENT) ?? null;
  const user = useSelector((state: any) => state.user?.user);
  const status = auctionOfferStatuses(vehicle, offerAuction, user);
  const buyerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.buyer_response_time || 900000
  );
  const sellerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.seller_response_time || 900000
  );

  const offerAuctionStatusTimerMap: Record<string, string[]> = {
    buyer: [BUYER_VEHICLE_STATUS.COUNTERED],
    admin: [
      ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT,
      ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT,
      ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT
    ],
    seller: [SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT]
  };

  const timerConfig = (() => {
    if (user && activeOffer && offerAuctionStatusTimerMap[user.role].includes(status)) {
      const responseTime = activeOffer.sender?.role === 'buyer' ? sellerResponseTime : buyerResponseTime;

      return {
        timer: moment(activeOffer.created_at).add(responseTime, 'milliseconds'),
        colorsConfig: { active: true, stale: true, activeColor: '#AF2930' }
      };
    }

    if (!vehicle.auction) {
      return {
        timer: null,
        colorsConfig: {}
      };
    }

    const endStatuses = [VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.PULLED];
    if (endStatuses.includes(vehicle.status)) {
      return {
        timer: null,
        colorsConfig: {}
      };
    }

    if (vehicle.auction && vehicle.auction.status === 'ended') {
      return {
        timer: null,
        colorsConfig: {}
      };
    }

    const isShuffled = vehicle.date_end != null;
    const timer = isShuffled ? moment(vehicle.date_end) : moment(vehicle.auction.date_end);

    return { timer, colorsConfig: {} };
  })();

  return timerConfig;
};
