import { AUCTION_END_FORMATTING } from 'constants';
import moment from 'moment';
import styled from 'styled-components';
import BidStatus from './BidStatus';
import { bidsListCalculateStatus } from 'utils/statusCalculator';
import { useSelector } from 'react-redux';
import { useScreen } from 'usehooks-ts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  font-size: ${props => props.isMobilePortrait ? '12px' : '16px'}
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Label = styled.span`
  color: #757575;
`;

const Value = styled.span`
  font-weight: 500;
  color: #000;
`;

export const MobileVehicleInfo = ({ vehicle }) => {
  const auctionEndDate = vehicle.auction
    ? moment
        .utc(vehicle.auction.date_end)
        .local()
        .format(AUCTION_END_FORMATTING)
    : 'None';

  const { width = 0, height = 0 } = useScreen();

  const isMobilePortrait = width < 800 && width > height;

  const currentUser = useSelector(state => state.user.user);

  return (
    <Wrapper isMobilePortrait={isMobilePortrait}>
      <ContentWrapper>
        <Label>Seller:</Label>
        <Value>{vehicle.user?.dealership_name ?? '-'}</Value>
      </ContentWrapper>
      <ContentWrapper>
        <Label>Ending Bid:</Label>
        <Value>{vehicle.highest_bid?.amount ?? '-'}</Value>
      </ContentWrapper>
      <ContentWrapper>
        <Label>Status:</Label>
        <Value>
          {' '}
          <BidStatus
            status={bidsListCalculateStatus(vehicle, currentUser?.id)}
            vehicle={vehicle}
            currentUser={currentUser}
          />
        </Value>
      </ContentWrapper>
      <ContentWrapper>
        <Label>Auction:</Label>
        <Value>{auctionEndDate}</Value>
      </ContentWrapper>
    </Wrapper>
  );
};
