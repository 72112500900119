import { RegularSectionContainer } from '../../components/RegularSectionContainer';
import { FormControlLabel, Radio } from '@material-ui/core';
import { Field } from 'react-final-form';
import { FormRadioGroup } from '../../../../components';
import styled from 'styled-components';

const normalizeValue = (value: string) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const Header = styled.div`
  font-weight: 700;
  color: #000;
  font-size: 20px;

  @media (min-width: 1024px) {
    font-size: 24px;
    font-weight: 28px;
  }
`;

const StyledLabel = styled(FormControlLabel)`
  margin-top: 14px;
  & span:last-child {
    color: rgba(102, 102, 102, 1);
  }

  @media (min-width: 1024px) {
    & span:last-child {
      font-size: 22px;
      line-height: 24px;
    }
  }
`;

export const DisclaimerSection = () => {
  return (
    <Wrapper>
      <Header>Disclaimer</Header>
      <Field name="is_guaranteed" parse={normalizeValue}>
        {props => (
          <FormRadioGroup input={props.input} meta={props.meta as any}>
            <StyledLabel
              value={false as any}
              control={<Radio />}
              label="Buyer responsible for viewing vehicle prior to placing bid (Sold As Is)"
            />
            <StyledLabel
              value={true as any}
              control={<Radio />}
              label="Vehicle is green light. Motor, tranny and rear end guaranteed for 24hrs. (Vehicles being transported over 300 miles are guaranteed until 5pm on the Wednesday following the auction)"
            />
          </FormRadioGroup>
        )}
      </Field>
    </Wrapper>
  );
};
