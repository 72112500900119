import { Component, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { FormattedAmount, Table } from '../../../../components';
import { EasyPayStatus } from '../../../../components/EasyPay';
import { DATE_FORMAT } from '../../../../constants';
import {
  awardedVehiclesListSetPage,
  filterAwardedVehiclesList,
  getAwardedVehicleList
} from '../../../../actions/financials';
import { ButtonsComponent } from '../components/Buttons';
import TransactionVehiclePopover from '../components/TransactionVehiclePopover';
import { useGetAllBuyerTransactionsQuery } from 'services/api/transactions';
import { setPage } from 'services/transactions/transactionsSlice';
import { MobileHorizontalCard } from '../components/MobileHorizontalCard';
import { useScreen, useWindowSize } from 'usehooks-ts';
import { Delimiter } from 'components/Table/common';
import { cutTextElipsis } from 'utils';

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }

  tr {
    height: 105px;
  }

  tr td {
    font-weight: 700;
  }

  tr td:nth-child(2) {
    max-width: ${props => (props.isMobilePortrait ? '20px' : 'none')};
  }

  @media (max-width: 800px) {
    thead tr {
      display: none;
    }
  }
`;

const AllTab = () => {
  const { page, perPage } = useSelector(state => state.transactions.all);
  const { data, isFetching } = useGetAllBuyerTransactionsQuery({
    limit: perPage,
    offset: page
  });

  const dispatch = useDispatch();

  const transactions = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const transactionsCount = useMemo(() => data?.count ?? 0, [data]);

  const { width = 0, height = 0 } = useWindowSize();

  const columns = (() => {
    if (width > 800) {
      return [
        { title: 'Auction ID', key: 'auction_id' },
        { title: 'Car Details / Description', key: 'vehicle_detail' },
        { title: 'Seller', key: 'seller_dealership_name' },
        { title: 'Date', key: 'date_status' },
        { title: 'Award Price', key: 'amount' },
        { title: 'EasyPay Status', key: 'status' },
        { title: '', key: 'actions' }
      ];
    }

    if (width > height) {
      return [
        {
          title: '',
          key: 'mobile_vertical_view_main'
        },
        {
          title: '',
          key: 'delimiter'
        },
        {
          title: '',
          key: 'mobile_vertical_view_secondary'
        },
        {
          title: '',
          key: 'actions'
        }
      ];
    }

    return [
      {
        title: '',
        key: 'mobile_horizontal_view'
      },
      {
        title: '',
        key: 'actions'
      }
    ];
  })();

  const isMobilePortrait = width <= 800 && width > height;

  const loadMore = () => {
    console.log(transactionsCount, perPage * page, 'all-tab');
    if (perPage * page > transactionsCount) return;
    dispatch(setPage({ page: page + 1, tab: 'all' }));
  };

  return (
    <>
      <StyledTableWrapper isMobilePortrait={isMobilePortrait}>
        <Table
          columnsHead={columns}
          columnsData={mapTransactions(transactions)}
          rowsCount={transactionsCount}
          page={page}
          withMobileView
          rowsPerPage={perPage}
          loadMore={loadMore}
          loading={isFetching}
        />
      </StyledTableWrapper>
    </>
  );
};

const mapTransactions = transactions =>
  transactions.map(vehicle => {
    const { id, payment_status, user, payment_method, buyer_id, status } = vehicle;
    const can_be_easily_purchased =
      user && user.seller && user.seller.purchase_options.find(({ option_name }) => option_name === 'ach_flooring');
    const auctionColumn = { label: 'Auction ID:', value: vehicle.auction_id };
    const detailsColumn = {
      label: (
        <>
          <span>Car Details/</span>
          <span>Description:</span>
        </>
      ),
      value: <TransactionVehiclePopover vehicleData={vehicle} />
    };
    const sellerColumn = { label: 'Seller:', value: user ? cutTextElipsis(user.dealership_name, 15) : 'N/A' };
    const dateColumn = {
      label: 'Date:',
      value: moment
        .utc(vehicle.date_status)
        .local()
        .format(DATE_FORMAT)
    };
    const awardPriceColumn = { label: 'Award Price:', value: <FormattedAmount amount={vehicle.vehicle_price} /> };
    const easyPayColumn = {
      label: 'EasyPay Status:',
      value: <EasyPayStatus vehicle={vehicle} statusHidden amountHidden />
    };
    return {
      id,
      payment_status,
      auction_id: vehicle.auction_id || 'N/A',
      vehicle_detail: <TransactionVehiclePopover vehicleData={vehicle} />,
      seller_dealership_name: user ? user.dealership_name : 'N/A',
      date_status: moment
        .utc(vehicle.date_status)
        .local()
        .format(DATE_FORMAT),
      amount: <FormattedAmount amount={vehicle.vehicle_price} />,
      status: <EasyPayStatus vehicle={vehicle} statusHidden amountHidden />,
      can_be_easily_purchased,
      vehicleStatus: status,
      buyer_id,
      payment_method,
      mobile_horizontal_view: (
        <MobileHorizontalCard
          columns={[auctionColumn, detailsColumn, sellerColumn, dateColumn, awardPriceColumn, easyPayColumn]}
        />
      ),
      mobile_vertical_view_main: (
        <MobileHorizontalCard
          textSize="sm"
          columns={[auctionColumn, { ...detailsColumn, styles: { minWidth: '260px' } }, sellerColumn]}
        />
      ),
      mobile_vertical_view_secondary: (
        <MobileHorizontalCard textSize="sm" columns={[dateColumn, awardPriceColumn, easyPayColumn]} />
      ),
      delimiter: <Delimiter />,
      actions: <ButtonsComponent transaction={{ ...vehicle, can_be_easily_purchased, vehicleStatus: status }} />
    };
  });

export default AllTab;
