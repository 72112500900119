import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const StyledBidderAccount = styled.p`
  color: #757575;
  margin: 0;
`;

const HighestBidder = ({ highestBid, currentUser }) => (
  <>
    <NumberFormat
      value={highestBid.amount}
      displayType="text"
      thousandSeparator
      prefix="$"
    />
    <StyledBidderAccount>
      {`${highestBid.bidder.dealership_name} ${
        highestBid.bidder.id === currentUser.id ? '(You)' : ''
      }`}
    </StyledBidderAccount>
  </>
);

export default HighestBidder;
