import { useDispatch } from 'react-redux';
import { modalsToggle } from '../actions';

export const useSellerCounterControls = (vehicleId: number) => {
  const dispatch = useDispatch();

  const CounterControlButtons = [
    {
      title: 'COUNTER OFFER',
      color: '#3994DE',
      textColor: '#fff',
      onClick: () => {
        dispatch(modalsToggle(`sendVehicleOffer-${vehicleId}`));
      }
    },
    {
      title: 'ACCEPT',
      color: '#225A91',
      textColor: '#fff',
      onClick: () => {
        dispatch(modalsToggle(`acceptPrivateOffer-${vehicleId}`));
      }
    }
  ];

  return CounterControlButtons;
};
