import styled from 'styled-components';
import { EditIcon } from '../Icons';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle } from '../../actions';
import { NotesComponent } from '../CarCard/NotesComponent';
import { Vehicle } from '../../types/vehicle';

const EditIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const AddNotesLabel = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: #3994de;
  margin-left: 4px;
  min-width: fit-content;

  &:hover {
    color: #000;
  }
`;

const Wrapper = styled.div<{ justifyBetween?: boolean }>`
  display: flex;
  gap: 6px;
  min-width: 100px;
  align-items: center;
  justify-content: ${({ justifyBetween }) => (justifyBetween ? 'space-between' : 'flex-start')};

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const AddNotesWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 12px;
`;

type Props = {
  vehicleId: number;
  vehicle?: Vehicle
};

export const AddNotesButton = ({ vehicleId, vehicle }: Props) => {
  const dispatch = useDispatch();
  const toggleModal = () => {
    dispatch(modalsToggle(`myBidsEditModal-${vehicleId}`));
  };

  const user = useSelector((state: any) => state.user?.user);
  if (!user || user.role === 'admin') return null;

  return (
    <Wrapper onClick={toggleModal}>
      <AddNotesWrapper>
        <EditIconWrapper>
          <EditIcon />
        </EditIconWrapper>
        <AddNotesLabel>Add Notes</AddNotesLabel>
      </AddNotesWrapper>
      {vehicle && <NotesComponent vehicle={vehicle} />}
    </Wrapper>
  );
};
