import { Component, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedAmount, Table } from '../../../../components';
import { EasyPayStatus } from '../../../../components/EasyPay';

import { DATE_FORMAT, DATE_FORMAT_FULL, TRANSACTION_STATUS_REPRESANTATION } from '../../../../constants';
import { filterTransactions, listTransactions, transactionsSetPage } from '../../../../actions/financials';
import { Filter } from '../components';
import { ButtonsComponent } from '../components/Buttons';
import TransactionVehiclePopover from '../components/TransactionVehiclePopover';
import { useGetAllBuyerTransactionsQuery, useGetCardBuyerTransactionsQuery } from 'services/api/transactions';
import { setPage } from 'services/transactions/transactionsSlice';
import { MobileHorizontalCard } from '../components/MobileHorizontalCard';
import { useScreen, useWindowSize } from 'usehooks-ts';
import { cutTextElipsis } from 'utils';
import { Delimiter } from 'components/Table/common';
import { useDeviceSelectors, useMobileOrientation } from 'react-device-detect';
import { isMobile } from 'react-device-detect';

const statusColors = {
  successful: '#009F0F',
  declined: '#D50808',
  voided: '#E47E00'
};

const StyledStatus = styled(Typography)`
  && {
    color: ${props => props.statusColor || '#000'};
  }
`;

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }
  tr td:nth-child(2) {
    max-width: ${props => (props.isMobilePortrait ? '20px' : '200px')};
  }
  tr td {
    font-weight: 700;
  }
  tr {
    height: 105px;
  }

  @media (max-width: 1024px) {
    tr td:first-child {
      padding-right: 0;
    }

    tr th:first-child {
      padding-right: 0;
    }
  }

  @media (max-width: 800px) {
    thead tr {
      display: none;
    }
  }
`;

const TransactionsTabFC = props => {
  const { page, perPage, filters } = useSelector(state => state.transactions.card);
  const { data, isFetching } = useGetCardBuyerTransactionsQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const dispatch = useDispatch();

  const transactions = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const transactionsCount = useMemo(() => data?.count ?? 0, [data]);

  const loadMore = () => {
    if (perPage * page > transactionsCount) return;
    dispatch(setPage({ page: page + 1, tab: 'card' }));
  };

  const { width = 0, height = 0 } = useWindowSize();

  const isMobilePortrait = width <= 1000 && width > height;

  const columns = (() => {
    if (width > 1000) {
      return [
        { title: 'Auc.ID', key: 'auction_id' },
        { title: 'Car Details / Description', key: 'vehicle_detail' },
        { title: 'Seller', key: 'seller_dealership_name' },
        { title: 'Date', key: 'created_at' },
        { title: 'Card Number', key: 'card' },
        { title: 'Amount', key: 'amount' },
        { title: 'Status', key: 'status' },
        { title: 'EasyPay', key: 'easy_pay_status' },
        { title: '', key: 'actions', cellClass: 'text-right' }
      ];
    }

    if (width > height) {
      return [
        {
          title: '',
          key: 'mobile_vertical_view_main'
        },
        {
          title: '',
          key: 'delimiter'
        },
        {
          title: '',
          key: 'mobile_vertical_view_secondary'
        },
        {
          title: '',
          key: 'actions'
        }
      ];
    }

    return [
      {
        title: '',
        key: 'mobile_horizontal_view'
      },
      {
        title: '',
        key: 'actions'
      }
    ];
  })();

  return (
    <>
      <Filter tab="card" {...props} />
      <StyledTableWrapper isMobilePortrait={isMobilePortrait}>
        <Table
          withMobileView
          columnsHead={columns}
          columnsData={mapTransactions(transactions)}
          rowsCount={transactionsCount}
          page={page}
          rowsPerPage={perPage}
          loadMore={loadMore}
          loading={isFetching}
        />
      </StyledTableWrapper>
    </>
  );
};

const mapTransactions = transactions =>
  transactions.map(transaction => {
    const { amount, status, auction_id, vehicle_id, created_at, card, description, ...rest } = transaction;
    const { vehicle } = rest;
    const { user, payment_method, payment_status } = vehicle || {};
    const can_be_easily_purchased =
      user && user.seller && user.seller.purchase_options.find(({ option_name }) => option_name === 'ach_flooring');
    const auctionColumn = { label: 'Auction ID:', value: auction_id };
    const detailsColumn = {
      label: (
        <>
          <span>Car Details/</span>
          <span>Description:</span>
        </>
      ),
      value: vehicle ? <TransactionVehiclePopover vehicleData={vehicle || {}} /> : description || '-'
    };
    const sellerColumn = { label: 'Seller:', value: user ? cutTextElipsis(user.dealership_name, 15) : 'N/A' };
    const dateColumn = {
      label: 'Date:',
      value: moment
        .utc(vehicle?.date_status || rest.created_at)
        .local()
        .format(DATE_FORMAT)
    };
    const cardNumber = { label: 'Card Number', value: card || 'N/A' };
    const awardPriceColumn = { label: 'Amount:', value: <FormattedAmount amount={vehicle?.vehicle_price} /> };
    const statusColumn = {
      label: 'Status:',
      value: (
        <StyledStatus variant="body2" statusColor={statusColors[status]}>
          {TRANSACTION_STATUS_REPRESANTATION[status]}
        </StyledStatus>
      )
    };
    const easyPayColumn = {
      label: 'EasyPay:',
      value: <EasyPayStatus vehicle={vehicle || {}} statusHidden amountHidden /> || 'pending'
    };
    return {
      auction_id: auction_id || 'N/A',
      card: card || 'N/A',
      vehicle_detail: vehicle ? <TransactionVehiclePopover vehicleData={vehicle || {}} /> : description || '-',
      seller_dealership_name: user ? user.dealership_name : 'N/A',
      created_at: moment
        .utc(created_at)
        .local()
        .format(DATE_FORMAT_FULL),
      amount: amount ? <FormattedAmount amount={amount} /> : 'N/A',
      status: (
        <StyledStatus variant="body2" statusColor={statusColors[status]}>
          {TRANSACTION_STATUS_REPRESANTATION[status]}
        </StyledStatus>
      ),
      buyer_id: transaction.user.id,
      can_be_easily_purchased,
      vehicleStatus: vehicle && vehicle.status,
      payment_status,
      payment_method,
      easy_pay_status: <EasyPayStatus vehicle={vehicle || {}} statusHidden amountHidden /> || 'pending',
      vehicle_id,
      actions: (
        <ButtonsComponent transaction={{ ...transaction, can_be_easily_purchased, vehicleStatus: vehicle?.status }} />
      ),
      mobile_horizontal_view: (
        <MobileHorizontalCard
          columns={[
            auctionColumn,
            detailsColumn,
            sellerColumn,
            dateColumn,
            cardNumber,
            awardPriceColumn,
            statusColumn,
            easyPayColumn
          ]}
        />
      ),
      mobile_vertical_view_main: (
        <MobileHorizontalCard
          textSize="sm"
          columns={[auctionColumn, { ...detailsColumn, styles: { minWidth: '280px' } }, sellerColumn, dateColumn]}
        />
      ),
      mobile_vertical_view_secondary: (
        <MobileHorizontalCard textSize="sm" columns={[cardNumber, awardPriceColumn, statusColumn, easyPayColumn]} />
      ),
      delimiter: <Delimiter />
    };
  });

export default TransactionsTabFC;
// export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TransactionsTab);
