import { MyBidsNotesBlock } from './NotesBlock';
import { BlockProps } from './types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useVehicleBidsActions } from '../hooks/useVehicleBidsActions';
import { TableButton } from '../../../../../components/Table/common';
import { useBidsOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { RenderPlaceBid } from './RenderPlaceBid';
import { BuyerModals } from './BuyerModals';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`;

const BidActions = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonWrapper = styled.div`
  width: 160px;
  display: flex;
  align-items: flex-end;
`;

export const TabletBottomBlock = ({ currentVehicle }: BlockProps) => {
  const user = useSelector((state: any) => state.user?.user);
  const offerAuction = useBidsOfferAuction(currentVehicle.id);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);

  const buttons = useVehicleBidsActions(currentVehicle, offerAuction, status);

  return (
    <Wrapper className='table-block'>
      <MyBidsNotesBlock currentVehicle={currentVehicle} />
      <BidActions>
        <RenderPlaceBid isMobileView currentVehicle={currentVehicle} offerAuction={offerAuction} status={status} />
        {buttons.map((btn, i) => {
          const renderButton = !btn.conditionalRendering || btn.conditionalRendering(currentVehicle);
          if (!renderButton) return null;

          return (
            <ButtonWrapper>
              <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
            </ButtonWrapper>
          );
        })}
      </BidActions>
      <BuyerModals currentVehicle={currentVehicle} />
    </Wrapper>
  );
};
