import React from 'react';
import { PurchaseMethodsSection } from '../sections';

const PurchaseMethodsTab = ({ user }) => (
  <>
    <PurchaseMethodsSection initialValues={user} />
  </>
);

export default PurchaseMethodsTab;
