import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import moment from 'moment';
import { Header, Filter, ImagePreview, BidStatus } from './components';
import { Table, NoImagePlaceholder, VehicleOptions } from '../../../components';
import {
  PAYMENT_METHODS_STATUSES,
  VEHICLE_STATUSES,
  VEHICLE_TABS_TYPES,
  AUCTION_END_FORMATTING
} from '../../../constants';
import { listBidsChangeStatus, bidsSetPage, listBids, filterBids } from '../../../actions/bids';
import { auctionOfferStatuses, bidsListCalculateStatus } from '../../../utils/statusCalculator';
import { MobileDetails } from './components/MobileDetails';
import { MobileVehicleInfo } from './components/MobileVehicleInfo';
import { ButtonsBlock } from './components/ButtonsBlock';

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }

  table {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      width: auto;
      align-items: center;
    }
  }
`;

class BidsHistoryList extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillMount() {
    const { auctionStatus, loadBids, changeStatus, fetchBids, page, perPage, setPage } = this.props;
    if (auctionStatus !== VEHICLE_TABS_TYPES.ENDED) {
      setPage(1);
      changeStatus(VEHICLE_TABS_TYPES.ENDED);
      fetchBids({
        status: VEHICLE_TABS_TYPES.ENDED,
        params: { limit: perPage, offset: 1 }
      });
      setPage(2);
    } else {
      loadBids({ params: { limit: perPage, offset: page } });
      setPage(page + 1);
    }
  }

  loadMore = () => {
    const { loadBids, setPage, perPage, page, newVehiclesLength } = this.props;
    // tmp fix before refactoring
    if (page > 1 && newVehiclesLength === 0) return;
    loadBids({ params: { limit: perPage, offset: page } });
    setPage(page + 1);
  };

  render() {
    const { bids, currentFilter, page, perPage, bidsCount, history, loading } = this.props;
    const { width } = this.state;
    const columns =
      width > 800
        ? [
            { title: '', key: 'image' },
            { title: 'Vehicle Details', key: 'details' },
            { title: 'Seller', key: 'seller' },
            { title: 'Ending Bid', key: 'ending_bid' },
            { title: 'Status', key: 'bid_status' },
            { title: 'Auction', key: 'auction_end_date' },
            { title: 'Actions', key: 'buttons' }
          ]
        : [
            { title: '', key: 'mobile_details' },
            { title: '', key: 'mobile_info' },
            { title: '', key: 'buttons' }
          ];

    const isFilterEmpty = currentFilter;
    const notFoundText =
      'After you place your first bid, you will see your bid history in this section at 5PM the day after the auction ends.';

    return (
      <div>
        <Header {...this.props} />
        <div className="pageWrapper">
          <Filter {...this.props} />
          <StyledTableWrapper>
            <Table
              columnsHead={columns}
              withMobileView
              columnsData={bids}
              rowsCount={bidsCount}
              buttons={null}
              page={page}
              rowsPerPage={perPage}
              loadMore={this.loadMore}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              loading={loading}
              noDataText={isFilterEmpty && !loading ? notFoundText : ''}
            />
          </StyledTableWrapper>
        </div>
      </div>
    );
  }
}

const convertBids = state => {
  let bids = [];
  if (state.bids.itemsData.rows) {
    const currentUser = state.user.user;
    bids = state.bids.itemsData.rows.map(vehicle => {
      const can_be_easily_purchased = vehicle.user.seller.purchase_options?.find(
        ({ option_name }) => option_name === 'ach_flooring'
      );
      return {
        ...vehicle,
        can_be_easily_purchased,
        image:
          vehicle.images[0] && vehicle.images[0].url_thumb ? (
            <ImagePreview vehicleId={vehicle.id} url={vehicle.images[0].url_thumb} />
          ) : (
            <NoImagePlaceholder />
          ),
        seller: vehicle.user.dealership_name,
        ending_bid: <NumberFormat displayType="text" prefix="$" value={vehicle.vehicle_price} thousandSeparator />,
        bid_status: (
          <BidStatus
            status={bidsListCalculateStatus(vehicle, currentUser?.id)}
            vehicle={vehicle}
            currentUser={currentUser}
          />
        ),
        details: <VehicleOptions currentVehicle={vehicle} />,
        auction_end_date: vehicle.auction
          ? moment
              .utc(vehicle.auction.date_end)
              .local()
              .format(AUCTION_END_FORMATTING)
          : 'None',
        mobile_details: <MobileDetails vehicle={vehicle} />,
        mobile_info: <MobileVehicleInfo vehicle={vehicle} />,
        buttons: <ButtonsBlock vehicle={{ ...vehicle, can_be_easily_purchased }} />
      };
    });
  }
  return bids;
};

const currentFilter = formValueSelector('bidsEndingFilterForm');

const mapStateToProps = state => ({
  bids: convertBids(state),
  bidsCount: state.bids.itemsData.count,
  newVehiclesLength: state.bids.itemsData.newVehiclesLength,
  page: state.bids.page,
  perPage: state.bids.perPage,
  loading: state.bids.loading,
  currentUser: state.user.user,
  auctionStatus: state.bids.auctionStatus,
  currentFilter: currentFilter(state, 'status')
});

const mapDispatchToProps = dispatch => ({
  changeStatus: payload => dispatch(listBidsChangeStatus(payload)),
  fetchBids: payload => dispatch(filterBids(payload).request),
  setPage: page => dispatch(bidsSetPage(page)),
  loadBids: payload => dispatch(listBids(payload).request)
});

export default connect(mapStateToProps, mapDispatchToProps)(BidsHistoryList);
