import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { Typography } from "@material-ui/core";
import { SectionBlock } from '../components';
import { CardSectionForm } from '../forms';

class CardInformationSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const extendedInitialValues = {
      credit_card_last_numbers: `**** **** **** ${
        initialValues.buyer.credit_card_last_numbers
      }`
    };

    return (
      <SectionBlock
        title="Current Card Information"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
        editButtonLabel={initialValues.buyer.credit_card_last_numbers ? 'Edit' : 'Add Card'}
      >
        {(initialValues.buyer.credit_card_last_numbers || isEditable) &&
          <CardSectionForm
            initialValues={extendedInitialValues}
            isDisabled={!isEditable}
            onSubmitSuccess={this.toggleEdit}
          />
        }
        {(!initialValues.buyer.credit_card_last_numbers && !isEditable) &&
          <Typography>
            You don’t have a credit card on file
          </Typography>
        }
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('creditCardSectionForm')),
  resetForm: () => dispatch(reset('creditCardSectionForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(CardInformationSection);
