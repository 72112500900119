import {
  LIST_AUCTIONS_REQUEST,
  LIST_AUCTIONS_SUCCESS,
  LIST_AUCTIONS_FAILURE,
  LIST_AUCTIONS_CLEAR,
  CREATE_AUCTION_REQUEST,
  CREATE_AUCTION_SUCCESS,
  CREATE_AUCTION_FAILURE,
  AUCTIONS_SET_PAGE,
  AUCTIONS_SET_PER_PAGE,
  AUCTIONS_SET_SEARCH_DATE,
  AUCTIONS_UNSET_SUCCESS,
  AUCTIONS_GET_CURRENT_REQUEST,
  AUCTIONS_GET_CURRENT_SUCCESS,
  AUCTIONS_GET_CURRENT_FAILURE,
  AUCTIONS_GET_NEXT_REQUEST,
  AUCTIONS_GET_NEXT_SUCCESS,
  AUCTIONS_GET_NEXT_FAILURE,
  AUCTIONS_GET_BY_ID_REQUEST,
  AUCTIONS_GET_BY_ID_SUCCESS,
  AUCTIONS_GET_BY_ID_FAILURE,
  LIST_AUCTION_VEHICLES_REQUEST,
  LIST_AUCTION_VEHICLES_SUCCESS,
  LIST_AUCTION_VEHICLES_FAILURE,
  AUCTION_EXPORT_SELLER_REPORT_REQUEST,
  AUCTION_EXPORT_SELLER_REPORT_SUCCESS,
  AUCTION_EXPORT_SELLER_REPORT_FAILURE,
  AUCTION_EXPORT_BUYER_REPORT_REQUEST,
  AUCTION_EXPORT_BUYER_REPORT_SUCCESS,
  AUCTION_EXPORT_BUYER_REPORT_FAILURE,
  FILTER_AUCTIONS_REQUEST,
  FILTER_AUCTIONS_SUCCESS,
  AUCTIONS_SET_TYPE
} from '../constants/actionTypes';
import { VEHICLES_PER_PAGE, AUCTIONS_PER_PAGE } from '../constants';
import {
  AUCTION_VEHICLES_RESET_MODAL,
  AUCTION_VEHICLES_SET_PAGE,
  AUCTION_VEHICLES_SET_MODAL,
  FILTER_AUCTION_VEHICLES_REQUEST,
  FILTER_AUCTION_VEHICLES_SUCCESS,
  AUCTION_DETAILS_BIDDERS_SUCCESS,
  AUCTION_DETAILS_BIDDERS_REQUEST,
  AUCTION_DETAILS_BIDDERS_RESET
} from '../actions/auctions';
import {
  AUCTION_DETAIL_SET_COUNTER_SUCCESS,
  AUCTION_DETAIL_AWARD_BUYER_SUCCESS,
  AUCTION_DETAIL_AWARD_BUYER_FAILURE,
  AUCTION_DETAIL_SET_NOTES_SUCCESS
} from '../actions/vehicles';
import { UPDATE_EASY_PAY_TRANSACTION_FAILURE, UPDATE_EASY_PAY_TRANSACTION_SUCCESS } from '../actions';

const initialState = {
  loading: true,
  submiting: false,
  itemsData: {
    rows: [],
    count: 0
  },
  auctionVehicles: {
    rows: [],
    count: 0,
    page: 1,
    perPage: VEHICLES_PER_PAGE,
    filterCount: {},
    bidders: {
      loading: false,
      success: false,
      data: []
    }
  },
  auctionVehiclesModal: null,
  page: 1,
  perPage: AUCTIONS_PER_PAGE,
  auctionType: 'autoaxess',
  searchDate: null,
  error: '',
  success: false,
  current: null,
  currentFrontline: null,
  nextAuction: {},
  aucById: {},
  reportSellerLink: null,
  reportBuyerLink: null
};

const auctions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_AUCTIONS_REQUEST:
      return { ...state, loading: true };
    case LIST_AUCTIONS_SUCCESS:
      // eslint-disable-next-line
      const alredyExistingAuctions = state.itemsData.rows.map(auction => auction.id);
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows:
            state.page === 1
              ? payload.data.rows
              : [...state.itemsData.rows, ...payload.data.rows.filter(({ id }) => !alredyExistingAuctions.includes(id))]
        }
      };
    case LIST_AUCTIONS_CLEAR:
      return { ...state, itemsData: initialState.itemsData };
    case FILTER_AUCTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AUCTION_DETAIL_SET_COUNTER_SUCCESS:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.data.id) {
              return payload.data;
            }
            return vehicle;
          })
        }
      };
    case AUCTION_DETAIL_SET_NOTES_SUCCESS:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.data.id) {
              return {
                ...vehicle,
                ...payload.data
              };
            }
            return vehicle;
          })
        }
      };
    case AUCTION_DETAIL_AWARD_BUYER_SUCCESS:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.data.id) {
              return payload.data;
            }
            return vehicle;
          })
        }
      };
    case AUCTION_DETAIL_AWARD_BUYER_FAILURE:
      payload.errorData = payload.response.data.data;
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.errorData.id) {
              return { ...vehicle, ...payload.errorData };
            }
            return vehicle;
          })
        }
      };
    case FILTER_AUCTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_AUCTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    case LIST_AUCTION_VEHICLES_REQUEST:
      return { ...state, loading: true };
    case FILTER_AUCTION_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true,
        auctionVehicles: {
          ...state.auctionVehicles,
          page: 2
        }
      };
    case FILTER_AUCTION_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: payload.data.rows,
          count: payload.data.count,
          filterCount: payload.data.filter_count || state.auctionVehicles.filterCount
        }
      };
    case AUCTION_VEHICLES_SET_MODAL:
      return {
        ...state,
        auctionVehiclesModal: payload
      };
    case AUCTION_VEHICLES_RESET_MODAL:
      return {
        ...state,
        auctionVehiclesModal: null
      };
    case AUCTION_VEHICLES_SET_PAGE:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          page: payload
        }
      };
    case LIST_AUCTION_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        auctionVehicles: {
          ...state.auctionVehicles,
          count: payload.data.count,
          rows: [...state.auctionVehicles.rows, ...payload.data.rows],
          filterCount: payload.data.filter_count || state.auctionVehicles.filterCount
        }
      };
    case LIST_AUCTION_VEHICLES_FAILURE:
      return { ...state, loading: false, submitting: false };
    case CREATE_AUCTION_REQUEST:
      return {
        ...state,
        loading: true,
        submitting: true,
        success: false,
        error: ''
      };
    case CREATE_AUCTION_SUCCESS: {
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: [payload.data, ...state.itemsData.rows],
          count: +state.itemsData.count + 1
        },
        loading: false,
        submitting: false,
        success: true,
        error: ''
      };
    }
    case CREATE_AUCTION_FAILURE:
      return { ...state, loading: false, submitting: false, success: false };
    case AUCTIONS_SET_PAGE:
      return { ...state, page: payload };
    case AUCTIONS_SET_TYPE:
      return { ...state, page: 1, auctionType: payload };
    case AUCTIONS_SET_PER_PAGE:
      return { ...state, loading: false, perPage: payload };
    case AUCTIONS_SET_SEARCH_DATE:
      return { ...state, loading: false, searchDate: payload };
    case AUCTIONS_UNSET_SUCCESS:
      return { ...state, loading: false, success: false };
    case AUCTIONS_GET_CURRENT_REQUEST:
      return { ...state, loading: true };
    case AUCTIONS_GET_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        current: payload.data.autoaxess,
        currentFrontline: payload.data.frontline
      };
    case AUCTIONS_GET_CURRENT_FAILURE:
      return {
        ...state,
        loading: false,
        current: payload.data
      };
    case AUCTIONS_GET_NEXT_REQUEST:
      return { ...state, loading: true };
    case AUCTIONS_GET_NEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        nextAuction: payload.data.auction
      };
    case AUCTIONS_GET_NEXT_FAILURE:
      return { ...state, loading: false, submitting: false };
    case AUCTIONS_GET_BY_ID_REQUEST:
      return { ...state, loading: true };
    case AUCTIONS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        aucById: (payload && payload.data) || {}
      };
    case AUCTIONS_GET_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        aucById: (payload && payload.data) || {}
      };
    case AUCTION_EXPORT_SELLER_REPORT_REQUEST:
    case AUCTION_EXPORT_BUYER_REPORT_REQUEST:
      return { ...state, loading: true };
    case AUCTION_EXPORT_SELLER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportSellerLink: payload.data
      };
    case AUCTION_EXPORT_BUYER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportBuyerLink: payload.data
      };
    case AUCTION_EXPORT_SELLER_REPORT_FAILURE:
    case AUCTION_EXPORT_BUYER_REPORT_FAILURE:
      return { ...state, loading: false, submitting: false };
    case AUCTION_DETAILS_BIDDERS_REQUEST:
      console.log({
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          bidders: {
            ...state.auctionVehicles.bidders,
            success: false,
            loading: true
          }
        }
      });
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          bidders: {
            ...state.auctionVehicles.bidders,
            success: false,
            loading: true
          }
        }
      };
    case AUCTION_DETAILS_BIDDERS_SUCCESS:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          bidders: {
            ...state.auctionVehicles.bidders,
            data: payload,
            success: true,
            loading: false
          }
        }
      };
    case AUCTION_DETAILS_BIDDERS_RESET:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          bidders: {
            ...state.auctionVehicles.bidders,
            data: [],
            success: false,
            loading: false
          }
        }
      };
    case UPDATE_EASY_PAY_TRANSACTION_SUCCESS:
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.id) {
              return { ...vehicle, ...payload };
            }
            return vehicle;
          })
        }
      };
    case UPDATE_EASY_PAY_TRANSACTION_FAILURE:
      payload.errorData = payload.response.data.data;
      return {
        ...state,
        auctionVehicles: {
          ...state.auctionVehicles,
          rows: state.auctionVehicles.rows.map(vehicle => {
            if (vehicle.id === payload.errorData.id) {
              return { ...vehicle, ...payload.errorData };
            }
            return vehicle;
          })
        }
      };
    default:
      return state;
  }
};

export default auctions;
