import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketMessage } from '../api/types';
import {
  WS_VEHICLE_TIME_END,
  WS_VEHICLE_NOTES_UPDATE,
  WS_NEW_PROXY,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_NEW_BID
} from 'constants/actionTypes';
import { salesAdapter, salesApiSlice as api } from 'services/api/sales';
import getSocket from '.';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const useMySalesSocketEffect = (data: any) => {
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>;
  const { page, perPage, filters } = useSelector((state: any) => state.mySales);
  const user = useSelector((state: any) => state.user.user);

  const listener = ({ payload, type }: SocketMessage) => {
    if (type === WS_NEW_BID) {
      dispatch(
        api.util.updateQueryData(
          'getMySales',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            salesAdapter.updateOne(draft.rows, {
              id: payload.vehicle_id,
              changes: {
                highest_bid: payload
              }
            });
          }
        )
      );
    }

    if (type === WS_NEW_PROXY) {
      dispatch(
        api.util.updateQueryData(
          'getMySales',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            salesAdapter.updateOne(draft.rows, {
              id: payload.vehicle_id,
              changes: {
                proxy_bid: payload
              }
            });
          }
        )
      );
    }

    if (type === WS_VEHICLE_DETAILS_UPDATE) {
      dispatch(
        api.util.updateQueryData(
          'getMySales',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            salesAdapter.updateOne(draft.rows, { id: payload.id, changes: payload });
          }
        )
      );
    }

    if (type === WS_VEHICLE_NOTES_UPDATE) {
      dispatch(
        api.util.updateQueryData(
          'getMySales',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            salesAdapter.updateOne(draft.rows, {
              id: payload.vehicleId,
              changes: { notes: payload.notes }
            });
          }
        )
      );
    }

    if (type === WS_VEHICLE_TIME_END) {
      dispatch(
        api.util.updateQueryData(
          'getMySales',
          {
            limit: perPage,
            offset: page,
            ...filters
          },
          draft => {
            const exists = salesAdapter.getSelectors().selectById(draft.rows, payload.id);
            const isMyVehicle = user?.id === payload.user_id;
            if (!isMyVehicle) return;
            const shouldRemove = filters.auctionStatus === 'active';
            if (shouldRemove && exists) {
              salesAdapter.removeOne(draft.rows, payload.id);
              draft.count -= 1;
            } else {
              salesAdapter.upsertOne(draft.rows, payload);
            }

            dispatch(
              // @ts-ignore
              api.util.updateQueryData('getMySalesCounters', undefined, counterDraft => {
                counterDraft.pending += 1;
                counterDraft.active -= 1;
              })
            );
          }
        )
      );
    }
  };

  useEffect(() => {
    const vehicleIds = data?.rows?.ids ?? [];
    const socket = getSocket();

    socket.emit('subscribe', {
      prefix: `vehicles`,
      ids: vehicleIds
    });
    socket.emit('subscribe', {
      prefix: `vehicles-notes:${user.id}`,
      ids: vehicleIds
    });
    socket.on('message', listener);

    return () => {
      socket.emit('unsubscribe', {
        prefix: `vehicles`,
        ids: vehicleIds
      });
      socket.emit('unsubscribe', {
        prefix: `vehicles-notes:${user.id}`,
        ids: vehicleIds
      });
      socket.off('message', listener);
    };
  }, [dispatch, data?.rows?.ids, perPage, page, filters, user.id]);
};
