import React from 'react';
import { RadioGroup, FormHelperText, FormControl } from '@material-ui/core';
import styled from 'styled-components';

const StyledError = styled(FormHelperText)`
  && {
    margin-top: -5px;
  }
`;

const InlineFormRadioGroup = ({
  input: { onChange, value, ...inputProps },
  meta: { touched, invalid, error },
  onChange: onChangeFormField,
  ...props
}) => (
  <FormControl>
    <div>
      <RadioGroup
        {...inputProps}
        {...props}
        row
        value={value}
        onChange={(event, inputValue) => {
          onChange(inputValue);
          if (onChangeFormField) {
            onChangeFormField(inputValue);
          }
        }}
      />
    </div>
    {touched && invalid && error && <StyledError error>{error}</StyledError>}
  </FormControl>
);

export default InlineFormRadioGroup;
