import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { PageHeader } from '../../../../components';
import {
  listCurrentSellerInvoices,
  sellersInvoicesSetPage,
  sellersInvoicesSetPerPage
} from '../../../../actions/financials';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: 23px 13px;
    border-bottom: none;
  }
`;

const Header = ({ history, refreshList, setPage, filterValues }) => (
  <PageHeader>
    <ButtonBase onClick={history.goBack}>
      <Icon>arrow_back</Icon>
    </ButtonBase>
    <StyledTypography variant="title">Invoices</StyledTypography>
    <span className="flex-grow" />
    <ButtonBase
      onClick={() => {
        setPage(0);
        refreshList({ params: { ...filterValues } });
      }}
    >
      <Icon>refresh</Icon>
    </ButtonBase>
  </PageHeader>
);

const mapStateToProps = state => ({
  currentSeller: state.financials.currentSeller,
  filterValues: getFormValues('invoicesFilterForm')(state)
});

const mapDispatchToProps = dispatch => ({
  refreshList: data => dispatch(listCurrentSellerInvoices(data).request),
  setPage: page => dispatch(sellersInvoicesSetPage(page)),
  setPerPage: perPage => dispatch(sellersInvoicesSetPerPage(perPage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
