import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { InfoBlock } from '../../InfoBlock';
import { useState } from 'react';
import { SellerInfo } from './SellerInfo';
import { TransportationInfo } from './TransportationInfo';

const Wrapper = styled.div`
  display: flex;
  padding: 14px 24px 22px 14px;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  gap: 14px;
  padding-bottom: 28px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  border: ${props => (props.isActive ? '1px solid rgba(204, 204, 204, 1)' : 'none')};
  background: ${props => (props.isActive ? '#fff' : 'transparent')};
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  height: 44px;
  cursor: pointer;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SellerAndTransportInfo = () => {
  const vehicle = useViewedVehicle();
  const [activeTab, setActiveTab] = useState('seller');

  if (!vehicle) return null;

  return (
    <InfoBlock>
      <Wrapper>
        <Title>
          <Tab onClick={() => setActiveTab('seller')} isActive={activeTab === 'seller'}>
            <span>Seller Information</span>
          </Tab>
          <Tab onClick={() => setActiveTab('transport')} isActive={activeTab === 'transport'}>
            <span>Transportation Cost</span>
          </Tab>
        </Title>
        {activeTab === 'seller' && <SellerInfo />}
        {activeTab === 'transport' && <TransportationInfo />}
      </Wrapper>
    </InfoBlock>
  );
};
