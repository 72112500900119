import React, { useState } from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PageHeader } from '../../../../components';
import { filterVehicleSalesHistory } from '../../../../actions/vehicles';
import { useScreen } from 'usehooks-ts';
import { Line, MainContentWrapper, Wrapper } from 'components/HeaderUI';
import { ExpandedMenuItem, MenuItem } from 'components/Icons/MenuIcon';
import { MobileSalesHistoryFilters } from './MobileFilters';
import { useGetSalesHistoryQuery } from 'services/api/sales';
import { resetFilters } from 'services/salesHistorySlice/salesHistorySlice';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: 20px 13px 20px 13px;
  }
`;

const Header = () => {
  const dispatch = useDispatch();

  const [expanded, setIsExpanded] = useState(false);
  const { width } = useScreen();
  const isMobile = width < 567;

  const { page, perPage, filters } = useSelector(state => state.salesHistory);
  const { refetch } = useGetSalesHistoryQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const toggleExpanded = () => setIsExpanded(!expanded);

  return (
    <>
      <div style={{ height: '60px' }} />
      <Wrapper isMobileExpanded={expanded && isMobile}>
        <MainContentWrapper>
          <StyledTypography variant="title">Sales History</StyledTypography>
          <span className="flex-grow" />
          <ButtonBase onClick={() => dispatch(resetFilters())}>
            <Icon>refresh</Icon>
          </ButtonBase>
          {isMobile && (
            <ButtonBase onClick={toggleExpanded}>{expanded ? <ExpandedMenuItem /> : <MenuItem />}</ButtonBase>
          )}
        </MainContentWrapper>
        {isMobile && expanded && <Line />}
        {isMobile && expanded && <MobileSalesHistoryFilters />}
      </Wrapper>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  refreshList: () => dispatch(filterVehicleSalesHistory().request)
});

export default connect(null, mapDispatchToProps)(Header);
