import { lazy } from 'react';

const VehiclesList = lazy(() => import('./VehiclesList'));
const VehicleManualUpload = lazy(() => import('./VehicleManualUpload'));
const LocationsList = lazy(() => import('./Locations/list'));
const AuctionsList = lazy(() => import('./AuctionsList'));
const AuctionDetail = lazy(() => import('./AuctionDetail'));
const CustomersList = lazy(() => import('./CustomersList'));
const AccountsList = lazy(() => import('./AccountsList'));
const AccountLogsList = lazy(() => import('./AccountLogsList'));
const AdminSellers = lazy(() => import('./AdminSellers'));
const CustomerDetailPage = lazy(() => import('./CustomerDetailPage'));
const BuyerFeesPage = lazy(() => import('./BuyerFeesPage'));
const AuctionSummaryReportPage = lazy(() => import('./AuctionSummaryReportPage'));
const AuctionSummaryReportExportPage = lazy(() => import('../Shared/AucttionSummaryReportExport'));
const ListerSummaryReportPage = lazy(() => import('./ListerSummaryReportPage'));
const BuyersDocs = lazy(() => import('./Users/Documents/BuyerDocs'));
const DeclinedCardsPage = lazy(() => import('./DeclinedCardsPage'));
const AdditionalFeesPage = lazy(() => import('./AdditionalFeesPage'));
const BannersList = lazy(() => import('./BannersList'));
const TransactionsList = lazy(() => import('./TransactionsList'));
const SiteStatisticsDetails = lazy(() => import('./SiteStatisticsDetails'));
const SiteStatistics = lazy(() => import('./SiteStatistics'));
const Logs = lazy(() => import('./Logs'));

export {
  AccountLogsList,
  AccountsList,
  CustomersList,
  VehiclesList,
  VehicleManualUpload,
  LocationsList,
  AuctionsList,
  AuctionDetail,
  AdminSellers,
  CustomerDetailPage,
  BuyerFeesPage,
  AuctionSummaryReportPage,
  AuctionSummaryReportExportPage,
  ListerSummaryReportPage,
  BuyersDocs,
  DeclinedCardsPage,
  AdditionalFeesPage,
  BannersList,
  TransactionsList,
  SiteStatisticsDetails,
  SiteStatistics,
  Logs
};
