import { Button, Dialog, Icon } from '@material-ui/core';
import styled from 'styled-components';
import { InfoIcon } from '../../../../components/Icons';
import { Field, useField } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { closeNotesDialog, useOpenedNotesDialog } from '../../../../services/uploadVehicle/uploadVehicleSlice';
import { useCallback, useEffect, useState } from 'react';
import {
  UpdateImagePayload,
  useUpdateCheckboxMutation,
  useUpdateSectionImageMutation,
  useUpdateVehicleSectionMutation,
} from '../../../../services/api/upload-vehicle';
import { snackShow } from '../../../../actions';
import { IMask, useIMask } from 'react-imask';
import { maxLength } from '../../../../utils/validators';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';

const StyledDialog = styled(Dialog)`
  .add-note-paper {
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 1);
    width: 530px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 16px;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;
`;

const StyledHelperText = styled.div`
  color: #757575;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 12px;
`;

const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-end;
`;

const NoteLabel = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  color: rgba(148, 148, 148, 1);
`;

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  height: 44px;
  width: 115px;
`;

const StyledCancelButton = styled(Button)`
  height: 44px;
  width: 115px;
  color: rgba(34, 90, 145, 1);
  border: 1px solid rgba(34, 90, 145, 1);
  background: #fff;

  &:hover {
    color: rgba(34, 90, 145, 1);
    border: 1px solid rgba(34, 90, 145, 1);
    background: #fff;
  }
`;

const StyledTextArea = styled.textarea`
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 8px;
  background: #fff;
  resize: none;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
`;

const StyledInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  border: none;
`;

const maxLength50 = maxLength(50);

export const AddNoteDialog = () => {
  const openedNotesDialog = useOpenedNotesDialog();

  const dispatch = useDispatch();
  const close = () => dispatch(closeNotesDialog());

  return (
    <StyledDialog
      onClose={close}
      onBackdropClick={close}
      open={!!openedNotesDialog}
      classes={{
        paperScrollPaper: 'add-note-paper'
      }}
    >
      {openedNotesDialog && <DialogContent fieldName={openedNotesDialog} />}
    </StyledDialog>
  );
};

const DialogContent = ({ fieldName }: { fieldName: string }) => {
  const dispatch = useDispatch();

  const [updateImage] = useUpdateSectionImageMutation();
  const [updateCheckbox] = useUpdateCheckboxMutation();
  const [updateSection] = useUpdateVehicleSectionMutation();

  const vehicleId = useGetVehicleId();
  const close = () => dispatch(closeNotesDialog());

  const {
    input: { value: fieldValue }
  } = useField(fieldName);

  const isTiresAndWheels = fieldName.startsWith('tires_and_wheels');
  const fieldType = isTiresAndWheels ? 'label' : 'notes';

  const [localInput, setLocalInput] = useState(fieldValue.notes || '');

  const handleSectionSave = useCallback(async () => {
    const [sectionName, subSectionName] = fieldName.split('.');
    const response = await updateSection({
      vehicleId,
      payload: {
        sectionName,
        subSectionName,
        notes: localInput
      }
    });
    return 'data' in response;
  }, [localInput]);

  const handleImageSave = useCallback(async () => {
    const updateImagePayload: UpdateImagePayload = {
      vehicleId,
      id: fieldValue.id
    };

    if (isTiresAndWheels) {
      updateImagePayload.label = localInput;
    } else {
      updateImagePayload.notes = localInput;
    }

    const response = await updateImage(updateImagePayload);
    return 'data' in response;
  }, [localInput]);

  const handleCheckboxSave = useCallback(async () => {
    const response = await updateCheckbox({
      checkboxId: fieldValue.id,
      payload: {
        notes: localInput
      }
    });
    return 'data' in response;
  }, [localInput]);

  const getHandler = () => {
    if (fieldName.includes('images')) {
      return handleImageSave;
    }

    if (fieldName.includes('checkboxes')) {
      return handleCheckboxSave;
    }

    return handleSectionSave;
  };

  const handleSave = useCallback(
    async (formCb: (v: string) => void) => {
      const handler = getHandler();
      const successfulSave = await handler();
      if (successfulSave) {
        formCb(localInput.trim());
      } else {
        dispatch(snackShow({ message: 'Error while uploading notes', type: 'error' }));
      }
      close();
    },
    [localInput]
  );

  return (
    <Field validate={maxLength50} name={`${fieldName}.${fieldType}`}>
      {props => {
        return (
          <Wrapper>
            <HeaderRow>
              <Title>Add Note</Title>
              <Icon style={{ cursor: 'pointer' }} onClick={close}>
                close
              </Icon>
            </HeaderRow>
            <MainContent>
              {!isTiresAndWheels && (
                <LabelRow>
                  <NoteLabel>Note</NoteLabel>
                  <HelperTextContainer>
                    <StyledHelperText>Max. 50 characters</StyledHelperText>
                    <InfoIcon />
                  </HelperTextContainer>
                </LabelRow>
              )}
              {isTiresAndWheels ? (
                <MaskedInput label={fieldValue.label || ''} setValue={setLocalInput} />
              ) : (
                <StyledTextArea
                  rows={2}
                  onChange={e => {
                    setLocalInput(e.target.value);
                  }}
                  value={localInput}
                />
              )}
            </MainContent>
            <ButtonRow>
              <StyledCancelButton onClick={close} variant="contained" color="primary">
                Cancel
              </StyledCancelButton>
              <StyledButton
                onClick={() => {
                  const parsedInput = localInput.trim();
                  if (parsedInput !== '' && parsedInput.length > 50) {
                    dispatch(snackShow({ message: 'Input is too long. Max length is 50 characters', type: 'error' }));
                    return;
                  }

                  handleSave(props.input.onChange);
                }}
                variant="contained"
                color="primary"
              >
                Save
              </StyledButton>
            </ButtonRow>
          </Wrapper>
        );
      }}
    </Field>
  );
};

type MaskedInputProps = {
  label: string;
  setValue: (v: string) => void;
};

const MaskedInput = ({ label, setValue }: MaskedInputProps) => {
  const { mask, defaultValue } = parseTiresAndWheelsInput(label.replaceAll('a', '\\a'));
  const { ref, value } = useIMask(
    {
      mask,
      lazy: false,
      blocks: {
        LL: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 32
        }
      }
    },
    {
      defaultUnmaskedValue: defaultValue
    }
  );

  useEffect(() => {
    setValue(value);
  }, [value]);

  return <StyledInput autoFocus ref={ref as any} />;
};

const parseTiresAndWheelsInput = (label: string) => {
  const startIndex = label.lastIndexOf(' ') + 1;
  const endIndex = label.lastIndexOf('/');

  const leftPart = label.slice(0, startIndex);
  const rightPart = label.slice(endIndex);

  let value: string | null;
  const hasValue = label.charAt(startIndex) !== '_';

  if (!hasValue) {
    value = null;
  }

  if (startIndex === endIndex) {
    value = label.charAt(startIndex);
  } else {
    value = label.substring(startIndex, endIndex);
  }

  return {
    mask: `${leftPart}LL${rightPart}`,
    defaultValue: value
  };
};
