import React from 'react';
import { Paper, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

import './index.scss';

const FormField = ({ title, className, children, ...rest }) => (
  <Paper className={className} {...rest}>
    <Typography
      variant="title"
      color="inherit"
      style={{ flexGrow: 1 }}
      className="title"
    >
      {title}
    </Typography>
    <Divider />
    {children}
  </Paper>
);

export default FormField;

FormField.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

FormField.defaultProps = {
  className: 'form-step'
};
