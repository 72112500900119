import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import HeaderTitle from '../components/HeaderTitle';

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
`;

const StatusHeader = () => (
  <StyledHeaderWrapper>
    <HeaderTitle
      titleJSX={<StyledHeaderTitle>In Progress</StyledHeaderTitle>}
    />
  </StyledHeaderWrapper>
);

export default StatusHeader;
