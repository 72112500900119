import { FormRenderProps, FieldRenderProps } from 'react-final-form';

export const hasVisibleFormError = <T>(props: FormRenderProps<T>) => {
  if (!props.touched) return false;

  for (const errorFieldKey in props.errors) {
    if (props.touched[errorFieldKey]) {
      return true;
    }
  }

  return false;
};
