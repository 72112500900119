import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import moment from 'moment';
import {
  STATISTICS_REQUEST,
  FILTER_STATISTICS_REQUEST,
  listStatistics,
  filterStatistics
} from '../actions/statistics';
import { Api } from '../utils';

const getFetchQuery = state => {
  const values = getFormValues('listStatisticsFilterForm')(state) || {};
  return {
    ...values,
    created_at_from: values.created_at_from
      ? moment(values.created_at_from)
          .utc()
          .format()
      : null,
    created_at_to: values.created_at_to
      ? moment(values.created_at_to)
          .utc()
          .format()
      : null
  };
};

function* fetchStatistics({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.statistics.fetchStatistics, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listStatistics(data).success);
  } catch (e) {
    yield put(listStatistics(e).failure);
  }
}

function* filterBidsSaga({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.statistics.fetchStatistics, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(filterStatistics(data).success);
  } catch (e) {
    yield put(filterStatistics(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(STATISTICS_REQUEST, fetchStatistics),
    takeLatest(FILTER_STATISTICS_REQUEST, filterBidsSaga)
  ]);
}
