import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import KBB from '../../../../img/kbb_logo.png';
import { SectionTitle, SectionWrapper } from './index';

const KBBIcon = styled.img`
  height: 50px;
  width: 85px;
  position: absolute;
  right: 35px;
`;

const StyledKarPowerLink = styled.a`
  font-weight: 500;
`;

const StyledAdaptiveWrapper = styled(SectionWrapper)`
  @media (min-width: 1280px) {
    display: none;
  }
`;

class KeyFeaturesMobile extends React.Component {
  state = {
    showMore: false
  };

  renderOptions = () => {};

  render() {
    const { options = [], currentVehicle } = this.props;
    const { showMore } = this.state;
    const selectedOptions = options.filter(option => option.is_typical);
    const renderOptions = showMore
      ? selectedOptions
      : selectedOptions.slice(0, 7);

    return (
      <StyledAdaptiveWrapper className="key-features-mobile">
        <SectionTitle>Equipment</SectionTitle>
        <KBBIcon alt="provided_by_kbb" src={KBB} />
        {selectedOptions &&
          renderOptions.map((option)=> (
            <Grid key={option.id} item xs={12}>
              <Typography paragraph variant="body1">
                {option.option_name}
              </Typography>
            </Grid>
          ))}
        {selectedOptions.length > 7 && (
          <Grid item xs={12}>
            <Button
              color="primary"
              size="small"
              onClick={() => this.setState({ showMore: !showMore })}
            >
              {showMore ? 'View Less' : 'View More'}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography color="primary">
            <StyledKarPowerLink
              href={currentVehicle.kbb_report_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Vehicle Report
            </StyledKarPowerLink>
          </Typography>
        </Grid>
      </StyledAdaptiveWrapper>
    );
  }
}

export default KeyFeaturesMobile;
