import styled from 'styled-components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { InfoBlock } from '../InfoBlock';

const Wrapper = styled.div`
  display: flex;
  padding: 32px 120px 24px 32px;
  flex-direction: column;
  gap: 14px;
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  gap: 16px;
`;

const TextInfo = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  height: 100%;
  font-weight: 400;
  line-height: 20px;
  color: rgba(97, 97, 97, 1);
`;

const Title = styled.div`
  display: flex;
  gap: 14px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
`;

export const InspectorComments = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle || !vehicle.dealer_comments) return null;

  return (
    <InfoBlock>
      <Wrapper>
        <Title>Inspector Comments</Title>
        <MainContent>
          <TextInfo>{vehicle.dealer_comments}</TextInfo>
        </MainContent>
      </Wrapper>
    </InfoBlock>
  );
};
