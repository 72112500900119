import React from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Grid, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import { modalsToggle } from '../../../../../actions';
import SectionTitle from '../../components/SectionTitle';
import ModalWrapper from '../../../../../components/ModalWrapper';
import { FormFormattedField, FormInput } from '../../../../../components/Form';
import { required } from '../../../../../utils/validators';
import { CounterSection } from '../../components';
import { SendOfferModal } from '../../../../../components/Offers';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { ActionButton } from '../../../../../components/ActionButton';

const StyledForm = styled.form`
  background: #f5f5f5;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 5px;
  div {
    flex-grow: 1;
  }
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  && {
    font-size: 16px;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    margin: 8px 8px 8px 32px;
    padding: 8px 55px;
  }
`;

const CounterSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > div > p {
    margin: 0;
  }
`;

const CounterButton = styled(Button)`
  background-color: #3994de;

  &:hover {
    background-color: #3994de;
  }
`;

const CounterComponent = ({ bid, vehicle }) => {
  const dispatch = useDispatch();

  const toggleCounterModal = () => {
    dispatch(modalsToggle(`sendVehicleOffer-${vehicle.id}`));
  };

  if (!bid.bidder) {
    return null;
  }

  return (
    <CounterSectionWrapper>
      <Grid item xs={12} sm={8}>
        <SectionTitle>{`Don't want to accept high bid from ${bid.bidder.dealership_name}?`}</SectionTitle>
      </Grid>
      <Grid item xs={12} sm={4}>
        <ActionButton onClick={toggleCounterModal} colorVariant="counter" fullWidth>
          Counter
        </ActionButton>
      </Grid>
      <SendOfferModal
        maxAmount={vehicle.reserve_price}
        form={`sendVehicleOffer-${vehicle.id}`}
        vehicle={vehicle}
      />
    </CounterSectionWrapper>
  );
};

const selector = formValueSelector('counterForm');

export default compose(
  reduxForm({
    form: 'counterForm',
    fields: ['amount']
  }),
  connect(
    state => ({
      amount: selector(state, 'amount')
    }),
    dispatch => ({
      toggleModal: modalId => dispatch(modalsToggle(modalId))
    })
  )
)(CounterComponent);
