import React from 'react';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import { StyledGrid } from '../components';
import { updateProfile } from '../../../../actions/user';
import { FormFormattedField, FormTextField } from '../../../../components';
import { required } from '../../../../utils/validators';
import { DATE_FORMAT } from '../../../../constants';

const isValidDate = date => {
  if (!date) {
    return undefined;
  }
  return moment(date, DATE_FORMAT, true).isValid()
    ? undefined
    : 'Dealer license expiry date is invalid';
};

const isExpired = date => {
  if (!date) {
    return undefined;
  }
  return moment().isBefore(moment(date, DATE_FORMAT))
    ? undefined
    : 'Dealer license is already expired';
};

const dealerLicenseValidation = value =>
  value && /^[a-zA-Z0-9-]{1,10}$/.test(value)
    ? undefined
    : 'Dealer license must contain from 1 to 10 digits';

const DealerLicenseForm = ({ handleSubmit, InputProps }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <StyledGrid item xs={12} sm={12}>
        <Field
          name="dealer_license_number"
          component={FormTextField}
          label="DMV Vehicle Dealer License Number"
          fullWidth
          InputProps={InputProps}
          validate={[required, dealerLicenseValidation]}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={12}>
        <Field
          name="dealer_license_expiry_date"
          component={FormFormattedField}
          label="DMV Vehicle Dealer License Expiry Date"
          InputProps={InputProps}
          fullWidth
          maskProps={{
            format: '##/##/####',
            placeholder: DATE_FORMAT,
            mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']
          }}
          placeholderOnFocus="MM/DD/YYYY"
          validate={[isValidDate, isExpired]}
        />
      </StyledGrid>
    </StyledGrid>
  </form>
);

export default reduxForm({
  form: 'dealerLicenseForm',
  onSubmit: (values, dispatch) =>
    new Promise((resolve, reject) =>
      dispatch(
        updateProfile({
          payload: {
            ...values,
            dealer_license_expiry_date: values.dealer_license_expiry_date
              ? moment
                  .utc(values.dealer_license_expiry_date, DATE_FORMAT)
                  .format()
              : null
          },
          resolve,
          reject
        }).request
      )
    ),
  enableReinitialize: true
})(DealerLicenseForm);
