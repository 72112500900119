import React, { Component } from 'react';
import print from 'print-js';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import { modalsToggle } from '../../../../actions';
import { ModalWrapper } from '../../../../components';
import { selectedVehicleSelector } from '../../../../selectors';
import { VEHICLE_STATUSES } from '../../../../constants';

const StyledModalWrapper = styled(ModalWrapper)`
  & > div:nth-child(2) {
    max-width: 100vw;
  }
`;

const StyledGridWrapper = styled(Grid)`
  font-weight: 500;
  text-align: center;
  @media (min-width: 600px) {
    > div:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
  }
  @media (max-width: 599px) {
    > div:nth-child(-n+3) {
      margin-top: 10px;
      max-width: 100%;
      flex-basis: 100%;
      text-align: left;
    }
  }
`;

const StyledLink = styled(Typography)`
  margin-top: 10px;
  font-weight: 500;
  color: #005a96;
`;

const StyledLinksWrapper = styled(Grid)`
  margin-top: 10px;
  border-left: 0!important;
`;

class BlueBookSection extends Component {
  state = {
    autoCheckReport: null
  };

  render() {
    const {
      currentUser,
      bluebook_value,
      bluebook_mileage,
      mileage,
      vin,
      vehicleId,
      ownerId,
      status,
    } = this.props;
    const { autoCheckReport } = this.state;

    const isBuyerLinkShown = currentUser.id === ownerId && status === VEHICLE_STATUSES.AWARDED;

    return (
      <StyledGridWrapper container>
        <Grid
          container
          direction="column"
          item
          xs={4}
        >
          <Typography gutterBottom variant="caption">
            Value Without Miles Adj
          </Typography>
          <NumberFormat
            displayType="text"
            prefix="$"
            value={bluebook_value}
            thousandSeparator
          />
        </Grid>
        <Grid
          container
          direction="column"
          item
          xs={4}
        >
          <Typography gutterBottom variant="caption">
            Blue Book® Lending Value
          </Typography>
          <NumberFormat
            displayType="text"
            prefix="$"
            value={bluebook_mileage}
            thousandSeparator
          />
        </Grid>
        <Grid
          container
          direction="column"
          item
          xs={4}
        >
          <Typography gutterBottom variant="caption">
            Mileage
          </Typography>
          <NumberFormat displayType="text" value={mileage} thousandSeparator />
        </Grid>
        <StyledLinksWrapper
          container
          item
          xs={12}
          justify={isBuyerLinkShown ? 'space-between' : 'flex-end'}
        >
          {
            isBuyerLinkShown && (
            <Typography>
              <StyledLink
                component={Link}
                to={`/vehicles/${vehicleId}/buyer-docs`}
              >
              View Buyer Docs
              </StyledLink>
            </Typography>
            )
          }
          <Typography>
            <StyledLink
              component={Link}
              to={`/vehicles/${vin}/autocheck-report`}
            >
              Autocheck History Report
            </StyledLink>
          </Typography>
          <StyledModalWrapper
            dialogProps={{ fullScreen: true }}
            showCancel={false}
            title="AutoCheck Report"
            fullscreen
            modalId="autoCheckModal"
            handleSubmit={() => print({ printable: 'printable', type: 'html' })}
            submitText="Print"
          >
            <div
              id="printable"
              dangerouslySetInnerHTML={{ __html: autoCheckReport }}
            />
          </StyledModalWrapper>
        </StyledLinksWrapper>
      </StyledGridWrapper>
    );
  }
}

const mapStateToProps = state => ({
  vehicleId: selectedVehicleSelector(state).id,
  status: selectedVehicleSelector(state).status,
  ownerId: selectedVehicleSelector(state).user_id,
});

const mapDispatchToProps = dispatch => ({
  toggleReport: () => dispatch(modalsToggle('autoCheckModal'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlueBookSection);
