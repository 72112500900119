import { Collapse, Grid } from '@material-ui/core';
import FormField from '../../pages/Seller/VehicleAdd/FormField';
import { User } from '../../types/user';
import styled from 'styled-components';

const Text = styled.div`
  margin-top: 24px;
`;

export const AnotherUserEditing = ({ user }: { user: User }) => {
  const fullName = [user.first_name, user.last_name].filter(Boolean).join(' ');
  return (
    <Grid item xs={12}>
      <FormField className={'form-step warning'} title="Warning!!!">
        <Text>{`${fullName} is already editing this vehicle. Please contact them if you want to make changes`}</Text>
      </FormField>
    </Grid>
  );
};
