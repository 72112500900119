import styled from 'styled-components';
import { InfoIcon } from 'components/Icons';

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const InfoText = styled.span`
  font-size: 12px;
  color: #757575;
  font-weight: 400;
`;

export const BioMaxChars = () => {
  return (
    <Wrapper>
      <InfoText>Max. 500 characters</InfoText>
      <InfoIcon />
    </Wrapper>
  );
};
