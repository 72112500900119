import React from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonBase, Icon } from '@material-ui/core';
import styled from 'styled-components';
import { PageHeader, } from '../../../../components';

const StyledBackButton = styled(ButtonBase)`
  padding-right: 10px;
  span {
    font-size: 20px;
  }
`;

const Header = ({ history }) => (
  <PageHeader>
    <StyledBackButton onClick={() => history.goBack()}>
      <Icon>arrow_back</Icon>
    </StyledBackButton>
    EasyPay
  </PageHeader>
);

export default withRouter(Header);
