import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

import PageWrapper from '../PageWrapper';
import './index.scss';

const Breadcrumbs = ({ title, showRefresh }) => (
  <PageWrapper>
    <AppBar position="static" color="default" className="breadcrumbs-holder">
      <Toolbar>
        <Typography variant="title" color="inherit">
          {title}
        </Typography>
        <span className="flex-grow" />
        {showRefresh && (
          <IconButton aria-haspopup="true" color="inherit">
            <i className="material-icons">refresh</i>
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  </PageWrapper>
);

Breadcrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  showRefresh: PropTypes.bool
};

Breadcrumbs.defaultProps = {
  showRefresh: true
};

export default Breadcrumbs;
