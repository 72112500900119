import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { useSearchParams, useUpdateSearchParams } from '../../../../hooks';
import { AdminMakeOfferModal } from '../../../../components/Offers/AdminMakeOfferModal';
import { useHasOfferAuction } from '../hooks';
import { useSelector } from 'react-redux';

const StyledButton = styled.div`
  width: 80px;
  height: 32px;
  border-radius: 6px;
  background: #3994de;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  .offer-log-paper {
    height: 420px;
  }
`;

const accessJobs = ['Admin', 'Salesman', 'Manager']

export const AdminPlaceOfferButton = () => {
  const { id: auctionId } = useParams<{ id: string }>();

  const user = useSelector((state: any) => state.user.user)

  const params = useSearchParams();
  const isModalOpen = params.get('place_offer_opened') === 'true';

  const updateSearchParams = useUpdateSearchParams();

  const closeModal = () => {
    updateSearchParams({ place_offer_opened: 'false' });
  };

  const openModal = () => {
    updateSearchParams({ place_offer_opened: 'true' });
  };

  const shouldRender = useHasOfferAuction();
  if (!shouldRender) {
    return null;
  }

  if (!user?.job_title || !accessJobs.includes(user.job_title)) {
    return null
  }

  return (
    <>
      <StyledButton onClick={openModal}>Make offer</StyledButton>
      <StyledDialog
        onBackdropClick={closeModal}
        fullWidth
        maxWidth={'xs'}
        title="Create New Location"
        open={isModalOpen}
        classes={{ paperScrollPaper: 'offer-log-paper' }}
      >
        <AdminMakeOfferModal auctionId={Number(auctionId)} closeModal={closeModal} />
      </StyledDialog>
    </>
  );
};
