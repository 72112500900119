import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import { Header, Filter, ImagePreview, BidStatus } from './components';
import { Table, Timer, NoImagePlaceholder } from '../../../components';
import { VEHICLE_STATUSES, BIDS_PER_PAGE, DATE_FORMAT } from '../../../constants';
import { MobileInfo } from './components/MobileInfo';
import { Delimiter } from 'components/Table/common';
import { VehicleOptionsBlock } from '../VehicleSales/components/tableBlocks';
import { ButtonsBlock } from './components/ButtonsBlock';
import { useGetSellerManageVehiclesQuery } from 'services/api/manage-vehicles';
import { useWindowSize } from 'usehooks-ts';
import { setPage } from 'services/sellerVehicles/sellerVehiclesSlice';
import { MobileActionButtons } from './components/MobileActionButtons';

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }

  @media (min-width: 800px) {
    tr td:last-child {
      width: 160px;
    }
  }

  @media (max-width: 800px) {
    thead tr {
      height: 0px;
    }
  }
`;

const VehicleListFC = () => {
  const { page, perPage, filters } = useSelector(state => state.sellerVehicles);
  const { data, isFetching: loading } = useGetSellerManageVehiclesQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const dispatch = useDispatch();

  const vehicles = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const vehiclesCount = useMemo(() => Number(data?.count ?? 0), [data]);

  const loadMore = () => {
    if (page * perPage > vehiclesCount) return;
    dispatch(setPage(page + 1));
  };

  const { width = 0 } = useWindowSize();

  const currentAuctionId = useSelector(state => state.auctions?.current?.id);

  const getRowBackgroundColor = item => {
    if (item.status === 'pulled' || item.auction_id !== currentAuctionId) {
      return '#B0B0B04D';
    }

    return '#fff';
  };

  const isMobile = width < 800;
  const columns = !isMobile
    ? [
        { title: 'Vehicle Details', key: 'details' },
        { title: 'Time Left', key: 'time_left' },
        { title: 'Status', key: 'bid_status' },
        { title: 'Date Uploaded', key: 'date' },
        { title: 'Actions', key: 'actions', cellClass: 'text-right' }
      ]
    : [
        { title: '', key: 'details' },
        { title: '', key: 'delimiter' },
        { title: '', key: 'mobile_info' },
        { title: '', key: 'actions' }
      ];

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <div className="pageWrapper">
        {isMobile ? <MobileActionButtons /> : <Filter />}
        <StyledTableWrapper>
          <Table
            withMobileView
            columnsHead={columns}
            columnsData={convertVehicles(vehicles)}
            rowsCount={vehiclesCount}
            buttons={null}
            page={page}
            rowsPerPage={perPage}
            loadMore={loadMore}
            loading={loading}
            noDataText={
              !filters.status && !loading
                ? 'Once you add your first vehicle, you can manage that vehicle in this section.'
                : ''
            }
            getRowBackgroundColor={getRowBackgroundColor}
          />
        </StyledTableWrapper>
      </div>
    </div>
  );
};

const convertVehicles = (vehicles = []) => {
  return vehicles.map(item => {
    let vehicleTimer;
    let isShuffled;
    if (item.auction) {
      isShuffled = item.end_time_spacing !== null;
      const shuffledTimer = moment(item.auction.date_end)
        .add(item.end_time_spacing, 'seconds')
        .utc();
      const endAuction = moment(item.auction.date_end).utc();
      vehicleTimer = (() => {
        const endStatuses = [VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.PULLED];
        if (endStatuses.includes(item.status)) {
          return 0;
        }

        return isShuffled ? shuffledTimer : endAuction;
      })();
    }

    const timeLeftComponent = vehicleTimer != null ? <Timer toDate={vehicleTimer} /> : 'N/A';
    const formattedDate = moment(item.created_at).format(DATE_FORMAT);

    return {
      ...item,
      image:
        item.images && item.images[0] && item.images[0].url_thumb ? (
          <ImagePreview vehicleId={item.id} url={item.images[0].url_thumb} />
        ) : (
          <NoImagePlaceholder />
        ),
      time_left: timeLeftComponent,
      bid_status: <BidStatus status={item.status} vehicle={item} />,
      details: <VehicleOptionsBlock currentVehicle={item} />,
      date: formattedDate,
      mobile_info: (
        <MobileInfo
          columns={[
            { label: 'Time Left:', value: timeLeftComponent },
            {
              label: 'Status:',
              value: <BidStatus status={item.status} vehicle={item} />
            },
            {
              label: 'Date Uploaded:',
              value: formattedDate
            }
          ]}
        />
      ),
      delimiter: <Delimiter />,
      actions: <ButtonsBlock vehicle={item} />
    };
  });
};

const currentFilter = formValueSelector('sellerVehiclesFilterForm');

export default VehicleListFC;
