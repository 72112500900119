import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  font-size: 14px;
  white-space: ${props => (props.isGridView ? 'initial' : 'nowrap')};
  overflow: ${props => (props.isGridView ? 'initial' : 'hidden')};
  text-overflow: ${props => (props.isGridView ? 'initial' : 'ellipsis')};
  max-width: ${props => (props.isGridView ? 'initial' : '100%')};
  width: ${props => (props.justify !== 'space-between' ? '100%' : 'initial')};
  display: ${props => (props.justify !== 'space-between' ? 'block' : 'initial')};
  padding-right: ${props => (props.justify !== 'space-between' ? '5px' : 'initial')};
  color: ${props => (props.highlighted ? '#757575' : '#616161')};
  font-weight: ${props => (props.highlighted ? '500' : '400')};
  padding-right: 12px;

  &.value {
    min-width: fit-content;
    padding-right: 0;
  }
`;

const HelperText = styled.span`
  color: #b0b0b0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const StyledWrapper = styled(Grid)`
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
`;

const InfoElement = props => {
  const {
    bid,
    isGridView,
    item,
    type,
    value,
    justify,
    container,
    className = '',
    highlighted = false,
    helperText,
    nowrap = false
  } = props;
  return (
    <>
      <StyledWrapper
        nowrap={nowrap}
        container={container}
        item={!container}
        justify={justify}
        className={bid ? 'current-bid' : ''}
      >
        {justify !== 'space-between' ? (
          <StyledTypography justify={justify} isGridView={isGridView} variant={bid ? 'subheading' : 'body1'}>
            {`${item}:\u00A0`}
            {type === 'info' && value}
            {type === 'time' && <span>{value}</span>}
            {(type === 'currency' || type === 'miles') && (
              <NumberFormat
                value={value}
                displayType="text"
                thousandSeparator
                prefix={type === 'currency' ? '$' : ''}
              />
            )}
          </StyledTypography>
        ) : (
          <>
            <StyledTypography
              className={className}
              justify={justify}
              isGridView={isGridView}
              variant={bid ? 'subheading' : 'body1'}
            >
              {`${item}:\u00A0`}
            </StyledTypography>
            <StyledTypography
              justify={justify}
              isGridView={isGridView}
              variant={bid ? 'subheading' : 'body1'}
              className="value"
              highlighted={highlighted}
            >
              {(type === 'info' || type === 'time') && value}
              {(type === 'currency' || type === 'miles') && (
                <NumberFormat
                  value={value}
                  displayType="text"
                  thousandSeparator
                  prefix={type === 'currency' ? '$' : ''}
                />
              )}
            </StyledTypography>
          </>
        )}
      </StyledWrapper>
      {helperText ? <HelperText>{helperText}</HelperText> : null}
    </>
  );
};

InfoElement.propTypes = {
  bid: PropTypes.bool,
  type: PropTypes.oneOf(['miles', 'currency', 'time']).isRequired,
  item: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  justify: PropTypes.oneOf(['space-between', 'flex-start']),
  container: PropTypes.bool
};

InfoElement.defaultProps = {
  bid: false,
  justify: 'space-between',
  container: true
};

export default InfoElement;
