/* eslint-disable no-case-declarations */
import {
  LIST_BANNERS_SUCCESS,
  LIST_BANNERS_FAILURE,
  LIST_BANNERS_REQUEST,
  CREATE_BANNERS_SUCCESS,
  LIST_ACTIVE_BANNERS_SUCCESS,
  DELETE_BANNERS_SUCCESS,
  CHANGE_BANNERS_SUCCESS
} from '../actions/banners';
import { BANNERS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  submiting: false,
  itemsData: {
    rows: [],
    count: 0
  },
  page: 0,
  perPage: BANNERS_PER_PAGE,
  activeBanners: []
};

const banners = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_BANNERS_REQUEST:
      return { ...state, loading: true };
    case LIST_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_BANNERS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    case CREATE_BANNERS_SUCCESS:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.concat(payload.data),
          count: payload.data.count + 1
        }
      };
    case DELETE_BANNERS_SUCCESS:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.filter(
            banner => banner.id !== payload.data.id
          ),
          count: payload.data.count - 1
        }
      };
    case CHANGE_BANNERS_SUCCESS:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(banner => {
            if (banner.id === payload.data.id) {
              return payload.data;
            }
            return banner;
          })
        }
      };
    case LIST_ACTIVE_BANNERS_SUCCESS:
      return {
        ...state,
        activeBanners: payload.data
      };
    default:
      return state;
  }
};

export default banners;
