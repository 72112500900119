import styled from 'styled-components';
import { FormattedAmount } from '../../../../../components';
import { StyledLabel, StyledText, Wrapper } from './styled';
import { ReservePriceLabel, VehicleSoldLabel } from '../../../../../components/ReservePriceMet';
import { getHighestBid } from '../../../../../utils/vehicle';
import moment from 'moment';
import { useSalesOfferAuction } from '../hooks';
import { usePrivateOfferAmount } from 'hooks';
import { useSelector } from 'react-redux';
import { OfferAuctionStatus } from '../../../../../types/vehicle';

const CurrentBidWrapper = styled.div`
  background-color: #3994de;
  color: #fff;
  border-radius: 2px;
  padding: 2px;
`;

const BidComponent = ({ title, amount }) => (
  <Wrapper justifyBetween>
    <StyledLabel isBold>{title}</StyledLabel>
    <StyledText>
      <CurrentBidWrapper>
        <FormattedAmount amount={amount} />
      </CurrentBidWrapper>
    </StyledText>
  </Wrapper>
);

const Gap = styled.div`
  padding-top: 8px;
`;

export const VehicleBidInfoBlock = ({ currentVehicle }) => {
  const currentTime = moment.utc();
  const isVehicleTimerEnded = currentVehicle.date_end && moment.utc(currentVehicle.date_end).isBefore(currentTime);

  const isAwarded = currentVehicle.status === 'awarded';
  const highestBid = getHighestBid(currentVehicle);

  const user = useSelector(state => state?.user?.user);

  const offerAuction = useSalesOfferAuction(currentVehicle.id);
  const { sellerAmount, adminAmount, buyerAmount } = usePrivateOfferAmount(offerAuction);
  const highestBuyerOffer = offerAuction?.offerBids?.[0]?.amount ?? 0;

  const [bidComponentTitle, bidComponentAmount] = (() => {
    if (isVehicleTimerEnded && !isAwarded) {
      const amount = (() => {
        if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) return highestBuyerOffer;
        if (!buyerAmount) return highestBid?.amount ?? 0;
        return buyerAmount;
      })();
      const title = offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS ? 'Top Offer' : 'Current Offer:';
      return [title, amount];
    }

    return highestBid?.amount
      ? ['Current bid:', highestBid.amount]
      : ['Starting/Min Bid', currentVehicle?.starting_bid || 100];
  })();

  return (
    <div>
      <BidComponent title={bidComponentTitle} amount={bidComponentAmount} />
      {isVehicleTimerEnded && !isAwarded && (
        <>
          <Wrapper justifyBetween>
            <StyledLabel>AutoAxess Counter</StyledLabel>
            <StyledText>{adminAmount ? <FormattedAmount amount={adminAmount} /> : '-'}</StyledText>
          </Wrapper>
          <Wrapper justifyBetween>
            <StyledLabel>Your Counter</StyledLabel>
            <StyledText>{sellerAmount ? <FormattedAmount amount={sellerAmount} /> : '-'}</StyledText>
          </Wrapper>
        </>
      )}
      <Wrapper justifyBetween>
        <StyledLabel>Reserve</StyledLabel>
        <StyledText>
          {currentVehicle.reserve_price ? <FormattedAmount amount={currentVehicle.reserve_price} /> : '-'}
        </StyledText>
      </Wrapper>
      <Gap />
      {!isVehicleTimerEnded ? (
        <ReservePriceLabel activeVehicle={currentVehicle} />
      ) : (
        <VehicleSoldLabel activeVehicle={currentVehicle} />
      )}
    </div>
  );
};
