import { APIProvider, AdvancedMarker, ControlPosition, Map, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { useFilteredGetVehiclesPoolQuery, useGetGeoQuery } from 'services/api/list-vehicles';
import {
  toggleMapOpen,
  useGetMapFilters,
  useIsMapOpen,
  useShowPolyline
} from 'services/listVehiclesSlice/listVehiclesSlice';
import styled, { CSSProperties } from 'styled-components';
import { MapPin } from './MapPin';
import { Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MapFilters } from './MapFilters';
import { HomeMapIcon } from '../../../../pages/Admin/ListerMap/icons/Home';
import { useState } from 'react';
import { MapRoutes } from '../../../../pages/Admin/ListerMap/components/MapRoutes';
import { GeolocationPin } from './GeolocationPin';
import { getDayNumberFromDate } from '../../../../pages/Admin/ListerDashboard/utils/formatDate';
import { dayToColorMap } from '../../../../pages/Admin/ListerDashboard/components/MapPin';

const Wrapper = styled.div`
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 1;
  z-index: 999;
`;

const CloseButton = styled.div`
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 60px;
`;

const startingPoint = {
  lat: 37.33548,
  lng: -121.893028
};

const containerStyle: CSSProperties = {
  width: '800px',
  height: '450px',
  position: 'relative'
};

export const AdminMap = () => {
  const isMapOpened = useIsMapOpen();

  const drawPolyline = useShowPolyline();

  const filters = useGetMapFilters();

  const { data: geolocations } = useGetGeoQuery(filters, { skip: !isMapOpened });

  const dispatch = useDispatch();
  const handleClose = () => dispatch(toggleMapOpen());

  if (!geolocations || !isMapOpened) return null;

  return (
    <Wrapper>
      <APIProvider libraries={['marker']} apiKey={'AIzaSyDFJbMx4Q4xrlUlsRpTVgbmU0lvzdUIjx0'}>
        <Map
          fullscreenControl={true}
          mapId={'admin-map'}
          defaultZoom={7}
          defaultCenter={startingPoint}
          gestureHandling={'greedy'}
          style={containerStyle}
          mapTypeControl={false}
        >
          <MapFilters />
          <CloseButton onClick={handleClose}>
            <Icon>close</Icon>
          </CloseButton>

          {geolocations.map(geo => {
            return (
              <>
                {geo?.routes?.filter(Boolean).map(route => {
                  return (
                    <>
                      {route?.points?.map((p, i) => (
                        <AdvancedMarker key={i} position={{ lat: p.latitude, lng: p.longitude }}>
                          {i === 0 ? (
                            <HomeMapIcon />
                          ) : (
                            <MapPin
                              color={dayToColorMap[getDayNumberFromDate(route.preferable_date!)]}
                              orderIndex={i}
                            />
                          )}
                        </AdvancedMarker>
                      ))}
                      {drawPolyline && route?.encodedPolyline && <MapRoutes encodedPolyline={route.encodedPolyline} />}
                    </>
                  );
                })}

                {geo.geolocation && (
                  <GeolocationPin
                    lat={geo.geolocation.lat}
                    long={geo.geolocation.long}
                    timestamp={geo.geolocation.timestamp}
                    color={geo.color}
                  />
                )}
              </>
            );
          })}
        </Map>
      </APIProvider>
    </Wrapper>
  );
};
