import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { FormTextField } from '../../../../components/Form';
import { debounce } from '../../../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { setStatusFilter, setSearchFilter } from '../../../../services/byMids/myBidsSlice';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  div {
    padding-right: 16px;
    div {
      @media (max-width: 768px) {
        min-width: initial;
      }
      min-width: 240px;
    }
    @media (max-width: 768px) {
      flex-grow: 1;
    }
  }
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
  div:last-child {
    padding-right: 0;
  }
  @media (max-width: 600px) {
    padding: 0 12px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    > div {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
`;

const activeFilters = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'awarded',
    label: 'Awarded'
  },
  {
    value: 'declined',
    label: 'Declined'
  },
  {
    value: 'pulled',
    label: 'Pulled'
  },
  {
    value: 'high_bid',
    label: 'High Bid'
  },
  {
    value: 'out_bid',
    label: 'OutBid'
  }
];

const pendingFilters = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'awarded',
    label: 'Awarded'
  },
  {
    value: 'declined',
    label: 'Declined'
  },
  {
    value: 'pulled',
    label: 'Pulled'
  },
  {
    value: 'undecided',
    label: 'Undecided'
  },
  {
    value: 'seller_accepting_offers',
    label: 'Seller Accepting Offers'
  },
  {
    value: 'seller_accepting_offers_in_negotiation',
    label: 'In Negotiation',
  },
  {
    value: 'countered',
    label: 'Countered'
  },
  {
    value: 'buyer_counter_sent',
    label: 'Counter sent to seller'
  }
];

const Filter = () => {
  const {
    filters: { auctionStatus }
  } = useSelector(state => state.myBids);
  const dispatch = useDispatch();

  const filterBlock = auctionStatus === 'pending' ? pendingFilters : activeFilters;

  const onSelect = (_e, value) => {
    dispatch(setStatusFilter(value));
  };

  const handleDealershipNameChange = debounce((_e, value) => {
    dispatch(setSearchFilter(value));
  }, 500);

  return (
    <StyledFilterWrapper>
      <Field onChange={onSelect} component={FormTextField} name="status" label="Filter by Bid Status" select>
        {filterBlock.map(({ value, label }) => {
          return <MenuItem value={value}>{label}</MenuItem>;
        })}
      </Field>
      <Field
        onChange={handleDealershipNameChange}
        component={FormTextField}
        name="search"
        label="Search"
      />
    </StyledFilterWrapper>
  );
};

export default reduxForm({
  form: 'myBidsFilterForm',
  destroyOnUnmount: false
})(Filter);
