import styled from 'styled-components';
import { SectionConfig } from '../../../../../../../services/api/vehicle-sections/types';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { SubSection } from './SubSection';
import { useViewVehicleContext } from '../../../../context/ViewVehicleContext';
import { CheckboxSection } from './CheckboxSection';
import { TiresAndWheelsSection } from './TiresAndWheelsSection';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubSectionsTable = () => {
  const vehicle = useViewedVehicle();

  const { activeSection } = useViewVehicleContext();

  if (!vehicle) return null;

  const subSections = vehicle.groupedSections[activeSection as keyof SectionConfig];

  if (activeSection === 'tires_and_wheels') {
    return <TiresAndWheelsSection />;
  }

  return (
    <Wrapper>
      {subSections?.map(subSection => {
        if (subSection.section.type === 'checkbox') {
          return <CheckboxSection item={subSection} key={subSection.id} />;
        }
        return <SubSection item={subSection} key={subSection.id} />;
      })}
    </Wrapper>
  );
};
