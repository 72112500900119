import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { PageHeader } from '../../../../../components';
import { listSellerPastInvoices } from '../../../../../actions';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
    border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
  }
`;

let sellerId = 0;

const Header = ({
  refreshList,
  history,
  currentSeller,
  filterValues,
  match
}) => {
  const { params } = match;
  /* eslint-disable-next-line */
  sellerId = params.sellerId;
  return (
    <PageHeader>
      <ButtonBase onClick={history.goBack}>
        <Icon>arrow_back</Icon>
      </ButtonBase>
      <StyledTypography variant="title">
        {`Invoices / Past Invoices / ${currentSeller.dealership_name}`}
      </StyledTypography>
      <span className="flex-grow" />
      <ButtonBase
        onClick={() => {
          refreshList({ seller: sellerId, params: { ...filterValues } });
        }}
      >
        <Icon>refresh</Icon>
      </ButtonBase>
    </PageHeader>
  );
};

const mapStateToProps = state => ({
  currentSeller: state.financials.currentSeller,
  filterValues: getFormValues('pastInvoicesFilterForm')(state)
});

const mapDispatchToProps = dispatch => ({
  refreshList: data => dispatch(listSellerPastInvoices(data).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
