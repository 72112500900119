import { MenuItem } from '@material-ui/core';
import { FormTextField } from 'components';
import { AuctionFilter } from 'components/Filters';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSalesHistoryQuery } from 'services/api/sales';
import { setFilters } from 'services/salesHistorySlice/salesHistorySlice';
import { setStatusFilter } from 'services/sellerVehicles/sellerVehiclesSlice';
import styled from 'styled-components';
import { debounce } from 'utils';

const StyledForm = styled.form`
  padding: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledField = styled(Field)`
  & * input::placeholder {
    font-size: 18px;
  }
`;

const toUpperCase = value => value && value.toUpperCase();

export const MobileSalesHistoryFilters = () => {
  const onSubmit = () => {};
  const dispatch = useDispatch();

  const { page, perPage, filters } = useSelector(state => state.salesHistory);
  const { data } = useGetSalesHistoryQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const makes = data?.makes || [];
  const models = data?.models || [];

  const handleChange = debounce(({ values }) => {
    dispatch(setFilters(values));
  }, 500);

  return (
    <Form onSubmit={onSubmit}>
      {props => {
        return (
          <StyledForm>
            <Field fullWidth component={FormTextField} name="vin" label="VIN Search" normalize={toUpperCase} />
            <Field fullWidth component={FormTextField} name="make" label="Filter by Make" select>
              <MenuItem value="">All</MenuItem>
              {makes.map((make, index) => (
                <MenuItem key={index} value={make}>
                  {make}
                </MenuItem>
              ))}
            </Field>
            {models.length !== 0 && (
              <Field fullWidth component={FormTextField} name="model" label="Filter by Model" select>
                <MenuItem value="">All</MenuItem>
                {models.map((model, index) => (
                  <MenuItem key={index} value={model}>
                    {model}
                  </MenuItem>
                ))}
              </Field>
            )}
            <StyledField fullWidth component={FormTextField} name="status" label="Filter by Bid Status" select>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="undecided">Undecided</MenuItem>
              <MenuItem value="pulled">Pulled</MenuItem>
              <MenuItem value="countered">Countered</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="awarded">Awarded</MenuItem>
            </StyledField>
            <AuctionFilter fullWidth formType="final-form" />
            <FormSpy subscription={{ values: true }} onChange={handleChange} />
          </StyledForm>
        );
      }}
    </Form>
  );
};
