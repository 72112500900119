import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import { getFormValues, reduxForm, Field } from 'redux-form';
import { FormTextField, FormResetButton } from '../../../../../components/Form';
import {
  listSellersInvoices,
  listUsers,
  filterSellersInvoices
} from '../../../../../actions';
import { roles } from '../../../../../constants';
import { getRegionsBySellers } from '../../../../../selectors/users';

const formName = 'invoicesFilterForm';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  div:first-child {
    padding-right: 16px;
  }
  div {
    div {
      min-width: 240px;
    }
  }
  align-items: flex-end;
`;

const StyledFormResetButton = styled(FormResetButton)`
  && {
    margin-left: 6px;
  }
`;

const onChange = (values, dispatch, { perPage, setPage }) => {
  setPage(1);
  dispatch(
    filterSellersInvoices({ params: { limit: perPage, offset: 1 } }).request
  );
  setPage(2);
};

class Filter extends PureComponent {
  componentDidMount() {
    const { fetchSellers } = this.props;
    fetchSellers();
  }

  render() {
    const { regions, values, change } = this.props;
    return (
      <StyledFilterWrapper>
        <Field
          component={FormTextField}
          name="dealership_name"
          label="Dealer Search"
        />
        <Field
          component={FormTextField}
          name="seller_region"
          label="Filter by Region"
          select
        >
          <MenuItem value="">All</MenuItem>
          {regions.map((item, i) => (
            <MenuItem value={item} key={i}>
              {item}
            </MenuItem>
          ))}
        </Field>
        {values && (values.dealership_name || values.seller_region) && (
          <StyledFormResetButton
            onClick={() => {
              Object.keys(values).forEach(value => change(value, null));
            }}
          />
        )}
      </StyledFilterWrapper>
    );
  }
}

const formValuesSelector = state => getFormValues(formName)(state);

const mapStateToProps = state => ({
  regions: getRegionsBySellers(state),
  values: formValuesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchSellers: () => dispatch(listUsers({ params: { role: roles.SELLER } }).request),
  getInvoices: () => dispatch(listSellersInvoices().request)
});

export default compose(
  reduxForm({
    form: formName,
    initialValues: {
      dealership_name: null,
      seller_region: null
    },
    onChange,
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Filter);
