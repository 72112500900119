/* eslint-disable */
import {
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LIST_USERS_FAILURE,
  LIST_CUSTOMERS_SUCCESS,
  LIST_CUSTOMERS_FAILURE,
  LIST_CUSTOMERS_REQUEST,
  DETAIL_CUSTOMER_REQUEST,
  DETAIL_CUSTOMER_SUCCESS,
  DETAIL_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER_DOC_REQUEST,
  UPDATE_USER_DOC_SUCCESS,
  UPDATE_USER_DOC_FAILURE,
  UPDATE_USER_DOC_FILE_REQUEST,
  UPDATE_USER_DOC_FILE_SUCCESS,
  UPDATE_USER_DOC_FILE_FAILURE,
  DELETE_USER_DOC_REQUEST,
  DELETE_USER_DOC_SUCCESS,
  DELETE_USER_DOC_FAILURE,
  DISABLE_BUYER_REQUEST,
  DISABLE_BUYER_SUCCESS,
  DISABLE_BUYER_FAILURE,
  CUSTOMERS_SET_PAGE,
  ACCOUNTS_SET_PAGE,
  FILTER_CUSTOMERS_REQUEST,
  FILTER_CUSTOMERS_SUCCESS,
  FILTER_CUSTOMERS_FAILURE,
  LIST_ACCOUNTS_REQUEST,
  LIST_ACCOUNTS_SUCCESS,
  LIST_ACCOUNTS_FAILURE,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  LIST_REFERRERS_REQUEST,
  LIST_REFERRERS_SUCCESS,
  LIST_REFERRERS_FAILURE,
  LIST_ADMIN_SELLER_SELLERS_REQUEST,
  LIST_ADMIN_SELLER_SELLERS_SUCCESS,
  LIST_ADMIN_SELLER_SELLERS_FAILURE,
  ACCOUNT_LOGS_SET_PAGE,
  LIST_ACCOUNT_LOGS_REQUEST,
  LIST_ACCOUNT_LOGS_SUCCESS,
  LIST_ACCOUNT_LOGS_FAILURE,
} from '../constants/actionTypes';

export const listUsers = data => ({
  request: { type: LIST_USERS_REQUEST, payload: data },
  success: { type: LIST_USERS_SUCCESS, payload: data },
  failure: { type: LIST_USERS_FAILURE, payload: data }
});

export const updateCustomer = data => ({
  request: { type: UPDATE_CUSTOMER_REQUEST, payload: data },
  success: { type: UPDATE_CUSTOMER_SUCCESS, payload: data },
  failure: { type: UPDATE_CUSTOMER_FAILURE, payload: data }
});

export const updateAccount = data => ({
  request: { type: UPDATE_ACCOUNT_REQUEST, payload: data },
  success: { type: UPDATE_ACCOUNT_SUCCESS, payload: data },
  failure: { type: UPDATE_ACCOUNT_FAILURE, payload: data }
});

export const createAccount = data => ({
  request: { type: CREATE_ACCOUNT_REQUEST, payload: data },
  success: { type: CREATE_ACCOUNT_SUCCESS, payload: data },
  failure: { type: CREATE_ACCOUNT_FAILURE, payload: data }
});

export const deleteAccount = data => ({
  request: { type: DELETE_ACCOUNT_REQUEST, payload: data },
  success: { type: DELETE_ACCOUNT_SUCCESS, payload: data },
  failure: { type: DELETE_ACCOUNT_FAILURE, payload: data }
});

export const disableBuyer = data => ({
  request: { type: DISABLE_BUYER_REQUEST, payload: data },
  success: { type: DISABLE_BUYER_SUCCESS, payload: data },
  failure: { type: DISABLE_BUYER_FAILURE, payload: data }
});

export const listCustomers = data => ({
  request: { type: LIST_CUSTOMERS_REQUEST, payload: data },
  success: { type: LIST_CUSTOMERS_SUCCESS, payload: data },
  failure: { type: LIST_CUSTOMERS_FAILURE, payload: data }
});

export const listAccounts = data => ({
  request: { type: LIST_ACCOUNTS_REQUEST, payload: data },
  success: { type: LIST_ACCOUNTS_SUCCESS, payload: data },
  failure: { type: LIST_ACCOUNTS_FAILURE, payload: data }
});

export const listAccountLogs = data => ({
  request: { type: LIST_ACCOUNT_LOGS_REQUEST, payload: data },
  success: { type: LIST_ACCOUNT_LOGS_SUCCESS, payload: data },
  failure: { type: LIST_ACCOUNT_LOGS_FAILURE, payload: data }
});

export const listAdminSellerSellers = data => ({
  request: { type: LIST_ADMIN_SELLER_SELLERS_REQUEST, payload: data },
  success: { type: LIST_ADMIN_SELLER_SELLERS_SUCCESS, payload: data },
  failure: { type: LIST_ADMIN_SELLER_SELLERS_FAILURE, payload: data }
});

export const listReferrers = data => ({
  request: { type: LIST_REFERRERS_REQUEST, payload: data },
  success: { type: LIST_REFERRERS_SUCCESS, payload: data },
  failure: { type: LIST_REFERRERS_FAILURE, payload: data }
});

export const filterCustomers = data => ({
  request: { type: FILTER_CUSTOMERS_REQUEST, payload: data },
  success: { type: FILTER_CUSTOMERS_SUCCESS, payload: data },
  failure: { type: FILTER_CUSTOMERS_FAILURE, payload: data }
});

export const detailCustomer = data => ({
  request: { type: DETAIL_CUSTOMER_REQUEST, payload: data },
  success: { type: DETAIL_CUSTOMER_SUCCESS, payload: data },
  failure: { type: DETAIL_CUSTOMER_FAILURE, payload: data }
});

export const getUser = data => ({
  request: { type: GET_USER_REQUEST, payload: data },
  success: { type: GET_USER_SUCCESS, payload: data },
  failure: { type: GET_USER_FAILURE, payload: data }
});

export const updateDoc = data => ({
  request: { type: UPDATE_USER_DOC_REQUEST, payload: data },
  success: { type: UPDATE_USER_DOC_SUCCESS, payload: data },
  failure: { type: UPDATE_USER_DOC_FAILURE, payload: data }
});

export const updateDocFile = data => ({
  request: { type: UPDATE_USER_DOC_FILE_REQUEST, payload: data },
  success: { type: UPDATE_USER_DOC_FILE_SUCCESS, payload: data },
  failure: { type: UPDATE_USER_DOC_FILE_FAILURE, payload: data }
});

export const deleteDoc = data => ({
  request: { type: DELETE_USER_DOC_REQUEST, payload: data },
  success: { type: DELETE_USER_DOC_SUCCESS, payload: data },
  failure: { type: DELETE_USER_DOC_FAILURE, payload: data }
});

export const customersSetPage = data => ({
  type: CUSTOMERS_SET_PAGE,
  payload: data
});

export const accountsSetPage = data => ({
  type: ACCOUNTS_SET_PAGE,
  payload: data
});

export const accountLogsSetPage = data => ({
  type: ACCOUNT_LOGS_SET_PAGE,
  payload: data
});

export const LOGIN_AS_USER_REQUEST = 'LOGIN_AS_USER_REQUEST';
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS';
export const LOGIN_AS_USER_FAILURE = 'LOGIN_AS_USER_FAILURE';

export const loginAsUser = data => ({
  request: { type: LOGIN_AS_USER_REQUEST, payload: data },
  success: { type: LOGIN_AS_USER_SUCCESS, payload: data },
  failure: { type: LOGIN_AS_USER_FAILURE, payload: data }
});

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const deleteUser = data => ({
  request: { type: DELETE_CUSTOMER_REQUEST, payload: data },
  success: { type: DELETE_CUSTOMER_SUCCESS, payload: data },
  failure: { type: DELETE_CUSTOMER_FAILURE, payload: data }
});

export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const enableUser = data => ({
  request: { type: ENABLE_USER_REQUEST, payload: data },
  success: { type: ENABLE_USER_SUCCESS, payload: data },
  failure: { type: ENABLE_USER_FAILURE, payload: data }
});
