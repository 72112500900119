import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { resetFilters } from 'services/sellerVehicles/sellerVehiclesSlice';
import { useState } from 'react';
import { useScreen } from 'usehooks-ts';
import { ExpandedMenuItem, MenuItem } from 'components/Icons/MenuIcon';
import { MobileVehicleListFilters } from './MobileVehicleListFilters';
import { Line, MainContentWrapper, Wrapper } from 'components/HeaderUI';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: 20px 13px 20px 13px;
  }
`;

const Header = () => {
  const dispatch = useDispatch();

  const [expanded, setIsExpanded] = useState(false);
  const { width } = useScreen();
  const isMobile = width < 567;

  const toggleExpanded = () => setIsExpanded(!expanded);

  return (
    <>
      <div style={{ height: '60px' }} />
      <Wrapper isMobileExpanded={expanded && isMobile}>
        <MainContentWrapper>
          <StyledTypography variant="title">Manage Vehicles</StyledTypography>
          <span className="flex-grow" />
          <ButtonBase
            onClick={() => {
              dispatch(resetFilters());
            }}
          >
            <Icon>refresh</Icon>
            {isMobile && (
              <ButtonBase onClick={toggleExpanded}>{expanded ? <ExpandedMenuItem /> : <MenuItem />}</ButtonBase>
            )}
          </ButtonBase>
        </MainContentWrapper>
        {isMobile && expanded && <Line />}
        {isMobile && expanded && <MobileVehicleListFilters />}
      </Wrapper>
    </>
  );
};

export default Header;
