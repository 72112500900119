import NumberFormat from 'react-number-format';
import { getHighestBid } from '../../utils/vehicle';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import { useRef, useState } from 'react';
import { useIsTextOverflown } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsUser } from '../../actions';
import { HoverInfoTooltip } from '../../pages/Admin/AuctionDetail/components/HoverInfo';
import { useCustomerHoverStateHandlers } from '../../pages/Admin/AuctionDetail/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
`;

const BidderName = styled.span`
  color: #757575;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 66px;
  cursor: pointer;
`;

export const HighestBidWithBuyer = ({ vehicle, showHoverInfo = false }: any) => {
  const highestBid = getHighestBid(vehicle);
  const buyerName = highestBid.bidder?.dealership_name;
  const admin = useSelector((state: any) => state?.user?.user);
  const isLister = admin?.job_title === 'Lister';

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  const dispatch = useDispatch();

  const handleClick = () => {
    if (!highestBid.user_id || isLister) return;
    dispatch(loginAsUser({ id: highestBid.user_id, initialPage: `/admin/auctions/${vehicle.auction_id}` }).request);
  };

  return (
    <Wrapper>
      <span style={{ fontWeight: 500 }}>
        <NumberFormat displayType="text" prefix="$" value={(highestBid && highestBid.amount) || 0} thousandSeparator />
      </span>
      {buyerName && showHoverInfo && highestBid.bidder && (
        <RenderBuyerNameWithHoverInfo
          userId={highestBid.bidder.id}
          initialPage={`/admin/auctions/${vehicle.auction_id}`}
          buyerName={buyerName}
        />
      )}
      {buyerName && !showHoverInfo && (
        <Tooltip isTextOverflown={isTextOverflown} title={buyerName} arrow light>
          <BidderName ref={ref} onClick={handleClick}>
            {buyerName}
          </BidderName>
        </Tooltip>
      )}
    </Wrapper>
  );
};

const RenderBuyerNameWithHoverInfo = ({
  userId,
  buyerName,
  initialPage
}: {
  userId: number;
  buyerName: string;
  initialPage: string;
}) => {
  const { isOpen, handleClose, handleOpen } = useCustomerHoverStateHandlers();

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  if (!buyerName) return null;

  return (
    <HoverInfoTooltip
      anchorEl={ref.current}
      initialPage={initialPage}
      userId={userId}
      isPopoverOpened={isOpen}
      closeTooltip={handleClose}
      showTooltip={isTextOverflown}
      tooltipName={buyerName}
    >
      <BidderName ref={ref} onClick={handleOpen}>
        {buyerName}
      </BidderName>
    </HoverInfoTooltip>
  );
};
