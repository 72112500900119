import { Icon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const GoBackButton = () => {
  const history = useHistory();
  return (
    <Icon style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
      arrow_back
    </Icon>
  );
};
