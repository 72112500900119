import { useSelector } from 'react-redux';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { useGetMyBidsQuery } from 'services/api/my-bids';

export const useBidsOfferAuction = (vehicleId: number) => {
  const { page, perPage, filters } = useSelector((state: any) => state.myBids);
  const { data: myBids } = useGetMyBidsQuery({ limit: perPage, offset: page, ...filters });
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: myBids?.rows?.ids as number[] ?? [] },
    {
      skip: !myBids?.rows?.ids || myBids.rows.ids.length === 0
    }
  );

  const offerAuction = offerAuctions?.entities?.[vehicleId] ?? null;
  return offerAuction
};
