import { useSelector } from 'react-redux';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { Vehicle } from '../../../../types/vehicle';

export const useBidsHistoryOfferAuction = (vehicleId: number) => {
  const bids = useSelector((state: any) => state.bids.itemsData.rows);
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: bids?.map((el: Vehicle) => el.id) ?? [] },
    {
      skip: !bids || bids.length === 0
    }
  );

  const offerAuction = offerAuctions?.entities?.[vehicleId] ?? null;
  return offerAuction
};
