import { Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-radius: 6px;
  width: 100%;
  min-width: ${props => `${props.minWidth}px` || 'none'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const variantMap = {
  accept: {
    color: '#225A91',
    textColor: '#fff'
  },
  counter: {
    color: '#3994DE',
    textColor: '#fff'
  }
};

export const ActionButton = ({ colorVariant = 'accept', minWidth = null, ...buttonProps }) => {
  const { color, textColor } = variantMap[colorVariant];
  return <StyledButton size="large" color={color} textColor={textColor} variant="contained" minWidth={minWidth} {...buttonProps} />;
};
