import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  LinearProgress,
  Icon,
  ButtonBase
} from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from "react-router-dom";
import RoleRestrictedHeader from '../../RoleRestrictedHeader';
import { PageWrapper } from '../../../../../components';

const StyledBackButton = styled(ButtonBase)`
  margin-right: 10px;
`;

const StyledLinearProgress = styled(LinearProgress)`
  background: none;
  position: absolute;
  bottom: -5px;
  width: 100%;
`

const Header = ({ wasDataInited, loading, vehicle, history }) => (
  <AppBar position="fixed" color="default">
    <PageWrapper>
      <Toolbar disableGutters>
        <StyledBackButton onClick={history.goBack}>
          <Icon>arrow_back</Icon>
        </StyledBackButton>
        <Typography
          variant="title"
          color="inherit"
          style={{ flexGrow: 1 }}
        >
          {loading ? (
            <CircularProgress />
          ) : vehicle && (
            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
          )}
        </Typography>
        {!loading && (
          <div className="actions">
            <RoleRestrictedHeader />
          </div>
        )}
      </Toolbar>
    </PageWrapper>
    {wasDataInited && (
      <StyledLinearProgress color="primary" />
    )}
  </AppBar>
);

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  wasDataInited: state.vehicles.selectedVehicle.wasDataInited,
  vehicle: state.vehicles.selectedVehicle.data
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Header);
