import { BlockProps } from './types';
import { useSelector } from 'react-redux';
import { TableButton } from '../../../../../components/Table/common';
import styled from 'styled-components';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useBidsOfferAuction } from '../hooks';
import { useVehicleBidsActions } from '../hooks/useVehicleBidsActions';
import { RenderPlaceBid } from './RenderPlaceBid';

const Container = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const MyBidsPlaceBidBlock = ({ currentVehicle }: BlockProps) => {
  const user = useSelector((state: any) => state.user?.user);

  const offerAuction = useBidsOfferAuction(currentVehicle.id);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);

  const buttons = useVehicleBidsActions(currentVehicle, offerAuction!, status);

  return (
    <Container>
      <RenderPlaceBid currentVehicle={currentVehicle} offerAuction={offerAuction} status={status} />
      {buttons.map((btn, i) => (
        <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
      ))}
    </Container>
  );
};
