import { Vehicle } from 'types/vehicle';
import styled from 'styled-components';
import { Button, Checkbox, Dialog, Icon, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CalendarIcon } from 'components/Icons/CalendarIcon';
import { Field, Form } from 'react-final-form';
import { FormTextAreaField, FormTextField } from 'components';
import moment from 'moment';
import createDecorator from 'final-form-calculate';
import { dashValidation, maxLength } from '../../utils/validators';
import { GreyInfoIcon } from '../../img/grey-info';
import { useRequestListVehiclesMutation } from '../../services/api/list-vehicles';
import { DatePicker } from 'material-ui-pickers';
import { DATE_FORMAT } from '../../constants';
import ConfirmModal from '../Modals/ConfirmModal';
import { useDispatch } from 'react-redux';
import { modalsHide, modalsToggle, snackShow } from '../../actions';

type Props = {
  vehicle: Vehicle;
  isOpen: boolean;
  close: () => void;
  classes?: any;
};

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.22188 18.8258L5.17188 17.7758L10.9469 12.0008L5.17188 6.22578L6.22188 5.17578L11.9969 10.9508L17.7719 5.17578L18.8219 6.22578L13.0469 12.0008L18.8219 17.7758L17.7719 18.8258L11.9969 13.0508L6.22188 18.8258Z"
      fill="#212121"
    />
  </svg>
);

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 44px;
`;

const NotesLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
`;

const DateGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const TimePickerWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }

  & * p {
    display: none;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0px;
  margin-left: -12px;
  margin-top: -8px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;

const StyledDialog = styled(Dialog)``;

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: 200
  }
};

const calculator: any = createDecorator({
  field: 'blueBookValue',
  updates: {
    difference: (blueBookValue: string, allValues: any) => {
      console.log(allValues, blueBookValue);
      return (Number(blueBookValue) || 0) - (Number(allValues.currentBlueBookValue) || 0);
    }
  }
});

const maxNotesLength = maxLength(500);

const dateValidation = (value: any, rest: any) => {
  if (rest.noPreferableTime) {
    return;
  }

  if (!value) {
    return `Choose a day or choose "No preferable day"`;
  }
};

const ErrorText = styled.span`
  color: #f44336;
  font-size: 12px;
  line-height: 12px;
  height: 8px;
  margin-top: -8px;
`;

const ListVehiclesDialog = ({ isOpen, close, classes }: Props) => {
  const initialValues = {
    numberOfCars: '',
    preferableTime: null,
    notes: '',
    noPreferableTime: true
  };

  const [submitRequest] = useRequestListVehiclesMutation();

  const onSubmit = (values: typeof initialValues) => {
    if (!values.noPreferableTime) {
      const startOfTheWeek = moment()
        .locale('en-us')
        .startOf('week');
      const endOfTheWeek = moment()
        .locale('en-us')
        .endOf('week');
      const preferableTime = moment(values.preferableTime);

      if (preferableTime.isAfter(startOfTheWeek) && preferableTime.isBefore(endOfTheWeek) && moment().weekday() >= 3) {
        dispatch(
          snackShow({
            message: 'Listing for the current week are accepted before Wednesday, choose next week',
            type: 'error'
          })
        );

        return;
      }
    }
    submitRequest({
      number_of_vehicles: values.numberOfCars,
      preferable_date: values.noPreferableTime ? undefined : moment(values.preferableTime).format('YYYY-MM-DD'),
      notes: values.notes
    });
    close();
  };

  const PatchedFormTextField = FormTextField as any;

  const dispatch = useDispatch();

  const handleCancelButtonClick = (isDirty: boolean) => {
    if (isDirty) {
      dispatch(modalsToggle('cancel-list-vehicles'));
    } else {
      close();
    }
  };

  const closeAll = () => {
    close();
    dispatch(modalsHide('cancel-list-vehicles'));
  };

  return (
    <StyledDialog open={isOpen} onBackdropClick={close} fullWidth maxWidth={'sm'}>
      <Form name={`list-vehicles-dialog`} initialValues={initialValues} onSubmit={onSubmit} decorators={[calculator]}>
        {({ handleSubmit, values, dirty, errors, touched }) => {
          return (
            <Wrapper onSubmit={handleSubmit}>
              <TitleRow>
                <Title>{`I have cars to list`}</Title>
                <div onClick={close}>
                  <Cross />
                </div>
              </TitleRow>
              <FormWrapper>
                <Field
                  label="Number of cars"
                  name="numberOfCars"
                  component={PatchedFormTextField}
                  styleVariant="filled"
                  validate={dashValidation}
                />
                <DateGroup>
                  <NotesLabel>Preferable date and time</NotesLabel>
                  <Field name="preferableTime" validate={dateValidation}>
                    {props => (
                      <TimePickerWrapper style={{ width: '100%' }}>
                        <DatePicker
                          disabled={values.noPreferableTime}
                          disablePast
                          value={props.input.value}
                          onChange={props.input.onChange}
                          format={DATE_FORMAT}
                          emptyLabel="Select date..."
                          invalidLabel="Select date..."
                          InputProps={{
                            onBlur: props.input.onBlur,
                            startAdornment: (
                              <InputAdornment position="start">
                                <div style={{ paddingLeft: '3px' }}>
                                  <CalendarIcon />
                                </div>
                              </InputAdornment>
                            ),
                            error: false
                          }}
                        />
                      </TimePickerWrapper>
                    )}
                  </Field>
                  <Field name="noPreferableTime">
                    {props => (
                      <CheckboxWrapper>
                        <Checkbox
                          checked={props.input.value}
                          value={props.input.value}
                          onChange={props.input.onChange}
                        />
                        <span>No preferable day</span>
                      </CheckboxWrapper>
                    )}
                  </Field>
                  <ErrorText>
                    {errors?.preferableTime && touched?.preferableTime ? errors?.preferableTime : ' '}
                  </ErrorText>
                </DateGroup>

                <Field name="notes" validate={maxNotesLength}>
                  {props => (
                    <div>
                      <NotesLabel>
                        <span>Notes</span>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <span>Max. 500 characters</span>
                          <GreyInfoIcon />
                        </div>
                      </NotesLabel>
                      <FormTextAreaField
                        placeholder="Add notes for AutoAxess..."
                        rows={4}
                        fullWidth
                        styleVariant="filled"
                        {...(props as any)}
                      />
                    </div>
                  )}
                </Field>
                <ButtonsWrapper>
                  <StyledButton
                    variant="contained"
                    color="default"
                    onClick={e => {
                      e.preventDefault();
                      handleCancelButtonClick(dirty);
                    }}
                  >
                    CANCEL
                  </StyledButton>
                  <StyledButton type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                    SUBMIT
                  </StyledButton>
                </ButtonsWrapper>
              </FormWrapper>
              <ConfirmModal
                title="Are you sure you want to cancel? All the information will be lost"
                handleSubmit={closeAll}
                submitText={'Continue'}
                modalId={'cancel-list-vehicles'}
              />
            </Wrapper>
          );
        }}
      </Form>
    </StyledDialog>
  );
};

// @ts-ignore
export default withStyles(styles)(ListVehiclesDialog);
