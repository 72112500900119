import NumberFormat from 'react-number-format';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import { useAcceptBidMutation } from '../../../../services/api/offer-auction';
import { useSalesOfferAuction } from './hooks';
import { useOfferAuctionBidsInfo } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../actions';

export const AcceptTopOfferModal = ({ currentVehicle }: any) => {
  const dispatch = useDispatch();
  const offerAuction = useSalesOfferAuction(currentVehicle.id);

  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction);
  const [acceptBid] = useAcceptBidMutation();

  const handleSubmit = () => {
    if (!highestBidOffer) return
    acceptBid({ vehicle_id: currentVehicle.id, offer_id: highestBidOffer.id });
    dispatch(modalsToggle(`acceptTopOffer-${currentVehicle.id}`));
  };

  if (!highestBidOffer) return null;

  return (
    <ConfirmModal
      submitText={'Accept'}
      modalId={`acceptTopOffer-${currentVehicle.id}`}
      title={
        <>
          You are about to accept the offer of{' '}
          <NumberFormat displayType="text" prefix="$" value={highestBidOffer.amount} thousandSeparator /> from{' '}{highestBidOffer.bidder?.dealership_name ?? ''}
        </>
      }
      handleSubmit={handleSubmit}
    />
  );
};
