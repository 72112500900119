import styled from 'styled-components';
import { getLabelFromDayNumber } from '../../utils/formatDate';
import {
  ListerPool,
  RouteLeg,
  useGetListerRouteDistancesQuery,
  useOptimizeRoadMutation
} from '../../../../../services/api/list-vehicles';
import { CarRoadIcon } from '../../icons/CarRoad';
import { Button, Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { AddPlace } from './AddPlace';
import { NoDataBlock } from './NoDataBlock';
import { RequestBlock } from './RequestBlock';
import { CaretDownIcon } from '../../icons/CaretDown';
import { useSelector } from 'react-redux';
import { formatAddress } from '../../utils/formatAddress';
import { SortableContext } from '@dnd-kit/sortable';
import { DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { DropZone } from '../../../../../components/ListVehicles/components/DropZone';
import { useState } from 'react';
import { ToggleButton } from '@material-ui/lab';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DateLabel = styled.div`
  color: #222222;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const RoadTimeBlock = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 400;
  line-height: 24px;
  color: #3994de;
  gap: 6px;
  margin-right: 9px;
`;

const EmptySpace = styled.div`
  flex-grow: 1;
`;

const OptimizeRoadButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const Delimiter = styled.div`
  background: #cccccc;
  height: 1px;
  margin-top: 4px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
`;

const CheckboxLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 18.75px;
  text-align: left;
`;

export const ListerDayBlock = ({ data, dayNumber }: { data: ListerPool[]; dayNumber: number }) => {
  const listRequests = data?.[0]?.listRequests || [];
  const user = useSelector((state: any) => state.user.user);
  const poolId = data?.[0]?.id || -1;

  const [farthestFirst, setFarthestFirst] = useState(false);

  const { data: route } = useGetListerRouteDistancesQuery(
    {
      origin: formatAddress(user),
      destinations: listRequests.map(item => formatAddress(item.user)),
      poolId
    },
    {
      skip: listRequests.length === 0 || !user || poolId === -1
    }
  );

  const [optimizeRoute] = useOptimizeRoadMutation();

  const handleOptimizeRoute = () => {
    const listRequestIds = listRequests.map(lr => lr.id);
    optimizeRoute({ listRequestIds, farthestFirst });
  };

  if (listRequests.length === 0) {
    return (
      <DropZone id={`NoDataDropZone-${dayNumber}`}>
        <NoDataBlock dayNumber={dayNumber} />
      </DropZone>
    );
  }

  return (
    <Wrapper>
      <Header>
        <DateLabel>{getLabelFromDayNumber(dayNumber)}</DateLabel>
        <EmptySpace />
        {route && (
          <RoadTimeBlock>
            <CarRoadIcon active />
            <span>{route.localizedDuration}</span>
          </RoadTimeBlock>
        )}
        <OptimizeRoadButton onClick={handleOptimizeRoute} color="primary" variant="contained">
          OPTIMIZE ROAD
        </OptimizeRoadButton>
      </Header>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={!farthestFirst} onChange={() => setFarthestFirst(c => !c)} value="checkedA" />}
          label={<CheckboxLabel>Farthest Last</CheckboxLabel>}
        />
        <FormControlLabel
          control={<Checkbox checked={farthestFirst} onChange={() => setFarthestFirst(c => !c)} value="checkedB" />}
          label={<CheckboxLabel>Closest Last</CheckboxLabel>}
        />
      </FormGroup>

      <SortableContext id={`SortableDay-${dayNumber}`} items={listRequests.map(e => e.id)}>
        {listRequests.map((item, i) => (
          <>
            <RequestBlock item={item} orderIndex={i + 1} dayNumber={dayNumber} />
            <DirectionsBlock routeLeg={route?.legs?.[i]} />
          </>
        ))}
      </SortableContext>
      <Delimiter />
    </Wrapper>
  );
};

const DirectionsWrapper = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 48px;
  color: #999999;
  gap: 6px;
`;

const DirectionsBlock = ({ routeLeg }: { routeLeg?: RouteLeg }) => {
  if (!routeLeg) return null;
  return (
    <DirectionsWrapper>
      <CarRoadIcon active={false} />
      <span>{routeLeg.localizedDuration}</span>
    </DirectionsWrapper>
  );
};
