import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { MenuItem } from '@material-ui/core';
import { StyledGrid } from '../components';
import { updateProfile } from '../../../../actions/user';
import { FormTextField } from '../../../../components';
import { required } from '../../../../utils/validators';

const RegionSectionForm = ({ handleSubmit, regions }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <StyledGrid item xs={12}>
        <Field
          name="region_id"
          component={FormTextField}
          label="Region"
          select
          fullWidth
          InputProps={{
            disableUnderline: true,
            disabled: true
          }}
          validate={required}
          SelectProps={{
            MenuProps: {
              style: {
                maxHeight: 400
              }
            }
          }}
        >
          {regions.map((item, i) => (
            <MenuItem key={i} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Field>
      </StyledGrid>
    </StyledGrid>
  </form>
);

export default reduxForm({
  form: 'regionSectionForm',
  onSubmit: (values, dispatch) =>
    new Promise((resolve, reject) =>
      dispatch(updateProfile({ payload: values, resolve, reject }).request)
    ),
  enableReinitialize: true
})(RegionSectionForm);
