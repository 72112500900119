export const CarRoadIcon = ({ active }: { active: boolean }) => {
  const fillColor = active ? '#3994DE' : '#CCCCCC';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_5896_4422)">
        <path
          d="M8 5.8L5.35 3.15L6.4 2.1L9.05 4.75L8 5.8ZM16 5.8L14.95 4.75L17.6 2.1L18.65 3.15L16 5.8ZM11.25 4.5V0H12.75V4.5H11.25ZM3.75 24C3.5375 24 3.35938 23.9281 3.21563 23.7844C3.07187 23.6406 3 23.4625 3 23.25V15.15L5.125 8.75C5.20833 8.51667 5.34583 8.33333 5.5375 8.2C5.72917 8.06667 5.95 8 6.2 8H17.8C18.05 8 18.2708 8.06667 18.4625 8.2C18.6542 8.33333 18.7917 8.51667 18.875 8.75L21 15.15V23.25C21 23.4625 20.9281 23.6406 20.7844 23.7844C20.6406 23.9281 20.4625 24 20.25 24H19.725C19.5125 24 19.3344 23.9281 19.1906 23.7844C19.0469 23.6406 18.975 23.4625 18.975 23.25V21.9H5V23.25C5 23.4625 4.92813 23.6406 4.78438 23.7844C4.64062 23.9281 4.4625 24 4.25 24H3.75ZM5.075 13.65H18.925L17.55 9.5H6.45L5.075 13.65ZM7.14413 19.15C7.53138 19.15 7.85417 19.0188 8.1125 18.7563C8.37083 18.4937 8.5 18.175 8.5 17.8C8.5 17.4111 8.36875 17.0806 8.10625 16.8083C7.84375 16.5361 7.525 16.4 7.15 16.4C6.76112 16.4 6.43056 16.5355 6.15832 16.8066C5.88611 17.0777 5.75 17.4069 5.75 17.7941C5.75 18.1814 5.88554 18.5042 6.15663 18.7625C6.42769 19.0208 6.75686 19.15 7.14413 19.15ZM16.875 19.15C17.2639 19.15 17.5944 19.0188 17.8667 18.7563C18.1389 18.4937 18.275 18.175 18.275 17.8C18.275 17.4111 18.1395 17.0806 17.8684 16.8083C17.5973 16.5361 17.2681 16.4 16.8809 16.4C16.4936 16.4 16.1708 16.5355 15.9125 16.8066C15.6542 17.0777 15.525 17.4069 15.525 17.7941C15.525 18.1814 15.6562 18.5042 15.9187 18.7625C16.1813 19.0208 16.5 19.15 16.875 19.15Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_5896_4422">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
