import { createSelector } from 'reselect';

export const getUsers = state => state.users.items;

export const getRegionsBySellers = createSelector(
  [getUsers],
  users => users
    .filter(item => item.seller)
    .reduce((acc, { seller }) => {
      if (!acc.includes(seller.region)) {
        acc.push(seller.region);
      }
      return acc;
    }, [])
);
