import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PAYMENT_METHODS_STATUSES, VEHICLE_STATUSES } from '../../../constants';

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #005a96;
`;

const DocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VehicleDocs = ({ vehicle }) =>
  vehicle.status === VEHICLE_STATUSES.AWARDED ? (
    <DocsWrapper>
      <StyledLink component={Link} to={`/vehicles/${vehicle.id}/buyer-docs`}>
        Buyer Docs
      </StyledLink>
      <StyledLink target="_blank" rel="noreferrer noopener" component="a" href={vehicle.seller_report_of_sale}>
        Report Of Sale
      </StyledLink>
      {vehicle.payment_status === PAYMENT_METHODS_STATUSES.APPROVED && (
        <StyledLink
          target="_blank"
          rel="noreferrer noopener"
          component="a"
          href={`/vehicles/${vehicle.id}/funding-notice`}
        >
          Funding Notice
        </StyledLink>
      )}
    </DocsWrapper>
  ) : (
    'N/A'
  );

export default VehicleDocs;
