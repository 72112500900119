import React from 'react';
import { MenuItem, ListItemText } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { roles } from '../../../constants';
import { restoreInitialUser, getInitialNavigationPage } from '../../../utils/auth';
import { BUYER_PROFILE_LINKS, SELLER_PROFILE_LINKS } from '../../../constants/links';

const StyledMobileLinkMenuItem = styled(NavLink)`
  display: block;
  padding-left: ${props => `${props.paddingLeft}px` || 0};
  text-decoration: none;
`;

const StyledMobileButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
`;

const StyledMobileMenuItem = styled(MenuItem)`
  && {
    padding-top: ${props => (props.isTitle ? '12px' : 0)};
    padding-bottom: ${props => (props.isTitle ? '12px' : 0)};
    height: 100%;
    button,
    a {
      text-align: left;
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

const MobileProfileLinks = ({ isAdminLoggedIn, user }) => (
  <>
    <StyledMobileMenuItem isTitle>
      <ListItemText primary={user.dealership_name} />
    </StyledMobileMenuItem>
    {(user.role === roles.BUYER ? BUYER_PROFILE_LINKS : SELLER_PROFILE_LINKS).map((item, listIndex) => (
      <StyledMobileMenuItem key={listIndex}>
        <StyledMobileLinkMenuItem to={item.to} paddingLeft={10}>
          <ListItemText primary={item.text} />
        </StyledMobileLinkMenuItem>
      </StyledMobileMenuItem>
    ))}
    {!isAdminLoggedIn && (
      <StyledMobileMenuItem>
        <StyledMobileButton
          type="button"
          onClick={() => {
            localStorage.clear();
            window.location.href = '/';
          }}
        >
          <ListItemText primary="Log Out" />
        </StyledMobileButton>
      </StyledMobileMenuItem>
    )}
    {isAdminLoggedIn && (
      <StyledMobileMenuItem>
        <StyledMobileButton
          type="button"
          onClick={() => {
            const navLink = getInitialNavigationPage() ?? '/admin/customers';
            restoreInitialUser();
            window.location.href = navLink;
          }}
        >
          <ListItemText primary="Go back to Admin" />
        </StyledMobileButton>
      </StyledMobileMenuItem>
    )}
  </>
);

export default connect(state => ({
  user: state.user.user
}))(MobileProfileLinks);
