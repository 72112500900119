import { Button, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { usePoolModalControls } from 'services/listVehiclesSlice/listVehiclesSlice';
import { useCreateVehiclesPoolRowMutation, useDeleteDraftVehiclePoolRowsMutation } from 'services/api/list-vehicles';
import listVehiclesApi from 'services/api/list-vehicles';
import { snackShow } from 'actions';
import { useUpdatePoolQueryData } from '../../hooks/useUpdatePoolsQuery';
import { MapButton } from './MapButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 24px 0;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  color: #000;
`;

const StyledButton = styled(Button)`
  h2 {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const RefreshIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const VehiclePoolFilter = () => {
  const dispatch = useDispatch<any>();
  const [deleteDraftRows] = useDeleteDraftVehiclePoolRowsMutation();

  const { close: closePoolDialog } = usePoolModalControls();

  const close = () => {
    closePoolDialog();
    deleteDraftRows();
  };

  const [createPoolRow, { isLoading }] = useCreateVehiclesPoolRowMutation();

  const updatePoolQuery = useUpdatePoolQueryData();

  const handleAddListing = async () => {
    if (isLoading) return;
    const data = await createPoolRow();
    if ('error' in data) {
      return;
    }
    updatePoolQuery(draft => {
      draft.unshift(data.data);
    });
  };

  const handleRefresh = () => {
    dispatch(listVehiclesApi.util.invalidateTags(['VehiclesPool', 'SidePanelBids']));
    dispatch(snackShow({ message: 'Refreshing data...' }));
  };

  return (
    <Wrapper>
      <Title>Vehicle Pool</Title>
      <FilterRow>
        <MapButton />
        <RefreshIconWrapper onClick={handleRefresh}>
          <Icon>refresh</Icon>
        </RefreshIconWrapper>
        <StyledButton variant="contained" color="primary" onClick={handleAddListing}>
          + ADD LISTING
        </StyledButton>
        <Icon onClick={close} style={{ cursor: 'pointer' }}>
          close
        </Icon>
      </FilterRow>
    </Wrapper>
  );
};
