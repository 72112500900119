import React from 'react';

export default [
  {
    id: 1,
    question: 'What is AutoAxess?',
    answer:
      'AutoAxess is a weekly online auto auction conducted live every Thursday afternoon. The site is designed to match used car dealers (buyers) with fresh trade-ins being sold wholesale by new car dealers (sellers) without having to drive to an auto auction.'
  },
  {
    id: 2,
    question: 'Is there a cost to register for AutoAxess?',
    answer:
      'No, AutoAxess is free to use. A small buy fee is applied only if you purchase a vehicle.'
  },
  {
    id: 3,
    question: 'Is AutoAxess a "dealer only" site?',
    answer:
      'Yes, the site is not available to the public. Vehicles can only be sold to pre-screened dealers.'
  },
  {
    id: 4,
    question: 'Can I see, touch, and evaluate vehicles before I bid?',
    answer:
      'Yes, we encourage you to visit the franchised dealer and identify yourself as an approved AutoAxess dealer prior to bidding.'
  },
  {
    id: 5,
    question: 'What payment methods for vehicles are accepted?',
    answer:
      "Method of payment for the vehicle is determined by the individual selling dealer.  Some sellers will accept company checks, some require cash or cashier's checks, and some require ACH from your account or flooring through NextGear, AFS, or Westlake. Buyers are responsible to be aware of the payment method required on any particular vehicle and abide by that. Buyer fees to AutoAxess are charged automatically from the credit card you designate at the time you registered."
  },
  {
    id: 6,
    question: 'How much are the buy fees?',
    answer: (
      <>
        <p>AutoAxess charges the buyer:</p>
        <p>$100 for vehicles purchased up to $200</p>
        <p>$150 for vehicles $201 to $500</p>
        <p>$250 for vehicles $501 to $7,999</p>
        <p>$300 for vehicles $8,000 to $14,999</p>
        <p>$350 for vehicles $15,000 to $200,000</p>
        <p>
          We charge no other fees, as long as the purchased vehicle is picked up
          and paid for on time.
        </p>
      </>
    )
  },
  {
    id: 7,
    question: 'When do auctions take place?',
    answer:
      "Auctions take place every Thursday starting at 1 pm. Vehicles are spaced 45 seconds apart.  Each vehicle has its own individual sale time, so check each individual listing on Auction day for exact time auction ends for each car. A live countdown clock shows time left to bid each vehicle in real time. All auctions typically are over by 4pm, but this varies depending on the number of vehicles listed for sale. It's important to be logged on by 1pm on Thursday so you don't miss out."
  },
  {
    id: 8,
    question: 'How does the bid process work?',
    answer:
      'On the home page, you can sort by make, model, selling dealer, payment type, Bluebook value or region, then click on the large photo of the car you\'d like to bid on. This takes you to an information page about the car, equipment, miles, condition and Bluebook values. Be sure to note any special payment instructions required by that dealer. Enter your maximum bid in the box labeled "Max bid" then click the "place bid" button to submit. You can also use the +/- buttons to raise or lower your bid by $100, then submit. Bids must be in increments of $100.'
  },
  {
    id: 9,
    question: 'Can I advance the bid by more than $100?',
    answer: (
      <>
        <p>
          {
            'Yes. If you prefer to simply enter the maximum you\'d like to pay on a car, just enter that dollar amount and hit "place bid" as before. This is called a proxy bid, and that amount is not displayed to any other buyers, sellers or AutoAxess personnel. The high bid will still only advance by $100 increments up to the proxy amount you entered.'
          }
        </p>
        <p>
          {
            "Example: High bid is currently $500 and the net wholesale is $2500. Let's say you're willing to pay $1800 for the car. Enter $1800 and hit the \"place bid\" button. You'll notice that the high bid will advance to $600, and you will be the high bidder. If another buyer bids $700, the system recognizes your proxy bid and will automatically advance to $800 with you as the high bidder. This process will continue up to (but not over) your $1800 proxy amount."
          }
        </p>
      </>
    )
  },
  {
    id: 10,
    question: 'Does the high bidder automatically get the car?',
    answer:
      'Not necessarily. Just like at a traditional auction, the seller may require a higher minimum amount in order to sell the car. Of course if your high bid is acceptable, the seller will award you the car. If your bid is not acceptable, a "bid declined" message will display to all bidders. The seller then has the option to send out a "minimum acceptable bid" as a counter offer to all bidders. The first bidder to click "buy now" will be awarded the car for that amount. You can also make an offer on a vehicle by either calling your AutoAxess representative directly, or our office at 925-315-4033.'
  },
  {
    id: 11,
    question: 'What if my bid is declined but my proxy bid is higher?',
    answer:
      'The seller can opt to send out a "minimum acceptable bid" to all bidders if the displayed bid is not high enough. However, if your proxy bid is greater than or equal to the minimum acceptable amount, you will be automatically awarded the car. Nobody can view your proxy amount except you.'
  },
  {
    id: 12,
    question: 'Sounds great!  How do I get started?',
    answer:
      'Click the Sign Up button at the top of the page. Then fill out the simple registration form, and upload your buyer paperwork.. Welcome to AutoAxess!'
  }
];
