import { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: rgba(249, 249, 249, 1);
  border-radius: 30px;
`;

export const InfoBlock = ({ children }: { children: ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
