import ConfirmModal from '../../Modals/ConfirmModal';

export const ConfirmDeleteModal = ({ handleDelete }: any) => {
  return (
    <ConfirmModal
      title="Are you sure you want to cancel? All the information will be lost"
      handleSubmit={handleDelete}
      submitText={'Confirm'}
      modalId={'cancel-list-vehicles'}
    />
  );
};

export const ConfirmDeleteStoreModal = ({ handleDelete, id }: any) => {
  return (
    <ConfirmModal
      title="Stores will be moved to the right-side panel"
      handleSubmit={handleDelete}
      submitText={'Confirm'}
      modalId={`delete-pool-${id}`}
    />
  );
};
