import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Section } from '../components';
import { selectedVehicleSelector } from '../../../../selectors';
import { COLOR_SUCCESSFUL } from '../../../../constants';
import { FormattedAmount } from '../../../../components';

const StyledFormattedAmount = styled(FormattedAmount)`
  color: ${COLOR_SUCCESSFUL}
`;

const buyFeeInfo = 'This fee is an estimate and it might be subjected to changes. ' +
  'I.e. extra as transportation charges, AutoAxess discounts, etc.';

const BuyFee = ({ vehicle }) => {
  let buyFee = 0
  if (vehicle && vehicle.buy_fee && vehicle.buy_fee.processing_fee) {
    buyFee = vehicle.buy_fee.processing_fee;
  }

  return (
    <Section title='Buy Fee' hoverInfo={buyFeeInfo} xs={6} md={3}>
      <StyledFormattedAmount amount={buyFee} />
    </Section>
  );
}

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state),
});

export default connect(mapStateToProps)(BuyFee);
