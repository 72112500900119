/* eslint-disable no-case-declarations */
import {
  LIST_VEHICLES_REQUEST,
  LIST_VEHICLES_SUCCESS,
  LIST_VEHICLES_FAILURE,
  LIST_VEHICLES_CLEAR,
  CREATE_VEHICLE_REQUEST,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  VEHICLES_SET_PAGE,
  VEHICLES_SET_PER_PAGE,
  GET_VIN_INFO_REQUEST,
  GET_VIN_INFO_SUCCESS,
  GET_VIN_INFO_FAILURE,
  RESET_VIN_INFO,
  GET_CAR_ESTIMATE_REQUEST,
  GET_CAR_ESTIMATE_SUCCESS,
  GET_CAR_ESTIMATE_FAILURE,
  CREATE_VEHICLE_SET_TMP_IMAGE,
  RESET_TMP_IMAGES,
  VEHICLES_UNSET_SUCCESS,
  GET_VEHICLE_DETAILS_REQUEST,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAILURE,
  VEHICLE_AWARD_BUYER_REQUEST,
  VEHICLE_AWARD_BUYER_SUCCESS,
  VEHICLE_AWARD_BUYER_FAILURE,
  VEHICLE_PULL_REQUEST,
  VEHICLE_PULL_SUCCESS,
  VEHICLE_PULL_FAILURE,
  VEHICLE_RELIST_FAILURE,
  VEHICLE_SELLER_ACCEPT_BID_REQUEST,
  VEHICLE_SELLER_ACCEPT_BID_SUCCESS,
  VEHICLE_SELLER_ACCEPT_BID_FAILURE,
  VEHICLE_ADMIN_APPROVE_BID_FAILURE,
  VEHICLE_SELLER_SET_COUNTER_REQUEST,
  VEHICLE_SELLER_SET_COUNTER_SUCCESS,
  VEHICLE_SELLER_SET_COUNTER_FAILURE,
  VEHICLE_ADMIN_SET_COUNTER_REQUEST,
  VEHICLE_ADMIN_SET_COUNTER_SUCCESS,
  VEHICLE_ADMIN_SET_COUNTER_FAILURE,
  VEHICLES_HOME_FILTERS_REQUEST,
  VEHICLES_HOME_FILTERS_SUCCESS,
  VEHICLES_HOME_FILTERS_FAILURE,
  WS_VEHICLE_BIDS_NEW,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_VEHICLE_AUCTION_UPDATE,
  LIST_VEHICLES_CHANGE_AUCTION_STATUS,
  FILTER_SELLER_VEHICLES_REQUEST,
  FILTER_SELLER_VEHICLES_SUCCESS,
  RESET_VEHICLE_DETAILS,
  RESET_CAR_ESTIMATE,
  VEHICLES_SELLER_SET_PAGE,
  REQUEST_IN_PROGRESS,
  WS_BIDS_VEHICLE_UPDATE,
  WS_BIDS_NEW_BID,
  WS_NEW_BID,
  WS_NEW_PROXY,
  WS_MY_BID_UPDATE
} from '../constants/actionTypes';
import {
  LIST_VEHICLES_SALES_HISTORY_SUCCESS,
  LIST_VEHICLES_SALES_HISTORY_REQUEST,
  LIST_VEHICLES_SALES_HISTORY_FAILURE,
  LIST_VEHICLES_ADMIN_REQUEST,
  LIST_VEHICLES_ADMIN_SUCCESS,
  FILTER_VEHICLES_ADMIN_REQUEST,
  FILTER_VEHICLES_ADMIN_SUCCESS,
  CHANGE_VEHICLE_TRIM,
  FILTER_VEHICLE_SALES_HISTORY_REQUEST,
  FILTER_VEHICLE_SALES_HISTORY_SUCCESS,
  VEHICLE_SALES_HISTORY_SET_PAGE,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
  VEHICLE_MANUAL_UPLOAD_SUCCESS,
  VEHICLE_BUYER_DOCS_SUCCESS,
  VEHICLE_BUYER_DOCS_REQUEST,
  INIT_VEHICLE_DETAILS_DATA,
  UPLOAD_VEHICLE_IMAGE_REQUEST,
  UPLOAD_VEHICLE_IMAGE_SUCCESS,
  UPLOAD_VEHICLE_IMAGE_FAILURE,
  DELETE_VEHICLE_IMAGE,
  SET_VEHICLE_IMAGES,
  LOAD_VEHICLE_SALES_COUNTERS_SUCCESS,
  VEHICLE_DELIVERY_ESTIMATE_REQUEST,
  VEHICLE_DELIVERY_ESTIMATE_SUCCESS,
  VEHICLE_DELIVERY_ESTIMATE_FAILURE,
  AUCTION_DETAIL_AWARD_BUYER_SUCCESS
} from '../actions/vehicles';
import { VEHICLE_TABS_TYPES, VEHICLE_SALES_PER_PAGE, VEHICLES_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  itemsData: {
    rows: [],
    count: 0
  },
  salesHistoryData: {
    rows: [],
    count: 0,
    makes: [],
    models: [],
    page: 1,
    perPage: VEHICLE_SALES_PER_PAGE
  },
  sellerVehicles: {
    page: 1,
    perPage: VEHICLES_PER_PAGE,
    rows: [],
    count: 0
  },
  auctionStatus: 'active',
  selectedVehicle: {
    data: null,
    wasDataInited: false,
    runbuggy: {
      loading: false,
      estimate: null,
      error: null
    },
    error: null,
    buyer: {},
    buyerDocs: []
  },
  page: 1,
  perPage: VEHICLES_PER_PAGE,
  error: '',
  vinInfo: {
    success: false,
    duplicate: false,
    data: {}
  },
  vinInfoError: '',
  carEstimate: {
    success: false,
    data: {}
  },
  vinInfoTrims: [],
  carEstimateError: '',
  success: false,
  tmpImages: Array(9).fill({}),
  homeFilters: {},
  vehiclesAdmin: {
    rows: [],
    count: 0
  },
  vehicleImages: [],
  requestInProgress: false
};

const vehicles = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_VEHICLES_REQUEST:
      return { ...state, loading: true, itemsData: { rows: [], count: 0 } };
    case LIST_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_VEHICLES_FAILURE:
      return {
        ...state,
        loading: false,
        itemsData: (payload && payload.data) || {}
      };
    case LIST_VEHICLES_CLEAR:
      return {
        ...state,
        itemsData: initialState.itemsData,
        vehiclesAdmin: initialState.vehiclesAdmin
      };
    case LIST_VEHICLES_ADMIN_REQUEST:
      return { ...state, loading: true };
    case REQUEST_IN_PROGRESS:
      return { ...state, requestInProgress: payload };
    case LIST_VEHICLES_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        vehiclesAdmin: {
          ...state.vehiclesAdmin,
          rows: [...state.vehiclesAdmin.rows, ...payload.data.rows],
          count: payload.data.count
        }
      };
    case FILTER_VEHICLES_ADMIN_REQUEST:
      return { ...state, loading: true };
    case FILTER_VEHICLES_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        vehiclesAdmin: {
          rows: payload.data.rows,
          count: payload.data.count
        }
      };
    case VEHICLE_MANUAL_UPLOAD_SUCCESS:
      // eslint-disable-next-line
      const vehicleAlreadyExist = state.sellerVehicles.rows.find(vehicle => vehicle.id === payload.id);
      if (vehicleAlreadyExist) {
        return {
          ...state,
          loading: false,
          selectedVehicle: {
            data: null,
            wasDataInited: false,
            runbuggy: {
              loading: false,
              estimate: null,
              error: null
            },
            error: null,
            buyer: {},
            buyerDocs: []
          },
          sellerVehicles: {
            ...state.sellerVehicles,
            rows: state.sellerVehicles.rows.map(vehicle => {
              if (payload.id === vehicle.id) return payload;
              return vehicle;
            })
          }
        };
      }
      return {
        ...state,
        loading: false,
        sellerVehicles: {
          ...state.sellerVehicles,
          rows: [payload, ...state.sellerVehicles.rows],
          count: state.sellerVehicles.count + 1
        }
      };
    case LIST_VEHICLES_SALES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LIST_VEHICLES_SALES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        salesHistoryData: {
          ...state.salesHistoryData,
          count: payload.data.count,
          rows: [...state.salesHistoryData.rows, ...payload.data.rows],
          makes: payload.data.makes,
          models: payload.data.models
        }
      };
    case VEHICLE_SALES_HISTORY_SET_PAGE:
      return {
        ...state,
        salesHistoryData: {
          ...state.salesHistoryData,
          page: payload
        }
      };
    case FILTER_VEHICLE_SALES_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FILTER_VEHICLE_SALES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        salesHistoryData: {
          ...state.salesHistoryData,
          count: payload.data.count,
          rows: payload.data.rows,
          makes: payload.data.makes,
          models: payload.data.models
        }
      };
    case LIST_VEHICLES_SALES_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        salesHistoryData: {
          ...state.salesHistoryData,
          count: 0,
          rows: [],
          makes: [],
          models: []
        }
      };
    case FILTER_SELLER_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FILTER_SELLER_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        sellerVehicles: {
          ...state.sellerVehicles,
          rows: payload.data.rows,
          count: payload.data.count
        }
      };
    case LIST_VEHICLES_CHANGE_AUCTION_STATUS:
      return {
        ...state,
        auctionStatus: payload
      };
    case CREATE_VEHICLE_REQUEST:
      return { ...state, loading: true, error: '', success: false };
    case CREATE_VEHICLE_SUCCESS: {
      const updatedState = {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: [...state.itemsData.rows, payload.data],
          count: +state.itemsData.count + 1
        }
      };
      return {
        ...updatedState,
        loading: false,
        submitting: false,
        success: payload.data.id || true,
        error: ''
      };
    }
    case CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
        success: false
      };
    case UPDATE_VEHICLE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            images: payload.data.images || []
          }
        }
      };
    case UPDATE_VEHICLE_FAILURE:
      return { ...state, loading: false };
    case DELETE_VEHICLE_REQUEST:
      return { ...state, loading: true, error: '' };
    case DELETE_VEHICLE_SUCCESS: {
      return {
        ...state,
        vehiclesAdmin: {
          ...state.vehiclesAdmin,
          rows: state.vehiclesAdmin.rows.filter(item => item.id !== payload.id)
        },
        loading: false,
        error: ''
      };
    }
    case DELETE_VEHICLE_FAILURE:
      return { ...state, loading: false, error: payload.response.data.message };
    case VEHICLES_SET_PAGE:
      return { ...state, page: payload };
    case VEHICLES_SET_PER_PAGE:
      return { ...state, loading: false, perPage: payload };
    case VEHICLES_SELLER_SET_PAGE:
      return {
        ...state,
        sellerVehicles: { ...state.sellerVehicles, page: payload }
      };
    case GET_VIN_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        vinInfo: {
          ...state.vinInfo
        },
        vinInfoError: ''
      };
    case GET_VIN_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        vinInfo: {
          ...state.vinInfo,
          data: payload.data[0],
          success: payload.success,
          duplicate: false
        },
        vinInfoError: '',
        vinInfoTrims: payload.data
      };
    case CHANGE_VEHICLE_TRIM:
      return {
        ...state,
        vinInfo: {
          ...state.vinInfo,
          duplicate: false,
          data: state.vinInfoTrims.find(vin => vin.trim === payload.trim) || state.vinInfo.data
        }
      };
    case GET_VIN_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        vinInfo: {
          ...initialState.vinInfo,
          duplicate:
            payload.response.data.data && payload.response.data.data[0] && payload.response.data.data[0].duplicate,
          data: payload.response.data.data && payload.response.data.data[0] && payload.response.data.data[0].data
        },
        vinInfoError: payload.response.data.message
      };
    case RESET_VIN_INFO:
      return { ...state, loading: false, vinInfo: initialState.vinInfo };
    case GET_CAR_ESTIMATE_REQUEST:
      return { ...state, loading: true, carEstimateError: '' };
    case GET_CAR_ESTIMATE_SUCCESS:
      return {
        ...state,
        loading: false,
        carEstimateError: '',
        carEstimate: payload
      };
    case GET_CAR_ESTIMATE_FAILURE:
      return {
        ...state,
        loading: false,
        carEstimateError: payload.response?.data?.data?.[0]?.message ?? 'Car estimation failed'
      };
    case RESET_CAR_ESTIMATE:
      return {
        ...state,
        loading: false,
        carEstimate: initialState.carEstimate
      };
    // TODO: Delete that after improvmenets to vehicle picture upload
    case CREATE_VEHICLE_SET_TMP_IMAGE: {
      const { key, file, thumb, isNew } = payload;
      let tmpImages = state.tmpImages.map((item, i) => {
        const newItem = { ...item };
        if (i === key) {
          if (file && thumb) {
            newItem.file = file;
            newItem.thumb = thumb;
            newItem.isNew = isNew || false;
          } else {
            return {};
          }
        }
        return newItem;
      });
      if ((key === undefined || !tmpImages.find(item => item.file === file)) && file && thumb) {
        tmpImages = [
          ...tmpImages,
          {
            file,
            thumb,
            isNew: isNew || false
          }
        ];
      }
      // remove empty images, except with labels (first X images)
      tmpImages = tmpImages.filter((item, i) => i < initialState.tmpImages.length || Object.keys(item).length > 0);
      return {
        ...state,
        tmpImages
      };
    }
    case RESET_TMP_IMAGES:
      return { ...state, tmpImages: initialState.tmpImages };
    case VEHICLES_UNSET_SUCCESS:
      return { ...state, loading: false, success: false };
    case GET_VEHICLE_DETAILS_REQUEST:
      // This will improve UX, if we preset vehicle details data - it'll show view ASAP
      return { ...state, loading: !state.selectedVehicle.wasDataInited };
    case GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          wasDataInited: false,
          data: payload.data
        }
      };
    case GET_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          wasDataInited: false,
          error: payload
        }
      };
    case INIT_VEHICLE_DETAILS_DATA:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          wasDataInited: true,
          data: {
            ...payload,
            bids: [],
            counters: []
          }
        }
      };
    case RESET_VEHICLE_DETAILS:
      return {
        ...state,
        loading: false,
        selectedVehicle: initialState.selectedVehicle
      };
    case VEHICLE_DELIVERY_ESTIMATE_REQUEST:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          runbuggy: {
            ...state.selectedVehicle.runbuggy,
            loading: true,
            error: null
          }
        }
      };
    case VEHICLE_DELIVERY_ESTIMATE_SUCCESS:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          runbuggy: {
            ...state.selectedVehicle.runbuggy,
            loading: false,
            estimate: payload,
            error: null
          }
        }
      };
    case VEHICLE_DELIVERY_ESTIMATE_FAILURE:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          runbuggy: {
            ...state.selectedVehicle.runbuggy,
            loading: false,
            estimate: null,
            error: true
          }
        }
      };
    case WS_VEHICLE_BIDS_NEW:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            bids: [payload, ...state.selectedVehicle.data.bids]
          }
        }
      };
    case WS_NEW_BID:
      return {
        ...state,
        selectedVehicle:
          payload.vehicle_id === state.selectedVehicle?.data?.id
            ? {
                ...state.selectedVehicle,
                data: {
                  ...state.selectedVehicle.data,
                  bids: [payload, ...state.selectedVehicle.data.bids],
                  highest_bid: payload
                }
              }
            : { ...state.selectedVehicle }
      };
    case WS_NEW_PROXY:
      return {
        ...state,
        selectedVehicle:
          payload.vehicle_id === state.selectedVehicle?.data?.id
            ? {
                ...state.selectedVehicle,
                data: {
                  ...state.selectedVehicle.data,
                  proxy_bid: payload
                }
              }
            : { ...state.selectedVehicle }
      };
    case WS_MY_BID_UPDATE:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            my_bid: payload
          }
        }
      };
    case WS_VEHICLE_DETAILS_UPDATE:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.id) {
              return {
                ...vehicle,
                ...payload
              };
            }
            return vehicle;
          })
        },
        selectedVehicle:
          state.selectedVehicle.data.id === payload.id
            ? {
                ...state.selectedVehicle,
                data: {
                  ...state.selectedVehicle.data,
                  ...payload
                }
              }
            : { ...state.selectedVehicle }
      };
    case WS_VEHICLE_AUCTION_UPDATE:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            auction: {
              ...state.selectedVehicle.data.auction,
              ...payload
            }
          }
        }
      };
    case VEHICLE_AWARD_BUYER_REQUEST:
      return { ...state, loading: true };
    case VEHICLE_AWARD_BUYER_SUCCESS:
    case AUCTION_DETAIL_AWARD_BUYER_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            ...payload.data
          }
        }
      };
    case VEHICLE_AWARD_BUYER_FAILURE:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            ...payload.data
          }
        }
      };
    case VEHICLE_PULL_REQUEST:
      return { ...state };
    case VEHICLE_PULL_SUCCESS:
      return { ...state };
    case VEHICLE_PULL_FAILURE:
      return { ...state, error: payload };
    case VEHICLE_RELIST_FAILURE:
      return { ...state, error: payload };
    case VEHICLE_SELLER_ACCEPT_BID_REQUEST:
      return { ...state };
    case VEHICLE_SELLER_ACCEPT_BID_FAILURE:
      return { ...state, error: payload };
    case VEHICLE_ADMIN_APPROVE_BID_FAILURE:
      return {
        ...state,
        loading: false,
        selectedVehicle: {
          ...state.selectedVehicle,
          data: {
            ...state.selectedVehicle.data,
            ...payload.data
          }
        }
      };
    case VEHICLE_ADMIN_SET_COUNTER_REQUEST:
      return { ...state };
    case VEHICLE_ADMIN_SET_COUNTER_SUCCESS:
      return { ...state };
    case VEHICLE_ADMIN_SET_COUNTER_FAILURE:
      return { ...state, error: payload };
    case VEHICLES_HOME_FILTERS_REQUEST:
      return { ...state };
    case VEHICLES_HOME_FILTERS_SUCCESS:
      return { ...state, homeFilters: payload.data };
    case VEHICLES_HOME_FILTERS_FAILURE:
      return { ...state, error: payload };
    case VEHICLE_BUYER_DOCS_REQUEST:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          buyer: {},
          buyerDocs: []
        }
      };
    case VEHICLE_BUYER_DOCS_SUCCESS:
      return {
        ...state,
        selectedVehicle: {
          ...state.selectedVehicle,
          buyer: payload.buyer,
          buyerDocs: payload.docs
        }
      };
    case SET_VEHICLE_IMAGES:
      return {
        ...state,
        vehicleImages: payload || initialState.vehicleImages
      };
    case UPLOAD_VEHICLE_IMAGE_REQUEST:
      const isImageKeyExists = state.vehicleImages.find(vehicle => vehicle.key === payload.key);
      if (isImageKeyExists) {
        return {
          ...state,
          vehicleImages: state.vehicleImages.map(image => {
            if (image.key === payload.key) return payload;
            return image;
          })
        };
      }
      return {
        ...state,
        vehicleImages: [...state.vehicleImages, payload]
      };
    case UPLOAD_VEHICLE_IMAGE_SUCCESS:
      return {
        ...state,
        vehicleImages: state.vehicleImages.map(image => {
          if (image.key === parseInt(payload.o, 10)) {
            return {
              ...image,
              ...payload,
              loading: false,
              failed: false
            };
          }
          return image;
        })
      };
    case UPLOAD_VEHICLE_IMAGE_FAILURE:
      return {
        ...state,
        vehicleImages: state.vehicleImages.map(image => {
          if (image.key === parseInt(payload.key, 10)) {
            return {
              ...image,
              ...payload,
              loading: false,
              failed: true
            };
          }
          return image;
        })
      };
    case DELETE_VEHICLE_IMAGE:
      return {
        ...state,
        vehicleImages: state.vehicleImages.filter(vehicle => vehicle.key !== payload.key)
      };
    case WS_BIDS_VEHICLE_UPDATE:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.id) {
              return {
                ...vehicle,
                ...payload
              };
            }
            return vehicle;
          })
        }
      };
    case WS_BIDS_NEW_BID:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.vehicle_id) {
              return {
                ...vehicle,
                bids: [payload, ...vehicle.bids]
              };
            }
            return vehicle;
          })
        }
      };
    default:
      return state;
  }
};

export default vehicles;
