import React, { useState } from 'react';
import FormTextField from '../FormTextField';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { ErrorIcon } from '../../Icons/ErrorIcon';
import { ShowPasswordIcon } from '../../Icons/ShowPassword';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  validate?: FieldValidator<any>;
  autocomplete?: 'on' | 'off';
};

const OutlinedFormPasswordField = ({
  name = 'password',
  label = 'Password*',
  placeholder = '******',
  validate = undefined,
  autocomplete = 'on'
}: Props) => {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const togglePasswordHidden = () => {
    setPasswordHidden(curr => !curr);
  };

  return (
    <Field name={name} validate={validate}>
      {fieldProps => {
        const hasError = (fieldProps.meta.touched && fieldProps.meta.error) || fieldProps.meta.submitError;
        return (
          <FormTextField
            input={fieldProps.input}
            meta={fieldProps.meta}
            label={label}
            type={passwordHidden ? 'password' : 'text'}
            placeholder={placeholder}
            fullWidth
            autoComplete={autocomplete}
            includeBottomIndent
            Icon={hasError ? ErrorIcon : ShowPasswordIcon}
            iconOptions={{
              position: 'end',
              handler: togglePasswordHidden
            }}
            styleVariant={hasError ? 'outlined_error' : 'outlined'}
          />
        );
      }}
    </Field>
  );
};

export default OutlinedFormPasswordField;
