import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ActiveItem } from '../listVehiclesSlice/listVehiclesSlice';

type ListerDashboardState = {
  activeItem: ActiveItem | null;
};

const initialState: ListerDashboardState = {
  activeItem: null
};

const listerDashboardSlice = createSlice({
  name: 'listerDashboard',
  initialState,
  reducers: {
    setDraggableItem: (state, action: PayloadAction<ActiveItem>) => {
      if (state.activeItem) return;
      state.activeItem = action.payload;
    },
    setDraggableDayNumber: (state, action: PayloadAction<number>) => {
      if (!state.activeItem) return;
      state.activeItem.dayNumber = action.payload;
    },
    setDraggableOrderIndex: (state, action: PayloadAction<number>) => {
      if (!state.activeItem) return;
      state.activeItem.orderIndex = action.payload;
    },
    clearDraggableItem: state => {
      state.activeItem = null;
    }
  }
});

export const useGetListerDraggableItem = () => {
  const item = useSelector<{ listerDashboard: ListerDashboardState }, ActiveItem | null>(
    state => state.listerDashboard.activeItem
  );

  return item;
};

export const {
  setDraggableItem,
  clearDraggableItem,
  setDraggableOrderIndex,
  setDraggableDayNumber
} = listerDashboardSlice.actions;
export default listerDashboardSlice;
