import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import { Grid, Typography, Button, MenuItem } from '@material-ui/core';

import {
  REGISTRATION_MAX_STRING_LENGTH,
  CONTACT_FORM_TOPICS,
  roles
} from '../../../../constants';
import {
  FormTextField,
  FormSelect,
  FormFormattedField
} from '../../../../components/Form';
import {
  required,
  validateEmail,
  maxLength,
  phoneNumberValidation
} from '../../../../utils/validators';
import { sendContactForm } from '../../../../actions';

import letterIcon from '../../../../img/Letter.png';

const StyledTitleSection = styled(Grid)`
  && {
    margin: 48px 0 35px 0;
    position: relative;
    @media (max-width: 960px) {
      padding-left: 45px;
    }
  }
`;

const ButtonWithMargin = styled(Button)`
  && {
    margin-left: 8px;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: -50px;
  @media (max-width: 960px) {
    left: 0;
  }
`;

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);
const maxMessageLength = maxLength(500);

class ContactForm extends Component {
  componentDidMount() {
    const { initialize, user } = this.props;
    if (Object.keys(user).length && user.role !== roles.ADMIN) {
      const initialValues = {
        fullName: `${user.first_name} ${user.first_name}`,
        dealershipName: user.dealership_name,
        phone: user.seller ? user.seller.phone_number : user.buyer.phone_number,
        email: user.email,
        topic: '',
        message: ''
      };
      initialize(initialValues);
    }
  }

  submit = values => {
    const { submitMessage, reset } = this.props;
    return submitMessage(values).then(reset);
  };

  render() {
    const {
      invalid,
      anyTouched,
      change,
      untouch,
      handleSubmit,
      submitting,
      initialized
    } = this.props;

    return (
      <Grid container>
        <StyledTitleSection item xs={12}>
          <StyledImg src={letterIcon} alt="shield" />
          <Typography variant="display1">Contact Form</Typography>
          <Typography variant="caption">All the fields are required</Typography>
        </StyledTitleSection>
        <form onSubmit={handleSubmit(this.submit)}>
          <Grid container item xs={12} spacing={32}>
            <Grid item xs={12}>
              <Field
                component={FormTextField}
                name="fullName"
                label="First &amp; Last Name"
                placeholder="First &amp; Last Name"
                fullWidth
                disabled={initialized}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormTextField}
                name="dealershipName"
                label="Dealer Name"
                placeholder="Dealer Name"
                fullWidth
                disabled={initialized}
                validate={[required, maxStringLength]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={FormTextField}
                name="email"
                label="Email Address"
                placeholder="Email Address"
                fullWidth
                disabled={initialized}
                validate={[required, validateEmail]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="phone"
                label="Phone Number"
                placeholder="Phone Number"
                component={FormFormattedField}
                disabled={initialized}
                validate={[required, phoneNumberValidation]}
                maskProps={{
                  format: '(###) ###-####',
                  mask: '_'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="topic"
                label="Please select a topic"
                component={FormSelect}
                placeholder="Please select a topic"
                validate={[required]}
              >
                {Object.keys(CONTACT_FORM_TOPICS).map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {CONTACT_FORM_TOPICS[item]}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                multiline
                fullWidth
                component={FormTextField}
                name="message"
                label="Message"
                placeholder="Message"
                validate={[required, maxMessageLength]}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={invalid || submitting}
              >
                send
              </Button>
              {anyTouched && (
                <ButtonWithMargin
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    change('message', '');
                    untouch('message');
                  }}
                >
                  clear message
                </ButtonWithMargin>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.user.contactForm.loading,
  contactFormError: state.user.contactForm.error,
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  submitMessage: data =>
    new Promise((resolve, reject) => {
      dispatch(sendContactForm({ data, resolve, reject }).request);
    })
});

export default compose(
  reduxForm({ form: 'contactForm' }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContactForm);
