import apiSlice from '..';
import { Vehicle } from '../../../types/vehicle';
import { convertToFormData } from '../../../utils/api';
import { FieldLocalImage } from '../../uploadVehicle/assignVehicleImagesSlice';
import { CheckboxField } from '../vehicle-sections/types/common';
import { BlueBookInfo } from './types';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type AddCheckboxArgs = {
  vehicleId: number;
  sectionName: string;
  payload: { selected: boolean; label: string };
};

type UpdateCheckboxArgs = {
  checkboxId: number;
  payload: { selected?: boolean; notes?: string; label?: string };
};

export type ImageField = {
  id: number;
  label: string;
  notes: string;
  url: string;
  url_thumb: string;
  type: string;
  is_damaged?: boolean;
};

export type UpdateSectionPayload = {
  sectionName: string;
  subSectionName?: string;
  notes?: string;
  grade?: string;
};

export type SaveImagePayload = {
  images: FieldLocalImage[];
  sectionName: string;
  vehicleId: number;
  subSectionName: string;
};

export type UpdateImagePayload = {
  vehicleId: number | string;
  id: number;
  order?: number;
  label?: string;
  notes?: string;
  type?: string;
  is_damaged?: boolean;
  file?: File;
};

export const uploadVehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getVinInfoByVehicleId: builder.query<BlueBookInfo[], number>({
      query: vehicleId => ({
        url: `vehicles/upload/vin-info/${vehicleId}`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    getVehicleById: builder.query<Vehicle, number>({
      query: id => ({
        url: `vehicles/${id}`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    uploadVehicle: builder.mutation<Vehicle, { vin: string; seller_id: number; upload_type?: string }>({
      query: ({ vin, seller_id, upload_type }) => ({
        url: `vehicles/upload?upload_type=${upload_type}`,
        method: 'POST',
        body: {
          vin,
          seller_id
        }
      }),
      transformResponse: (r: any) => r.data
    }),
    updateVehicleField: builder.mutation<{ data: Vehicle }, { payload: DeepPartial<Vehicle>; id: number }>({
      query: ({ payload, id }) => ({
        url: `vehicles/upload/${id}`,
        body: payload,
        method: 'PATCH'
      })
    }),
    estimateCarPrice: builder.mutation<{ data: Partial<Vehicle> }, { vehicleId: number; mileage: number }>({
      query: ({ vehicleId, mileage }) => ({
        url: `vehicles/upload/${vehicleId}/estimate-price`,
        body: { mileage },
        method: 'POST'
      })
    }),
    saveSectionImages: builder.mutation<
      { data: FieldLocalImage[]; sectionName: string; subSectionName: string },
      SaveImagePayload
    >({
      query: ({ vehicleId, sectionName, subSectionName, images }) => {
        const formData = new FormData();
        for (const i in images) {
          const image = images[i];
          if (image.file) {
            formData.append(`images[${i}][file]`, image.file);
          }
          if (image.id) {
            formData.append(`images[${i}][id]`, image.id?.toString());
          }
          if (image.url) {
            formData.append(`images[${i}][url]`, image.url);
          }
          if (image.url_thumb) {
            formData.append(`images[${i}][url_thumb]`, image.url_thumb);
          }
          if (image.label) {
            formData.append(`images[${i}][label]`, image.label);
          }
          if (image.notes) {
            formData.append(`images[${i}][notes]`, image.notes);
          }
          if (image.type) {
            formData.append(`images[${i}][type]`, image.type);
          }
          if (image.is_damaged != null) {
            formData.append(`images[${i}][is_damaged]`, image.is_damaged.toString());
          }
        }

        formData.append('sectionName', sectionName);
        formData.append('subSectionName', subSectionName);

        return {
          url: `vehicles/upload/images/${vehicleId}`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    updateSectionImage: builder.mutation<{ data: ImageField }, UpdateImagePayload>({
      query: ({ vehicleId, id, ...payload }) => {
        const formData = convertToFormData(payload);

        return {
          url: `vehicles/upload/${vehicleId}/images/${id}`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    deleteSectionImage: builder.mutation<void, number>({
      query: id => ({
        url: `vehicles/upload/images/${id}`,
        method: 'DELETE'
      })
    }),
    updateVehicleSection: builder.mutation<void, { vehicleId: number; payload: UpdateSectionPayload }>({
      query: ({ vehicleId, payload }) => ({
        url: `vehicles/upload/sections/${vehicleId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    addCheckbox: builder.mutation<{ data: CheckboxField }, AddCheckboxArgs>({
      query: ({ vehicleId, sectionName, payload }) => ({
        url: `vehicles/upload/${vehicleId}/${sectionName}/checkboxes`,
        method: 'POST',
        body: payload
      })
    }),
    updateCheckbox: builder.mutation<void, UpdateCheckboxArgs>({
      query: ({ checkboxId, payload }) => ({
        url: `vehicles/upload/checkboxes/${checkboxId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    removeCheckbox: builder.mutation<void, number>({
      query: checkboxId => ({
        url: `vehicles/upload/checkboxes/${checkboxId}`,
        method: 'DELETE'
      })
    }),
    changeVin: builder.mutation<{ data: Vehicle }, { vehicleId: number; vin: string }>({
      query: ({ vehicleId, vin }) => ({
        url: `vehicles/change-vin/${vehicleId}`,
        method: 'POST',
        body: { vin }
      })
    }),
    submitVehicle: builder.mutation<void, { vehicleId: number; auctionType: string }>({
      query: ({ vehicleId, auctionType }) => ({
        url: `vehicles/upload/submit/${vehicleId}`,
        method: 'POST',
        body: { auctionType }
      })
    }),
    submitVehicleForReview: builder.mutation<void, { vehicleId: number }>({
      query: ({ vehicleId }) => ({
        url: `vehicles/upload/submit-for-review/${vehicleId}`,
        method: 'POST'
      })
    }),
    loadSectionAudio: builder.mutation<
      { data: { audio_url: string | null } },
      { vehicleId: number; sectionName: string; subSectionName: string; audio: Blob | null }
    >({
      query: ({ vehicleId, audio, sectionName, subSectionName }) => {
        const formData = convertToFormData({ sectionName, subSectionName });
        if (audio) {
          formData.append('audio', audio);
        }

        return {
          url: `vehicles/upload/${vehicleId}/audio`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    addVehicleOption: builder.mutation<
      { data: { id: number } },
      { vehicleId: number; payload: { optionName: string; isTypical: boolean } }
    >({
      query: ({ vehicleId, payload }) => ({
        url: `/vehicles/upload/${vehicleId}/manual/options`,
        body: payload,
        method: 'POST'
      })
    }),
    changeVehicleOption: builder.mutation<
      { data: { id: number } },
      { vehicleId: number; optionId: number; payload: { optionName?: string; isTypical?: boolean } }
    >({
      query: ({ vehicleId, optionId, payload }) => ({
        url: `/vehicles/upload/${vehicleId}/manual/options/${optionId}`,
        body: payload,
        method: 'PATCH'
      })
    }),
    removeVehicleOption: builder.mutation<void, { vehicleId: number; optionId: number }>({
      query: ({ vehicleId, optionId }) => ({
        url: `/vehicles/upload/${vehicleId}/manual/${optionId}`,
        method: 'DELETE'
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetVehicleByIdQuery,
  useUploadVehicleMutation,
  useUpdateVehicleFieldMutation,
  useEstimateCarPriceMutation,
  useUpdateVehicleSectionMutation,
  useSaveSectionImagesMutation,
  useUpdateSectionImageMutation,
  useDeleteSectionImageMutation,
  useAddCheckboxMutation,
  useUpdateCheckboxMutation,
  useRemoveCheckboxMutation,
  useChangeVinMutation,
  useGetVinInfoByVehicleIdQuery,
  useSubmitVehicleMutation,
  useSubmitVehicleForReviewMutation,
  useLoadSectionAudioMutation,
  useAddVehicleOptionMutation,
  useRemoveVehicleOptionMutation,
  useChangeVehicleOptionMutation
} = uploadVehicleApiSlice;

export default uploadVehicleApiSlice;
