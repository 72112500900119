/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconButton, MenuItem, MenuList, ListItemText, Drawer } from '@material-ui/core';
import DropDownList from './DropDownList';
import ProfileDropDownList from './ProfileDropDownList';
import MobileProfileLinks from './MobileProfileLinks';
import { useSelector } from 'react-redux';

const StyledMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledLinkMenuItem = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  min-width: fit-content;
  text-transform: uppercase;
  color: #212121;
  position: relative;
  span {
    text-transform: none;
  }
  &:hover:before,
  &.active:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #212121;
    position: absolute;
    top: -28px;
  }
  @media only screen and (min-width: 1300px) {
    margin-left: 24px;
  }
  @media only screen and (max-width: 1025px) {
    display: none;
  }
  > .menu-burger {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
`;

const AuctionButton = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  text-transform: uppercase;
  color: #167e13;
  position: relative;

  .pulse {
    box-sizing: border-box;
    display: block;
    width: 14px;
    height: 14px;
    margin-right: 12px;
    border: 4px solid #2ad345;
    border-radius: 50%;
    background: #167e13;
    box-shadow: 0 0 0 0 rgba(42, 211, 69, 0.5);
    animation: pulse 1.5s infinite;
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

const StyledLinkButton = styled(NavLink)`
  padding: 10px 26px 9px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 50px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  background: #225a91;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover,
  &:active {
    background: rgb(0, 62, 105);
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

const StyledMobileMenuItem = styled(MenuItem)`
  && {
    padding-top: ${props => (props.isTitle ? '12px' : 0)};
    padding-bottom: ${props => (props.isTitle ? '12px' : 0)};
    height: 100%;
    a {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

const StyledMobileLinkMenuItem = styled(NavLink)`
  display: block;
  padding-left: ${props => `${props.paddingLeft}px` || 0};
  text-decoration: none;
`;

const StyledMobileButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
`;

const StyledButton = styled.button`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  color: #212121;
  position: relative;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  font-family: 'Roboto';
  text-transform: uppercase;

  span {
    text-transform: none;
  }
  &:hover:before,
  &.active:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #212121;
    position: absolute;
    top: -28px;
  }
  @media only screen and (max-width: 1025px) {
    display: none;
  }
  > .menu-burger {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
`;

const renderLinks = (links, isAdminLoggedIn) =>
  links.map(({ to, list, type, text, onClick }, index) => {
    if (to === '/register') {
      return (
        <StyledLinkButton exact to={to} key={index}>
          {text}
        </StyledLinkButton>
      );
    }
    if (to) {
      return (
        <StyledLinkMenuItem exact to={to} key={index}>
          {text}
        </StyledLinkMenuItem>
      );
    } else if (onClick) {
      return (
        <StyledButton onClick={onClick} key={index}>
          {text}
        </StyledButton>
      );
    } else if (list) {
      return <DropDownList key={index} text={text} list={list} />;
    } else if (type === 'profile') {
      return <ProfileDropDownList key={index} isAdminLoggedIn={isAdminLoggedIn} />;
    }
    return null;
  });

const renderMobileLinks = (links, isAdminLoggedIn) =>
  links.map(({ to, type, text, list, onClick }, index) => {
    if (to) {
      return (
        <StyledMobileMenuItem key={index}>
          <StyledMobileLinkMenuItem to={to}>
            <ListItemText primary={text} />
          </StyledMobileLinkMenuItem>
        </StyledMobileMenuItem>
      );
    } else if (onClick) {
      return (
        <StyledMobileMenuItem key={index}>
          <StyledMobileButton type="button" onClick={onClick}>
            <ListItemText primary={text} />
          </StyledMobileButton>
        </StyledMobileMenuItem>
      );
    } else if (list) {
      return (
        <React.Fragment key={index}>
          <StyledMobileMenuItem isTitle>
            <ListItemText primary={text} />
          </StyledMobileMenuItem>
          {list.map((item, listIndex) => (
            <StyledMobileMenuItem key={listIndex}>
              <StyledMobileLinkMenuItem to={item.to} paddingLeft={10}>
                <ListItemText primary={item.text} />
              </StyledMobileLinkMenuItem>
            </StyledMobileMenuItem>
          ))}
        </React.Fragment>
      );
    } else if (type === 'profile') {
      return <MobileProfileLinks key={index} isAdminLoggedIn={isAdminLoggedIn} />;
    }
    return null;
  });

const Links = ({ links, isAdminLoggedIn, isDrawerOpen, toggleDrawer, isActiveAuction }) => {
  const isLoggedIn = useSelector(state => !!state.user?.user?.role);
  return (
    <React.Fragment>
      <StyledMenu>
        {isLoggedIn && (
          <StyledLinkMenuItem exact to={'/new-features'}>
            NEW FEATURES
          </StyledLinkMenuItem>
        )}
        {isActiveAuction && (
          <AuctionButton to="/live-auction">
            <span className="pulse" />
            Live Auction
          </AuctionButton>
        )}
        {renderLinks(links, isAdminLoggedIn)}
      </StyledMenu>
      <IconButton className="menu-burger" onClick={toggleDrawer}>
        <i className="material-icons">menu</i>
      </IconButton>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <div tabIndex={0} role="button" className="drawer-menu" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <MenuList>
            {isLoggedIn && (
              <StyledMobileMenuItem>
                <StyledMobileLinkMenuItem to={'/new-features'}>
                  <ListItemText primary={'New Features'} />
                </StyledMobileLinkMenuItem>
              </StyledMobileMenuItem>
            )}
            {isActiveAuction && (
              <StyledMobileMenuItem>
                <StyledMobileLinkMenuItem to="/live-auction">
                  <ListItemText primary="Live Auction" />
                </StyledMobileLinkMenuItem>
              </StyledMobileMenuItem>
            )}
            {renderMobileLinks(links, isAdminLoggedIn)}
          </MenuList>
        </div>
      </Drawer>
    </React.Fragment>
  );
};
export default Links;
