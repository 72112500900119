import {
  STATISTICS_SUCCESS,
  STATISTICS_REQUEST,
  STATISTICS_FAILURE,
  FILTER_STATISTICS_REQUEST,
  FILTER_STATISTICS_SUCCESS,
  STATISTICS_SET_PAGE
} from '../actions/statistics';
import { STATISTICS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  itemsData: {
    rows: [],
    count: 0
  },
  page: 1,
  perPage: STATISTICS_PER_PAGE
};

const statistics = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STATISTICS_SET_PAGE:
      return { ...state, page: payload };
    case STATISTICS_REQUEST:
      return { ...state, loading: true };
    case STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: [...state.itemsData.rows, ...payload.data.rows]
        }
      };
    case STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    case FILTER_STATISTICS_REQUEST:
      return { ...state, loading: true };
    case FILTER_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    default:
      return state;
  }
};

export default statistics;
