import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { Vehicle } from '../../types/vehicle';
import { WS_VEHICLE_DETAILS_UPDATE, WS_NEW_PROXY, WS_NEW_BID, WS_MY_BID_UPDATE, WS_VEHICLE_TIME_END, WS_VEHICLE_NOTES_UPDATE } from '../../constants/actionTypes';
import { PatchCollection, Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';

type SocketMessage = {
  type: string;
  payload: any;
};

type UpdateCachedDataFN = (
  updateRecipe: Recipe<{
    rows: EntityState<Vehicle>;
    count: number;
  }>
) => PatchCollection;

export const registerCommonEventHandlers = (
  adapter: EntityAdapter<Vehicle>,
  updateCachedData: UpdateCachedDataFN,
  { payload, type }: SocketMessage
) => {
  if (type === WS_NEW_BID) {
    updateCachedData(draft => {
      adapter.updateOne(draft.rows, {
        id: payload.vehicle_id,
        changes: {
          highest_bid: payload
        }
      });
    });
  }
  if (type === WS_NEW_PROXY) {
    updateCachedData(draft => {
      adapter.updateOne(draft.rows, {
        id: payload.vehicle_id,
        changes: {
          proxy_bid: payload
        }
      });
    });
  }
  if (type === WS_VEHICLE_DETAILS_UPDATE) {
    updateCachedData(draft => {
      adapter.updateOne(draft.rows, { id: payload.id, changes: payload });
    });
  }
};

export class VehicleSocketEventHandlerManager {
  constructor(
    private updateCachedData: UpdateCachedDataFN,
    private adapter: EntityAdapter<Vehicle>,
    private socketMessage: SocketMessage
  ) {}

  private handleCommonEvents() {
    const { type, payload } = this.socketMessage;
    if (type === WS_NEW_BID) {
      this.updateCachedData(draft => {
        this.adapter.updateOne(draft.rows, {
          id: payload.vehicle_id,
          changes: {
            highest_bid: payload
          }
        });
      });
    }

    if (type === WS_NEW_PROXY) {
      this.updateCachedData(draft => {
        this.adapter.updateOne(draft.rows, {
          id: payload.vehicle_id,
          changes: {
            proxy_bid: payload
          }
        });
      });
    }

    if (type === WS_VEHICLE_DETAILS_UPDATE) {
      this.updateCachedData(draft => {
        this.adapter.updateOne(draft.rows, { id: payload.id, changes: payload });
      });
    }

    if (type === WS_MY_BID_UPDATE) {
      this.updateCachedData(draft => {
        this.adapter.updateOne(draft.rows, {
          id: payload.vehicle_id,
          changes: {
            my_bid: payload
          }
        });
      });
    }

    if (type === WS_VEHICLE_TIME_END) {
      this.updateCachedData(draft => {
        const exists = this.adapter.getSelectors().selectById(draft.rows, payload.id);
        if (exists) {
          this.adapter.removeOne(draft.rows, payload.id);
          draft.count -= 1;
        }
      });
    }

    if (type === WS_VEHICLE_NOTES_UPDATE) {
      this.updateCachedData(draft => {
        this.adapter.updateOne(draft.rows, {
          id: payload.vehicleId,
          changes: { notes: payload.notes }
        });
      });
    }
  }

  // handlers - WS_BID_NEW, WS_PROXY_NEW and so on
  public registerHandlers(handlers: string[]) {
    const { type } = this.socketMessage;
    if (!handlers.includes(type)) return;

    this.handleCommonEvents();
  }
}
