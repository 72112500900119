import { createSelector } from 'reselect';
import moment from 'moment';
import { VEHICLE_STATUSES } from '../constants';

export const getSellersInvoices = state => state.financials.invoices.rows;
export const getSellersInvoicesCount = state => state.financials.invoices.count;

const calculateVehiclesUploadedLastMonth = vehicles => vehicles.filter(vehicle => {
  const dateOfCreation = moment.utc(vehicle.created_at).local();
  const currentDay = moment().local();
  const startOfPrevMonth = dateOfCreation.isAfter(
    currentDay
      .clone()
      .subtract(1, 'month')
      .startOf('month')
  );
  const endOfPrevMonth = dateOfCreation.isBefore(
    currentDay
      .clone()
      .subtract(1, 'month')
      .endOf('month')
  );
  return startOfPrevMonth && endOfPrevMonth;
}).length;


const calculateVehiclesUploadedThisMonth = vehicles => vehicles.filter(vehicle => {
  const dateOfCreation = moment.utc(vehicle.created_at).local();
  const currentDay = moment().local();
  const startOfThisMonth = dateOfCreation.isAfter(
    currentDay.startOf('month')
  );
  const endOfThisMonth = dateOfCreation.isBefore(currentDay.endOf('month'));
  return startOfThisMonth && endOfThisMonth;
}).length;


const calculateVehiclesUploadedThisYear = vehicles => vehicles.filter(vehicle => {
  const dateOfCreation = moment.utc(vehicle.created_at).local();
  const currentDay = moment().local();
  const startOfThisYear = dateOfCreation.isAfter(
    currentDay.startOf('year')
  );
  const endOfThisYear = dateOfCreation.isBefore(currentDay.endOf('year'));
  return startOfThisYear && endOfThisYear;
}).length;

const calculateVehiclesSoldThisMonth = vehicles => vehicles.filter(vehicle => {
  const isVehicleAwarded = vehicle.status === VEHICLE_STATUSES.AWARDED;
  const dateOfAward = moment.utc(vehicle.date_status).local();
  const currentDay = moment().local();
  const startOfThisMonth = dateOfAward.isAfter(currentDay.startOf('month'));
  const endOfThisMonth = dateOfAward.isBefore(currentDay.endOf('month'));
  return isVehicleAwarded && startOfThisMonth && endOfThisMonth;
}).length;

const calculateVehiclesSoldLastMonth = vehicles => vehicles.filter(vehicle => {
  const isVehicleAwarded = vehicle.status === VEHICLE_STATUSES.AWARDED;
  const dateOfAward = moment.utc(vehicle.date_status).local();
  const currentDay = moment().local();
  const startOfPrevMonth = dateOfAward.isAfter(
    currentDay
      .clone()
      .subtract(1, 'month')
      .startOf('month')
  );
  const endOfPrevMonth = dateOfAward.isBefore(
    currentDay
      .clone()
      .subtract(1, 'month')
      .endOf('month')
  );
  return isVehicleAwarded && startOfPrevMonth && endOfPrevMonth;
}).length;

const calculateVehiclesSoldThisYear = vehicles => vehicles.filter(vehicle => {
  const isVehicleAwarded = vehicle.status === VEHICLE_STATUSES.AWARDED;
  const dateOfAward = moment.utc(vehicle.date_status).local();
  const currentDay = moment().local();
  const startOfThisYear = dateOfAward.isAfter(currentDay.startOf('year'));
  const endOfThisYear = dateOfAward.isBefore(currentDay.endOf('year'));
  return isVehicleAwarded && startOfThisYear && endOfThisYear;
}).length;

export const getSellersInvoicesStatistics = createSelector(
  [getSellersInvoices],
  invoices => invoices.map(({ vehicles, ...invoice }) => ({
    ...invoice,
    vehicles_this_year: `${calculateVehiclesUploadedThisYear(vehicles)}/${calculateVehiclesSoldThisYear(vehicles)}`,
    vehicles_last_month: `${calculateVehiclesUploadedLastMonth(vehicles)}/${calculateVehiclesSoldLastMonth(vehicles)}`,
    vehicles_this_month: `${calculateVehiclesUploadedThisMonth(vehicles)}/${calculateVehiclesSoldThisMonth(vehicles)}`
  })
));
