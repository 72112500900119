import styled from 'styled-components';
import moment from 'moment';
import { Button, Icon } from '@material-ui/core';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { composeValidators, divisibleBy, minValue } from '../../utils/validators';
import { required } from 'utils/validators';
import { FormFormattedField, FormTextField } from '../Form';
import Select from 'react-select';
import {
  useGetMinBuyerOfferAmountQuery,
  useGetVehicleByOrderIndexQuery,
  useLazyGetVehicleByOrderIndexQuery,
  useMakeOfferForBuyerMutation
} from '../../services/api/admin-auction-vehicles';
import { normalizeFormattedField } from '../../utils';

type Props = {
  auctionId: number;
  closeModal: () => void;
};

const PageWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleComponent = styled.div<{ isPlaceholder: boolean }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 8px 0;
  font-size: 16px;
  color: ${props => (props.isPlaceholder ? 'rgba(0, 0, 0, 0.52)' : 'rgba(0, 0, 0, 0.87)')};
`;

const HeaderText = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 500;
`;

const IconsWrapper = styled.div`
  cursor: pointer;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  height: 100%;
`;

const ErrorMessage = styled.span`
  color: #f44336;
  margin-top: 8px;
  font-size: 12px;
`;

const StyledSelect = styled(Select)`
  & .make-offer-for-buyer-select__control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .make-offer-for-buyer-select__control:hover {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .make-offer-for-buyer-select__control--is-focused {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    box-shadow: none;
  }

  & .make-offer-for-buyer-select__control {
    min-height: unset;
  }

  & .make-offer-for-buyer-select__control--is-focused {
    border-bottom: 2px solid rgb(0, 62, 105);
  }

  & .make-offer-for-buyer-select__value-container {
    padding: 0px;
  }

  & .make-offer-for-buyer-select__menu-list {
    padding: 4px 4px 4px 0;
  }

  & .make-offer-for-buyer-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .make-offer-for-buyer-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }

  & .make-offer-for-buyer-select__control--is-disabled {
    background-color: #fff;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: auto;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

const PatchedFormTextField = FormTextField as any;

const customSearch = (option: { value: string; label: string }, searchText: string) => {
  return option?.label && option.label.toLowerCase().includes(searchText.toLowerCase());
};

const transformOptions = (options: Array<{ user_id: number; dealership_name: string; is_bidder: boolean }>) => {
  return options.map(({ user_id, dealership_name, is_bidder }) => ({
    value: user_id,
    label: is_bidder ? `Bidder: ${dealership_name}` : dealership_name
  }));
};

export const AdminMakeOfferModal = ({ auctionId, closeModal }: Props) => {
  const [makeOffer] = useMakeOfferForBuyerMutation();
  const [fetchVehicleId] = useLazyGetVehicleByOrderIndexQuery();

  const onSubmit = async (v: any) => {
    const { data } = await fetchVehicleId({ auctionId, orderIndex: v.orderIndex });
    makeOffer({ amount: v.amount, buyerId: v.buyer?.value, vehicleId: data?.vehicleId ?? 0 });
    closeModal();
  };

  return (
    <PageWrapper>
      <HeaderWrapper>
        <HeaderRowWrapper>
          <HeaderText>Make offer Instead of Buyer</HeaderText>
          <IconsWrapper>
            <Icon onClick={closeModal}>close</Icon>
          </IconsWrapper>
        </HeaderRowWrapper>
      </HeaderWrapper>
      <Form onSubmit={onSubmit} destroyOnUnregister>
        {props => <MakeOfferForm auctionId={auctionId} closeModal={closeModal} {...props} />}
      </Form>
    </PageWrapper>
  );
};

const MakeOfferForm = ({
  auctionId,
  closeModal,
  values,
  ...props
}: {
  auctionId: number;
  closeModal: () => void;
} & FormRenderProps<any, Partial<any>>) => {
  console.log('component refetched 123');
  const { data } = useGetVehicleByOrderIndexQuery({ auctionId, orderIndex: values.orderIndex });

  const { data: minOfferAmount } = useGetMinBuyerOfferAmountQuery(
    {
      vehicleId: data?.vehicleId || 0,
      buyerId: values.buyer?.value
    },
    {
      skip: !data?.vehicleId || !values.buyer?.value
    }
  );

  const minValueValidator = minValue(minOfferAmount?.offerAllowed ? minOfferAmount.amount : 0);
  const divisibleBy100Validator = divisibleBy(100);

  const [fetchVehicleId] = useLazyGetVehicleByOrderIndexQuery({});

  const hasVehicleValidator = async (value: any) => {
    if (data && data.vehicleId) return;
    const { data: vehicle } = await fetchVehicleId({ auctionId, orderIndex: value });
    if (!vehicle?.vehicleId) return 'Could not find vehicle with given id';
  };

  const offerAllowedValidator = () => {
    if (!minOfferAmount) return;
    if (!minOfferAmount.offerAllowed) {
      return minOfferAmount.disallowedReason;
    }
  };

  return (
    <StyledForm name={`make-offer-${auctionId}`} onSubmit={props.handleSubmit} style={{ maxWidth: '100%' }}>
      <Field name="orderIndex" validate={composeValidators(required, hasVehicleValidator)}>
        {fieldProps => (
          <PatchedFormTextField
            label={'Vehicle ID'}
            fullWidth
            name={fieldProps.input.name}
            value={fieldProps.input.value}
            onChange={(e: any) => {
              props.form.change('buyer', undefined);
              props.form.change('amount', undefined);
              props.form.resetFieldState('amount');
              fieldProps.input.onChange(e);
            }}
            {...fieldProps}
          />
        )}
      </Field>
      <TitleComponent isPlaceholder={!data?.title}>{data?.title || 'Vehicle title'}</TitleComponent>
      <Field name="buyer" validate={required}>
        {fieldProps => (
          <div style={{ marginTop: '8px' }}>
            <StyledSelect
              isDisabled={!values.orderIndex || !data?.vehicleId}
              isClearable
              classNamePrefix={'make-offer-for-buyer-select'}
              name={fieldProps.input.name}
              value={fieldProps.input.value}
              label={'Offer amount'}
              onChange={(e: any) => {
                props.form.change('amount', undefined);
                props.form.resetFieldState('amount');
                console.log(e, 'on change event');
                fieldProps.input.onChange(e);
              }}
              onBluer={fieldProps.input.onBlur}
              options={transformOptions(data?.buyers ?? [])}
              placeholder="Select Buyer..."
              filterOption={customSearch}
            />
            {fieldProps.meta.error && fieldProps.meta.touched && <ErrorMessage>{fieldProps.meta.error}</ErrorMessage>}
          </div>
        )}
      </Field>
      <Field
        name="amount"
        disabled={!values.orderIndex || !values.buyer}
        label={'Make offer'}
        noMargin
        placeholder="-"
        component={FormFormattedField}
        prefix="$"
        parse={normalizeFormattedField}
        validate={composeValidators(required, divisibleBy100Validator, minValueValidator, offerAllowedValidator)}
        fullWidth
      />
      <ButtonWrapper>
        <StyledButton variant="contained" color="secondary" type="button" onClick={closeModal}>
          Cancel
        </StyledButton>
        <StyledButton variant="contained" color="primary" type="submit">
          Place Offer
        </StyledButton>
      </ButtonWrapper>
    </StyledForm>
  );
};
