import { ClickAwayListener, Popover, Tooltip, withStyles } from '@material-ui/core';
import { UserHoverInfo } from './HoverInfo';
import { useRef } from 'react';
import CustomTooltip from 'components/Tooltip';
import styled from 'styled-components';

const PopoverContentWrapper = styled.div`
  width: 400px;
  max-width: 100vw;
  background-color: #ffffff;
  color: #616161;
`;

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1,
    width: 400,
    maxWidth: '100vw',
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  popper: {
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    top: '20px !important'
  }
});

export const HoverInfoTooltip = withStyles(styles as any)(
  ({
    userId,
    children,
    tooltipName,
    isPopoverOpened,
    closeTooltip,
    initialPage,
    anchorEl,
    showTooltip,
    maxWidth = null
  }: any) => {
    return (
      <>
        <Popover
          open={isPopoverOpened}
          onClose={closeTooltip}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          // classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        >
          <PopoverContentWrapper>
            <ClickAwayListener onClickAway={closeTooltip}>
              <div onMouseLeave={closeTooltip}>
                <UserHoverInfo customerId={userId} initialPage={initialPage} />
              </div>
            </ClickAwayListener>
          </PopoverContentWrapper>
        </Popover>
        <CustomTooltip arrow light isTextOverflown={showTooltip} maxWidth={maxWidth} title={tooltipName}>
          {children}
        </CustomTooltip>
      </>
    );
  }
);
