import React, { Component } from 'react';
import { Header } from '../components';
import { PageWrapper } from '../../../../components';
import AccountInformation from './AccountInformation';
import AboutYourCompany from './AboutYourCompany';
import PurchaseOptions from './PurchaseOptions';

const tabs = [
  { name: 'account_information', title: 'Account Information' },
  { name: 'about_your_company', title: 'About Your Company' },
  { name: 'purchase_options', title: 'Purchase Options' }
];

const tabsPages = {
  account_information: (props = {}) => <AccountInformation {...props} />,
  about_your_company: (props = {}) => <AboutYourCompany {...props} />,
  purchase_options: (props = {}) => <PurchaseOptions {...props} />
};

class SellerProfile extends Component {
  constructor(props) {
    super(props);
    const { tab } = this.props;

    this.state = {
      currentTab: tab || 'account_information'
    };
  }

  componentWillReceiveProps(newProps) {
    const { currentTab } = this.state;
    if (currentTab !== newProps.tab) {
      this.setState({
        currentTab: newProps.tab || 'account_information'
      });
    }
  }

  toggleTab = tab => {
    const { onTabChange } = this.props;
    this.setState({ currentTab: tab });
    onTabChange(tab);
  };

  renderPage = () => {
    const { currentTab } = this.state;
    const { user } = this.props;
    const tabToRender = tabsPages[currentTab] || tabsPages.account_information;
    return tabToRender({ user });
  };

  render() {
    const { currentTab } = this.state;
    return (
      <>
        <Header currentTab={currentTab} tabs={tabs} onClick={this.toggleTab} />
        <PageWrapper>{this.renderPage()}</PageWrapper>
      </>
    );
  }
}

export default SellerProfile;
