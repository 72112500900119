import { useGetVehicleByIdQuery } from '../../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../../../CreateVehicle/hooks/useGetVehicleId';
import ImageGallery from 'react-image-gallery';
import './index.scss';
import styled from 'styled-components';
import { RenderLeftNav, RenderRightNav } from './components/nav';
import Slider from 'react-slick';
import { useRef } from 'react';
import { PlayImageIcon } from '../../icons/PlayImageIcon';
import { FullscreenButton } from './components/fullscreen';
import { FullScreenCarouselDialog } from './components/FullScreenCarousel';
import {
  openFullScreenWithId,
  openFullScreenWithIndex,
  useImages
} from '../../../../../services/viewVehicleSlice/viewVehicleSlice';
import { useDispatch } from 'react-redux';
import ReactImageGallery from 'react-image-gallery';

const Wrapper = styled.div`
  width: 560px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 16px;
`;

const SliderImage = styled.div<{ showNextButton?: boolean }>`
  border-radius: 30px;
  position: relative;
  width: 134px !important;
  height: 133px !important;

  & > img {
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 30px;
    width: 100% !important;
    height: 100% !important;
    opacity: ${props => (props.showNextButton ? '0.6' : '1')};
  }
`;

const PlayImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SliderWrapper = styled.div`
  & * .slick-list {
  }
  & * .slick-slide > div {
  }
`;

const getFillSliderImages = (length: number) => {
  const remainder = length % 4;
  if (remainder === 0) return [];
  return Array(4 - remainder).fill(0);
};

type Props = {
  imageGalleryRef: React.RefObject<ReactImageGallery>;
};

export const ImageCarousel = ({ imageGalleryRef }: Props) => {
  const vehicleId = useGetVehicleId();
  const { data: vehicle } = useGetVehicleByIdQuery(vehicleId, { skip: !vehicleId });

  const sliderRef = useRef<Slider>(null);

  const dispatch = useDispatch();

  const images = useImages();

  const openFullScreen = (i: number) => {
    dispatch(openFullScreenWithIndex(i));
  };

  if (!vehicle) return null;

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
  };

  const handleSlickSlide = (i: number) => {
    if (!sliderRef.current) return;
    sliderRef.current.slickNext();
  };

  const fillArray = getFillSliderImages(images.length);

  return (
    <Wrapper>
      {images.length > 0 && (
        <ImageGallery
          ref={imageGalleryRef}
          showPlayButton={false}
          showFullscreenButton={false}
          renderLeftNav={(onclick, disabled) => <RenderLeftNav onClick={onclick} disabled={disabled} />}
          renderRightNav={(onclick, disabled) => <RenderRightNav onClick={onclick} disabled={disabled} />}
          items={images.map(i => ({ original: i.url, thumbnail: i.url_thumb, description: i.notes || '' }))}
          showIndex
          useBrowserFullscreen={false}
          showThumbnails={false}
          infinite={true}
        />
      )}
      <SliderWrapper>
        <Slider {...sliderSettings} ref={sliderRef}>
          {images.map((img, i) => {
            const showNextButton = (i + 1) % 4 === 0 && i !== images.length - 1;
            const handleClick = () => {
              if (showNextButton) {
                return;
              }

              dispatch(openFullScreenWithId(img.id));
            };
            return (
              <SliderImage onClick={handleClick} showNextButton={showNextButton} key={img.id}>
                <img src={img.url} alt={img.id.toString()} />
                {showNextButton && (
                  <PlayImageWrapper onClick={() => handleSlickSlide(i)}>
                    <PlayImageIcon />
                  </PlayImageWrapper>
                )}
              </SliderImage>
            );
          })}
          {fillArray.map(() => (
            <SliderImage />
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};
