import { useDispatch } from 'react-redux';
import {
  UpdateImagePayload,
  useUpdateSectionImageMutation,
} from '../../../services/api/upload-vehicle';
import { useField } from 'react-final-form';
import { useGetVehicleId } from './useGetVehicleId';
import { ImageField } from '../types';
import { snackShow } from '../../../actions';

export const useHandleImageChange = (name: string) => {
  const dispatch = useDispatch();

  const vehicleId = useGetVehicleId();

  const { input } = useField<ImageField>(name);

  const [updateImage, { isLoading }] = useUpdateSectionImageMutation();

  const handleImageChange = async (file?: File) => {
    if (!file) return null;

    const payload: UpdateImagePayload = {
      id: input.value.id,
      file,
      vehicleId
    };

    const response = await updateImage(payload);
    if ('data' in response) {
      input.onChange(response.data.data);
    } else {
      dispatch(
        snackShow({
          message: (response.error as any)?.data?.data?.message || 'Uploading image failed',
          type: 'error'
        })
      );
    }

    return null;
  };

  return { handleImageChange, imageLoading: isLoading };
};
