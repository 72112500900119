import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type OfferLogState = {
  filters: Record<string, any>;
  page: number;
  perPage: number;
}

const initialState: OfferLogState = {
  filters: {},
  page: 1,
  perPage: 25
};

const offerLogSlice = createSlice({
  name: 'offerLog',
  initialState,
  reducers: {
      setFilters(state, action: PayloadAction<Record<string, any>>) {
        state.filters = action.payload
        state.page = 1
      },
      setPage(state, action: PayloadAction<number>) {
        state.page = action.payload;
      },
      setFilterByName(state, action: PayloadAction<[string, any]>) {
        const [key, value] = action.payload;
        if (value === '') { 
          delete state.filters[key]
        } else {
          state.filters[key] = value
        }
        state.page = 1
      },
      resetFilters(state) {
        state.filters = {
        }
        state.page = 1
      }
    }
});

export const { setPage, setFilterByName, setFilters, resetFilters } = offerLogSlice.actions
export default offerLogSlice;
