import React, { Component } from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography, Divider } from '@material-ui/core';

import { PageWrapper } from '../../../components';
import ContactFrom from './components/ContactForm';

import phoneIcon from '../../../img/Phone.png';
import houseIcon from '../../../img/House.png';

const StyledSection = styled(Paper)`
  && {
    max-width: 856px;
    border-radius: 0;
    margin: 24px auto 0;
    padding: 32px 110px 48px 110px;
    @media (max-width: 960px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 32px;
    margin: 40px 0;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
`;

const StyledDivider = styled(Divider)`
  && {
    width: calc(100% + 48px);
    margin: 48px 0 48px -24px;
    @media (max-width: 960px) {
      width: 100%;
      margin: 20px 0;
    }
  }
`;

const TypographyWithMarginBot = styled(Typography)`
  && {
    margin-bottom: 1em;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 10px;
  left: -33px;
  @media (max-width: 960px) {
    left: 0;
  }
  @media (max-width: 600px) {
    left: 5px;
  }
`;

const StyledGrid = styled(Grid)`
  &&& {
    @media (max-width: 960px) {
      padding-left: 40px;
    }
    @media (max-width: 600px) {
      padding-left: 45px;
    }
  }
`;

class ContactsPage extends Component {
  componentDidMount() {}

  render() {
    return (
      <PageWrapper>
        <StyledSection>
          <StyledTitle variant="title">Contact AutoAxess</StyledTitle>
          <Grid container spacing={32}>
            <StyledGrid item xs={12} sm={6} style={{ position: 'relative' }}>
              <StyledImg src={phoneIcon} alt="shield" />
              <TypographyWithMarginBot variant="display1">
                Phone Number
              </TypographyWithMarginBot>
              <Typography>(925)315-4033</Typography>
            </StyledGrid>
            <StyledGrid item xs={12} sm={6} style={{ position: 'relative' }}>
              <StyledImg src={houseIcon} alt="shield" />
              <TypographyWithMarginBot variant="display1">
                Address
              </TypographyWithMarginBot>
              <Typography>AutoAxess Inc</Typography>
              <Typography>486 S. K Street</Typography>
              <Typography>Livermore, CA 94550</Typography>
            </StyledGrid>
            <StyledDivider />
            <Grid item xs={12}>
              <Typography>
                Thank you for contacting us. If you’d like to send us a message,
                please choose from one of the drop down options below, and
                someone from our team will return your message within 24 hours.
                If this is regarding a specific vehicle, please make sure to
                include the VIN number. We are eager to provide you with
                exceptional service!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ContactFrom />
            </Grid>
          </Grid>
        </StyledSection>
      </PageWrapper>
    );
  }
}

export default ContactsPage;
