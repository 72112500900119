export const LOGS_COUNT_SUCCESS = 'LOGS_COUNT_SUCCESS';
export const LOGS_COUNT_REQUEST = 'LOGS_COUNT_REQUEST';
export const LOGS_COUNT_FAILURE = 'LOGS_COUNT_FAILURE';
export const LOGS_SET_PAGE = 'LOGS_SET_PAGE';
export const LOGS_REQUEST = 'LOGS_REQUEST';
export const LOGS_SUCCESS = 'LOGS_SUCCESS';
export const LOGS_FAILURE = 'LOGS_FAILURE';

export const FILTER_LOGS_REQUEST = 'FILTER_LOGS_REQUEST';
export const FILTER_LOGS_SUCCESS = 'FILTER_LOGS_SUCCESS';
export const FILTER_LOGS_FAILURE = 'FILTER_LOGS_FAILURE';

export const fetchLogs = data => ({
  request: { type: LOGS_REQUEST, payload: data },
  success: { type: LOGS_SUCCESS, payload: data },
  failure: { type: LOGS_FAILURE, payload: data }
});

export const filterLogs = data => ({
  request: { type: FILTER_LOGS_REQUEST, payload: data },
  success: { type: FILTER_LOGS_SUCCESS, payload: data },
  failure: { type: FILTER_LOGS_FAILURE, payload: data }
});

export const logsCount = data => ({
  request: { type: LOGS_COUNT_REQUEST, payload: data },
  success: { type: LOGS_COUNT_SUCCESS, payload: data },
  failure: { type: LOGS_COUNT_FAILURE, payload: data }
});

export const logsSetPage = data => ({
  type: LOGS_SET_PAGE,
  payload: data
});
