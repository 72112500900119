import React, { Component, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typography, ButtonBase, Icon, Popover, Grid } from '@material-ui/core';
import { getSliderTextValue, getWindShieldTextValue } from '../../../../utils';
import { useIsTextOverflown } from '../../../../hooks';
import Tooltip from '../../../../components/Tooltip';

const StyledVehicleTitle = styled(Typography)`
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  && {
    font-weight: 500;
  }
`;

const StyledVehicleDescription = styled(Typography)`
  && {
    color: #757575;
  }
`;

const StyledBackButton = styled(ButtonBase)`
  && {
    margin-left: 10px;
    span {
      font-size: 24px;
    }
  }
`;

const StyledGridContainer = styled(Grid)`
  && {
    max-width: 400px;
    padding: 6px;
  }
`;

const StyledGridItemLabel = styled(Grid)`
  && {
    color: #616161;
    padding: 4px 8px;
  }
`;

const StyledGridItem = styled(Grid)`
  && {
    color: #757575;
    padding: 4px 8px;
  }
`;

const VehiclePopover = ({ vehicleData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const titleRef = useRef();
  const isTextOverflown = useIsTextOverflown();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const vehicleTitle = `${vehicleData.year} ${vehicleData.make} ${vehicleData.model}`;
  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip title={vehicleTitle} isTextOverflown={isTextOverflown}>
        <StyledVehicleTitle ref={titleRef}>{vehicleTitle}</StyledVehicleTitle>
      </Tooltip>

      <StyledVehicleDescription>
        {`${vehicleData.vin}`}
        <StyledBackButton onClick={handleClick}>
          <Icon>expand_more</Icon>
        </StyledBackButton>
      </StyledVehicleDescription>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <StyledGridContainer container>
          <StyledGridItemLabel item xs={6}>
            Mileage:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.mileage}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.engine}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Transmission:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.transmission}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Check Engine Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.check_engine_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            ABS Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.abs_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Airbag Light:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.airbag_light ? 'on' : 'off'}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Windshield:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getWindShieldTextValue(vehicleData.windshield)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Tires:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.tiers)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Paint:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.paint)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Interior:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.interior)}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Air Conditioning Blows:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.air_conditioning_blows, ['Cold', 'Cool', 'Warm', '', 'N/A'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine Oil:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.engine_oil, ['Very Clean', 'Clean', 'Dirty', 'Milky'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Engine Coolant:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {getSliderTextValue(vehicleData.engine_coolant, ['Very Clean', 'Clean', 'Dirty', 'Milky'])}
          </StyledGridItem>
          <StyledGridItemLabel item xs={6}>
            Dealer Comments:
          </StyledGridItemLabel>
          <StyledGridItem item xs={6}>
            {vehicleData.dealer_comments}
          </StyledGridItem>
        </StyledGridContainer>
      </Popover>
    </div>
  );
};

export default VehiclePopover;
