import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageWrapper } from '../../../components';

import VideoPoster from '../../../video/poster.png';

const welcomeVideoSrc =
  'https://ffe40fe2e189c787e62485df8568170b7e02fbd5654e8254c0675bd-apidata.googleusercontent.com/download/storage/v1/b/autoaxess-web-static/o/videos%2FAutoAxess_540.mp4?jk=ASOpP9iBkAmp6FzIvK3uywlDC89RePPBlWwxxm0Qn87WrV1Bx-4VsGmLPgcw-NYTCuYcby_VpsPEAsxfFj0s_UUWWvVnE4cpo4aKa9Mk2Sckj-T5FmX9wqq6o5amM90YkJuqwFrNlYSZkaEvqkndELWh6Jg__dETHI68wN_SLIW7dWjCoggIT7lp5FDWZP4tl4Dy-OzDFXr3rwlcp9ZF0-62uMCFj2e-VC1crHdjxCwED7Dp6Udv4PI2y16ytdF5fykzwhrYjh-HJtH0s9pCGyMtY2Hftag3DCa4PIDbHT-i3wTgFSALE-G5QRPTBmrUowEbFkEru-NXLvUAls0ABpjgUuTu0NWn4dtEIHL-fStKqzBVUKLGehyBNLnEl1DkJimXkQ9RxiALnGTv5fOn1-8ZT4cl-5roLCXyr-KyiCoJYfdrRXGOTT-kLVuMNtt63_bqWLpDTTRDU8S69N_TVIyzikLux1fOERQugA_sjYCU-BV2P4Sj9ILcPUR1vmPSSvwtiTpSR_5_cPUjhq9LTf6-nkYsG3KSC-T7N9E7tykMresr_q3eN2DYxWIxlqsfa1HC0B1Zj7j3zJkrf0d3QEGveqy8c5aPsJ2AySnRR66QICsjQMlFvSRHFAtJE6SCuUFseNgj7KtqOrJiSiCAToKaoEYBiWoYMocCK3pot0m2CmFhAxEAZeNe5UTBagRvTtgeCTWZcc0pIHrsvzvocZHNFD5V4sGG_6n_ss3yWPPGxInxoAhyQshnF-g_wkUxHpEQiDMa-HU53EmLgki3PiP3E0T_ooH8i8rOf8T2GLW7YGqC-p1JUvD7NqLPEEWQSSdeglpvAeBuE9TgBFRXOeQRNRoJeAlA0t3r2lHcLVutnnVNeTnnfR590C1KEB3C9mNcsLNaYrTaFLidlAqszPNjXhCNVJ1JhSsSlZv0ovBtdcPI83Yb7gwpA4iCmygGGrVAPFIdMJ6JMllDjhIM23atYKOfGQTd-VALO6ngFbgpvuPbye8BuM_RQ7fZPVXyStM12CVMcaJPKhCqxGSs3zyMgAkkbP2HOlAuxq45b2WwK3p7SbiapiHxchoQID_L2jtAQFzw3HGbCa9oR793krHgQC4ydjYK-TUqC2zRhEaVsa81xxZp9rb0Ugt99jS_hURn2uczETVMYuI&isca=1';

const IntroSectionHolder = styled.section`
  padding: 40px 0;

  .button-primary {
    &.left {
      display: none;
    }

    &.right {
      margin-top: 40px;
    }
  }

  @media only screen and (min-width: 1026px) {
    padding: 112px 40px 126px;

    .button-primary {
      &.left {
        display: block;
      }

      &.right {
        display: none;
      }
    }
  }
`;

const StyledHeading = styled.h1`
  margin: 0 0 10px;
  color: #424141;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;

  @media only screen and (min-width: 1026px) {
    margin: 0 0 23px;
    font-size: 36px;
    line-height: 42px;
  }
`;

const StyledDescription = styled.p`
  margin-bottom: 22px;
  color: #54607d;
  font-size: 14px;
  line-height: 24px;

  span {
    display: block;
  }

  @media only screen and (min-width: 1026px) {
    margin-bottom: 48px;
    font-size: 18px;
  }
`;

const VideoHolder = styled.div`
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);

  video {
    display: block;
  }

  @media only screen and (min-width: 1026px) {
    margin-left: 70px;
  }
`;

const IntroSection = () => (
  <PageWrapper>
    <IntroSectionHolder>
      <Grid container>
        <Grid item xs={12} md={5}>
          <StyledHeading>The online wholesale auto auction built by car guys for car guys</StyledHeading>
          <StyledDescription>
            AutoAxess offers less frustration, a wide variety of local vehicles, and the lowest fees in the market…
            <span>period.</span>
          </StyledDescription>
          <Link className="button-primary left" to="/register">
            Sign Up
          </Link>
        </Grid>
        <Grid item xs={12} md={7}>
          <VideoHolder>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video controls width="100%" poster={VideoPoster}>
              <source src={welcomeVideoSrc} type="video/mp4" />
            </video>
          </VideoHolder>
          <Link className="button-primary right" to="/register">
            Sign Up
          </Link>
        </Grid>
      </Grid>
    </IntroSectionHolder>
  </PageWrapper>
);

export default IntroSection;
