export const RetakeCameraImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
    <path
      opacity="0.8"
      d="M16.2735 2.47657C19.6738 4.18427 22 7.66322 22 11.6743C22 17.3773 17.2992 22 11.5 22C5.70077 22 1 17.3773 1 11.6743C1 7.24226 3.84308 3.46148 7.83308 2"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path opacity="0.8" d="M3 1L8 1.72977L7.14552 6" stroke="white" stroke-width="2" stroke-miterlimit="10" />
    <path
      opacity="0.8"
      d="M12 15C14.2091 15 16 13.433 16 11.5C16 9.567 14.2091 8 12 8C9.79086 8 8 9.567 8 11.5C8 13.433 9.79086 15 12 15Z"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </svg>
);
