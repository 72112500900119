/* eslint-disable no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import StatusHeader from './StatusHeader';
import VehicleNavigationHeader from './VehicleNavigationHeader';
import {
  ADDITIONAL_NAVIGATION_SECONDS,
  VEHICLE_STATUSES,
  AUCTION_STATUSES,
} from '../../../../../constants';

const BuyerRestrictedHeader = ({ currentVehicle, currentUser }) => {
  const isAuctionActive =
    currentVehicle.auction.status === AUCTION_STATUSES.ACTIVE;
  const isVehicleActive = currentVehicle.status === VEHICLE_STATUSES.ACTIVE;

  const currentTime = moment.utc();

  const isNavigationTimerEnded =
    currentVehicle.end_time_spacing !== null &&
    moment
      .utc(currentVehicle.auction.date_end)
      .add(
        currentVehicle.end_time_spacing + ADDITIONAL_NAVIGATION_SECONDS,
        'seconds'
      )
      .isBefore(currentTime);

  if (isVehicleActive && isAuctionActive && !isNavigationTimerEnded) {
    return <VehicleNavigationHeader currentVehicle={currentVehicle} />;
  }

  return <StatusHeader vehicle={currentVehicle} />;
};

export default connect(state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
}))(BuyerRestrictedHeader);
