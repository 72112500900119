/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const statusText = {
  active: 'Active',
  pending: 'Active',
  ended: 'Ended',
  'N/A': 'N/A'
};

const statusColor = {
  active: '#009F0F',
  pending: '#009F0F',
  ended: '#E47E00',
  'N/A': '#E47E00'
};

const AuctionStatus = ({ status }) => (
  <StyledStatus statusColor={statusColor[status]}>
    {statusText[status] || 'None'}
  </StyledStatus>
);

export default AuctionStatus;
