import styled from 'styled-components';
import { AAFullLogo } from '../../../components/Icons/AAFullLogo';
import logoImg from 'img/logo.svg';

const Wrapper = styled.div`
  padding: 65px 90px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const AutoaxessLogo = () => {
  return (
    <Wrapper>
      <img src={logoImg} alt="logo" />
    </Wrapper>
  );
};
