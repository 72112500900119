import { Icon } from '@material-ui/core';
import styled from 'styled-components';
import { useGetSectionConfigQuery } from '../../../../services/api/vehicle-sections';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { ExpandIcon } from '../../../../components/Icons/ExpandIcon';
import { DragIndicator } from '../../icons/DragIndicator';

type Props = {
  sectionId: keyof SectionConfig | 'dealer_comments';
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  border: 2px solid rgba(57, 148, 222, 1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  flex-grow: 1;
  font-weight: 700;
  line-height: 23.44px;
  padding: 12px 8px;
  color: #000;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const MovableSectionOverlay = ({ sectionId }: Props) => {
  const { data: config } = useGetSectionConfigQuery();

  const isDealerSection = sectionId === 'dealer_comments';

  if (!isDealerSection && (!config || !config[sectionId])) {
    return null;
  }

  const label = isDealerSection ? 'Inspector Comments' : config![sectionId].label;

  return (
    <Wrapper>
      <Header>
        <Title>{label}</Title>
        <Icon>
          <DragIndicator />
        </Icon>
        <Icon>
          <ExpandIcon />
        </Icon>
      </Header>
    </Wrapper>
  );
};
