import { Dialog, Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledDropReservePriceModal = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 520px;

  @media (max-width: 600px) { 
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  & > button {
    flex: 1;
  }
`;

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
  buttonsDisabled?: boolean;
};

export const ConfirmDropReserveModal = ({ isOpen, closeModal, handleSubmit, buttonsDisabled = false }: Props) => {
  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <StyledDropReservePriceModal>
        <h2>
          You’re dropping your reserve and the car will be sold. Buyers will see reserve is met. Any changes in the
          final 5 minutes resets the timer to 5 minutes
        </h2>
        <ButtonWrapper>
          <Button color="primary" onClick={closeModal} variant="outlined" disabled={buttonsDisabled}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit} disabled={buttonsDisabled}>
            Confirm
          </Button>
        </ButtonWrapper>
      </StyledDropReservePriceModal>
    </Dialog>
  );
};
