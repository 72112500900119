import React from 'react';
import { Field } from 'react-final-form';
import { FormControlLabel, Radio, FormLabel } from '@material-ui/core';
import styled from 'styled-components';
import InlineFormRadioGroup from './InlineFormRadioGroup';
import { required } from '../../../../utils/validators';
import { useUpdateSearchParams } from '../../../../hooks';

const StyledFieldTitle = styled(FormLabel)`
  && {
    margin-bottom: -5px;
    font-size: 12px;
    color: #616161;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  span:last-child {
    margin-left: -5px;
  }
`;

const StyledInlineFormRadioGroup = styled(InlineFormRadioGroup)`
  label:last-child {
    margin-top: -10px;
  }
`;

const RolePicker = () => {
  const updateSearchParams = useUpdateSearchParams();
  const onChangeFormField = value => {
    console.log(value, 'onChangeFromField');
    updateSearchParams({ role: value });
  };

  return (
    <>
      <StyledFieldTitle component="legend">Role</StyledFieldTitle>
      <Field name="role" validate={required}>
        {props => {
          return (
            <StyledInlineFormRadioGroup input={props.input} onChange={onChangeFormField} meta={props.meta} row>
              <StyledFormControlLabel
                value="buyer"
                control={<Radio color="primary" />}
                label="Buyer (Independent used car dealer)"
                error
              />
              <StyledFormControlLabel
                value="seller"
                control={<Radio color="primary" />}
                label="Seller (Franchised new car dealer)"
              />
            </StyledInlineFormRadioGroup>
          );
        }}
      </Field>
    </>
  );
};

export default RolePicker;
