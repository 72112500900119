import { createSelector } from 'reselect';
// import createFilterOptions from "react-select-fast-filter-options";
import { getUsers } from './users';

const createFilterOptions = () => {};
export const getModalData = state => state.auctions.auctionVehiclesModal;

export const getBidders = state => state.auctions.auctionVehicles.bidders.data;

export const getIsBiddersLoaded = state => state.auctions.auctionVehicles.bidders.success;

export const getBiddersAndUsers = createSelector([getUsers, getModalData], (users, vehicle) => {
  const uniqueBidders = vehicle.bids.reduce((acc, { bidder }) => {
    const isBidderAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
    if (isBidderAlreadyExists) return acc;
    acc.push({
      value: bidder.id,
      label: `Bidder: ${bidder.dealership_name}`
    });
    return acc;
  }, []);

  const usersWithoutBidders = users.reduce((acc, user) => {
    const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === user.id);
    if (isBidderAlreadyExists) return acc;
    acc.push({
      value: user.id,
      label: user.dealership_name
    });
    return acc;
  }, []);

  return [...uniqueBidders, ...usersWithoutBidders];
});

export const getIndexedBiddersAndUsers = createSelector([getUsers, getModalData], (users, vehicle) => {
  const uniqueBidders = vehicle.bids.reduce((acc, { bidder }) => {
    const isBidderAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
    if (isBidderAlreadyExists) return acc;
    acc.push({
      value: bidder.id,
      label: `Bidder: ${bidder.dealership_name}`
    });
    return acc;
  }, []);

  const usersWithoutBidders = users.reduce((acc, user) => {
    const isBidderAlreadyExists = uniqueBidders.find(existingBidder => existingBidder.value === user.id);
    if (isBidderAlreadyExists) return acc;
    acc.push({
      value: user.id,
      label: user.dealership_name
    });
    return acc;
  }, []);

  return createFilterOptions({
    options: [...uniqueBidders, ...usersWithoutBidders]
  });
});
