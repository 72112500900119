import React, { Component } from 'react';
import { vehicles } from '../../../utils/api';

class AutocheckReport extends Component {
  state = {
    autoCheckReport: null
  };

  componentDidMount() {
    const { match } = this.props;
    const { vin } = match.params;
    vehicles.autocheckReport(vin).then(({ data }) => {
      this.setState({
        autoCheckReport: data.data
      });
    });
  }

  render() {
    const { autoCheckReport } = this.state;
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: autoCheckReport }} />
      </>
    );
  }
}

export default AutocheckReport;
