import { TableButton } from 'components/Table/common';
import { VEHICLE_STATUSES } from 'constants';
import { PAYMENT_METHODS_STATUSES } from 'constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  min-width: 100px;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const ButtonsBlock = ({ vehicle }) => {
  const history = useHistory();
  const currentUser = useSelector(state => state?.user?.user);

  const buttons = [
    {
      title: 'View',
      color: 'secondary',
      handleClick: item => history.push(`/vehicles/${item.id}/view`),
      fullWidth: true
    },
    {
      title: 'EasyPay',
      color: 'secondary',
      conditionalRendering: item =>
        item.status === VEHICLE_STATUSES.AWARDED &&
        item.buyer_id === currentUser?.id &&
        item.can_be_easily_purchased &&
        (!item.payment_method || item.payment_status === PAYMENT_METHODS_STATUSES.DECLINED),
      handleClick: item => history.push(`/buyer/bids/${item.id}/easy-pay`),
      fullWidth: true
    }
  ];

  return (
    <Container>
      {buttons.map((btn, i) => (
        <TableButton key={`b${i}`} btn={btn} itemData={vehicle} />
      ))}
    </Container>
  );
};
