import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { FormTextField } from '../../../../components/Form';
import { setStatusFilter, setSearchFilter } from '../../../../services/mySales/salesSlice';
import { debounce } from 'utils';

const StyledFilterWrapper = styled.form`
  display: flex;
  justify-content: flex-end;
  div:first-child {
    padding-right: 16px;
    @media (max-width: 600px) {
      padding-right: 0px;
    }
  }
  div {
    @media (max-width: 600px) {
      flex-grow: 1;
    }
    div {
      min-width: 240px;
    }
  }
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    padding: 0 12px;
  }
`;

export const activeFilters = [
  {
    value: '',
    label: 'All'
  },
  {
    value: 'awarded',
    label: 'Awarded'
  },
  {
    value: 'pulled',
    label: 'Pulled'
  },
  {
    value: 'in_progress',
    label: 'Active'
  }
];

export const pendingFilters = [
  {
    value: undefined,
    label: 'All'
  },
  {
    value: 'awarded',
    label: 'Awarded'
  },
  {
    value: 'pulled',
    label: 'Pulled'
  },
  {
    value: 'counter_sent_to_buyers',
    label: 'Counter Sent to Buyer'
  },
  {
    value: 'public_counter_sent_to_buyers',
    label: 'Counter Sent to Buyers'
  },
  {
    value: 'buyer_counter_sent',
    label: 'Counter Sent to Seller'
  },
  {
    value: 'need_seller_review',
    label: 'Needs Seller Review'
  },
  {
    value: 'pending_autoaxess_review',
    label: 'Pending Autoaxess Review'
  },
  {
    value: 'pending_admin_approval+accepted_offer_pending_admin_approval',
    label: 'Pending Admin Approval'
  }
];

const Filter = () => {
  const {
    filters: { auctionStatus }
  } = useSelector(state => state.mySales);
  const dispatch = useDispatch();

  const filterBlock = auctionStatus === 'pending' ? pendingFilters : activeFilters;
  const onSelect = (_e, value) => {
    dispatch(setStatusFilter(value));
  };

  const handleSearchChange = debounce((_e, value) => { 
    dispatch(setSearchFilter(value));
  }, 500);

  return (
    <StyledFilterWrapper>
      <Field onChange={onSelect} component={FormTextField} name="status" label="Filter by Bid Status" select>
        {filterBlock.map(({ value, label }) => {
          return <MenuItem value={value}>{label}</MenuItem>;
        })}
      </Field>
      <Field
        onChange={handleSearchChange}
        component={FormTextField}
        name="search"
        label="Search"
      />
    </StyledFilterWrapper>
  );
};

export default reduxForm({
  form: 'vehicleSalesFilterForm',
  destroyOnUnmount: false
})(Filter);
