import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useScreen, useWindowSize } from 'usehooks-ts';
import { SIDE_PANEL_BREAKPOINT, VEHICLE_STATUSES } from '../../constants';
import { AUTSlider } from '..';
import { VehicleSideCardMobile } from './VehicleSideCardMobile';
import { useGetSidePanelBidsQuery } from 'services/api/my-bids';
import { setActiveVehicleId } from '../../services/sidePanel/sidePanel';
import { useScreenOrientation } from '../../hooks';
import { useDeviceSelectors, useMobileOrientation } from 'react-device-detect';

const MobileContainer = styled.div`
  border-radius: 16px 16px 0px 0px;
  background: #fff;
  padding: 16px;
  width: 100%;
  justify-content: center;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

  & .slick-list {
  }
`;

const StyledSlider = styled(AUTSlider)`
  & .slick-slide {
    padding-right: 4px;
    box-sizing: border-box;
  }
`;

export const VehicleSidePanelMobile = () => {
  const user = useSelector((state: any) => state.user?.user);
  const { data } = useGetSidePanelBidsQuery(undefined, { skip: !user || user.role !== 'buyer' });
  const activeVehicleId = useSelector((state: any) => state.sidePanel.activeVehicleId);
  const sliderRef = useRef<any>(null);

  const dispatch = useDispatch();

  const items = useMemo(
    () => data?.rows?.ids.map(id => data?.rows?.entities[id]!)?.filter(item => item.status === VEHICLE_STATUSES.ACTIVE),
    [data]
  );

  useEffect(() => {
    if (!activeVehicleId || !items) return;

    const newIndex = items.findIndex(vehicle => vehicle.id === activeVehicleId);
    sliderRef.current?.slickGoTo(newIndex > -1 ? newIndex : 0);

    dispatch(setActiveVehicleId(null));
  }, [activeVehicleId, dispatch, items]);

  const screen = useWindowSize();
  const { width = 0 } = screen ?? {};

  if (!items || items.length === 0) return null;

  const isMobile = width <= SIDE_PANEL_BREAKPOINT;
  if (!isMobile) return null;

  return (
    <MobileContainer>
      <AUTSlider
        sliderRef={sliderRef}
        settings={{
          slidesToShow: 1,
          swipeToSlide: true,
          arrows: false,
          dots: false,
          variableWidth: width > 576,
          infinite: false,
        }}
      >
        {(items ?? []).map((vehicle, i) => {
          return <VehicleSideCardMobile includeIndents={width > 576} vehicle={vehicle} key={vehicle.id} />;
        })}
      </AUTSlider>
    </MobileContainer>
  );
};
