import styled from 'styled-components';
import { Header } from '../ListerDashboard/components/Header';
import { useState } from 'react';
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import { MapPin } from '../ListerDashboard/components/MapPin';
import { useGetListerRequestsByDayQuery, useGetListerRouteDistancesQuery } from '../../../services/api/list-vehicles';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatAddress } from '../ListerDashboard/utils/formatAddress';
import { HomeMapIcon } from './icons/Home';
import { MapRoutes } from './components/MapRoutes';
import { getDayNumberFromDate } from '../ListerDashboard/utils/formatDate';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  background: #fff;
  position: relative;
  width: 100%;
`;

const containerStyle = {
  width: '100vw',
  height: 'calc(100vh - 86px - 72px - 60px)'
};

const startingPoint = {
  lat: 37.33548,
  lng: -121.893028
};

const StartNavigationButton = styled(Button)`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 92vw;
  position: absolute;
  bottom: 136px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: center;
  left: 50%;
  transform: translate(-50%);
`;

const ListerMap = () => {
  const [dayNumber, setActiveDayNumber] = useState(() => getDayNumberFromDate(moment()));

  return (
    <Wrapper>
      <Header activeDayNumber={dayNumber} setDayNumber={setActiveDayNumber} withDaySelect />
      <APIProvider libraries={['marker']} apiKey={'AIzaSyDFJbMx4Q4xrlUlsRpTVgbmU0lvzdUIjx0'}>
        <Map
          mapId={'lister-map'}
          defaultZoom={7}
          defaultCenter={startingPoint}
          gestureHandling={'greedy'}
          style={containerStyle}
        >
          {dayNumber === 8 ? (
            [0, 1, 2, 3, 4, 5, 6, 7].map(day => <DrawDay dayNumber={day} drawPolyline={false} key={day} />)
          ) : (
            <DrawDay dayNumber={dayNumber} />
          )}
        </Map>
      </APIProvider>
    </Wrapper>
  );
};

const DrawDay = ({ dayNumber, drawPolyline = true }: { dayNumber: number; drawPolyline?: boolean }) => {
  const { data } = useGetListerRequestsByDayQuery();

  const listRequests = data?.[dayNumber]?.[0]?.listRequests || [];
  const poolId = data?.[dayNumber]?.[0]?.id || -1;

  const user = useSelector((state: any) => state.user.user);

  const origin = formatAddress(user);
  const destinations = listRequests.map(item => formatAddress(item.user));

  const { data: route } = useGetListerRouteDistancesQuery(
    {
      origin,
      destinations,
      poolId
    },
    {
      skip: listRequests.length === 0 || !user || poolId === -1
    }
  );

  const handleStartNavigation = () => {
    const qs = new URLSearchParams();
    const waypoints = [...destinations];
    const finalDestination = waypoints.pop()!;

    if (!route) return;

    qs.append('api', '1');
    qs.append('origin', origin);
    qs.append('origin_place_id', route.geocodingResults.origin.placeId);
    qs.append('destination_place_id', route.geocodingResults.destination.placeId);
    qs.append('destination', finalDestination);
    qs.append('waypoints', waypoints.join('|'));
    qs.append('waypoint_place_ids', route.geocodingResults.intermediates.join('|'));
    qs.append('travelmode', 'driving');

    window.open(`https://www.google.com/maps/dir/?${qs.toString()}`, '_blank');
  };

  return (
    <>
      {listRequests.length > 0 &&
        route?.points?.map((p, i) => (
          <AdvancedMarker key={i} position={{ lat: p.latitude, lng: p.longitude }}>
            {i === 0 ? <HomeMapIcon /> : <MapPin dayNumber={dayNumber} orderIndex={i} type="map" />}
          </AdvancedMarker>
        ))}
      {listRequests.length > 0 && drawPolyline && route?.encodedPolyline && (
        <MapRoutes encodedPolyline={route.encodedPolyline} />
      )}
      {listRequests.length > 0 && route?.points && route.points.length >= 2 && (
        <StartNavigationButton onClick={handleStartNavigation} color="primary" variant="contained">
          START NAVIGATION
        </StartNavigationButton>
      )}
    </>
  );
};

export default ListerMap;
