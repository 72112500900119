import { useSelector } from 'react-redux';
import { TableButton } from '../../../../components/Table/common';
import { PAYMENT_METHODS_STATUSES, VEHICLE_STATUSES } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: flex-end;
    & > * {
      width: 120px;
    }
  }
`;

export const ButtonsComponent = ({ transaction }) => {
  const currentUser = useSelector(state => state.user.user);
  const history = useHistory();

  const buttons = [
    {
      title: 'View',
      color: 'secondary',
      handleClick: item => history.push(`/vehicles/${item.id}/view`),
      isRounded: true,
      withShadow: true
    },
    {
      title: 'EasyPay',
      color: 'secondary',
      conditionalRendering: item => {
        return (
          item.vehicleStatus === VEHICLE_STATUSES.AWARDED &&
          item.buyer_id === currentUser.id &&
          item.can_be_easily_purchased &&
          (!item.payment_method || item.payment_status === PAYMENT_METHODS_STATUSES.DECLINED)
        );
      },
      handleClick: item => history.push(`/buyer/bids/${item.id}/easy-pay`),
      isRounded: true,
      withShadow: true
    }
  ];

  return (
    <ButtonsContainer>
      {buttons.map((button, i) => (
        <TableButton btn={button} key={i} itemData={transaction} />
      ))}
    </ButtonsContainer>
  );
};
