import styled from 'styled-components';
import { InfoBlock } from '../InfoBlock';
import moment from 'moment';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { BlockTitle } from '../UI/BlockTitle';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px 16px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
`;

const formatTime = (date: string) => {
  return moment(date).format('dddd, MMM. DD, YYYY, hh:mm A');
};

export const AuctionEndTime = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  return (
    <InfoBlock>
      <Wrapper>
        <BlockTitle title="Auction End Time" />
        <Value>{vehicle.auction?.date_end ? formatTime(vehicle.auction.date_end) : 'N/A'}</Value>
      </Wrapper>
    </InfoBlock>
  );
};
