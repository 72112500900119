import React, { Component, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Header, Filter, ImagePreview, BidStatus } from './components';
import { Table, NoImagePlaceholder, VehicleOptions } from '../../../components';
import { VehicleDocs } from '../../../components/TableComponents';
import { AUCTION_END_FORMATTING, VEHICLE_STATUSES } from '../../../constants';
import { vehicleSalesHistoryCalculateStatus } from '../../../utils/statusCalculator';
import { MobileInfo } from '../VehicleList/components/MobileInfo';
import { Delimiter } from 'components/Table/common';
import { VehicleOptionsBlock } from '../VehicleSales/components/tableBlocks';
import { useScreen } from 'usehooks-ts';
import { useGetSalesHistoryQuery } from 'services/api/sales';
import { setPage } from 'services/salesHistorySlice/salesHistorySlice';
import VehicleDocsMobile from '../../../components/TableComponents/VehicleDocs/VehicleDocsMobile';
import { useHistory } from 'react-router-dom';

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }

  @media (max-width: 800px) {
    thead tr {
      height: 0px;
    }
  }
`;

const VehicleSalesHistoryFC = () => {
  const { page, perPage, filters } = useSelector(state => state.salesHistory);
  const { data, isFetching: loading } = useGetSalesHistoryQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const dispatch = useDispatch();

  const vehicles = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const vehiclesCount = useMemo(() => Number(data?.count ?? 0), [data]);

  const history = useHistory();

  const { width = 0 } = useScreen();

  const loadMore = () => {
    if (page * perPage > vehiclesCount) return;
    dispatch(setPage(page + 1));
  };

  const isMobile = width < 800;
  const columns = !isMobile
    ? [
        { title: 'Vehicle Details', key: 'details' },
        { title: 'Status', key: 'status' },
        { title: 'Counter', key: 'counter' },
        { title: 'Auction', key: 'auction_end_date' },
        { title: 'Docs', key: 'docs' },
        { title: 'Actions', key: 'actions', cellClass: 'text-right' }
      ]
    : [
        { title: '', key: 'details' },
        { title: '', key: 'delimiter' },
        { title: '', key: 'mobile_info' },
        { title: '', key: 'actions' }
      ];

  const buttons = [
    {
      title: 'View',
      color: 'secondary',
      handleClick: vehicle => history.push(`/vehicles/${vehicle.id}/view`),
      size: isMobile ? 'lg' : 'auto',
      withShadow: isMobile
    }
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <div className="pageWrapper">
        <Filter perPage={perPage} setPage={setPage} />
        <StyledTableWrapper>
          <Table
            withMobileView
            columnsHead={columns}
            columnsData={convertVehicles(vehicles)}
            rowsCount={vehiclesCount}
            buttons={buttons}
            page={page}
            rowsPerPage={perPage}
            loadMore={loadMore}
            loading={loading}
            noDataText={
              !currentFilter && !loading
                ? 'Once you list your first vehicle, you will see the sale status of that vehicle in this section.'
                : ''
            }
          />
        </StyledTableWrapper>
      </div>
    </div>
  );
};

const convertVehicles = (vehicles = []) => {
  return vehicles.map(vehicle => {
    const isAdminCountered = vehicle.counters && vehicle.counters.amount_admin;

    const bidStatus = vehicleSalesHistoryCalculateStatus(vehicle);
    const counterComponent = isAdminCountered ? (
      <NumberFormat value={vehicle.counters.amount_admin} thousandSeparator prefix="$" displayType="text" />
    ) : (
      'N/A'
    );
    const formattedAuctionDate = vehicle.auction
      ? moment
          .utc(vehicle.auction.date_end)
          .local()
          .format(AUCTION_END_FORMATTING)
      : 'None';
    return {
      ...vehicle,
      image:
        vehicle.images[0] && vehicle.images[0].url_thumb ? (
          <ImagePreview vehicleId={vehicle.id} url={vehicle.images[0].url_thumb} />
        ) : (
          <NoImagePlaceholder />
        ),
      counter: counterComponent,
      status: <BidStatus vehicle={vehicle} status={bidStatus} />,
      details: <VehicleOptionsBlock currentVehicle={vehicle} />,
      auction_end_date: vehicle.auction
        ? moment
            .utc(vehicle.auction.date_end)
            .local()
            .format(AUCTION_END_FORMATTING)
        : 'None',
      docs: <VehicleDocs vehicle={vehicle} />,
      mobile_info: (
        <MobileInfo
          columns={[
            { label: 'Status:', value: <BidStatus vehicle={vehicle} status={bidStatus} /> },
            { label: 'Counter:', value: counterComponent },
            { label: 'Auction:', value: formattedAuctionDate },
            {
              label: vehicle.status === VEHICLE_STATUSES.AWARDED ? null : 'Docs',
              value: <VehicleDocsMobile vehicle={vehicle} />
            }
          ]}
        />
      ),
      delimiter: <Delimiter />
    };
  });
};

const currentFilter = formValueSelector('vehicleSalesHistoryFilterForm');

export default VehicleSalesHistoryFC;
