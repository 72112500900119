import moment from 'moment';

export const formatListerDate = (date: moment.Moment | string) => {
  return moment(date).format('ddd MM/DD YYYY');
};

export const getLabelFromDayNumber = (dayNumber: number) => {
  if (dayNumber === 8) return 'Whole Week';
  if (dayNumber === 0) return 'Any Date';
  const startOfTheWeek = moment()
    .locale('en-us')
    .startOf('week');
  const date = startOfTheWeek.add(dayNumber - 1, 'days');
  return formatListerDate(date);
};

export const getDayNumberFromDate = (date: moment.Moment | string) => {
  if (!date) return 0;
  const utcWeekday = moment(date).weekday();

  if (utcWeekday === 7) return 1;
  return utcWeekday + 1;
};
