import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { FormControlLabel, Radio, Typography} from '@material-ui/core';
import { Section } from '../components';
import { FormRadioGroup } from '../../../../components/Form';
import { required } from '../../../../utils/validators';
import {EASYPAY_REQUEST_BUY_FEE_METHODS} from "../../../../constants";

const StyledSection = styled(Section)`
  margin-top: 15px;
`;

const StyledLabel = styled(FormControlLabel)`
  > span {
    padding: 5px 0 3px 12px;
  }
`;

const BuyFeeMethods = ({creditCardDigits}) => (
  <StyledSection title="Buy Fee" xs={12}>
    <Typography style={{lineHeight: '3em'}}>
      Please select if you want to include the buy fee in your ACH/Flooring charge or
      pay it with your credit card on file.
    </Typography>
    <Field name="request_buy_fee_method" validate={required} component={FormRadioGroup} row>
      <StyledLabel
        value={EASYPAY_REQUEST_BUY_FEE_METHODS.INCLUDE_IN_ACH_FLOORING}
        label="Include in ACH/Flooring"
        control={<Radio />}
        style={{marginRight: '50px'}}
      />
      <StyledLabel
        value={EASYPAY_REQUEST_BUY_FEE_METHODS.PAY_WITH_CREDIT_CARD}
        label={`Pay with credit card (****${creditCardDigits})`}
        control={<Radio />}
      />
    </Field>
  </StyledSection>
);

export default BuyFeeMethods;
