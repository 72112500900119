import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useTimerConfig } from '../../hooks';
import { OfferAuction, Offer, OfferAuctionStatus, OfferStatus, Vehicle } from '../../types/vehicle';
import Timer from '../../components/Timer';
import styled from 'styled-components';

const parseAdminAuctionOfferInfo = (offerAuction: OfferAuction) => {
  const res: {
    sellerAmount: number;
    buyerAmount: number;
    adminAmount: number;
    activeOffer: null | Offer;
  } = {
    sellerAmount: 0,
    buyerAmount: 0,
    adminAmount: 0,
    activeOffer: null
  };
  if (!offerAuction) return res;
  if (offerAuction.status === OfferAuctionStatus.PRIVATE_BIDS) {
    const buyerAmount = offerAuction.privateOffers?.find(offer => offer.sender.role === 'buyer')?.amount;
    const sellerAmount = offerAuction.privateOffers?.find(offer => !offer.is_admin && offer.sender.role === 'seller')
      ?.amount;
    const adminAmount = offerAuction.privateOffers?.find(offer => offer.is_admin)?.amount;

    res.buyerAmount = buyerAmount ?? res.buyerAmount;
    res.sellerAmount = sellerAmount ?? res.sellerAmount;
    res.adminAmount = adminAmount ?? res.adminAmount;
    res.activeOffer = offerAuction.privateOffers?.find(offer => offer.status === OfferStatus.SENT) ?? null;
  }
  return res;
};

type Props = {
  offerAuction: OfferAuction;
  vehicle: Vehicle;
};

const StyledGrid = styled(Grid)`
  padding: 0 32px 0 0;
`;
const StyledBold = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const ActiveOfferInfo = ({ vehicle, offerAuction }: Props) => {
  const { sellerAmount, adminAmount, buyerAmount, activeOffer } = parseAdminAuctionOfferInfo(offerAuction);
  const { timer, colorsConfig } = useTimerConfig(vehicle, offerAuction);

  return (
    <>
      {sellerAmount > 0 && (
        <Grid container alignItems="center" justify="space-between">
          <StyledGrid item xs={12} sm={8}>
            <StyledBold>
              <span>Seller counter amount — </span>
              <NumberFormat value={sellerAmount} displayType="text" thousandSeparator prefix="$" />
            </StyledBold>
          </StyledGrid>
          {activeOffer?.sender.role === 'seller' && !activeOffer?.is_admin && timer && (
            <Timer toDate={timer} withColors colorsConfig={colorsConfig} fullWidth={false} />
          )}
        </Grid>
      )}
      {adminAmount > 0 && (
        <Grid container alignItems="center" justify="space-between">
          <StyledBold>
            <span>AutoAxess counter amount — </span>
            &nbsp;
            <NumberFormat value={adminAmount} displayType="text" thousandSeparator prefix="$" />
          </StyledBold>
          {activeOffer?.is_admin && timer && (
            <Timer toDate={timer} withColors colorsConfig={colorsConfig} fullWidth={false} />
          )}
        </Grid>
      )}
      {buyerAmount > 0 && (
        <Grid container alignItems="center" justify="space-between">
          <StyledBold>
            <span>Buyer counter amount — </span>
            &nbsp;
            <NumberFormat value={buyerAmount} displayType="text" thousandSeparator prefix="$" />
          </StyledBold>
          {activeOffer?.sender?.role === 'buyer' && timer && (
            <Timer toDate={timer} withColors colorsConfig={colorsConfig} fullWidth={false} />
          )}
        </Grid>
      )}
    </>
  );
};
