import styled from 'styled-components';
import { ConfirmIcon } from '../../../../../components/Icons/ConfirmIcon';
import { RequestsByDay, useListerMarkAsDoneMutation } from '../../../../../services/api/list-vehicles';
import { MapPin } from '../MapPin';
import { formatAddress } from '../../utils/formatAddress';
import { ListerNotesBlock } from './ListerNotes';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const RequestWrapper = styled.div<{ isDragging?: boolean }>`
  display: flex;
  background: #2222220a;
  height: 76px;
  padding: 14px 16px 14px 20px;
  align-items: center;
  gap: 8px;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`;

const AddressBlock = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #222222;
  max-width: 190px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const EmptySpace = styled.div`
  flex-grow: 1;
`;

type Props = { item: RequestsByDay; dayNumber: number; orderIndex: number };

const NumberOfVehicles = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #222222;
  min-width: fit-content;
`;

export const RequestBlock = ({ item, dayNumber, orderIndex }: Props) => {
  const [markAsDone] = useListerMarkAsDoneMutation();

  const handleConfirm = () => {
    if (item.status === 'done') return;
    markAsDone(item.id);
  };

  const confirmIconColor = item.status === 'done' ? '#479747' : '#757575';

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
    data: { ...item, dayNumber, orderIndex }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <RequestWrapper isDragging={isDragging} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <MapPin dayNumber={dayNumber} orderIndex={orderIndex} />
      <AddressBlock>{`${item.user.dealership_name} - ${formatAddress(item.user)}`}</AddressBlock>
      <EmptySpace />
      <NumberOfVehicles>{item.number_of_vehicles || '-'}</NumberOfVehicles>
      {/* <IconWrapper onClick={() => {}}>
        <BinIcon />
      </IconWrapper> */}
      <ListerNotesBlock requestId={item.id} />
      <IconWrapper onClick={handleConfirm}>
        <ConfirmIcon fillColor={confirmIconColor} />
      </IconWrapper>
    </RequestWrapper>
  );
};
