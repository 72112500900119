import styled from 'styled-components';
import { SubSection } from 'services/api/vehicle-sections/types/common';
import { HeaderComponent } from '../../components/CollapsibleSubSection/Header';
import { useGetSubSectionConfig } from '../../hooks/useGetSubSectionConfig';

const Wrapper = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background: rgba(217, 217, 217, 1);
  position: relative;
  border-radius: 8px;
  padding: 16px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type Props = {
  name: string;
};

export const MovableSubSectionOverlay = ({ name }: Props) => {
  const [sectionName, subSectionName] = name.split('.');
  const subSectionConfig = useGetSubSectionConfig(sectionName as any, subSectionName);
  if (!subSectionConfig) {
    return <div>no config</div>;
  }

  return (
    <Wrapper>
      <HeaderComponent name={name} isCollapsed={true} toggleCollapse={() => {}} subSectionConfig={subSectionConfig} />
    </Wrapper>
  );
};
