import styled from 'styled-components';
import { useWindowSize } from 'usehooks-ts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  color: #757575;
  font-size: ${props => (props.isPortrait ? '12px' : '16px')};
`;

const Value = styled.span`
  font-size: ${props => (props.isPortrait ? '12px' : '16px')};
  font-weight: 500;
  flex-grow: ${props => (props.fullWidth ? 1 : 0)};
`;

export const MobileInfo = ({ columns }) => {
  const { width = 0, height = 0 } = useWindowSize();
  const isPortrait = width > height;
  return (
    <Wrapper>
      {columns.map(({ label, value }) => {
        return (
          <Content>
            {label && <Label isPortrait={isPortrait}>{label}</Label>}
            <Value fullWidth={!label} isPortrait={isPortrait}>
              {value}
            </Value>
          </Content>
        );
      })}
    </Wrapper>
  );
};
