import React from 'react';
import FormTextField from '../FormTextField';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';
import { ErrorIcon } from '../../Icons/ErrorIcon';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  type?: 'text' | 'email' | 'password';
  validate?: FieldValidator<any>;
  autocomplete?: 'on' | 'off';
};

const OutlinedFormTextField = ({
  name,
  label,
  placeholder,
  type = 'text',
  validate = undefined,
  autocomplete = 'on'
}: Props) => {
  return (
    <Field name={name} validate={validate}>
      {fieldProps => {
        const hasError = (fieldProps.meta.touched && fieldProps.meta.error) || fieldProps.meta.submitError;
        return (
          <FormTextField
            input={fieldProps.input}
            meta={fieldProps.meta}
            label={label}
            type={type}
            placeholder={placeholder}
            fullWidth
            autoComplete={autocomplete}
            includeBottomIndent
            Icon={hasError ? ErrorIcon : null}
            iconOptions={{
              position: 'end'
            }}
            styleVariant={hasError ? 'outlined_error' : 'outlined'}
          />
        );
      }}
    </Field>
  );
};

export default OutlinedFormTextField;
