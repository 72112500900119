import styled from 'styled-components';
import { SoundIcon } from '../../../icons/SoundIcon';
import { useState } from 'react';
import { CountdownAnimation } from './CountdownAnimation';
import Tooltip from '../../../../../components/Tooltip';
import { useSoundRecordingContext } from '../../../context/SoundRecordingContext';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SoundRecorder = () => {
  const [iconHovered, setIconHovered] = useState(false);

  const {
    isRecording,
    finishRecording,
    startRecordingCountdown,
    startRecording,
    shouldRenderCountdown,
    stopCountdown
  } = useSoundRecordingContext();

  return (
    <>
      <Tooltip title={isRecording ? 'Stop Recording' : 'Start Recording'} isTextOverflown titleStyle="uploadVehicle">
        <Wrapper
          onMouseOut={() => setIconHovered(false)}
          onMouseOver={() => setIconHovered(true)}
          onClick={isRecording ? finishRecording : startRecordingCountdown}
        >
          <SoundIcon active={iconHovered || isRecording} />
        </Wrapper>
      </Tooltip>

      <CountdownAnimation onClose={startRecording} shouldRender={shouldRenderCountdown} stopRendering={stopCountdown} />
    </>
  );
};
