import React from 'react';
import { connect } from 'react-redux';
import { Section } from '../components';
import { selectedVehicleSelector } from '../../../../selectors';

const VehicleInformation = ({ vehicle }) => ( 
  <Section title='Vehicle Information' sm={12} md={6}>
    {`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`}
    <br />
    {`VIN: ${vehicle.vin}`}
  </Section>
);

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state)
});

export default connect(mapStateToProps)(VehicleInformation);
