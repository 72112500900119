import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { reset, getFormValues } from 'redux-form';
import moment from 'moment';
import { Api } from '../utils';
import {
  listSellersInvoices,
  listSellerPastInvoices,
  getSingleInvoice,
  createSingleInvoiceRecord,
  listBuyerFees,
  updateBuyerFees,
  getBuyerFee,
  generateSellerInvoiceFile,
  snackShow,
  getDeclinedTransactions,
  retryFeeCharging,
  listCurrentSellerInvoices,
  chargeAdditionalFees,
  filterSellersInvoices,
  modalsHide,
  getTotalsInvoice,
  getAuctionSummaryReport,
  getListerSummaryReport,
} from '../actions';
import {
  listTransactions,
  filterTransactions,
  deleteDeclinedTransaction,
  LIST_TRANSACTIONS_REQUEST, LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_REQUEST,
  FILTER_TRANSACTIONS_REQUEST,
  DELETE_DECLINED_TRANSACTION_REQUEST,
  LIST_EASYPAY_PAYMENTS_REQUEST,
  LIST_AWARDED_VEHICLES_REQUEST,
  getEasyPayList,
  getAwardedVehicleList,
  filterEasyPayList,
  filterAwardedVehiclesList,
  FILTER_EASYPAY_PAYMENTS_REQUEST,
  FILTER_AWARDED_VEHICLES_REQUEST,
  updateEasyPayTransaction,
  updateTransaction,
  UPDATE_EASY_PAY_TRANSACTION_REQUEST
} from '../actions/financials';
import {
  LIST_SELLERS_INVOICES_REQUEST,
  LIST_SELLER_PAST_INVOICES_REQUEST,
  LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST,
  CREATE_SELLER_INVOICE_RECORD_REQUEST,
  UPDATE_BUYER_FEES_REQUEST,
  LIST_BUYER_FEES_REQUEST,
  GET_BUYER_FEE_REQUEST,
  GENERATE_SELLER_INVOICE_FILE_REQUEST,
  LIST_CURRENT_SELLER_INVOICES_REQUEST,
  GET_DECLINED_TRANSACTIONS_REQUEST,
  RETRY_FEE_CHARGING_REQUEST,
  CHARGE_ADDITIONAL_FEES_REQUEST,
  FILTER_SELLERS_INVOICES_REQUEST,
  UPDATE_TRANSACTION_REQUEST,
  TOTALS_INVOICES_REQUEST,
  GET_AUCTION_SUMMARY_REPORT_REQUEST,
  GET_LISTER_SUMMARY_REPORT_REQUEST,
} from '../constants/actionTypes';
import { roles, TRANSACTION_TYPES } from '../constants';

const getFetchQuery = state => getFormValues('invoicesFilterForm')(state);

function* fetchSellersInvoices({ payload }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.financials.fetchSellersInvoices, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listSellersInvoices(data).success);
  } catch (e) {
    yield put(listSellersInvoices(e).failure);
  }
}

function* filterSellersInvoicesSaga({ payload }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.financials.fetchSellersInvoices, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(filterSellersInvoices(data).success);
  } catch (e) {
    yield put(filterSellersInvoices(e).failure);
  }
}

function* fetchSellerPastInvoices({ payload }) {
  try {
    const { data } = yield call(
      Api.financials.fetchSellerPastInvoices,
      payload.seller,
      payload.params
    );
    yield put(listSellerPastInvoices(data).success);
  } catch (e) {
    yield put(listSellerPastInvoices(e).failure);
  }
}

function* fetchSingleInvoice({ payload }) {
  try {
    const { data } = yield call(
      Api.financials.fetchSingleInvoice,
      payload.seller,
      payload.date
    );
    yield put(getSingleInvoice(data).success);
  } catch (e) {
    yield put(getSingleInvoice(e).failure);
  }
}

function* fetchTotalInvoices() {
  try {
    const { data } = yield call(Api.financials.fetchTotalInvoices);

    yield put(getTotalsInvoice(data.data).success);
  } catch (e) {
    yield put(getTotalsInvoice(e).failure);
  }
}

function* fetchSingleInvoiceByUploadedCars({ payload }) {
  try {
    const { data } = yield call(
      Api.financials.fetchSingleInvoiceByUploadedCars,
      payload.seller,
      payload.date
    );
    yield put(getSingleInvoice(data).success);
  } catch (e) {
    yield put(getSingleInvoice(e).failure);
  }
}

function* createSellerSingleInvoiceRecord({ payload }) {
  try {
    const { data } = yield call(Api.financials.createInvoiceRecord, payload);
    yield put(createSingleInvoiceRecord(data).success);
    const currentInvoiceDate = yield select(
      state => state.financials.currentInvoiceDate
    );
    const { data: invoiceData } = yield call(
      Api.financials.fetchSingleInvoice,
      payload.user_id,
      currentInvoiceDate
    );
    yield put(getSingleInvoice(invoiceData).success);
    yield put(reset('invoiceDetailForm'));
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(createSingleInvoiceRecord(e).failure);
  }
}

function* fetchFees({ payload }) {
  try {
    const { data } = yield call(Api.financials.fetchBuyerFees, payload);
    yield put(listBuyerFees(data).success);
  } catch (e) {
    yield put(listBuyerFees(e).failure);
  }
}

function* fetchAuctionSummaryReport({ payload }) {
  try {
    const { data } = yield call(Api.financials.fetchAuctionSummaryReport, payload);
    yield put(getAuctionSummaryReport(data).success);
  } catch (e) {
    yield put(getAuctionSummaryReport(e).failure);
  }
}

function* fetchListerSummaryReport({ payload }) {
  try {
    const { data } = yield call(Api.financials.fetchListerSummaryReport, payload);
    yield put(getListerSummaryReport(data).success);
  } catch (e) {
    yield put(getListerSummaryReport(e).failure);
  }
}

function* updateFees({ payload }) {
  try {
    const { data } = yield call(Api.financials.updateBuyerFees, payload);
    yield put(updateBuyerFees(data).success);
  } catch (e) {
    yield put(updateBuyerFees(e).failure);
  }
}

function* fetchBuyerFee({ payload }) {
  try {
    const { data } = yield call(Api.financials.fetchBuyerFee, payload);

    yield put(getBuyerFee(data).success);
  } catch (e) {
    yield put(getBuyerFee(e).failure);
  }
}

function* retryChargingFee({ payload }) {
  try {
    const { data } = yield call(Api.financials.retryTransaction, payload);
    yield put(snackShow({ message: data.message, type: 'success' }));
    yield put(retryFeeCharging(data).success);
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(retryFeeCharging(e.response.data).failure);
  }
}

const invoiceFormSelector = state => getFormValues('invoiceDetailForm')(state);

function* createSellerInvoiceFile({ payload }) {
  try {
    const formData = yield select(invoiceFormSelector);
    const invoiceData = {
      ...payload,
      invoice_date: moment(formData.invoice_date).format('MMM DD, YYYY'),
      due_date: moment(formData.due_date).format('MMM DD, YYYY')
    };
    const { data } = yield call(
      Api.financials.generateSellerInvoicePdf,
      invoiceData
    );
    yield put(snackShow({ message: data.message, type: 'success' }));
    yield put(generateSellerInvoiceFile(data).success);
  } catch (e) {
    const errMsg = e.response.data.data[0]
      ? e.response.data.data[0].message
      : e.response.data.message;
    yield put(snackShow({ message: errMsg, type: 'error' }));
    yield put(generateSellerInvoiceFile(e).failure);
  }
}

function* fetchCurrentSellerInvoices({ payload }) {
  try {
    const { data } = yield call(
      Api.financials.fetchCurrentSellerInvoices,
      payload
    );
    yield put(listCurrentSellerInvoices(data).success);
  } catch (e) {
    yield put(listCurrentSellerInvoices(e).failure);
  }
}

function* fetchDeclinedTransactions({ payload }) {
  try {
    const { data } = yield call(Api.financials.getDeclinedTransactions, payload);
    yield put(getDeclinedTransactions(data).success);
  } catch (e) {
    yield put(getDeclinedTransactions(e).failure);
  }
}

function* removeDeclinedTransaction({ payload }) {
  try {
    const { data } = yield call(Api.financials.deleteDeclinedTransactions, payload);
    yield put(snackShow({ message: data.message, type: 'success' }));
    yield put(modalsHide());
    yield put(deleteDeclinedTransaction(data).success);
  } catch (e) {
    const errMsg = e.response.data.data[0]
      ? e.response.data.data[0].message
      : e.response.data.message;
    yield put(snackShow({ message: errMsg, type: 'error' }));
    yield put(deleteDeclinedTransaction(e).failure);
  }
}

function* additionalFeesCharging({ payload }) {
  try {
    const { data } = yield call(
      Api.financials.chargeAdditionalFees,
      payload.payload
    );
    yield put(chargeAdditionalFees(data).success);
    payload.resolve(data);
  } catch (e) {
    yield put(chargeAdditionalFees(e).failure);
    payload.reject(e);
  }
}

const getTransactionsType = state => state.financials.transactionsType;

const getUserRole = state => state.user.user.role;

function* getCurrentFiler(transactionsType) {
  if (transactionsType === TRANSACTION_TYPES.AUCTION) {
    return yield select(state =>
      getFormValues('auctionTransactionFilterForm')(state)
    );
  }
  if (transactionsType === TRANSACTION_TYPES.ADDITIONAL_FEE) {
    return yield select(state =>
      getFormValues('additionalFeeTransactionFilterForm')(state)
    );
  }
  if (transactionsType === TRANSACTION_TYPES.EASY_PAY) {
    return yield select(state =>
      getFormValues('easyPayTransactionFilterForm')(state)
    );
  }
  if (transactionsType === TRANSACTION_TYPES.ALL) {
    return yield select(state =>
      getFormValues('buyerTransactionFilterForm')(state)
    );
  }
  return {};
}

function* fetchTransactions({ payload = {} }) {
  try {
    const role = yield select(getUserRole);
    let { params } = payload;
    if (role === roles.ADMIN) {
      const type = yield select(getTransactionsType);
      const filter = yield getCurrentFiler(type);
      params = { ...params, ...filter, type };
    } else {
      const filter = yield getCurrentFiler(TRANSACTION_TYPES.ALL);
      params = { ...params, ...filter };
    }
    const { data } = yield call(Api.financials.fetchTransactions, {
      ...payload,
      params
    });


    yield put(listTransactions(data).success);
  } catch (e) {
    yield put(listTransactions(e).failure);
  }
}

function* filterTransactionsSaga({ payload = {} }) {
  try {
    const role = yield select(getUserRole);
    let { params } = payload;
    if (role === roles.ADMIN) {
      const type = yield select(getTransactionsType);
      const filter = yield getCurrentFiler(type);
      if (filter && filter.combinedStatus && type === 'easy_pay') {
        // eslint-disable-next-line prefer-destructuring
        filter.payment_type = filter.combinedStatus.split("_")[0];
        // eslint-disable-next-line prefer-destructuring
        filter.payment_status = filter.combinedStatus.split("_")[1];
      }
      params = { ...params, ...filter, type };
    } else {
      const filter = yield getCurrentFiler(TRANSACTION_TYPES.ALL);
      params = { ...params, ...filter };
    }
    const { data } = yield call(Api.financials.fetchTransactions, {
      ...payload,
      params
    });
    yield put(filterTransactions(data).success);
  } catch (e) {
    yield put(filterTransactions(e).failure);
  }
}

const getEasyPayFilter = state => getFormValues('buyerEasyPayTransactionFilterForm')(state);

function* fetchEasyPayList({ payload }) {
  try {
    const query = yield select(getEasyPayFilter);
    const { data } = yield call(Api.financials.fethcEasyPayTransactionList, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(getEasyPayList(data).success);
  } catch (e) {
    yield put(getEasyPayList(e).failure);
  }
}

const getAwardedVehiclesFilter = state => getFormValues('buyerAwardedVehiclesFilterForm')(state);

function* fetchAwardedVehiclesList({ payload }) {
  try {
    const query = yield select(getAwardedVehiclesFilter);
    const { data } = yield call(Api.financials.fetchAwardedVehiclesList, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(getAwardedVehicleList(data).success);
  } catch (e) {
    yield put(getAwardedVehicleList(e).failure);
  }
}

function* filterEasyPayListSaga({ payload }) {
  try {
    let { params } = payload;

    const filter = yield select(getEasyPayFilter);
    if (filter && filter.combinedStatus) {
      // eslint-disable-next-line prefer-destructuring
      filter.payment_type = filter.combinedStatus.split("_")[0];
      // eslint-disable-next-line prefer-destructuring
      filter.payment_status = filter.combinedStatus.split("_")[1];
    }
    params = { ...params, ...filter };

    const { data } = yield call(Api.financials.fethcEasyPayTransactionList, {
      ...payload,
      params
    });
    yield put(filterEasyPayList(data).success);
  } catch (e) {
    yield put(filterEasyPayList(e).failure);
  }
}

function* filterAwardedVehiclesListSaga({ payload }) {
  try {
    let { params } = payload;

    const filter = yield select(getAwardedVehiclesFilter);
    if (filter && filter.combinedStatus) {
      // eslint-disable-next-line prefer-destructuring
      filter.payment_type = filter.combinedStatus.split("_")[0];
      // eslint-disable-next-line prefer-destructuring
      filter.payment_status = filter.combinedStatus.split("_")[1];
    }
    params = { ...params, ...filter };

    const { data } = yield call(Api.financials.fetchAwardedVehiclesList, {
      ...payload,
      params
    });
    yield put(filterAwardedVehiclesList(data).success);
  } catch (e) {
    yield put(filterAwardedVehiclesList(e).failure);
  }
}

function* updateEasyPayTransactionSaga({ payload }) {
  try {
    const { data } = yield call(Api.financials.updateEasyPayTransaction, payload);
    yield put(updateEasyPayTransaction(data.data).success);
  } catch (e) {
    yield put(updateEasyPayTransaction(e).failure);
  }
}

function* updateTransactionSaga({ payload }) {
  try {
    const { data } = yield call(Api.financials.updateTransaction, payload);
    yield put(updateTransaction(data.data).success);
    yield put(modalsHide());
  } catch (e) {
    yield put(updateTransaction(e).failure);
  }
}

export default function* () {
  yield all([
    takeLatest(LIST_SELLERS_INVOICES_REQUEST, fetchSellersInvoices),
    takeLatest(LIST_SELLERS_INVOICES_GET_SINGLE_REQUEST, fetchSingleInvoice),
    takeLatest(TOTALS_INVOICES_REQUEST, fetchTotalInvoices),
    takeLatest(LIST_SELLERS_INVOICES_GET_UPLOADED_CARS_INVOICE_REQUEST, fetchSingleInvoiceByUploadedCars),
    takeLatest(CREATE_SELLER_INVOICE_RECORD_REQUEST, createSellerSingleInvoiceRecord),
    takeLatest(LIST_SELLER_PAST_INVOICES_REQUEST, fetchSellerPastInvoices),
    takeLatest(LIST_BUYER_FEES_REQUEST, fetchFees),
    takeLatest(GET_AUCTION_SUMMARY_REPORT_REQUEST, fetchAuctionSummaryReport),
    takeLatest(GET_LISTER_SUMMARY_REPORT_REQUEST, fetchListerSummaryReport),
    takeLatest(UPDATE_BUYER_FEES_REQUEST, updateFees),
    takeLatest(GET_BUYER_FEE_REQUEST, fetchBuyerFee),
    takeLatest(GENERATE_SELLER_INVOICE_FILE_REQUEST, createSellerInvoiceFile),
    takeLatest(LIST_CURRENT_SELLER_INVOICES_REQUEST, fetchCurrentSellerInvoices),
    takeLatest(GET_DECLINED_TRANSACTIONS_REQUEST, fetchDeclinedTransactions),
    takeLatest(DELETE_DECLINED_TRANSACTION_REQUEST, removeDeclinedTransaction),
    takeLatest(RETRY_FEE_CHARGING_REQUEST, retryChargingFee),
    takeLatest(CHARGE_ADDITIONAL_FEES_REQUEST, additionalFeesCharging),
    takeLatest(FILTER_SELLERS_INVOICES_REQUEST, filterSellersInvoicesSaga),
    takeLatest(LIST_TRANSACTIONS_REQUEST, fetchTransactions),
    takeLatest(FILTER_TRANSACTIONS_REQUEST, filterTransactionsSaga),
    takeLatest(LIST_EASYPAY_PAYMENTS_REQUEST, fetchEasyPayList),
    takeLatest(LIST_AWARDED_VEHICLES_REQUEST, fetchAwardedVehiclesList),
    takeLatest(FILTER_EASYPAY_PAYMENTS_REQUEST, filterEasyPayListSaga),
    takeLatest(FILTER_AWARDED_VEHICLES_REQUEST, filterAwardedVehiclesListSaga),
    takeLatest(UPDATE_EASY_PAY_TRANSACTION_REQUEST, updateEasyPayTransactionSaga),
    takeLatest(UPDATE_TRANSACTION_REQUEST, updateTransactionSaga)
  ]);
}
