import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { InfoBlock } from '../../InfoBlock';

const Wrapper = styled.div`
  display: flex;
  padding: 14px 24px 22px 14px;
  flex-direction: column;
  gap: 8px;
`;

const InfoRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  gap: 14px;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
`;

const InfoText = styled.span`
  font-size: 16px;
  line-height: 28px;
`;

const InfoValue = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  max-width: 300px;
  text-align: right;
  white-space: break-word;
  word-break: break-all;
`;

export const PaymentInfo = () => {
  const vehicle = useViewedVehicle();

  if (!vehicle) return null;

  const seller = vehicle.user.seller;

  const paymentOptions =
    seller.purchase_options?.length > 0
      ? seller.purchase_options.map((option: any) => option.displayName).join(', ')
      : 'N/A';

  return (
    <InfoBlock>
      <Wrapper>
        <Title>Payment / Pick Up Instructions</Title>
        <InfoRow>
          <InfoText>Payment</InfoText>
          <InfoValue>{paymentOptions}</InfoValue>
        </InfoRow>
        {seller.pickup_instruction && (
          <InfoRow>
            <InfoText>Pick Up</InfoText>
            <InfoValue>{seller.pickup_instruction}</InfoValue>
          </InfoRow>
        )}
      </Wrapper>
    </InfoBlock>
  );
};
