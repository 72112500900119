import { Field, Form, FormSpy } from 'react-final-form';
import styled from 'styled-components';
import { FormResetButton, FormTextField } from '../../../../components';
import { MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { activeFilters, pendingFilters } from './Filter';
import { setFilters, setPage } from '../../../../services/mySales/salesSlice';
import { debounce } from '../../../../utils';

const StyledForm = styled.form`
  padding: 0 16px 16px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const PatchedFormTextField = FormTextField as any;

export const MobileDashboardFilters = () => {
  const onSubmit = () => {};
  const {
    filters: { auctionStatus }
  } = useSelector((state: any) => state.mySales);
  const dispatch = useDispatch();

  const handleChange = debounce(({ values }: any) => {
    dispatch(setPage(1));
    dispatch(setFilters({ ...values, auctionStatus }));
  }, 500);

  const filterBlock = auctionStatus === 'pending' ? pendingFilters : activeFilters;
  return (
    <Form onSubmit={onSubmit}>
      {() => {
        return (
          <StyledForm>
            <Field fullWidth component={PatchedFormTextField} name="status" label="Filter by Car Status" select>
              {filterBlock.map(({ value, label }) => {
                return <MenuItem value={value}>{label}</MenuItem>;
              })}
            </Field>
            <Field fullWidth component={PatchedFormTextField} name="search" label="Search" />
            <FormSpy subscription={{ values: true }} onChange={handleChange} />
          </StyledForm>
        );
      }}
    </Form>
  );
};
