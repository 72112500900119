import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PAYMENT_METHODS_STATUSES, VEHICLE_STATUSES } from '../../../constants';
import { BuyerDocsIcon, FundingNoticeIcon, ReportOfSaleIcon } from '../../Icons/VehicleDocs';

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #005a96;
`;

const DocsWrapper = styled.div`
  display: flex;
  margin-bottom: -12px;
  justify-content: ${props => (props.withFundingNotice ? 'space-between' : 'space-around')};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const VehicleDocsMobile = ({ vehicle }) => {
  if (vehicle.status !== VEHICLE_STATUSES.AWARDED) {
    return 'N/A';
  }

  const withFundingNotice = vehicle.payment_status === PAYMENT_METHODS_STATUSES.APPROVED;

  return (
    <DocsWrapper withFundingNotice={withFundingNotice}>
      <ItemWrapper>
        <BuyerDocsIcon />
        <StyledLink component={Link} to={`/vehicles/${vehicle.id}/buyer-docs`}>
          Buyer Docs
        </StyledLink>
      </ItemWrapper>

      <ItemWrapper>
        <ReportOfSaleIcon />
        <StyledLink target="_blank" rel="noreferrer noopener" component="a" href={vehicle.seller_report_of_sale}>
          Report Of Sale
        </StyledLink>
      </ItemWrapper>

      {withFundingNotice && (
        <ItemWrapper>
          <FundingNoticeIcon />
          <StyledLink
            target="_blank"
            rel="noreferrer noopener"
            component="a"
            href={`/vehicles/${vehicle.id}/funding-notice`}
          >
            Funding Notice
          </StyledLink>
        </ItemWrapper>
      )}
    </DocsWrapper>
  );
};

export default VehicleDocsMobile;
