import { useMemo } from 'react';
import { BID_INCREMENT } from '../constants';
import { OfferAuction, OfferAuctionBid } from './../types/vehicle';
import { useSelector } from 'react-redux';

type OfferAuctionBidsInfo = {
  minBidOfferAmount: number;
  highestBidOffer: OfferAuctionBid | null;
  isProxyOffer: boolean;
};

export const useOfferAuctionBidsInfo = (offerAuction?: OfferAuction | null) => {
  const user = useSelector((state: any) => state.user.user);

  const bidInfo: OfferAuctionBidsInfo = {
    minBidOfferAmount: 0,
    highestBidOffer: { amount: 0, id: 0, bidder: {}, user_id: 0, status: '', created_at: '', updated_at: '' },
    isProxyOffer: false
  };

  bidInfo.highestBidOffer = (offerAuction?.offerBids ?? []).reduce(
    (acc, bid) => {
      if (bid.amount && bid.amount >= acc.amount && bid.id > acc.id) {
        return bid;
      }
      return acc;
    },
    { amount: 0, id: 0, bidder: {} } as OfferAuctionBid
  );

  const minBidOfferAmount = useMemo(() => {
    if (!offerAuction) {
      return 0;
    }
    if (!offerAuction.offerBids || offerAuction.offerBids.length === 0) return offerAuction.starting_bid;
    if (user && offerAuction.offerProxyBid && offerAuction.offerProxyBid?.user_id === user.id) {
      return offerAuction.offerProxyBid.amount + BID_INCREMENT;
    }

    return (bidInfo.highestBidOffer?.amount || 0) + BID_INCREMENT;
  }, [offerAuction, user]);

  if (!offerAuction) return bidInfo;

  bidInfo.isProxyOffer = offerAuction.offerProxyBid?.user_id === user.id;

  return { ...bidInfo, minBidOfferAmount };
};
