import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import moment from 'moment';
import { MenuItem } from '@material-ui/core';
import { FormTextField } from '../Form';
import { AUCTION_END_FORMATTING } from '../../constants';
import { useGetAuctionsQuery } from 'services/api/auctions-list';
import { Field as FinalFormField } from 'react-final-form';

const AuctionFilterFC = ({
  multiple = false,
  showAll = true,
  displayType,
  formType = 'redux-form',
  fullWidth = false
}) => {
  const FieldComponent = formType === 'redux-form' ? Field : FinalFormField;
  const { data } = useGetAuctionsQuery({
    auctionType: 'all'
  });

  const auctions = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const sorted = [...auctions].sort((a, b) => {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  });

  return (
    <FieldComponent
      component={FormTextField}
      name="auction_id"
      label="Filter by Auction"
      select
      fullWidth={fullWidth}
      style={{ minWidth: '160px' }}
      inputProps={{ multiple }}
    >
      {showAll && <MenuItem value="">All</MenuItem>}
      {sorted.map((item, i) => {
        if (displayType === 'id') {
          return (
            <MenuItem value={item.id} key={i}>
              {item.slug ?? item.id}
            </MenuItem>
          );
        }
        return (
          <MenuItem value={item.id} key={i}>
            {moment
              .utc(item.date_end)
              .local()
              .format(AUCTION_END_FORMATTING)}
          </MenuItem>
        );
      })}
    </FieldComponent>
  );
};

export default AuctionFilterFC;
