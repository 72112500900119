import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { GoBackButton } from '../containers/goBackButton';

const StepperTitleWrapper = styled.div`
  margin: 24px 0;

  @media (max-width: 600px) {
    margin: 24px 0;
  }
`;

const StyledTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    color: #212121;
    letter-spacing: 0.15px;
    font-weight: 500;
  }
`;

const StyledSubTitle = styled(Typography)`
  && {
    font-size: 12px;
    color: #616161;
    letter-spacing: 0.15px;
    font-weight: 500;
  }
`;

const StyledDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #000;
  margin-top: 12px;
`;

const StepperTitle = ({ title, subTitle = '', description = '' }) => (
  <StepperTitleWrapper>
    <StyledTitle>
      <GoBackButton />
      <span>{title}</span>
    </StyledTitle>
    {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
    {description && <StyledDescription>{description}</StyledDescription>}
  </StepperTitleWrapper>
);

export default StepperTitle;
