import {
  LIST_LOCATIONS_REQUEST,
  LIST_LOCATIONS_SUCCESS,
  LIST_LOCATIONS_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  LOCATIONS_SET_PAGE,
  LOCATIONS_SET_PER_PAGE,
  LOCATIONS_SET_END_DAY,
  LOCATIONS_SET_LOCATION_NAME,
  LOCATIONS_UNSET_SUCCESS,
  SET_LOCATION,
  SET_EDIT_LOCATION
} from '../constants/actionTypes';

export const listLocations = data => ({
  request: { type: LIST_LOCATIONS_REQUEST, payload: data },
  success: { type: LIST_LOCATIONS_SUCCESS, payload: data },
  failure: { type: LIST_LOCATIONS_FAILURE, payload: data }
});
export const addLocation = data => ({
  request: { type: CREATE_LOCATION_REQUEST, payload: data },
  success: { type: CREATE_LOCATION_SUCCESS, payload: data },
  failure: { type: CREATE_LOCATION_FAILURE, payload: data }
});
export const updateLocation = data => ({
  request: { type: UPDATE_LOCATION_REQUEST, payload: data },
  success: { type: UPDATE_LOCATION_SUCCESS, payload: data },
  failure: { type: UPDATE_LOCATION_FAILURE, payload: data }
});
export const deleteLocation = data => ({
  request: { type: DELETE_LOCATION_REQUEST, payload: data },
  success: { type: DELETE_LOCATION_SUCCESS, payload: data },
  failure: { type: DELETE_LOCATION_FAILURE, payload: data }
});
export const getLocation = data => ({
  request: { type: GET_LOCATION_REQUEST, payload: data },
  success: { type: GET_LOCATION_SUCCESS, payload: data },
  failure: { type: GET_LOCATION_FAILURE, payload: data }
});
export const locationsSetPage = data => ({
  type: LOCATIONS_SET_PAGE,
  payload: data
});
export const locationsSetPerPage = data => ({
  type: LOCATIONS_SET_PER_PAGE,
  payload: data
});
export const locationsSetEndDay = data => ({
  type: LOCATIONS_SET_END_DAY,
  payload: data
});
export const locationsSetLocationName = data => ({
  type: LOCATIONS_SET_LOCATION_NAME,
  payload: data
});
export const locationsUnsetSuccess = data => ({
  type: LOCATIONS_UNSET_SUCCESS,
  payload: data
});
export const setLocation = data => ({ type: SET_LOCATION, payload: data });
export const setEditLocation = data => ({
  type: SET_EDIT_LOCATION,
  payload: data
});
