import styled from 'styled-components';
import { ViewVehicleSectionsHeader } from './components/Header';
import { SubSectionsTable } from './components/SubSections';
import { ViewVehicleProvider } from '../../context/ViewVehicleContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const VehicleSections = () => {
  return (
    <ViewVehicleProvider>
      <Wrapper>
        <ViewVehicleSectionsHeader />
        <SubSectionsTable />
      </Wrapper>
    </ViewVehicleProvider>
  );
};
