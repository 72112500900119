import { useDispatch } from 'react-redux';
import api from 'services/api/list-vehicles';
import { ListVehiclesRequest } from '../components/ListVehiclesRow/types';
import moment from 'moment';
import { useUpdatePoolQueryData } from './useUpdatePoolsQuery';
import { useSelectedWeekType } from '../../../services/listVehiclesSlice/listVehiclesSlice';

export const useOptimisticRequestRemoval = () => {
  const dispatch = useDispatch<any>();
  const updatePoolQueryCache = useUpdatePoolQueryData();

  const selectedWeekType = useSelectedWeekType();

  return (rowId: number, itemToRemove: ListVehiclesRequest) => {
    // dispatch(
    //   api.util.updateQueryData('getRequestsByDay', selectedWeekType, draft => {
    //     if (!itemToRemove.preferable_date) {
    //       const dayBlock = draft[0];
    //       dayBlock.push({ ...itemToRemove, status: 'draft' });
    //     } else {
    //       const weekday = moment(itemToRemove.preferable_date).isoWeekday();
    //       const dayBlock = draft[weekday];
    //       dayBlock.push({ ...itemToRemove, status: 'draft' });
    //     }
    //   })
    // );

    updatePoolQueryCache(draft => {
      const row = draft.find(e => e.id === rowId);
      if (!row) {
        return;
      }

      const itemToRemoveIndex = row.listRequests.findIndex(e => e.id === itemToRemove.id);
      if (itemToRemoveIndex === -1) {
        return;
      }

      row.listRequests.splice(itemToRemoveIndex, 1);
    });
  };
};

export const useOptimisticDayBlockRemoval = () => {
  const dispatch = useDispatch<any>();
  const selectedWeekType = useSelectedWeekType();

  return (id: number, dayNumber: number) => {
    dispatch(
      api.util.updateQueryData('getRequestsByDay', selectedWeekType, draft => {
        const indexToRemove = draft[dayNumber]?.findIndex(e => e.id === id);
        if (typeof indexToRemove !== 'number' || indexToRemove === -1) {
          return;
        }

        draft[dayNumber].splice(indexToRemove, 1);
      })
    );
  };
};

export const useOptimisticVehicleNumberUpdate = () => {
  const updatePoolQueryCache = useUpdatePoolQueryData();

  return (vehicleRequestId: number, numberOfVehicles?: string) => {
    updatePoolQueryCache(draft => {
      const requests = draft.flatMap(pool => pool.listRequests);

      const itemToUpdateIndex = requests.findIndex(e => e.id === vehicleRequestId);
      if (itemToUpdateIndex === -1) {
        return;
      }

      requests[itemToUpdateIndex].number_of_vehicles = numberOfVehicles;
    });
  };
};
