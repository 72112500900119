import React from 'react';
import { SubmissionError, reduxForm, Field } from 'redux-form';
import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { FormCheckbox, FormError } from '../../../../components/Form/index';
import { StyledGrid } from '../components';
import { updateProfile } from '../../../../actions/user';

const StyledCheckBoxWrapper = styled(StyledGrid)`
  && {
    margin-left: 12px;
    span:nth-child(1) {
      margin-right: 5px;
      padding: 0;
    }
  }
`;

const renderCheckboxes = (array, props) =>
  array.map(({ label, name }, index) => (
    <StyledCheckBoxWrapper item key={index}>
      <FormControlLabel
        control={
          <Field name={name} component={FormCheckbox} {...props.InputProps} />
        }
        label={label}
      />
    </StyledCheckBoxWrapper>
  ));

const checkBoxes = [
  {
    name: 'purchase_option.cash',
    label: 'Cash'
  },
  {
    name: 'purchase_option.company_check',
    label: 'Company Check'
  },
  {
    name: 'purchase_option.cashiers_check',
    label: "Cashier's Check"
  },
  {
    name: 'purchase_option.ach_flooring',
    label: 'ACH/Flooring'
  }
];

const PurchaseOptionsForm = ({ handleSubmit, error, InputProps }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container direction="column" spacing={24}>
      {renderCheckboxes(checkBoxes, { InputProps })}
    </StyledGrid>
    {error && <FormError msg={error} />}
  </form>
);

const getSelectedMultipleCheckboxes = (checkboxes = {}) =>
  Object.keys(checkboxes).reduce((acc, key) => {
    if (checkboxes[key]) {
      return [...acc, key];
    }
    return acc;
  }, []);

const onSubmit = (values, dispatch) => {
  const isPurchaseOptionChosen = Object.values(
    values.purchase_option || {}
  ).filter(i => i).length;

  if (!isPurchaseOptionChosen) {
    // eslint-disable-next-line no-underscore-dangle
    throw new SubmissionError({
      _error: 'At least one purchase option should be chosen'
    });
  }
  return new Promise((resolve, reject) =>
    dispatch(
      updateProfile({
        payload: {
          ...values,
          purchase_option: getSelectedMultipleCheckboxes(values.purchase_option)
        },
        resolve,
        reject
      }).request
    )
  );
};

export default reduxForm({
  form: 'purchaseOptionsForm',
  onSubmit,
  enableReinitialize: true
})(PurchaseOptionsForm);
