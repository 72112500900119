import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { FormTextField, FormFormattedField } from 'components/Form';
import { normalizeFormattedField } from 'utils';
import { required, validateMinBid, validateReservePrice } from 'utils/validators';
import { ManualVehicleEquipment } from './Equipment';

const PatchedFormTextField = FormTextField as any;

export const ManualVehicleInformationForm = () => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={3}>
        <Field validate={required} name="year" label="Year" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field validate={required} name="make" label="Make" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          validate={required}
          name="model"
          label="Model"
          component={PatchedFormTextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field validate={required} name="trim" label="Trim" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          validate={required}
          name="engine"
          label="Engine"
          component={PatchedFormTextField}
          type="text"
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          validate={required}
          name="transmission"
          label="Transmission"
          component={PatchedFormTextField}
          type="text"
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          validate={required}
          name="drive_train"
          label="Drive Train"
          component={PatchedFormTextField}
          type="text"
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item sm={6} xs={12} md={3}>
        <Field
          validate={required}
          name="ext_color"
          label="Ext. color"
          component={PatchedFormTextField}
          fullWidth
          margin="none"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          validate={required}
          className="text-field"
          name="mileage"
          label="Enter Mileage"
          component={FormFormattedField}
          fullWidth
          thousandSeparator
          normalize={normalizeFormattedField}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Field
          className="text-field"
          name="bluebook_value"
          label="Value Without Miles Adj"
          component={FormFormattedField}
          decimalScale={0}
          prefix="$"
          thousandSeparator
          fullWidth
          normalize={normalizeFormattedField}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Field
          className="text-field"
          name="bluebook_mileage"
          label="Blue Book® Lending Value"
          component={FormFormattedField}
          decimalScale={0}
          prefix="$"
          thousandSeparator
          fullWidth
          normalize={normalizeFormattedField}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Field
          className="text-field"
          name="starting_bid"
          label="Starting Bid"
          type="text"
          component={FormFormattedField}
          decimalScale={0}
          thousandSeparator
          prefix="$"
          fullWidth
          allowNegative={false}
          validate={validateMinBid}
          normalize={normalizeFormattedField}
          // value={startingBid}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Field
          className="text-field"
          name="reserve_price"
          label="Reserve price"
          type="text"
          component={FormFormattedField}
          decimalScale={0}
          allowNegative={false}
          prefix="$"
          thousandSeparator
          fullWidth
          normalize={normalizeFormattedField}
          validate={validateReservePrice}
        />
      </Grid>
      <ManualVehicleEquipment />
    </Grid>
  );
};
