import { Divider, Grid } from '@material-ui/core';
import { modalsToggle } from 'actions';
import { SendOfferModal } from 'components/Offers';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledSpan = styled.span`
  color: #d50808;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

export const StopNegotiationBlock = ({ vehicle }) => {
  const dispatch = useDispatch();
  return (
    <Grid item xs={12}>
      <StyledSpan onClick={() => dispatch(modalsToggle(`stop-negotiation-${vehicle.id}`))}>Stop Countering between Seller and Top Buyer</StyledSpan>;
      <SendOfferModal
        maxAmount={vehicle.reserve_price}
        form={`stop-negotiation-${vehicle.id}`}
        vehicle={vehicle}
        type="stop-negotiation"
      />
      <StyledDivider />
    </Grid>
  );
};
