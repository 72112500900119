import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { modalsToggle, unawardVehicle, retryFeeCharging, getBuyerFee } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { USER_STATUSES, ADMIN_JOB_TITLES, ADMIN_VEHICLE_BID_STATUSES } from '../../../../../constants';
import { OfferHistory, SendOfferModal } from '../../../../../components/Offers';
import moment from 'moment';

const modalUnawardTitle = 'unawardVehicleModal';
const chargeModalTitle = 'retryChargeBuyer';

const StyledViewReportOfSale = styled(Typography)`
  && {
    color: #005a96;
    text-decoration: underline;
    flex-basis: 100%;
    cursor: pointer;
    text-align: right;
    font-weight: 500;
    margin-top: 17px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
    margin-top: 8px;
  }
`;

const StyledText = styled.span`
  && {
    color: #212121;
  }
`;

const StyledBold = styled.span`
  font-weight: 500;
  font-size: 18px;
  margin-top: 4px;
`;

const StyledSpan = styled(Typography)`
  && {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
  }
`;

const StyledButton = styled(Button)`
  && {
    margin: 16px 0;
  }
`;

const AwardedBlockFC = ({
  currentVehicle,
  getFee,
  modalsToggle,
  unawardCurrentVehicle,
  fee,
  offerAuction,
  retryTransaction,
  currentUser
}) => {
  useEffect(() => {
    getFee(currentVehicle.vehicle_price);
  }, [currentVehicle.vehicle_price, getFee]);

  const adminStatus = currentVehicle.role_based_statuses?.admin;

  const handleClickUnaward = () => {
    const isTimeEnded = currentVehicle.date_end && moment.utc(currentVehicle.date_end).isBefore(moment.utc());
    if (!isTimeEnded) {
      modalsToggle(modalUnawardTitle);
    } else {
      console.log('toggle-unaward');
      modalsToggle(`unaward-${currentVehicle.id}`);
    }
  };
  const handleUnaward = () => {
    unawardCurrentVehicle({
      vehicle_id: currentVehicle.id
    });

    modalsToggle(modalUnawardTitle);
  };

  const handleClickViewReport = role => {
    window.open(currentVehicle[`${role}_report_of_sale`] || currentVehicle.seller_report_of_sale);
  };

  return (
    <Fragment>
      {![ADMIN_JOB_TITLES.SALESMAN, ADMIN_JOB_TITLES.LISTER].includes(currentUser.job_title) && (
        <StyledSpan onClick={handleClickUnaward}>Unaward vehicle</StyledSpan>
      )}
      <ConfirmModal
        modalId={modalUnawardTitle}
        title="Are you sure you want to unaward this vehicle?"
        handleSubmit={() => {
          handleUnaward();
        }}
        submitText="Unaward"
      />
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      {!currentVehicle.charge_completed && currentVehicle.awarded_user.status !== USER_STATUSES.DISABLED && (
        <Fragment>
          <StyledText>The buyer updated his/her payment method. You may retry charging now.</StyledText>
          <StyledButton variant="contained" color="primary" onClick={() => modalsToggle(chargeModalTitle)}>
            charge buyer
          </StyledButton>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          <ConfirmModal
            title="Are your sure you want to retry charging?"
            handleSubmit={() =>
              retryTransaction({
                userId: currentVehicle.awarded_user.id,
                vehicleId: currentVehicle.id,
                auction_id: currentVehicle.auction_id,
                amount: fee
              })
            }
            modalId={chargeModalTitle}
          />
        </Fragment>
      )}
      <OfferHistory offerAuction={offerAuction} withDivider />
      <StyledDescription>
        This vehicle was awarded to <StyledText>{currentVehicle.awarded_user.dealership_name}</StyledText> for the
        amount of{' '}
        <StyledText>
          <NumberFormat displayType="text" prefix="$" value={currentVehicle.vehicle_price} thousandSeparator />
        </StyledText>
      </StyledDescription>

      {currentVehicle.admin_report_of_sale && (
        <>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('admin')}>
            View Admin Report of Sale
          </StyledViewReportOfSale>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('buyer')}>
            View Buyer Report of Sale
          </StyledViewReportOfSale>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('seller')}>
            View Seller Report of Sale
          </StyledViewReportOfSale>
        </>
      )}

      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      <SendOfferModal
        maxAmount={currentVehicle.reserve_price}
        form={`unaward-${currentVehicle.id}`}
        vehicle={currentVehicle}
        type="unaward"
      />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user,
  fee: state.financials.buyerFees.fee.fee
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  unawardCurrentVehicle: data => dispatch(unawardVehicle(data).request),
  retryTransaction: data => dispatch(retryFeeCharging(data).request),
  getFee: price => dispatch(getBuyerFee({ price }).request)
});

export default connect(mapStateToProps, mapDispatchToProps)(AwardedBlockFC);
