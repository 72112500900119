import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { MapPin } from './MapPin';
import { useCallback, useState } from 'react';
import moment from 'moment';

const defaultPinColor = '#757575';

export const GeolocationPin = ({
  lat,
  long,
  timestamp,
  color
}: {
  lat: number;
  long: number;
  timestamp: number;
  color?: string;
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(() => setInfoWindowShown(isShown => !isShown), []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <AdvancedMarker ref={markerRef} onClick={handleMarkerClick} position={{ lat, lng: long }}>
      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose}>
          <span>{`Last updated ${Math.floor(moment.duration(Date.now() - timestamp).asMinutes())} minutes ago`}</span>
        </InfoWindow>
      )}
      <MapPin orderIndex={'L'} color={color || defaultPinColor} />
    </AdvancedMarker>
  );
};
