import React from "react";
import styled from "styled-components";
import { AUTSlider, PageWrapper } from "../../../components";
import fordLogo from "../../../img/car-emblems/ford.png";
import chevroletLogo from "../../../img/car-emblems/сhevrolet.png";
import gmcLogo from "../../../img/car-emblems/gmc.png";
import jeepLogo from "../../../img/car-emblems/jeep.png";
import toyotaLogo from "../../../img/car-emblems/toyota.png";
import hondaLogo from "../../../img/car-emblems/honda.png";
import subaruLogo from "../../../img/car-emblems/subaru.png";
import nissanLogo from "../../../img/car-emblems/nissan.png";

const carsModalList = [
  {
    url: fordLogo
  },
  {
    url: chevroletLogo
  },
  {
    url: gmcLogo
  },
  {
    url: jeepLogo
  },
  {
    url: toyotaLogo
  },
  {
    url: hondaLogo
  },
  {
    url: subaruLogo
  },
  {
    url: nissanLogo
  },
];

const CarsModalSectionHolder = styled.section`
  background: rgba(57, 148, 222, 0.05);
`;

const CarsModalsListItem = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 108px;

  img {
    transform: scale(0.5);
    width: auto !important;
  }
  
  .text {
    color: #3994DE;
    text-align: center;
    
    span {
      display: block;
      font-size: 36px;
    }
  }
`;

const CarsModalSection = () => (
  <CarsModalSectionHolder>
    <PageWrapper>
      <AUTSlider
        settings={{
          responsive: [
            {
              breakpoint: 1126,
              settings: {
                slidesToShow: 6,
                autoplay: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                autoplay: true
              }
            }
          ],
          arrows: false,
          dots: false,
          draggable: false,
          slidesToShow: 9,
          slidesToScroll: 1,
        }}
      >
        {
          carsModalList.map(item => (
            <CarsModalsListItem key={item.url}>
              <img src={item.url} alt={item.url} />
            </CarsModalsListItem>
          ))
        }
        <CarsModalsListItem>
          <p className="text">
            <span className="count">+ 10</span>
            more
          </p>
        </CarsModalsListItem>
      </AUTSlider>
    </PageWrapper>
  </CarsModalSectionHolder>
);

export default CarsModalSection;
