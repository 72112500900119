import React, { Component, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { SubmissionError, reduxForm } from 'redux-form';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { Grid, MenuItem } from '@material-ui/core';
import { FormTextField, FormFormattedField } from '../../../../components/Form';
import {
  OfficePhoneLabel,
  FormWrapper,
  StepControl,
  AddressComponent,
  FieldWrapper,
  StepperTitle
} from '../components';
import { composeValidators, maxLength, required } from '../../../../utils/validators';
import { DATE_FORMAT, REGISTRATION_MAX_STRING_LENGTH, ONBOARDING_STEPS } from '../../../../constants';
import { listRegions, listReferrers } from '../../../../actions';
import { user } from '../../../../utils/api';
import scrollToError from '../../../../utils/scrollToError';
import OutlinedFormSelectField from 'components/Form/FinalForm/OutlinedFormSelectField';
import OutlinedFormFormattedField from 'components/Form/FinalForm/OutlinedFormFormattedField';
import OutlinedFormTextField from 'components/Form/FinalForm/OutlinedFormTextField';
import { useMeQuery, useOnboardingMutation } from 'services/api/auth';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const dealerLicenseValidation = value =>
  value && /^[a-zA-Z0-9-]{1,10}$/.test(value) ? undefined : 'Dealer license must contain from 1 to 10 digits';

const phoneNumberValidation = value =>
  value && value.replace(/[\D]/g, '').length === 10 ? undefined : 'Phone number must contain 10 digits';

const notRequiredPhoneNumberValidation = value =>
  (value && value.replace(/[\D]/g, '').length === 10) || (value || '').length === 0
    ? undefined
    : 'Phone number must contain 10 digits';

const arrayRequired = value => (value ? undefined : 'Required');

const isValidDate = date => {
  if (!date) {
    return undefined;
  }
  return moment(date, DATE_FORMAT, true).isValid() ? undefined : 'Dealer license expiry date is invalid';
};

const isExpired = date => {
  if (!date) {
    return undefined;
  }
  return moment().isBefore(moment(date, DATE_FORMAT)) ? undefined : 'Dealer license is already expired';
};

const continueOnboarding = onSubmit => values =>
  user
    .continueOnboarding({
      ...values,
      purchase_option: [],
      phone_number: (values.phone_number || '').replace(/[\D]/g, ''),
      secondary_contact_phone: (values.secondary_contact_phone || '').replace(/[\D]/g, ''),
      store_number: (values.store_number || '').replace(/[\D]/g, ''),
      last_step: ONBOARDING_STEPS.THIRD_SELLER_STEP
    })
    .then(() => {
      onSubmit();
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        const errors = (response.data.data || []).reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        // eslint-disable-next-line no-throw-literal
        throw new SubmissionError(errors);
      }
    });

const SecondSellerStep = ({ nextStep }) => {
  const referrers = useSelector(state => state.users.referrers);
  const regions = useSelector(state => state.regions.itemsData.rows || []);

  const user = useMeQuery();

  const dispatch = useDispatch();

  const [onboarding] = useOnboardingMutation();

  useEffect(() => {
    dispatch(listReferrers().request);
    dispatch(listRegions().request);
  }, []);

  const onSubmit = async values => {
    console.log(values);

    await onboarding({
      ...values,
      purchase_option: [],
      phone_number: (values.phone_number || '').replace(/[\D]/g, ''),
      secondary_contact_phone: (values.secondary_contact_phone || '').replace(/[\D]/g, ''),
      store_number: (values.store_number || '').replace(/[\D]/g, ''),
      last_step: ONBOARDING_STEPS.THIRD_SELLER_STEP
    });

    nextStep();
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...user
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <FormWrapper>
            <StepperTitle title="Seller Info" />
            <Grid container>
              <AddressComponent />
              <OutlinedFormSelectField
                name="region_id"
                label="Region"
                validate={arrayRequired}
                options={regions.map(item => ({
                  label: item.name,
                  value: item.id
                }))}
              />
              {/* <Field
                name="region_id"
                component={FormTextField}
                type="text"
                label="Region"
                fullWidth
                validate={arrayRequired}
                select
              >
                {regions.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Field> */}
              <OutlinedFormSelectField
                name="referrer"
                label="Referrered By"
                options={referrers.map(item => ({
                  label: item.name,
                  value: item.value
                }))}
              />
              {/* <Field name="referrer" component={FormTextField} type="text" label="Referrered By" fullWidth select>
                {referrers.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Field> */}
              <OutlinedFormFormattedField
                name="phone_number"
                maskProps={{
                  format: '(###) ###-####',
                  mask: '_'
                }}
                validate={composeValidators(required, phoneNumberValidation)}
                label="Mobile phone number*"
                placeholder="(###) ###-####"
              />

              <OutlinedFormFormattedField
                name="store_number"
                maskProps={{
                  format: '(###) ###-####',
                  mask: '_'
                }}
                validate={notRequiredPhoneNumberValidation}
                label="Store phone number (Optional)*"
                placeholder="(###) ###-####"
              />
              <OutlinedFormTextField
                name="secondary_contact_name"
                label="Secondary contact name (Optional)*"
                placeholder="Secondary contact name"
                validate={maxStringLength}
              />

              <OutlinedFormFormattedField
                name="secondary_contact_phone"
                label="Secondary contact phone number (Optional)*"
                placeholder="(###) ###-####"
                validate={maxStringLength}
                maskProps={{
                  format: '(###) ###-####',
                  mask: '_'
                }}
              />

              <OutlinedFormTextField
                name="dealer_license_number"
                label="DMV Vehicle Dealer License Number*"
                placeholder="DMV Vehicle Dealer License Number"
                validate={composeValidators(required, dealerLicenseValidation)}
              />

              <OutlinedFormFormattedField
                name="dealer_license_expiry_date"
                label="DMV Vehicle Dealer License Expiry Date*"
                placeholder="MM/DD/YYYY"
                validate={composeValidators(isValidDate, isExpired)}
                maskProps={{
                  format: '##/##/####',
                  placeholder: DATE_FORMAT,
                  mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']
                }}
              />
            </Grid>
            <StepControl noIndent nextStep={nextStep} />
          </FormWrapper>
        </form>
      )}
    </Form>
  );
};

const mapStateToProps = state => ({
  referrers: state.users.referrers,
  regions: state.regions.itemsData.rows || []
});

const mapDispatchToProps = dispatch => ({
  getReferrers: () => dispatch(listReferrers().request),
  getRegions: () => dispatch(listRegions().request)
});

export default SecondSellerStep;
