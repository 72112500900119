import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { PurchaseMethodsForm } from '../forms';

class PurchaseMethodsSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    const { purchase_methods, ach_form, flooring_form } = initialValues[
      initialValues.role
    ];
    const extendedInitialValues = {
      purchase_method: (purchase_methods || []).reduce(
        (acc, option) => ({
          ...acc,
          [option.option_name]: true
        }),
        {}
      ),
      flooring_form,
      ach_form
    };
    return (
      <SectionBlock
        title="Purchase Methods"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <PurchaseMethodsForm
          InputProps={InputProps}
          initialValues={extendedInitialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('purchaseMethodsForm')),
  resetForm: () => dispatch(reset('purchaseMethodsForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(PurchaseMethodsSection);
