import styled from 'styled-components';
import { LoaderModal, PageWrapper } from 'components';
import { Header } from './components/Header';
import { useGetVehicleId } from '../../CreateVehicle/hooks/useGetVehicleId';
import { useGetVehicleByIdQuery } from '../../../services/api/upload-vehicle';
import { ImageCarousel } from './components/ImageCarousel';
import { VehicleSections } from './components/VehicleSections';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setImages } from '../../../services/viewVehicleSlice/viewVehicleSlice';
import ReactImageGallery from 'react-image-gallery';
import { FullScreenCarouselDialog } from './components/ImageCarousel/components/FullScreenCarousel';
import { VehicleSaleBlock } from './components/VehicleSaleBlock';
import { ListerInformation } from './components/ListerInformation';
import { VehicleInfo } from './components/VehicleInfo';
import { AutoCheckReport } from './components/AutoCheckReport';
import { OfferMessages } from './components/OfferMessages';
import { ActiveOffers } from './components/ActiveOffers';
import AuctionSummarySection from './components/AuctionSummarySection';
import { VehicleCharacteristics } from './components/VehicleCharacteristics';
import { IncreaseMaxBid } from './components/IncreaseMaxBid';
import { IncreaseMaxOffer } from './components/IncreaseMaxOffer';
import { AuctionEndTime } from './components/AuctionEndTime';
import { VehicleSidePanel } from '../../../components/VehicleSidePanel';
import { useGetSidePanelBidsQuery } from '../../../services/api/my-bids';
import { useCurrentUser } from '../../../hooks/selectors';
import { InspectorComments } from './components/InspectorComments';
import { BidsHistory } from './components/BidsHistory';
import { OffersHistory } from './components/OffersHistory';
import { useViewVehicleSocketEffect } from '../../../services/socket/handleViewVehicleEvent';
import { AskAQuestion } from './components/AskAQuestion';

const StyledPageWrapper = styled(PageWrapper)`
  padding: 70px 12px 150px 221px;
  display: flex;
  margin: 0;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 226px);
  @media only screen and (min-width: 1800px) {
    margin: 0 auto;
    padding-left: 0;
  }
`;

const MainPart = styled.div`
  display: flex;
  gap: 8px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  gap: 16px;
  margin-top: 12px;
`;

const SidePanelWrapper = styled.div`
  position: absolute;
  max-width: 226px;
  min-width: 226px;
  right: 0;
  top: 54px;
  bottom: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: calc(100% - 30px);
  overflow-y: scroll;

  @media (max-width: 1280px) {
    display: none;
  }
`;

const BottomPart = styled.div`
  display: flex;
  gap: 8px;
  & > div {
    flex: 1 1 100%;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

export const ViewVehicle = () => {
  const vehicleId = useGetVehicleId();
  const { data: vehicle, isLoading } = useGetVehicleByIdQuery(vehicleId, { skip: !vehicleId });

  const user = useCurrentUser();
  const imageGalleryRef = useRef<ReactImageGallery>(null);

  const { data: myBids } = useGetSidePanelBidsQuery(undefined, { skip: !user || user.role !== 'buyer' });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!vehicle) return;
    dispatch(setImages(vehicle.images));
  }, [vehicle?.vehicleSections]);

  useViewVehicleSocketEffect(vehicle?.id);

  if (!vehicle) return <LoaderModal modalId={'vehicle_loading'} forceOpen={isLoading} />;

  return (
    <Wrapper>
      <Header />
      <StyledPageWrapper>
        <MainPart>
          <Left>
            <ImageCarousel imageGalleryRef={imageGalleryRef} />
            <VehicleCharacteristics />
          </Left>
          <Right>
            <VehicleInfo />
            <AutoCheckReport />
            <OfferMessages />
            <ActiveOffers />
            <IncreaseMaxBid />
            <IncreaseMaxOffer />
            <AuctionSummarySection />
            <BidsHistory />
            <OffersHistory />
            <AuctionEndTime />
          </Right>
        </MainPart>
        <VehicleSections />
        <VehicleSaleBlock />
        <BottomPart>
          <InspectorComments />
          <ListerInformation />
        </BottomPart>
      </StyledPageWrapper>
      <LoaderModal modalId={'vehicle_loading'} forceOpen={isLoading} />
      <FullScreenCarouselDialog imageGalleryRef={imageGalleryRef} />
      <SidePanelWrapper hidden={!myBids || myBids.count === 0}>
        <VehicleSidePanel />
      </SidePanelWrapper>
      <AskAQuestion />
    </Wrapper>
  );
};
