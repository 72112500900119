import React, { useEffect, useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import styled from 'styled-components';
import SectionWrapper from './SectionWrapper';

const StyledWrapper = styled(SectionWrapper)`
  background-color: transparent;
  box-shadow: none;
  padding: 0 !important;
  position: relative;

  .image-gallery {
    .image-gallery-fullscreen-button {
      bottom: auto;
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      font-size: 2em;
      top: calc(100% + 52px);
      padding: 0px 16px;
    }

    &-slides {
      background: black;
    }

    ${'' /* &-slide {
      opacity: 0;
      transition: opacity 500ms ease-in !important;
    } */}

    &-slide.center {
      opacity: 1;
    }

    &-thumbnails {
      padding: 0;
      margin-top: 13px;
    }

    .image-gallery-content.fullscreen {
      .image-gallery-slide.center {
        height: 100vh;
        img {
          height: calc(100vh - 70px);
          padding: 35px 0;
          object-fit: contain;
        }
      }
      .image-gallery-thumbnails-wrapper {
        position: absolute;
        bottom: 15px;
      }
    }
  }
`;

const ImageSlider = ({ images }) => {
  const convertedImages = useMemo(
    () => (images || []).map(image => ({
      original: image.url,
      thumbnail: image.url_thumb,
    })),
    [images]
  );

  return (
    <StyledWrapper>
      <ImageGallery
        showPlayButton={false}
        items={convertedImages}
        showThumbnails={convertedImages.length > 1}
        slideDuration={0}
      />
    </StyledWrapper>
  );
};

export default React.memo(ImageSlider);
