import React, { Component } from 'react';
import { formValueSelector, change, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  IconButton,
  Icon,
  Grid,
  MenuItem,
  TextField
} from '@material-ui/core';
import styled from 'styled-components';
import { FormFormattedField, FormTextField } from '../components/Form';
import { FILTER_TYPES } from '../constants/filters';

const StyledGridWithFullWidth = styled(Grid)`
  width: 100%;
`;

const StyledIcon = styled(IconButton)`
  padding: 0;
  margin-bottom: 5px;
  > span {
    padding-bottom: 0;
    margin-right: 0;
  }
`;

const StyledOperatorField = styled(Field)`
  width: 100px;
`;

class AdditionalFilters extends Component {
  constructor(props) {
    super(props); 


    const { unselectedFilters, selectedFilters } = this.initAdditionalFilters(props);

    this.state = {
      unselectedFilters,
      selectedFilters,
    };
  }

  addAdditionalFilter = (additionalFilter) => {
    const { filters } = this.props;
    const { unselectedFilters, selectedFilters } = this.state;
    const selectedFilter = filters.find((filter => filter.name === additionalFilter));
    this.setState({
      selectedFilters: selectedFilters.concat(selectedFilter),
      unselectedFilters: unselectedFilters.filter(filter => filter.name !== additionalFilter),
    });
  }

  removeAdditionalFilter = (name) => {
    const { changeField, filters } = this.props;
    changeField(name, '');
    const { unselectedFilters, selectedFilters } = this.state;
    const unselectedFilter = filters.find((filter => filter.name === name));
    this.setState({
      selectedFilters: selectedFilters.filter(filter => filter.name !== unselectedFilter.name),
      unselectedFilters: unselectedFilters.concat(unselectedFilter),
    });
  }

  // eslint-disable-next-line
  initAdditionalFilters({ filters, additionalFilters }) {
    let selectedFilters = [];
    let unselectedFilters = filters;
    filters.forEach((filter) => {
      if (additionalFilters[filter.name]) {
        selectedFilters = selectedFilters.concat(filter);
        unselectedFilters = unselectedFilters.filter(item => item.name !== filter.name);
      }
    });
    return { selectedFilters, unselectedFilters };
  }

  render() {
    const { unselectedFilters, selectedFilters } = this.state;
    return (
      <>
        {unselectedFilters.length !== 0 && (
          <TextField
            select
            label="Additional Filters"
            onChange={({ target }) => this.addAdditionalFilter(target.value)}
            value=''
          >
            {unselectedFilters.map(({ name, text }) => (
              <MenuItem key={name} value={name}>{text}</MenuItem>
            ))}
          </TextField>
        )}
        <Grid container>
          {selectedFilters.map(({ name, operators, text, type }, index) => (
            <Grid container wrap='nowrap' direction="row" alignItems="flex-end" key={index} spacing={8}>
              {operators && (
                <Grid item>
                  <StyledOperatorField
                    component={FormTextField}
                    name={`${name}_operator`}
                    label="Operator"
                    select
                  >
                    <MenuItem value='eq'>Equals</MenuItem>
                    <MenuItem value='gt'>Greater Than</MenuItem>
                    <MenuItem value='lt'>Less Than</MenuItem>
                  </StyledOperatorField>
                </Grid>
              )}
              <StyledGridWithFullWidth item>
                <Field
                  component={type === FILTER_TYPES.STRING ? FormTextField : FormFormattedField}
                  name={name}
                  label={text}
                  fullWidth
                  allowNegative={false}
                  fixedDecimalScale
                  decimalScale={0}
                />
              </StyledGridWithFullWidth>
              <Grid item>
                <StyledIcon
                  onClick={() => this.removeAdditionalFilter(name)}
                >
                  <Icon>cancel</Icon>
                </StyledIcon>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state, { formName, filters  }) => {
  const selector = formValueSelector(formName);
  const filterNames = filters.reduce((acc, filter) => acc.concat(filter.name), []);
  return ({
    additionalFilters: selector(state, ...filterNames),
  })
};

const mapDispatchToProps = (dispatch, { formName }) => ({
  changeField: (...params) => dispatch(change(formName, ...params))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFilters);
