import { Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  span:nth-child(2),
  div,
  input:disabled {
    color: #212121;
  }
  color: rgba(0, 0, 0, 0.54);
  position: relative;
`;

export default StyledGrid;
