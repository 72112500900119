import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, Field, reduxForm, change } from 'redux-form';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { VehicleInformation, VehicleOptions } from './components';
import FormFormattedField from '../../../../components/Form/FormFormattedField';
import { required as validateRequired } from '../../../../utils/validators';
import { changeTrim } from '../../../../actions/vehicles';

const minAmount = value => (value.toString().replace(/[\D]/g, '') >= 0 ? undefined : 'Min Amount is 0');

const formName = 'addVehicleForm';

const VehicleForm = ({
  trim,
  vehicles,
  getCarEstimate,
  submitting,
  handleSubmit,
  invalid,
  carEstimateSuccess,
  initialValues,
  isSellerSelected,
  initiallyCollapseMileage = true
}) => {
  return (
    <form id="info-form">
      <VehicleInformation trim={trim} />
      {trim && (
        <>
          <VehicleOptions initialValues={initialValues} />
          <Grid item container alignItems="flex-end" xs={12}>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                className="text-field"
                name="mileage"
                label="Enter Mileage"
                component={FormFormattedField}
                fullWidth
                thousandSeparator
                validate={validateRequired}
              />
            </Grid>
            {!carEstimateSuccess && initiallyCollapseMileage ? (
              <Grid item xs={12} sm={6} md={3}>
                {submitting ? (
                  <CircularProgress size={30} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={invalid || submitting || !isSellerSelected}
                    onClick={handleSubmit(getCarEstimate)}
                  >
                    GET VALUES
                  </Button>
                )}
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={3}>
                  <Field
                    className="text-field"
                    name="bluebookValue"
                    label="Value Without Miles Adj"
                    component={FormFormattedField}
                    decimalScale={0}
                    prefix="$"
                    thousandSeparator
                    // type="text"
                    fullWidth
                    validate={[validateRequired, minAmount]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field
                    className="text-field"
                    name="bluebookMileage"
                    label="Blue Book® Lending Value"
                    component={FormFormattedField}
                    decimalScale={0}
                    // type="text"
                    prefix="$"
                    thousandSeparator
                    fullWidth
                    validate={[validateRequired, minAmount]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {submitting ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={handleSubmit(getCarEstimate)}
                    >
                      Update Value
                    </Button>
                  )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </>
      )}
    </form>
  );
};

const formValues = formValueSelector(formName);

const mapStateToProps = state => ({
  trim: formValues(state, 'trim'),
  vehicles: state.vehicles
});

const mapDispatchToProps = dispatch => ({
  setFormField: (field, value) => dispatch(change(formName, field, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onChange: (values, dispatch, props, prevValues) => {
      const prevTrim = prevValues.trim;
      const newTrim = values.trim;
      if (prevTrim !== newTrim) {
        dispatch(changeTrim({ trim: newTrim }));
      }
    }
  })(VehicleForm)
);
