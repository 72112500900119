import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import { Vehicle } from '../../../../types/vehicle';
import { useAcceptBidMutation } from 'services/api/offer-auction';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../actions';
import { useAdminOfferAuction } from '../hooks';

type Props = {
  vehicle: Vehicle;
};

export const AcceptTopOfferModal = ({ vehicle }: Props) => {
  const dispatch = useDispatch();

  const [acceptTopBid] = useAcceptBidMutation();

  const offerAuction = useAdminOfferAuction(vehicle.id)
  const sellerAcceptBid = offerAuction?.offerBids?.find(el => el.status === 'seller_accepted');

  const handleAccept = () => {
    acceptTopBid({ vehicle_id: vehicle.id, offer_id: sellerAcceptBid?.id! });
    dispatch(modalsToggle(`acceptTopOffer-${vehicle.id}`));
  };

  return (
    <ConfirmModal
      submitText="Accept"
      title="Are you sure you want to accept the offer?"
      modalId={`acceptTopOffer-${vehicle.id}`}
      handleSubmit={handleAccept}
    />
  );
};
