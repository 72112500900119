import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { ExportIcon } from '../../../icons/ExportIcon';
import { LikeIcon } from '../../../icons/LikeIcon';
import { CopyIcon } from '../../../icons/CopyIcon';

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
`;

const ActionButton = styled.div`
  height: 24px;
  padding: 0 12px;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 16px;
  display: flex;
  gap: 6px;
  cursor: pointer;
  align-items: center;
`;

const ActionLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(33, 33, 33, 1);
`;

export const Actions = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  return (
    <Wrapper>
      <ActionButton>
        <CopyIcon />
        <ActionLabel>Copy</ActionLabel>
      </ActionButton>
      <ActionButton>
        <LikeIcon />
        <ActionLabel>Wishlist</ActionLabel>
      </ActionButton>
    </Wrapper>
  );
};
