import apiSlice from '..';
import { snackShow } from '../../../actions';

export const adminOffersApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateOfferBid: builder.mutation<{ data: { isUpdated: boolean } }, { id: number; amount: number }>({
      query: ({ id, amount }) => ({
        url: `/offers/bids/${id}`,
        method: 'PUT',
        body: { amount }
      }),
      invalidatesTags: ['OfferAuction'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          if (data?.data?.data?.isUpdated) {
            dispatch(snackShow({ message: 'Successfully updated bid offer' }));
          }
        } catch (e) {
          dispatch(
            snackShow({ message: (e as any)?.error?.data?.message || 'Error while updating bid offer', type: 'error' })
          );
        }
      }
    }),
    removeOfferBid: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/offers/bids/${id}`,
        method: 'DELETE'
      })
    }),
    updateProxyOfferBid: builder.mutation<{ data: { isUpdated: boolean } }, { id: number; amount: number }>({
      query: ({ id, amount }) => ({
        url: `/offers/proxy-bids/${id}`,
        method: 'PUT',
        body: { amount }
      }),
      invalidatesTags: ['OfferAuction'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          if (data?.data?.data?.isUpdated) {
            dispatch(snackShow({ message: 'Successfully updated bid offer' }));
          }
        } catch (e) {
          dispatch(
            snackShow({
              message: (e as any)?.error?.data?.message || 'Error while updating proxy bid offer',
              type: 'error'
            })
          );
        }
      }
    }),
    removeProxyOfferBid: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/offers/proxy-bids/${id}`,
        method: 'DELETE'
      })
    })
  }),
  overrideExisting: true
});

export const {
  useUpdateOfferBidMutation,
  useRemoveOfferBidMutation,
  useUpdateProxyOfferBidMutation,
  useRemoveProxyOfferBidMutation
} = adminOffersApi;
export default adminOffersApi;
