export const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8 19V5L19 12L8 19Z" fill="white" />
  </svg>
);

export const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.3333 15.8307C12.875 15.8307 12.4826 15.6675 12.1562 15.3411C11.8299 15.0148 11.6667 14.6224 11.6667 14.1641V5.83073C11.6667 5.3724 11.8299 4.98003 12.1562 4.65365C12.4826 4.32726 12.875 4.16406 13.3333 4.16406C13.7917 4.16406 14.184 4.32726 14.5104 4.65365C14.8368 4.98003 15 5.3724 15 5.83073V14.1641C15 14.6224 14.8368 15.0148 14.5104 15.3411C14.184 15.6675 13.7917 15.8307 13.3333 15.8307ZM6.66667 15.8307C6.20833 15.8307 5.81597 15.6675 5.48958 15.3411C5.16319 15.0148 5 14.6224 5 14.1641V5.83073C5 5.3724 5.16319 4.98003 5.48958 4.65365C5.81597 4.32726 6.20833 4.16406 6.66667 4.16406C7.125 4.16406 7.51736 4.32726 7.84375 4.65365C8.17014 4.98003 8.33333 5.3724 8.33333 5.83073V14.1641C8.33333 14.6224 8.17014 15.0148 7.84375 15.3411C7.51736 15.6675 7.125 15.8307 6.66667 15.8307Z"
      fill="white"
    />
  </svg>
);
