import styled, { keyframes } from 'styled-components';
import { BuyerInfo, Contact, SellerInfo, useGetHoverCustomerInfoQuery } from 'services/api/customers';
import { formatCurrency } from '../../../../../utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import { loginAsUser } from '../../../../../actions';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  height: 650px;
  overflow-y: scroll;
`;

const LoaderKeyframes = keyframes`
  0% {
    background-color: hsl(200, 20%, 100%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
`;

const Loader = styled.div`
  height: 650px;
  animation: ${LoaderKeyframes} 1s linear infinite alternate;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 12px;
  border-bottom: 1px solid #75757533;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TextValue = styled.div<{ blue?: boolean; bold?: boolean; alignRight?: boolean; isLink?: boolean }>`
  font-family: Roboto;
  font-size: 12px;
  font-weight: ${props => (props.bold ? '500' : '400')};
  line-height: 18px;
  flex: 1 1 100%;
  text-align: ${props => (props.alignRight ? 'right' : 'left')};
  color: ${props => {
    if (props.blue) {
      return '#3994DE';
    }

    return props.bold ? '#000' : '#757575';
  }};
  text-decoration: ${props => (props.isLink ? 'underline' : 'none')};
  cursor: ${props => (props.isLink ? 'pointer' : 'auto')};
`;

const SoldVehiclesBlock = styled.div`
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const purchaseMethodMap: Record<string, string> = {
  cash: 'Cash',
  company_check: 'Company Check',
  cashiers_check: "Cashier's Check",
  ach: 'ACH',
  flooring: 'Flooring'
};

export const UserHoverInfo = ({ customerId, initialPage }: { customerId: number; initialPage: string }) => {
  const { data: info, isLoading } = useGetHoverCustomerInfoQuery(customerId);

  const dispatch = useDispatch();

  if (isLoading || !info) return <Loader />;

  const login = () => {
    dispatch(loginAsUser({ id: customerId, initialPage }).request);
  };

  return (
    <Wrapper id={`user-hover-info-${customerId}`}>
      <Block>
        <RowComponent onClick={login} blueLabel blueValue label="Name of Dealership:" value={info.dealership_name} />
      </Block>
      <Block>
        <RowComponent boldLabel label="Contacts:" />
        <ContactBlock label="Primary Contact" includeIndent={false} contact={info.primary_contact} />
        {info.secondary_contact && <ContactBlock label="Secondary Contact" contact={info.secondary_contact} />}
        {info.contacts.map((contact, i) => (
          <ContactBlock key={contact.id} label={`Other contact #${i + 1}`} contact={contact} />
        ))}
      </Block>
      <Block>
        <RowComponent boldLabel label="Address:" />
        <RowComponent label="Street Address:" value={info.address.street} />
        <RowComponent label="City:" value={info.address.city} />
        <RowComponent label="State:" value={info.address.state} />
        <RowComponent label="Zip:" value={info.address.zip} />
        <RowComponent label="Vehicle Dealer License Number:" value={info.dealer_license_number} />
      </Block>
      {info.role === 'seller' ? <SellerFeeBlock info={info} /> : <SellerPermitBlock info={info} />}
      <Block>
        <RowComponent boldLabel label="Last 5 vehicles sold:" />
        {info.lastSoldVehicles.length > 0 && (
          <SoldVehiclesBlock>
            {info.lastSoldVehicles.map((v, i) => (
              <TextValue id={v.id}>{`${i + 1}. ${v.year} ${v.make} ${v.model} ${formatCurrency(
                v.vehicle_price
              )} ${moment(v.date_status).format(DATE_FORMAT)} ${v.user.dealership_name} ${v.paymentMethodLabel ||
                ''}`}</TextValue>
            ))}
          </SoldVehiclesBlock>
        )}
      </Block>
      {info.role === 'buyer' && <BuyerFormsOfPayment info={info} />}
      <Block>
        <RowComponent boldLabel label="Notes:" />
        <TextValue>{info.notes ?? '-'}</TextValue>
      </Block>
    </Wrapper>
  );
};

const ContactBlock = ({
  contact,
  label,
  includeIndent = true
}: {
  contact: Contact;
  label: string;
  includeIndent?: boolean;
}) => {
  return (
    <>
      {includeIndent && <div />}
      <RowComponent label={label} />
      <RowComponent label="Name Surname:" value={contact.name || '-'} />
      <RowComponent label="Job Title:" value={contact.job_title || '-'} />
      <RowComponent label="Mobile phone number:" value={contact.phone || '-'} />
      <RowComponent label="Email:" value={contact.email || '-'} />
    </>
  );
};

const SellerPermitBlock = ({ info }: { info: BuyerInfo }) => {
  return (
    <Block>
      <RowComponent blueLabel blueValue boldLabel label="Seller Permit:" value={info.seller_permit_number || '-'} />
    </Block>
  );
};

const SellerFeeBlock = ({ info }: { info: SellerInfo }) => {
  return (
    <Block>
      <RowComponent boldLabel label={`Seller fee: ${formatCurrency(info.fee)}`} />
      <RowComponent label="Payment/Pick Up instructions" />
      <RowComponent boldLabel label="Payment:" value={info.purchase_options.join(', ')} />
      <RowComponent boldLabel label="Pick up:" value={info.pickup_instruction || '-'} />
    </Block>
  );
};

const BuyerFormsOfPayment = ({ info }: { info: BuyerInfo }) => {
  return (
    <Block>
      <RowComponent label="Forms of payment" value={info.purchase_methods.map(m => purchaseMethodMap[m]).join(', ')} />
    </Block>
  );
};

const RowComponent = ({
  label,
  value,
  boldLabel = false,
  boldValue = true,
  blueLabel = false,
  blueValue = false,
  onClick
}: {
  label: string;
  value?: string;
  boldLabel?: boolean;
  boldValue?: boolean;
  blueValue?: boolean;
  blueLabel?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Row>
      <TextValue blue={blueLabel} bold={boldLabel}>
        {label}
      </TextValue>
      {value && (
        <TextValue isLink={!!onClick} onClick={onClick} alignRight blue={blueValue} bold={boldValue}>
          {value}
        </TextValue>
      )}
    </Row>
  );
};
