import React from 'react';
import { connect } from 'react-redux';
import { Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { pullVehicle, sellerSetCounter, sellerAcceptBid, modalsToggle } from '../../../../../actions';
import {
  VEHICLE_STATUSES,
  AUCTION_STATUSES,
  SELLER_SALES_BID_STATUSES,
  SELLER_VEHICLE_STATUS
} from '../../../../../constants';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import StatusComponent from './StatusComponent';
import CounterComponent from './CounterComponent';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { ReservePriceSection } from '../../components';
import { OfferAuctionStatus } from '../../../../../types/vehicle';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: #e74549;
    text-decoration: underline;
    text-transform: initial;
    padding: 0;
    min-height: auto;
  }
`;

const confirmPullModal = 'confirmPullModal';

const setCounter = setCounterDispatch => vehicleId => values => {
  setCounterDispatch(vehicleId, +values.amount.replace(/[\D]/g, ''));
};

const SellerRestrictedSection = ({
  currentVehicle,
  currentUser,
  counterSet,
  sellerPullVehicle,
  acceptBid,
  toggleModal
}) => {
  let blockToRender = null;
  const highestBid = currentVehicle.bids.reduce(
    (acc, bid) => {
      if (bid.amount >= acc.amount && bid.id > acc.id) {
        return bid;
      }
      return acc;
    },
    { amount: 0, id: 0 }
  );

  const isSellerCreatedVehicle = currentVehicle.user_id === currentUser.id;

  const offerAuction = useNoSelectorVehicleOfferAuctionById(currentVehicle.id);

  const vehicleOfferAuctionStatus = auctionOfferStatuses(currentVehicle, offerAuction, currentUser);

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    currentVehicle.end_time_spacing !== null &&
    moment(currentVehicle.auction.date_end)
      .utc()
      .add(currentVehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  if (!isSellerCreatedVehicle) {
    return null;
  }
  if (
    currentVehicle.status === VEHICLE_STATUSES.ACTIVE &&
    currentVehicle.auction.status === AUCTION_STATUSES.ACTIVE &&
    !isVehicleTimerEnded
  ) {
    blockToRender = (
      <StyledButton onClick={() => toggleModal(confirmPullModal)}>Pull vehicle from the auction</StyledButton>
    );
  } else if (
    currentVehicle.status === VEHICLE_STATUSES.PULLED ||
    currentVehicle.status === SELLER_VEHICLE_STATUS.PENDING
  ) {
    blockToRender = (
      <StatusComponent
        auctionStatus={currentVehicle.auction.status}
        vehicle={currentVehicle}
        status={currentVehicle.status}
        bid={highestBid}
        sellerAcceptBid={acceptBid}
      />
    );
  } else if (isVehicleTimerEnded || AUCTION_STATUSES.ENDED === currentVehicle.auction.status) {
    if (
      !currentVehicle.buyer_id &&
      currentVehicle.auction.status !== AUCTION_STATUSES.ENDED &&
      vehicleOfferAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW
    ) {
      blockToRender = (
        <>
          <StatusComponent
            auctionStatus={currentVehicle.auction.status}
            vehicle={currentVehicle}
            status={currentVehicle.status}
            bid={highestBid}
            sellerAcceptBid={acceptBid}
          />
          {highestBid.amount > 0 && offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS && (
            <>
              <StyledDivider />
              <CounterComponent bid={highestBid} vehicle={currentVehicle} />
            </>
          )}
        </>
      );
    } else {
      blockToRender = (
        <StatusComponent
          auctionStatus={currentVehicle.auction.status}
          vehicle={currentVehicle}
          status={currentVehicle.status}
          bid={highestBid}
          sellerAcceptBid={acceptBid}
        />
      );
    }
  } else if (currentVehicle.status === VEHICLE_STATUSES.AWARDED) {
    blockToRender = (
      <StatusComponent
        status={currentVehicle.status}
        amount={currentVehicle.vehicle_price}
        awardedUser={currentVehicle.awarded_user}
        vehicle={currentVehicle}
        sellerAcceptBid={acceptBid}
      />
    );
  }
  return (
    <>
      {blockToRender}
      <StyledDivider />
      <ConfirmModal
        modalId={confirmPullModal}
        title={`Are you sure you want to pull this vehicle from the auction?${
          !isVehicleTimerEnded ? ' Once it is pulled, no more bids can be made.' : ''
        }`}
        handleSubmit={() => {
          sellerPullVehicle(currentVehicle.id);
          toggleModal(confirmPullModal);
        }}
      />
    </>
  );
};

export default connect(
  state => ({
    currentVehicle: state.vehicles.selectedVehicle.data || {},
    currentUser: state.user.user
  }),
  dispatch => ({
    sellerPullVehicle: vehicle_id => dispatch(pullVehicle({ vehicle_id }).request),
    counterSet: (vehicle_id, amount) => dispatch(sellerSetCounter({ vehicle_id, amount }).request),
    acceptBid: vehicleId => dispatch(sellerAcceptBid({ vehicle_id: vehicleId }).request),
    toggleModal: key => dispatch(modalsToggle(key))
  })
)(SellerRestrictedSection);
