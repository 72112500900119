import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Divider } from '@material-ui/core';
import { StyledGrid, FormFileField } from '../components';
import { updateProfileDoc } from '../../../../actions/user';

const ManageDocumentsForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <StyledGrid item xs={12}>
        <Field
          name="dmv_license"
          component={FormFileField}
          label="DMV Vehicle Dealer License"
          id="dmv"
          type="file"
          formName="manageDocumentsForm"
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Divider />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          name="resale_certificate"
          component={FormFileField}
          label="Seller's Permit"
          id="resale"
          type="file"
          formName="manageDocumentsForm"
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Divider />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          name="city_business_license"
          component={FormFileField}
          label="City Business License"
          id="city"
          type="file"
          formName="manageDocumentsForm"
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Divider />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          name="dealer_bond"
          component={FormFileField}
          label="Dealer Bond/Other"
          id="dealer"
          type="file"
          formName="manageDocumentsForm"
        />
      </StyledGrid>
    </StyledGrid>
  </form>
);

export default reduxForm({
  form: 'manageDocumentsForm',
  enableReinitialize: true,
  onChange: (values, dispatch) => {
    const changedFile = Object.keys(values).find(
      key => values[key] instanceof File
    );
    if (changedFile) {
      return new Promise((resolve, reject) =>
        dispatch(
          updateProfileDoc({
            payload: {
              name: changedFile,
              document: values[changedFile]
            },
            resolve,
            reject
          }).request
        )
      );
    }
    return null;
  }
})(ManageDocumentsForm);
