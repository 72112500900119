import styled from 'styled-components';
import {
  setWeekType,
  toggleRightPanel,
  useIsRightPanelExpanded,
  useSelectedWeekType
} from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import { useGetRequestsByDayQuery } from '../../../../services/api/list-vehicles';
import { DayBlock } from './DayBlock';
import { useDispatch } from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';
import { useRef } from 'react';

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  right: 96px;
  top: calc(48px + 56px + 72px + 12px);
  max-height: calc(100vh - 96px - 56px - 72px - 12px);
  overflow-y: auto;
  width: 300px;
  z-index: 10;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 20px 12px;
  flex-grow: 1;
  flex-direction: column;
  gap: 12px;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease-in-out;
  display: flex;

  @media (min-width: 1450px) {
    right: 10%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div<{ isSelected?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: ${props => (props.isSelected ? 'underline' : 'none')};
`;

const weekTypes = [
  {
    label: 'Previous week',
    value: 'previous'
  },
  {
    label: 'Current week',
    value: 'current'
  },
  {
    label: 'Next week',
    value: 'next'
  }
];

export const RightPanel = () => {
  const isOpen = useIsRightPanelExpanded();
  const selectedWeekType = useSelectedWeekType();
  const { data } = useGetRequestsByDayQuery(selectedWeekType);

  const ref = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const handleWeekTypeClick = (type: string) => dispatch(setWeekType(type));

  useOnClickOutside(ref, () => {
    if (isOpen) dispatch(toggleRightPanel());
  });

  return (
    <Wrapper ref={ref} isOpen={isOpen}>
      <Tabs>
        {weekTypes.map(({ label, value }) => {
          return (
            <Title onClick={() => handleWeekTypeClick(value)} isSelected={value === selectedWeekType}>
              {label}
            </Title>
          );
        })}
        {/* <Title>THIS WEEKS STORES</Title> */}
      </Tabs>
      <ContentWrapper>
        {data?.map((d, i) => (
          <DayBlock key={i} data={d} dayNumber={i} />
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};
