import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../components/ModalWrapper';
import InvoiceDetail from '../../../../components/InvoiceDetail';

const CurrentInvoiceModal = ({
  seller,
  invoiceData,
  invoiceDate,
  canAddRecord,
  handleSubmit,
  loading,
  ...props
}) => (
  <ModalWrapper
    title="CurrentInvoiceModal"
    showTitle={false}
    handleSubmit={handleSubmit}
    modalId="CurrentInvoiceModal"
    submitText="Generate Invoice"
    showSubmit={canAddRecord}
    submitting={loading}
  >
    <InvoiceDetail
      sellerData={seller}
      invoiceData={invoiceData}
      invoiceDate={invoiceDate}
      canAddRecord={canAddRecord}
      {...props}
    />
  </ModalWrapper>
);

CurrentInvoiceModal.propTypes = {
  canAddRecord: PropTypes.bool,
  handleSubmit: PropTypes.func
};
CurrentInvoiceModal.defaultProps = {
  canAddRecord: false,
  handleSubmit: () => {}
};

const mapStateToProps = state => ({
  seller: state.financials.currentSeller,
  invoiceData: state.financials.currentInvoice,
  invoiceDate: state.financials.currentInvoiceDate,
  loading: state.financials.loading
});

export default connect(mapStateToProps)(CurrentInvoiceModal);
