import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Typography, Button, Icon, Grid, Divider } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { FormFormattedField, FormInput } from '../Form';
import { ModalWrapper } from '../index';
import { getBuyerFee, modalsToggle } from '../../actions';

const formName = 'adjustmentForm';

const StyledGrid = styled(Grid)`
  && {
    min-width: 500px;
    margin-top: 24px;
  }
`;

const StyledForm = styled.form`
  background: #f5f5f5;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

const StyledControlsWrapper = styled(Grid)`
  display: flex;
`;

const StyledControlButton = styled(Button)`
  && {
    min-height: 0;
    width: 24px;
    height: 24px;
    font-size: 16px;
    box-shadow: none;
    margin: 0 0 2px 0;
    &:last-child {
      margin: 0;
    }
  }
`;

const StyledIcon = styled(Icon)`
  && {
    font-size: 16px;
  }
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  && {
    font-size: 16px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 8px 0;
  }
`;

const GreenNumbers = styled(Typography)`
  && {
    color: #06a000;
  }
`;

const getValue = (value = '') => parseInt(value.replace('$', '').replace(',', ''), 10);

class ChargeBuyerModal extends Component {
  componentDidMount() {
    const { vehicle, getFee } = this.props;
    const lastBid = vehicle.highest_bid || {};
    const price = vehicle.vehicle_price || lastBid.amount;

    // if (price) {
    //   getFee(price);
    // }
  }

  initializePrice = () => {
    const { vehicle, initialize, dirrectAward, getFee } = this.props;

    const highestBid = vehicle.highest_bid ?? { amount: 0, id: 0 };

    const price = vehicle.vehicle_price || highestBid.amount;
    if (price > 0) {
      getFee(price);
    }
    initialize({
      awardPrice: dirrectAward ? '' : `${price}`,
      adjustment: ''
    });
  };

  charge = async () => {
    const {
      getFee,
      vehicle,
      adjustment,
      toggle,
      chargeAction,
      buyerId,
      modalId,
      awardPrice,
      dirrectAward,
      reset
    } = this.props;
    const numericAmount = typeof adjustment === 'string' ? getValue(adjustment) : adjustment;

    try {
      const data = {
        vehicle_id: vehicle.id,
        adjustment: numericAmount,
        buyer_id: buyerId || vehicle.buyer_id
      };

      if (dirrectAward) {
        data.awardPrice = getValue(awardPrice);
      }

      chargeAction(data);
      toggle(modalId);
      reset();
    } catch (e) {
      reset();
      toggle(modalId);
    }
  };

  render() {
    const {
      modalId,
      vehicle,
      fees,
      change,
      buyerForAward,
      adjustment,
      awardPrice,
      getFee,
      dirrectAward,
      reset
    } = this.props;
    const title = `Charge ${buyerForAward || 'buyer'}`;
    let numericAmount = typeof adjustment === 'string' ? getValue(adjustment) : adjustment;

    const fee = fees?.find(el => el.min_amount <= +awardPrice && +awardPrice <= el.max_amount)?.fee || 0;

    const total = numericAmount < 0 && fee < Math.abs(numericAmount) ? 0 : fee + numericAmount;

    return (
      <ModalWrapper
        modalId={modalId}
        title={title}
        submitText="Charge"
        handleSubmit={this.charge}
        submitting={(dirrectAward && !awardPrice) || !fee}
        cancelAdditionalAction={reset}
        onEnter={this.initializePrice}
      >
        <Typography variant="body2">Car: {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
        <Typography variant="body2">VIN: {vehicle.vin}</Typography>
        <Typography variant="body2">Seller: {vehicle.user.dealership_name}</Typography>
        <Typography variant="body2">
          Value Without Miles Adj:{' '}
          <NumberFormat value={vehicle.bluebook_value} displayType="text" thousandSeparator prefix="$" />
        </Typography>
        <Typography variant="body2">
          Blue Book® Lending Value:{' '}
          <NumberFormat value={vehicle.bluebook_mileage} displayType="text" thousandSeparator prefix="$" />
        </Typography>
        <StyledGrid container spacing={32} alignItems="center">
          <Grid item container justify="space-between" alignItems="center">
            <Typography variant="title">Award Price:</Typography>
            <StyledForm>
              <Field
                autoFocus
                name="awardPrice"
                placeholder="$0"
                label="$0"
                component={StyledFormFormattedField}
                thousandSeparator
                fixedDecimalScale
                prefix="$"
                customInput={FormInput}
                disableUnderline
                style={{ width: '100px' }}
                inputProps={{
                  disabled: !dirrectAward,
                  style: { textAlign: 'right' }
                }}
                onBlur={() => getFee(getValue(awardPrice || '0'))}
              />
            </StyledForm>
          </Grid>
          {dirrectAward && <StyledDivider />}
          <Grid item container justify="space-between">
            <Typography variant="title">Processing Fee: </Typography>
            <NumberFormat
              renderText={fv => <GreenNumbers variant="title">{fv}</GreenNumbers>}
              value={fee}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </Grid>
          <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
            <Typography variant="title">Price Adjustment:</Typography>
            <Grid container item style={{ gap: '16px', width: 'auto' }} alignItems="center">
              <StyledControlsWrapper item direction="column">
                <StyledControlButton
                  variant="fab"
                  color="secondary"
                  onClick={() => {
                    if (Number.isNaN(numericAmount)) {
                      numericAmount = 0;
                    }
                    change('adjustment', numericAmount + 1);
                  }}
                >
                  <StyledIcon>add</StyledIcon>
                </StyledControlButton>
                <StyledControlButton
                  variant="fab"
                  color="secondary"
                  onClick={() => {
                    if (Number.isNaN(numericAmount)) {
                      numericAmount = 0;
                    }
                    change('adjustment', numericAmount - 1);
                  }}
                >
                  <StyledIcon>remove</StyledIcon>
                </StyledControlButton>
              </StyledControlsWrapper>
              <StyledForm>
                <Field
                  name="adjustment"
                  placeholder="$0"
                  label="$0"
                  component={StyledFormFormattedField}
                  thousandSeparator
                  fixedDecimalScale
                  prefix="$"
                  customInput={FormInput}
                  disableUnderline
                  style={{ width: '100px' }}
                  inputProps={{ style: { textAlign: 'right' } }}
                />
              </StyledForm>
            </Grid>
          </Grid>
          <StyledDivider />
          <Grid item container justify="space-between">
            <Typography variant="title">TOTAL:</Typography>
            <NumberFormat
              renderText={fv => <GreenNumbers variant="title">{fv}</GreenNumbers>}
              value={total >= 0 ? total : fee}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </Grid>
        </StyledGrid>
      </ModalWrapper>
    );
  }
}

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  fees: state.financials.fees,
  adjustment: selector(state, 'adjustment'),
  awardPrice: selector(state, 'awardPrice')
});

const mapDispatchToProps = dispatch => ({
  getFee: price => dispatch(getBuyerFee({ price }).request),
  toggle: key => dispatch(modalsToggle(key))
});

export default compose(
  reduxForm({
    form: formName
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(ChargeBuyerModal);
