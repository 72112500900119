import React from 'react';
import { AccountInformation, NotificationSettings } from '../sections';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  > div:not(:first-child) {
    margin-top: 16px;
  }
`;

const AccountInformationTab = ({ user }) => (
  <SectionWrapper>
    <AccountInformation initialValues={user} />
    <NotificationSettings initialValues={user} />
  </SectionWrapper>
);

export default AccountInformationTab;
