import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { getFormValues } from "redux-form";
import moment from "moment/moment";
import { LOGS_COUNT_REQUEST, LOGS_REQUEST, FILTER_LOGS_REQUEST, fetchLogs, logsCount, filterLogs } from '../actions/logs';
import { Api } from '../utils';

const getSellerVehiclesQuery = state =>
  getFormValues('listLogsFilterForm')(state);

function* fetchLogsSaga({ payload }) {
  try {
    const query = yield select(getSellerVehiclesQuery);
    const filter = {
      ...payload,
      params: {
        ...query,
        ...payload.params
      }
    };

    if (query && query.created_at_from) {
      filter.created_at_from = moment(query.created_at_from)
        .utc()
        .format()
    }

    if (query && query.created_at_to) {
      filter.created_at_to = moment(query.created_at_to)
        .utc()
        .format()
    }
    const { data } = yield call(Api.logs.fetchLogs, filter);
    yield put(fetchLogs(data).success);
  } catch (e) {
    yield put(fetchLogs(e).failure);
  }
}

function* filterLogsSaga({ payload }) {
  try {
    const query = yield select(getSellerVehiclesQuery);
    const filter = {
      ...payload,
      params: {
        ...query,
        ...payload.params
      }
    };

    if (query && query.created_at_from) {
      filter.created_at_from = moment(query.created_at_from)
        .utc()
        .format()
    }

    if (query && query.created_at_to) {
      filter.created_at_to = moment(query.created_at_to)
        .utc()
        .format()
    }
    const { data } = yield call(Api.logs.fetchLogs, filter);
    yield put(filterLogs(data).success);
  } catch (e) {
    yield put(filterLogs(e).failure);
  }
}

function* fetchLogsCount({ payload }) {
  try {
    const { data } = yield call(Api.logs.fetchLogsCount, payload);
    yield put(logsCount(parseInt(data.data, 10) || 0).success);
  } catch (e) {
    yield put(logsCount(e).failure);
  }
}

export default function* () {
  yield all([takeLatest(LOGS_REQUEST, fetchLogsSaga)]);
  yield all([takeLatest(LOGS_COUNT_REQUEST, fetchLogsCount)]);
  yield all([takeLatest(FILTER_LOGS_REQUEST, filterLogsSaga)]);
}
