import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Popover, withStyles } from '@material-ui/core';
import { Calendar } from 'material-ui-pickers';
import moment from 'moment';
import styled from 'styled-components';
import { CalendarIcon } from '../../../Icons/CalendarIcon';
import { DATE_FORMAT } from '../../../../constants';

const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    padding: 12px 12px 0 12px;
  }

  & .date-checkbox {
    padding: 0px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 22px;
  padding-bottom: 24px;
  border-bottom: 1px solid #757575;
`;

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const Controls = styled.div`
  display: flex;
  gap: 6px;
`;

const Label = styled.div<{ isEditing: boolean }>`
  display: flex;
  cursor: ${props => (props.isEditing ? 'pointer' : 'default')};
  gap: 8px;
  align-items: center;
  background: ${props => (props.isEditing ? '#f5f5f5' : 'transparent')};
  padding: 8px;
  width: 90%;
  min-width: 80px;
`;

const DayWrapper = styled.div``;

const styles = {};

const StyledButton = styled(Button)`
  border-radius: 6px;
`;

const CustomDatePickerWithCheckbox = ({
  value,
  isEditing = false,
  onChange = () => {},
  styles: customStyles = {},
  withCurrentWeekCheckbox = false,
  disableDates = true
}: any) => {
  const [date, setDate] = useState(value ? moment.utc(value) : moment());
  const [isAnyDayFlag, setIsAnyDayFlag] = useState(!value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentWeekFlag, setCurrentWeekFlag] = useState(false);

  const handleClick = (event: any) => {
    if (isEditing) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleAnyDayFlag = () => {
    setIsAnyDayFlag(!isAnyDayFlag);
    setCurrentWeekFlag(false);
  };

  const handleCurrentWeekFlag = () => {
    setCurrentWeekFlag(!currentWeekFlag);
    setIsAnyDayFlag(false);
  };

  const close = () => setAnchorEl(null);

  const isOpen = Boolean(anchorEl);
  const handleApply = () => {
    if (isAnyDayFlag) {
      onChange(null);
    } else {
      onChange(date, currentWeekFlag);
    }

    close();
  };

  const displayValue = (() => {
    if (currentWeekFlag) return 'Current Week';
    return value ? moment.utc(value).format(DATE_FORMAT) : 'Any Day';
  })();

  return (
    <div>
      <Label isEditing={isEditing} onClick={handleClick} style={{ ...customStyles }}>
        <CalendarIcon />
        <span>{displayValue}</span>
      </Label>
      <Popover
        onClose={close}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PopoverWrapper>
          <Calendar
            renderDay={(
              // unused options are needed
              day: moment.Moment,
              selectedDate: moment.Moment,
              dayInCurrentMonth: boolean,
              dayComponent: any
            ) => {
              return <DayWrapper>{dayComponent}</DayWrapper>;
            }}
            date={date}
            disableFuture={isAnyDayFlag && !disableDates}
            disablePast={!disableDates}
            minDate={new Date('2024-01-01')}
            maxDate={new Date('2025-01-01')}
            onChange={setDate}
          />
          <CheckboxWrapper>
            <Checkbox checked={isAnyDayFlag} onChange={handleAnyDayFlag} className="date-checkbox" />
            <span>Any day</span>
            {withCurrentWeekCheckbox && (
              <>
                <Checkbox checked={currentWeekFlag} onChange={handleCurrentWeekFlag} className="date-checkbox" />
                <span>Current week</span>
              </>
            )}
          </CheckboxWrapper>
          <ControlsRow>
            <span>{date.format(DATE_FORMAT)}</span>
            <Controls>
              <StyledButton variant="contained" onClick={close}>
                Cancel
              </StyledButton>
              <StyledButton variant="contained" color="primary" onClick={handleApply}>
                Apply
              </StyledButton>
            </Controls>
          </ControlsRow>
        </PopoverWrapper>
      </Popover>
    </div>
  );
};

export default withStyles(styles)(CustomDatePickerWithCheckbox);
