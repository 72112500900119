import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { MenuItem } from '@material-ui/core';
import { StyledGrid } from '../components';
import { updateProfile } from '../../../../actions/user';
import { FormTextField } from '../../../../components';
import { maxLength, required } from '../../../../utils/validators';
import { ADDRESS_MAX_STRING_LENGTH, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import STATES from '../../../../constants/states';
import scrollToError from '../../../../utils/scrollToError';
import { compose } from 'redux';
import { connect } from 'react-redux';

const addressMaxStringLength = maxLength(ADDRESS_MAX_STRING_LENGTH);
const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);
const maxAlternativeAddressLength = maxLength(20);
const zipCodeValidation = value =>
  value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value) ? 'ZIP Code have invalid format' : undefined;

const AddressSectionForm = ({ handleSubmit, InputProps, form }) => {
  const fieldPrefix =
    {
      alternativeAddressSectionForm: 'alternativeAddress.',
      addressSectionForm: ''
    }[form] ?? '';

  return (
    <form onSubmit={handleSubmit}>
      <StyledGrid container spacing={24}>
        {form === 'alternativeAddressSectionForm' && (
          <StyledGrid item xs={12}>
            <Field
              name={`${fieldPrefix}purpose`}
              component={FormTextField}
              label="Address Purpose"
              fullWidth
              InputProps={InputProps}
              validate={[required, maxAlternativeAddressLength]}
            />
          </StyledGrid>
        )}
        <StyledGrid item xs={12}>
          <Field
            name={`${fieldPrefix}street`}
            component={FormTextField}
            label="Street Address"
            fullWidth
            InputProps={InputProps}
            validate={[required, addressMaxStringLength]}
          />
        </StyledGrid>
        <StyledGrid item xs={12} sm={4}>
          <Field
            name={`${fieldPrefix}city`}
            component={FormTextField}
            label="City"
            fullWidth
            InputProps={InputProps}
            validate={[required, maxStringLength]}
          />
        </StyledGrid>
        <StyledGrid item xs={12} sm={4}>
          <Field
            name={`${fieldPrefix}state`}
            component={FormTextField}
            label="State"
            select
            fullWidth
            InputProps={InputProps}
            validate={required}
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 400
                }
              }
            }}
          >
            {STATES.map(state => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </Field>
        </StyledGrid>
        <StyledGrid item xs={12} sm={4}>
          <Field
            name={`${fieldPrefix}zip`}
            component={FormTextField}
            label="ZIP"
            fullWidth
            InputProps={InputProps}
            validate={[required, zipCodeValidation]}
          />
        </StyledGrid>
      </StyledGrid>
    </form>
  );
};

const mapStateToProps = (state, props) => {
  return {
    form: props.name
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    onSubmit: (values, dispatch) =>
      new Promise((resolve, reject) => dispatch(updateProfile({ payload: values, resolve, reject }).request)),
    enableReinitialize: true,
    onSubmitFail: scrollToError
  })
)(AddressSectionForm);
