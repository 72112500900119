import React, { Component, useRef, useState } from 'react';
import { Grid, Typography, Button, Icon, Popover } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { reduxForm, Field, formValueSelector, SubmissionError } from 'redux-form';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import SectionTitle from '../../components/SectionTitle';
import { FormFormattedField, FormError, FormInput } from '../../../../../components/Form';
import { vehicles } from '../../../../../utils/api';
import apiSlice from '../../../../../services/api';
import { useOfferAuctionBidsInfo } from '../../../../../hooks';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { usePlaceOfferBidMutation } from 'services/api/offer-auction';

const StyledDescription = styled(Typography)`
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledProxyBidAmount = styled(Typography)`
  display: inline;
`;

const StyledForm = styled.form`
  background: #f5f5f5;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 16px;
  div {
    flex-grow: 1;
  }
`;

const StyledSubmitButton = styled(Button)`
  margin: 8px 8px 8px 8px;
  min-width: 128px;
`;

const StyledControlButton = styled(Button)`
  min-height: 0;
  width: 24px;
  height: 24px;
  font-size: 16px;
  box-shadow: none;
  margin: 0 0 0 8px;
`;

const StyledIcon = styled(Icon)`
  font-size: 16px;
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  font-size: 16px;
`;

const StyledButtonsHolder = styled.div`
  min-width: 70px;
  display: flex;
  justify-content: flex-end;
`;

const StyledPopoverBody = styled.div`
  padding: 5px 10px;
  max-width: 100%;
  width: 290px;
  font-size: 14px;
`;

const getValue = (value = '') => parseInt(value.replace(/[\D]*([\d,]*)(.*)/g, '$1').replace(',', ''), 10);

const isFloored = value => {
  const flooredNumber = Math.floor(typeof value === 'string' ? getValue(value) / 100 : value / 100) * 100;
  const formattedNumber = typeof value === 'string' ? getValue(value) : value;
  return flooredNumber !== formattedNumber;
};

const validate = values => {
  const errors = {};
  if (values.amount && isFloored(values.amount)) {
    // eslint-disable-next-line
    errors._error = 'Use bidding increments of min bid amount';
  }
  return errors;
};

const OfferBiddingComponent = ({ bidIncrement, amount, handleSubmit, vehicleId, change }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const formattedAmount = typeof amount === 'string' ? getValue(amount) : amount;

  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicleId);
  const [placeOfferBid] = usePlaceOfferBidMutation();
  const { minBidOfferAmount: minAmount, isProxyOffer } = useOfferAuctionBidsInfo(offerAuction);

  const isSubmitDisabled = formattedAmount < minAmount;

  const onSubmit = (values, dispatch, props) => {
    const formattedNumber = typeof values.amount === 'string' ? getValue(values.amount) : values.amount;
    const bidAmount = formattedNumber - minAmount;
    if (isFloored(values.amount)) {
      throw new SubmissionError({
        _error: `Use bidding increments of $${bidIncrement}`
      });
    }
    if (formattedNumber < minAmount) {
      throw new SubmissionError({
        _error: 'Bid is lesser than minimum bid amount!'
      });
    }
    if (bidAmount > 0 && bidAmount < bidIncrement) {
      throw new SubmissionError({
        _error: `Minimum bid amount is ${minAmount + bidIncrement}`
      });
    }
    placeOfferBid({
      vehicle_id: vehicleId,
      amount: formattedNumber
    })
      .then(() => {
        props.change('amount', '');
      })
      .catch(({ response }) => {
        if (response.data && response.data.message) {
          throw new SubmissionError({
            _error: response.data.message
          });
        }
        throw new SubmissionError({
          _error: 'Server exception'
        });
      });
    // dispatch hook here
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <SectionTitle noGutterBottom>
        {isProxyOffer ? (
          <>
            Increase Max Offer{' '}
            <StyledProxyBidAmount component="span">
              (from{' '}
              <NumberFormat displayType="text" prefix="$" thousandSeparator value={Number(minAmount) - bidIncrement} />)
            </StyledProxyBidAmount>
          </>
        ) : (
          'Enter Max Offer'
        )}
      </SectionTitle>
      <StyledDescription onClick={e => setAnchorEl(e.currentTarget)}>How Max Offer Works</StyledDescription>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <StyledPopoverBody>
          <p>Your max bid cannot be seen by the seller.</p>
          <p>
            {`By entering your Max Bid, the system will auto bid for you in $${bidIncrement}
              increments up to your max bid (while the timer is active)`}
            .
          </p>
        </StyledPopoverBody>
      </Popover>
      <StyledForm onSubmit={handleSubmit(onSubmit)} id="bidding">
        <Field
          name="amount"
          placeholder={`$${minAmount} min`}
          label={`$${minAmount} min`}
          component={StyledFormFormattedField}
          thousandSeparator
          allowNegative={false}
          fixedDecimalScale
          prefix="$"
          customInput={FormInput}
          disableUnderline
        />
        <StyledButtonsHolder>
          <StyledControlButton
            variant="fab"
            color="secondary"
            onClick={() => {
              const newValue = formattedAmount - bidIncrement;
              if (newValue >= 0) {
                change('amount', newValue);
              }
            }}
            disabled={formattedAmount - bidIncrement < 0 || formattedAmount < minAmount}
          >
            <StyledIcon>remove</StyledIcon>
          </StyledControlButton>
          <StyledControlButton
            variant="fab"
            color="secondary"
            onClick={() => {
              if (formattedAmount) {
                return change('amount', formattedAmount + bidIncrement);
              }
              return change('amount', minAmount);
            }}
          >
            <StyledIcon>add</StyledIcon>
          </StyledControlButton>
        </StyledButtonsHolder>
        <StyledSubmitButton
          type="submit"
          color={isSubmitDisabled ? 'secondary' : 'primary'}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          Submit
        </StyledSubmitButton>
      </StyledForm>
      {/* {dirty && error && this.renderError(error)} */}
    </Grid>
  );
};

const selector = formValueSelector('offerBiddingForm');

export default compose(
  reduxForm({
    form: 'offerBiddingForm',
    validate,
    enableReinitialize: true
  }),
  connect(state => {
    const amount = selector(state, 'amount');
    return {
      amount: amount === '' ? 0 : amount
    };
  })
)(OfferBiddingComponent);
