import React from 'react';
import { Field } from 'react-final-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import styled from 'styled-components';
import { required } from '../../../../utils/validators';
import { FormRadioGroup } from 'components';

const StyledFormControlLabel = styled(FormControlLabel)`
  & > span:last-child {
    font-size: 12px;
    line-height: 14.06px;
    color: #939393;
    margin-left: -6px;
  }
`;

const BuyFeeMethodPicker = () => (
  <Field name="buyFeeMethod" component={FormRadioGroup} validate={required}>
    <StyledFormControlLabel value="credit_card" control={<Radio color="primary" />} label="Credit Card" error />
    <StyledFormControlLabel value="ach_flooring" control={<Radio color="primary" />} label="ACH/Flooring" />
  </Field>
);

export default BuyFeeMethodPicker;
