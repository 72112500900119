import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, CircularProgress, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createVehicleSetTmpImage, snackShow } from '../../actions';
import {
  vehicleImageThumb,
  AVAILABLE_VEHICLE_IMAGE_TYPES,
  vehicleImageOriginal
} from '../../constants';

const styles = () => ({
  labelBlock: {
    boxShadow: 'none',
    width: '100%',
    background: '#DCDCDC',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 230,
    alignItems: 'center',
    padding: 16
  },
  imageBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    height: 230,
    padding: 8
  },
  iconDelete: {
    color: '#fff',
    textShadow: '0 1px 1px rgba(0,0,0,0.7)'
  },
  textStyle: {
    textTransform: 'none'
  },
  fileHolder: {
    display: 'none'
  },
  loadingHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: 'rgba(220, 220, 220, 0.5)'
  }
});

class PictureUploadButton extends Component {
  state = {
    loading: false
  };

  handleChangeFile = (e, itemKey) => {
    const { updateImg, showSnack } = this.props;
    e.preventDefault();

    const that = this;

    that.setState({ loading: true });

    const file = e.target.files[0];
    if (AVAILABLE_VEHICLE_IMAGE_TYPES.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const tempImg = new Image();
        tempImg.src = reader.result;

        tempImg.onload = function() {
          const { width: thumbWidth, height: thumbHeight } = vehicleImageThumb;
          const { width: origWidth, height: origHeight } = vehicleImageOriginal;
          let { width: tempW, height: tempH } = tempImg;
          let { width: newW, height: newH } = tempImg;

          // thumb
          let newX = 0;
          let newY = 0;
          if (tempW <= tempH) {
            tempH *= thumbWidth / tempW;
            newY = 0 - (tempH - thumbHeight) / 2;
            tempW = thumbWidth;
          } else {
            tempW *= thumbHeight / tempH;
            newX = 0 - (tempW - thumbWidth) / 2;
            tempH = thumbHeight;
          }

          const canvas = document.createElement('canvas');
          canvas.id = 'canvas';
          canvas.width = thumbWidth;
          canvas.height = thumbHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(this, newX, newY, tempW, tempH);
          const dataURL = canvas.toDataURL('image/jpeg');

          // orig
          if (newW > newH) {
            if (newW > origWidth) {
              newH *= origWidth / newW;
              newW = origWidth;
            }
          } else if (newH > origHeight) {
            newW *= origHeight / newH;
            newH = origHeight;
          }
          const canvasOrig = document.createElement('canvas');
          canvasOrig.id = 'canvasOrig';
          canvasOrig.width = newW;
          canvasOrig.height = newH;
          const ctxOrig = canvasOrig.getContext('2d');
          ctxOrig.drawImage(this, 0, 0, newW, newH);

          const dataURLOrig = canvasOrig.toDataURL('image/jpeg');

          that.setState({ loading: false });

          // updateImg({ key: itemKey, file: reader.result, thumb: dataURL })
          updateImg({
            key: itemKey,
            file: dataURLOrig,
            thumb: dataURL,
            isNew: true
          });
        };
      };

      reader.readAsDataURL(file);
    } else {
      showSnack({ message: 'Invalid image', type: 'error' });
      that.setState({ loading: false });
    }
  };

  handleRemoveImage = (e, itemKey) => {
    const { updateImg } = this.props;
    updateImg({ key: itemKey });
  };

  render() {
    const { classes, label, itemKey, tmpImages } = this.props;
    const { loading } = this.state;
    if (loading) {
      return (
        <div className={classes.loadingHolder}>
          <CircularProgress size={30} color="secondary" />
        </div>
      );
    }
    return tmpImages[itemKey] &&
      tmpImages[itemKey].file &&
      tmpImages[itemKey].thumb ? (
        <span
          className={classes.imageBlock}
          style={{
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${tmpImages[itemKey].thumb})`
        }}
        >
          <IconButton
            className={classes.iconDelete}
            onClick={e => this.handleRemoveImage(e, itemKey)}
          >
            <i className="material-icons">delete</i>
          </IconButton>
        </span>
    ) : (
      <label className={classes.labelBlock}>
        <span />
        <i className="material-icons">add_photo_alternate</i>
        <Typography className={classes.textStyle}>{label}</Typography>
        <input
          type="file"
          className={classes.fileHolder}
          onChange={e => this.handleChangeFile(e, itemKey)}
        />
      </label>
    );
  }
}

const mapStateToProps = state => ({
  tmpImages: state.vehicles.tmpImages
});

const mapDispatchToProps = dispatch => ({
  updateImg: data => dispatch(createVehicleSetTmpImage(data)),
  showSnack: message => dispatch(snackShow(message))
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PictureUploadButton)
);
