import { AppBar, Toolbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { LoaderModal, PageWrapper } from '../../../components';
import { useGetFormControlButtons } from '../hooks/useGetFormControlButtons';

const TypographyBreadcrumbs = styled(Typography)`
  font-size: 16px;
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background: #fff;
  top: 72px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Header = () => {
  const { CancelButton, SubmitButton, SubmitForReviewButton } = useGetFormControlButtons();

  return (
    <StyledAppBar position="fixed" color="default">
      <PageWrapper className="">
        <Toolbar disableGutters>
          <TypographyBreadcrumbs variant="title">Upload Vehicle</TypographyBreadcrumbs>
          <LoaderModal modalId="loaderModal" loaderText="Loading" />
          <ButtonContainer>
            <CancelButton renderMobileIcon />
            <SubmitForReviewButton renderMobileIcon />
            <SubmitButton renderMobileIcon />
          </ButtonContainer>
        </Toolbar>
      </PageWrapper>
    </StyledAppBar>
  );
};
