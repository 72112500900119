import React from 'react';
import { Field } from 'react-final-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import styled from 'styled-components';
import InlineFormRadioGroup from './InlineFormRadioGroup';
import { required } from '../../../../utils/validators';
import { SIGNUP_ACH_METHODS } from '../../../../constants';
import { FormRadioGroup } from 'components';

const StyledFormControlLabel = styled(FormControlLabel)`
  & > span:last-child {
    font-size: 12px;
    line-height: 14.06px;
    color: #939393;
    margin-left: -6px;
  }
`;

const AchMethodPicker = () => (
  <>
    <Field name="ach_method" component={FormRadioGroup} validate={required}>
      <StyledFormControlLabel
        value={SIGNUP_ACH_METHODS.ENTER_ACH_INFORMATION}
        control={<Radio color="primary" />}
        label="Enter ACH Information"
        error
      />
      <StyledFormControlLabel
        value={SIGNUP_ACH_METHODS.UPLOAD_VOIDED_CHECK}
        control={<Radio color="primary" />}
        label="Upload Voided Check"
      />
    </Field>
  </>
);

export default AchMethodPicker;
