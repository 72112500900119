import styled from 'styled-components';
import { RequestsByDay, useDeleteVehicleRequestMutation } from 'services/api/list-vehicles';
import moment from 'moment';
import { SellerButton } from '../SellerButton';
import { BlockIcon } from '../../../Icons/BlockIcon';
import { DropZone } from '../DropZone';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useOptimisticDayBlockRemoval } from '../../hooks/useOptimisticUpdates';
import ConfirmModal from '../../../Modals/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle } from '../../../../actions';
import { useSelectedWeekType } from '../../../../services/listVehiclesSlice/listVehiclesSlice';

const getLabelFromDayNumber = (dayNumber: number, weekStartOffset = 0) => {
  if (dayNumber === 0) return 'Any Day';
  const startOfTheWeek = moment()
    .locale('en-us')
    .startOf('week')
    .add({ days: weekStartOffset });
  const date = startOfTheWeek.add(dayNumber - 1, 'days');
  return date.format('ddd MM/DD YYYY');
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SellerButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Label = styled.div`
  color: #757575;
  font-size: 12px;
`;

const NoDataText = styled.div`
  display: flex;
  color: #a2a2a2;
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const DayBlock = ({ data, dayNumber }: { data: RequestsByDay[]; dayNumber: number }) => {
  const [deleteListRequest] = useDeleteVehicleRequestMutation();

  const optimisticRemoval = useOptimisticDayBlockRemoval();

  const [activeRequestId, setActiveRequestId] = useState<null | number>(null);
  const ref = useRef<any>(null);

  const modals = useSelector((state: any) => state.modals);

  const selectedWeekType = useSelectedWeekType();
  const weekStartOffset = (() => {
    if (selectedWeekType === 'next') return 7;
    if (selectedWeekType === 'previous') return -7;
    return 0;
  })();

  useOnClickOutside(ref, () => {
    const { open, modalId } = modals || {};
    if (open && modalId?.startsWith('delete-store')) return;
    setActiveRequestId(null);
  });

  const dispatch = useDispatch();

  return (
    <DropZone id={`dayBlock-${dayNumber}`}>
      <Wrapper>
        <Label>{getLabelFromDayNumber(dayNumber, weekStartOffset)}</Label>
        <SellerButtonsWrapper ref={ref} data-no-dnd="true">
          {data.map(el => {
            const isActive = el.id === activeRequestId;
            const deleteAction = () => {
              optimisticRemoval(el.id, dayNumber);
              deleteListRequest(el.id);
            };
            const openDeleteModal = () => {
              dispatch(modalsToggle(`delete-store-${el.id}`));
            };
            const setActive = () => setActiveRequestId(el.id);

            return (
              <>
                <SellerButton
                  key={el.id}
                  id={el.id}
                  seller={{ ...el.user, vehiclesRange: el.number_of_vehicles }}
                  status={'draft'}
                  dragData={{ ...el, dayNumber }}
                  deleteAction={isActive ? openDeleteModal : null}
                  clickAction={setActive}
                  isActive={isActive}
                />
                {isActive && (
                  <ConfirmModal
                    title="Store will be permanently deleted for admin and seller"
                    handleSubmit={deleteAction}
                    submitText={'Confirm'}
                    modalId={`delete-store-${el.id}`}
                  />
                )}
              </>
            );
          })}
          {data.length === 0 && (
            <NoDataText>
              <BlockIcon />
              <span>No Vehicles for today</span>
            </NoDataText>
          )}
        </SellerButtonsWrapper>
      </Wrapper>
    </DropZone>
  );
};

const RenderSellerButton = ({ el, dayNumber }: { el: RequestsByDay; dayNumber: number }) => {
  const [deleteListRequest] = useDeleteVehicleRequestMutation();
  const deleteAction = () => deleteListRequest(el.id);
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    console.log('toggleActive triggered');
    setIsActive(!isActive);
  };

  return (
    <SellerButton
      id={el.id}
      seller={{ ...el.user, vehiclesRange: el.number_of_vehicles }}
      status={'draft'}
      dragData={{ ...el, dayNumber }}
      deleteAction={isActive ? deleteAction : null}
      clickAction={toggleActive}
      isActive={isActive}
    />
  );
};
