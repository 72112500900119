import React from 'react';
import styled from 'styled-components';
import { Typography, Button, MenuItem } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FormResetButton, FormTextField } from '../../../../components/Form';
import { AuctionFilter } from '../../../../components/Filters';
import { filterVehicleSalesHistory } from '../../../../actions/vehicles';
import { Form, Field, FormSpy } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useGetSalesHistoryQuery } from 'services/api/sales';
import { setFilters } from 'services/salesHistorySlice/salesHistorySlice';
import { debounce } from 'utils';
import { useScreen } from 'usehooks-ts';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1180px) {
    flex-direction: column;
    > div > div {
      width: 100%;
    }
    > button {
      width: 100%;
      margin-top: 10px;
    }
  }
  > div > div {
    min-width: 160px;
  }
`;

const StyledButton = styled(Button)`
  && {
    h2 {
      color: white;
      font-weight: 500;
      font-size: 16px;
    }

    margin-bottom: ${props => (props.isMobile ? '16px' : 0)};
  }
`;

const StyledFieldWrapper = styled.div`
  && {
    display: flex;
    align-items: flex-end;
    > div:not(:last-child) {
      margin-right: 15px;
    }
    button {
      margin: 0 0 5px 5px;
    }
    @media (max-width: 1180px) {
      && {
        flex-direction: column;
        width: 100%;
        > div {
          margin-right: 0;
          margin-top: 10px;
        }
        button {
          margin-top: 10px;
        }
      }
    }
  }
`;

const toUpperCase = value => value && value.toUpperCase();

const Filter = () => {
  const history = useHistory();

  const onSubmit = () => {};
  const dispatch = useDispatch();

  const { page, perPage, filters } = useSelector(state => state.salesHistory);
  const { data } = useGetSalesHistoryQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const makes = data?.makes || [];
  const models = data?.models || [];

  const handleChange = debounce(({ values }) => {
    dispatch(setFilters(values));
  }, 500);

  const navigateToSellerInvoices = () => history.push('/seller/invoices');

  const { width = 0 } = useScreen();
  const isMobile = width < 567;

  return (
    <StyledFilterWrapper>
      {!isMobile && (
        <Form onSubmit={onSubmit}>
          {props => (
            <StyledFieldWrapper>
              <Field component={FormTextField} name="vin" label="VIN Search" normalize={toUpperCase} />
              <Field component={FormTextField} name="make" label="Filter by Make" select>
                <MenuItem value="">All</MenuItem>
                {makes.map((make, index) => (
                  <MenuItem key={index} value={make}>
                    {make}
                  </MenuItem>
                ))}
              </Field>
              {models.length !== 0 && (
                <Field component={FormTextField} name="model" label="Filter by Model" select>
                  <MenuItem value="">All</MenuItem>
                  {models.map((model, index) => (
                    <MenuItem key={index} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Field>
              )}
              <Field component={FormTextField} name="status" label="Filter by Bid Status" select>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="undecided">Undecided</MenuItem>
                <MenuItem value="pulled">Pulled</MenuItem>
                <MenuItem value="countered">Countered</MenuItem>
                <MenuItem value="processing">Processing</MenuItem>
                <MenuItem value="awarded">Awarded</MenuItem>
              </Field>
              <AuctionFilter formType="final-form" />
              {props.dirty && <FormResetButton onClick={props.form.reset} />}
              <FormSpy subscription={{ values: true }} onChange={handleChange} />
            </StyledFieldWrapper>
          )}
        </Form>
      )}

      <StyledButton isMobile={isMobile} onClick={() => navigateToSellerInvoices()} variant="contained" color="primary">
        <Typography variant="title">Invoices</Typography>
      </StyledButton>
    </StyledFilterWrapper>
  );
};

const mapStateToProps = state => ({
  makes: state.vehicles.salesHistoryData.makes,
  models: state.vehicles.salesHistoryData.models
});

const mapDispatchToProps = dispatch => ({
  navigateToSellerInvoices: () => dispatch(push('/seller/invoices'))
});

export default Filter;
