import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyledGrid = styled(Grid)`
  && {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

const FieldWrapper = ({ children, ...restProps }) => (
  <StyledGrid item xs={12} {...restProps}>
    {children}
  </StyledGrid>
);

export default FieldWrapper;
