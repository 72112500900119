import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ScrollableBlock extends Component {
  componentDidMount() {
    const { handleScroll } = this.props;
    window.addEventListener('scroll', handleScroll);
  }

  componentWillUnmount() {
    const { handleScroll } = this.props;
    window.removeEventListener('scroll', handleScroll);
  }

  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

ScrollableBlock.propTypes = {
  children: PropTypes.any.isRequired,
  handleScroll: PropTypes.func.isRequired
};

export default ScrollableBlock;
