import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { PurchaseOptionsForm } from '../forms';

class PurchaseOptionsSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    const { purchase_options } = initialValues[initialValues.role];
    const extendedInitialValues = {
      purchase_option: (purchase_options || []).reduce(
        (acc, option) => ({
          ...acc,
          [option.option_name]: true
        }),
        {}
      )
    };
    return (
      <SectionBlock
        title="Purchase Options"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <PurchaseOptionsForm
          InputProps={InputProps}
          initialValues={extendedInitialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('purchaseOptionsForm')),
  resetForm: () => dispatch(reset('purchaseOptionsForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(PurchaseOptionsSection);
