import { useSelector } from 'react-redux';
import { useGetMySalesQuery } from '../../../../../services/api/sales';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';

export const useSalesOfferAuction = (vehicleId: number) => {
  const { page, perPage, filters } = useSelector((state: any) => state.mySales);
  const { data: mySales } = useGetMySalesQuery({ limit: perPage, offset: page, ...filters });
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: mySales?.rows?.ids as number[] ?? [] },
    {
      skip: !mySales?.rows?.ids || mySales.rows.ids.length === 0
      // refetchOnMountOrArgChange: true
    }
  );

  const offerAuction = offerAuctions?.entities?.[vehicleId] ?? null;
  return offerAuction
};
