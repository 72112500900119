import styled from 'styled-components';

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #000;
`;

export const InfoValue = styled.span<{ size?: number; black?: boolean }>`
  color: ${props => (props.black ? '#000' : '#757575')};
  font-size: ${({ size = 12 }) => `${size}px`};
  font-weight: 500;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;
