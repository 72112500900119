import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ImageField } from '../api/upload-vehicle';

export type LocalImage = {
  id: string;
  serverId: number | null;
  file: File;
  isUploaded: boolean
}

export type FieldLocalImage = ImageField & {
  file?: File
  localId?: string;
}

const generateId = () => {
  if (self && self.crypto && typeof self.crypto.randomUUID === 'function') {
    return self.crypto.randomUUID()
  }

  return String(Math.random())
}

export type DragImage = {
  file?: File;
  url?: string;
  type: 'form' | 'local'
}

type AssignVehicleImagesState = {
  openedAssignImagesDialog: string | null;
  activeDragImage: DragImage | null
  localImages: LocalImage[];
  localFields: FieldLocalImage[]
}

const initialState: AssignVehicleImagesState = {
  openedAssignImagesDialog: null,
  activeDragImage: null,
  localImages: [],
  localFields: []
};

const createLocalImage = (file: File) => {
  return {
    id: generateId(),
    serverId: null,
    file,
    isUploaded: false
  }
}

const assignVehicleImagesSlice = createSlice({
  name: 'assignVehicleImages',
  initialState,
  reducers: {
    openAssignImageDialog: (state, action: PayloadAction<{ sectionId: string; formImages: ImageField[]; localImages?: File[] | FileList }>) => {
      const { sectionId, localImages, formImages } = action.payload
      state.openedAssignImagesDialog = sectionId
      state.localImages = localImages ? Array.from(localImages).map(createLocalImage) : []
      state.activeDragImage = null
      state.localFields = [...formImages]
    },
    closeAssignImageDialog: (state) => {
      state.openedAssignImagesDialog = null
      state.localImages = []
      state.activeDragImage = null
      state.localFields = []
    },
    addLocalImages: (state, action: PayloadAction<File[] | FileList>) => {
      state.localImages = [...state.localImages, ...Array.from(action.payload).map(createLocalImage)]
    },
    removeLocalImage: (state, action: PayloadAction<number>) => {
      state.localImages.splice(action.payload, 1)
    },
    uploadLocalImage: (state, action: PayloadAction<{ localId: string; fieldImageType: string; }>) => {
      const image = state.localImages.find(i => i.id === action.payload.localId)
      const formImage = state.localFields.find(i => i.type === action.payload.fieldImageType)
      if (!image || !formImage) return
      image.isUploaded = true
      formImage.localId = image.id
      formImage.file = image.file
    },
    setActiveDragImage: (state, action: PayloadAction<DragImage>) => {
      const image = action.payload
      state.activeDragImage = image;
    },
    removeActiveDragImage: (state) => {
      state.activeDragImage = null
    },
    setDamaged: (state, action: PayloadAction<{ index: number; isDamaged: boolean}>) => {
      const {index, isDamaged} = action.payload;
      const imageField = state.localFields[index];
      if (!imageField) return;
      imageField.is_damaged = isDamaged
    },
    removeImageField: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const imageField = state.localFields[index];
      if (!imageField) return;
      const localImage = state.localImages.find(i => i.id === imageField.localId)
      if (localImage) {
        localImage.isUploaded = false;
      }
      if (imageField.type === 'user_defined') {
        state.localFields.splice(index, 1)
      } else {
        state.localFields[index].file = undefined
        state.localFields[index].url = ''
        state.localFields[index].notes = ''
        state.localFields[index].url_thumb = ''
        state.localFields[index].is_damaged = false
      }
    },
    setFieldImageFile: (state, action: PayloadAction<{ index: number; file?: File }>) => {
      const { index, file } = action.payload;
      const imageField = state.localFields[index];
      if (!imageField) return;
      imageField.file = file
    },
    replaceFormImages: (state, action: PayloadAction<FieldLocalImage[]>) => {
      state.localFields = action.payload
    }
  }
});

export const useOpenedAssignVehicleImageDialog = () => {
  const openedAssignVehicleDialog = useSelector<{ assignVehicleImages: AssignVehicleImagesState }, string | null>((state) => state.assignVehicleImages.openedAssignImagesDialog)
  return openedAssignVehicleDialog
}

export const useLocalImages = () => {
  const images = useSelector<{ assignVehicleImages: AssignVehicleImagesState }, LocalImage[]>((state) => state.assignVehicleImages.localImages)
  return images
}

export const useFormImages = () => {
  const formImages = useSelector<{ assignVehicleImages: AssignVehicleImagesState }, FieldLocalImage[]>((state) => state.assignVehicleImages.localFields)
  return formImages
}

export const useActiveDragImage = () => {
  const image = useSelector<{ assignVehicleImages: AssignVehicleImagesState }, DragImage | null>((state) => state.assignVehicleImages.activeDragImage)
  return image
}

export const {
  openAssignImageDialog,
  closeAssignImageDialog,
  addLocalImages,
  removeLocalImage,
  setActiveDragImage,
  uploadLocalImage,
  removeActiveDragImage,
  setDamaged,
  removeImageField,
  setFieldImageFile,
  replaceFormImages
} = assignVehicleImagesSlice.actions
export default assignVehicleImagesSlice;
