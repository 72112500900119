/* eslint-disable no-await-in-loop,guard-for-in,no-restricted-syntax */
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
// import { SubmissionError, Field, reduxForm, formValueSelector } from 'redux-form';
import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import styled from 'styled-components';
import { OfficePhoneLabel, StepControl, FormFileField, FormWrapper, StepperTitle, FieldWrapper } from '../components';
import scrollToError from '../../../../utils/scrollToError';
import { composeValidators, required, validateDoc } from '../../../../utils/validators';
import { ONBOARDING_STEPS } from '../../../../constants';
import { user } from '../../../../utils/api';
import DocumentsUploadMethodPicker from '../components/DocumentsUploadMethodPicker';
import InfoAlert from '../components/InfoAlert';
import { Form } from 'react-final-form';
import { FormRadioGroup } from 'components';
import { OutlinedFormFileUpload } from 'components/Form/FinalForm/OutlinedFormFileUpload';
import { useOnboardingMutation } from 'services/api/auth';

const validFiles = ['dealer_bond', 'dmv_license', 'resale_certificate', 'city_business_license'];

const requiredFile = value => (!value || !value.type ? 'Required' : undefined);

const StyledFormWrapper = styled.form`
  width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & > span:last-child {
    font-size: 12px;
    line-height: 14.06px;
    color: #939393;
    margin-left: -6px;
  }
`;

const StyledUL = styled.ul`
  & li::marker {
    color: #757575;
  }

  color: #757575;
  padding-left: 20px;
`;

const fileValidation = composeValidators(required, validateDoc);

const FourthBuyerStep = ({ prevStep, nextStep }) => {
  const [onboarding] = useOnboardingMutation();

  const onSubmit = async values => {
    const { documentsUploadMethod, ...files } = values;
    if (documentsUploadMethod === 'email') {
      await onboarding({
        last_step: ONBOARDING_STEPS.FIFTH_BUYER_STEP
      });
      return nextStep();
    }

    const payload = {};

    for (const [key, values] of Object.entries(files)) {
      payload[key] = values[0];
    }

    await onboarding({
      ...payload,
      last_step: ONBOARDING_STEPS.FIFTH_BUYER_STEP
    });

    nextStep();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        documentsUploadMethod: '',
        dmv_license: '',
        resale_certificate: '',
        city_business_license: '',
        dealer_bond: ''
      }}
    >
      {props => {
        const documentsUploadMethod = props.values.documentsUploadMethod;
        return (
          <StyledFormWrapper onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle title="Documents" />
              <Grid container style={{ marginTop: '-12px' }}>
                <Field name="documentsUploadMethod" component={FormRadioGroup} validate={required}>
                  <StyledFormControlLabel
                    value="upload"
                    control={<Radio color="primary" />}
                    label="Upload documents now"
                    error
                  />
                  <StyledFormControlLabel
                    value="email"
                    control={<Radio color="primary" />}
                    label="Send documents via Email"
                  />
                </Field>
              </Grid>

              {documentsUploadMethod === 'upload' && (
                <Grid>
                  <OutlinedFormFileUpload
                    validate={fileValidation}
                    name="dmv_license"
                    label="DMV Vehicle Dealer License*"
                  />
                  <OutlinedFormFileUpload validate={fileValidation} name="resale_certificate" label="Sellers Permit*" />
                  <OutlinedFormFileUpload
                    validate={fileValidation}
                    name="city_business_license"
                    label="City Business License*"
                  />
                  <OutlinedFormFileUpload
                    validate={validateDoc}
                    name="dealer_bond"
                    label="Dealer Bond/Other (Optional)"
                  />
                </Grid>
              )}
              {documentsUploadMethod === 'email' && (
                <Grid>
                  <br />
                  <InfoAlert>
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#000'
                      }}
                    >
                      <span>Send us the following documents at </span>
                      <a href="mailto:info@autoaxess.com">info@autoaxess.com</a>
                    </span>
                  </InfoAlert>
                  <StyledUL>
                    <li>DMV Vehicle Dealer License</li>
                    <li>Sellers Permit</li>
                    <li>City Business License</li>
                    <li>Dealer Bond/Other (Optional)</li>
                  </StyledUL>
                </Grid>
              )}
              <StepControl nextStep={nextStep} prevStep={prevStep} />
            </FormWrapper>
            <OfficePhoneLabel />
          </StyledFormWrapper>
        );
      }}
    </Form>
  );
};

/**
 *    <Grid container spacing={24}>
                  <FieldWrapper>
                    <Field
                      name="dmv_license"
                      component={FormFileField}
                      label="DMV Vehicle Dealer License"
                      id="dmv"
                      validate={[requiredFile, validateDoc]}
                      formName="registrationForm"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="resale_certificate"
                      component={FormFileField}
                      label="Sellers Permit"
                      id="resale"
                      validate={[requiredFile, validateDoc]}
                      formName="registrationForm"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="city_business_license"
                      component={FormFileField}
                      label="City Business License"
                      id="city_business"
                      validate={[requiredFile, validateDoc]}
                      formName="registrationForm"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Field
                      name="dealer_bond"
                      component={FormFileField}
                      label="Dealer Bond/Other (Optional)"
                      id="dealer"
                      type="file"
                      validate={validateDoc}
                      formName="registrationForm"
                    />
                  </FieldWrapper>
                </Grid>
 */

// const documentsForm = reduxForm({
//   form: 'registrationForm',
//   destroyOnUnmount: false,
//   fields: ['dealer_bond', 'dmv_license', 'resale_certificate', 'city_business_license'],
//   onSubmitFail: scrollToError
// })(fourthBuyerStep);

// const selector = formValueSelector('registrationForm');

export default FourthBuyerStep;
