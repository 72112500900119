import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import SellerProfile from './SellerProfile';
import BuyerProfile from './BuyerProfile';
import { detailProfile } from '../../../actions/user';

class Profile extends Component {
  componentWillMount() {
    const { fetchProfile } = this.props;
    fetchProfile();
  }

  onTabChange = tab => {
    const { changeTab } = this.props;
    changeTab(tab);
  };

  render() {
    const { user, match } = this.props;
    const { tab } = match.params;
    const { role } = user;
    if (role === 'buyer') {
      return (
        <BuyerProfile onTabChange={this.onTabChange} user={user} tab={tab} />
      );
    }
    if (role === 'seller') {
      return (
        <SellerProfile onTabChange={this.onTabChange} user={user} tab={tab} />
      );
    }
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProfile: () => dispatch(detailProfile().request),
  changeTab: tab => dispatch(push(`/profile/${tab}`))
});

export default connect(
  state => ({
    user: state.user.user
  }),
  mapDispatchToProps
)(Profile);
