import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useViewedVehicle } from '../hooks/useViewedVehicle';
import ReactImageGallery from 'react-image-gallery';
import { ViewSectionOrder } from '../constants';
import { VehicleSectionImage } from '../../../../types/vehicle';

type Props = {
  children: React.ReactNode;
};

const imageGalleryRef = createRef<ReactImageGallery>();

type ViewVehicleContextType = {
  setActiveSection: (sectionId: string) => void;
  activeSection: string;
  imageGalleryRef: React.RefObject<ReactImageGallery>;
  initialFullScreenIndex: number | null;
  fullScreenImageModalOpen: boolean;
  images: VehicleSectionImage[];
  openFullScreenWithIndex: (i: number) => void;
  openFullScreenWithId: (id: number) => void;
  closeFullScreen: () => void;
};

const defaultValue: ViewVehicleContextType = {
  activeSection: '',
  setActiveSection: () => {},
  imageGalleryRef,
  initialFullScreenIndex: null,
  fullScreenImageModalOpen: false,
  openFullScreenWithIndex: () => {},
  openFullScreenWithId: () => {},
  closeFullScreen: () => {},
  images: []
};

const ViewVehicleContext = createContext(defaultValue);

export const ViewVehicleProvider = ({ children }: Props) => {
  const vehicle = useViewedVehicle();
  const [activeSection, setActiveSection] = useState(() => Object.keys(vehicle?.groupedSections || {})?.[0] || '');
  const [fullScreenImageModalOpen, setFullScreenImageModalOpen] = useState(false);
  const [initialFullScreenIndex, setInitialFullScreenIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!activeSection && vehicle?.groupedSections) {
      const keys = Object.keys(vehicle.groupedSections);
      setActiveSection(keys[0]);
    }
  }, [vehicle?.groupedSections]);

  const images = useMemo(() => {
    if (!vehicle) return [];
    return ViewSectionOrder.flatMap(sectionOrder => {
      const [section, subSections] = sectionOrder;
      if (section === 'dealer_comments' || subSections.length === 0) return [];
      const vehicleSectionImages = vehicle.vehicleSections.find(s => s.section.name === section);
      if (!vehicleSectionImages) return [];
      const sectionImages: VehicleSectionImage[] = [];
      for (const subSection of subSections) {
        const vehicleSection = vehicle.vehicleSections.find(
          s => s.section.name === section && s.section.sub_section_name === subSection
        );
        if (!vehicleSection || vehicleSection.images.length === 0) continue;
        sectionImages.push(...vehicleSection.images.filter(i => !!i.url));
      }
      return sectionImages;
    });
  }, [vehicle]);

  const openFullScreenWithIndex = (i: number) => {
    setInitialFullScreenIndex(i);
    setFullScreenImageModalOpen(true);
  };

  const openFullScreenWithId = (id: number) => {
    const index = images.findIndex(i => i.id === id);
    if (index === -1) return;
    setInitialFullScreenIndex(index);
    setFullScreenImageModalOpen(true);
  };

  const closeFullScreen = () => {
    setInitialFullScreenIndex(null);
    setFullScreenImageModalOpen(false);
  };

  return (
    <ViewVehicleContext.Provider
      value={{
        activeSection,
        setActiveSection,
        imageGalleryRef,
        initialFullScreenIndex,
        fullScreenImageModalOpen,
        openFullScreenWithId,
        openFullScreenWithIndex,
        images,
        closeFullScreen
      }}
    >
      {children}
    </ViewVehicleContext.Provider>
  );
};

export const useViewVehicleContext = () => {
  const context = useContext(ViewVehicleContext);
  return context;
};
