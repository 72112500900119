import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import { SortableContext } from '@dnd-kit/sortable';
import {
  SectionsOrder,
  useGetSectionConfigQuery,
  useGetSectionsOrderQuery,
  useUpdateSectionsOrderMutation
} from 'services/api/vehicle-sections';
import { SectionConfig } from 'services/api/vehicle-sections/types';
import { CollapsibleSubSection } from '../../components/CollapsibleSubSection';
import { arrayMove } from '@dnd-kit/sortable';
import api from 'services/api/vehicle-sections';
import styled from 'styled-components';
import { useScreen } from 'usehooks-ts';

type Props = {
  sectionId: keyof SectionConfig;
};

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const SectionWithCollapsibleBlocks = ({ sectionId }: Props) => {
  const { data: config } = useGetSectionConfigQuery();

  const { data: sectionOrder = [] } = useGetSectionsOrderQuery();
  const [updateSectionOrder] = useUpdateSectionsOrderMutation();

  const dispatch = useDispatch<any>();

  const onDragStart = (e: DragStartEvent) => {};

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over && active.id !== over.id) {
      const [, activeId] = String(active.id).split('.');
      const [, overId] = String(over.id).split('.');

      const sectionIndex = sectionOrder.findIndex(s => s[0] === sectionId);
      if (sectionIndex === -1) return;

      const copy: SectionsOrder = sectionOrder.map(([section, subsections]) => {
        return [section, [...subsections]];
      });
      const [, subsections] = copy[sectionIndex];
      const oldI = subsections.findIndex(e => e === activeId);
      const newI = subsections.findIndex(e => e === overId);

      copy[sectionIndex][1] = arrayMove(subsections, oldI, newI);

      dispatch(
        api.util.updateQueryData('getSectionsOrder', undefined, draft => {
          return copy;
        })
      );

      updateSectionOrder(copy);
    }
  };

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  if (!config) return null;

  const [, subsectionsOrder = []] = sectionOrder.find(e => e[0] === sectionId) || [];

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext
        disabled={subsectionsOrder.length <= 1}
        items={subsectionsOrder.map(name => `${sectionId}.${name}`)}
      >
        <RenderContent sectionId={sectionId} subsectionsOrder={subsectionsOrder} config={config} />
      </SortableContext>
    </DndContext>
  );
};

const RenderContent = ({
  sectionId,
  subsectionsOrder,
  config
}: {
  sectionId: keyof SectionConfig;
  subsectionsOrder: string[];
  config: SectionConfig;
}) => {
  const { width = 0 } = useScreen() || { width: 0 };
  const shouldRenderByParts = width > 1024;

  const subsectionConfigs = config[sectionId].subsections;

  const leftPart = subsectionsOrder.filter((_, i) => i % 2 === 0);
  const rightPart = subsectionsOrder.filter((_, i) => i % 2 === 1);

  if (shouldRenderByParts) {
    return (
      <Wrapper>
        <SectionsWrapper>
          {leftPart.map(name => {
            return (
              <CollapsibleSubSection
                name={`${sectionId}.${name}`}
                subSectionConfig={subsectionConfigs[name]}
                key={name}
              />
            );
          })}
        </SectionsWrapper>
        <SectionsWrapper>
          {rightPart.map(name => {
            return (
              <CollapsibleSubSection
                name={`${sectionId}.${name}`}
                subSectionConfig={subsectionConfigs[name]}
                key={name}
              />
            );
          })}
        </SectionsWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SectionsWrapper>
        {subsectionsOrder.map(name => {
          return (
            <CollapsibleSubSection
              name={`${sectionId}.${name}`}
              subSectionConfig={subsectionConfigs[name]}
              key={name}
            />
          );
        })}
      </SectionsWrapper>
    </Wrapper>
  );
};
