import { SNACK_SHOW, SNACK_UNSET_MESSAGE } from '../constants/actionTypes';

const initialState = {
  open: false,
  message: '',
  type: 'success'
};

const utils = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SNACK_SHOW:
      return {
        ...state,
        open: true,
        message: payload.message,
        type: payload.type
      };
    case SNACK_UNSET_MESSAGE:
      return { ...state, open: false, message: '' };
    default:
      return state;
  }
};

export default utils;
