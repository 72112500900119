import React from 'react';
import { Field } from 'redux-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import styled from 'styled-components';
import InlineFormRadioGroup from '../../../Auth/Registration/components/InlineFormRadioGroup';
import { required } from '../../../../utils/validators';
import { PAYMENT_METHODS_STATUSES } from "../../../../constants";

const StyledInlineFormRadioGroup = styled(InlineFormRadioGroup)`
  label:last-child {
    margin-left: 5px;
  }
`;

const StyledRadio = <Radio color="primary" style={{padding: 5}} />

const FinalizeAchFlooringApprovalPicker = () => (
  <Field
    name="payment_status"
    component={StyledInlineFormRadioGroup}
    row
    validate={required}
  >
    <FormControlLabel
      control={StyledRadio}
      value={PAYMENT_METHODS_STATUSES.APPROVED}
      label="Approve"
    />
    <FormControlLabel
      control={StyledRadio}
      value={PAYMENT_METHODS_STATUSES.DECLINED}
      label="Decline"
    />
  </Field>
);

export default FinalizeAchFlooringApprovalPicker;
