import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type Pagination = {
    page: number,
    perPage: number
}

const initialPaginationState = {
    page: 1,
    perPage: 25
}

type Filters = {filters: Record<string, any>}

type TransactionsState = {
  all: Pagination,
  easyPay: Pagination & Filters,
  card: Pagination & Filters
}

const initialState: TransactionsState = {
  all: {...initialPaginationState},
  easyPay: {...initialPaginationState, filters: {}},
  card: {...initialPaginationState, filters: {}},
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<{ tab: keyof TransactionsState, page: number }>) => {
        const { tab, page } = action.payload
        state[tab].page = page;
      },
      setFilter(state, action: PayloadAction<{ tab: keyof TransactionsState, key: string, value: any }>) {
        const { key, value, tab } = action.payload
        if (tab === 'all') return;
        if (!value) {
            delete state[tab].filters[key]
        } else {
            state[tab].filters[key] = value
        }
        state[tab].page = 1
      },
      setEasyPayStatusFilter(state, action: PayloadAction<string>) {
        if (!action.payload) {
            delete state.easyPay.filters.combinedStatus
            delete state.easyPay.filters.payment_type
            delete state.easyPay.filters.payment_status
        } else {
            const [payment_type, payment_status] = action.payload.split("_");
            state.easyPay.filters = {
                payment_status,
                payment_type,
                combinedStatus: action.payload
            }
        }
        state.easyPay.page = 1
      },
      resetFilters(state) {
        state = {...initialState}
      }
    }
});

export const { setPage, setFilter, resetFilters, setEasyPayStatusFilter } = transactionsSlice.actions;
export default transactionsSlice;
