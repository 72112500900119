import moment from 'moment';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { listLocations, addLocation, getLocation, updateLocation, snackShow, modalsToggle } from '../actions';
import {
  LIST_LOCATIONS_REQUEST,
  CREATE_LOCATION_REQUEST,
  GET_LOCATION_REQUEST,
  UPDATE_LOCATION_REQUEST
} from '../constants/actionTypes';
import { Api, minutesToMs } from '../utils';

function* fetchLocations({ payload }) {
  try {
    const { data } = yield call(Api.locations.fetchLocations, payload);
    yield put(listLocations(data).success);
  } catch (e) {
    yield put(listLocations(e).failure);
  }
}

function* createLocation({ payload }) {
  try {
    const createData = {
      name: payload.name,
      regions: payload.regions,
      end_day: payload.end_day,
      end_time: moment.utc(payload.end_time).format('HH:mm'),
      end_time_spacing: `${payload.endTimeMinutes}:${payload.endTimeSeconds}`,
      shuffle_day: payload.shuffleDay,
      shuffle_time: moment.utc(payload.shuffleTime).format('HH:mm')
    };
    const { data } = yield call(Api.locations.createLocation, createData);
    yield put(addLocation(data).success);
  } catch (e) {
    yield put(addLocation(e).failure);
  }
}
function* editLocation({ payload }) {
  try {
    const createData = {
      end_day: payload.end_day,
      end_time: payload.end_time.format(),
      end_time_spacing: `${payload.endTimeMinutes}:${payload.endTimeSeconds}`,
      shuffle_day: payload.shuffleDay,
      shuffle_time: payload.shuffleTime.format(),
      buyer_response_time: minutesToMs(Number(payload.buyer_response_time)),
      seller_response_time: minutesToMs(Number(payload.seller_response_time)),
      frontline_end_date_time: payload.frontline_end_date_time?.format(),
      extension_times: [
        Number(payload.extension_time_1),
        Number(payload.extension_time_2),
        Number(payload.extension_time_3),
        Number(payload.extension_time_4)
      ]
    };
    const { data } = yield call(Api.locations.updateLocation, payload.id, createData);
    yield put(updateLocation(data).success);
    yield put(snackShow({ message: 'Location updated', type: 'success' }));
    yield put(modalsToggle('editLocationMo     dal'));
  } catch (e) {
    yield put(updateLocation(e).failure);
  }
}

function* fetchLocation({ payload }) {
  try {
    const { data } = yield call(Api.locations.fetchLocation, payload);
    yield put(getLocation(data).success);
  } catch (e) {
    yield put(getLocation(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_LOCATIONS_REQUEST, fetchLocations),
    takeLatest(GET_LOCATION_REQUEST, fetchLocation),
    takeLatest(CREATE_LOCATION_REQUEST, createLocation),
    takeLatest(UPDATE_LOCATION_REQUEST, editLocation)
  ]);
}
