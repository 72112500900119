import { useSelector } from 'react-redux';
import { Vehicle } from '../types/vehicle';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { auctionOfferStatuses } from '../utils/statusCalculator';

export const useVehicleOfferAuctionById = (vehicleId: number, selectorFn: (state: any) => Vehicle[]) => {
  const vehicles = useSelector(selectorFn);
  const { data } = useFetchActiveOffersQuery(
    { vehicleIds: vehicles?.map(el => el.id) },
    { skip: !vehicles || vehicles.length === 0 }
  );

  return data?.entities[vehicleId];
};

export const useNoSelectorVehicleOfferAuctionById = (vehicleId: number) => {
  const { data } = useFetchActiveOffersQuery(
    { vehicleIds: [vehicleId] },
    { skip: !vehicleId }
  );

  return data?.entities[vehicleId];
};

export const useVehicleStatus = (vehicle: Vehicle) => {
  const currentUser = useSelector((state: any) => state.user?.user);
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);
  const vehicleStatus = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  return vehicleStatus
}
