import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type SidePanelState = {
  isOpen: boolean;
  activeVehicleId: number | null;
}

const initialState: SidePanelState = {
  isOpen: false,
  activeVehicleId: null,
};

const sidePanelSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
      setActiveVehicleId: (state, action: PayloadAction<number | null>) => { 
        state.activeVehicleId = action.payload;
      }
    }
});

export const { setActiveVehicleId } = sidePanelSlice.actions
export default sidePanelSlice;
