import { useDispatch } from 'react-redux';
import { DragStartEvent } from '@dnd-kit/core';
import { setDraggableItem } from '../../../../services/listerDashboard/listerDashboardSlice';

export const useHandleDragStart = () => {
  const dispatch = useDispatch();

  return (e: DragStartEvent) => {
    console.log(e.active.data.current, 'drag start event');
    dispatch(setDraggableItem(e.active.data.current as any));
  };
};
