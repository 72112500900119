import moment from 'moment';
import { BidHistorySection } from '../../../../Shared/VehicleDetails/components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { InfoBlock } from '../InfoBlock';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { AUCTION_STATUSES, BUYER_VEHICLE_STATUS, SELLER_VEHICLE_STATUS } from '../../../../../constants';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { OfferAuctionStatus, Vehicle } from '../../../../../types/vehicle';

const formatDate = (date: string) => {
  return moment(date).format('MM/DD/YY, hh:mm A');
};

export const useShowOfferSection = (vehicle?: Vehicle) => {
  const currentUser = useCurrentUser();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  if (!offerAuction || !vehicle || offerAuction.offerBids.length === 0) return false;
  if (currentUser.role === 'admin') return true;
  if (vehicle.auction.status === AUCTION_STATUSES.ENDED) return false;
  if (currentUser.role === 'buyer') {
    return [
      BUYER_VEHICLE_STATUS.AWARDED,
      BUYER_VEHICLE_STATUS.DECLINED,
      BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED,
      BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE,
      BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS,
      BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER
    ].includes(status);
  }

  const showOfferBidsSectionSellerAwarded = (() => {
    if (status !== SELLER_VEHICLE_STATUS.AWARDED) return false;

    const hasPublicOffer = offerAuction?.privateOffers?.some(offer => offer.type === 'public');
    return hasPublicOffer;
  })();

  if (currentUser.role === 'seller') {
    return (
      showOfferBidsSectionSellerAwarded ||
      (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS &&
        [
          SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW,
          SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS,
          SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL,
          SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL
        ].includes(status))
    );
  }

  return false;
};

export const OffersHistory = () => {
  const vehicle = useViewedVehicle();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);

  const showOfferBidsSection = useShowOfferSection(vehicle);
  if (!showOfferBidsSection) return null;

  const bids = (offerAuction?.offerBids || []).map(bid => ({
    id: bid.id,
    bid: bid.amount,
    bidder: bid.bidder?.dealership_name,
    date: formatDate(bid.created_at),
    created_at: bid.created_at
  }));

  return (
    <InfoBlock>
      <BidHistorySection type="offers" initiallyCollapsed={false} bids={bids} proxyBid={offerAuction?.offerProxyBid} />
    </InfoBlock>
  );
};
