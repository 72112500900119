/* eslint-disable no-else-return */
import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import SectionTitle from '../../components/SectionTitle';
import { USER_STATUSES, BUYER_VEHICLE_STATUS, BUYER_VEHICLE_LABELS } from '../../../../../constants';
import { useSelector } from 'react-redux';
import { useNoSelectorVehicleOfferAuctionById, usePrivateOfferAmount, useTimerConfig } from '../../../../../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { FormattedAmount, Timer } from '../../../../../components';
import { OfferAuctionStatus, OfferStatus } from '../../../../../types/vehicle';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { useBuyerCounterControls } from '../../../../../hooks';
import { AcceptOfferModal, OfferHistory, SendOfferModal } from '../../../../../components/Offers';
import { editBanner } from '../../../../../actions';
import { ReservePriceSection } from '../../components';
import { AcceptPublicOfferSection } from './AcceptPublicOfferSection';

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
  }
`;

const CounterAmountWrapper = styled.div`
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 12px;
`;

const SectionTitleWithStatus = styled(SectionTitle)`
  span {
    color: ${props => props.statusColor || '#000'};
  }
`;

const TimerWrapper = styled(Grid)`
  margin-bottom: 12px;
  & > h2 {
    margin: 0;
  }

  & > span {
    width: unset;
  }
`;
const StyledViewReportOfSale = styled(Typography)`
  && {
    color: #005a96;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    font-weight: 500;
    margin-top: 17px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const statusDescription = {
  [BUYER_VEHICLE_STATUS.PULLED]: 'The vehicle has been pulled and it is no longer available for bidding',
  [BUYER_VEHICLE_STATUS.UNDECIDED]: `Congratulations! You are the Top Buyer and have a solo chance to negotiate with the seller.
    You will see the updates here.`,
  [BUYER_VEHICLE_STATUS.PENDING_APPROVAL]:
    'The high bid is being reviewed. Once a counter or acceptance is processed, you will see the update here.',
  [BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS]: `The high bid is being reviewed. Once a counter or acceptance is processed, you will see the update here.`
};

const CounterControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 20px;
`;

const CounterButton = styled(Button)`
  border-radius: 6px;
  flex: 1 1 100%;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const StatusComponent = ({ vehicle }) => {
  const currentUser = useSelector(state => state.user?.user ?? {});
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);
  const isBuyerAwarded = currentUser.id === vehicle.buyer_id;
  const isCharged = vehicle.charge_completed;
  const amount = vehicle.vehicle_price;
  const awardedUser = vehicle.awarded_user;
  const counterControlButtons = useBuyerCounterControls(vehicle.id);
  const [, acceptButton] = counterControlButtons;
  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);
  const { timer, colorsConfig } = useTimerConfig(vehicle, offerAuction);
  const { buyerAmount, adminOrSellerAmount } = usePrivateOfferAmount(offerAuction);

  const AwardedDescriptionComponent = () => {
    if (status === BUYER_VEHICLE_STATUS.AWARDED) {
      return (
        <StyledDescription>
          {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
          <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator />
        </StyledDescription>
      );
    }

    if (BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE) {
      return (
        <StyledDescription>
          {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
          <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator />{' '}
        </StyledDescription>
      );
    }

    return (
      <StyledDescription>
        {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
        <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator /> but your credit card was
        declined during fee charging. Please update your credit card information on your Profile.
      </StyledDescription>
    );
  };

  switch (status) {
    case BUYER_VEHICLE_STATUS.PULLED:
    case BUYER_VEHICLE_STATUS.UNDECIDED:
    case BUYER_VEHICLE_STATUS.PENDING_APPROVAL: {
      return (
        <Grid container direction="column">
          <SectionTitleWithStatus variant="title" statusColor={BUYER_VEHICLE_LABELS[status].color}>
            Bid Status - <span>{BUYER_VEHICLE_LABELS[status].label}</span>
          </SectionTitleWithStatus>
          <StyledDescription>{statusDescription[status]}</StyledDescription>
          <ReservePriceSection vehicle={vehicle} includeTopDivider />
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS:
    case BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER: {
      const descriptionText = (() => {
        if (
          status === BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS &&
          offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS
        ) {
          return 'Seller accepting backup offers if top buyer and seller don’t come to an agreement.';
        }

        return statusDescription[status];
      })();
      return (
        <Grid container direction="column">
          <SectionTitleWithStatus variant="title" statusColor={BUYER_VEHICLE_LABELS[status].color}>
            Bid Status - <span>{BUYER_VEHICLE_LABELS[status].label}</span>
          </SectionTitleWithStatus>
          {descriptionText && <StyledDescription>{descriptionText}</StyledDescription>}
          <ReservePriceSection vehicle={vehicle} includeTopDivider />
          <AcceptPublicOfferSection
            offerAuction={offerAuction}
            vehicleId={vehicle.id}
            vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          />
          {status === BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER && (
            <>
              <StyledDivider />
              <OfferHistory offerAuction={offerAuction} />
            </>
          )}
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT:
    case BUYER_VEHICLE_STATUS.COUNTERED: {
      const lastOffer = offerAuction.privateOffers?.find(el => el.status === OfferStatus.SENT);
      return (
        <Grid container direction="column">
          <TimerWrapper container justify="space-between" alignItems="center">
            <SectionTitleWithStatus variant="title" statusColor={BUYER_VEHICLE_LABELS[status].color}>
              Bid Status - <span>{BUYER_VEHICLE_LABELS[status].label}</span>
            </SectionTitleWithStatus>
            {lastOffer && lastOffer.sender.role !== 'buyer' && timer && (
              <Timer withColors colorsConfig={colorsConfig} toDate={timer} />
            )}
          </TimerWrapper>
          <ReservePriceSection vehicle={vehicle} />
          <StyledDivider />
          <CounterAmountWrapper>
            {`Your countered Amount - `}
            <FormattedAmount amount={buyerAmount} />
          </CounterAmountWrapper>
          {status === BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT && (
            <StyledDescription style={{ marginBottom: '12px' }}>
              The counter was sent to seller. You will see updates here.
            </StyledDescription>
          )}
          <CounterAmountWrapper>
            {`Seller’s countered Amount - `}
            <FormattedAmount amount={adminOrSellerAmount} />
          </CounterAmountWrapper>
          {status === BUYER_VEHICLE_STATUS.COUNTERED && (
            <StyledDescription style={{ marginBottom: '12px' }}>
              The counter was set by seller. You will see updates here.
            </StyledDescription>
          )}
          {status === BUYER_VEHICLE_STATUS.COUNTERED && (
            <CounterControlsWrapper>
              {counterControlButtons.map(btn => (
                <CounterButton key={btn.title} color={btn.color} textColor={btn.textColor} onClick={btn.onClick}>
                  {btn.title}
                </CounterButton>
              ))}
            </CounterControlsWrapper>
          )}
          {status === BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT && (
            <CounterControlsWrapper>
              <CounterButton
                key={acceptButton.title}
                color={acceptButton.color}
                textColor={acceptButton.textColor}
                onClick={acceptButton.onClick}
              >
                {acceptButton.title}
              </CounterButton>
            </CounterControlsWrapper>
          )}
          <SendOfferModal
            offerAuction={offerAuction}
            maxAmount={vehicle.reserve_price}
            form={`sendVehicleOffer-${vehicle.id}`}
            vehicle={vehicle}
          />
          <AcceptOfferModal vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} vehicleId={vehicle.id} />
          <OfferHistory offerAuction={offerAuction} />
        </Grid>
      );
    }
    // TODO: move this to the top of the switch
    case BUYER_VEHICLE_STATUS.DECLINED: {
      return (
        <Grid container direction="column">
          <SectionTitleWithStatus variant="title" statusColor="#D50808">
            Bid Status - <span>Declined</span>
          </SectionTitleWithStatus>
          <StyledDescription>The car was awarded to {awardedUser.dealership_name}.</StyledDescription>
          {offerAuction?.top_bidder_id === currentUser.id && (
            <OfferHistory offerAuction={offerAuction} withTopDivider />
          )}
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.AWARDED:
    case BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE:
    case BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED:
      return (
        <Grid container direction="column">
          <SectionTitleWithStatus variant="title" statusColor={BUYER_VEHICLE_LABELS[status].color}>
            Bid Status - <span>{BUYER_VEHICLE_LABELS[status].label}</span>
          </SectionTitleWithStatus>
          <AwardedDescriptionComponent />
          <StyledViewReportOfSale
            onClick={() => {
              window.open(vehicle.buyer_report_of_sale);
            }}
          >
            View Report of Sale
          </StyledViewReportOfSale>
          {offerAuction?.top_bidder_id === currentUser.id && (
            <OfferHistory offerAuction={offerAuction} withTopDivider />
          )}
        </Grid>
      );
    default:
      return null;
  }
};

export default StatusComponent;
