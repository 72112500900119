import React from 'react';
import styled from 'styled-components';
import { formValueSelector, Field } from 'redux-form';
import { MenuItem, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Section } from '../components';
import { FormTextField, FormRadioGroup } from '../../../../components/Form';
import { userStateSelector } from '../../../../selectors';
import { EASY_PAY_PURCHASE_FORM } from '../../../../constants/forms';
import { FLOORING_FORM_COMPANIES_NAMES, BUYER_PURCHASE_METHODS } from '../../../../constants';
import { required } from '../../../../utils/validators';

const StyledSection = styled(Section)`
  margin-top: 15px;
`;

const StyledLabel = styled(FormControlLabel)`
  > span {
    padding: 5px 0 3px 12px;
  }
`;

const PurchaseMethods = ({ type, profile }) => {
  if (!profile) return null;

  const { buyer } = profile;
  const { flooring_form: flooringForm, purchase_methods: purchaseMethods = [] } = buyer;

  const achAvailable = purchaseMethods.find(method => method.option_name === BUYER_PURCHASE_METHODS.ACH);
  const flooringAvailable = purchaseMethods.find(method => method.option_name === BUYER_PURCHASE_METHODS.FLOORING);

  const achLabel = achAvailable ? 'ACH' : 'ACH (add an ACH account to use this payment method)';
  const flooringLabel = flooringAvailable ? 'Flooring' : 'Flooring (add a Flooring account to use this payment method)';

  let companies = [];
  if (flooringForm) {
    const { additional_forms: additionalFlooringForms = [] } = flooringForm;
    companies = [flooringForm, ...additionalFlooringForms].map(company => company.flooring_company);
    companies = [...new Set(companies)];
  }

  return (
    <StyledSection title="EasyPay Methods" xs={12}>
      <Typography style={{lineHeight: '3em'}}>
        Please select the EasyPay method you want to use to pay for this car.
      </Typography>
      <Field name="type" validate={required} component={FormRadioGroup} row>
        <StyledLabel
          value={BUYER_PURCHASE_METHODS.ACH}
          control={<Radio />}
          label={achLabel}
          disabled={!achAvailable}
          style={{marginRight: '50px'}}
        />
        <StyledLabel
          value={BUYER_PURCHASE_METHODS.FLOORING}
          control={<Radio />}
          label={flooringLabel}
          disabled={!flooringAvailable}
        />
      </Field>
      {(Boolean(companies.length) && type === BUYER_PURCHASE_METHODS.FLOORING) && (
        <Field
          name="company"
          component={FormTextField}
          label="Select Flooring Company"
          fullWidth
          validate={required}
          margin="normal"
          select
        >
          {companies.map((company, index) => (
            <MenuItem value={company} key={index}>
              {FLOORING_FORM_COMPANIES_NAMES[company]}
            </MenuItem>
          ))}
        </Field>
      )}
    </StyledSection>
  );
};

const selector = formValueSelector(EASY_PAY_PURCHASE_FORM);

const mapStateToProps = state => ({
  profile: userStateSelector(state),
  type: selector(state, 'type')
});

export default connect(mapStateToProps)(PurchaseMethods);
