import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { StyledPaper } from '../components/PageCotainer';
import { AutoaxessLogo } from '../components/AutoaxessLogo';
import { useHistory } from 'react-router-dom';

// const StyledPaper = styled(Paper)`
//   padding: 20px;
//   width: calc(100% - 20px);
//   max-width: 600px;
//   margin: 15px auto;
// `;

const StyledTypography = styled(Typography)`
  && {
    font-size: 15px;
  }
`;

const DescriptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  padding-top: 24px;
  padding-bottom: 20px;
  text-align: center;
  width: 220px;
`;

const SuccessText = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  margin: 12px 0 32px 0;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 8px;
    margin-bottom: 12px;
    text-transform: none;
  }

  @media (max-width: 768px) {
    && {
      margin-top: 10px;
    }
  }
`;

const MailSentSuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
    <path
      d="M44.0514 7.32932C23.8004 7.32932 7.38477 23.745 7.38477 43.9959C7.38477 64.2469 23.8004 80.6625 44.0514 80.6625C62.678 80.6625 78.3127 66.6998 80.4907 48.3519C80.7254 46.3389 79.2917 44.4653 77.2787 44.2233C75.2693 43.9886 73.392 45.4222 73.1574 47.4352C71.4157 62.1019 58.9527 73.3292 44.0514 73.3292C27.8521 73.3292 14.7181 60.1952 14.7181 43.9959C14.7181 27.7966 27.8521 14.6626 44.0514 14.6626C47.4357 14.6626 50.7871 15.2749 53.9074 16.3823C55.8141 17.0569 57.8124 15.9973 58.4907 14.0906C59.1654 12.1803 58.2194 10.0684 56.3127 9.39002C52.4114 8.00769 48.2717 7.32932 44.0514 7.32932ZM77.0514 14.6626C76.1127 14.6626 75.1301 14.9927 74.4151 15.693L42.4454 47.2043C41.5031 48.1319 40.5534 47.9523 39.8127 46.8596L36.146 41.4733C35.024 39.8196 32.6701 39.3393 30.9908 40.443C29.3078 41.5466 28.8348 43.8309 29.9568 45.4846L33.6234 50.8709C36.9271 55.7439 43.3914 56.3892 47.6044 52.2459L79.6877 20.8519C81.1177 19.4439 81.1177 17.101 79.6877 15.693C78.9727 14.989 77.99 14.6626 77.0514 14.6626Z"
      fill="#479747"
    />
  </svg>
);

const textVariations = {
  'forgot-password': {
    description: 'We have sent a link to reset your password to your email.',
    title: 'Mail sent to you',
    button: 'Ok'
  },
  registration: {
    description: 'Your account was successfully created. Feel free to sign in now.',
    title: 'Success!',
    button: 'Continue'
  }
};

const getPageText = (params = {}) => {
  const { page } = params;
  if (textVariations[page]) {
    return textVariations[page];
  }
  return { title: 'Page not found', description: 'Return to login page' };
};

const SuccessPage = ({ match }) => {
  const { title, description, button } = getPageText(match.params);
  const history = useHistory();

  return (
    <StyledPaper>
      <PageWrapper>
        <AutoaxessLogo />
        <SuccessText>{title}</SuccessText>
        <MailSentSuccessIcon />
        <DescriptionText>{description}</DescriptionText>
        <StyledButton onClick={() => history.push('/login')} variant="raised" color="primary" fullWidth>
          {button}
        </StyledButton>
      </PageWrapper>
    </StyledPaper>
  );
};

export default SuccessPage;
