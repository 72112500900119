import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useScanVinMutation } from '../../../../../services/api';

const StyledInput = styled.input`
  display: none;
`;

type VinInputProps = {
  change: (data: 'vin', value: string) => void;
};

export const VinInput = ({ change }: VinInputProps) => {
  const [scanVin, { data }] = useScanVinMutation();
  const ref = useRef<HTMLInputElement>(null);

  const uploadFile = () => {
    ref.current?.click();
  };


  useEffect(() => {
    if (data?.success && !data.data.error) {
      change('vin', data.data.vin);
    }
  }, [data]);

  return (
    <>
      <a
        href="#vinScanner"
        onClick={e => {
          e.preventDefault();
          uploadFile();
        }}
      >
        Use VIN Scanner
      </a>
      <StyledInput
        type="file"
        ref={ref}
        onChange={e => {
          if (e.target.files?.[0]) {
            scanVin(e.target.files[0]);
          }
        }}
      />
    </>
  );
};
