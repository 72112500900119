/* eslint-disable no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import StatusHeader from './StatusHeader';
import BiddingHeader from './BiddingHeader';
import CounterHeader from './CounterHeader';
import { VEHICLE_STATUSES, AUCTION_STATUSES } from '../../../../../constants';
import { Timer } from '../../../../../components';

const SellerRestrictedHeader = ({ currentUser, currentVehicle }) => {
  const highestBid = currentVehicle.bids.reduce(
    (acc, bid) => {
      if (bid.amount >= acc.amount && bid.id > acc.id) {
        return bid;
      }
      return acc;
    },
    { amount: 0, id: 0 }
  );

  const isSellerCreatedVehicle = currentVehicle.user_id === currentUser.id;

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    currentVehicle.end_time_spacing !== null &&
    moment
      .utc(currentVehicle.auction.date_end)
      .add(currentVehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const isShuffled = currentVehicle.end_time_spacing !== null;
  const shuffledTimer = moment
    .utc(currentVehicle.auction.date_end)
    .add(currentVehicle.end_time_spacing, 'seconds');
  const endAuction = moment.utc(currentVehicle.auction.date_end);
  const vehicleTimer = (() => {
    const endStatuses = [VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.PULLED];
    if (endStatuses.includes(currentVehicle.status)) {
      return 0;
    }

    return isShuffled ? shuffledTimer : endAuction;
  })();

  if (!isSellerCreatedVehicle) {
    return null;
  }
  if (
    currentVehicle.status === VEHICLE_STATUSES.ACTIVE &&
    currentVehicle.auction.status === AUCTION_STATUSES.ACTIVE &&
    !isVehicleTimerEnded
  ) {
    return (
      <BiddingHeader
        currentBid={highestBid}
        timeRemaining={<Timer toDate={vehicleTimer} />}
      />
    );
  }
  return <StatusHeader vehicle={currentVehicle} />
};

export default connect(state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
}))(SellerRestrictedHeader);
