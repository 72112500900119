import { AcceptOfferModal, SendOfferModal } from '../../../../../components/Offers';
import MyBidEditModal from '../MyBidEditModal';
import { BlockProps } from './types';

export const BuyerModals = ({ currentVehicle: vehicle }: BlockProps) => {
  return (
    <>
      <MyBidEditModal form={`myBidsEditModal-${vehicle.id}`} initialValues={vehicle} />
      <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
      <AcceptOfferModal vehicleId={vehicle.id} vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} />
    </>
  );
};
