import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DatePicker from '../ListVehiclesRow/CheckboxDatePicker';
import Select from 'react-select';
import { useGetListersQuery } from '../../../../services/api/list-vehicles';
import {
  setMapFilters,
  toggleShowPolylines,
  useGetMapFilters,
  useShowPolyline
} from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  gap: 8px;
`;

const DateWrapper = styled.div`
  width: 190px;
  height: 44px;
`;

const VehiclePoolSelect = styled(Select)`
  & > div {
    background: #fff;
  }
  & .map-filters-select__control {
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
  }

  & .map-filters-select__control:hover {
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
  }

  & .map-filters-select__control--is-focused {
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-shadow: none;
  }

  & .map-filters-select__dropdown-indicator {
    padding: 6px;
  }

  & .map-filters-select__control {
    min-height: unset;
  }

  & .map-filters-select__menu-list {
    padding: 4px 4px 4px 0;
  }

  & .map-filters-select__value-container {
    height: 44px;
  }

  & .map-filters-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .map-filters-select__indicator-separator {
    display: none;
  }

  & .map-filters-select__dropdown-indicator,
  .map-filters-select__dropdown-indicator:hover {
    color: hsl(0, 0%, 40%);
  }

  & .map-filters-select__dropdown-indicator {
    color: hsl(0, 0%, 40%);
  }

  & .map-filters-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }
`;

const StyledButton = styled(Button)`
  height: 44px;
  width: 140px;
`;

export const MapFilters = () => {
  const dispatch = useDispatch();

  const { data: listers } = useGetListersQuery();

  const { date, listerId } = useGetMapFilters();

  const showPolylines = useShowPolyline();

  const listerOptions =
    listers?.map(el => ({
      value: el.id,
      label: el.first_name
    })) ?? [];

  const handleListerIdChange = (v: typeof listerOptions[0]) => dispatch(setMapFilters({ date, listerId: v.value }));
  const handleDateChange = (v: moment.Moment, currentWeekFlag: boolean) => {
    dispatch(
      setMapFilters({ date: v ? v.format(DATE_FORMAT) : undefined, listerId, currentWeekOnly: currentWeekFlag })
    );
  };

  const handleToggleShowRoute = () => dispatch(toggleShowPolylines());

  return (
    <Wrapper>
      <DateWrapper>
        <VehiclePoolSelect
          classNamePrefix="map-filters-select"
          value={listerOptions.find(o => o.value === listerId)}
          onChange={handleListerIdChange}
          options={listerOptions}
        />
      </DateWrapper>
      <DatePicker
        styles={{
          background: '#fff',
          borderRadius: '8px',
          height: '44px',
          width: '190px',
          border: '1px solid rgba(204, 204, 204, 1)'
        }}
        withCurrentWeekCheckbox
        isEditing
        value={date}
        onChange={handleDateChange}
      />
      <StyledButton color="primary" variant="contained" onClick={handleToggleShowRoute}>
        {showPolylines ? 'Hide Routes' : 'Show Routes'}
      </StyledButton>
    </Wrapper>
  );
};
