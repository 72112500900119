import React, { useEffect } from 'react';
import { Typography, ButtonBase } from '@material-ui/core';
import styled from 'styled-components';
import { useGetMySalesCountersQuery } from 'services/api/sales';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setFilters } from '../../../../services/mySales/salesSlice';

const StyledTypography = styled<any>(Typography)`
  font-weight: 500;
  font-size: 16px;
  padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
  border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
`;

const Tabs = () => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state: any) => state.mySales);
  const activeTab = filters.auctionStatus;
  const { data } = useGetMySalesCountersQuery();

  const handleTabClick = React.useCallback(
    (tab: string) => {
      // setActiveTab(tab);
      dispatch(setPage(1));
      dispatch(setFilters({ auctionStatus: tab }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!data) return;
    if (data.active === 0 && data.pending === 0) return;
    if (data.active === 0 && activeTab === 'active') {
      handleTabClick('pending');
    }
    if (data.pending === 0 && activeTab === 'pending') {
      handleTabClick('active');
    }
  }, [activeTab, data, dispatch, handleTabClick]);

  return (
    <>
      {data && data.active !== 0 && (
        <ButtonBase onClick={() => handleTabClick('active')}>
          <StyledTypography variant="title" isActive={activeTab === 'active'}>
            {`Active (${data?.active ?? 0})`}
          </StyledTypography>
        </ButtonBase>
      )}
      {data && data.pending !== 0 && (
        <ButtonBase onClick={() => handleTabClick('pending')}>
          <StyledTypography variant="title" isActive={activeTab === 'pending'}>
            {`Timer Ended (${data?.pending ?? 0})`}
          </StyledTypography>
        </ButtonBase>
      )}
    </>
  );
};

export default Tabs;
