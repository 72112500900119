import { ImageField } from 'services/api/upload-vehicle';
import { ImagePlaceholderIcon } from '../../../../icons/ImagePlaceholder';
import {
  ImageContainer,
  ImageTitle,
  ImageTitleContainer,
  ImageWrapper,
  LabelPlaceholder,
  NotesIconWrapper,
  StyledImage
} from '../ImageView';
import { cutTextElipsis } from '../../../../../../utils';
import { NotesIcon } from '../../../../icons/NotesIcon';

export const ImageDragOverlay = ({ image }: { image: ImageField }) => {
  return (
    <ImageContainer withShadow>
      <ImageWrapper>{image.url ? <StyledImage src={image.url} /> : <ImagePlaceholderIcon />}</ImageWrapper>
      <RenderLabel label={image.label} notes={image.notes} />
      <NotesIconWrapper>
        <NotesIcon isActive={!!image.notes} />
      </NotesIconWrapper>
      {/* {renderDamageCheckbox && <RenderDamagedCheckbox name={name} />} */}
    </ImageContainer>
  );
};

const RenderLabel = ({ label, notes }: { label: string; notes: string }) => {
  if (!label && !notes) {
    return (
      <ImageTitleContainer>
        <LabelPlaceholder>Add comment</LabelPlaceholder>
      </ImageTitleContainer>
    );
  }

  const renderValue = [label, cutTextElipsis(notes, 3)].filter(Boolean).join(' - ');
  return (
    <ImageTitleContainer>
      <ImageTitle>{renderValue}</ImageTitle>
    </ImageTitleContainer>
  );
};
