import styled from 'styled-components';
import { VehicleOptions, NoImagePlaceholder } from '../../../../../components';
import { ImagePreview } from '..';
import { useSalesOfferAuction } from '../hooks';
import { useSelector } from 'react-redux';
import { NegotiationLabel } from '../../../../../components/NegotiationLabel';
import { useBidsOfferAuction } from '../../../../Buyer/BidsList/components/hooks';

const DetailsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const ImagePreviewWrapper = styled.div`
  position: relative;
`;

const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderIndexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 12px;
`;

const OrderIndex = styled.div`
  background: #b0b0b04d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 32px;
`;

export const VehicleOptionsBlock = ({ currentVehicle, includeOrderIndex = false }) => {
  const user = useSelector(state => state.user.user);
  const hookByUserType = {
    buyer: useBidsOfferAuction,
    seller: useSalesOfferAuction
  };

  const useOfferAuction = hookByUserType[user.role];
  const offerAuction = useOfferAuction(currentVehicle.id);

  return (
    <DetailsWrapper>
      <ImageBlock>
        <ImagePreviewWrapper>
          {currentVehicle.images[0] && currentVehicle.images[0].url_thumb ? (
            <ImagePreview vehicleId={currentVehicle.id} url={currentVehicle.images[0].url_thumb} />
          ) : (
            <NoImagePlaceholder />
          )}
          <NegotiationLabel vehicle={currentVehicle} offerAuction={offerAuction} user={user} position="bottom" />
        </ImagePreviewWrapper>

        {includeOrderIndex && (
          <OrderIndexWrapper>
            <span>Vehicle #</span>
            <OrderIndex>
              <span>{currentVehicle.orderIndex}</span>
            </OrderIndex>
          </OrderIndexWrapper>
        )}
      </ImageBlock>

      <VehicleOptions currentVehicle={currentVehicle} />
    </DetailsWrapper>
  );
};
