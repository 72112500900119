import styled from 'styled-components';
import { getStatusColor } from './components/ListVehiclesRow/StatusComponent';
import { Icon } from '@material-ui/core';
import { UniqueIdentifier, useDraggable } from '@dnd-kit/core';
import { VehicleRangeWrapper } from './components/SellerButton';
import { ActiveItem } from '../../services/listVehiclesSlice/listVehiclesSlice';

type Props = {
  item: ActiveItem
};

const RoundedLabel = styled.div<{ isDragging?: boolean; color: string; background: string }>`
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 8px;
  padding: 8px 12px;
  width: fit-content;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  border: 1px solid #225a91;
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`;

const RoundedDot = styled.div`
  width: 2px;
  height: 2px;
  background: #b6b1b1;
`;

const DotsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  width: 5px;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ActiveDragItem = ({ item }: Props) => {
  const { color, background } = getStatusColor('draft');

  return (
    <RoundedLabel color={color} background={background}>
      <DotsArea>
        <RoundedDot />
        <RoundedDot />
        <RoundedDot />
        <RoundedDot />
        <RoundedDot />
        <RoundedDot />
      </DotsArea>
      <span>{item.user.dealership_name}</span>
      {item.number_of_vehicles && <VehicleRangeWrapper>{item.number_of_vehicles}</VehicleRangeWrapper>}
    </RoundedLabel>
  );
};
