import styled from 'styled-components';
import { GradesComponent } from '../../components/Grades';
import { useGetSectionConfigQuery } from '../../../../services/api/vehicle-sections';
import { PhotoUploadComponent } from '../../components/CollapsibleSubSection/PhotoUpload/PhotoUpload';
import { Footer } from '../../components/CollapsibleSubSection/Footer';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const SubSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 100%;
`;

const sectionId = 'tires_and_wheels';

export const TiresAndWheelsSection = () => {
  const { data: config } = useGetSectionConfigQuery();
  if (!config) return null;

  const subsections = config.tires_and_wheels.subsections;

  return (
    <Wrapper>
      {Object.entries(subsections).map(([subsection, subsectionConfig]) => (
        <SubSectionWrapper key={subsection}>
          <PhotoUploadComponent name={`${sectionId}.${subsection}`} subSectionConfig={subsectionConfig} />
          <GradesComponent name={`${sectionId}.${subsection}`} grades={subsectionConfig.possibleGrades!} />
          <Footer name={`${sectionId}.${subsection}`} subSectionConfig={subsectionConfig} />
        </SubSectionWrapper>
      ))}
    </Wrapper>
  );
};
