import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type AuctionVehiclesState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
  openedRelistDialogId: number | null;
  shouldRelistAfterPull: boolean;
}

export const getOrder = () => {
  const cachedOrder = localStorage.getItem('auctionVehiclesOrder')
  return cachedOrder ?? 'by_time'
}

const setOrder = (order: string) => { 
  localStorage.setItem('auctionVehiclesOrder', order)
}

const initialState: AuctionVehiclesState = {
  page: 1,
  perPage: 24,
  filters: {
    order_by: getOrder()
  },
  openedRelistDialogId: null,
  shouldRelistAfterPull: false,
};

const auctionVehiclesSlice = createSlice({
  name: 'auctionVehicles',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<number>) => {
        console.log('set page', action.payload)
        state.page = action.payload;
      },
      setFilters(state, action: PayloadAction<Record<string, any>>) {
        state.filters = action.payload
      },
      setFilterByKey(state, action: PayloadAction<{key: string, value: string}>) {
        const { value, key } = action.payload;
        if (value === '') { 
          delete state.filters[key]
        } else {
          state.filters[key] = value
        }

        if (key === 'order_by') {
          setOrder(value)
        }

        state.page = 1
      },
      setAuctionId(state, action: PayloadAction<number>) {
        state = initialState 
        state.filters.auction_id = action.payload
      },
      resetFilters(state) {
        state.page = 1
        state.perPage = 24
        state.filters = {
          order_by: getOrder()
        }
      },
      setShouldRelistAfterPull(state) {
        state.shouldRelistAfterPull = true
      },
      openRelistDialog(state, action: PayloadAction<number>) {
        state.openedRelistDialogId = action.payload
        state.shouldRelistAfterPull = false
      },
      closeRelistDialog(state) { 
        state.openedRelistDialogId = null
        state.shouldRelistAfterPull = false
      }
    }
});

export const { setPage, setFilterByKey, resetFilters, setAuctionId, openRelistDialog, closeRelistDialog, setShouldRelistAfterPull } = auctionVehiclesSlice.actions
export default auctionVehiclesSlice;
