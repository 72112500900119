import { EventChannel, eventChannel } from 'redux-saga';
import Io, { Socket } from 'socket.io-client';
import { put, call, take, takeEvery, all } from 'redux-saga/effects';
import { SUBSCRIBE_TO_WS, UNSUBSCRIBE_FROM_WS } from '../constants/actionTypes';
import getSocket from '../services/socket';

const WEBSOCKET_URL = import.meta.env.VITE_WS_API_URL;

let socketConnection: EventChannel<Socket> | null = null;

function createEventChannel(socket: Socket, subscriptions: any[]): any {
  return eventChannel(emit => {
    // socket.on('connect', () => {
    //   // Subscribe to multiple channels
    //   subscriptions.forEach(({ to, onMessage }) => {
    //     socket.emit('subscribe', to);
    //   });
    // });
    subscriptions.forEach(({ to, onMessage }) => {
      socket.emit('subscribe', to);
    });
    socket.on('message', emit);
    return () => {
      socket.off('message', emit);
    };
  });
}

function* initializeWebSocketsChannel({ payload: settings }: any) {
  // const socket = Io(WEBSOCKET_URL!, {
  //   transports: ['websocket'],
  //   reconnection: true,
  //   reconnectionDelay: 1000,
  //   reconnectionDelayMax: 5000,
  //   reconnectionAttempts: Infinity
  // });
  const socket = getSocket();
  // console.log(socket, 'socket')
  if (!socket.connected) {
    socket.connect();
  }
  socketConnection = yield call(createEventChannel, socket, settings.subscribe);
  while (true) {
    const { type, payload } = yield take(socketConnection!);

    yield put({ type, payload });
  }
}

const unsubscribe = () => {
  if (socketConnection) {
    socketConnection.close();
  }
};

export default function* socketSaga() {
  yield all([takeEvery(SUBSCRIBE_TO_WS, initializeWebSocketsChannel), takeEvery(UNSUBSCRIBE_FROM_WS, unsubscribe)]);
}
