import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import React from 'react'


const StyledTitle = styled(Typography)`
  && {
    margin: 40px 0;
    margin-left:200px;

    @media (max-width: 600px) {
      margin: 20px 0;
      margin-left:70px;
    }
  }
`;


const OfficePhoneLabel = () => (
  <StyledTitle>
    {/* Office Phone: 925-315-4033 */}
  </StyledTitle>
)


export default OfficePhoneLabel
