import { Vehicle } from 'types/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGetRelistDataQuery, useRelistVehicleMutation } from 'services/api/admin-auction-vehicles';
import { Button, Dialog } from '@material-ui/core';
import { closeRelistDialog } from 'services/auctionVehicles/auctionVehiclesSlice';
import { Field, Form } from 'react-final-form';
import { FormFormattedField, FormTextField } from 'components';
import moment from 'moment';
import { formatCurrency, normalizeFormattedField } from 'utils';
import { RelistInputField } from './components/FilledInput';
import createDecorator from 'final-form-calculate';
import { DownArrow, UpArrow } from '../../img/arrows';
import { composeValidators, maxLength } from '../../utils/validators';
import ConfirmModal from '../Modals/ConfirmModal';
import { modalsHide, modalsToggle } from '../../actions';
import { GreyInfoIcon } from '../../img/grey-info';

type Props = {
  vehicle: Vehicle;
};

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;
  gap: 32px;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  grid-auto-rows: 1fr;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const Cross = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.22188 18.8258L5.17188 17.7758L10.9469 12.0008L5.17188 6.22578L6.22188 5.17578L11.9969 10.9508L17.7719 5.17578L18.8219 6.22578L13.0469 12.0008L18.8219 17.7758L17.7719 18.8258L11.9969 13.0508L6.22188 18.8258Z"
      fill="#212121"
    />
  </svg>
);

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 44px;
`;

const NotesLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDialog = styled(Dialog)``;

const formatTimestamp = (timestamp: string) => {
  return moment(timestamp).format('MM/DD/YY hh:mm A');
};

const calculator: any = createDecorator({
  field: 'blueBookValue',
  updates: {
    difference: (blueBookValue: string, allValues: any) => {
      console.log(allValues, blueBookValue);
      return (Number(blueBookValue) || 0) - (Number(allValues.currentBlueBookValue) || 0);
    }
  }
});

const formatMaxOffer = (maxOffer?: number, proxy?: number) => {
  if (maxOffer && proxy) {
    return `${formatCurrency(maxOffer)}/${formatCurrency(proxy)}`;
  }

  return '';
};

const maxNotesLength = maxLength(50);
const maxFieldLength = maxLength(30);
const maxCounterLength = maxLength(25);

const maxDropdownLength = (opt: { value: string | number; label: string }) =>
  !opt?.value || typeof opt.value === 'number' || opt.value.length <= 70
    ? undefined
    : `Must have a length less than 70 characters`;

const parseMaxOfferValue = (value: string) => {
  const [first, second] = value?.split('/') ?? [];
  const maxOffer = Number(first?.replace(/\D/g, ''));
  const proxyOffer = Number(second?.replace(/\D/g, ''));
  return [maxOffer, proxyOffer];
};

const maxOfferValidator = (value: string) => {
  if (!value) return '';
  const [maxOffer, maxProxy] = parseMaxOfferValue(value);
  if (!maxOffer || !maxProxy) return 'Invalid offer amount';
};

export const RelistDialog = ({ vehicle }: Props) => {
  const dispatch = useDispatch();

  const { openedRelistDialogId } = useSelector((state: any) => state.auctionVehicles);

  const shouldRender = openedRelistDialogId && openedRelistDialogId === vehicle.id;

  const { data } = useGetRelistDataQuery(vehicle.id, { skip: !shouldRender });

  const [relistVehicle] = useRelistVehicleMutation();

  if (!shouldRender || !data) {
    return null;
  }

  const closeModal = () => {
    dispatch(closeRelistDialog());
    dispatch(modalsHide(`cancel-relist-${vehicle.id}`));
  };

  const { activeAuctions, listOfBuyers } = data;
  const auctionOptions = activeAuctions.map(el => ({
    label: `#${el.slug ?? el.id} ${formatTimestamp(el.startDate)}`,
    value: el.id
  }));

  const buyerOptions = listOfBuyers.map(el => ({
    label: el,
    value: el
  }));

  const initialValues = {
    auctionId: auctionOptions[0],
    initialReserve: data.initialReserve,
    maxOffer: formatMaxOffer(data.maxOffer, data.proxyOffer),
    finalReserve: data.finalReserve ?? 0,
    seller: data.seller ?? '',
    blueBookValue: data.latestBlueBookValue,
    currentBlueBookValue: data.blueBookValue,
    difference: (data.latestBlueBookValue || 0) - data.blueBookValue,
    newReserve: '',
    aaCounter: data.aaCounter,
    minSellerWillTake: '',
    buyer: buyerOptions[0],
    notes: '',
    startingBid: data.startingBid || 100
  };

  const onSubmit = (values: typeof initialValues) => {
    const [maxOffer, maxProxy] = parseMaxOfferValue(values.maxOffer);
    relistVehicle({
      auctionId: values.auctionId?.value,
      vehicleId: vehicle.id,
      maxOffer,
      maxProxy,
      newReserve: Number(values.newReserve) ?? null,
      initialReserve: values.initialReserve,
      finalReserve: values.finalReserve,
      aaCounter: values.aaCounter,
      minSellerWillTake: values.minSellerWillTake,
      difference: values.difference,
      notes: values.notes,
      buyer: values.buyer?.value,
      seller: values.seller,
      blueBookValue: values.blueBookValue,
      startingBid: Number(values.startingBid)
    });
    closeModal();
  };

  const handleCancel = (isDirty: boolean) => {
    if (isDirty) {
      dispatch(modalsToggle(`cancel-relist-${vehicle.id}`));
    } else {
      closeModal();
    }
  };

  const PatchedFormTextField = FormTextField as any;

  return (
    <StyledDialog open={shouldRender} onBackdropClick={closeModal} fullWidth maxWidth={'sm'}>
      <Form name={`relist-${vehicle.id}`} initialValues={initialValues} onSubmit={onSubmit} decorators={[calculator]}>
        {({ handleSubmit, values, dirty }) => {
          return (
            <Wrapper onSubmit={handleSubmit}>
              <TitleRow>
                <Title>{`Relist ${data.title.toUpperCase()}`}</Title>
                <div onClick={closeModal}>
                  <Cross />
                </div>
              </TitleRow>
              <FormWrapper>
                <Field label="Auction" name="auctionId">
                  {props => <RelistInputField options={auctionOptions} {...props} />}
                </Field>
                <Field
                  label="Initial Reserve"
                  name="initialReserve"
                  component={FormFormattedField}
                  parse={normalizeFormattedField}
                  prefix="$"
                  thousandSeparator
                  disabled
                  placeholder="-"
                  styleVariant="filled"
                />
                <Field
                  label="Max Offer"
                  name="maxOffer"
                  component={PatchedFormTextField}
                  validate={composeValidators(maxFieldLength, maxOfferValidator)}
                  styleVariant="filled"
                />
                <Field
                  label="Final Reserve"
                  name="finalReserve"
                  component={FormFormattedField}
                  styleVariant="filled"
                  disabled
                  prefix="$"
                  thousandSeparator
                  placeholder="-"
                  parse={normalizeFormattedField}
                />
                <Field label="From Buyer" name="buyer" validate={maxDropdownLength}>
                  {props => <RelistInputField isCreatable options={[...buyerOptions]} {...props} />}
                </Field>
                <Field
                  label="Blue Book value"
                  name="blueBookValue"
                  component={FormFormattedField}
                  prefix="$"
                  thousandSeparator
                  parse={normalizeFormattedField}
                  styleVariant="filled"
                />
                <Field
                  label="Seller"
                  name="seller"
                  validate={maxFieldLength}
                  component={PatchedFormTextField}
                  styleVariant="filled"
                  placeholder=""
                />
                <Field
                  label="Difference"
                  name="difference"
                  component={FormFormattedField}
                  prefix="$"
                  thousandSeparator
                  styleVariant="filled"
                  disabled
                  placeholder="-"
                  allowNegative={false}
                  Icon={(values as any).difference > 0 ? UpArrow : DownArrow}
                />
                <Field
                  label="New Reserve"
                  name="newReserve"
                  component={FormFormattedField}
                  parse={normalizeFormattedField}
                  prefix="$"
                  validate={maxCounterLength}
                  thousandSeparator
                  styleVariant="filled"
                />
                <Field
                  name="aaCounter"
                  component={FormFormattedField}
                  parse={normalizeFormattedField}
                  prefix="$"
                  thousandSeparator
                  placeholder="-"
                  label="Counter to buyers"
                  styleVariant="filled"
                />
                <Field
                  name="minSellerWillTake"
                  component={FormFormattedField}
                  parse={normalizeFormattedField}
                  prefix="$"
                  validate={maxCounterLength}
                  thousandSeparator
                  label="Min Seller will take"
                  styleVariant="filled"
                />
                <Field
                  name="startingBid"
                  component={FormFormattedField}
                  parse={normalizeFormattedField}
                  prefix="$"
                  thousandSeparator
                  label="Starting Bid"
                  styleVariant="filled"
                />
                <Field name="notes" validate={maxNotesLength}>
                  {props => (
                    <div style={{ gridColumn: 'span 2' }}>
                      <NotesLabel>
                        <span>Notes</span>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <span>Max. 50 characters</span>
                          <GreyInfoIcon />
                        </div>
                      </NotesLabel>
                      <FormTextField fullWidth styleVariant="filled" {...props} />
                    </div>
                  )}
                </Field>
                <StyledButton
                  variant="contained"
                  color="default"
                  onClick={e => {
                    e.preventDefault();
                    handleCancel(dirty);
                  }}
                >
                  CANCEL
                </StyledButton>
                <StyledButton type="submit" variant="contained" color="primary" size="small" onClick={handleSubmit}>
                  RELIST
                </StyledButton>
              </FormWrapper>
            </Wrapper>
          );
        }}
      </Form>
      <ConfirmModal
        modalId={`cancel-relist-${vehicle.id}`}
        handleSubmit={closeModal}
        title={`Are use sure you want to cancel? All data will be lost.`}
        submitText={'Confirm'}
      />
    </StyledDialog>
  );
};
