import { animateScroll } from 'react-scroll';

const flatten = arr =>
  arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  );

const getErrorFieldNames = (obj, name = '') => {
  const errorArr = [];
  errorArr.push(
    Object.keys(obj)
      .map(key => {
        const next = obj[key];
        if (next) {
          if (typeof next === 'string') {
            return name + key;
          }
          if (typeof next === 'object') {
            return errorArr.push(
              Object.keys(next).map(errorKey => `${key}.${errorKey}`)
            );
          }
          // Keep looking
          if (next.map) {
            errorArr.push(
              next
                .map((item, index) =>
                  getErrorFieldNames(item, `${name}${key}[${index}].`)
                )
                .filter(o => o)
            );
          }
        }
        return null;
      })
      .filter(o => o)
  );
  return flatten(errorArr);
};

const getElementOffset = el => {
  if (el.type === 'hidden' || el.type === 'file') {
    // eslint-disable-next-line
    el = el.parentElement;
  }
  const rect = el.getBoundingClientRect
    ? el.getBoundingClientRect()
    : { top: 0, left: 0 };

  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset
  };
};

const scrollToFirstError = (errors, offset = -100) => {
  const errorFields = getErrorFieldNames(errors);
  // Using breakable for loop
  for (let i = 0; i < errorFields.length; i += 1) {
    const fieldName = `${errorFields[i]}`;
    // Checking if the marker exists in DOM
    const elements = document.querySelectorAll(`[name="${fieldName}"]`);

    if (elements.length) {
      const elementOffset = getElementOffset(elements[0]);
      animateScroll.scrollTo(elementOffset.top + offset); // animate directly to the right position
      break;
    } else {
      animateScroll.scrollTo(0);
    }
  }
};

export default scrollToFirstError;
