import React from 'react';
import { ButtonBase, Icon, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { PageHeader } from '../../../../components';
import { filterBids } from '../../../../actions/bids';

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  padding: 23px 0;
  span {
    margin-right: 5px;
    font-size: 18px;
  }
`;

const Header = ({ setPage, refreshList }) => (
  <PageHeader>
    <StyledTypography variant="title">Bid History</StyledTypography>
    <span className="flex-grow" />
    <ButtonBase
      onClick={() => {
        setPage(1);
        refreshList();
        setPage(2);
      }}
    >
      <Icon>refresh</Icon>
    </ButtonBase>
  </PageHeader>
);

const mapDispatchToProps = (dispatch, { perPage }) => ({
  refreshList: () =>
    dispatch(filterBids({ params: { limit: perPage, offset: 1 } }).request)
});

export default connect(
  null,
  mapDispatchToProps
)(Header);
