import React from 'react';
import styled from 'styled-components';
import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_STATUSES,
  VEHICLE_STATUSES,
  SELLER_VEHICLE_LABELS,
  SELLER_VEHICLE_STATUS
} from '../../../../constants';
import { FormattedAmount } from '../../../../components';

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};
`;

const BidStatus = ({ payment_method: paymentMethod, payment_status: paymentStatus, status, counterAmount, sellerAcceptedAmount, awardedAmount }) => {
  const isAwarded = status === VEHICLE_STATUSES.AWARDED;
  const easyPaid = paymentMethod === PAYMENT_METHODS.EASY_PAY && paymentStatus === PAYMENT_METHODS_STATUSES.APPROVED;

  return (
    <Wrapper>
      <StyledStatus statusColor={SELLER_VEHICLE_LABELS[status]?.color}>
        {SELLER_VEHICLE_LABELS[status]?.label || 'None'}
        {isAwarded && easyPaid && ' (EasyPay)'}
      </StyledStatus>
      {status === SELLER_VEHICLE_STATUS.AWARDED && <FormattedAmount amount={awardedAmount ?? 0} />}
      {status === SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS && <FormattedAmount amount={counterAmount ?? 0} />}
      {status === SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT && <FormattedAmount amount={counterAmount ?? 0} />}
      {status === SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL && <FormattedAmount amount={sellerAcceptedAmount ?? 0} />}
    </Wrapper>
  );
};

export default BidStatus;
