import { RegularSectionContainer } from '../../components/RegularSectionContainer';
import { useIsAutomaticUpload } from '../../hooks/useIsAutomaticUpload';
import { AutomaticVehicleInformationForm } from './AutomaticUploadForm';
import { ManualVehicleInformationForm } from './ManualUploadForm';

export const VehicleInformation = () => {
  const isAutomaticUpload = useIsAutomaticUpload();

  return (
    <RegularSectionContainer title="Vehicle Information">
      {isAutomaticUpload ? <AutomaticVehicleInformationForm /> : <ManualVehicleInformationForm />}
    </RegularSectionContainer>
  );
};

const ConditionalSelectField = ({ name }: { name: string }) => {};
