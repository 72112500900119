import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketMessage } from '../api/types';
import { WS_VEHICLE_TIME_END, WS_NEW_PROXY, WS_VEHICLE_DETAILS_UPDATE, WS_NEW_BID } from 'constants/actionTypes';
import { auctionVehiclesAdapter, adminAuctionVehiclesSlice as api } from 'services/api/admin-auction-vehicles';
import getSocket from '.';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useParams } from 'react-router-dom';

export const useAdminVehiclesSocketEffect = (data: any) => {
  const dispatch = useDispatch() as ThunkDispatch<any, any, AnyAction>;
  const user = useSelector((state: any) => state.user.user);

  const { page, perPage, filters } = useSelector((state: any) => state.auctionVehicles);
  const { id: auctionId } = useParams<{ id: string }>();

  useEffect(() => {
    const listener = ({ payload, type }: SocketMessage) => {
      if (type === WS_NEW_BID) {
        dispatch(
          api.util.updateQueryData(
            'getAuctionVehicles',
            {
              limit: perPage,
              offset: page,
              auction_id: auctionId,
              ...filters
            },
            draft => {
              auctionVehiclesAdapter.updateOne(draft.rows, {
                id: payload.vehicle_id,
                changes: {
                  highest_bid: payload
                }
              });
            }
          )
        );
      }

      if (type === WS_NEW_PROXY) {
        dispatch(
          api.util.updateQueryData(
            'getAuctionVehicles',
            {
              limit: perPage,
              offset: page,
              auction_id: auctionId,
              ...filters
            },
            draft => {
              auctionVehiclesAdapter.updateOne(draft.rows, {
                id: payload.vehicle_id,
                changes: {
                  proxy_bid: payload
                }
              });
            }
          )
        );
      }

      if (type === WS_VEHICLE_DETAILS_UPDATE) {
        dispatch(
          api.util.updateQueryData(
            'getAuctionVehicles',
            {
              limit: perPage,
              offset: page,
              auction_id: auctionId,
              ...filters
            },
            draft => {
              auctionVehiclesAdapter.updateOne(draft.rows, { id: payload.id, changes: payload });
            }
          )
        );
      }

      if (type === WS_VEHICLE_TIME_END) {
        dispatch(
          api.util.updateQueryData(
            'getAuctionVehicles',
            {
              limit: perPage,
              offset: page,
              auction_id: auctionId,
              ...filters
            },
            draft => {
              const record = auctionVehiclesAdapter.getSelectors().selectById(draft.rows, payload.vehicle_id);
              if (!record) return;
              auctionVehiclesAdapter.removeOne(draft.rows, record.id);
              auctionVehiclesAdapter.addOne(draft.rows, { ...record });
            }
          )
        );
      }
    };

    const vehicleIds = data?.rows?.ids ?? [];
    const socket = getSocket();
    socket.emit('subscribe', {
      prefix: `vehicles`,
      ids: vehicleIds
    });

    socket.on('message', listener);

    return () => {
      socket.emit('unsubscribe', {
        prefix: `vehicles`,
        ids: vehicleIds
      });
      socket.off('message', listener);
    };
  }, [dispatch, data?.rows?.ids, perPage, page, filters, user.id, auctionId]);
};
