import { maxLength } from '../utils/validators';
import { REGISTRATION_MAX_STRING_LENGTH } from './index';

export const VEHICLE_CREATION_FORM = 'VEHICLE_CREATION_FORM';

export const EASY_PAY_PURCHASE_FORM = 'EASY_PAY_PURCHASE_FORM';

export const VEHICLE_CREATION_DEFAULT_OPTIONS = [
  'ABS (4-Wheel)',
  'Cruise Control',
  'Bluetooth Wireless',
  'Sun Roof',
  'Air Conditioning',
  'Power Steering',
  'DVD System',
  'Moon Roof',
  'Rear air',
  'Tilt Wheel',
  'Heated Seats',
  'Panorama Roof',
  'Conditioning',
  'AM/FM Stereo',
  'Cooled Seats',
  'Rear Spoiler',
  'Sliding Rear',
  'Cassette',
  'Power Seat',
  'Bed Liner',
  'Window',
  'CD Player',
  'Dual Power Seats',
  'Towing Pkg',
  'Power Rear Slider',
  'CD Changer',
  'Leather',
  'Alloy Wheels',
  'Power Windows',
  'Premium Sound',
  'Running Boards',
  'Premium Wheels',
  'Power Locks',
  'Navigation system',
];

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

export const ACH_FORM_FIELDS = [
  {
    name: 'ach_form.full_account_name',
    label: 'Full account name'
  },
  {
    name: 'ach_form.bank_account',
    label: 'Bank account #'
  },
  {
    name: 'ach_form.bank_routing',
    label: 'Bank routing #',
    sm: 6
  },
  {
    name: 'ach_form.bank_name',
    label: 'Bank name',
    sm: 6
  },
  {
    name: 'ach_form.bank_address',
    label: 'Bank address',
    validate: undefined,
  },
  {
    name: 'ach_form.bank_contact',
    label: 'Bank contact',
    validate: undefined,
    sm: 6
  },
  {
    name: 'ach_form.bank_phone',
    label: 'Bank phone',
    validate: undefined,
    sm: 6
  }
];

export const FLOORING_FORM_FIELDS = [
  {
    name: 'flooring_form.name_act',
    label: 'Name on Acct'
  },
  {
    name: 'flooring_form.act',
    label: 'Acct #'
  },
  {
    name: 'flooring_form.auction_access',
    label: 'Auction Access #',
    validation: maxStringLength
  }
];
