import React from 'react';
import styled from 'styled-components';
import { Grid, Paper, Typography, Button, Divider } from '@material-ui/core';

import { PageWrapper } from '../../../components';
import { getUser } from '../../../utils/auth';

import shieldIcon from '../../../img/Shield.png';
import eyeIcon from '../../../img/eye.png';
import addIcon from '../../../img/Add.png';

const StyledSection = styled(Paper)`
  && {
    max-width: 856px;
    border-radius: 0;
    margin: 24px auto 0;
    padding: 32px 110px 48px 110px;
    @media (max-width: 960px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
`;

const StyledTitle = styled(Typography)`
  && {
    font-size: 32px;
    margin: 40px 0;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
`;

const TypographyWithMarginBot = styled(Typography)`
  && {
    margin-bottom: 1em;
    @media (max-width: 960px) {
      margin-left: 48px;
    }
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: -4px;
  left: -48px;
  @media (max-width: 960px) {
    left: 0;
  }
  @media (max-width: 600px) {
    left: 0;
    top: -4px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    width: calc(100% + 48px);
    margin: 48px 0 48px -24px;
    @media (max-width: 960px) {
      width: 100%;
      margin: 20px 0;
    }
  }
`;

const sections = [
  {
    title: 'Our Mission',
    paragraphs: [
      'AutoAxess was developed to make it fast, easy, and inexpensive for an independent used car dealer to find and buy fresh trade-ins directly from franchised dealers.  By using sophisticated technology to match franchised dealers offering unwanted trade-ins with qualified wholesale buyers, AutoAxess was born!',
      'Finding quality used vehicles can be expensive and time consuming.  Traditional auto auctions are gigantic, expensive, and impersonal.  AutoAxess nimbly avoids these issues by creating a streamlined on-line buying process.'
    ],
    icon: shieldIcon
  },
  {
    title: 'Our Story',
    paragraphs: [
      'Mark Codiroli, Autoaxess President, grew up in the retail automobile industry eventually becoming a successful Ford, Mazda, Chrysler-Jeep dealer for over 20 years.  When it came to wholesaling unwanted trade-ins, he used physical auctions, but he found them to be extremely inefficient and painfully expensive.',
      'Having tried traditional auctions, he developed a network of wholesalers and began "hand selling" his unwanted trades.  As the dealership grew, this became very time consuming and difficult to manage.',
      'Realizing that there had to be a better way, he started AutoAxess, which simply matches trade ins from the franchised dealer with independent used car dealer buyers, at less that half the cost of traditional auctions. AutoAxess was born!'
    ],
    icon: eyeIcon
  },
  {
    title: 'Join Us',
    paragraphs: [
      'Save money and time, and buy your inventory on line.',
      'Welcome to AutoAxess.'
    ],
    icon: addIcon
  }
];

const AboutPage = ({ history }) => {
  const isUserLogged = Object.keys(getUser()).length === 0;

  return (
    <PageWrapper>
      <StyledSection>
        <StyledTitle variant="title">About Us</StyledTitle>
        <Grid container>
          {sections.map((section, i) => (
            <Grid key={i} item xs={12} style={{ position: 'relative' }}>
              <StyledImg src={section.icon} alt="shield" />
              <TypographyWithMarginBot variant="display1">
                {section.title}
              </TypographyWithMarginBot>
              {section.paragraphs.map((p, j) => (
                <Typography gutterBottom key={`${i}${j}`}>
                  {p}
                </Typography>
              ))}
              {i !== sections.length - 1 && <StyledDivider />}
            </Grid>
          ))}
          <Grid item xs={12}>
            {isUserLogged && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/register')}
              >
                join us
              </Button>
            )}
          </Grid>
        </Grid>
      </StyledSection>
    </PageWrapper>
  );
};

export default AboutPage;
