import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { TableBody, Table, TableRow, TableCell } from '@material-ui/core';
import { DocumentLayout, FormattedAmount } from '../../../components';
import { financials } from '../../../utils/api';
import { DATE_FORMAT } from '../../../constants';

import logoImg from '../../../img/logo.svg';

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: 7.5px;
    font-size: 18px;
  }
`;

const HighlightedText = styled.span`
  background: yellow;
`;


class FundingNotice extends Component {
  state = {
    vehicleDetails: null,
    loading: true
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    financials.fetchFundingNotice(id).then(({ data }) => {
      this.setState({
        vehicleDetails: data.data,
        loading: false
      });
    }).catch(({ response }) => {
      if (response.status === 404) {
        this.setState({
          loading: false
        });
      }
    });
  }

  render() {
    const { loading, vehicleDetails } = this.state;

    if (loading) return <DocumentLayout>Loading</DocumentLayout>;
    if (!vehicleDetails) return <DocumentLayout>Not Found</DocumentLayout>;

    const { awarded_user: awardedUser } = vehicleDetails;

    const sellFee = (vehicleDetails.user && vehicleDetails.user.seller && vehicleDetails.user.seller.fee)
      ? +vehicleDetails.user.seller.fee
      : 0;
    const sellerShowFee = vehicleDetails.user.seller.show_fee;
    const fundingNoticeVehiclePrice = sellerShowFee ? vehicleDetails.vehicle_price - sellFee : vehicleDetails.vehicle_price;

    const pOptions = (vehicleDetails.user && vehicleDetails.user.seller && vehicleDetails.user.seller.purchase_options)
      ? vehicleDetails.user.seller.purchase_options : [];
    const isAchOnlySeller = pOptions.length === 1 && pOptions[0].option_name === 'ach_flooring';

    return (
      <DocumentLayout>
        <StyledPageHeader>
          <img src={logoImg} alt="logo" />
          <span>EasyPay</span>
          <span>Funding Notice</span>
        </StyledPageHeader>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Auction Date:</TableCell>
              <TableCell>{moment.utc(vehicleDetails.auction.date_end).local().format(DATE_FORMAT)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vehicle Decription:</TableCell>
              <TableCell>{`${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VIN:</TableCell>
              <TableCell>{vehicleDetails.vin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Seller:</TableCell>
              <TableCell>{vehicleDetails.user.dealership_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Buyer Info:</TableCell>
              <TableCell>
                {`${awardedUser.dealership_name}, Dlr: ${awardedUser.buyer.dealer_license_number}`}
                <br />
                {awardedUser.street}
                <br />
                {`${awardedUser.city}, ${awardedUser.state} ${awardedUser.zip}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {isAchOnlySeller ? 'Net Sale Amount:' : 'Sale Amount:'}
              </TableCell>
              <TableCell>
                <FormattedAmount amount={fundingNoticeVehiclePrice} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <p>
          Please note that the above vehicle is being floored through AutoAxess EasyPay.
          The payment is guaranteed by AutoAxess and the vehicle can be released to the
          above Buyer (or Buyer’s transport).
          {' '}
          <HighlightedText>
            Please mark cleared title (no duplicate
            applications will be accepted) sold to the above Buyer, not to AutoAxess
          </HighlightedText>
          {' '}
          and mail title to:
        </p>
        AutoAxess
        <br />
        486 S K Street
        <br />
        Livermore, CA 94550
        <p>
          Once AutoAxess receives the title (and any accompanying documents),
          AutoAxess will send payment to above Seller.
        </p>
        <p>
          If you have any questions, please feel free to call (925) 315-4033 or email info@autoaxess.com.
        </p>
      </DocumentLayout>
    );
  }
}

export default FundingNotice;
