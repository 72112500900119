import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const StyledPaper = styled(Paper)`
  padding: 21px 32px 21px 25px;
  margin-bottom: 16px;

  > p:last-child {
    margin-bottom: 0;
  }
`;

const SectionWrapper = ({ children, className }) => (
  <StyledPaper className={className}>{children}</StyledPaper>
);

export default SectionWrapper;
