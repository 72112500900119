import styled from 'styled-components';
import { SellerAndTransportInfo } from './SellerAndTransportInfo';
import { PaymentInfo } from './PaymentInfo';
import { SaleTerms } from './SaleTerms';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  & > div {
    flex: 1 1 100%;
  }
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const VehicleSaleBlock = () => {
  return (
    <Wrapper>
      <SellerAndTransportInfo />
      <RightPart>
        <PaymentInfo />
        <SaleTerms />
      </RightPart>
    </Wrapper>
  );
};
