export const BuyerDocsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.99984 11.9997H9.99984C10.1887 11.9997 10.3471 11.9358 10.4748 11.808C10.6026 11.6802 10.6665 11.5219 10.6665 11.333C10.6665 11.1441 10.6026 10.9858 10.4748 10.858C10.3471 10.7302 10.1887 10.6663 9.99984 10.6663H5.99984C5.81095 10.6663 5.65262 10.7302 5.52484 10.858C5.39706 10.9858 5.33317 11.1441 5.33317 11.333C5.33317 11.5219 5.39706 11.6802 5.52484 11.808C5.65262 11.9358 5.81095 11.9997 5.99984 11.9997ZM5.99984 9.33301H9.99984C10.1887 9.33301 10.3471 9.26912 10.4748 9.14134C10.6026 9.01356 10.6665 8.85523 10.6665 8.66634C10.6665 8.47745 10.6026 8.31912 10.4748 8.19134C10.3471 8.06356 10.1887 7.99967 9.99984 7.99967H5.99984C5.81095 7.99967 5.65262 8.06356 5.52484 8.19134C5.39706 8.31912 5.33317 8.47745 5.33317 8.66634C5.33317 8.85523 5.39706 9.01356 5.52484 9.14134C5.65262 9.26912 5.81095 9.33301 5.99984 9.33301ZM3.99984 14.6663C3.63317 14.6663 3.31928 14.5358 3.05817 14.2747C2.79706 14.0136 2.6665 13.6997 2.6665 13.333V2.66634C2.6665 2.29967 2.79706 1.98579 3.05817 1.72467C3.31928 1.46356 3.63317 1.33301 3.99984 1.33301H8.78317C8.96095 1.33301 9.13039 1.36634 9.2915 1.43301C9.45262 1.49967 9.59428 1.59412 9.7165 1.71634L12.9498 4.94967C13.0721 5.0719 13.1665 5.21356 13.2332 5.37467C13.2998 5.53579 13.3332 5.70523 13.3332 5.88301V13.333C13.3332 13.6997 13.2026 14.0136 12.9415 14.2747C12.6804 14.5358 12.3665 14.6663 11.9998 14.6663H3.99984ZM8.6665 5.33301V2.66634H3.99984V13.333H11.9998V5.99967H9.33317C9.14428 5.99967 8.98595 5.93579 8.85817 5.80801C8.73039 5.68023 8.6665 5.5219 8.6665 5.33301Z"
      fill="#225A91"
    />
  </svg>
);

export const ReportOfSaleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M5.33333 6C5.52222 6 5.68056 5.93611 5.80833 5.80833C5.93611 5.68056 6 5.52222 6 5.33333C6 5.14444 5.93611 4.98611 5.80833 4.85833C5.68056 4.73056 5.52222 4.66667 5.33333 4.66667C5.14444 4.66667 4.98611 4.73056 4.85833 4.85833C4.73056 4.98611 4.66667 5.14444 4.66667 5.33333C4.66667 5.52222 4.73056 5.68056 4.85833 5.80833C4.98611 5.93611 5.14444 6 5.33333 6ZM5.33333 8.66667C5.52222 8.66667 5.68056 8.60278 5.80833 8.475C5.93611 8.34722 6 8.18889 6 8C6 7.81111 5.93611 7.65278 5.80833 7.525C5.68056 7.39722 5.52222 7.33333 5.33333 7.33333C5.14444 7.33333 4.98611 7.39722 4.85833 7.525C4.73056 7.65278 4.66667 7.81111 4.66667 8C4.66667 8.18889 4.73056 8.34722 4.85833 8.475C4.98611 8.60278 5.14444 8.66667 5.33333 8.66667ZM5.33333 11.3333C5.52222 11.3333 5.68056 11.2694 5.80833 11.1417C5.93611 11.0139 6 10.8556 6 10.6667C6 10.4778 5.93611 10.3194 5.80833 10.1917C5.68056 10.0639 5.52222 10 5.33333 10C5.14444 10 4.98611 10.0639 4.85833 10.1917C4.73056 10.3194 4.66667 10.4778 4.66667 10.6667C4.66667 10.8556 4.73056 11.0139 4.85833 11.1417C4.98611 11.2694 5.14444 11.3333 5.33333 11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H10.1167C10.2944 2 10.4639 2.03333 10.625 2.1C10.7861 2.16667 10.9278 2.26111 11.05 2.38333L13.6167 4.95C13.7389 5.07222 13.8333 5.21389 13.9 5.375C13.9667 5.53611 14 5.70556 14 5.88333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM3.33333 12.6667H12.6667V6H10.6667C10.4778 6 10.3194 5.93611 10.1917 5.80833C10.0639 5.68056 10 5.52222 10 5.33333V3.33333H3.33333V12.6667Z"
      fill="#225A91"
    />
  </svg>
);

export const FundingNoticeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3.33333 12.6667H9.33333V10C9.33333 9.81111 9.39722 9.65278 9.525 9.525C9.65278 9.39722 9.81111 9.33333 10 9.33333H12.6667V3.33333H3.33333V12.6667ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V9.45C14 9.62778 13.9667 9.79722 13.9 9.95833C13.8333 10.1194 13.7389 10.2611 13.6167 10.3833L10.3833 13.6167C10.2611 13.7389 10.1194 13.8333 9.95833 13.9C9.79722 13.9667 9.62778 14 9.45 14H3.33333ZM7.33333 9.33333H5.33333C5.14444 9.33333 4.98611 9.26944 4.85833 9.14167C4.73056 9.01389 4.66667 8.85556 4.66667 8.66667C4.66667 8.47778 4.73056 8.31944 4.85833 8.19167C4.98611 8.06389 5.14444 8 5.33333 8H7.33333C7.52222 8 7.68056 8.06389 7.80833 8.19167C7.93611 8.31944 8 8.47778 8 8.66667C8 8.85556 7.93611 9.01389 7.80833 9.14167C7.68056 9.26944 7.52222 9.33333 7.33333 9.33333ZM10.6667 6.66667H5.33333C5.14444 6.66667 4.98611 6.60278 4.85833 6.475C4.73056 6.34722 4.66667 6.18889 4.66667 6C4.66667 5.81111 4.73056 5.65278 4.85833 5.525C4.98611 5.39722 5.14444 5.33333 5.33333 5.33333H10.6667C10.8556 5.33333 11.0139 5.39722 11.1417 5.525C11.2694 5.65278 11.3333 5.81111 11.3333 6C11.3333 6.18889 11.2694 6.34722 11.1417 6.475C11.0139 6.60278 10.8556 6.66667 10.6667 6.66667Z"
      fill="#225A91"
    />
  </svg>
);
