import { Grid } from '@material-ui/core';
import moment from 'moment';
import { OfficePhoneLabel, StepControl, FormWrapper, AddressComponent, StepperTitle } from '../components';
import { composeValidators, maxLength, required } from '../../../../utils/validators';
import { ONBOARDING_STEPS, DATE_FORMAT, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import { Form } from 'react-final-form';
import OutlinedFormFormattedField from 'components/Form/FinalForm/OutlinedFormFormattedField';
import OutlinedFormTextField from 'components/Form/FinalForm/OutlinedFormTextField';
import { useMeQuery, useOnboardingMutation } from 'services/api/auth';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const dealerLicenseValidation = value =>
  value && /^[a-zA-Z0-9-]{1,10}$/.test(value) ? undefined : 'Dealer license must contain from 1 to 10 digits';

const sellerPermitNumberValidation = value =>
  value && /^[a-zA-Z0-9-]{9}$/.test(value) ? undefined : 'Dealer license must contain 9 digits';

const phoneNumberValidation = value =>
  value && value.replace(/[\D]/g, '').length === 10 ? undefined : 'Phone number must contain 10 digits';
const notRequiredPhoneNumberValidation = value =>
  (value && value.replace(/[\D]/g, '').length === 10) || (value || '').length === 0
    ? undefined
    : 'Phone number must contain 10 digits';

const isValidDate = date => {
  if (!date) {
    return undefined;
  }
  return moment(date, DATE_FORMAT, true).isValid() ? undefined : 'Dealer license expiry date is invalid';
};

const isExpired = date => {
  if (!date) {
    return undefined;
  }
  return moment().isBefore(moment(date, DATE_FORMAT)) ? undefined : 'Dealer license is already expired';
};

const secondBuyerStep = ({ nextStep }) => {
  const [onboarding] = useOnboardingMutation();

  const { data: user } = useMeQuery();

  const onSubmit = async values => {
    const { error } = await onboarding({
      ...values,
      purchase_method: [],
      phone_number: (values.phone_number || '').replace(/[\D]/g, ''),
      secondary_contact_phone: (values.secondary_contact_phone || '').replace(/[\D]/g, ''),
      store_number: (values.store_number || '').replace(/[\D]/g, ''),
      last_step: ONBOARDING_STEPS.THIRD_BUYER_STEP
    });

    if (error) {
      console.error(error);
      return;
    }

    nextStep();
  };

  if (!user) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...user,
        state: '',
        phone_number: user.buyer?.phone_number || '',
        store_number: user.buyer?.store_number || '',
        secondary_contact_name: user.buyer?.secondary_contact_name || '',
        secondary_contact_phone: user.buyer?.secondary_contact_phone || '',

        dealer_license_number: user.buyer?.dealer_license_number || '',
        dealer_license_expiry_date: user.buyer?.dealer_license_expiry_date || '',
        seller_permit_number: user.options?.seller_permit_number || ''
      }}
    >
      {props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle title="Buyer Info" />
              <Grid container>
                <AddressComponent />
                <OutlinedFormFormattedField
                  name="phone_number"
                  label="Mobile Phone Number*"
                  placeholder="(###) ### ####"
                  maskProps={{
                    format: '(###) ###-####',
                    mask: '_'
                  }}
                  validate={composeValidators(required, phoneNumberValidation)}
                />
                <OutlinedFormFormattedField
                  name="store_number"
                  label="Store phone number (Optional)"
                  placeholder="(###) ### ####"
                  maskProps={{
                    format: '(###) ###-####',
                    mask: '_'
                  }}
                  validate={notRequiredPhoneNumberValidation}
                />

                <OutlinedFormTextField
                  name="secondary_contact_name"
                  label="Secondary contact name (Optional)"
                  placeholder="Secondary contact name"
                  validate={maxStringLength}
                />

                <OutlinedFormFormattedField
                  name="secondary_contact_phone"
                  label="Secondary contact phone number (Optional)"
                  placeholder="(###) ### ####"
                  maskProps={{
                    format: '(###) ###-####',
                    mask: '_'
                  }}
                  validate={notRequiredPhoneNumberValidation}
                />

                <OutlinedFormTextField
                  name="dealer_license_number"
                  label="DMV Vehicle Dealer License Number*"
                  placeholder="DMV Vehicle Dealer License Number"
                  validate={composeValidators(required, dealerLicenseValidation)}
                />

                <OutlinedFormFormattedField
                  name="dealer_license_expiry_date"
                  label="DMV Vehicle Dealer License Expiry Date*"
                  placeholder="MM/DD/YYYY"
                  maskProps={{
                    format: '##/##/####',
                    placeholder: DATE_FORMAT,
                    mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']
                  }}
                  validate={composeValidators(required, isValidDate, isExpired)}
                  placeholderOnFocus="MM/DD/YYYY"
                />

                <OutlinedFormTextField
                  name="seller_permit_number"
                  label="Seller Permit Number*"
                  placeholder="Seller permit Number"
                  validate={composeValidators(required, sellerPermitNumberValidation)}
                />
              </Grid>
              <StepControl nextStep={nextStep} />
            </FormWrapper>
            <OfficePhoneLabel />
          </form>
        );
      }}
    </Form>
  );
};

export default secondBuyerStep;
