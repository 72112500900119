import React from 'react';
import { change, destroy } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { loginUser, snackShow } from '../../../actions';
import { minLength, maxLength, required, validateEmail, composeValidators } from '../../../utils/validators';
import { Form } from 'react-final-form';
import { useLoginMutation } from 'services/api/auth';
import { USER_STATUSES } from 'constants';
import { push } from 'connected-react-router';
import { AutoaxessLogo } from '../components/AutoaxessLogo';
import { StyledPaper } from '../components/PageCotainer';
import { hasVisibleFormError } from '../../../utils/form';
import OutlinedFormTextField from '../../../components/Form/FinalForm/OutlinedFormTextField';
import OutlinedFormPasswordField from '../../../components/Form/FinalForm/OutlinedFormPasswordField';
import { setUser } from 'services/userSlice/userSlice';
import { setToken } from 'utils/auth';

const StyledLink = styled(Link)`
  text-decoration: none;
  span {
    text-transform: none;
    font-size: 12px;
    color: #005a96;
  }
`;

const StyledLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const NoAccountText = styled.div`
  font-size: 12px;
  font-weight: 500;
  align-self: flex-start;
  color: #b9b9b9;

  & > span {
    color: #3994de;
    cursor: pointer;
  }

  & > span:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 12px;
    text-transform: none;
  }

  &:disabled {
    background: #005a96;
    opacity: 0.5;
    color: #fff;
  }

  @media (max-width: 768px) {
    && {
      margin-top: 10px;
    }
  }
`;

const LoginText = styled.div`
  align-self: flex-start;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000;
  margin: 12px 0;
`;

const emailFieldValidation = composeValidators(required, validateEmail);

const passwordFieldValidation = composeValidators(required, minLength(6), maxLength(25));

const LoginForm = () => {
  const dispatch = useDispatch();
  // dispatch(loginUser(values).request);

  const [login] = useLoginMutation();

  const submitForm = async values => {
    const { data, error } = await login(values);
    if (error) {
      return {
        email: 'Incorrect email',
        password: 'Incorrect password'
      };
    }
    const { user, token } = data;
    localStorage.setItem('token', token);
    if (user.status === USER_STATUSES.NEED_TO_COMPLETE_SIGN_UP) {
      dispatch(snackShow({ message: 'You need to complete registration' }));

      dispatch(setUser(user));

      dispatch(
        push({
          pathname: '/register'
        })
      );
      return;
    }
    dispatch(loginUser(data).success);
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(snackShow({ message: 'Successful login' }));
    if (user.role === 'seller') {
      // return dispatch(push('/seller/dashboard'));
    }

    return dispatch(push('/new-features'));
  };

  return (
    <div className="pageWrapper">
      <StyledPaper>
        <Form initialValues={{ email: '', password: '' }} onSubmit={submitForm}>
          {props => {
            const disableLoginButton =
              (props.submitFailed && !props.dirtySinceLastSubmit) || hasVisibleFormError(props);
            return (
              <StyledForm onSubmit={props.handleSubmit}>
                <AutoaxessLogo />
                <LoginText>Login</LoginText>
                <OutlinedFormTextField
                  name="email"
                  validate={emailFieldValidation}
                  type="email"
                  placeholder="John.smith@gmail.com"
                  label="E-mail*"
                />
                <OutlinedFormPasswordField validate={passwordFieldValidation} />
                <StyledLinkWrapper>
                  <StyledLink to="/forgot-password">
                    <Typography
                      style={{ fontWeight: 300, fontSize: '12px', lineHeight: '14px', color: '#3994DE' }}
                      component="span"
                    >
                      Forgot Password?
                    </Typography>
                  </StyledLink>
                </StyledLinkWrapper>
                <StyledButton disabled={disableLoginButton} variant="raised" color="primary" type="submit" fullWidth>
                  Login
                </StyledButton>
                <NoAccountText>
                  Don't have an account? <span onClick={() => dispatch(push('/register'))}>Register.</span>
                </NoAccountText>
              </StyledForm>
            );
          }}
        </Form>
      </StyledPaper>
    </div>
  );
};

export default LoginForm;
