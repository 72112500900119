import React from 'react';
import { PurchaseOptionsSection } from '../sections';

const PurchaseOptionsTab = ({ user }) => (
  <>
    <PurchaseOptionsSection initialValues={user} />
  </>
);

export default PurchaseOptionsTab;
