import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LiveAuctionState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
};

const initialState: LiveAuctionState = {
  page: 1,
  perPage: 24,
  filters: {}
};

const liveAuctionsSlice = createSlice({
  name: 'liveAuctions',
  initialState,

  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setFilters(state, action: PayloadAction<Record<string, any>>) {
      state.filters = action.payload;
      state.page = 1;
    },
    setFilterByKey(state, action: PayloadAction<{ key: string; value: string }>) {
      const { value, key } = action.payload;
      if (value === '') {
        delete state.filters[key];
      } else {
        state.filters[key] = value;
      }

      state.page = 1;
    },
    resetFilters(state) {
      state.page = 1;
      state.perPage = 24;
      state.filters = {};
    }
  }
});

export const { setPage, setFilters, setFilterByKey, resetFilters } = liveAuctionsSlice.actions
export default liveAuctionsSlice;
