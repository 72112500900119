/* eslint-disable no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import InProgressHeader from './InProgressHeader';
import CustomHeader from './CustomHeader';
import {
  AUCTION_STATUSES,
  VEHICLE_STATUSES,
  USER_STATUSES,
  ADMIN_VEHICLE_STATUS,
  DATE_FORMAT
} from '../../../../../constants';
import { useVehicleStatus } from '../../../../../hooks';

const colorPalette = {
  red: '#D50808',
  orange: '#E47E00',
  green: '#06A000'
};

const AdminRestrictedHeader = props => {
  const { currentVehicle } = props;
  const vehicleStatus = useVehicleStatus(currentVehicle);

  console.log('ADMIN HEADER', vehicleStatus);

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    currentVehicle.end_time_spacing !== null &&
    moment
      .utc(currentVehicle.auction.date_end)
      .add(currentVehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);
  const isAuctionEnded = currentVehicle.auction.status === AUCTION_STATUSES.ENDED;
  const isFeeCharged = currentVehicle.charge_completed;
  const isBuyerDisabled = currentVehicle.awarded_user && currentVehicle.awarded_user.status === USER_STATUSES.DISABLED;

  if (currentVehicle.status === VEHICLE_STATUSES.PULLED) {
    return <CustomHeader {...props} color="red" title="Pulled" />;
  }
  if (currentVehicle.status === VEHICLE_STATUSES.DEACTIVATED) {
    return <CustomHeader {...props} color="red" title="Deactivated" />;
  }

  if (currentVehicle.status === VEHICLE_STATUSES.AWARDED && currentVehicle.buyer_id) {
    return (
      <>
        <CustomHeader
          {...props}
          color="green"
          title={
            <span style={{ color: colorPalette.green }}>
              Awarded to {currentVehicle.awarded_user.dealership_name} on{' '}
              {moment(currentVehicle.date_status).format(DATE_FORMAT)} for{' '}
              <NumberFormat displayType="text" prefix="$" value={currentVehicle.vehicle_price} thousandSeparator />
              {!isFeeCharged && (
                <span style={{ color: colorPalette.orange }}>
                  {' - '}
                  Fee not charged
                </span>
              )}
              {!isFeeCharged && isBuyerDisabled && (
                <span style={{ color: colorPalette.red }}>
                  {' - '}
                  Card Declined
                </span>
              )}
            </span>
          }
        />
      </>
    );
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED) {
    return <CustomHeader {...props} color="orange" title={'Seller Accepted'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.UNDECIDED) {
    return <CustomHeader {...props} color="orange" title={'Bid Undecided'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT) {
    return <CustomHeader {...props} color="orange" title={'Counter Sent to Seller'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.SELLER_COUNTERED) {
    return <CustomHeader {...props} color="orange" title={'Seller Countered'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT) {
    return <CustomHeader {...props} color="orange" title={'Seller Counter Sent to Buyer'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT) {
    return <CustomHeader {...props} color="orange" title={'AutoAxess Counter Sent to Buyer'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED) {
    return <CustomHeader {...props} color="orange" title={'Buyer Counter Expired'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.SELLER_NOT_COUNTERED) {
    return <CustomHeader {...props} color="orange" title={'Seller Counter Expired'} />;
  }

  if (vehicleStatus === ADMIN_VEHICLE_STATUS.PUBLIC_ADMIN_COUNTER_SENT) {
    return <CustomHeader {...props} color="orange" title="Autoaxess Counter Sent to Buyers" />;
  }

  if (currentVehicle.status === VEHICLE_STATUSES.ACTIVE && currentVehicle.buyer_id) {
    return <CustomHeader {...props} color="orange" title="Seller Accepted" />;
  }
  if (isVehicleTimerEnded && !isAuctionEnded) {
    return <CustomHeader {...props} color="orange" title="Bid Undecided" />;
  }
  if (isVehicleTimerEnded && isAuctionEnded) {
    return <CustomHeader {...props} color="orange" title="Undecided" />;
  }
  return <InProgressHeader />;
};

export default connect(state => ({
  currentVehicle: state.vehicles.selectedVehicle.data,
  currentUser: state.user.user
}))(AdminRestrictedHeader);
