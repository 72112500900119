type Props = { isActive?: boolean };

export const StepperIndicatorIcon = ({ isActive = false }: Props) => {
  const fillOuterColor = isActive ? '#3994DE' : '#F6F6F6';
  const fillInnerColor = isActive ? '#ffffff' : '#E0E0E7';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_6229_6238)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill={fillOuterColor}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
          fill={fillInnerColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6229_6238">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CompletedStepperIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#3994DE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9496 6.29289C13.3401 5.90236 13.3401 5.2692 12.9496 4.87867C12.5591 4.48815 11.9259 4.48815 11.5354 4.87867L6.58564 9.82842L4.46432 7.7071C4.07379 7.31658 3.44063 7.31658 3.0501 7.7071C2.65958 8.09763 2.65958 8.73079 3.0501 9.12131L5.87853 11.9497C6.26905 12.3403 6.90222 12.3403 7.29274 11.9497L12.9496 6.29289Z"
      fill="white"
    />
  </svg>
);
