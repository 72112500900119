import { OfferHistory } from '../../../../../components/Offers';
import { BUYER_VEHICLE_STATUS } from '../../../../../constants';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { User } from '../../../../../types/user';
import { Vehicle } from '../../../../../types/vehicle';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';

type VisibilityFn = (vehicle: Vehicle, user: User, status: string) => boolean;

const shouldRenderBuyerOfferMessages: VisibilityFn = (vehicle: Vehicle, user: User, status: string) => {
  const isTopBuyer =
    vehicle.status === 'awarded' ? vehicle.buyer_id === user.id : user.id === vehicle.highest_bid?.user_id;
  if (!isTopBuyer) return false;

  return [
    BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER,
    BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT,
    BUYER_VEHICLE_STATUS.COUNTERED,
    BUYER_VEHICLE_STATUS.DECLINED,
    BUYER_VEHICLE_STATUS.AWARDED,
    BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE,
    BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED
  ].includes(status);
};

const visibilityMap: Record<string, VisibilityFn> = {
  buyer: shouldRenderBuyerOfferMessages,
  seller: (vehicle: Vehicle, user: User, status: string) => true,
  admin: (vehicle: Vehicle, user: User, status: string) => true
};

export const OfferMessages = () => {
  const vehicle = useViewedVehicle();
  const currentUser = useCurrentUser();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);

  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  if (!vehicle || !offerAuction) return null;

  const isVisible = visibilityMap[currentUser.role](vehicle, offerAuction, status);
  if (!isVisible) return null;

  return <OfferHistory offerAuction={offerAuction} />;
};
