import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { listActiveBanners } from '../../actions/banners';
import { AUTSlider } from '../index';
import './index.scss';

const StyledImage = styled.img`
  object-fit: cover;
  max-height: 260px;
`;

export class BannerSlider extends Component {
  componentWillMount() {
    const { fetchActiveBanners } = this.props;
    fetchActiveBanners();
  }

  settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  render() {
    const { banners } = this.props;
    return (
      <div className="banner-slider">
        <AUTSlider settings={this.settings}>
          {banners.map(banner=> (
            <div key={banner.id}>
              <StyledImage src={banner.image_url} alt="" />
            </div>
          ))}
        </AUTSlider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  banners: state.banners.activeBanners || []
});

const mapDispatchToProps = dispatch => ({
  fetchActiveBanners: () => dispatch(listActiveBanners().request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerSlider);
