import { SubmissionError } from 'redux-form';
import { Grid } from '@material-ui/core';
import { StepControl, FormWrapper, StepperTitle, RolePicker } from '../components';
import {
  validateEmail,
  required,
  minLength,
  maxLength,
  passwordRegExp,
  composeValidators,
  isSame
} from '../../../../utils/validators';
import { roles, ONBOARDING_STEPS, SIGNUP_ACH_METHODS, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import { user } from '../../../../utils/api';
import { setToken } from '../../../../utils/auth';
import { Form } from 'react-final-form';
import OutlinedFormTextField from '../../../../components/Form/FinalForm/OutlinedFormTextField';
import OutlinedFormPasswordField from '../../../../components/Form/FinalForm/OutlinedFormPasswordField';
import { useSearchParams } from '../../../../hooks';
import { useRegisterMutation } from 'services/api/auth';
import { useDispatch } from 'react-redux';
import { snackShow } from 'actions';
import api from 'services/api';
const initialAccountCreation = onSubmit => values =>
  user
    .createUser({
      ...values,
      last_step: values.role === roles.BUYER ? ONBOARDING_STEPS.SECOND_BUYER_STEP : ONBOARDING_STEPS.SECOND_SELLER_STEP
    })
    .then(({ data }) => {
      onSubmit(values.role);
      setToken(data.data.token);
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        const errors = (response.data.data || []).reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        // eslint-disable-next-line no-throw-literal
        throw new SubmissionError(errors);
      }
    });

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const textFieldValidator = composeValidators(required, maxStringLength);
const emailValidator = composeValidators(required, validateEmail);
const passwordValidator = composeValidators(required, minLength(8), maxLength(25), passwordRegExp);
const confirmPasswordValidator = isSame('password', 'Passwords do not match');

const firstStep = ({ nextStep, role }) => {
  const [register] = useRegisterMutation();

  const dispatch = useDispatch();

  const onSubmit = async values => {
    const { data, error } = await register({
      ...values,
      last_step: values.role === roles.BUYER ? ONBOARDING_STEPS.SECOND_BUYER_STEP : ONBOARDING_STEPS.SECOND_SELLER_STEP
    });

    if (error) {
      console.error(error);
      return dispatch(snackShow({ message: 'Could not register. Please try again', type: 'error' }));
    }

    setToken(data.token);

    dispatch(api.util.invalidateTags(['Me']));
    nextStep();
  };

  const searchParams = useSearchParams();
  const searchParamsRole = searchParams.get('role');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: '',
        role: searchParamsRole || 'buyer',
        buy_fee_method: 'credit_card',
        ach_method: SIGNUP_ACH_METHODS.ENTER_ACH_INFORMATION,
        documents_upload_method: 'upload',
        purchase_method: {
          ach: true,
          flooring: true,
          cash: true,
          company_check: true,
          cashiers_check: true
        }
      }}
    >
      {props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle title="User Info" />
              <Grid container>
                <OutlinedFormTextField
                  name="first_name"
                  placeholder="First Name"
                  label="First Name*"
                  validate={textFieldValidator}
                />
                <OutlinedFormTextField
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name*"
                  validate={textFieldValidator}
                />
                <OutlinedFormTextField
                  name="email"
                  placeholder="John.smith@gmail.com"
                  label="E-mail*"
                  type="email"
                  autocomplete="off"
                  validate={emailValidator}
                />
                <OutlinedFormTextField
                  name="dealership_name"
                  placeholder="Dealer Name"
                  label="Dealer Name*"
                  validate={textFieldValidator}
                />
                <OutlinedFormPasswordField
                  autocomplete="off"
                  name="password"
                  label="Create Password*"
                  validate={passwordValidator}
                />
                <OutlinedFormPasswordField autocomplete="off" name="confirm_password" label="Password confirmation*" />
                <RolePicker />
              </Grid>
              <StepControl nextStep={nextStep} />
            </FormWrapper>
          </form>
        );
      }}
    </Form>
  );
};

export default firstStep;
