import React from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { MenuItem } from '@material-ui/core';
import { FormSelect, FormResetButton } from '../../../../../components/Form';
import { listSellerPastInvoices } from '../../../../../actions';
import { MONTHS } from '../../../../../constants';

const formName = 'pastInvoicesFilterForm';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  div:first-child {
    padding-right: 16px;
  }
  div {
    div {
      min-width: 240px;
    }
  }
  align-items: flex-end;
`;

const StyledFormResetButton = styled(FormResetButton)`
  && {
    margin-left: 6px;
  }
`;

let sellerId = 0;

const asyncValidate = (values, dispatch) =>
  new Promise(resolve => {
    resolve(
      dispatch(
        listSellerPastInvoices({ seller: sellerId, params: { ...values } })
          .request
      )
    );
  });

const Filter = ({ years, match, values, getInvoices, reset }) => {
  const { params } = match;
  /* eslint-disable-next-line */
  sellerId = params.sellerId;
  return (
    <StyledFilterWrapper>
      <Field component={FormSelect} id="month" name="month" label="Month">
        {MONTHS.map((e, i) => (
          <MenuItem key={i} value={i}>
            {e}
          </MenuItem>
        ))}
      </Field>
      <Field component={FormSelect} id="year" name="year" label="Year">
        {years.map((e, i) => (
          <MenuItem key={i} value={e}>
            {e}
          </MenuItem>
        ))}
      </Field>
      {values && (values.month || values.year) && (
        <StyledFormResetButton
          onClick={() => {
            reset();
            getInvoices();
          }}
        />
      )}
    </StyledFilterWrapper>
  );
};

const mapStateToProps = state => ({
  years: (state.financials.pastInvoices || []).reduce((arr, item) => {
    const year = moment
      .utc(item.date, 'MM-YYYY')
      .local()
      .format('YYYY');
    if (!arr.includes(year)) {
      arr.push(year);
    }
    return arr;
  }, []),
  values: state.form[formName].values || {}
});

const mapDispatchToProps = dispatch => ({
  getInvoices: () =>
    dispatch(listSellerPastInvoices({ seller: sellerId }).request)
});

export default compose(
  reduxForm({
    form: formName,
    initialValues: {
      month: null,
      year: null
    },
    asyncValidate,
    asyncChangeFields: ['month', 'year'],
    destroyOnUnmount: false
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Filter);
