import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../../types/user';

type UserState = {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
      setUser: (state, action: PayloadAction<User>) => { 
        state.user = action.payload;
      },
      removeUser: (state) => {
          state.user = null
      }
    }
});

export const { setUser, removeUser } = userSlice.actions
export default userSlice;
