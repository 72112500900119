import React from 'react';
import { DateTimePicker, DatePicker } from 'material-ui-pickers';
import { DATE_FORMAT_FULL, DATE_FORMAT } from '../../constants';

const DatePickerField = ({
  type,
  input: { onChange, value },
  meta: { touched, error, invalid },
  label = '',
  ...custom
}) => {
  const props = {
    label,
    showTodayButton: true,
    value: value || null,
    onChange,
    format: DATE_FORMAT,
    error: !!(touched && error),
    helperText: touched && invalid && error
  };
  if (type === 'DatePicker') {
    return <DatePicker {...props} format={custom.dateFormat} {...custom} />;
  }
  if (type === 'DateTimePicker') {
    return <DateTimePicker {...props} format={DATE_FORMAT_FULL} {...custom} />;
  }
  return <DatePicker {...props} {...custom} />;
};

DatePickerField.defaultProps = {
  type: 'DatePicker'
};

export default DatePickerField;
