import React from 'react';
import { reset, reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import { ModalWrapper } from '../../../../components';
import { FormTextField } from '../../../../components/Form';
import { updateProfile } from '../../../../actions/user';
import {
  required,
  minLength,
  maxLength,
  passwordRegExp
} from '../../../../utils/validators';
import { getBackupToken } from '../../../../utils/auth';

const StyledFormWrapper = styled.form`
  width: 550px;
  > div:not(:first-child) {
    margin-top: 12.5px;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const isSame = (value, values) =>
  value === values.new_password
    ? undefined
    : 'Password and confirm password must be same';

const ChangePasswordModal = ({ handleSubmit, reset: resetForm }) => {
  const isAdminLoggedIn = getBackupToken();
  return (
    <ModalWrapper
      title="Change Password Modal"
      handleSubmit={handleSubmit}
      modalId="changePasswordModal"
      cancelAdditionalAction={resetForm}
    >
      <StyledFormWrapper onSubmit={handleSubmit}>
        {!isAdminLoggedIn && (
          <Field
            name="current_password"
            type="password"
            component={FormTextField}
            label="Current Password"
            fullWidth
            validate={required}
          />
        )}
        <Field
          name="new_password"
          type="password"
          component={FormTextField}
          label="New Password"
          fullWidth
          validate={[required, minLength(8), maxLength(25), passwordRegExp]}
        />
        <Field
          name="new_password_confirmation"
          type="password"
          component={FormTextField}
          label="New Password Confirmation"
          fullWidth
          validate={[required, isSame]}
        />
      </StyledFormWrapper>
    </ModalWrapper>
  );
}

export default reduxForm({
  form: 'changePasswordModalForm',
  onSubmit: (values, dispatch) =>
    new Promise((resolve, reject) =>
      dispatch(updateProfile({ payload: values, resolve, reject }).request)
    ),
  onSubmitSuccess: (result, dispatch) =>
    dispatch(reset('changePasswordModalForm'))
})(ChangePasswordModal);
