import {
  LIST_VEHICLES_REQUEST,
  LIST_VEHICLES_SUCCESS,
  LIST_VEHICLES_FAILURE,
  LIST_VEHICLES_CLEAR,
  LIST_VEHICLES_CHANGE_AUCTION_STATUS,
  CREATE_VEHICLE_REQUEST,
  CREATE_VEHICLE_SUCCESS,
  CREATE_VEHICLE_FAILURE,
  UPDATE_VEHICLE_REQUEST,
  UPDATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_FAILURE,
  VEHICLES_SET_PAGE,
  VEHICLES_SET_PER_PAGE,
  GET_VIN_INFO_REQUEST,
  GET_VIN_INFO_SUCCESS,
  GET_VIN_INFO_FAILURE,
  RESET_VIN_INFO,
  GET_CAR_ESTIMATE_REQUEST,
  GET_CAR_ESTIMATE_SUCCESS,
  GET_CAR_ESTIMATE_FAILURE,
  CREATE_VEHICLE_SET_TMP_IMAGE,
  RESET_TMP_IMAGES,
  VEHICLES_UNSET_SUCCESS,
  GET_VEHICLE_DETAILS_REQUEST,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAILURE,
  VEHICLE_AWARD_BUYER_REQUEST,
  VEHICLE_AWARD_BUYER_SUCCESS,
  VEHICLE_AWARD_BUYER_FAILURE,
  VEHICLE_UNAWARD_REQUEST,
  VEHICLE_UNAWARD_SUCCESS,
  VEHICLE_UNAWARD_FAILURE,
  VEHICLE_PULL_REQUEST,
  VEHICLE_PULL_SUCCESS,
  VEHICLE_PULL_FAILURE,
  VEHICLE_RELIST_REQUEST,
  VEHICLE_RELIST_SUCCESS,
  VEHICLE_RELIST_FAILURE,
  VEHICLE_DEACTIVATE_REQUEST,
  VEHICLE_DEACTIVATE_SUCCESS,
  VEHICLE_DEACTIVATE_FAILURE,
  VEHICLE_SELLER_ACCEPT_BID_REQUEST,
  VEHICLE_SELLER_ACCEPT_BID_SUCCESS,
  VEHICLE_SELLER_ACCEPT_BID_FAILURE,
  VEHICLE_ADMIN_APPROVE_BID_REQUEST,
  VEHICLE_ADMIN_APPROVE_BID_SUCCESS,
  VEHICLE_ADMIN_APPROVE_BID_FAILURE,
  VEHICLE_SELLER_SET_COUNTER_REQUEST,
  VEHICLE_SELLER_SET_COUNTER_SUCCESS,
  VEHICLE_SELLER_SET_COUNTER_FAILURE,
  VEHICLE_ADMIN_SET_COUNTER_REQUEST,
  VEHICLE_ADMIN_SET_COUNTER_SUCCESS,
  VEHICLE_ADMIN_SET_COUNTER_FAILURE,
  VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST,
  VEHICLE_BUYER_ACCEPT_COUNTER_SUCCESS,
  VEHICLE_BUYER_ACCEPT_COUNTER_FAILURE,
  VEHICLES_HOME_FILTERS_REQUEST,
  VEHICLES_HOME_FILTERS_SUCCESS,
  VEHICLES_HOME_FILTERS_FAILURE,
  RESET_VEHICLE_DETAILS,
  RESET_CAR_ESTIMATE,
  VEHICLES_SELLER_SET_PAGE,
  REQUEST_IN_PROGRESS,
  UPDATE_ADMIN_NOTES_REQUEST,
  FILTER_SELLER_VEHICLES_REQUEST,
  FILTER_SELLER_VEHICLES_SUCCESS,
  FILTER_SELLER_VEHICLES_FAILURE
} from '../constants/actionTypes';

export const listVehicles = data => ({
  request: { type: LIST_VEHICLES_REQUEST, payload: data },
  success: { type: LIST_VEHICLES_SUCCESS, payload: data },
  failure: { type: LIST_VEHICLES_FAILURE, payload: data }
});

export const listVehiclesClear = data => ({
  type: LIST_VEHICLES_CLEAR,
  payload: data
});

export const filterSellerVehicles = data => ({
  request: { type: FILTER_SELLER_VEHICLES_REQUEST, payload: data },
  success: { type: FILTER_SELLER_VEHICLES_SUCCESS, payload: data },
  failure: { type: FILTER_SELLER_VEHICLES_FAILURE, payload: data }
});

export const listVehiclesChangeAuctionStatus = data => ({
  type: LIST_VEHICLES_CHANGE_AUCTION_STATUS,
  payload: data
});

export const addVehicle = data => ({
  request: { type: CREATE_VEHICLE_REQUEST, payload: data },
  success: { type: CREATE_VEHICLE_SUCCESS, payload: data },
  failure: { type: CREATE_VEHICLE_FAILURE, payload: data }
});
export const updateVehicle = data => ({
  request: { type: UPDATE_VEHICLE_REQUEST, payload: data },
  success: { type: UPDATE_VEHICLE_SUCCESS, payload: data },
  failure: { type: UPDATE_VEHICLE_FAILURE, payload: data }
});
export const updateAdminNotes = data => ({
  request: { type: UPDATE_ADMIN_NOTES_REQUEST, payload: data }
});
export const vehiclesSetPage = data => ({
  type: VEHICLES_SET_PAGE,
  payload: data
});
export const vehiclesSetPerPage = data => ({
  type: VEHICLES_SET_PER_PAGE,
  payload: data
});
export const requestInProgress = data => ({
  type: REQUEST_IN_PROGRESS,
  payload: data
});
export const vehiclesSellerSetPage = data => ({
  type: VEHICLES_SELLER_SET_PAGE,
  payload: data
});

export const getVinInfo = (vin, resolve, reject) => ({
  request: { type: GET_VIN_INFO_REQUEST, payload: { vin, resolve, reject } },
  success: { type: GET_VIN_INFO_SUCCESS, payload: vin },
  failure: { type: GET_VIN_INFO_FAILURE, payload: vin }
});

export const resetVinInfo = data => ({ type: RESET_VIN_INFO, payload: data });

export const getCarEstimate = (data, resolve, reject) => ({
  request: {
    type: GET_CAR_ESTIMATE_REQUEST,
    payload: { data, resolve, reject }
  },
  success: { type: GET_CAR_ESTIMATE_SUCCESS, payload: data },
  failure: { type: GET_CAR_ESTIMATE_FAILURE, payload: data }
});
export const resetCarEstimate = data => ({
  type: RESET_CAR_ESTIMATE,
  payload: data
});

export const VEHICLE_DELIVERY_ESTIMATE_REQUEST = 'VEHICLE_DELIVERY_ESTIMATE_REQUEST';
export const VEHICLE_DELIVERY_ESTIMATE_SUCCESS = 'VEHICLE_DELIVERY_ESTIMATE_SUCCESS';
export const VEHICLE_DELIVERY_ESTIMATE_FAILURE = 'VEHICLE_DELIVERY_ESTIMATE_FAILURE';

export const getDeliveryEstimate = data => ({
  request: { type: VEHICLE_DELIVERY_ESTIMATE_REQUEST, payload: data },
  success: { type: VEHICLE_DELIVERY_ESTIMATE_SUCCESS, payload: data },
  failure: { type: VEHICLE_DELIVERY_ESTIMATE_FAILURE, payload: data }
});

export const createVehicleSetTmpImage = data => ({
  type: CREATE_VEHICLE_SET_TMP_IMAGE,
  payload: data
});
export const resetTmpImages = data => ({
  type: RESET_TMP_IMAGES,
  payload: data
});
export const vehiclesUnsetSuccess = data => ({
  type: VEHICLES_UNSET_SUCCESS,
  payload: data
});

export const vehicleDetails = (data, resolve, reject) => ({
  request: {
    type: GET_VEHICLE_DETAILS_REQUEST,
    payload: { data, resolve, reject }
  },
  success: { type: GET_VEHICLE_DETAILS_SUCCESS, payload: data },
  failure: { type: GET_VEHICLE_DETAILS_FAILURE, payload: data }
});

export const awardBuyer = data => ({
  request: { type: VEHICLE_AWARD_BUYER_REQUEST, payload: data },
  success: { type: VEHICLE_AWARD_BUYER_SUCCESS, payload: data },
  failure: { type: VEHICLE_AWARD_BUYER_FAILURE, payload: data }
});

export const unawardVehicle = data => ({
  request: { type: VEHICLE_UNAWARD_REQUEST, payload: data },
  success: { type: VEHICLE_UNAWARD_SUCCESS, payload: data },
  failure: { type: VEHICLE_UNAWARD_FAILURE, payload: data }
});

export const pullVehicle = data => ({
  request: { type: VEHICLE_PULL_REQUEST, payload: data },
  success: { type: VEHICLE_PULL_SUCCESS, payload: data },
  failure: { type: VEHICLE_PULL_FAILURE, payload: data }
});

export const relistVehicle = data => ({
  request: { type: VEHICLE_RELIST_REQUEST, payload: data },
  success: { type: VEHICLE_RELIST_SUCCESS, payload: data },
  failure: { type: VEHICLE_RELIST_FAILURE, payload: data }
});

export const deactivateVehicle = data => ({
  request: { type: VEHICLE_DEACTIVATE_REQUEST, payload: data },
  success: { type: VEHICLE_DEACTIVATE_SUCCESS, payload: data },
  failure: { type: VEHICLE_DEACTIVATE_FAILURE, payload: data }
});

export const sellerAcceptBid = data => ({
  request: { type: VEHICLE_SELLER_ACCEPT_BID_REQUEST, payload: data },
  success: { type: VEHICLE_SELLER_ACCEPT_BID_SUCCESS, payload: data },
  failure: { type: VEHICLE_SELLER_ACCEPT_BID_FAILURE, payload: data }
});

export const adminApproveBid = data => ({
  request: { type: VEHICLE_ADMIN_APPROVE_BID_REQUEST, payload: data },
  success: { type: VEHICLE_ADMIN_APPROVE_BID_SUCCESS, payload: data },
  failure: { type: VEHICLE_ADMIN_APPROVE_BID_FAILURE, payload: data }
});

export const sellerSetCounter = data => ({
  request: { type: VEHICLE_SELLER_SET_COUNTER_REQUEST, payload: data },
  success: { type: VEHICLE_SELLER_SET_COUNTER_SUCCESS, payload: data },
  failure: { type: VEHICLE_SELLER_SET_COUNTER_FAILURE, payload: data }
});

export const adminSetCounter = data => ({
  request: { type: VEHICLE_ADMIN_SET_COUNTER_REQUEST, payload: data },
  success: { type: VEHICLE_ADMIN_SET_COUNTER_SUCCESS, payload: data },
  failure: { type: VEHICLE_ADMIN_SET_COUNTER_FAILURE, payload: data }
});

export const buyerAcceptCounter = data => ({
  request: { type: VEHICLE_BUYER_ACCEPT_COUNTER_REQUEST, payload: data },
  success: { type: VEHICLE_BUYER_ACCEPT_COUNTER_SUCCESS, payload: data },
  failure: { type: VEHICLE_BUYER_ACCEPT_COUNTER_FAILURE, payload: data }
});

export const getHomeFilters = data => ({
  request: { type: VEHICLES_HOME_FILTERS_REQUEST, payload: data },
  success: { type: VEHICLES_HOME_FILTERS_SUCCESS, payload: data },
  failure: { type: VEHICLES_HOME_FILTERS_FAILURE, payload: data }
});

export const LIST_VEHICLES_ADMIN_REQUEST = 'LIST_VEHICLES_ADMIN_REQUEST';
export const LIST_VEHICLES_ADMIN_SUCCESS = 'LIST_VEHICLES_ADMIN_SUCCESS';
export const LIST_VEHICLES_ADMIN_FAILURE = 'LIST_VEHICLES_ADMIN_FAILURE';

export const FILTER_VEHICLES_ADMIN_REQUEST = 'FILTER_VEHICLES_ADMIN_REQUEST';
export const FILTER_VEHICLES_ADMIN_SUCCESS = 'FILTER_VEHICLES_ADMIN_SUCCESS';
export const FILTER_VEHICLES_ADMIN_FAILURE = 'FILTER_VEHICLES_ADMIN_FAILURE';

export const listVehiclesAdmin = payload => ({
  request: { type: LIST_VEHICLES_ADMIN_REQUEST, payload },
  success: { type: LIST_VEHICLES_ADMIN_SUCCESS, payload },
  failure: { type: LIST_VEHICLES_ADMIN_FAILURE, payload }
});

export const filterVehiclesAdmin = payload => ({
  request: { type: FILTER_VEHICLES_ADMIN_REQUEST, payload },
  success: { type: FILTER_VEHICLES_ADMIN_SUCCESS, payload },
  failure: { type: FILTER_VEHICLES_ADMIN_FAILURE, payload }
});

export const LIST_VEHICLES_SALES_HISTORY_REQUEST = 'LIST_VEHICLES_SALES_HISTORY_REQUEST';
export const LIST_VEHICLES_SALES_HISTORY_SUCCESS = 'LIST_VEHICLES_SALES_HISTORY_SUCCESS';
export const LIST_VEHICLES_SALES_HISTORY_FAILURE = 'LIST_VEHICLES_SALES_HISTORY_FAILURE';

export const listVehicleSalesHistory = payload => ({
  request: { type: LIST_VEHICLES_SALES_HISTORY_REQUEST, payload },
  success: { type: LIST_VEHICLES_SALES_HISTORY_SUCCESS, payload },
  failure: { type: LIST_VEHICLES_SALES_HISTORY_FAILURE, payload }
});

export const FILTER_VEHICLE_SALES_HISTORY_REQUEST = 'FILTER_VEHICLE_SALES_HISTORY_REQUEST';
export const FILTER_VEHICLE_SALES_HISTORY_SUCCESS = 'FILTER_VEHICLE_SALES_HISTORY_SUCCESS';
export const FILTER_VEHICLE_SALES_HISTORY_FAILURE = 'FILTER_VEHICLE_SALES_HISTORY_FAILURE';

export const filterVehicleSalesHistory = payload => ({
  request: { type: FILTER_VEHICLE_SALES_HISTORY_REQUEST, payload },
  success: { type: FILTER_VEHICLE_SALES_HISTORY_SUCCESS, payload },
  failure: { type: FILTER_VEHICLE_SALES_HISTORY_FAILURE, payload }
});

export const resetVehicleDetails = data => ({
  type: RESET_VEHICLE_DETAILS,
  payload: data
});

export const CHANGE_VEHICLE_TRIM = 'CHANGE_VEHICLE_TRIM';

export const changeTrim = payload => ({
  type: CHANGE_VEHICLE_TRIM,
  payload
});

export const VEHICLE_SALES_HISTORY_SET_PAGE = 'VEHICLE_SALES_HISTORY_SET_PAGE';

export const vehicleSalesHistorySetPage = payload => ({
  type: VEHICLE_SALES_HISTORY_SET_PAGE,
  payload
});

export const AUCTION_DETAIL_SET_COUNTER_REQUEST = 'AUCTION_DETAIL_SET_COUNTER_REQUEST';
export const AUCTION_DETAIL_SET_COUNTER_SUCCESS = 'AUCTION_DETAIL_SET_COUNTER_SUCCESS';
export const AUCTION_DETAIL_SET_NOTES_SUCCESS = 'AUCTION_DETAIL_SET_NOTES_SUCCESS';
export const AUCTION_DETAIL_SET_COUNTER_FAILURE = 'AUCTION_DETAIL_SET_COUNTER_FAILURE';

export const auctionDetailSetCounter = payload => ({
  request: { type: AUCTION_DETAIL_SET_COUNTER_REQUEST, payload },
  success: { type: AUCTION_DETAIL_SET_COUNTER_SUCCESS, payload },
  failure: { type: AUCTION_DETAIL_SET_COUNTER_FAILURE, payload }
});

export const AUCTION_DETAIL_AWARD_BUYER_REQUEST = 'AUCTION_DETAIL_AWARD_BUYER_REQUEST';
export const AUCTION_DETAIL_AWARD_BUYER_SUCCESS = 'AUCTION_DETAIL_AWARD_BUYER_SUCCESS';
export const AUCTION_DETAIL_AWARD_BUYER_FAILURE = 'AUCTION_DETAIL_AWARD_BUYER_FAILURE';

export const auctionDetailAwardBuyer = payload => ({
  request: { type: AUCTION_DETAIL_AWARD_BUYER_REQUEST, payload },
  success: { type: AUCTION_DETAIL_AWARD_BUYER_SUCCESS, payload },
  failure: { type: AUCTION_DETAIL_AWARD_BUYER_FAILURE, payload }
});

export const DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_FAILURE = 'DELETE_VEHICLE_FAILURE';

export const deleteVehicle = payload => ({
  request: { type: DELETE_VEHICLE_REQUEST, payload },
  success: { type: DELETE_VEHICLE_SUCCESS, payload },
  failure: { type: DELETE_VEHICLE_FAILURE, payload }
});

export const VEHICLE_MANUAL_UPLOAD_REQUEST = 'VEHICLE_MANUAL_UPLOAD_REQUEST';
export const VEHICLE_MANUAL_UPLOAD_SUCCESS = 'VEHICLE_MANUAL_UPLOAD_SUCCESS';
export const VEHICLE_MANUAL_UPLOAD_FAILURE = 'VEHICLE_MANUAL_UPLOAD_FAILURE';

export const vehicleManualUpload = payload => ({
  request: { type: VEHICLE_MANUAL_UPLOAD_REQUEST, payload },
  success: { type: VEHICLE_MANUAL_UPLOAD_SUCCESS, payload },
  failure: { type: VEHICLE_MANUAL_UPLOAD_FAILURE, payload }
});

export const VEHICLE_FORM_INIT_REQUEST = 'VEHICLE_FORM_INIT_REQUEST';
export const VEHICLE_FORM_INIT_SUCCESS = 'VEHICLE_FORM_INIT_SUCCESS';
export const VEHICLE_FORM_INIT_FAILURE = 'VEHICLE_FORM_INIT_FAILURE';

export const vehicleFormInit = payload => ({
  request: { type: VEHICLE_FORM_INIT_REQUEST, payload },
  success: { type: VEHICLE_FORM_INIT_SUCCESS, payload },
  failure: { type: VEHICLE_FORM_INIT_FAILURE, payload }
});

export const VEHICLE_BUYER_DOCS_REQUEST = 'VEHICLE_BUYER_DOCS_REQUEST';
export const VEHICLE_BUYER_DOCS_SUCCESS = 'VEHICLE_BUYER_DOCS_SUCCESS';
export const VEHICLE_BUYER_DOCS_FAILURE = 'VEHICLE_BUYER_DOCS_FAILURE';

export const getVehicleBuyerDocs = payload => ({
  request: { type: VEHICLE_BUYER_DOCS_REQUEST, payload },
  success: { type: VEHICLE_BUYER_DOCS_SUCCESS, payload },
  failure: { type: VEHICLE_BUYER_DOCS_FAILURE, payload }
});

export const INIT_VEHICLE_DETAILS_DATA = 'INIT_VEHICLE_DETAILS_DATA';

export const initVehicleDetailsData = payload => ({
  type: INIT_VEHICLE_DETAILS_DATA,
  payload
});

export const UPLOAD_VEHICLE_IMAGE_REQUEST = 'UPLOAD_VEHICLE_IMAGE_REQUEST';
export const UPLOAD_VEHICLE_IMAGE_SUCCESS = 'UPLOAD_VEHICLE_IMAGE_SUCCESS';
export const UPLOAD_VEHICLE_IMAGE_FAILURE = 'UPLOAD_VEHICLE_IMAGE_FAILURE';

export const uploadVehicleImage = payload => ({
  request: { type: UPLOAD_VEHICLE_IMAGE_REQUEST, payload },
  success: { type: UPLOAD_VEHICLE_IMAGE_SUCCESS, payload },
  failure: { type: UPLOAD_VEHICLE_IMAGE_FAILURE, payload }
});

export const DELETE_VEHICLE_IMAGE = 'DELETE_VEHICLE_IMAGE';

export const deleteVehicleImage = payload => ({
  type: DELETE_VEHICLE_IMAGE,
  payload
});

export const SET_VEHICLE_IMAGES = 'SET_VEHICLE_IMAGES';

export const setVehicleImages = payload => ({
  type: SET_VEHICLE_IMAGES,
  payload
});

export const LOAD_VEHICLE_SALES_COUNTERS_SUCCESS = 'LOAD_VEHICLE_SALES_COUNTERS_SUCCESS';
export const LOAD_VEHICLE_SALES_COUNTERS_FAILURE = 'LOAD_VEHICLE_SALES_COUNTERS_FAILURE';

export const EASY_PAY_BUY_REQUEST = 'EASY_PAY_BUY_REQUEST';
export const EASY_PAY_BUY_SUCCESS = 'EASY_PAY_BUY_SUCCESS';
export const EASY_PAY_BUY_FAILURE = 'EASY_PAY_BUY_FAILURE';

export const easyPayBuyVehicle = payload => ({
  request: { type: EASY_PAY_BUY_REQUEST, payload },
  success: { type: EASY_PAY_BUY_SUCCESS, payload },
  failure: { type: EASY_PAY_BUY_FAILURE, payload }
});
