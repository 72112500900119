import { call, put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SubmissionError, change, startSubmit, stopSubmit, destroy } from 'redux-form';
import { addUser, snackShow, listUsers, getUser, updateDoc, updateDocFile, deleteDoc, modalsHide } from '../actions';
import {
  CREATE_USER_REQUEST,
  LOGIN_USER_REQUEST,
  LIST_USERS_REQUEST,
  GET_USER_REQUEST,
  UPDATE_USER_DOC_REQUEST,
  UPDATE_USER_DOC_FILE_REQUEST,
  DELETE_USER_DOC_REQUEST,
  SEND_CONTACT_FORM_REQUEST,
  SEND_QUESTION_FORM_REQUEST
} from '../constants/actionTypes';
import {
  updateProfile,
  updateProfileDoc,
  detailProfile,
  DETAIL_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_DOC_REQUEST,
  sendContactForm,
  sendQuestionForm
} from '../actions/user';
import { Api } from '../utils';
import { getBackupToken } from '../utils/auth';
import { USER_STATUSES } from '../constants';

function* createUser({ payload }) {
  try {
    yield put(startSubmit('registrationForm'));
    const { data } = yield call(Api.user.continueOnboarding, payload);
    const isAdminLoggedIn = getBackupToken();
    if (isAdminLoggedIn) yield put(addUser(data.data).success);
    yield put(push('/success/registration'));
    // To reset fields
    yield put(destroy('registrationForm'));
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield put(stopSubmit('registrationForm', validationErrors));
      yield put(
        snackShow({
          message: 'Validation failed on some fields',
          type: 'error'
        })
      );
      yield put(addUser(e).failure);
    }
  }
}

function* fetchUsers({ payload }) {
  try {
    const { data } = yield call(Api.user.fetchUsers, payload);
    yield put(listUsers(data).success);
  } catch (e) {
    yield put(listUsers(e).failure);
  }
}

function* fetchUser({ payload }) {
  try {
    const { data } = yield call(Api.user.fetchUser, payload);
    yield put(getUser(data).success);
  } catch (e) {
    yield put(getUser(e).failure);
  }
}

function* updateUserDoc({ payload }) {
  try {
    const { data } = yield call(Api.user.updateUserDoc, payload.data);
    yield put(updateDoc(data).success);
    payload.resolve();
  } catch (e) {
    yield put(updateDoc(e).failure);
    payload.reject();
  }
}

function* updateUserDocFile({ payload: { reqData, resolve, reject } }) {
  try {
    const { data } = yield call(Api.user.updateUserDocFile, reqData);
    yield put(updateDocFile(data).success);
    resolve();
  } catch (e) {
    yield put(updateDocFile(e).failure);
    reject(e);
  }
}

function* deleteUserDoc({ payload: { reqData, resolve, reject } }) {
  try {
    const { data } = yield call(Api.user.deleteDoc, reqData.doc.id, {
      params: reqData.doc
    });
    yield put(deleteDoc(data).success);
    resolve();
  } catch (e) {
    yield put(deleteDoc(e).failure);
    reject(e);
  }
}

function* fetchProfile({ payload }) {
  try {
    const { data } = yield call(Api.user.fetchProfile, payload);
    yield put(detailProfile(data).success);
  } catch (e) {
    yield put(detailProfile(e).failure);
  }
}

function* editProfile({ payload }) {
  try {
    const formValues = { ...payload.payload };

    const isAdminLoggedIn = getBackupToken();

    if (isAdminLoggedIn) {
      formValues.admin_token = isAdminLoggedIn;
    }

    const { data } = yield call(Api.user.updateProfile, formValues);
    yield put(snackShow({ message: 'Successfully Updated Profile' }));
    yield put(updateProfile(data).success);
    yield put(modalsHide());
    yield call(payload.resolve, data);
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield call(payload.reject, new SubmissionError(validationErrors));
      yield put(updateProfile(e).failure);
    }
  }
}

function* editProfileDoc({ payload }) {
  const submitData = payload.payload;
  try {
    const { data } = yield call(Api.user.updateProfileDoc, submitData);
    yield put(snackShow({ message: 'Successfully Updated Profile' }));
    yield put(updateProfileDoc(data).success);
    yield put(modalsHide());
    yield call(payload.resolve);
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [submitData.name]: error.message
        }),
        {}
      );
      yield put(stopSubmit('manageDocumentsForm', validationErrors));
      yield put(updateProfileDoc(e).failure);
    }
  }
}

function* contactFormSend({ payload: { data, resolve, reject } }) {
  try {
    yield call(Api.user.sendContactForm, data);
    yield put(snackShow({ message: 'Your message successfully sent!' }));
    yield put(sendContactForm().success);
    resolve();
  } catch (e) {
    yield put(
      snackShow({
        message: 'Failed to send message! Please try later',
        type: 'error'
      })
    );
    yield put(sendContactForm(e).failure);
    reject();
  }
}

function* questionFormSend({ payload: { data, resolve, reject } }) {
  try {
    yield call(Api.user.sendQuestionForm, data);
    yield put(snackShow({ message: 'Your question successfully sent!' }));
    yield put(sendQuestionForm().success);
    resolve();
  } catch (e) {
    yield put(
      snackShow({
        message: 'Failed to send question! Please try later',
        type: 'error'
      })
    );
    yield put(sendQuestionForm(e).failure);
    reject();
  }
}

export default function*() {
  yield all([
    takeLatest(CREATE_USER_REQUEST, createUser),
    takeLatest(LIST_USERS_REQUEST, fetchUsers),
    takeLatest(GET_USER_REQUEST, fetchUser),
    takeLatest(UPDATE_USER_DOC_REQUEST, updateUserDoc),
    takeLatest(UPDATE_USER_DOC_FILE_REQUEST, updateUserDocFile),
    takeLatest(DELETE_USER_DOC_REQUEST, deleteUserDoc),
    takeLatest(DETAIL_PROFILE_REQUEST, fetchProfile),
    takeLatest(UPDATE_PROFILE_REQUEST, editProfile),
    takeLatest(UPDATE_PROFILE_DOC_REQUEST, editProfileDoc),
    takeLatest(SEND_CONTACT_FORM_REQUEST, contactFormSend),
    takeLatest(SEND_QUESTION_FORM_REQUEST, questionFormSend)
  ]);
}
