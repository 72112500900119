import React from 'react';
import styled from 'styled-components';
import {Grid, Typography} from '@material-ui/core';
import {Slider} from '@material-ui/lab';
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles';
import './index.scss';

const styles = {
  root: {
    marginTop: '14px',
    marginLeft: 'calc(25% - 5px)'
  },
  thumbIconWrapper: {
    backgroundColor: '#fff',
    width: '24px',
    height: '24px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)'
  },
  trackAfter: {
    borderRadius: '2px'
  }
};

const StyledSlider = styled(Slider)`
  && {
    :before {
      content: '';
      display: block;
      width: 33%;
      height: 4px;
      margin-left: -33%;
      background: rgba(0, 0, 0, 0.24);
      position: relative;
      top: 2px;
    }
    &.moreSteps {
     margin-left: 0 !important;
    }
     &.moreSteps:before {
       width: 0;
       margin-left: 0;
    }
     &.na:before {
      background: #c2c2c2;
    }
    &.poor:before {
      background: #f60000;
    }
    &.fair:before {
      background: #ffab58;
    }
    &.good:before {
      background: #009d3e;
    }
    &.excellent:before {
      background: #4889bf;
    }
  }
`;

let QualityPicker = ({value, onChange, steps = 3, titles = ["Poor", "Fair", "Good", "Excellent"], ...rest}) => {
  let color;
  let backgroundClass;

  if (steps === 3) {
    switch (true) {
      case value >= 100:
        color = '#4889bf';
        backgroundClass = 'excellent';
        break;
      case value > 51:
        color = '#009D3E';
        backgroundClass = 'good';
        break;
      case value > 25:
        color = '#FFAB58';
        backgroundClass = 'fair';
        break;
      case value > 0:
        color = '#F60000';
        backgroundClass = 'poor';
        break;
      default:
        color = '#FFAB58';
        backgroundClass = 'poor';
    }
  } else if (steps === 4) {
    switch (true) {
      case value >= 100:
        color = '#4889bf';
        backgroundClass = 'excellent moreSteps';
        break;
      case value >= 75:
        color = '#009D3E';
        backgroundClass = 'good moreSteps';
        break;
      case value >= 50:
        color = '#FFAB58';
        backgroundClass = 'fair moreSteps';
        break;
      case value >= 25:
        color = '#F60000';
        backgroundClass = 'poor moreSteps';
        break;
      case value >= 0:
        color = '#c2c2c2';
        backgroundClass = 'na moreSteps';
        break;
      default:
        color = '#FFAB58';
        backgroundClass = 'na moreSteps';
    }
  }else if (steps === 2) {
    switch (true) {
      case value >= 50:
        color = '#009D3E';
        backgroundClass = 'good';
        break;
      case value < 50:
        color = '#F60000';
        backgroundClass = 'poor';
        break;
      default:
        color = '#F60000';
        backgroundClass = 'poor';
    }
  }


  const theme = createMuiTheme({
    overrides: {
      MuiSlider: {
        track: {backgroundColor: color, height: '4px'},
        trackAfter: {backgroundColor: '#000'}
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Grid item container xs={12} className="quality-picker">
        <StyledSlider
          {...rest}
          value={value}
          style={styles}
          thumb={<div />}
          onChange={onChange}
          className={backgroundClass}
        />
        <Grid item xs={12}>
          <div className="delimiter-holder">
            {new Array(5).fill(true).map((tmpValue, i) => (
              <span key={i} className="delimiter-item" />
            ))}
          </div>
        </Grid>
        <Grid className="delimiter-text" item xs={12}>
          {titles.length === 4 ? (<Typography className="delimiter-text-item" />) : null}
          {titles.map((title) => (<Typography key={title} className="delimiter-text-item">{title}</Typography>))}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

QualityPicker = withStyles(styles)(QualityPicker);

const WrappedQualityPicker = ({input, disableStep, ...custom}) => (
  <QualityPicker
    {...custom}
    onChange={(event, value) => {

        if (disableStep && value >= 25 && value <= 30) {
          input.onChange(50)
        } else if(custom.steps && custom.steps === 2) {
          if(value >= 50) {
            input.onChange(75)
          } else {
            input.onChange(5)
          }
      } else {
        input.onChange(value)
      }
    }}
    value={input.value}
  />
);

export default WrappedQualityPicker;
