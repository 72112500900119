import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ListVehiclesState,
  setPoolFilterValue,
  setPoolSortFilters
} from '../../../services/listVehiclesSlice/listVehiclesSlice';
import { CaretDown, CaretUp } from '../../Icons';
import { DoubleCaretDown, DoubleCaretUp } from '../../Icons/DoubleCaret';

const TableHeader = styled.div`
  background: #2222220a;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  min-height: 56px;
  align-items: center;
  padding: 12px 24px;
  color: #000;
  font-weight: 600;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr 1.5fr 1fr 1.5fr 1fr 1fr;
  grid-gap: 16px;
`;

const Column = styled.div<{ isSortable?: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${props => (props.isSortable ? 'pointer' : 'auto')};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ColumnItem =
  | {
      label: string;
      isSortable: true;
      orderColumn: string;
    }
  | {
      label: string;
      isSortable: false;
    };

const columns: ColumnItem[] = [
  { label: 'Lister', isSortable: true, orderColumn: 'lister' },
  { label: 'Seller/Store', isSortable: false },
  { label: 'Number of Vehicles', isSortable: false },
  { label: 'Notes', isSortable: false },
  { label: 'Preferable date', isSortable: true, orderColumn: 'preferable_date' },
  { label: 'Status', isSortable: true, orderColumn: 'status' },
  { label: 'Actions', isSortable: false }
];

export const VehiclePoolTableHeader = () => {
  const filters = useSelector<{ listVehicles: ListVehiclesState }, Record<string, any>>(
    state => state.listVehicles.poolFilters
  );

  const dispatch = useDispatch();

  const handleColumnClick = (item: ColumnItem) => {
    if (!item.isSortable) return;
    const isCurrentlySorted = filters.orderColumn === item.orderColumn;
    if (isCurrentlySorted) {
      dispatch(
        setPoolFilterValue({
          key: 'orderDirection',
          value: filters.orderDirection === 'asc' ? 'desc' : 'asc'
        })
      );
    } else {
      dispatch(
        setPoolSortFilters({
          orderColumn: item.orderColumn,
          orderDirection: 'asc'
        })
      );
    }
  };

  return (
    <TableHeader>
      {columns.map(item => {
        return (
          <Column onClick={() => handleColumnClick(item)} isSortable={item.isSortable} key={item.label}>
            <span>{item.label}</span>
            <SortComponent item={item} />
          </Column>
        );
      })}
      {/* <span>Lister</span>
      <span>Seller/Store</span>
      <span>Number of Vehicles</span>
      <span>Seller Preferable date</span>
      <span>Notes</span>
      <span>Preferable date</span>
      <span>Status</span>
      <span>Actions</span> */}
    </TableHeader>
  );
};

const SortComponent = ({ item }: { item: ColumnItem }) => {
  const filters = useSelector<{ listVehicles: ListVehiclesState }, Record<string, any>>(
    state => state.listVehicles.poolFilters
  );

  if (!item.isSortable) return null;

  if (filters.orderColumn !== item.orderColumn) return <DoubleCaretDown fillColor="#757575" />;

  return <IconWrapper>{filters.orderDirection === 'asc' ? <DoubleCaretDown /> : <DoubleCaretUp />}</IconWrapper>;
};
