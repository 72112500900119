/* eslint-disable no-else-return,no-underscore-dangle */
import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { reduxForm, SubmissionError, formValueSelector, change } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { OfficePhoneLabel, StepControl, FormCreditCardPicker, FormWrapper, StepperTitle } from '../components';
import scrollToError from '../../../../utils/scrollToError';
import { ONBOARDING_STEPS } from '../../../../constants';
import { user } from '../../../../utils/api';
import BuyFeeMethodPicker from '../components/BuyFeeMethodPicker';
import InfoAlert from '../components/InfoAlert';

import cardLogo from '../../../../img/visa-mastercard.png';
import cardLogo2 from '../../../../img/discover-amex.png';
import { Form } from 'react-final-form';
import { useMeQuery, useOnboardingMutation, useValidateFormMutation } from 'services/api/auth';

const StyledCardLogo = styled.img`
  width: 125px;
  float: right;
  margin-right: 15px;
`;

const StyledDisclaimerDescription = styled(Typography)`
  && {
    color: #616161;
    margin-bottom: 15px;
    font-size: 14px;
  }
`;

const continueOnboarding = (onSubmit, values) => {
  let continueOnboardingParams = { last_step: ONBOARDING_STEPS.FOURTH_BUYER_STEP };
  if (values.buy_fee_method !== 'ach_flooring') {
    continueOnboardingParams = {
      credit_card_number: (values.credit_card_number || '').replace(/\s/g, ''),
      credit_card_expiry_date: values.credit_card_expiry_date
        ? moment.utc(values.credit_card_expiry_date || '', 'MM/YY').format('YYYY-MM')
        : '',
      credit_card_cvc: values.credit_card_cvc,
      last_step: ONBOARDING_STEPS.FOURTH_BUYER_STEP
    };
  }
  user
    .continueOnboarding(continueOnboardingParams)
    .then(() => {
      onSubmit();
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        const errors = (response.data.data || []).reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        // eslint-disable-next-line no-throw-literal
        throw new SubmissionError(errors);
      }
    });
};

const submitValidation = onSubmit => values => {
  if (values.buy_fee_method === 'ach_flooring') {
    continueOnboarding(onSubmit, values);
    return;
  }
  user
    .validateForm({
      credit_card_number: (values.credit_card_number || '').replace(/\s/g, ''),
      credit_card_expiry_date: moment(values.credit_card_expiry_date || '', 'MM/YY').format('YYYY-MM'),
      credit_card_cvc: values.credit_card_cvc || 1 // Default value to trigger validation
    })
    .then(() => continueOnboarding(onSubmit, values))
    .catch(({ response }) => {
      if (response.status === 400) {
        const errors = (response.data.data || []).reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        // eslint-disable-next-line no-throw-literal
        throw new SubmissionError(errors);
      }
    });
};

const validate = values => {
  if (values.buyFeeMethod === 'ach_flooring') {
    return;
  }

  const errors = {};

  const creditCardNumber = (values.credit_card_number || '').replace(/\s/g, '');
  const isValidDate = /^\d{2}\/\d{2}$/.test(values.credit_card_expiry_date || '');
  const isExpired = moment()
    .subtract(1, 'month')
    .isAfter(moment(values.credit_card_expiry_date, 'MM/YY'));
  const isValidCVC = (values.credit_card_cvc || '').length === 3;

  if (creditCardNumber.length <= 12 || creditCardNumber.length >= 19) {
    errors.credit_card_number = 'Credit card length must be between 12 and 19 digits';
  }
  if (!isValidDate) {
    errors.credit_card_expiry_date = 'Date must be specified in MM/YY format';
  }
  if (isExpired) {
    errors.credit_card_expiry_date = 'Credit card is already expired';
  }
  if (!isValidCVC) {
    errors.credit_card_cvc = 'CVC must contain 3 digits';
  }
  return errors;
};

const thirdBuyerStep = ({ nextStep, prevStep, buy_fee_method }) => {
  const user = useMeQuery();

  const [onboarding] = useOnboardingMutation();
  const [validateForm] = useValidateFormMutation();

  if (!user) return null;

  const onSubmit = async values => {
    console.log('wtf submit');
    const { buyFeeMethod } = values;

    if (buyFeeMethod === 'credit_card') {
      const { error } = await validateForm({
        credit_card_number: (values.credit_card_number || '').replace(/\s/g, ''),
        credit_card_expiry_date: moment(values.credit_card_expiry_date || '', 'MM/YY').format('YYYY-MM'),
        credit_card_cvc: values.credit_card_cvc || 1 // Default value to trigger validation
      });

      if (error) {
        return {
          credit_card_number: 'Please provide valid credit card credentials',
          credit_card_expiry_date: 'Please provide valid credit card credentials',
          credit_card_cvc: 'Please provide valid credit card credentials'
        };
      }

      await onboarding({
        last_step: ONBOARDING_STEPS.FOURTH_BUYER_STEP,
        credit_card_number: (values.credit_card_number || '').replace(/\s/g, ''),
        credit_card_expiry_date: values.credit_card_expiry_date
          ? moment.utc(values.credit_card_expiry_date || '', 'MM/YY').format('YYYY-MM')
          : '',
        credit_card_cvc: values.credit_card_cvc
      });
    } else {
      await onboarding({ last_step: ONBOARDING_STEPS.FOURTH_BUYER_STEP });
    }

    nextStep();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        buyFeeMethod: 'credit_card',
        credit_card_number: '',
        credit_card_expiry_date: '',
        credit_card_cvc: ''
      }}
      validate={validate}
    >
      {props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle
                title="Buy Fee Payment Method"
                description="Select how you want to pay for the buy fees charged by AutoAxess after you are awarded a vehicle."
              />
              <Grid container spacing={24}>
                <Grid item>
                  <BuyFeeMethodPicker />

                  {props.values.buyFeeMethod === 'ach_flooring' && (
                    <StyledDisclaimerDescription>
                      <br />
                      <b>Important:</b> you can enter your ACH/Flooring information in the last step of the registration
                      process. After a vehicle is awarded to you, you will be able to choose ACH or Flooring to pay the
                      fee.
                    </StyledDisclaimerDescription>
                  )}
                </Grid>
                {props.values.buyFeeMethod === 'credit_card' && (
                  <>
                    <InfoAlert>Your credit card is not used to pay for vehicles.</InfoAlert>
                    <FormCreditCardPicker />
                    <Grid item container justify="center" xs={12}>
                      <StyledCardLogo src={cardLogo} alt="visa/mastercard logo" />
                      <StyledCardLogo src={cardLogo2} alt="discover/amex logo" />
                    </Grid>
                  </>
                )}
              </Grid>
              <StepControl nextStep={nextStep} prevStep={prevStep} />
            </FormWrapper>
            <OfficePhoneLabel />
          </form>
        );
      }}
    </Form>
  );
};

export default thirdBuyerStep;
