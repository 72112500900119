import { useDispatch, useSelector } from 'react-redux';
import { ListVehiclesState } from '../../../services/listVehiclesSlice/listVehiclesSlice';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListVehiclePoolRowFromServer } from '../components/ListVehiclesRow/types';
import listVehiclesApi from '../../../services/api/list-vehicles';

export type UpdateCb = (draft: MaybeDrafted<ListVehiclePoolRowFromServer[]>) => void;

export const useUpdatePoolQueryData = () => {
  const filters = useSelector<{ listVehicles: ListVehiclesState }, Record<string, any>>(
    state => state.listVehicles.poolFilters
  );

  const dispatch = useDispatch<any>();

  return (cb: UpdateCb) => {
    dispatch(listVehiclesApi.util.updateQueryData('getVehiclesPools', { ...filters }, draft => cb(draft)));
  };
};
