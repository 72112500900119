import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  padding: 20px;
  max-width: 400px;
  width: 100%;
  margin: 15px auto;

  @media (max-width: 768px) {
    max-width: unset;
    margin: 0;
    box-shadow: none;
  }
`;
