import { Header } from './components';
import { AllTab, EasyPayTab, TransactionsTab } from './containers';
import { useSearchParams, useUpdateSearchParams } from 'hooks';

const TransactionsList = (props) => {
  const params = useSearchParams();
  const currentTab = params.get('active_tab') ?? '';
  const updateSearchParams = useUpdateSearchParams();

  const changeTab = tab => {
    updateSearchParams({ active_tab: tab });
  };

  return (
    <div>
      <Header currentTab={currentTab} changeTab={changeTab} {...props} />
      <div className="pageWrapper">
        {(currentTab === '' || currentTab === 'all') && <AllTab />}
        {currentTab === 'transactions' && <TransactionsTab />}
        {currentTab === 'easy_pay' && <EasyPayTab />}
      </div>
    </div>
  );
};

export default TransactionsList;
