import React from 'react';
import ModalWrapper from '../ModalWrapper';

const ConfirmModal = ({ submitText, title, handleSubmit, modalId, text = '' }) => (
  <ModalWrapper
    title={title}
    handleSubmit={handleSubmit}
    modalId={modalId}
    submitText={submitText}
  >
    {text}
  </ModalWrapper>
);

export default ConfirmModal;
