import { restoreInitialUser, getInitialNavigationPage } from '../utils/auth';

export const LOGGED_AS_ADMIN_LINK = {
  text: 'Go back to Admin',
  onClick: () => {
    const navLink = getInitialNavigationPage() ?? '/admin/customers';
    restoreInitialUser();
    window.location.href = navLink;
  }
};

export const GUEST_LINKS = [
  {
    text: 'More Info',
    list: [
      {
        to: '/about-us',
        text: 'About Us'
      },
      {
        to: '/faqs',
        text: "FAQ's"
      },
      {
        to: '/contact-us',
        text: 'Contact Us'
      },
      {
        to: '/terms-of-use',
        text: 'Terms Of Use'
      }
    ]
  },
  {
    to: '/login',
    text: 'Login'
  },
  {
    to: '/register',
    text: 'Sign Up'
  }
];

export const BUYER_LINKS = [
  {
    to: '/buyer/bids',
    text: 'My Bids'
  },
  {
    to: '/buyer/bids-history',
    text: 'Bid History'
  },
  {
    to: '/buyer/transactions',
    text: 'Transactions'
  },
  {
    text: 'More Info',
    list: [
      {
        to: '/about-us',
        text: 'About Us'
      },
      {
        to: '/faqs',
        text: "FAQ's"
      },
      {
        to: '/contact-us',
        text: 'Contact Us'
      },
      {
        to: '/terms-of-use',
        text: 'Terms Of Use'
      }
    ]
  },
  {
    type: 'profile'
  }
];

export const SELLER_LINKS = [
  {
    text: 'Dashboard',
    to: '/seller/dashboard'
  },
  {
    text: 'Manage Vehicles',
    to: '/seller/vehicles'
  },
  {
    text: 'Sales History',
    to: '/seller/vehicle-sales/history'
  },
  {
    text: 'More Info',
    list: [
      {
        to: '/about-us',
        text: 'About Us'
      },
      {
        to: '/faqs',
        text: "FAQ's"
      },
      {
        to: '/contact-us',
        text: 'Contact Us'
      },
      {
        to: '/terms-of-use',
        text: 'Terms Of Use'
      }
    ]
  },
  {
    type: 'profile'
  }
];

export const SELLER_PROFILE_LINKS = [
  {
    to: '/profile/account_information',
    text: 'Account Information'
  },
  {
    to: '/profile/about_your_company',
    text: 'About Your Company'
  },
  {
    to: '/profile/purchase_options',
    text: 'Purchase Options'
  }
];

export const BUYER_PROFILE_LINKS = [
  {
    to: '/profile/account_information',
    text: 'Account Information'
  },
  {
    to: '/profile/about_your_company',
    text: 'About Your Company'
  },
  {
    to: '/profile/card_information',
    text: 'Card Information'
  },
  {
    to: '/profile/manage_documents',
    text: 'Manage Documents'
  },
  {
    to: '/profile/purchase_methods',
    text: 'Purchase Methods'
  }
];

export const ADMIN_LINKS = [
  {
    to: '/admin/auctions',
    text: 'Auctions'
  },
  {
    text: 'Billing',
    list: [
      {
        to: '/admin/financials/invoices',
        text: 'Seller Invoice'
      },
      {
        to: '/admin/financials/buyer-fees',
        text: 'Buyer Fees'
      },
      {
        to: '/admin/financials/declined-cards',
        text: 'Declined Cards'
      },
      {
        to: '/admin/financials/additional-fees',
        text: 'Additional Fees'
      },
      {
        to: '/admin/financials/transactions',
        text: 'Transactions'
      },
      {
        to: '/admin/financials/auction-summary-report',
        text: 'Auction Summary Report'
      },
      {
        to: '/admin/financials/lister-summary-report',
        text: 'Lister Summary Report'
      },
      {
        to: '/admin/accounts',
        text: 'Accounts'
      }
    ]
  },
  {
    text: 'CMS',
    list: [
      {
        to: '/admin/banners',
        text: 'Banners'
      },
      {
        to: '/admin/site-statistics',
        text: 'Site Statistics'
      }
    ]
  },
  {
    to: '/admin/customers',
    text: 'Customers'
  },
  {
    to: '/admin/vehicles',
    text: 'Vehicles'
  },
  {
    to: '/admin/locations',
    text: 'Locations'
  },
  localStorage.getItem('backup_token')
    ? LOGGED_AS_ADMIN_LINK
    : {
        text: 'Logout',
        onClick: () => {
          localStorage.clear();
          window.location.href = '/';
        }
      }
];

export const ADMIN_LINKS_MANAGER = [
  {
    to: '/admin/auctions',
    text: 'Auctions'
  },
  {
    text: 'Billing',
    list: [
      {
        to: '/admin/financials/invoices',
        text: 'Seller Invoice'
      },
      {
        to: '/admin/financials/declined-cards',
        text: 'Declined Cards'
      },
      {
        to: '/admin/financials/additional-fees',
        text: 'Additional Fees'
      },
      {
        to: '/admin/financials/transactions',
        text: 'Transactions'
      }
    ]
  },
  {
    text: 'CMS',
    list: [
      {
        to: '/admin/banners',
        text: 'Banners'
      },
      {
        to: '/admin/site-statistics',
        text: 'Site Statistics'
      }
    ]
  },
  {
    to: '/admin/customers',
    text: 'Customers'
  },
  {
    to: '/admin/vehicles',
    text: 'Vehicles'
  },
  {
    to: '/lister/dashboard',
    text: 'Dashboard'
  },
  {
    to: '/lister/map',
    text: 'Map'
  },
  localStorage.getItem('backup_token')
    ? LOGGED_AS_ADMIN_LINK
    : {
        text: 'Logout',
        onClick: () => {
          localStorage.clear();
          window.location.href = '/';
        }
      }
];

export const ADMIN_LINKS_SALESMAN = [
  {
    to: '/admin/auctions',
    text: 'Auctions'
  },
  {
    to: '/admin/customers',
    text: 'Customers'
  },
  {
    to: '/admin/vehicles',
    text: 'Vehicles'
  },
  {
    to: '/lister/dashboard',
    text: 'Dashboard'
  },
  {
    to: '/lister/map',
    text: 'Map'
  },
  localStorage.getItem('backup_token')
    ? LOGGED_AS_ADMIN_LINK
    : {
        text: 'Logout',
        onClick: () => {
          localStorage.clear();
          window.location.href = '/';
        }
      }
];

export const ADMIN_LINKS_LISTER = [
  {
    to: '/lister/dashboard',
    text: 'Dashboard'
  },
  {
    to: '/lister/map',
    text: 'Map'
  },
  {
    to: '/admin/auctions',
    text: 'Auctions'
  },
  {
    to: '/admin/customers',
    text: 'Customers'
  },
  {
    to: '/admin/vehicles',
    text: 'Vehicles'
  },
  {
    text: 'More Info',
    list: [
      {
        to: '/about-us',
        text: 'About Us'
      },
      {
        to: '/faqs',
        text: "FAQ's"
      },
      {
        to: '/contact-us',
        text: 'Contact Us'
      },
      {
        to: '/terms-of-use',
        text: 'Terms Of Use'
      }
    ]
  },
  localStorage.getItem('backup_token')
    ? LOGGED_AS_ADMIN_LINK
    : {
        text: 'Logout',
        onClick: () => {
          localStorage.clear();
          window.location.href = '/';
        }
      }
];

export const ADMIN_LINKS_ADMIN_SELLER = [
  {
    to: '/admin/my-sellers',
    text: 'My Sellers'
  },
  {
    to: '/lister/dashboard',
    text: 'Dashboard'
  },
  {
    to: '/lister/map',
    text: 'Map'
  },
  localStorage.getItem('backup_token')
    ? LOGGED_AS_ADMIN_LINK
    : {
        text: 'Logout',
        onClick: () => {
          localStorage.clear();
          window.location.href = '/';
        }
      }
];

export const NOT_COMPLETED_REGISTRATION_LINKS = [
  {
    text: 'Go back to Admin',
    onClick: () => {
      const navLink = getInitialNavigationPage() ?? '/admin/customers';
      restoreInitialUser();
      window.location.href = navLink;
    }
  }
];

export default {
  GUEST_LINKS,
  BUYER_LINKS,
  SELLER_LINKS,
  ADMIN_LINKS,
  SELLER_PROFILE_LINKS,
  NOT_COMPLETED_REGISTRATION_LINKS
};
