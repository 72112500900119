import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Section } from '../components';
import { selectedVehicleSelector } from '../../../../selectors';
import { COLOR_SUCCESSFUL } from '../../../../constants';
import { FormattedAmount } from '../../../../components';

const StyledFormattedAmount = styled(FormattedAmount)`
  color: ${COLOR_SUCCESSFUL}
`;

const TransportFee = ({ vehicle }) => {
  let fee = 0
  if (vehicle && vehicle.buy_fee && vehicle.buy_fee.transportation_fee) {
    fee = vehicle.buy_fee.transportation_fee;
  }
  if (!fee) return null;
  return (
    <Section title='Transport' xs={6} md={3}>
      <StyledFormattedAmount amount={fee} />
    </Section>
  )
}

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state),
  fees: state.financials.fees,
});

export default connect(mapStateToProps)(TransportFee);
