export const DoubleCaretDown = ({ fillColor = '#3994DE' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 11.0501L17.425 7.1751C17.7583 6.94176 18.1042 6.9126 18.4625 7.0876C18.8208 7.2626 19 7.55843 19 7.9751C19 8.14176 18.9625 8.3001 18.8875 8.4501C18.8125 8.6001 18.7083 8.71676 18.575 8.8001L12 13.5001L5.425 8.8001C5.29167 8.71676 5.1875 8.6001 5.1125 8.4501C5.0375 8.3001 5 8.14176 5 7.9751C5 7.5751 5.17917 7.28343 5.5375 7.1001C5.89583 6.91676 6.24167 6.94176 6.575 7.1751L12 11.0501ZM12 17.0001L17.425 13.1251C17.7583 12.8918 18.1042 12.8626 18.4625 13.0376C18.8208 13.2126 19 13.5084 19 13.9251C19 14.0918 18.9625 14.2501 18.8875 14.4001C18.8125 14.5501 18.7083 14.6668 18.575 14.7501L12 19.4501L5.425 14.7501C5.29167 14.6668 5.1875 14.5501 5.1125 14.4001C5.0375 14.2501 5 14.0918 5 13.9251C5 13.5251 5.17917 13.2334 5.5375 13.0501C5.89583 12.8668 6.24167 12.8918 6.575 13.1251L12 17.0001Z"
      fill={fillColor}
    />
  </svg>
);

export const DoubleCaretUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 12.9499L6.575 16.8249C6.24167 17.0582 5.89583 17.0874 5.5375 16.9124C5.17917 16.7374 5 16.4416 5 16.0249C5 15.8582 5.0375 15.6999 5.1125 15.5499C5.1875 15.3999 5.29167 15.2832 5.425 15.1999L12 10.4999L18.575 15.1999C18.7083 15.2832 18.8125 15.3999 18.8875 15.5499C18.9625 15.6999 19 15.8582 19 16.0249C19 16.4249 18.8208 16.7166 18.4625 16.8999C18.1042 17.0832 17.7583 17.0582 17.425 16.8249L12 12.9499ZM12 6.9999L6.575 10.8749C6.24167 11.1082 5.89583 11.1374 5.5375 10.9624C5.17917 10.7874 5 10.4916 5 10.0749C5 9.90824 5.0375 9.7499 5.1125 9.5999C5.1875 9.4499 5.29167 9.33324 5.425 9.2499L12 4.5499L18.575 9.2499C18.7083 9.33324 18.8125 9.4499 18.8875 9.5999C18.9625 9.7499 19 9.90823 19 10.0749C19 10.4749 18.8208 10.7666 18.4625 10.9499C18.1042 11.1332 17.7583 11.1082 17.425 10.8749L12 6.9999Z"
      fill="#3994DE"
    />
  </svg>
);
