import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { VEHICLE_STATUSES } from '../../constants';

type LabelType = 'met' | 'not-met' | 'unset';

const colorMap = {
  met: '#479747',
  unset: '#3994DE',
  'not-met': '#ca8d57'
};

const ReservePriceLabelComponent = styled.div<{ fitContent?: boolean; type: LabelType; size: 'sm' | 'lg' }>`
  background-color: ${props => colorMap[props.type]};
  border-radius: 9999px;
  padding: ${props => (props.size === 'sm' ? '4px' : '6px')};
  text-align: center;
  color: #fff;
  font-size: ${props => (props.size === 'sm' ? '8px' : '12px')};
  min-width: ${props => (props.fitContent ? 'fit-content' : '130px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReservePriceLabel = ({
  activeVehicle,
  twoColorMode = false,
  fitContent = false,
  size = 'lg'
}: {
  activeVehicle?: any;
  twoColorMode?: boolean;
  fitContent?: boolean;
  size?: 'sm' | 'lg';
}) => {
  const storeVehicle: any = useSelector<any>(state => state.vehicles.selectedVehicle.data);
  const vehicle = activeVehicle ?? storeVehicle;
  const user = useSelector((state: any) => state.user?.user);

  const labelConfig: { type: LabelType; text: string } = (() => {
    if (!vehicle.reserve_price_set) {
      return {
        type: twoColorMode ? 'not-met' : 'unset',
        text: 'Reserve price not set'
      };
    }
    if (vehicle.reserve_price_met) {
      return {
        type: 'met',
        text: 'Reserve price met'
      };
    }
    return {
      type: 'not-met',
      text: 'Reserve price not met'
    };
  })();

  if (labelConfig.type === 'unset' && user?.role === 'buyer') return null;

  return (
    <ReservePriceLabelComponent fitContent={fitContent} size={size} color="inherit" type={labelConfig.type}>
      {labelConfig.text}
    </ReservePriceLabelComponent>
  );
};

export const VehicleSoldLabel = ({ activeVehicle }: { activeVehicle?: any }) => {
  const isSold = activeVehicle?.status === VEHICLE_STATUSES.AWARDED;

  if (!isSold) return null;

  return (
    <ReservePriceLabelComponent color="inherit" type={'met'} size="lg">
      Sold
    </ReservePriceLabelComponent>
  );
};
