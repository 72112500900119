import {
  LIST_LOCATIONS_REQUEST,
  LIST_LOCATIONS_SUCCESS,
  LIST_LOCATIONS_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  LOCATIONS_SET_PAGE,
  LOCATIONS_SET_PER_PAGE,
  LOCATIONS_SET_END_DAY,
  LOCATIONS_SET_LOCATION_NAME,
  LOCATIONS_UNSET_SUCCESS,
  SET_LOCATION,
  SET_EDIT_LOCATION
} from '../constants/actionTypes';
import { LOCATIONS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  submiting: false,
  itemsData: {
    rows: [],
    count: 0
  },
  singleItem: null,
  page: 0,
  perPage: LOCATIONS_PER_PAGE,
  startDay: '',
  locationName: '',
  error: '',
  success: false,
  currentLocation: null,
  editLocationData: null
};

const locations = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_LOCATIONS_REQUEST:
      return { ...state, loading: true };
    case LIST_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
          // rows: [
          //   ...(state.locationName || state.startDay ? [] : state.itemsData.rows),
          //   ...payload.data.rows
          // ]
        }
      };
    case LIST_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    case CREATE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        submitting: true,
        success: false,
        error: ''
      };
    case CREATE_LOCATION_SUCCESS: {
      const updatedState = {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: [...state.itemsData.rows, payload.data],
          count: +state.itemsData.count + 1
        }
      };
      return {
        ...updatedState,
        loading: false,
        submitting: false,
        success: true,
        error: ''
      };
    }
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        success: false,
        error: payload.response.data.data[0].message
      };
    case UPDATE_LOCATION_REQUEST:
      return { ...state, loading: true };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(item => (item.id === payload.data.id ? payload.data : item))
        }
      };
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.response.data.message
      };
    case DELETE_LOCATION_REQUEST:
      return { ...state, loading: true, error: '' };
    case DELETE_LOCATION_SUCCESS: {
      const updatedState = {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.filter(item => item.id !== payload.id)
        }
      };
      return { ...updatedState, loading: false, error: '' };
    }
    case DELETE_LOCATION_FAILURE:
      return { ...state, loading: false, error: payload.response.data.message };
    case GET_LOCATION_REQUEST:
      return { ...state, loading: true, error: '' };
    case GET_LOCATION_SUCCESS: {
      return { ...state, singleItem: payload.data, loading: false, error: '' };
    }
    case GET_LOCATION_FAILURE:
      return { ...state, loading: false, error: payload.response.data.message };
    case LOCATIONS_SET_PAGE:
      return { ...state, page: payload };
    case LOCATIONS_SET_PER_PAGE:
      return { ...state, loading: false, perPage: payload };
    case LOCATIONS_SET_END_DAY:
      return { ...state, loading: false, startDay: payload };
    case LOCATIONS_SET_LOCATION_NAME:
      return { ...state, loading: false, locationName: payload };
    case LOCATIONS_UNSET_SUCCESS:
      return { ...state, loading: false, success: false };
    case SET_LOCATION:
      return { ...state, currentLocation: payload };
    case SET_EDIT_LOCATION:
      return { ...state, editLocationData: payload };
    default:
      return state;
  }
};

export default locations;
