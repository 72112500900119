import { MyBidsNotesBlock } from './NotesBlock';
import { BlockProps } from './types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useVehicleBidsActions } from '../hooks/useVehicleBidsActions';
import { Delimiter, TableButton } from '../../../../../components/Table/common';
import { useBidsOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { RenderPlaceBid } from './RenderPlaceBid';
import { BuyerModals } from './BuyerModals';
import { MyBidsCurrentBidBlock } from './CurrentBidBlock';
import { ReservePriceLabel } from '../../../../../components/ReservePriceMet';
import { BUYER_VEHICLE_STATUS } from '../../../../../constants';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  gap: 20px;
  align-items: center;
`;

const BidActions = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
`;

const ButtonWrapper = styled.div`
  width: 160px;
  display: flex;
`;

const ReservePriceWrapper = styled.div<{ canPlaceBid: boolean }>`
    position: ${({ canPlaceBid }) => canPlaceBid ? 'absolute' : 'static'};
    top: -25px;
    width: 100%;
`;

export const MobileHorizontalTableBottomBlock = ({ currentVehicle }: BlockProps) => {
  const user = useSelector((state: any) => state.user?.user);
  const offerAuction = useBidsOfferAuction(currentVehicle.id);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);

  const buttons = useVehicleBidsActions(currentVehicle, offerAuction, status);

  const canPlaceBid = [
    BUYER_VEHICLE_STATUS.OUT_BID,
    BUYER_VEHICLE_STATUS.HIGH_BID,
    BUYER_VEHICLE_STATUS.ACTIVE,
    BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER,
    BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS
  ].includes(status);

  return (
    <Wrapper className="table-block">
      <div style={{ width: 200 }}>
        <MyBidsCurrentBidBlock currentVehicle={currentVehicle} />
      </div>
      <Delimiter />
      <div style={{ minWidth: 160 }}>
        <MyBidsNotesBlock currentVehicle={currentVehicle} />
      </div>
      <BidActions>
        <ReservePriceWrapper canPlaceBid={canPlaceBid}>
          <ReservePriceLabel activeVehicle={currentVehicle} />
        </ReservePriceWrapper>
        <RenderPlaceBid withButtons currentVehicle={currentVehicle} offerAuction={offerAuction} status={status} />
        {!canPlaceBid &&
          buttons.map((btn, i) => {
            const renderButton = !btn.conditionalRendering || btn.conditionalRendering(currentVehicle);
            if (!renderButton) return null;

            return (
              <ButtonWrapper>
                <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
              </ButtonWrapper>
            );
          })}
      </BidActions>
      <BuyerModals currentVehicle={currentVehicle} />
    </Wrapper>
  );
};
