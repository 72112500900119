import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
  background: #f5f5f5;
  width: 100%;
  padding: 12px 8px;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const NotesComponent = ({ vehicle }) => {
  const notes = vehicle.vehicle_notes?.[0]?.notes ?? vehicle?.notes
  if (!notes) return null;

  return (
    <Container>
      <Text>{notes}</Text>
    </Container>
  );
};
