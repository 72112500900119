import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Divider } from '@material-ui/core';
import {
  modalsToggle,
  relistVehicle,
  listAuctions
} from '../../../../../actions';
import AuctionCreateModal from '../../../../../components/Modals/AuctionCreateModal';
import ReactivateVehicleModal from '../../ReactivateVehicleModal';
import {
  VEHICLE_STATUSES,
  AUCTION_STATUSES,
  DATE_FORMAT_FULL
} from '../../../../../constants';

const modalReactivateTitle = 'reactivateVehicleModal';
const modalNewAuction = 'newAuctionModal';

const StyledRelistSpan = styled.span`
  color: #06a000;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
  margin-bottom: 16px;
`;

const StyledDisabledSpan = styled.span`
  color: rgba(0, 0, 0, 0.12);
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledSmallerSpan = styled.span`
  font-size: 14px;
`;

const StyledDeactivateSpan = styled.span`
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
`;

class DeactivatedBlock extends Component {
  componentDidMount() {
    const { fetchAuctions } = this.props;
    fetchAuctions();
  }

  handleClickRelist = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalReactivateTitle);
  };

  handleClickNewAuction = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalNewAuction);
  };

  handleRelist = () => {
    const {
      currentVehicle,
      relistCurrentVehicle,
      relistForm,
      modalsToggle: toggle
    } = this.props;
    relistCurrentVehicle({
      vehicle_id: currentVehicle.id,
      auctionId: relistForm.auction,
      message: 'Vehicle reactivated successfully'
    });
    toggle(modalReactivateTitle);
  };

  render() {
    const { currentVehicle, auctions } = this.props;
    return (
      <Grid item xs={12}>
        {currentVehicle.status === VEHICLE_STATUSES.DEACTIVATED && (
          <Fragment>
            {auctions.length > 0 && (
              <StyledRelistSpan onClick={this.handleClickRelist}>
                Reactivate vehicle in auction
              </StyledRelistSpan>
            )}
            {auctions.length === 0 && (
              <>
                <StyledDisabledSpan>
                  Reactivate vehicle in auction
                </StyledDisabledSpan>
                <br />
                <StyledSmallerSpan>
                  There is no an active auction to reactivate the vehicle in.
                  <br />
                  <StyledDeactivateSpan onClick={this.handleClickNewAuction}>
                    Create a New auction
                  </StyledDeactivateSpan>
                  <br />
                </StyledSmallerSpan>
              </>
            )}
            <ReactivateVehicleModal
              handleSubmit={this.handleRelist}
              {...this.props}
            />
            <AuctionCreateModal />
          </Fragment>
        )}
        <StyledDivider />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  currentVehicle: state.vehicles.selectedVehicle.data,
  relistForm: state.form.ReactivateVehicleModal
    ? state.form.ReactivateVehicleModal.values || []
    : [],
  auctions: (state.auctions.itemsData.rows || [])
    .filter(item => item.status !== AUCTION_STATUSES.ENDED)
    .map(item => ({
      ...item,
      formatedStartDate: moment
        .utc(item.date_start)
        .local()
        .format(DATE_FORMAT_FULL),
      isCurrent:
        moment
          .utc(item.date_end)
          .local()
          .diff() > 0 &&
        moment
          .utc(item.date_start)
          .local()
          .diff() < 0
    }))
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  relistCurrentVehicle: data => dispatch(relistVehicle(data).request),
  fetchAuctions: params => dispatch(listAuctions(params).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeactivatedBlock);
