import styled from 'styled-components';
import { useSoundRecordingContext } from '../../../../context/SoundRecordingContext';
import './index.scss';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 8px;
  background: #fff;
  position: relative;
  height: 50px;
`;

const RecordingCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: rgba(233, 51, 35, 1);
  animation: blink infinite 1s;
`;

const TimeWrapper = styled.div`
  border-radius: 8px;
  background: rgba(204, 204, 204, 0.8);
  width: 72px;
  height: 24px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(102, 102, 102, 1);
  padding-left: 8px;
  padding-right: 10px;
  display: flex;
  gap: 7px;
  align-items: center;
`;

const BinIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
`;

const formatTime = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  return `00:${totalSeconds.toString().padStart(2, '0')}`;
};

export const RecordingPlayer = () => {
  const { isRecording, recordingTime, removeRecording } = useSoundRecordingContext();
  if (!isRecording) return null;

  return (
    <Container>
      <TimeWrapper>
        <RecordingCircle />
        {/** TODO: fix the hack( */}
        <span>{formatTime((recordingTime || 0) + 100)}</span>
      </TimeWrapper>
      <BinIconWrapper onClick={removeRecording}>
        <BinIcon />
      </BinIconWrapper>
    </Container>
  );
};

const BinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
      fill="#999999"
      fill-opacity="0.6"
    />
  </svg>
);
