import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { VehicleSection, VehicleSectionImage } from '../../types/vehicle';
import { useSelector } from 'react-redux';

type ViewVehicleState = {
  initialFullScreenIndex: number | null;
  fullScreenImageModalOpen: boolean;
  images: VehicleSectionImage[];
}

const initialState: ViewVehicleState = {
    initialFullScreenIndex: null,
    fullScreenImageModalOpen: false,
    images: []
};

const viewVehicleSlice = createSlice({
  name: 'viewVehicle',
  initialState,
  reducers: {
      setImages: (state, action: PayloadAction<VehicleSectionImage[]>) => { 
        state.images = action.payload;
      },
      openFullScreenWithIndex: (state, action: PayloadAction<number>) => {
          state.fullScreenImageModalOpen = true;
          state.initialFullScreenIndex = action.payload;
      },
      openFullScreenWithId: (state, action: PayloadAction<number>) => {
         const index = state.images.findIndex(i => i.id === action.payload);
        if (index === -1) return;
          state.fullScreenImageModalOpen = true;
          state.initialFullScreenIndex = index;
      },
      closeFullScreen: (state) => {
          state.fullScreenImageModalOpen = false;
          state.initialFullScreenIndex = null
      },
      resetState: () => {
          return initialState
      }
    }
});

export const useOpenedFullScreenImageDialog = () => {
  const fullScreenImageModalOpen = useSelector<{ viewVehicle: ViewVehicleState }, boolean>((state) => state.viewVehicle.fullScreenImageModalOpen)
  return fullScreenImageModalOpen
}

export const useImages = () => {
  const images = useSelector<{ viewVehicle: ViewVehicleState }, VehicleSectionImage[]>((state) => state.viewVehicle.images)
  return images
}

export const useInitialFullScreenIndex = () => {
  const images = useSelector<{ viewVehicle: ViewVehicleState }, number | null>((state) => state.viewVehicle.initialFullScreenIndex)
  return images
}

export const { setImages, openFullScreenWithId, openFullScreenWithIndex, closeFullScreen, resetState } = viewVehicleSlice.actions
export default viewVehicleSlice;
