export const getRole = () => JSON.parse(localStorage.getItem('user')).role || 'guest';

export const setToken = token => localStorage.setItem('token', token);

export const setUser = user => localStorage.setItem('user', JSON.stringify(user));

export const getToken = () => localStorage.getItem('token');

export const getUser = () => JSON.parse(localStorage.getItem('user') || '{}');

export const removeToken = () => localStorage.removeItem('token');

export const removeUser = () => localStorage.removeItem('user');

export const setBackupUser = user => localStorage.setItem('backup_user', JSON.stringify(user));

export const setInitialNavigationPage = initialPage => localStorage.setItem('initial_page', initialPage);

export const setBackupToken = token => localStorage.setItem('backup_token', token);

export const getBackupToken = () => localStorage.getItem('backup_token');

export const getBackupUser = () => JSON.parse(localStorage.getItem('backup_user') || '{}');

export const getInitialNavigationPage = () => localStorage.getItem('initial_page');

export const setAnotherUserCredentials = (newUser, newToken, initialPage) => {
  const currentToken = getToken();
  const currentUser = getUser();
  setInitialNavigationPage(initialPage);
  setBackupUser(currentUser);
  setBackupToken(currentToken);
  setUser(newUser);
  setToken(newToken);
};

export const restoreInitialUser = () => {
  const backupUser = getBackupUser();
  const backupToken = getBackupToken();
  setBackupUser({});
  setBackupToken('');
  setInitialNavigationPage('');
  setUser(backupUser);
  setToken(backupToken);
};

export default {
  setToken,
  getToken,
  removeToken,
  getRole,
  getUser,
  removeUser,
  setAnotherUserCredentials,
  getBackupToken,
  getBackupUser
};
