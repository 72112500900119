import {
  LIST_AUCTIONS_REQUEST,
  LIST_AUCTIONS_SUCCESS,
  LIST_AUCTIONS_FAILURE,
  LIST_AUCTIONS_CLEAR,
  CREATE_AUCTION_REQUEST,
  CREATE_AUCTION_SUCCESS,
  CREATE_AUCTION_FAILURE,
  AUCTIONS_SET_PAGE,
  AUCTIONS_SET_PER_PAGE,
  AUCTIONS_SET_SEARCH_DATE,
  AUCTIONS_UNSET_SUCCESS,
  AUCTIONS_GET_CURRENT_REQUEST,
  AUCTIONS_GET_CURRENT_SUCCESS,
  AUCTIONS_GET_CURRENT_FAILURE,
  AUCTIONS_GET_NEXT_REQUEST,
  AUCTIONS_GET_NEXT_SUCCESS,
  AUCTIONS_GET_NEXT_FAILURE,
  AUCTIONS_GET_BY_ID_REQUEST,
  AUCTIONS_GET_BY_ID_SUCCESS,
  AUCTIONS_GET_BY_ID_FAILURE,
  LIST_AUCTION_VEHICLES_REQUEST,
  LIST_AUCTION_VEHICLES_SUCCESS,
  LIST_AUCTION_VEHICLES_FAILURE,
  AUCTION_EXPORT_SELLER_REPORT_REQUEST,
  AUCTION_EXPORT_SELLER_REPORT_SUCCESS,
  AUCTION_EXPORT_SELLER_REPORT_FAILURE,
  AUCTION_EXPORT_BUYER_REPORT_REQUEST,
  AUCTION_EXPORT_BUYER_REPORT_SUCCESS,
  AUCTION_EXPORT_BUYER_REPORT_FAILURE,
  FILTER_AUCTIONS_REQUEST,
  FILTER_AUCTIONS_SUCCESS,
  FILTER_AUCTIONS_FAILURE,
  AUCTIONS_SET_TYPE
} from '../constants/actionTypes';

export const listAuctions = data => ({
  request: { type: LIST_AUCTIONS_REQUEST, payload: data },
  success: { type: LIST_AUCTIONS_SUCCESS, payload: data },
  failure: { type: LIST_AUCTIONS_FAILURE, payload: data }
});

export const filterAuctions = data => ({
  request: { type: FILTER_AUCTIONS_REQUEST, payload: data },
  success: { type: FILTER_AUCTIONS_SUCCESS, payload: data },
  failure: { type: FILTER_AUCTIONS_FAILURE, payload: data }
});

export const listAuctionVehicles = data => ({
  request: { type: LIST_AUCTION_VEHICLES_REQUEST, payload: data },
  success: { type: LIST_AUCTION_VEHICLES_SUCCESS, payload: data },
  failure: { type: LIST_AUCTION_VEHICLES_FAILURE, payload: data }
});

export const FILTER_AUCTION_VEHICLES_REQUEST = 'FILTER_AUCTION_VEHICLES_REQUEST';
export const FILTER_AUCTION_VEHICLES_SUCCESS = 'FILTER_AUCTION_VEHICLES_SUCCESS';
export const FILTER_AUCTION_VEHICLES_FAILURE = 'FILTER_AUCTION_VEHICLES_FAILURE';

export const filterAuctionVehicles = data => ({
  request: { type: FILTER_AUCTION_VEHICLES_REQUEST, payload: data },
  success: { type: FILTER_AUCTION_VEHICLES_SUCCESS, payload: data },
  failure: { type: FILTER_AUCTION_VEHICLES_FAILURE, payload: data }
});

export const getAuctionById = data => ({
  request: { type: AUCTIONS_GET_BY_ID_REQUEST, payload: data },
  success: { type: AUCTIONS_GET_BY_ID_SUCCESS, payload: data },
  failure: { type: AUCTIONS_GET_BY_ID_FAILURE, payload: data }
});
export const addAuction = data => ({
  request: { type: CREATE_AUCTION_REQUEST, payload: data },
  success: { type: CREATE_AUCTION_SUCCESS, payload: data },
  failure: { type: CREATE_AUCTION_FAILURE, payload: data }
});
export const auctionsSetPage = data => ({
  type: AUCTIONS_SET_PAGE,
  payload: data
});
export const auctionsSetType = data => ({
  type: AUCTIONS_SET_TYPE,
  payload: data
});
export const auctionsSetPerPage = data => ({
  type: AUCTIONS_SET_PER_PAGE,
  payload: data
});
export const auctionsSetSearchDate = data => ({
  type: AUCTIONS_SET_SEARCH_DATE,
  payload: data
});
export const auctionsUnsetSuccess = data => ({
  type: AUCTIONS_UNSET_SUCCESS,
  payload: data
});
export const getCurrentAuction = data => ({
  request: { type: AUCTIONS_GET_CURRENT_REQUEST, payload: data },
  success: { type: AUCTIONS_GET_CURRENT_SUCCESS, payload: data },
  failure: { type: AUCTIONS_GET_CURRENT_FAILURE, payload: data }
});

export const getNextAuction = data => ({
  request: { type: AUCTIONS_GET_NEXT_REQUEST, payload: data },
  success: { type: AUCTIONS_GET_NEXT_SUCCESS, payload: data },
  failure: { type: AUCTIONS_GET_NEXT_FAILURE, payload: data }
});

export const exportSellerReport = data => ({
  request: { type: AUCTION_EXPORT_SELLER_REPORT_REQUEST, payload: data },
  success: { type: AUCTION_EXPORT_SELLER_REPORT_SUCCESS, payload: data },
  failure: { type: AUCTION_EXPORT_SELLER_REPORT_FAILURE, payload: data }
});

export const exportBuyerReport = data => ({
  request: { type: AUCTION_EXPORT_BUYER_REPORT_REQUEST, payload: data },
  success: { type: AUCTION_EXPORT_BUYER_REPORT_SUCCESS, payload: data },
  failure: { type: AUCTION_EXPORT_BUYER_REPORT_FAILURE, payload: data }
});

export const AUCTION_VEHICLES_SET_PAGE = 'AUCTION_VEHICLES_SET_PAGE';

export const auctionVehiclesSetPage = data => ({
  type: AUCTION_VEHICLES_SET_PAGE,
  payload: data
});

export const AUCTION_VEHICLES_SET_MODAL = 'AUCTION_VEHICLES_SET_MODAL';

export const auctionVehiclesSetModal = data => ({
  type: AUCTION_VEHICLES_SET_MODAL,
  payload: data
});

export const AUCTION_VEHICLES_RESET_MODAL = 'AUCTION_VEHICLES_RESET_MODAL';

export const auctionVehiclesResetModal = () => ({
  type: AUCTION_VEHICLES_SET_MODAL,
  payload: null
});

export const AUCTION_DETAILS_BIDDERS_REQUEST = 'AUCTION_DETAILS_BIDDERS_REQUEST';
export const AUCTION_DETAILS_BIDDERS_SUCCESS = 'AUCTION_DETAILS_BIDDERS_SUCCESS';
export const AUCTION_DETAILS_BIDDERS_FAILURE = 'AUCTION_DETAILS_BIDDERS_FAILURE';

export const getAuctionDetailsBidders = data => ({
  request: { type: AUCTION_DETAILS_BIDDERS_REQUEST, payload: data },
  success: { type: AUCTION_DETAILS_BIDDERS_SUCCESS, payload: data },
  failure: { type: AUCTION_DETAILS_BIDDERS_FAILURE, payload: data }
});
export const AUCTION_DETAILS_BIDDERS_RESET = 'AUCTION_DETAILS_BIDDERS_RESET';

export const resetBidders = () => ({
  type: AUCTION_DETAILS_BIDDERS_RESET,
  payload: null
});
