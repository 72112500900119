import './init';

import React from 'react';
import { render } from 'react-dom';
import Root from './pages/Root';
import configureStore, { history } from './store/configureStore';
import './styles/styles.scss';

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;

render(<Root store={store} history={history} />, document.getElementById('app'));
