import React from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography, ButtonBase } from '@material-ui/core';
import { StyledGrid } from '../components';
import ChangePasswordModal from './ChangePasswordModal';
import { updateProfile } from '../../../../actions/user';
import { FormTextField } from '../../../../components';
import {
  validateEmail,
  required,
  maxLength
} from '../../../../utils/validators';
import { modalsToggle } from '../../../../actions';
import { REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import scrollToError from '../../../../utils/scrollToError';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const PasswordButtonWrapper = styled(ButtonBase)`
  && {
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 13px;
    height: 23px;
    p {
      font-weight: 500;
      color: #005a96;
    }
  }
`;

const AccountInformationForm = ({
  openPasswordChangeModal,
  handleSubmit,
  InputProps
}) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="dealership_name"
          label="Name of Dealership"
          InputProps={InputProps}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          component={FormTextField}
          name="first_name"
          label="First Name"
          InputProps={InputProps}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          component={FormTextField}
          name="last_name"
          label="Last Name"
          InputProps={InputProps}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="email"
          label="Email"
          InputProps={InputProps}
          fullWidth
          validate={[required, validateEmail]}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="password_placeholder"
          label="Password"
          InputProps={{
            ...InputProps,
            disabled: true
          }}
          fullWidth
        />
        <PasswordButtonWrapper disableRipple onClick={openPasswordChangeModal}>
          <Typography>Change Password</Typography>
        </PasswordButtonWrapper>
      </StyledGrid>
      <ChangePasswordModal />
      {/*      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="username"
          label="Username"
          InputProps={{
            ...InputProps,
            disabled: true
          }}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid> */}
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="role_placeholder"
          label="Role"
          InputProps={{
            ...InputProps,
            disabled: true
          }}
          fullWidth
        />
      </StyledGrid>
    </StyledGrid>
  </form>
);

const mapDispatchToProps = dispatch => ({
  openPasswordChangeModal: () => dispatch(modalsToggle('changePasswordModal'))
});

export default compose(
  reduxForm({
    form: 'accountInformationForm',
    onSubmit: (values, dispatch) =>
      new Promise((resolve, reject) =>
        dispatch(updateProfile({ payload: values, resolve, reject }).request)
      ),
    enableReinitialize: true,
    onSubmitFail: scrollToError
  }),
  connect(
    null,
    mapDispatchToProps
  )
)(AccountInformationForm);
