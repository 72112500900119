import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Section } from '../components';
import { selectedVehicleSelector } from '../../../../selectors';
import { COLOR_SUCCESSFUL } from '../../../../constants';
import { FormattedAmount } from '../../../../components';

const StyledAwardPrice = styled(FormattedAmount)`
  color: ${COLOR_SUCCESSFUL}
`;

const AwardPrice = ({ vehicle }) => (
  <Section title='Award Price' xs={6} md={3}>
    <StyledAwardPrice
      amount={vehicle.vehicle_price}
    />
  </Section>
);

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state)
});

export default connect(mapStateToProps)(AwardPrice);
