import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { modalsToggle } from '../../../../actions';
import { ModalWrapper, QualityPicker, Switcher } from '../../../../components';
import FormTextField from '../../../../components/Form/FormTextField';
import { maxLength } from '../../../../utils/validators';

const maxLength500 = maxLength(500);

// eslint-disable-next-line no-nested-ternary
const getStateValue = (state, key, ownProps, defaultValue) => state.vehicles.selectedVehicle.data && state.vehicles.selectedVehicle.data[key]
    ? state.vehicles.selectedVehicle.data[key]
    : ownProps.initialValues && ownProps.initialValues[key] ? ownProps.initialValues[key] : defaultValue;

const StyledModalWrapper = styled(ModalWrapper)`
  & > div:nth-child(2) {
    max-width: 100vw;
  }
`;

const StyledAutocheckReportLink = styled(Typography)`
  color: #005A96;
  margin-top: 13px;
`;

const StyledDealerCommentsField = styled(Field)`
  margin-top: 22px;
`;

class QualityForm extends Component {
  state = {
    autoCheckReport: null
  };

  render() {
    const {vin} = this.props;
    const {autoCheckReport} = this.state;
    return (
      <div id="more-info">
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Windshield</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="windshield"
              min={0}
              max={100}
              step={100 / 2}
              aria-labelledby="label"
              component={QualityPicker}
              props={{
                steps: 2,
                editing: true,
                titles: [" ","Bad", " ", "Good"," "]
              }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Tires</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="tiers"
              min={0}
              max={100}
              step={100 / 3}
              aria-labelledby="label"
              component={QualityPicker}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Paint</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="paint"
              min={0}
              max={100}
              step={100 / 3}
              aria-labelledby="label"
              component={QualityPicker}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Interior</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="interior"
              min={0}
              max={100}
              step={100 / 3}
              aria-labelledby="label"
              component={QualityPicker}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Air Conditioning Blows</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="air_conditioning_blows"
              min={0}
              max={100}
              step={100 / 4}
              aria-labelledby="label"
              component={QualityPicker}
              props={{
                disableStep: true,
                editing: true,
                steps: 4,
                titles: ["N/A", "", "Warm", "Cool", "Cold"]
              }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Engine Oil</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="engine_oil"
              min={0}
              max={100}
              step={100 / 4}
              aria-labelledby="label"
              component={QualityPicker}
              props={{
                editing: true,
                steps: 4,
                titles: ["N/A", "Milky", "Dirty", "Clean", "Very Clean"]
              }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={12}>
            <Typography>Engine Coolant</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="engine_coolant"
              min={0}
              max={100}
              step={100 / 4}
              aria-labelledby="label"
              component={QualityPicker}
              props={{
                editing: true,
                steps: 4,
                titles: ["N/A", "Milky", "Dirty", "Clean", "Very Clean"]
              }}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={6}>
            <Typography>Check Engine Light</Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Field name="engine_light" component={Switcher} type="checkbox" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={6}>
            <Typography>ABS Light</Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Field name="abs_light" component={Switcher} type="checkbox" />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" xs={12}>
          <Grid item sm={3} xs={6}>
            <Typography>Airbag Light</Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Field name="airbag_light" component={Switcher} type="checkbox" />
          </Grid>
        </Grid>
        <StyledAutocheckReportLink
          component='a'
          href="#autocheck"
          onClick={e => {
            e.preventDefault();
            window.open(`/vehicles/${vin}/autocheck-report`, '_blank');
          }}
        >
          View AutoCheck Report
        </StyledAutocheckReportLink>
        <StyledModalWrapper
          dialogProps={{fullScreen: true}}
          showSubmit={false}
          showCancel={false}
          title="AutoCheck Report"
          fullscreen
          modalId="autoCheckModal"
        >
          <div
            id="printable"
            dangerouslySetInnerHTML={{__html: autoCheckReport}}
          />
        </StyledModalWrapper>
        <Grid item container xs={12}>
          <Grid item sm={6} xs={12}>
            <StyledDealerCommentsField
              className="text-field"
              name="dealerComments"
              multiline
              placeholder="Dealer Comments"
              component={FormTextField}
              type="text"
              validate={[maxLength500]}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: (() => ({
    windshield: getStateValue(state, 'windshield', ownProps, 75),
    tiers: getStateValue(state, 'tiers', ownProps, 34),
    paint: getStateValue(state, 'paint', ownProps, 34),
    interior: getStateValue(state, 'interior', ownProps, 34),
    air_conditioning_blows: getStateValue(state, 'air_conditioning_blows', ownProps, 0),
    engine_oil: getStateValue(state, 'engine_oil', ownProps, 0),
    engine_coolant: getStateValue(state, 'engine_coolant', ownProps, 0),
    engine_light: getStateValue(state, 'check_engine_light', ownProps, false),
    abs_light: getStateValue(state, 'abs_light', ownProps, false),
    airbag_light: getStateValue(state, 'airbag_light', ownProps, false),
    dealerComments: getStateValue(state, 'dealer_comments', ownProps, ''),
  }))()
});

const mapDispatchToProps = dispatch => ({
  toggleReport: () => dispatch(modalsToggle('autoCheckModal'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'qualityForm',
    initialValues: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(QualityForm)
);
