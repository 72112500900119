import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { SubSection } from '../../../services/api/vehicle-sections/types/common';
import { useField } from 'react-final-form';
import RecordingStarted from 'sound/recording_started.mp3';
import { useLoadSectionAudioMutation } from '../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../hooks/useGetVehicleId';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../actions';
import { number } from 'prop-types';

type Props = {
  name: string;
  children: React.ReactNode;
  subSectionConfig: SubSection;
};

type SoundRecordingContext = {
  startRecording: () => Promise<void>;
  finishRecording: () => void;
  startRecordingCountdown: () => void;
  stopCountdown: () => void;
  isRecording: boolean;
  recordingTime: number | null;
  shouldRenderCountdown: boolean;
  removeRecording: () => void;
};

const defaultValue: SoundRecordingContext = {
  startRecording: () => Promise.resolve(),
  finishRecording: () => {},
  startRecordingCountdown: () => {},
  stopCountdown: () => {},
  isRecording: false,
  recordingTime: null,
  shouldRenderCountdown: false,
  removeRecording: () => {}
};

const SoundRecordingContext = createContext(defaultValue);

export const SoundRecordingProvider = ({ children, name, subSectionConfig }: Props) => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingTime, setRecordingTime] = useState<number | null>(null);
  const [shouldRenderCountdown, setShouldRenderCountdown] = useState(false);
  const recordingStartedAtRef = useRef<number | null>(null);

  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const { input } = useField(`${name}.audio_url`, { subscription: { value: true } });

  const vehicleId = useGetVehicleId();
  const dispatch = useDispatch();

  const [loadAudio] = useLoadSectionAudioMutation();

  const startRecordingCountdown = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true
        })
        .then(stream => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          setShouldRenderCountdown(true);
        })
        .catch(e => {
          console.log(e);
          dispatch(snackShow({ message: 'Allow browser to record audio to proceed', type: 'error' }));
        });
    } else {
      dispatch(snackShow({ message: 'Audio recording is not supported on your browser', type: 'error' }));
    }
  };

  const startRecording = async () => {
    if (!mediaRecorderRef.current) return;
    setIsRecording(true);
    input.onChange(null);

    try {
      if (audioRef.current) {
        audioRef.current.src = RecordingStarted;
        await audioRef.current.play();
      }
    } catch (error) {
      console.error(error, 'error while playing sound');
    }

    mediaRecorderRef.current.onstart = e => {
      recordingStartedAtRef.current = e.timeStamp;
    };

    mediaRecorderRef.current.start(1000);

    const localChunks: Blob[] = [];
    mediaRecorderRef.current.ondataavailable = (e: BlobEvent) => {
      localChunks.push(e.data);
      setRecordingTime(e.timeStamp - recordingStartedAtRef.current!);
      if (localChunks.length >= 25) {
        finishRecording();
      }
    };

    mediaRecorderRef.current.onstop = () => {
      onAudioStopped(localChunks);
    };
  };

  const finishRecording = () => {
    if (!mediaRecorderRef.current) return;
    mediaRecorderRef.current.stop();
  };

  const onAudioStopped = async (chunks: Blob[]) => {
    if (!mediaRecorderRef.current) return;
    const audioBlob = new Blob(chunks, { type: 'audio/wav' });
    const [sectionName, subSectionName] = name.split('.');
    if (chunks.length > 0) {
      const response = await loadAudio({ vehicleId, audio: audioBlob, sectionName, subSectionName });
      if ('data' in response) {
        input.onChange(response.data.data.audio_url);
      }
    }

    setIsRecording(false);
    setRecordingTime(null);
    recordingStartedAtRef.current = null;
  };

  const removeRecording = () => {
    if (!mediaRecorderRef.current) return;
    mediaRecorderRef.current.onstop = null;
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    setRecordingTime(null);
    recordingStartedAtRef.current = null;
  };

  return (
    <SoundRecordingContext.Provider
      value={{
        startRecording,
        finishRecording,
        startRecordingCountdown,
        isRecording,
        recordingTime,
        shouldRenderCountdown,
        removeRecording,
        stopCountdown: () => setShouldRenderCountdown(false)
      }}
    >
      {children}
    </SoundRecordingContext.Provider>
  );
};

export const useSoundRecordingContext = () => {
  const context = useContext(SoundRecordingContext);
  return context;
};
