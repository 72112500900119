import React, { Component } from 'react';
import { Badge, Button } from '@material-ui/core';
import { FilterDrawer } from '../../components';

class VerticalFilter extends Component {
  state = {
    isDrawerOpen: false
  };

  toggleDrawer = () =>
    this.setState(state => ({
      isDrawerOpen: !state.isDrawerOpen
    }));

  closeDrawer = () =>
    this.setState({
      isDrawerOpen: false
    });

  render() {
    const { filterCount, children } = this.props;
    const { isDrawerOpen } = this.state;
    return (
      <>
        {filterCount ? (
          <Badge badgeContent={filterCount} color='secondary'>
            <Button variant="contained" color="primary" onClick={this.toggleDrawer}>
              Filter
            </Button>
          </Badge>
        ) : (
          <Button variant="contained" color="primary" onClick={this.toggleDrawer}>
            Filter
          </Button>
        )}
        <FilterDrawer open={isDrawerOpen} handleClose={this.closeDrawer}>
          {children}
        </FilterDrawer>
      </>
    );
  }
}

export default VerticalFilter;
