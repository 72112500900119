import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';
import { Typography, Divider, Menu, ClickAwayListener } from '@material-ui/core';
import { BUYER_PROFILE_LINKS, SELLER_PROFILE_LINKS } from '../../../constants/links';
import { restoreInitialUser, getInitialNavigationPage } from '../../../utils/auth';
import dropdownArrow from '../../../img/down-arrow-dark.svg';

const MenuWrapper = styled.div``;

const StyledDivider = styled(Divider)`
  && {
    margin-bottom: 10px;
    background: #dcdcdc;
  }
`;

const StyledName = styled(Typography)`
  && {
    font-size: 16px;
    color: #212121;
    padding: 12px 22px 0;
  }
`;

const StyledRole = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
    padding: 0 22px 12px;
  }
`;

const StyledListButton = styled.button`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #212121;
  position: relative;
  background: none;
  border: 0;
  padding: 12px 22px;
  outline: 0;
  cursor: pointer;
  font-family: 'Roboto';
  width: 100%;
  text-align: left;
  transition: all 250ms;
  text-transform: uppercase;

  &:hover {
    color: #3994de;
  }
`;

const StyledButton = styled.button`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-left: 24px;
  color: #212121;
  position: relative;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  text-transform: uppercase;

  span {
    width: 17px;
  }
  &:before {
    ${({ isActive }) =>
      isActive &&
      `
      content: '';
      width: 100%;
      height: 3px;
      background: #212121;
      position: absolute;
      top: -28px;
    `}
  }
  &:hover:before,
  &.active:before {
    content: '';
    width: 100%;
    height: 3px;
    background: #212121;
    position: absolute;
    top: -28px;
  }

  .dropdown-arrow {
    margin-left: 3px;

    &.open {
      transform: rotate(180deg);
    }
  }

  @media only screen and (min-width: 1300px) {
    margin-left: 42px;
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
  > .menu-burger {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
`;

const StyledLinkMenuItem = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 12px 22px;
  color: #212121;
  position: relative;
  display: block;
  transition: all 250ms;

  &:hover {
    color: #3994de;
  }
`;

class ProfileDropDownList extends Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleClickAway = () => {
    this.setState({
      open: false
    });
  };

  renderProfileLinks = ({ to, text }, index) => (
    <StyledLinkMenuItem key={index} to={to}>
      {text}
    </StyledLinkMenuItem>
  );

  renderProfileHeader = () => {
    const { user } = this.props;
    const userRole = user.role[0].toUpperCase() + user.role.slice(1);
    return (
      <>
        <StyledName>{user.email}</StyledName>
        <StyledRole>{userRole}</StyledRole>
        <StyledDivider />
      </>
    );
  };

  render() {
    const { open, anchorEl } = this.state;
    const { isAdminLoggedIn, user, location } = this.props;
    const isActiveButton = /\/profile\/.*/.test(location.pathname);
    return (
      <div>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <>
            {' '}
            <StyledButton
              aria-owns={open ? 'profile-drop-down-menu' : null}
              aria-haspopup="true"
              isActive={isActiveButton}
              onClick={this.handleClick}
            >
              {user.dealership_name}
              <span className={`dropdown-arrow ${open ? 'open' : ''}`}>
                <img src={dropdownArrow} alt="arrow" />
              </span>
            </StyledButton>
            <Menu
              id="profile-drop-down-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleRequestClose}
              anchorOrigin={{ vertical: 30, horizontal: 'left' }}
              getContentAnchorEl={null}
              classes={{
                paper: 'dropdown'
              }}
            >
              <MenuWrapper>
                {this.renderProfileHeader()}
                {(user.role === 'seller' ? SELLER_PROFILE_LINKS : BUYER_PROFILE_LINKS).map(this.renderProfileLinks)}
                {isAdminLoggedIn ? (
                  <StyledListButton
                    onClick={() => {
                      const navLink = getInitialNavigationPage() ?? '/admin/customers'
                      restoreInitialUser();
                      window.location.href = navLink;
                    }}
                  >
                    Go back to Admin
                  </StyledListButton>
                ) : (
                  <StyledListButton
                    onClick={() => {
                      localStorage.clear();
                      window.location.href = '/';
                    }}
                  >
                    Log Out
                  </StyledListButton>
                )}
              </MenuWrapper>
            </Menu>
          </>
        </ClickAwayListener>{' '}
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    user: state.user.user
  }))(ProfileDropDownList)
);
