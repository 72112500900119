/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import { Header, Filter } from './components';
import { Table } from '../../../components';
import {
  listCurrentSellerInvoices,
  getSingleInvoice,
  modalsToggle
} from '../../../actions';
import { FINANCIALS_INVOICES_PER_PAGE, DATE_FORMAT } from '../../../constants';
import InvoiceModal from '../InvoiceModal';

const StyledTableWrapper = styled.div`
  && {
    tr td:first-child {
      width: 200px;
    }
  }
`;

class Invoices extends Component {
  state = {
    params: {}
  };

  componentDidMount() {
    const { getInvoices, page, perPage } = this.props;
    this.setState({ params: { limit: perPage, offset: page } }, () => {
      const { params } = this.state;
      getInvoices({ params });
    });
  }

  // TODO fix this
  loadMore = () => false;

  render() {
    const { history, page, perPage, invoices, invoicesCount } = this.props;
    const buttons = [
      {
        title: 'View',
        color: 'secondary',
        handleClick: item => {
          window.open(item.file, '_blank');
        }
      }
    ];
    return (
      <div>
        <Header history={history} />
        <div className="pageWrapper">
          <Filter />
          <StyledTableWrapper>
            <Table
              columnsHead={[
                { title: 'Invoice', key: 'date_month_string' },
                { title: 'Date Generated', key: 'date_generated_string' },
                { title: '', key: 'actions', cellClass: 'text-right' }
              ]}
              columnsData={invoices}
              rowsCount={invoicesCount}
              buttons={buttons}
              page={page}
              rowsPerPage={perPage}
              loadMore={this.loadMore}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              loading={false}
            />
          </StyledTableWrapper>
          <InvoiceModal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let invoices = [];
  if (state.financials.invoices.rows) {
    invoices = state.financials.invoices.rows.map(item => ({
      ...item,
      date_month_string: moment.utc(item.date).format('MMMM'),
      date_generated_string: moment(item.created_at).format(DATE_FORMAT)
    }));
  }
  return {
    invoices,
    page: 0,
    perPage:
      parseInt(state.financials.perPage, 10) || FINANCIALS_INVOICES_PER_PAGE,
    loading: state.vehicles.loading
  };
};

const mapDispatchToProps = dispatch => ({
  getInvoices: data => dispatch(listCurrentSellerInvoices(data).request),
  toggleModal: key => dispatch(modalsToggle(key)),
  getInvoice: data => dispatch(getSingleInvoice(data).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices);
