import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { OfferLog } from '../../../../components/Offers/OfferLog/OfferLog';
import { useSearchParams, useUpdateSearchParams } from '../../../../hooks';
import { useSelector } from 'react-redux';

const StyledButton = styled.div`
  width: 80px;
  height: 32px;
  border-radius: 6px;
  background: #3994de;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledDialog = styled(Dialog)`
  .offer-log-paper {
    min-height: calc(100% - 96px);
  }
`;

export const AdminOfferLogButton = () => {
  const { id: auctionId } = useParams<{ id: string }>();

  const user = useSelector((state: any) => state.user.user);
  
  const params = useSearchParams();
  const isModalOpen = params.get('log_opened') === 'true';

  const updateSearchParams = useUpdateSearchParams();

  const closeModal = () => {
    updateSearchParams({ log_opened: 'false' });
  };

  const openModal = () => {
    updateSearchParams({ log_opened: 'true' });
  };

  return (
    <>
      <StyledButton onClick={openModal}>Log</StyledButton>
      <StyledDialog id="offer-log-dialog" onBackdropClick={closeModal} fullWidth maxWidth={'lg'} title="Create New Location" open={isModalOpen} classes={{ paperScrollPaper: "offer-log-paper"}}>
        <OfferLog auctionId={Number(auctionId)} closeModal={closeModal} />
      </StyledDialog>
    </>
  );
};
