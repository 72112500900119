import moment from 'moment';
import {
  SELLER_SALES_BID_STATUSES,
  SELLER_SALES_HISTORY_BID_STATUSES,
  ADMIN_VEHICLE_BID_STATUSES,
  AUCTION_STATUSES,
  VEHICLE_STATUSES,
  ADMIN_VEHICLE_STATUS,
  SELLER_VEHICLE_STATUS,
  BUYER_VEHICLE_STATUS,
  USER_STATUSES
} from '../constants';
import { OfferAuctionStatus, OfferStatus } from '../types/vehicle';
import { getHighestBid } from './vehicle';

export const vehicleSalesHistoryCalculateStatus = vehicle => {
  let status = 'None';
  if (
    vehicle.status === VEHICLE_STATUSES.DRAFT ||
    vehicle.status === VEHICLE_STATUSES.AWARDED ||
    vehicle.status === VEHICLE_STATUSES.PULLED
  ) {
    return vehicle.status;
  }
  const isVehicleActive = vehicle.status === VEHICLE_STATUSES.ACTIVE;
  const isAuctionEnded = vehicle.auction.status === AUCTION_STATUSES.ENDED;
  const isCounteredByAdmin = vehicle.counters?.amount_admin;
  const isCounteredBySeller = vehicle.counters?.amount_seller;
  const isCounteredByAnyone = isCounteredByAdmin || isCounteredBySeller;
  const isBuyerSet = vehicle.buyer_id;

  if (isAuctionEnded && isVehicleActive && !isBuyerSet && !isCounteredByAnyone) {
    status = SELLER_SALES_HISTORY_BID_STATUSES.UNDECIDED;
  } else if (isAuctionEnded && isCounteredByAnyone && !isBuyerSet) {
    status = SELLER_SALES_HISTORY_BID_STATUSES.COUNTERED;
  } else if (isAuctionEnded && isVehicleActive && isBuyerSet) {
    status = SELLER_SALES_HISTORY_BID_STATUSES.PROCESSING;
  }

  return status;
};

export const bidsListCalculateStatus = (vehicle, userId) => {
  let status = 'None';

  if (vehicle.status === VEHICLE_STATUSES.PULLED) {
    return VEHICLE_STATUSES.PULLED;
  }

  const highestBid = vehicle.highest_bid ?? { amount: 0, id: 0 };

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    vehicle.end_time_spacing !== null &&
    moment
      .utc(vehicle.auction.date_end)
      .add(vehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const isVehicleActive = vehicle.status === VEHICLE_STATUSES.ACTIVE;
  const isVehicleAwarded = vehicle.status === VEHICLE_STATUSES.AWARDED;
  const isBuyerAwarded = vehicle.buyer_id === userId;
  const isAuctionActive = vehicle.auction.status === AUCTION_STATUSES.ACTIVE;
  const isAuctionEnded = vehicle.auction.status === AUCTION_STATUSES.ENDED;
  const isBuyerLastBidder = userId === highestBid.user_id;

  if (isVehicleAwarded && !isBuyerAwarded) {
    status = 'declined';
  } else if (isVehicleAwarded && isBuyerAwarded) {
    status = 'awarded';
  } else if (isVehicleTimerEnded && isVehicleActive && !isAuctionEnded) {
    status = 'undecided';
  } else if (isVehicleActive && isAuctionActive) {
    status = isBuyerLastBidder ? 'high_bid' : 'out_bid';
  }
  return status;
};

export const auctionOfferStatuses = (vehicle, offerAuction, user) => {
  const currentTime = moment.utc();
  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(currentTime);
  const isBuyerSet = vehicle.buyer_id;

  const privateOffers = offerAuction?.privateOffers?.length ?? 0;
  const lastPrivateOffer = offerAuction?.privateOffers?.[0];

  if (!user) return vehicle.status;

  if (user.role === 'admin' && vehicle.role_based_statuses?.admin) {
    if (vehicle.status === 'awarded') return ADMIN_VEHICLE_STATUS.AWARDED;
    return vehicle.role_based_statuses.admin;
  }

  if (user.role === 'seller' && vehicle.role_based_statuses?.seller) {
    return vehicle.role_based_statuses.seller;
  }

  const roleMap = {
    admin: {
      [VEHICLE_STATUSES.AWARDED]: () => ADMIN_VEHICLE_STATUS.AWARDED,
      [VEHICLE_STATUSES.PULLED]: () => ADMIN_VEHICLE_STATUS.PULLED,
      [VEHICLE_STATUSES.DRAFT]: () => ADMIN_VEHICLE_STATUS.DRAFT,
      [VEHICLE_STATUSES.DEACTIVATED]: () => ADMIN_VEHICLE_STATUS.DEACTIVATED,
      [VEHICLE_STATUSES.ACTIVE]: () => {
        if (!isVehicleTimerEnded) {
          return ADMIN_VEHICLE_STATUS.IN_PROGRESS;
        }

        if (vehicle.buyer_id) {
          return ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED;
        }

        if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) {
          if (privateOffers === 0) return ADMIN_VEHICLE_STATUS.UNDECIDED;

          const sellerAcceptedOffer = offerAuction.offerBids?.find(el => el.status === 'seller_accepted');
          if (sellerAcceptedOffer) {
            return ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED;
          }

          const lastPublicOffer = offerAuction.privateOffers?.find(el => el.type === 'public');
          if (!lastPublicOffer) return ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED;

          if (lastPublicOffer.is_admin) {
            return ADMIN_VEHICLE_STATUS.PUBLIC_ADMIN_COUNTER_SENT;
          }

          return ADMIN_VEHICLE_STATUS.SELLER_COUNTERED;
        }

        if (privateOffers === 0) return ADMIN_VEHICLE_STATUS.UNDECIDED;

        if (lastPrivateOffer && lastPrivateOffer.status === OfferStatus.REJECTED) {
          return ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED;
        }

        if (lastPrivateOffer && lastPrivateOffer.status === OfferStatus.SENT && lastPrivateOffer.is_admin) {
          return ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT;
        }
        if (
          lastPrivateOffer &&
          lastPrivateOffer.status === OfferStatus.SENT &&
          lastPrivateOffer.sender.role === 'buyer'
        ) {
          return ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT;
        }
        return ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT;
      }
    },
    seller: {
      [VEHICLE_STATUSES.AWARDED]: () => SELLER_VEHICLE_STATUS.AWARDED,
      [VEHICLE_STATUSES.PULLED]: () => SELLER_VEHICLE_STATUS.PULLED,
      [VEHICLE_STATUSES.DRAFT]: () => SELLER_VEHICLE_STATUS.DRAFT,
      [VEHICLE_STATUSES.DEACTIVATED]: () => SELLER_VEHICLE_STATUS.DEACTIVATED,
      [VEHICLE_STATUSES.ACTIVE]: () => {
        if (!isVehicleTimerEnded) {
          return SELLER_VEHICLE_STATUS.IN_PROGRESS;
        }

        if (isBuyerSet) {
          return SELLER_VEHICLE_STATUS.PENDING_AUTOAXESS_REVIEW;
        }

        if (privateOffers === 0) return SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW;

        if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) {
          const sellerAcceptedOffer = offerAuction.offerBids?.find(el => el.status === 'seller_accepted');
          if (sellerAcceptedOffer) {
            return SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL;
          }

          const lastPublicOffer = offerAuction.privateOffers?.find(el => el.type === 'public');
          if (!lastPublicOffer) return SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW;

          if (lastPublicOffer.is_admin) {
            return SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS;
          }

          return SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL;
        }

        const isBuyerOffer =
          lastPrivateOffer && lastPrivateOffer.status === OfferStatus.SENT && lastPrivateOffer.sender.role === 'buyer';

        return isBuyerOffer ? SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT : SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS;
      }
    },
    buyer: {
      [VEHICLE_STATUSES.AWARDED]: () => {
        const isBuyerAwarded = vehicle.buyer_id === user.id;
        if (!isBuyerAwarded) {
          return BUYER_VEHICLE_STATUS.DECLINED;
        }

        const isCharged = vehicle.charge_completed;
        if (!isCharged) {
          return user.status === USER_STATUSES.ACTIVE
            ? BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE
            : BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED;
        }

        return BUYER_VEHICLE_STATUS.AWARDED;
      },
      [VEHICLE_STATUSES.PULLED]: () => BUYER_VEHICLE_STATUS.PULLED,
      [VEHICLE_STATUSES.DRAFT]: () => BUYER_VEHICLE_STATUS.DRAFT,
      [VEHICLE_STATUSES.DEACTIVATED]: () => BUYER_VEHICLE_STATUS.DEACTIVATED,
      [VEHICLE_STATUSES.ACTIVE]: () => {
        const topBidderId = offerAuction ? offerAuction.top_bidder_id : getHighestBid(vehicle)?.bidder?.id;
        const isTopBuyer = user.id === topBidderId;

        if (!isVehicleTimerEnded) {
          return isTopBuyer ? BUYER_VEHICLE_STATUS.HIGH_BID : BUYER_VEHICLE_STATUS.OUT_BID;
        }

        if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) {
          return isTopBuyer
            ? BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER
            : BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS;
        }

        if (privateOffers === 0) {
          return isTopBuyer ? BUYER_VEHICLE_STATUS.UNDECIDED : BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS;
        }

        if (!isTopBuyer) {
          return BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS;
        }

        if (lastPrivateOffer.status === OfferStatus.REJECTED) {
          return BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER;
        }

        const isBuyerOffer =
          lastPrivateOffer && lastPrivateOffer.status === OfferStatus.SENT && lastPrivateOffer.sender.role === 'buyer';

        return isBuyerOffer ? BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT : BUYER_VEHICLE_STATUS.COUNTERED;
      }
    }
  };

  const resolver = roleMap[user.role][vehicle.status];
  if (!resolver) return null;

  return resolver();
};
