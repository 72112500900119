import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  AUTSlider,
  CarCard,
  PageWrapper,
} from "../../../components";

const VehiclesSectionHolder = styled.section`
  padding: 52px 0 30px;
  
  @media only screen and (min-width: 1026px) {
    padding: 112px 0 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  @media only screen and (min-width: 1026px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const SectionDescription = styled.p`
  margin: 0 auto 24px;
  max-width: 414px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #54607D;

  @media only screen and (min-width: 1026px) {
    margin: 0 auto 60px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  margin: 96px auto 0;
  padding: 13px 16px 12px;
  background: #FFFFFF;
  border: 2px solid #424141;
  color: #424141;
  font-weight: 500;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;

  @media only screen and (min-width: 1026px) {
    width: 335px;
    margin: 70px auto 0;
    
    &:hover {
      border: 2px solid #3994DE;
      color: #3994DE;
    }
  }
`;

const VehiclesSection = ({vehicles, isActiveAuction}) => (
  <VehiclesSectionHolder>
    <PageWrapper>
      <div className="vehicles">
        <SectionTitle>{isActiveAuction ? "Vehicles in Auction" : "Vehicles in Last Auction"}</SectionTitle>
        <SectionDescription>The best selection of vehicles every week. These are some of the latest vehicles listed on the current auction.</SectionDescription>
        <AUTSlider
          settings={{
            responsive: [
              {
                breakpoint: 1126,
                settings: {
                  dots: true,
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 800,
                settings: {
                  dots: true,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  dots: true,
                  slidesToShow: 1,
                }
              }
            ],
            arrows: false,
            dots: false,
            draggable: false,
            slidesToShow: vehicles.length,
            slidesToScroll: 1,
          }}
        >
          {vehicles.map(item => (
            <CarCard listView={false} vehicle={item} />
          ))}
        </AUTSlider>
        {
          isActiveAuction && <StyledLink to="/live-auction">VIEW ALL VEHICLES</StyledLink>
        }
      </div>
    </PageWrapper>
  </VehiclesSectionHolder>
);

export default VehiclesSection;
