import styled from 'styled-components';
import { SectionTitle, SectionWrapper } from './index';
import { BioMaxChars } from 'pages/Admin/AccountsList/components/BioMaxChars';
import { EmptyAvatarPlaceholder } from 'components/Icons';
import { Typography } from '@material-ui/core';

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;

  svg, img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

const BioWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ContentRow = styled.div`
  display: flex;
  gap: 12px;
`;

const ListerName = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const ListerBio = styled.span`
  color: #616161;
  font-size: 20px;
  font-weight: 400;
`;

export const ListerAvatarSection = ({ lister }) => {
  if (!lister.avatar && !lister.name_for_bio && !lister.bio) return null;

  return (
    <SectionWrapper>
      <ContentWrapper>
        <HeaderRow>
          <SectionTitle style={{ margin: 0}}>Lister Information</SectionTitle>
          <BioMaxChars />
        </HeaderRow>
        <ContentRow>
          <ImageWrapper>
            {lister.avatar ? <img src={lister.avatar} alt="avatar" /> : <EmptyAvatarPlaceholder />}
          </ImageWrapper>
          <BioWrapper>
            <ListerName>{lister.name_for_bio ?? 'N/A'}</ListerName>
            <Typography>{lister.bio ?? 'N/A'}</Typography>
          </BioWrapper>
        </ContentRow>
      </ContentWrapper>
    </SectionWrapper>
  );
};
