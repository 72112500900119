import styled from 'styled-components';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useGetSectionConfigById, useGetSubSectionConfig } from '../../hooks/useGetSubSectionConfig';
import { GradesComponent } from '../../components/Grades';
import { Checkboxes } from '../../components/CheckboxField';
import { useField } from 'react-final-form';

type Props = {
  sectionId: keyof SectionConfig;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AutoCheckReportButton = styled.div`
  background: rgba(57, 148, 222, 1);
  border-radius: 4px;
  font-size: 10px;
  font-weight: 400;
  width: 122px;
  margin-top: 12px;
  line-height: 11.72px;
  padding: 3px 8px 4px;
  color: #fff;
`;

export const SectionWithCheckboxes = ({ sectionId }: Props) => {
  const config = useGetSectionConfigById(sectionId);

  if (!config) return null;

  return (
    <Wrapper>
      {config.possibleGrades && <GradesComponent grades={config.possibleGrades} name={`${sectionId}.grade`} />}
      {sectionId === 'title_issues' && <AutoCheckReport />}
      <Checkboxes sectionId={sectionId} />
    </Wrapper>
  );
};

const AutoCheckReport = () => {
  const {
    input: { value }
  } = useField('vin');

  return (
    <AutoCheckReportButton
      onClick={e => {
        e.preventDefault();
        window.open(`/vehicles/${value}/autocheck-report`, '_blank');
      }}
    >
      View AutoCheck Report
    </AutoCheckReportButton>
  );
};
