import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import './index.scss';
import { CountdownNumber } from './CountdownNumber';
import { useEffect } from 'react';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 240px;
  height: 240px;
`;

const StyledDialog = styled(Dialog)`
  .countdown-paper {
    background: transparent;
    box-shadow: none;
  }

  .countdown-root {
    background: rgba(0, 0, 0, 0.2);
  }
`;

type Props = {
  shouldRender: boolean;
  stopRendering: () => void;
  onClose: () => void;
};

export const CountdownAnimation = ({ shouldRender, stopRendering, onClose }: Props) => {
  useEffect(() => {
    if (!shouldRender) return;
    const timeout = setTimeout(() => {
      console.log('finished animation');
      stopRendering();
    }, 1000 * 5);

    return () => clearTimeout(timeout);
  }, [shouldRender]);

  return (
    <StyledDialog
      open={shouldRender}
      disableBackdropClick
      disableEscapeKeyDown
      BackdropProps={{ classes: { root: 'countdown-root' } }}
      classes={{ paperScrollPaper: 'countdown-paper', root: 'countdown-root' }}
      onExit={() => {
        console.log('on close triggered');
        onClose();
      }}
    >
      <Wrapper>
        <CountdownNumber />
      </Wrapper>
    </StyledDialog>
  );
};
