import { useFieldArray } from 'react-final-form-arrays';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import styled from 'styled-components';
import { CheckboxField } from './CheckboxField';

type Props = {
  sectionId: keyof SectionConfig;
};

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MobileCheckboxGrid = ({ sectionId }: Props) => {
  const { fields } = useFieldArray(`${sectionId}.checkboxes`);

  return (
    <Container>
      {fields.map((name, i) => {
        return <CheckboxField i={i} key={name} name={name} sectionId={sectionId} isLast={i === fields.length! - 1} />;
      })}
    </Container>
  );
};
