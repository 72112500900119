import React, { Component } from 'react';
import Slider from 'react-slick';
import './index.scss';

const PrevArrow = ({ className, style, onClick }) => (
  <i
    className={`material-icons ${className}`}
    onClick={onClick}
    onKeyDown={onClick}
    style={{
      ...style,
      zIndex: 1,
      color: 'white',
      fontSize: '48px',
      textShadow: '1px 1px 10px #000'
    }}
    role="button"
    tabIndex="0"
  >
    keyboard_arrow_left
  </i>
);

const NextArrow = ({ className, style, onClick }) => (
  <i
    className={`material-icons ${className}`}
    onClick={onClick}
    onKeyDown={onClick}
    style={{
      ...style,
      zIndex: 1,
      color: 'white',
      fontSize: '48px',
      textShadow: '1px 1px 10px #000'
    }}
    role="button"
    tabIndex="0"
  >
    keyboard_arrow_right
  </i>
);

class AUTSlider extends Component {
  render() {
    const { settings, sliderRef, className, children, beforeChange } = this.props;
    const sliderSettings = {
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange,
      ...settings,
    };

    return (
      <Slider className={className} {...sliderSettings} ref={sliderRef}>
        {children}
      </Slider>
    );
  }
}

export default AUTSlider;
