import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle, adminApproveBid } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import ChargeBuyerModal from 'components/ChargeBuyerModal';

const modalTitle = 'confirmApproveModal';
const chargeModalTitle = 'acceptHighBidCharge';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 0 32px 8px 0;
  font-size: 14px;
`;

const StyledSpanTitle = styled.span`
  font-weight: 500;
  padding-left: 0;
  display: block;
  font-size: 18px;
  margin-bottom: 4px;
`;

const StyledGraySpan = styled.span`
  color: #616161;
`;

class AcceptedHighestBidBlock extends Component {
  handleClickApprove = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalTitle);
  };

  handleApprove = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalTitle);
    toggle(chargeModalTitle);
  };

  render() {
    const { currentVehicle, loading, adminApproveHighestBid } = this.props;
    const highestBid = currentVehicle.bids.reduce(
      (acc, bid) => {
        if (bid.amount >= acc.amount && bid.id > acc.id) {
          return bid;
        }
        return acc;
      },
      { amount: 0, id: 0 }
    );
    if ((highestBid && !highestBid.bidder)) {
      return null;
    }
    return (
      <Fragment>
        <Grid container item xs={12}>
          <StyledGrid item xs={12} sm={8}>
            <StyledSpanTitle>Seller Accepted</StyledSpanTitle>
            <span>{`${currentVehicle.user.dealership_name}`}</span>
            <StyledGraySpan> has accepted the highest bid of </StyledGraySpan>
            <NumberFormat
              value={highestBid.amount}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
            <StyledGraySpan> from </StyledGraySpan>
            <span>{` ${highestBid.bidder.dealership_name}`}</span>
          </StyledGrid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={this.handleClickApprove}
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading}
            >
              Approve
            </Button>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
        </Grid>
        <ConfirmModal
          modalId={modalTitle}
          title={
            <>
              You are about to approve the high bid of{' '}
              <NumberFormat
                displayType="text"
                prefix="$"
                value={highestBid.amount}
                thousandSeparator
              />{' '}
              from {highestBid.bidder.dealership_name}.
            </>
          }
          handleSubmit={() => {
            this.handleApprove();
          }}
        />
        {(currentVehicle.bids.length || currentVehicle.vehicle_price) && (
          <ChargeBuyerModal
            modalId={chargeModalTitle}
            vehicle={currentVehicle}
            chargeAction={adminApproveHighestBid}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  currentVehicle: state.vehicles.selectedVehicle.data
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  adminApproveHighestBid: data => dispatch(adminApproveBid(data).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptedHighestBidBlock);
