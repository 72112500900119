export const auctionDetailsVehiclesSelector = state => state.auctions.auctionVehicles.rows;

export const userStateSelector = state => state.user.user;

export const userRoleSelector = state => state.user.user.role;

export const selectedVehicleSelector = state => state.vehicles.selectedVehicle.data;

export const selectedVehicleProxyBidSelector = state => state.vehicles.selectedVehicle.data.proxy_bid;

export const selectedVehicleBuyerDocsSelector = state => state.vehicles.selectedVehicle.buyerDocs;

export const selectedVehicleBuyerSelector = state => state.vehicles.selectedVehicle.buyer;

export const currentAuctionSelector = state => state.auctions.current;
