import apiSlice from '..';

export type Contact = {
  id: number;
  name: string;
  email: string;
  job_title: string;
  phone: string;
};

type LastSoldVehicle = {
  id: string;
  vehicle_price: number;
  year: number;
  make: string;
  model: string;
  date_status: string;
  user: {
    id: number;
    dealership_name: string;
  };
  paymentMethodLabel: string;
};

export type BuyerInfo = {
  id: string;
  role: 'buyer';
  name: string;
  primary_contact: Contact;
  secondary_contact?: Contact;
  dealership_name: string;
  purchase_methods: string[];
  dealer_license_number: string;
  seller_permit_number?: string;
  address: {
    city: string;
    state: string;
    zip: string;
    street: string;
  };
  contacts: Contact[];
  lastSoldVehicles: LastSoldVehicle[];
  notes: string;
};

export type SellerInfo = {
  id: string;
  role: 'seller';
  name: string;
  dealership_name: string;
  primary_contact: Contact;
  secondary_contact?: Contact;
  fee: number;
  pickup_instruction: string;
  purchase_options: string[];
  dealer_license_number: string;
  address: {
    city: string;
    state: string;
    zip: string;
    street: string;
  };
  contacts: Contact[];
  lastSoldVehicles: LastSoldVehicle[];
  notes: string;
};

export const customersApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHoverCustomerInfo: builder.query<BuyerInfo | SellerInfo, number>({
      query: customerId => ({
        url: `/admin/customers/${customerId}/hover-info`,
        method: 'GET'
      }),
      transformResponse: (response: any) => {
        return response.data;
      }
    })
  }),
  overrideExisting: true
});

export const { useGetHoverCustomerInfoQuery } = customersApi;
export default customersApi;
