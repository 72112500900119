import { SubSection } from '../../../services/api/vehicle-sections/types/common';
import { useIS4WDVehicle } from './useIs4WDVehicle';
import { useIsManualTransmissionVehicle } from './useIsManualTransmissionVehicle';

export const useIsSubsectionVisible = (config: SubSection) => {
  const is4WDVehicle = useIS4WDVehicle(config.visibilityCondition !== '4wd');
  const isManualTransmissionVehicle = useIsManualTransmissionVehicle(
    config.visibilityCondition !== 'manual_transmission'
  );

  if (config.visibilityCondition === '4wd') {
    return is4WDVehicle;
  }

  if (config.visibilityCondition === 'manual_transmission') {
    return isManualTransmissionVehicle;
  }

  return true;
};
