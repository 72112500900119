import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import { EntityWithCount, SocketMessage } from '../types';
import {
  WS_MY_BID_UPDATE,
  WS_NEW_BID,
  WS_NEW_PROXY,
  WS_NEW_VEHICLE,
  WS_VEHICLE_DETAILS_UPDATE,
  WS_VEHICLE_NOTES_UPDATE,
  WS_VEHICLE_TIME_END
} from '../../../constants/actionTypes';
import getSocket from '../../socket';
import { VehicleSocketEventHandlerManager } from '../socketHelpers';
import moment from 'moment';
import apiSlice from '..';

const isLastPage = (offset: number, perPage: number, total: number) => {
  return offset + perPage >= total;
};

const isVehicleExpired = (vehicle: Vehicle) => {
  if (!vehicle.date_end) return false;
  return moment(vehicle.date_end).isBefore(moment());
};

export type HomeFilters = {
  makes: Array<{ make: string; count: number }>;
  models: Array<{ make: string; model: string; count: number }>;
  sellers: Array<{ id: number; name: string; count: number }>;
  regions: Array<{ id: number; name: string; count: number }>;
};

export const liveAuctionVehiclesAdapter = createEntityAdapter<Vehicle>({
  sortComparer: (a, b) => moment(a.date_end).diff(b.date_end)
});

export const liveAuctionApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLiveAuctionVehicles: builder.query<EntityWithCount<Vehicle>, Record<string, any>>({
      query: ({ limit, offset, region, ...filters }) => ({
        url: `/vehicles`,
        method: 'GET',
        params: {
          limit,
          offset,
          ...filters
        }
      }),
      transformResponse: (response: any) => {
        return {
          rows: liveAuctionVehiclesAdapter.addMany(liveAuctionVehiclesAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState }) {
        const store = getState() as any;
        const userId = store.user?.user?.id;
        const socket = getSocket();

        const listener = ({ payload, type }: SocketMessage) => {
          const socketManager = new VehicleSocketEventHandlerManager(updateCachedData, liveAuctionVehiclesAdapter, {
            payload,
            type
          });
          socketManager.registerHandlers([
            WS_VEHICLE_NOTES_UPDATE,
            WS_NEW_PROXY,
            WS_VEHICLE_DETAILS_UPDATE,
            WS_NEW_BID,
            WS_VEHICLE_TIME_END,
            WS_MY_BID_UPDATE
          ]);

          if (type === WS_NEW_VEHICLE) {
            updateCachedData(draft => {
              if (isLastPage(arg.offset, arg.limit, draft.count) && !isVehicleExpired(payload)) {
                liveAuctionVehiclesAdapter.upsertOne(draft.rows, payload);
                draft.count += 1;
                socket.emit('subscribe', `vehicles:${payload.id}`);
                if (userId) {
                  socket.emit('subscribe', `vehicles-notes:${userId}:${payload.id}`);
                }
              }
            });
          }
        };
        try {
          const data = await cacheDataLoaded;
          if (userId) {
            socket.emit('subscribe', `user:${userId}`);
          }
          for (const vehicleId of data.data.rows.ids) {
            socket.emit('subscribe', `vehicles:${vehicleId}`);
            if (userId) {
              socket.emit('subscribe', `vehicles-notes:${userId}:${vehicleId}`);
            }
          }
          socket.emit('subscribe', `live-auction-vehicles`);
          socket.on('message', listener);
        } catch {}
        await cacheEntryRemoved;
        socket.off('message', listener);
      },
      async onQueryStarted(arg, { getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          const state = getState() as any;
          const scrollPosition = state.scrollPositions?.positions?.['live-auction'];
          if (scrollPosition) {
            window.scrollTo(0, scrollPosition);
          }
        } catch (error) {}
      }
    }),
    getActiveAuctions: builder.query<any, void>({
      query: () => ({
        url: `/auctions/active`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    }),
    getVehicleSuggestions: builder.query<
      {
        totalBeforeSuggestions: number;
        totalAfterSuggestionsLength: number;
        pivot: number;
        suggestions: Vehicle[];
      },
      number
    >({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/time-suggestions`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    }),
    getHomeFilters: builder.query<HomeFilters, void>({
      query: () => ({
        url: `home-filters?location_id=1`,
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data
    })
  }),
  overrideExisting: true
});

export const {
  useGetLiveAuctionVehiclesQuery,
  useGetActiveAuctionsQuery,
  useGetVehicleSuggestionsQuery,
  useGetHomeFiltersQuery
} = liveAuctionApi;
export default liveAuctionApi;
