// eslint-disable-next-line
export const VALIDATION_TYPES = {
  REQUIRED: 'required',
  LENGTH: 'length',
  CUSTOM: 'custom',
  SUBMITTED: 'submitted'
};

export const vehicleSubmitRequiredFields = [
  { field: 'seller_id', type: VALIDATION_TYPES.REQUIRED },
  { field: 'vin', type: VALIDATION_TYPES.LENGTH, min: 1, max: 32, message: 'Please provide valid VIN' },
  { field: 'year', type: VALIDATION_TYPES.REQUIRED },
  { field: 'make', type: VALIDATION_TYPES.REQUIRED },
  { field: 'model', type: VALIDATION_TYPES.REQUIRED },
  { field: 'engine', type: VALIDATION_TYPES.REQUIRED },
  { field: 'transmission', type: VALIDATION_TYPES.REQUIRED },
  { field: 'drive_train', type: VALIDATION_TYPES.REQUIRED },
  { field: 'trim', type: VALIDATION_TYPES.REQUIRED },
  { field: 'ext_color', type: VALIDATION_TYPES.REQUIRED },
  { field: 'mileage', type: VALIDATION_TYPES.REQUIRED },
  { field: 'bluebook_value', type: VALIDATION_TYPES.REQUIRED },
  { field: 'bluebook_mileage', type: VALIDATION_TYPES.REQUIRED },
  { field: 'options', type: VALIDATION_TYPES.CUSTOM },
  { field: 'windshield', type: VALIDATION_TYPES.REQUIRED },
  { field: 'tiers', type: VALIDATION_TYPES.REQUIRED },
  { field: 'paint', type: VALIDATION_TYPES.REQUIRED },
  { field: 'air_conditioning_blows', type: VALIDATION_TYPES.REQUIRED },
  { field: 'engine_oil', type: VALIDATION_TYPES.REQUIRED },
  { field: 'engine_coolant', type: VALIDATION_TYPES.REQUIRED },
  { field: 'interior', type: VALIDATION_TYPES.REQUIRED },
  { field: 'check_engine_light', type: VALIDATION_TYPES.REQUIRED },
  { field: 'abs_light', type: VALIDATION_TYPES.REQUIRED },
  { field: 'airbag_light', type: VALIDATION_TYPES.REQUIRED },
  { field: 'images', type: VALIDATION_TYPES.CUSTOM, message: 'Load at least one photo' },
  { field: 'is_guaranteed', type: VALIDATION_TYPES.REQUIRED },
  { field: 'status', type: VALIDATION_TYPES.REQUIRED },
];

export const vehicleDraftRequiredFields = [
  { field: 'seller_id', type: VALIDATION_TYPES.REQUIRED },
  { field: 'vin', type: VALIDATION_TYPES.LENGTH, min: 1, max: 32, message: 'Please provide valid VIN' },
  { field: 'year', type: VALIDATION_TYPES.REQUIRED },
  { field: 'make', type: VALIDATION_TYPES.REQUIRED },
  { field: 'model', type: VALIDATION_TYPES.REQUIRED },
  { field: 'options', type: VALIDATION_TYPES.CUSTOM },
  { field: 'images', type: VALIDATION_TYPES.SUBMITTED, message: 'Wait till image uploaded' },
];
