import { useState } from 'react';
import styled from 'styled-components';
import { formatCurrency, normalizeFormattedField } from 'utils';
import { Button, Icon, Popover } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import { Value } from 'sass';
import { FormFormattedField } from '../Form';

type Props = {
  type: 'bid' | 'offer';
  initialAmount: number;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21px;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  color: rgba(33, 33, 33, 1);
`;

const FromAmount = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(135, 135, 135, 1);
`;

const HowItWorks = styled.div`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: rgba(135, 135, 135, 1);
`;

const StyledPopoverBody = styled.div`
  padding: 5px 10px;
  max-width: 100%;
  width: 290px;
  font-size: 14px;
`;

const PlusMinusButton = styled.button<{ disabled?: boolean; isMobile?: boolean }>`
  width: ${props => (props.isMobile ? '20px' : '32px')};
  height: ${props => (props.isMobile ? '20px' : '32px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100%;
  background: ${props => (props.disabled ? 'rgba(217, 217, 217, 1)' : '#000')};

  & > span {
    font-size: ${props => (props.isMobile ? '16px' : '24px')};
    color: #fff;
  }
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  & * input {
    padding: 0;
    font-size: 12px;
    line-height: 14px;
  }
`;

const BiddingWrapper = styled.div`
  border: 1px solid rgba(204, 204, 204, 0.8);
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;

const SubmitButton = styled(Button)<{ isMobile?: boolean }>`
  border-radius: 20px;
  width: 100%;
  height: ${props => (props.isMobile ? '38px' : '50px')};
`;

const isFloored = (value: number) => {
  const flooredNumber = Math.floor(value / 100) * 100;
  return flooredNumber !== value;
};

const validate = (value: number) => {
  if (value == null) return;

  if (isFloored(Number(value))) {
    return 'Use bidding increments of min bid amount';
  }
};

const TextMap = {
  bid: {
    title: 'Increase Max Bid',
    howItWorks: 'How Max Bid Works'
  },
  offer: {
    title: 'Increase Max Offer',
    howItWorks: 'How Max Offer Works'
  }
};

export const IncreaseAmountMobile = ({ initialAmount, type }: Props) => {
  const form = useForm();

  return (
    <Field name="amount" parse={normalizeFormattedField} validate={validate}>
      {props => {
        const handleIncrement = () => {
          if (!props.input.value) {
            props.input.onChange(initialAmount + 100);
          } else {
            props.input.onChange(Number(props.input.value) + 100);
          }
        };

        const handleDecrement = () => props.input.onChange(Number(props.input.value) - 100);

        return (
          <Wrapper>
            <BiddingWrapper>
              <StyledFormFormattedField
                placeholder={`${formatCurrency(initialAmount)} min`}
                input={props.input}
                meta={{ ...props.meta, error: '' }}
                prefix="$"
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                thousandSeparator
              />
              <PlusMinusButton
                isMobile
                type="button"
                onClick={handleDecrement}
                disabled={props.input.value <= 100 || props.input.value <= initialAmount}
              >
                <Icon>remove</Icon>
              </PlusMinusButton>
              <PlusMinusButton isMobile type="button" onClick={handleIncrement}>
                <Icon>add</Icon>
              </PlusMinusButton>
            </BiddingWrapper>
            <SubmitButton
              isMobile
              onClick={form.submit}
              disabled={!props.input.value || !!props.meta.error}
              type="submit"
              color="primary"
              variant="contained"
            >
              Submit
            </SubmitButton>
          </Wrapper>
        );
      }}
    </Field>
  );
};
