import styled from 'styled-components';

export const Wrapper = styled.div<{ isMobileExpanded?: boolean; withBottomMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  width: 100%;
  background: #fff;
  border-bottom-left-radius: ${props => (props.isMobileExpanded ? '6px' : 0)};
  border-bottom-right-radius: ${props => (props.isMobileExpanded ? '6px' : 0)};
  box-shadow: 0px 4px 4px 0px #00000040;
  margin-bottom: ${props => (props.withBottomMargin ? '8px' : 0)};
  position: absolute;
  z-index: 40;
  top: 0;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  min-height: 60px;

  @media (min-width: 1024px) {
    max-width: 1296px;
    width: 100%;
    padding: 0 12px;
    margin: 0 auto;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: red;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: -2px;
  background: rgba(176, 176, 176, 0.8);
`;
