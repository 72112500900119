export const SystemAlertIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#F2F8FF" />
    <g clip-path="url(#clip0_8296_35001)">
      <path
        d="M16.0006 8.96094C15.2944 8.96094 14.7206 9.53469 14.7206 10.2409C14.7206 10.8359 15.1306 11.3322 15.6806 11.4759V12.4809H12.8106C11.3931 12.4809 10.2406 13.6334 10.2406 15.0509V16.3209H8.64062V20.1609H10.2406V23.0409H21.7606V20.1609H23.3606V16.3209H21.7606V15.0509C21.7606 13.6334 20.6081 12.4809 19.1906 12.4809H16.3206V11.4759C16.8719 11.3322 17.2806 10.8359 17.2806 10.2409C17.2806 9.53469 16.7069 8.96094 16.0006 8.96094ZM16.0006 9.60094C16.3531 9.60094 16.6406 9.88844 16.6406 10.2409C16.6406 10.5934 16.3531 10.8809 16.0006 10.8809C15.6481 10.8809 15.3606 10.5934 15.3606 10.2409C15.3606 9.88844 15.6481 9.60094 16.0006 9.60094ZM12.8106 13.1209H19.1906C20.2544 13.1209 21.1206 13.9872 21.1206 15.0509V22.4009H19.2006V19.8409H12.8006V22.4009H10.8806V15.0509C10.8806 13.9872 11.7469 13.1209 12.8106 13.1209ZM13.9206 15.0409C13.1269 15.0409 12.4806 15.6872 12.4806 16.4809C12.4806 17.2747 13.1269 17.9209 13.9206 17.9209C14.7144 17.9209 15.3606 17.2747 15.3606 16.4809C15.3606 15.6872 14.7144 15.0409 13.9206 15.0409ZM18.0806 15.0409C17.2869 15.0409 16.6406 15.6872 16.6406 16.4809C16.6406 17.2747 17.2869 17.9209 18.0806 17.9209C18.8744 17.9209 19.5206 17.2747 19.5206 16.4809C19.5206 15.6872 18.8744 15.0409 18.0806 15.0409ZM13.9206 15.6809C14.3619 15.6809 14.7206 16.0397 14.7206 16.4809C14.7206 16.9222 14.3619 17.2809 13.9206 17.2809C13.4794 17.2809 13.1206 16.9222 13.1206 16.4809C13.1206 16.0397 13.4794 15.6809 13.9206 15.6809ZM18.0806 15.6809C18.5219 15.6809 18.8806 16.0397 18.8806 16.4809C18.8806 16.9222 18.5219 17.2809 18.0806 17.2809C17.6394 17.2809 17.2806 16.9222 17.2806 16.4809C17.2806 16.0397 17.6394 15.6809 18.0806 15.6809ZM9.28062 16.9609H10.2406V19.5209H9.28062V16.9609ZM21.7606 16.9609H22.7206V19.5209H21.7606V16.9609ZM13.4406 20.4809H14.7206V22.4009H13.4406V20.4809ZM15.3606 20.4809H16.6406V22.4009H15.3606V20.4809ZM17.2806 20.4809H18.5606V22.4009H17.2806V20.4809Z"
        fill="#0070F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_8296_35001">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const AutoaxessMessageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle cx="16" cy="16" r="16" fill="#F2F8FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4991 11.4579C14.4991 11.4579 18.2737 8.54366 21.1691 7.99621C24.0644 7.44876 22.791 10.557 21.98 11.8391C21.1691 13.1212 16.1413 20.121 11.5961 22.7855C7.05095 25.4501 6.54299 21.5626 7.94299 18.9888C7.94299 18.9888 5.62019 22.5992 8.26571 24.4946C10.9112 26.3899 15.4029 22.4724 16.9305 21.0513C18.4581 19.6302 26.7422 11.1057 24.6691 7.84754C21.5395 4.66816 14.4991 11.4579 14.4991 11.4579Z"
      fill="url(#paint0_radial_8296_35015)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4991 20.5421C14.4991 20.5421 18.2737 23.4563 21.1691 24.0038C24.0644 24.5512 22.791 21.443 21.98 20.1609C21.1691 18.8788 16.1413 11.879 11.5961 9.21449C7.05095 6.54994 6.54299 10.4374 7.94299 13.0112C7.94299 13.0112 5.62019 9.40076 8.26571 7.50544C10.9112 5.61011 15.4029 9.52764 16.9305 10.9487C18.4581 12.3698 26.7422 20.8943 24.6691 24.1525C21.5395 27.3318 14.4991 20.5421 14.4991 20.5421Z"
      fill="url(#paint1_radial_8296_35015)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_8296_35015"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-815.608 965.963) rotate(90) scale(2528.3 2528.97)"
      >
        <stop stop-color="#3B92CC" />
        <stop offset="1" stop-color="#005A96" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_8296_35015"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-815.608 -933.963) rotate(-90) scale(2528.3 2528.97)"
      >
        <stop stop-color="#3B92CC" />
        <stop offset="1" stop-color="#005A96" />
      </radialGradient>
    </defs>
  </svg>
);
