import { Field } from 'react-final-form';
import styled from 'styled-components';
import { SubSection } from '../../../../../services/api/vehicle-sections/types/common';
import { GradesComponent } from '../../Grades';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 1024px) {
    gap: 16px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

type Props = {
  sectionId: string;
  additionalSubSections: Record<string, SubSection>;
};

export const AdditionalGradeSections = ({ sectionId, additionalSubSections }: Props) => {
  const keys = Object.keys(additionalSubSections);
  return (
    <Wrapper>
      {keys.map(k => (
        <SectionWrapper>
          <Label>{additionalSubSections[k].label}</Label>
          <GradesComponent name={`${sectionId}.${k}`} grades={additionalSubSections[k].possibleGrades!} />
        </SectionWrapper>
      ))}
    </Wrapper>
  );
};
