export const HomeMapIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="37" viewBox="0 0 31 37" fill="none">
    <mask id="path-1-inside-1_6625_6129" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4619 36.9061C14.6497 36.9687 14.8532 37 15.0723 37C15.2601 37 15.4401 36.9687 15.6123 36.9061C15.7845 36.8435 15.9488 36.7496 16.1053 36.6244C20.7695 32.5237 24.2597 28.7204 26.5761 25.2145C28.8925 21.7085 30.0508 18.4374 30.0508 15.401C30.0508 10.7056 28.5404 6.96489 25.5197 4.17893C22.4989 1.39298 19.0008 0 15.0254 0C11.0499 0 7.55182 1.39298 4.53109 4.17893C1.51036 6.96489 0 10.7056 0 15.401C0 18.4374 1.15821 21.7085 3.47462 25.2145C5.79103 28.7204 9.2813 32.5237 13.9454 36.6244C14.1019 36.7496 14.2741 36.8435 14.4619 36.9061Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4619 36.9061C14.6497 36.9687 14.8532 37 15.0723 37C15.2601 37 15.4401 36.9687 15.6123 36.9061C15.7845 36.8435 15.9488 36.7496 16.1053 36.6244C20.7695 32.5237 24.2597 28.7204 26.5761 25.2145C28.8925 21.7085 30.0508 18.4374 30.0508 15.401C30.0508 10.7056 28.5404 6.96489 25.5197 4.17893C22.4989 1.39298 19.0008 0 15.0254 0C11.0499 0 7.55182 1.39298 4.53109 4.17893C1.51036 6.96489 0 10.7056 0 15.401C0 18.4374 1.15821 21.7085 3.47462 25.2145C5.79103 28.7204 9.2813 32.5237 13.9454 36.6244C14.1019 36.7496 14.2741 36.8435 14.4619 36.9061Z"
      fill="#CA8D57"
    />
    <path
      d="M14.4619 36.9061L15.7269 33.1114H15.7269L14.4619 36.9061ZM15.6123 36.9061L14.2453 33.1469L14.2453 33.1469L15.6123 36.9061ZM16.1053 36.6244L18.6041 39.7478L18.6767 39.6898L18.7465 39.6284L16.1053 36.6244ZM26.5761 25.2145L29.9135 27.4195V27.4195L26.5761 25.2145ZM4.53109 4.17893L1.81924 1.23855L1.81924 1.23855L4.53109 4.17893ZM13.9454 36.6244L11.3043 39.6284L11.3741 39.6898L11.4466 39.7478L13.9454 36.6244ZM15.0723 33C15.2376 33 15.4657 33.0243 15.7269 33.1114L13.197 40.7008C13.8338 40.9131 14.4688 41 15.0723 41V33ZM14.2453 33.1469C14.5224 33.0462 14.8059 33 15.0723 33V41C15.7144 41 16.3579 40.8912 16.9793 40.6653L14.2453 33.1469ZM13.6065 33.5009C13.7583 33.3795 13.9715 33.2465 14.2453 33.1469L16.9793 40.6653C17.5975 40.4405 18.1393 40.1197 18.6041 39.7478L13.6065 33.5009ZM23.2388 23.0094C21.187 26.1148 17.976 29.6535 13.4642 33.6203L18.7465 39.6284C23.5629 35.3938 27.3324 31.326 29.9135 27.4195L23.2388 23.0094ZM26.0508 15.401C26.0508 17.4058 25.2833 19.915 23.2388 23.0094L29.9135 27.4195C32.5018 23.5021 34.0508 19.469 34.0508 15.401H26.0508ZM22.8078 7.11932C24.8868 9.03675 26.0508 11.6472 26.0508 15.401H34.0508C34.0508 9.76398 32.194 4.89303 28.2315 1.23855L22.8078 7.11932ZM15.0254 4C17.9591 4 20.4984 4.98937 22.8078 7.11932L28.2315 1.23855C24.4995 -2.20342 20.0425 -4 15.0254 -4V4ZM7.24295 7.11932C9.55238 4.98937 12.0916 4 15.0254 4V-4C10.0082 -4 5.55126 -2.20342 1.81924 1.23855L7.24295 7.11932ZM4 15.401C4 11.6472 5.16393 9.03675 7.24295 7.11932L1.81924 1.23855C-2.14321 4.89303 -4 9.76398 -4 15.401H4ZM6.81196 23.0094C4.76743 19.915 4 17.4058 4 15.401H-4C-4 19.469 -2.45102 23.5021 0.137277 27.4195L6.81196 23.0094ZM16.5866 33.6203C12.0747 29.6535 8.8637 26.1148 6.81196 23.0094L0.137277 27.4195C2.71836 31.326 6.48786 35.3938 11.3043 39.6284L16.5866 33.6203ZM15.7269 33.1114C15.9923 33.1998 16.2369 33.335 16.4442 33.5009L11.4466 39.7478C11.967 40.1641 12.5559 40.4871 13.197 40.7008L15.7269 33.1114Z"
      fill="white"
      mask="url(#path-1-inside-1_6625_6129)"
    />
    <path
      d="M10.9604 22.5795C10.6024 22.5795 10.2985 22.4573 10.0487 22.2131C9.79891 21.9688 9.67402 21.6754 9.67402 21.3329V15.1293L7.72753 16.5498C7.58495 16.6548 7.43026 16.6974 7.26346 16.6778C7.09665 16.6581 6.95796 16.5793 6.84739 16.4416C6.7368 16.3081 6.69392 16.1568 6.71874 15.9874C6.74357 15.8181 6.82708 15.6797 6.96926 15.5722L14.4877 10.0281C14.6033 9.94715 14.7259 9.88318 14.8554 9.83615C14.9849 9.78913 15.1182 9.76562 15.255 9.76562C15.3919 9.76562 15.5254 9.78913 15.6555 9.83615C15.7855 9.88318 15.9108 9.94715 16.0314 10.0281L18.7362 12.0194V11.255C18.7362 10.9754 18.839 10.7371 19.0447 10.5401C19.2504 10.3431 19.4992 10.2446 19.7911 10.2446C20.0785 10.2446 20.3238 10.3441 20.5269 10.543C20.73 10.7419 20.8316 10.9803 20.8316 11.2583V13.5547L23.5431 15.5722C23.683 15.6794 23.7654 15.8177 23.7902 15.9872C23.815 16.1567 23.7722 16.3081 23.6616 16.4416C23.5465 16.5793 23.4055 16.6581 23.2387 16.6778C23.0719 16.6974 22.9195 16.6548 22.7814 16.5498L20.8316 15.1129V21.3329C20.8316 21.6754 20.7055 21.9688 20.4535 22.2131C20.2014 22.4573 19.8987 22.5795 19.5452 22.5795H18.3028C17.9494 22.5795 17.6466 22.4573 17.3945 22.2131C17.1425 21.9688 17.0165 21.6754 17.0165 21.3329V19.2071C17.0165 18.8645 16.8904 18.5711 16.6384 18.3269C16.3863 18.0826 16.0836 17.9605 15.7301 17.9605H14.7822C14.4288 17.9605 14.126 18.0826 13.874 18.3269C13.6219 18.5711 13.4959 18.8645 13.4959 19.2071V21.3329C13.4959 21.6754 13.3699 21.9688 13.1178 22.2131C12.8657 22.4573 12.563 22.5795 12.2095 22.5795H10.9604ZM13.5907 14.7258H16.9217C16.9217 14.3081 16.7552 13.962 16.4224 13.6875C16.0895 13.4131 15.7008 13.2758 15.2562 13.2758C14.8116 13.2758 14.4229 13.4124 14.09 13.6855C13.7571 13.9586 13.5907 14.3053 13.5907 14.7258Z"
      fill="white"
    />
  </svg>
);
