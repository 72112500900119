import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ChatMessage, useSendChatMessageMutation } from '../../../../services/api/list-vehicles';
import { Field, Form } from 'react-final-form';
import { composeValidators, maxLength, required } from '../../../../utils/validators';

type Props = {
  messages: ChatMessage[];
  listRequestId?: number;
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.span`
  color: #757575;
  font-size: 18px;
  font-weight: 500;
`;

const BlockWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  background: #f5f5f5;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`;

const MessagesWrapper = styled.div`
  max-height: 205px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  /* total width */
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 12px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #f5f5f5;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const MessageWrapper = styled.div`
  padding: 0 12px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SendMessageForm = styled.form`
  padding: 0 12px;
  width: calc(100% - 12px);
`;

const SendMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  background: #fff;
`;

const StyledInput = styled.input`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.41px;
  width: 400px;
  border: none;
  pointer-events: auto;

  @media (max-width: 567px) {
    width: 200px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Title = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const Timestamp = styled.span`
  color: #757575;
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

const NotesWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;

const SendMessageError = styled.span`
  padding: 12px;
  padding-bottom: 0;
  margin-top: 8px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #af2930;
`;

const ContentWrapper = styled.div`
  background-color: #ffffff;
  color: #616161;
  font-size: 14;
  opacity: 1;
  width: 532px;
  max-width: calc(100vw - 32px);
  margin-top: 6;
  padding: 8px 8px 8px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const SendMessageIcon = ({ isActive }: { isActive?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_6848_6585)">
      <path
        d="M3.4 20.4005L20.85 12.9205C21.66 12.5705 21.66 11.4305 20.85 11.0805L3.4 3.60051C2.74 3.31051 2.01 3.80051 2.01 4.51051L2 9.12051C2 9.62051 2.37 10.0505 2.87 10.1105L17 12.0005L2.87 13.8805C2.37 13.9505 2 14.3805 2 14.8805L2.01 19.4905C2.01 20.2005 2.74 20.6905 3.4 20.4005Z"
        fill={isActive ? '#3994DE' : '#CCCCCC'}
        fill-opacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_6848_6585">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const formatTimestamp = (timestamp: string | moment.Moment) => {
  return moment(timestamp).format('ddd DD.MM.YY h:mm A');
};

const maxMessageLength = maxLength(500);

export const ListVehicleChat = ({ messages, listRequestId }: Props) => {
  const [sendChatMessage] = useSendChatMessageMutation();

  const blockRef = useRef<any>(null);

  useEffect(() => {
    if (!blockRef?.current) return;
    blockRef.current.scrollTo(0, blockRef.current.scrollHeight);
  }, [messages.length]);

  const handleSubmit = async (values: { message: string }) => {
    if (!listRequestId) return;
    await sendChatMessage({ listRequestId, message: values.message });
  };

  return (
    <ContentWrapper>
      <PageWrapper>
        <PageHeaderWrapper>
          <HeaderTitle>Notes</HeaderTitle>
        </PageHeaderWrapper>
        <BlockWrapper>
          <MessagesWrapper ref={blockRef}>
            {messages.map(item => (
              <MessageWrapper key={item.id}>
                <Header>
                  <TitleWrapper>
                    <Title>{item.user.dealership_name}</Title>
                  </TitleWrapper>
                  <Timestamp>{formatTimestamp(item.created_at)}</Timestamp>
                </Header>
                <NotesWrapper>
                  <span>{item.message}</span>
                </NotesWrapper>
              </MessageWrapper>
            ))}
          </MessagesWrapper>

          <Form initialValues={{ message: '' }} onSubmit={handleSubmit}>
            {props => {
              const hasMessageError = props.touched?.message && props.errors?.message;
              return (
                <SendMessageForm
                  onSubmit={e => {
                    props.handleSubmit(e);
                    props.form.restart({ message: '' });
                  }}
                >
                  <SendMessageWrapper>
                    <Field name="message" validate={composeValidators(required, maxMessageLength)}>
                      {fieldProps => (
                        <NotesWrapper>
                          <StyledInput
                            autoComplete="off"
                            type="text"
                            placeholder="Write a message..."
                            autoFocus
                            {...fieldProps.input}
                          />
                        </NotesWrapper>
                      )}
                    </Field>
                    <div
                      style={{ display: 'flex', cursor: 'pointer' }}
                      onClick={() => {
                        if (hasMessageError) {
                          return;
                        }

                        props.form.submit();
                        props.form.restart({ message: '' });
                      }}
                    >
                      <SendMessageIcon isActive={!hasMessageError && props.values.message?.length > 0} />
                    </div>
                  </SendMessageWrapper>
                  {hasMessageError ? (
                    <SendMessageError>{props.errors?.message}</SendMessageError>
                  ) : (
                    <SendMessageError> </SendMessageError>
                  )}
                </SendMessageForm>
              );
            }}
          </Form>
        </BlockWrapper>
      </PageWrapper>
    </ContentWrapper>
  );
};
