import styled from 'styled-components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { DriveTrainIcon, EngineIcon, ExteriorIcon, TransmissionIcon, TrimIcon } from './icons';
import Tooltip from '../../../../../components/Tooltip';
import { useEffect, useRef, useState } from 'react';

const Wrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: 1fr;
`;

const InfoCard = styled.div`
  display: flex;
  max-width: 104px;
  background: rgba(249, 249, 249, 1);
  height: 113px;
  flex-direction: column;
  border-radius: 30px;
  gap: 4px;
  padding: 10px;
`;

const Label = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #000;
`;

const Value = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type InfoCardType = {
  Icon: () => JSX.Element;
  label: string;
  value: string;
};

export const MainCharacteristics = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  const data = [
    {
      Icon: TrimIcon,
      label: 'Trim',
      value: vehicle.trim
    },
    {
      Icon: EngineIcon,
      label: 'Engine',
      value: vehicle.engine
    },
    {
      Icon: ExteriorIcon,
      label: 'Exterior',
      value: vehicle.ext_color
    },
    {
      Icon: TransmissionIcon,
      label: 'Transmission',
      value: vehicle.transmission
    },
    {
      Icon: DriveTrainIcon,
      label: 'Drive Train',
      value: vehicle.drive_train
    }
  ];

  return (
    <Wrapper>
      {data.map(item => (
        <InfoCardComponent item={item} key={item.label} />
      ))}
    </Wrapper>
  );
};

const InfoCardComponent = ({ item }: { item: InfoCardType }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isTextOverflown, setIsTextOverflown] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsTextOverflown(ref.current.offsetWidth < ref.current.scrollWidth);
  }, [ref.current]);

  return (
    <InfoCard>
      <item.Icon />
      <Label>{item.label}</Label>
      <Tooltip isTextOverflown={isTextOverflown} titleStyle="uploadVehicle" title={item.value}>
        <Value ref={ref}>{item.value}</Value>
      </Tooltip>
    </InfoCard>
  );
};
