import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import {
  FormSelect,
  FormTextField,
  FormResetButton
} from '../../../../components/Form';
import { AuctionFilter } from '../../../../components/Filters';
import { filterBids } from '../../../../actions';
import { debounce } from '../../../../utils';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  div {
    padding-right: 16px;
    div {
      min-width: 155px;
    }
  }
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
  div:last-child {
    padding-right: 0;
  }
  @media (max-width: 960px) {
    flex-direction: column;
    > div {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
`;

const onChange = debounce((values, dispatch, { perPage, setPage }) => {
  setPage(1);
  dispatch(filterBids({ params: { limit: perPage, offset: 1 } }).request);
  setPage(2);
}, 500);

const toUpperCase = value => value && value.toUpperCase();

const Filter = ({ dirty, reset }) => (
  <StyledFilterWrapper>
    <Field
      component={FormTextField}
      name="vin"
      label="VIN Search"
      normalize={toUpperCase}
    />
    <Field
      component={FormTextField}
      name="dealership_name"
      label="Seller Search"
    />
    <Field component={FormSelect} name="status" label="Filter by Bid Status">
      <MenuItem value="">All</MenuItem>
      <MenuItem value="awarded">Awarded</MenuItem>
      <MenuItem value="declined">Declined</MenuItem>
      <MenuItem value="pulled">Pulled</MenuItem>
      <MenuItem value="undecided">Undecided</MenuItem>
      <MenuItem value="countered">Countered</MenuItem>
    </Field>
    <AuctionFilter />
    <Field component={FormSelect} name="order_by" label="Order By" select>
      <MenuItem value="recent_auction">Most Recent Auction</MenuItem>
      <MenuItem value="seller_asc">Seller A-Z</MenuItem>
      <MenuItem value="seller_desc">Seller Z-A</MenuItem>
    </Field>
    {dirty && <FormResetButton onClick={reset} />}
  </StyledFilterWrapper>
);

export default reduxForm({
  form: 'bidsEndingFilterForm',
  onChange,
  initialValues: {
    order_by: 'recent_auction'
  },
  destroyOnUnmount: false
})(Filter);
