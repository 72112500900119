/* eslint-disable no-else-return */
import { VEHICLE_STATUSES } from 'constants';
import React from 'react';
import styled from 'styled-components';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const statusText = {
  awarded: 'Awarded',
  pulled: 'Pulled',
  active: 'Active',
  draft: 'Draft',
  deactivated: 'Deactivated',
  not_running: 'Not Running'
};

const statusColor = {
  awarded: '#009F0F',
  active: '#009F0F',
  pulled: '#E47E00',
  draft: '#E47E00',
  deactivated: '#E47E00',
  not_running: '#E47E00'
};

const BidStatus = ({ status, vehicle }) => {
  const parsedStatus = (() => {
    if (status !== VEHICLE_STATUSES.ACTIVE) {
      return status;
    }

    return vehicle.auction && vehicle.auction.status === 'active' ? 'active' : 'not_running';
  })();

  return <StyledStatus statusColor={statusColor[parsedStatus]}>{statusText[parsedStatus] || 'None'}</StyledStatus>;
};

export default BidStatus;
