import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageWrapper } from '../../components';
import {
  IntroSection,
  CarsModalSection,
  BenefitsSection,
  ClientsSaySection,
  PartnersSection,
  VehiclesSection
} from './components';
import './index.scss';
import { listVehicles, getCurrentAuction } from '../../actions';
import { BuyerDisabledSection } from './components/BuyerDisabledSection';

class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { fetchVehicles, fetchCurrentAuction } = this.props;

    // TODO: Location is hardcoded. Change it to currentLocation prop when change location will be handled
    fetchVehicles({
      params: {
        mainPage: true,
        location_id: 1
      }
    });
    fetchCurrentAuction();
  }

  render() {
    const { vehicles, currentAuction, user } = this.props;
    let showLiveAuction = currentAuction && currentAuction.status === 'active';
    const isLoggedIn = user && user.id;

    if (isLoggedIn && showLiveAuction) {
      showLiveAuction = vehicles.length;
    } else if (showLiveAuction) {
      showLiveAuction = vehicles.length > 3;
    }

    return (
      <div className="home-page">
        <IntroSection />
        <CarsModalSection />
        <BenefitsSection />
        <ClientsSaySection />
        <section className="sign-up-section">
          <PageWrapper>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} md={7}>
                <p className="text accent">We care about your satisfaction.</p>
                <p className="text">We want to help you increase your profits!</p>
              </Grid>
              <Grid item xs={12} md={3}>
                <Link className="button-primary" to="/register">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </PageWrapper>
        </section>
        {showLiveAuction && vehicles.length > 3 && (
          <VehiclesSection vehicles={vehicles} isActiveAuction={Boolean(showLiveAuction)} />
        )}
        <PartnersSection />
        <BuyerDisabledSection />
        <section className="sign-up-section bottom-button">
          <PageWrapper>
            <p className="description">Take 5 minutes to create an account and start buying/selling in AutoAxess</p>
            <Link className="button-primary" to="/register">
              Sign Up
            </Link>
          </PageWrapper>
        </section>
        <section className="contact-us">
          <p className="text">
            Thank you for choosing us. For more information please contact us or visit our{' '}
            <Link className="link" to="/contact-us">
              support page.
            </Link>
          </p>
        </section>
        {Boolean(showLiveAuction) && (
          <Link to="/live-auction" className="auction-button">
            <span className="pulse" />
            Live Auction
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: (state.user || {}).user,
  vehicles: state.vehicles.itemsData.rows || [],
  currentLocation: state.locations.currentLocation,
  currentAuction: state.auctions.current,
  vehiclesLoading: state.vehicles.loading
});

const mapDispatchToProps = dispatch => ({
  fetchVehicles: params => dispatch(listVehicles(params).request),
  fetchCurrentAuction: () => dispatch(getCurrentAuction().request)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
