// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/demos/autocomplete/IntegrationReactSelect.js
import React, { Component } from 'react';
import Select from 'react-select';
import { Typography, TextField, MenuItem, Paper, FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
  word-break: break-word;
  min-height: 24px;
  height: 100%;
`;

// Previous developers copy-pasted code from git without converting to styled-components.
// Would be nice to convert to styled-components in future.
const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 32
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08)
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    color: theme.palette.text.primary
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography color="textSecondary" className={selectProps.classes.noOptionsMessage} {...innerProps}>
    {children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = ({ selectProps, innerProps, children, innerRef }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        inputRef: innerRef,
        children,
        ...innerProps
      }
    }}
    {...selectProps.textFieldProps}
  />
);

const Option = ({ innerProps, children, innerRef, isFocused, isSelected }) => (
  <StyledMenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400
    }}
    {...innerProps}
  >
    {children}
  </StyledMenuItem>
);

const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
);

const SingleValue = ({ selectProps, innerProps, children }) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

// Shrink logic dedicated for faking TextField label
// Guys chosen wrong select for material ui,
//  so in that case we need this logic inside
class FormAutocompleteSelect extends Component {
  state = { shrink: false };

  componentWillMount() {
    const {
      input: { value: inputValue }
    } = this.props;

    if (inputValue && inputValue.value) {
      this.toggleShrink(true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      input: { value: prevValue }
    } = prevProps;
    const {
      meta: { active },
      input: { value: currentValue }
    } = this.props;
    const isBlurred = !active;
    const isEmpty = !currentValue.value;
    if (prevValue.value && isBlurred && isEmpty) {
      this.toggleShrink(false);
    }
  }

  toggleShrink = shrink => this.setState({ shrink });

  render() {
    const {
      input,
      meta: { touched, error, invalid },
      label = '',
      placeholder = '',
      children,
      classes,
      theme,
      defaultValue,
      shrinkIndicator = false,
      ...custom
    } = this.props;

    const { shrink } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      }),
      dropdownIndicator: base => ({ ...base, padding: shrinkIndicator ? 0 : 8 })
    };

    return (
      <>
        <Select
          {...input}
          {...custom}
          classes={classes}
          styles={selectStyles}
          onBlur={() => {
            if (input.value && input.value.value) {
              input.onBlur({ ...input.value });
            } else {
              this.toggleShrink(false);
            }
          }}
          onFocus={() => {
            input.onFocus({ ...input.value });
            if (!input.value || !input.value.value) {
              this.toggleShrink(true);
            }
          }}
          placeholder={placeholder}
          value={input.value && input.value.value ? input.value : defaultValue || ''}
          components={components}
          textFieldProps={{
            label,
            InputLabelProps: {
              shrink: shrink || !!defaultValue || !!input.value
            }
          }}
        />
        {touched && invalid && error && !custom.hideErrors && <FormHelperText error>{error}</FormHelperText>}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FormAutocompleteSelect);
