import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, Field, arrayPush } from 'redux-form';
import styled from 'styled-components';
import { FormLabel, Divider, MenuItem, Button } from '@material-ui/core';
import { required, maxLength } from '../../../../utils/validators';
import StyledGrid from './StyledGrid';
import { FormTextField } from '../../../../components/Form';
import { FLOORING_FORM_COMPANIES, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const StyledFlooringFormLabel = styled(FormLabel)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: #222222;
  }
`;

const fieldComponent = ({ fields, InputProps }) =>
  fields.map((field, index) => (
    <div key={index}>
      <Divider />
      <StyledGrid sm={12}>
        <StyledFlooringFormLabel component="legend">
          Flooring Form #{index + 2}
        </StyledFlooringFormLabel>
        <Field
          name={`${field}.flooring_company`}
          component={FormTextField}
          label="Select Flooring Company"
          fullWidth
          validate={required}
          select
          InputProps={InputProps}
        >
          {FLOORING_FORM_COMPANIES.map((company) => (
            <MenuItem value={company.value}>{company.name}</MenuItem>
          ))}
        </Field>
        <StyledGrid sm={12}>
          <Field
            name={`${field}.name_act`}
            component={FormTextField}
            label="Name on Acct"
            fullWidth
            validate={[required, maxStringLength]}
            InputProps={InputProps}
          />
        </StyledGrid>
        <StyledGrid sm={12}>
          <Field
            name={`${field}.act`}
            component={FormTextField}
            label="Acct #"
            fullWidth
            validate={[required, maxStringLength]}
            InputProps={InputProps}
          />
        </StyledGrid>
        <StyledGrid sm={12}>
          <Field
            name={`${field}.auction_access`}
            component={FormTextField}
            label="Auction Access #"
            fullWidth
            validate={[required, maxStringLength]}
            InputProps={InputProps}
          />
        </StyledGrid>
      </StyledGrid>
      {!InputProps.disabled && (
        <StyledGrid sm={12}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => fields.remove(index)}
          >
            Remove
          </Button>
        </StyledGrid>
      )}
    </div>
  ));

const AdditionalFlooringForms = ({ InputProps, addItem }) => (
  <>
    <FieldArray
      name="flooring_form.additional_forms"
      component={fieldComponent}
      InputProps={InputProps}
    />
    <Button
      color="primary"
      variant="outlined"
      onClick={addItem}
      disabled={InputProps.disabled}
    >
      Add Another Flooring Account
    </Button>
  </>
);

export default connect(
  null,
  dispatch => ({
    addItem: () =>
      dispatch(
        arrayPush('purchaseMethodsForm', 'flooring_form.additional_forms', {})
      )
  })
)(AdditionalFlooringForms);
