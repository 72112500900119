import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import moment from 'moment';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { DealerLicenseForm } from '../forms';
import { DATE_FORMAT } from '../../../../constants';

class DealerLicenseSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    const { role } = initialValues;
    const { dealer_license_number, dealer_license_expiry_date } = initialValues[
      initialValues.role
    ];
    const extendedInitialValues = {
      role,
      dealer_license_expiry_date: dealer_license_expiry_date
        ? moment(dealer_license_expiry_date).format(DATE_FORMAT)
        : null,
      dealer_license_number
    };
    return (
      <SectionBlock
        title="Dealer License"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <DealerLicenseForm
          InputProps={InputProps}
          initialValues={extendedInitialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('dealerLicenseForm')),
  resetForm: () => dispatch(reset('dealerLicenseForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(DealerLicenseSection);
