import styled from 'styled-components';
import { StyledLabel, StyledText, Wrapper } from './styled';
import { BlockProps } from './types';
import { EditIcon } from '../../../../../components/Icons';
import Tooltip from '../../../../../components/Tooltip';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../../actions';
import { useRef } from 'react';
import { useIsTextOverflown } from '../../../../../hooks';

const EditIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const AddNotesLabel = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: #3994de;
  margin-left: 4px;
  min-width: fit-content;

  &:hover {
    color: #000;
  }
`;

const NotesPlaceholder = styled.div`
  color: #b0b0b0;
  font-weight: 500;
  font-size: 12px;
  min-width: fit-content;
`;

const AddNotesWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 12px;
`;

export const MyBidsNotesBlock = ({ currentVehicle }: BlockProps) => {
  const dispatch = useDispatch();

  const toggleModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(modalsToggle(`myBidsEditModal-${currentVehicle.id}`));
  };

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  return (
    <div>
      <Wrapper>
        <StyledLabel>Notes:</StyledLabel>
        {currentVehicle.notes ? (
          <Tooltip isTextOverflown={isTextOverflown} disableFocusListener title={currentVehicle.notes} arrow light>
            <StyledText maxWidth={100} ref={ref}>{currentVehicle.notes}</StyledText>
          </Tooltip>
        ) : (
          <NotesPlaceholder>No Notes</NotesPlaceholder>
        )}
      </Wrapper>

      <Wrapper onClick={toggleModal}>
        <AddNotesWrapper>
          <EditIconWrapper>
            <EditIcon />
          </EditIconWrapper>
          <AddNotesLabel>Add Notes</AddNotesLabel>
        </AddNotesWrapper>
      </Wrapper>
    </div>
  );
};
