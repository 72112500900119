import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PageHeader } from '../../../../components';
import { filterAuctionVehicles } from '../../../../actions';
import { auctionVehiclesSetPage } from '../../../../actions/auctions';
import {
  useGetRunningVehiclesCountQuery,
  useLazyGetAuctionFilterCountersQuery,
  useLazyGetAuctionVehiclesQuery
} from 'services/api/admin-auction-vehicles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetActiveAuctionsQuery } from 'services/api/live-auction';
import { useGetAuctionByIdQuery } from 'services/api/auctions-list';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
    border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
  }
`;

const StyledBackButton = styled(ButtonBase)`
  && {
    padding-right: 10px;
    span {
      font-size: 20px;
    }
  }
`;

const Header = ({ match, history }) => {
  const { params } = match;

  const { id: auctionId } = useParams();
  const { filters, page, perPage } = useSelector(state => state.auctionVehicles);
  const [refetchData] = useLazyGetAuctionVehiclesQuery();
  const [refetchCounters] = useLazyGetAuctionFilterCountersQuery();

  const { data: runningVehicles } = useGetRunningVehiclesCountQuery(auctionId);

  const { data } = useGetAuctionByIdQuery(auctionId, { skip: !auctionId });

  const slug = data?.slug ?? auctionId;

  return (
    <PageHeader>
      <StyledBackButton onClick={() => history.push('/admin/auctions')}>
        <Icon>arrow_back</Icon>
      </StyledBackButton>
      <StyledTypography variant="title">{`Auction ${slug}`}</StyledTypography>
      <span className="flex-grow" />
      <StyledTypography variant="title">{`${runningVehicles ?? 0} Cars Running`}</StyledTypography>
      <ButtonBase
        onClick={() => {
          refetchData({
            limit: perPage,
            offset: page,
            auction_id: auctionId,
            ...filters
          });
          refetchCounters({
            auction_id: auctionId
          });
        }}
      >
        <Icon>refresh</Icon>
      </ButtonBase>
    </PageHeader>
  );
};

const mapStateToProps = state => ({
  perPage: state.auctions.auctionVehicles.perPage
});

const mapDispatchToProps = dispatch => ({
  refreshList: payload => dispatch(filterAuctionVehicles(payload).request),
  setPage: data => dispatch(auctionVehiclesSetPage(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
