import React, { Component } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography, FormLabel, Divider, MenuItem, Button } from '@material-ui/core';
import styled from 'styled-components';
import { FormTextField, FormError } from '../../../../components/Form';
import { StyledGrid } from '../components';
import { userStateSelector } from '../../../../selectors';
import { updateProfile } from '../../../../actions/user';
import { maxLength, required } from '../../../../utils/validators';
import {
  FLOORING_FORM_COMPANIES_NAMES,
  BUYER_PURCHASE_METHODS,
  FLOORING_FORM_COMPANIES,
  EASYPAY_PAYMENT_TYPES,
  REGISTRATION_MAX_STRING_LENGTH
} from '../../../../constants';
import { ACH_FORM_FIELDS, FLOORING_FORM_FIELDS ,EASY_PAY_PURCHASE_FORM } from '../../../../constants/forms';

const formName = 'easyPayPurchaseMethodsForm';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const StyledDivider = styled(Divider)`
  margin-top: 15px;
`;

const StyledCancelButton = styled(Button)`
  margin-right: 10px;
`;

const renderBasicFields = (fields, props = {}) =>
  fields.map(({ name = '', label = '', sm = 12, validation = [required, maxStringLength], ...rest }, index) => (
    <StyledGrid item sm={sm} key={index}>
      <Field name={name} component={FormTextField} label={label} fullWidth validate={validation} {...props} {...rest} />
    </StyledGrid>
  ));

const StyledFormLabel = styled(FormLabel)`
  margin: 20px 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: #222222;
`;

const StyledDisclosure = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

class EasyPayPurchaseMethodsForm extends Component {
  state = {
    isFlooringFormShowing: false
  };

  render() {
    const { reset, handleSubmit, error, profile, type } = this.props;
    const { isFlooringFormShowing } = this.state;
    if (!profile) return null;
    const { buyer } = profile;
    const { flooring_form: flooringForm, purchase_methods: purchaseMethods = [] } = buyer;

    const achAvailable = purchaseMethods.find(method => method.option_name === BUYER_PURCHASE_METHODS.ACH);
    const flooringAvailable = purchaseMethods.find(method => method.option_name === BUYER_PURCHASE_METHODS.FLOORING);

    let availableCompanies = FLOORING_FORM_COMPANIES.map(company => company.value);
    if (flooringAvailable) {
      const { additional_forms: additionalFlooringForms = [] } = flooringForm;
      let companies = [flooringForm, ...additionalFlooringForms].map(company => company.flooring_company);
      companies = [...new Set(companies)];
      availableCompanies = availableCompanies
        .filter(company => !companies.includes(company));
    }

    const shouldShowACHForm = !achAvailable;
    const canAddFlooringForm = Boolean(availableCompanies.length);

    return (
      <form
        onSubmit={(e) => {
          handleSubmit(e).then(() => {
            this.setState({ isFlooringFormShowing: false });
            reset();
          });
        }}
      >
        <StyledGrid container spacing={24}>
          {shouldShowACHForm && (
            <>
              <StyledGrid item xs={12}>
                <StyledDivider />
                <StyledFormLabel component="legend">ACH Form</StyledFormLabel>
                <StyledDisclosure>
                  By filling out my (our) banking and flooring information below
                  {' '}
                  I (we) have authority to and authorize AutoAxess to Electronically debit
                  {' '}
                  my (our) account (and if necessary, electronically credit my (our) account to correct erroneous debits) as follows:
                </StyledDisclosure>
                <Field
                  name="ach_form.account_type"
                  component={FormTextField}
                  label="Select Account Type"
                  fullWidth
                  validate={required}
                  select
                >
                  <MenuItem value="checking">Checking</MenuItem>
                  <MenuItem value="savings">Savings</MenuItem>
                </Field>
              </StyledGrid>
              {renderBasicFields(ACH_FORM_FIELDS)}
            </>
          )}
          {isFlooringFormShowing && (
            <>
              <StyledGrid item xs={12}>
                <StyledDivider />
                <StyledFormLabel component="legend">Flooring Form</StyledFormLabel>
                <StyledDisclosure>
                  By filling out my (our) banking and flooring information below
                  {' '}
                  I (we) have authority to and authorize AutoAxess to Electronically debit
                  {' '}
                  my (our) account (and if necessary, electronically credit my (our) account to correct erroneous debits) as follows:
                </StyledDisclosure>
                <Field
                  name="flooring_form.flooring_company"
                  component={FormTextField}
                  label="Select Flooring Company"
                  fullWidth
                  validate={required}
                  select
                >
                  {availableCompanies.map((company, index) => (
                    <MenuItem value={company} key={index}>
                      {FLOORING_FORM_COMPANIES_NAMES[company]}
                    </MenuItem>
                  ))}
                </Field>
              </StyledGrid>
              {renderBasicFields(FLOORING_FORM_FIELDS)}
            </>
          )}
          {(canAddFlooringForm
            && !isFlooringFormShowing
            && (!flooringAvailable || type === EASYPAY_PAYMENT_TYPES.FLOORING))
            &&
            <StyledGrid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ isFlooringFormShowing: true })}
              >
                {flooringAvailable ? 'Add Another Flooring Account' : 'Add A Flooring Account'}
              </Button>
            </StyledGrid>
          }
          {isFlooringFormShowing &&
            <StyledGrid item container justify="flex-end" xs={12}>
              <StyledDisclosure>
                I (we) understand that this authorization will remain in full force and effect until
                {' '}
                I (we) notify AutoAxess in writing or by phone that I (we) wish to revoke this authorization.
                {' '}
                I (we) understand that AutoAxess requires at least 30 days notice to cancel this authorization.
              </StyledDisclosure>
              <StyledCancelButton
                variant="contained"
                color="primary"
                onClick={() => this.setState({ isFlooringFormShowing: false })}
              >
                Cancel
              </StyledCancelButton>
              <Button variant="contained" color="primary" type="submit">
                Confirm
              </Button>
            </StyledGrid>
          }
          {error && <FormError msg={error} />}
        </StyledGrid>
      </form>
    );
  }
}

const onSubmit = (values, dispatch, props) => {
  const { buyer } = props.profile;
  const { purchase_methods: purchaseMethods = [] } = buyer;
  const methods = purchaseMethods
    .map(({ option_name }) => option_name)
    .filter(({ option_name: optionName }) => optionName !== 'ach' || optionName !== 'flooring');
  let payload = {};

  const isAchSelected = Boolean(values.ach_form);
  const isFlooringSelected = Boolean(values.flooring_form);

  if (isAchSelected) {
    payload = {
      purchase_method: [...methods, BUYER_PURCHASE_METHODS.ACH],
      ach_form: values.ach_form
    };
  } else if (isFlooringSelected) {
    const buyerHasFlooringAccount = Boolean(buyer.flooring_form);
    const flooringForm = buyerHasFlooringAccount
      ? {
          ...buyer.flooring_form,
          additional_forms: [...(buyer.flooring_form.additional_forms || []), values.flooring_form]
        }
      : values.flooring_form;
    payload = {
      purchase_method: [...methods, BUYER_PURCHASE_METHODS.FLOORING],
      flooring_form: flooringForm
    };
  }

  return new Promise((resolve, reject) =>
    dispatch(
      updateProfile({
        payload,
        resolve,
        reject
      }).request
    )
  );
};

export default compose(
  connect(state => ({
    profile: userStateSelector(state),
    type: formValueSelector(EASY_PAY_PURCHASE_FORM)(state, 'type'),
  })),
  reduxForm({
    form: formName,
    onSubmit,
    enableReinitialize: true
  })
)(EasyPayPurchaseMethodsForm);
