import React from 'react';
import { CardInformationSection } from '../sections';

const CardInformationTab = ({ user }) => (
  <>
    <CardInformationSection initialValues={user} />
  </>
);

export default CardInformationTab;
