import { call, put, takeLatest, all } from 'redux-saga/effects';
import { listRegions } from '../actions';
import { LIST_REGIONS_REQUEST } from '../constants/actionTypes';
import { Api } from '../utils';

function* fetchLocations({ payload }) {
  try {
    const { data } = yield call(Api.regions.fetchRegions, payload);
    yield put(listRegions(data).success);
  } catch (e) {
    yield put(listRegions(e).failure);
  }
}

export default function*() {
  yield all([takeLatest(LIST_REGIONS_REQUEST, fetchLocations)]);
}
