/* eslint-disable no-case-declarations */
import {
  LIST_BIDS_SUCCESS,
  LIST_BIDS_REQUEST,
  LIST_BIDS_FAILURE,
  FILTER_BIDS_REQUEST,
  FILTER_BIDS_SUCCESS,
  LIST_BIDS_CHANGE_AUCTION_STATUS,
  WS_BIDS_NEW_BID,
  WS_BIDS_AUCTION_STATUS_CHANGE,
  WS_BIDS_VEHICLE_UPDATE,
  WS_BIDS_VEHICLE_DELETE,
  BIDS_SET_PAGE,
  SET_ACTIVE_BID
} from '../constants/actionTypes';
import { EASY_PAY_BUY_SUCCESS } from '../actions/vehicles';
import { VEHICLE_TABS_TYPES, BIDS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  submiting: false,
  activeVehicle: null,
  itemsData: {
    rows: [],
    count: 0,
    newVehiclesLength: 0
  },
  counters: {
    loading: false,
    success: false,
    data: {}
  },
  page: 1,
  auctionStatus: 'active',
  perPage: BIDS_PER_PAGE,
  error: '',
  success: false
};

const bids = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case BIDS_SET_PAGE:
      return { ...state, page: payload };
    case LIST_BIDS_REQUEST:
      return { ...state, loading: true };
    case LIST_BIDS_SUCCESS:
      let newVehicles = payload.data.rows;
      const updatedAlreadyExistingVehicles = state.itemsData.rows.map(vehicle => {
        const updatedVehicle = newVehicles.find(item => item.id === vehicle.id);
        if (updatedVehicle) {
          newVehicles = newVehicles.filter(item => item.id !== vehicle.id);
          return { ...vehicle, ...updatedVehicle };
        }
        return vehicle;
      });
      return {
        ...state,
        loading: false,
        itemsData: {
          ...state.itemsData,
          count: payload.data.count,
          rows: payload.reset ? newVehicles : [...updatedAlreadyExistingVehicles, ...newVehicles],
          newVehiclesLength: newVehicles?.length ?? 0
        }
      };
    case LIST_BIDS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    case FILTER_BIDS_REQUEST:
      return { ...state, loading: true };
    case FILTER_BIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    case LIST_BIDS_CHANGE_AUCTION_STATUS:
      return {
        ...state,
        auctionStatus: payload
      };
    case WS_BIDS_NEW_BID:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.vehicle_id) {
              return {
                ...vehicle,
                bids: [payload, ...vehicle.bids]
              };
            }
            return vehicle;
          })
        }
      };
    case WS_BIDS_VEHICLE_UPDATE:
      // const existingVehicle = state.itemsData.rows.some(vehicle => vehicle.id === payload.id);
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.id) {
              return {
                ...vehicle,
                ...payload
              };
            }
            return vehicle;
          })
        }
      };
    case WS_BIDS_VEHICLE_DELETE:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.filter(vehicle => vehicle.id !== payload)
        }
      };
    case WS_BIDS_AUCTION_STATUS_CHANGE:
      const vehicleExist = state.itemsData.rows.find(vehicle => vehicle.id === payload.id);
      const isAuctionStatusSame = state.auctionStatus === payload.auction.status;
      if (isAuctionStatusSame && vehicleExist) {
        return state;
      }
      if (isAuctionStatusSame && !vehicleExist) {
        return {
          ...state,
          itemsData: {
            ...state.itemsData,
            rows: [payload, ...state.itemsData.rows]
          }
        };
      }
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.filter(vehicle => vehicle.id !== payload.id)
        }
      };
    case SET_ACTIVE_BID:
      return {
        ...state,
        activeBid: payload
      };
    case EASY_PAY_BUY_SUCCESS:
      return {
        ...state,
        itemsData: {
          ...state.itemsData,
          rows: state.itemsData.rows.map(vehicle => {
            if (vehicle.id === payload.data.id) {
              return {
                ...vehicle,
                ...payload.data
              };
            }
            return vehicle;
          })
        }
      };
    default:
      return state;
  }
};

export default bids;
