import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { OfferAuction } from '../../types/vehicle';
import styled from 'styled-components';
import { usePublicOfferAmount } from '../../hooks/usePublicOfferAmount';

type Props = {
  offerAuction: OfferAuction;
};

const StyledGrid = styled(Grid)`
  padding: 0 32px 0 0;
`;
const StyledBold = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const PublicOfferInfo = ({ offerAuction }: Props) => {
  const { sellerAmount, adminAmount } = usePublicOfferAmount(offerAuction);

  const hasPublicCounters = sellerAmount > 0 || adminAmount > 0

  return (
    <>
      {sellerAmount > 0 && (
        <Grid container alignItems="center" justify="space-between">
          <StyledGrid item xs={12} sm={8}>
            <StyledBold>
              <span>Seller counter amount — </span>
              <NumberFormat value={sellerAmount} displayType="text" thousandSeparator prefix="$" />
            </StyledBold>
          </StyledGrid>
        </Grid>
      )}
      {adminAmount > 0 && (
        <Grid container alignItems="center" justify="space-between">
          <StyledBold>
            <span>AutoAxess counter amount — </span>
            &nbsp;
            <NumberFormat value={adminAmount} displayType="text" thousandSeparator prefix="$" />
          </StyledBold>
        </Grid>
      )}
      {!hasPublicCounters && (
        <Grid container alignItems="center" justify="space-between">
          <StyledBold>
            <span>No counters</span>
          </StyledBold>
        </Grid>
      )}
    </>
  );
};
