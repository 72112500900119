import React from 'react';
import { Divider } from '@material-ui/core';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 24px 0;
`;

const SectionDivider = () => <StyledDivider />;

export default SectionDivider;
