import { Button, Dialog, Icon } from '@material-ui/core';
import { RelistHistoryRecord, useGetRelistHistoryQuery } from '../../services/api/admin-auction-vehicles';
import styled from 'styled-components';
import { Vehicle } from '../../types/vehicle';
import { formatCurrency } from '../../utils';
import { DownArrow, UpArrow } from '../../img/arrows';
import moment from 'moment';
import { PullVehicleModal } from '../../pages/Admin/AuctionDetail/components/AdminPullModal';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../actions';
import { RelistDialog } from '../RelistDialog/RelistDialog';
import { setShouldRelistAfterPull, openRelistDialog } from '../../services/auctionVehicles/auctionVehiclesSlice';

type Props = {
  vehicleId: number;
  isOpen: boolean;
  close: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

const Title = styled.span`
  color: #3994de;
  font-size: 18px;
  font-weight: 700;
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

const HistoryItem = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #b0b0b0;
  padding-bottom: 20px;
`;

const RelistedFromContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #b0b0b0;
  gap: 64px;

  & > div:first-child {
    flex: 1 1 40%;
  }

  & > div:last-child {
    flex: 1 1 60%;
  }
`;

const SecondaryDataContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 24px;
`;

const SecondaryDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 50%;
`;

const Data = styled.div`
  display: flex;
  gap: 12px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  color: #757575;
  position: relative;
`;

const Value = styled.div`
  color: #000000;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const GreyContainer = styled.div`
  background: #b0b0b033;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  color: #757575;
  font-size: 16px;
  gap: 4px;
  margin: 18px 0;
`;

const HorizontalLine = styled.span`
  height: 1px;
  width: 100%;
  background: #b0b0b0;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledDialog = styled(Dialog)``;

const labelMap: Record<string, string> = {
  max_offer: 'Max Offer',
  buyer: 'From Buyer',
  new_reserve: 'New Reserve',
  initial_reserve: 'Initial Reserve',
  final_reserve: 'Final Reserve',
  aa_counter: 'Counter to buyers',
  min_seller_take: 'Min Seller will take',
  bluebook_value: 'Blue Book Value',
  difference: 'Difference',
  notes: 'Notes',
  starting_bid: 'Starting Bid'
};

export const RelistHistory = ({ vehicleId, isOpen, close }: Props) => {
  const { data } = useGetRelistHistoryQuery(vehicleId, { skip: !isOpen });
  const dispatch = useDispatch();

  if (!isOpen || !data || data.length === 0) return null;

  const vehicleTitle = (vehicle: Vehicle) => `${vehicle.year} ${vehicle.make} ${vehicle.model}`.toUpperCase();

  const isVehicleAlreadyPulled = data[0]?.vehicle?.status === 'pulled';

  const openPullModal = () => {
    if (isVehicleAlreadyPulled) {
      return dispatch(openRelistDialog(vehicleId));
    }
    dispatch(setShouldRelistAfterPull());
    dispatch(modalsToggle(`confirm-pull-${vehicleId}`));
    close();
  };

  const SecondaryDataItem = ({ name, record }: { name: keyof RelistHistoryRecord; record: RelistHistoryRecord }) => {
    let value = record[name];
    let Icon: (() => JSX.Element) | null = null;

    if (name === 'max_offer') {
      value = [value, record['max_proxy']]
        .filter(Boolean)
        .map(formatCurrency)
        .join('/');
    } else if (name === 'difference') {
      Icon = value < 0 ? DownArrow : UpArrow;
      value = formatCurrency(Math.abs(value));
    } else if (typeof value === 'number') {
      value = formatCurrency(value);
    }

    const label = labelMap[name];

    return (
      <Data>
        <Label>{label}</Label>
        <ValueWrapper>
          {Icon && (
            <IconWrapper>
              <Icon />
            </IconWrapper>
          )}
          <Value>{value || '-'}</Value>
        </ValueWrapper>
      </Data>
    );
  };

  const [first, ...rest] = data;

  const HistoryComponent = ({ el, i }: { el: RelistHistoryRecord; i: number }) => {
    return (
      <HistoryItem key={el.id}>
        <RelistedFromContainer>
          <Data>
            <Label>Relisted From</Label>
            <Value>{`#${el.auction?.slug ?? el.relisted_from_id}`}</Value>
          </Data>
          <Data>
            <Label>Relisted Date</Label>
            <Value>{`${moment(el.relisted_at).format('MM/DD/YY hh:mm A')}`}</Value>
          </Data>
        </RelistedFromContainer>
        <SecondaryDataContainer>
          <SecondaryDataColumn>
            <SecondaryDataItem name="max_offer" record={el} />
            <SecondaryDataItem name="buyer" record={el} />
            <SecondaryDataItem name="starting_bid" record={el} />
          </SecondaryDataColumn>
          <SecondaryDataColumn>
            <SecondaryDataItem name="new_reserve" record={el} />
            <SecondaryDataItem name="initial_reserve" record={el} />
            <SecondaryDataItem name="final_reserve" record={el} />
            <SecondaryDataItem name="aa_counter" record={el} />
            <SecondaryDataItem name="min_seller_take" record={el} />
          </SecondaryDataColumn>
        </SecondaryDataContainer>
        <GreyContainer>
          <SecondaryDataItem name="bluebook_value" record={el} />
          <HorizontalLine />
          <SecondaryDataItem name="difference" record={el} />
        </GreyContainer>
        <SecondaryDataItem name="notes" record={el} />
        {i === 0 && (
          <Button
            onClick={openPullModal}
            size="large"
            style={{ marginTop: '18px' }}
            color="primary"
            variant="contained"
          >
            {isVehicleAlreadyPulled ? 'RELIST' : 'PULL AND RELIST'}
          </Button>
        )}
      </HistoryItem>
    );
  };

  return (
    <StyledDialog open={isOpen} onBackdropClick={close} fullWidth maxWidth={'sm'}>
      <Container>
        <Header>
          <Title>
            Relisted {vehicleTitle(data[0]?.vehicle)} {data[0].seller ? `from ${data[0].seller}` : ''}
          </Title>
          <Icon onClick={close}>close</Icon>
        </Header>
        <HistoryComponent el={first} i={0} />
        <HistoryWrapper>
          {rest.map((el: any, i: number) => {
            return <HistoryComponent key={el.id} el={el} i={i + 1} />;
          })}
        </HistoryWrapper>
      </Container>
    </StyledDialog>
  );
};
