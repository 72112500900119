import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import sagas from '../sagas';
import googleAnalyticsMiddleware from './googleAnalyticsMiddleware';
import { configureStore } from '@reduxjs/toolkit';
import apiSlice from '../services/api';

export const history = createBrowserHistory();
if (history.location && history.location.state && history.location.state.step) {
  const state = { ...history.location.state };
  delete state.step;
  history.replace({ ...history.location, state });
}

const setupStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const reactRouterMiddleware = routerMiddleware(history);

  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
        sagaMiddleware,
        reactRouterMiddleware,
        apiSlice.middleware
      );
      if (process.env.NODE_ENV === 'production') {
        middleware.push(googleAnalyticsMiddleware);
      }
      return middleware;
    },
    devTools: process.env.NODE_ENV !== 'production'
  });
  sagaMiddleware.run(sagas);
  return store;
};

export default setupStore;
