import { AppBar, ButtonBase, Icon, Toolbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { PageWrapper } from '../../../../../components';
import { useHistory } from 'react-router-dom';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';

const TypographyBreadcrumbs = styled(Typography)`
  font-size: 22px;
  line-height: 26px;
  color: #000;
  font-weight: 500;
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background: #fff;
  top: 72px;
  height: 60px;
`;

const StyledBackButton = styled(ButtonBase)`
  margin-right: 10px;
`;

export const Header = () => {
  const history = useHistory();
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  return (
    <StyledAppBar position="fixed" color="default">
      <PageWrapper className="">
        <Toolbar disableGutters>
          <StyledBackButton onClick={history.goBack}>
            <Icon>arrow_back</Icon>
          </StyledBackButton>
          <TypographyBreadcrumbs variant="title">
            {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          </TypographyBreadcrumbs>
        </Toolbar>
      </PageWrapper>
    </StyledAppBar>
  );
};
