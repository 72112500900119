import { useEffect, useRef, useState } from 'react';
import { FormSpy, FormSpyRenderProps, useForm } from 'react-final-form';
import { diff } from 'deep-object-diff';
import { useUpdateVehicleFieldMutation } from '../../../services/api/upload-vehicle';
import { useParams } from 'react-router-dom';
import { Vehicle } from '../../../types/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle } from '../../../actions';
import { setSkipNextAutosave } from '../../../services/uploadVehicle/uploadVehicleSlice';
import { defaultSectionOrder } from '../../../services/api/vehicle-sections';
import { useIsAutomaticUpload } from '../hooks/useIsAutomaticUpload';

export const Autosave = () => {
  return <FormSpy subscription={{ values: true, dirty: true }} component={AutosaveComponent} />;
};

const AutosaveComponent = ({ values, dirty }: FormSpyRenderProps) => {
  const [formValues, setFormValues] = useState(values);
  const [updateVehicleMutation] = useUpdateVehicleFieldMutation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const isAutomaticUpload = useIsAutomaticUpload();

  const firstRun = useRef(true);
  const timeout = useRef<number | null>(null);

  const skipNextDiff = useSelector((state: any) => state.uploadVehicle.skipNextAutosave);

  const form = useForm();

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const valuesDiff = diff(formValues, values);
      setFormValues(values);

      if (skipNextDiff) {
        dispatch(setSkipNextAutosave(false));
        return;
      }

      if (!id || !dirty) {
        return;
      }

      if ('vin' in valuesDiff) {
        return;
      }

      const sectionNames = defaultSectionOrder.map(s => s[0]) as string[];

      const keys = Object.keys(valuesDiff);
      if (keys.some(k => sectionNames.includes(k))) {
        return;
      }

      if ('options' in valuesDiff) {
        if (!isAutomaticUpload) {
          delete valuesDiff.options;
        } else {
          const optionsToUpdate = Object.keys(valuesDiff.options as any)
            .map(key => {
              const option = values.options[key];
              if (!option) return;
              return {
                id: option.id,
                is_typical: option.is_typical
              };
            })
            .filter(Boolean);
          valuesDiff.options = optionsToUpdate;
        }
      }

      if (Object.keys(valuesDiff).length > 0) {
        if ('trim' in valuesDiff) {
          dispatch(modalsToggle('loaderModal'));
        }
        updateVehicleMutation({ payload: valuesDiff, id: Number(id) }).then(response => {
          if ('data' in response && 'trim' in valuesDiff && id) {
            const vehicle = (response as any).data.data as Vehicle;

            dispatch(setSkipNextAutosave(true));
            form.batch(() => {
              form.change('transmission', vehicle.transmission);
              form.change('drive_train', vehicle.drive_train);
              form.change('ext_color', vehicle.ext_color);
              form.change('engine', vehicle.engine);
              form.change('options', vehicle.options);
            });
            dispatch(modalsToggle('loaderModal'));
          }
        });
      }
    }, 500);
  }, [values]);

  return null;
};
