import { Checkbox, TextField } from '@material-ui/core';
import { Field, useField } from 'react-final-form';
import { FieldArray, useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { useIsAutomaticUpload } from '../../hooks/useIsAutomaticUpload';
import { VEHICLE_CREATION_DEFAULT_OPTIONS } from '../../../../constants/forms';
import { useAddVehicleOptionMutation, useChangeVehicleOptionMutation } from '../../../../services/api/upload-vehicle';
import { composeValidators, maxLength } from '../../../../utils/validators';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';
import { AddCheckboxIcon } from '../../icons/AddCheckboxIcon';
import { RedInfoIcon } from '../../icons/RedInfoIcon';
import { useRef } from 'react';

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 32px;
  padding-bottom: 12px;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
`;

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(320px, 1fr));
  grid-template-rows: auto;
  row-gap: 26px;
  column-gap: 8px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const EditingFieldWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  border-bottom: ${props => (props.hasError ? '1px solid rgba(175, 41, 48, 1)' : '1px solid rgba(204, 204, 204, 1)')};
  gap: 8px;
  align-items: center;
  position: relative;
`;

const FieldError = styled.div`
  position: absolute;
  display: flex;
  font-size: 12px;
  align-items: center;
  color: rgba(175, 41, 48, 1);
  gap: 8px;
  right: 0;
  bottom: -26px;
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`;

const AddButtonWrapper = styled.div<{ noMargin?: boolean }>`
  margin-left: ${props => (props.noMargin ? '0' : 'auto')};
  margin-top: 8px;
  cursor: pointer;
`;

const defaultOption = {
  is_typical: true,
  isEditing: true,
  option_name: ''
};

const maxLength20 = maxLength(20, 'Max. 25 characters');

type Props = {
  name: string;
};

export const VehicleEquipment = ({ name }: Props) => {
  return (
    <Wrapper>
      <Title>{'Optional Equipment'}</Title>
      <CheckboxGrid>
        <FieldArray name={name}>
          {({ fields }) =>
            fields.map(fieldName => {
              return <EquipmentCheckbox key={fieldName} fieldName={fieldName} />;
            })
          }
        </FieldArray>
      </CheckboxGrid>
    </Wrapper>
  );
};

export const ManualVehicleEquipment = () => {
  const isPresetOption = (v: string) => VEHICLE_CREATION_DEFAULT_OPTIONS.includes(v);

  return (
    <Wrapper>
      <FieldArray name={'options'}>
        {({ fields }) => {
          const hasCustomEquipment = !!fields.value.find(o => !isPresetOption(o.option_name));
          return (
            <>
              <Title>Preset Equipment</Title>
              <CheckboxGrid>
                {fields.map((fieldName, i) => {
                  if (!isPresetOption(fields.value[i].option_name)) return null;
                  return <EquipmentCheckbox isLast={i === fields.length! - 1} key={fieldName} fieldName={fieldName} />;
                })}
              </CheckboxGrid>
              <Title>
                <span>Custom Equipment</span>
                {!hasCustomEquipment && (
                  <AddButtonWrapper noMargin onClick={() => fields.push(defaultOption)}>
                    <AddCheckboxIcon />
                  </AddButtonWrapper>
                )}
              </Title>
              {/* <Button color="primary" variant="contained" onClick={() => fields.push(defaultOption)}>
                Add Equipment
              </Button> */}
              <CheckboxGrid>
                {fields.map((fieldName, i) => {
                  if (isPresetOption(fields.value[i].option_name)) return null;
                  return (
                    <EquipmentCheckbox
                      isCustom
                      isLast={i === fields.length! - 1}
                      key={fieldName}
                      fieldName={fieldName}
                    />
                  );
                })}
              </CheckboxGrid>
            </>
          );
        }}
      </FieldArray>
    </Wrapper>
  );
};

type EquipmentCheckboxProps = {
  fieldName: string;
  isLast?: boolean;
  isCustom?: boolean;
};

const EquipmentCheckbox = ({ fieldName, isLast, isCustom = false }: EquipmentCheckboxProps) => {
  const { fields } = useFieldArray(`options`);

  const isEditing = isLast && fields.length && fields.value[fields.length - 1].isEditing;

  if (isLast) {
    console.log(fieldName, isCustom, 'isLast');
  }
  const [updateOption] = useChangeVehicleOptionMutation();
  const isAutomaticUpload = useIsAutomaticUpload();

  const vehicleId = useGetVehicleId();

  const {
    input: { value: optionId }
  } = useField(`${fieldName}.id`);

  if (isEditing) {
    return <EditingEquipmentCheckbox fieldName={fieldName} />;
  }

  return (
    <CheckboxWrapper>
      <Field name={`${fieldName}.is_typical`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '12px', height: '12px' }}
            checked={props.input.value}
            onChange={e => {
              if (!isAutomaticUpload) {
                updateOption({ optionId, vehicleId, payload: { isTypical: e.target.checked } });
              }
              props.input.onChange(e.target.checked);
            }}
          />
        )}
      </Field>
      <Field name={`${fieldName}.option_name`}>{props => <CheckboxLabel>{props.input.value}</CheckboxLabel>}</Field>
      {isLast && isCustom && (
        <AddButtonWrapper noMargin onClick={() => fields.push(defaultOption)}>
          <AddCheckboxIcon />
        </AddButtonWrapper>
      )}
    </CheckboxWrapper>
  );
};

const EditingEquipmentCheckbox = ({ fieldName }: EquipmentCheckboxProps) => {
  const [addNewEquipment] = useAddVehicleOptionMutation();
  const vehicleId = useGetVehicleId();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { fields } = useFieldArray(`options`, { subscription: { value: true } });
  const { input: editingInput } = useField(`${fieldName}.isEditing`);
  const {
    input: { value: isSelected }
  } = useField(`${fieldName}.is_typical`);
  const {
    input: { value: label, onChange: changeLabel },
    meta
  } = useField(`${fieldName}.option_name`);
  const { input: idInput } = useField(`${fieldName}.id`);

  const submitCheckbox = async () => {
    inputRef.current?.blur();
    if (meta.error) {
      return;
    }
    if (!label) {
      return;
    }
    const parsedLabel = label.trim();
    editingInput.onChange(false);
    const response = await addNewEquipment({
      vehicleId,
      payload: {
        isTypical: isSelected,
        optionName: parsedLabel
      }
    });

    if ('data' in response) {
      changeLabel(parsedLabel);
      idInput.onChange(response.data.data.id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== 'Enter') return;
    if (!label || label.trim() === '') {
      const i = fields.value.length - 1;
      fields.remove(i);
    } else {
      submitCheckbox();
    }
  };

  const duplicateValidator = (inputLabel: string) => {
    if (!inputLabel) return;
    const parsedLabel = inputLabel.trim();
    const isUnique = !fields.value.slice(0, -1).find(v => v.option_name === parsedLabel);
    if (!isUnique) {
      return 'Duplicate value';
    }
  };

  const hasError = !!meta.error && !!meta.touched;

  return (
    <EditingFieldWrapper hasError={hasError}>
      <Field name={`${fieldName}.is_typical`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '12px', height: '12px' }}
            checked={props.input.value}
            onChange={e => props.input.onChange(e.target.checked)}
          />
        )}
      </Field>
      <Field validate={composeValidators(maxLength20, duplicateValidator)} name={`${fieldName}.option_name`}>
        {props => (
          <StyledTextField
            onKeyDown={handleKeyDown}
            InputProps={{
              disableUnderline: true
            }}
            inputRef={inputRef}
            {...props.meta}
            {...props.input}
          />
        )}
      </Field>

      <AddButtonWrapper onClick={submitCheckbox}>
        <AddCheckboxIcon />
      </AddButtonWrapper>
      {hasError && (
        <FieldError>
          <span>{meta.error}</span>
          <RedInfoIcon />
        </FieldError>
      )}
    </EditingFieldWrapper>
  );
};
