import React from 'react';
import { CircularProgress, Button, Grid } from '@material-ui/core';
import styled from 'styled-components';

const StyledButtonList = styled.div`
  display: flex;
  justify-content: ${props => (props.withPrevStep ? 'space-between' : 'flex-end')};
  flex: 1;
  border-top: 1px solid #dcdcdc;
  padding: 15px 60px;
  margin-top: 50px;
  @media (max-width: 600px) {
    padding: 15px 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: ${props => (props.noIndent ? '0' : '32px')};
`;

const StyledBackButton = styled(Button)`
  background: #cccccccc;
  color: #000;
  text-transform: none;

  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;

  height: 36px;
  border-radius: 10px;
`;

const StyledNextButton = styled(Button)`
  text-transform: none;

  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;

  border-radius: 10px;
  height: 36px;
`;

const stepControl = ({ noIndent = false, nextStep, prevStep, nextLabelText = 'Next' }) => (
  <ButtonWrapper noIndent={noIndent}>
    {prevStep && (
      <StyledBackButton variant="contained" fullWidth color="secondary" onClick={prevStep}>
        Back
      </StyledBackButton>
    )}
    {nextStep && (
      <StyledNextButton variant="contained" fullWidth color="primary" type="submit">
        {nextLabelText}
      </StyledNextButton>
    )}
  </ButtonWrapper>
);

export default stepControl;
