import React, { Component } from 'react';
import { ButtonBase } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { vehicles } from '../../../../../utils/api';

const StyledHeaderWrapper = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
  }
`;

const StyledNavigationButton = styled(ButtonBase)`
  && {
    margin-left: 35px;
    font-size: 16px;
    font-weight: 600;
    color: #005a96;
    text-transform: uppercase;
    text-decoration: underline;
  }
`;

class VehicleNavigationHeader extends Component {
  state = {
    nextVehicle: null,
    prevVehicle: null
  };

  componentDidMount() {
    return this.getSuggestions();
  }

  getSuggestions() {
    const { currentVehicle } = this.props;
    return vehicles.getAnotherActiveVehicles(currentVehicle.id).then(res => {
      this.setState({
        nextVehicle: res.data.data.vehicle_after,
        prevVehicle: res.data.data.vehicle_before
      });
    });
  }

  render() {
    const { prevVehicle, nextVehicle } = this.state;
    const { history } = this.props;

    return (
      <StyledHeaderWrapper>
        {prevVehicle && (
          <StyledNavigationButton
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(`/vehicles/${prevVehicle.id}/view`);
            }}
          >
            {'< Previous'}
          </StyledNavigationButton>
        )}
        {nextVehicle && (
          <StyledNavigationButton
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(`/vehicles/${nextVehicle.id}/view`);
            }}
          >
            {'Next >'}
          </StyledNavigationButton>
        )}
      </StyledHeaderWrapper>
    );
  }
}

export default withRouter(VehicleNavigationHeader);
