import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Select from 'react-select';
import { SectionDivider, SectionTitle } from '../../components';
import { listUsers, modalsToggle, awardBuyer, auctionVehiclesSetModal } from '../../../../../actions';
import { roles } from '../../../../../constants';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { AdminAwardBuyerModal } from "../../../../Admin/AuctionDetail/components";

const modalTitle = 'confirmAwardModal';
const chargeModalTitle = 'auctionDetailAwardBuyerModal';

const StyledRoundedBlock = styled.div`
  border-radius: 4px;
  background: #f5f5f5;
  padding: 8px;
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  padding: 0 32px 0 8px;
`;

const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'none',
    border: 'none',
    fontSize: '16px'
  })
};

const styles = {};

class AwardBuyerBlock extends Component {
  state = {
    buyerForAward: ''
  };

  componentDidMount() {
    const { fetchUsers, setModal, vehicle } = this.props;
    fetchUsers();
    setModal(vehicle);
  }

  handleChangeBuyer = item => {
    this.setState({ buyerForAward: item });
  };

  handleClickAward = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalTitle);
  };

  handleAward = () => {
    const { modalsToggle: toggle, setModal, vehicle } = this.props;
    const { buyerForAward } = this.state;
    const preSelectedBuyer = buyerForAward;
    setModal({ ...vehicle, preSelectedBuyer, predefinedAwardPrice: null });
    toggle(modalTitle);
    toggle(chargeModalTitle);
  };

  renderDropDown() {
    const { users, vehicle } = this.props;

    const uniqueBidders = vehicle.bids.reduce((acc, { bidder }) => {
      const isBidderAlreadyExists = acc.find(
        existingBidder => existingBidder.value === bidder.id
      );
      if (isBidderAlreadyExists) return acc;
      acc.push({
        value: bidder.id,
        label: `Bidder: ${bidder.dealership_name}`
      });
      return acc;
    }, []);

    const usersWithoutBidders = users.reduce((acc, user) => {
      const isBidderAlreadyExists = uniqueBidders.find(
        existingBidder => existingBidder.value === user.id
      );
      if (isBidderAlreadyExists) return acc;
      acc.push({
        value: user.id,
        label: user.dealership_name
      });
      return acc;
    }, []);

    return [...uniqueBidders, ...usersWithoutBidders];
  }

  render() {
    const { loading, currentVehicle, auctionVehicle } = this.props;
    const { buyerForAward } = this.state;

    return (
      <>
        <SectionTitle>Award Vehicle to Buyer</SectionTitle>
        <StyledRoundedBlock>
          <Grid container>
            <StyledGrid item xs={12} sm={8}>
              <Select
                options={this.renderDropDown()}
                value={buyerForAward}
                onChange={this.handleChangeBuyer}
                styles={selectStyles}
                placeholder="Enter / Select Buyer"
              />
            </StyledGrid>
            <Grid item xs={12} sm={4}>
              <Button
                onClick={this.handleClickAward}
                color="primary"
                variant="contained"
                fullWidth
                disabled={!buyerForAward || loading}
              >
                Award
              </Button>
            </Grid>
          </Grid>
        </StyledRoundedBlock>
        {(currentVehicle.bids || currentVehicle.vehicle_price) && (
        <ConfirmModal
          modalId={modalTitle}
          title={`You are about to award ${
            (buyerForAward || {}).label
          } the vehicle ${currentVehicle.year} ${currentVehicle.make} ${
            currentVehicle.model
          }?`}
          handleSubmit={() => {
            this.handleAward();
          }}
        />
        )}

        {auctionVehicle &&
          <AdminAwardBuyerModal
            initialValues={{ vehicle_id: currentVehicle.id }}
            vehicle={currentVehicle}
          />
        }
        <SectionDivider />
      </>
    );
  }
}

const mapStateToProps = state => ({
  users:
    state.users.items.filter(
      item =>
        item.role === roles.BUYER
    ) || [],
  loading: state.vehicles.loading,
  auctionVehicle: state.auctions.auctionVehiclesModal,
  currentVehicle: state.vehicles.selectedVehicle.data
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: params => dispatch(listUsers(params).request),
  modalsToggle: key => dispatch(modalsToggle(key)),
  setModal: data => dispatch(auctionVehiclesSetModal(data)),
  awardVehicleBuyer: data => dispatch(awardBuyer(data).request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AwardBuyerBlock));
