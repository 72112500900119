import React from 'react';
import { connect } from 'react-redux';
import ModalWrapper from '../../../components/ModalWrapper';
import InvoiceDetail from '../../../components/InvoiceDetail';

const InvoiceModal = ({ seller, invoiceData, invoiceDate }) => (
  <ModalWrapper
    title="InvoiceModal"
    showTitle={false}
    modalId="InvoiceModal"
    showSubmit={false}
  >
    <InvoiceDetail
      sellerData={seller}
      invoiceData={invoiceData}
      invoiceDate={invoiceDate}
    />
  </ModalWrapper>
);

const mapStateToProps = state => ({
  seller: state.user.user,
  invoiceData: state.financials.currentInvoice,
  invoiceDate: state.financials.currentInvoiceDate,
  loading: state.financials.loading
});

export default connect(mapStateToProps)(InvoiceModal);
