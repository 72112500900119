import { useDispatch, useSelector } from 'react-redux';
import { Vehicle } from '../../types/vehicle';
import styled from 'styled-components';
import { User } from '../../types/user';
import { getHighestBid, getMinBitAmount, getUserHighestBid, getVehicleTimer } from '../../utils/vehicle';
import { NotesSection } from '../Notes';
import { VEHICLE_STATUSES } from '../../constants';
import { IncreaseHighBid } from '../../pages/Buyer/BidsList/containers';
import FormattedAmount from '../FormattedAmount';
import Timer from '../Timer';
import { BidInfoLabel } from '../../pages/Buyer/BidsList/components/tableBlocks/BidInfoLabel';
import { InfoValue, Title, InfoWrapper } from './styled';
import { CurrentBidComponent } from '../Typography';
import { push } from 'connected-react-router';
import { initVehicleDetailsData } from '../../actions/vehicles';
import { useParams } from 'react-router-dom';
import { ReservePriceLabel } from '../ReservePriceMet';
import { Grid } from '@material-ui/core';
import { NotesIcon } from '../../pages/Admin/ViewVehicle/icons/NotesIcon';
import { Form } from 'react-final-form';
import { IncreaseAmount } from '../IncreaseAmount';
import { usePlaceBidMutation, usePlaceSidePanelBidMutation } from '../../services/api/my-bids';
import { IncreaseAmountMobile } from '../IncreaseAmount/mobile';
import { RoundedBidInfoLabel } from '../../pages/Buyer/BidsList/components/tableBlocks/RoundedBidInfoLabel';

type Props = { vehicle: Vehicle };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  gap: 8px;
  position: relative;
  background: rgba(249, 249, 249, 1);
  border-radius: 15px;
  padding: 4px;
`;

const VehicleImage = styled.div<{ imageUrl: string }>`
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 120px;
  border-radius: 20px;
  border: 0.5px solid rgba(210, 210, 210, 1)
  cursor: pointer;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

const VehicleInfoLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoLabel = styled.div`
  color: #757575;
  font-size: 10px;
  font-weight: 400;
`;

const BidInfoLabelWrapper = styled.div`
  position: absolute;
  right: -8px;
  top: -8px;
`;

const NotesWrapper = styled.div`
  background-color: rgba(57, 148, 222, 0.1);
  color: rgba(57, 148, 222, 1);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  height: 26px;
  flex: 1 1 100%;
  padding: 1px 0;
  justify-content: center;
  align-items: center;

  & * .notes-container {
    font-size: 10px;

    background: transparent;
    border: none;
  }

  & * .add-notes-container {
    font-size: 10px;

    background: transparent;
    border: none;
  }
`;

const NotesAndReserveWrapper = styled.div`
  display: flex;
  gap: 4px;
  & > div {
    flex: 1 1 100%;
  }
`;

export const VehicleSideCard = ({ vehicle }: Props) => {
  const dispatch = useDispatch() as any;
  const { vehicleId } = useParams<{ vehicleId?: string }>();

  const user: User = useSelector((state: any) => state.user?.user);

  const highestBid = getHighestBid(vehicle);
  const myBid = vehicle.my_bid;
  const vehicleTimer = getVehicleTimer(vehicle);

  const minBidAmount = getMinBitAmount(vehicle, user);

  const vehicleImageSrc = vehicle.images?.[0]?.url ?? '';
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

  const canPlaceBid = !!user && user.role === 'buyer' && vehicle.status === VEHICLE_STATUSES.ACTIVE;

  const handleOnClick = () => {
    if (vehicleId && Number(vehicleId) === vehicle.id) return;
    dispatch(push(`/vehicles/${vehicle.id}/view`));
    dispatch(initVehicleDetailsData(vehicle));
  };

  const VehicleValueInfo = () => (
    <VehicleInfoLabelWrapper>
      <InfoLabel>Book: </InfoLabel>
      <InfoValue size={10}>
        <FormattedAmount amount={vehicle.bluebook_value} />
      </InfoValue>
      <InfoLabel>/ </InfoLabel>
      <InfoLabel>miles: </InfoLabel>
      <InfoValue size={10}>
        <FormattedAmount prefix="" amount={vehicle.bluebook_mileage} />
      </InfoValue>
    </VehicleInfoLabelWrapper>
  );

  return (
    <Container>
      <RoundedBidInfoLabel item={vehicle} />
      {/* <BidInfoLabelWrapper>
        <BidInfoLabel id={vehicle.id} item={vehicle} labelType={'right'} />
      </BidInfoLabelWrapper> */}
      <VehicleImage imageUrl={vehicleImageSrc} onClick={handleOnClick} />
      <Title>{vehicleName}</Title>
      <VehicleValueInfo />
      <InfoWrapper>
        <Title>Current bid:</Title>
        <InfoValue>{myBid?.amount ? <CurrentBidComponent amount={highestBid.amount} /> : '-'}</InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoLabel>Time left:</InfoLabel>
        <InfoValue black>
          <Timer toDate={vehicleTimer} />
        </InfoValue>
      </InfoWrapper>
      <InfoWrapper>
        <InfoLabel>Your bid:</InfoLabel>
        <InfoValue black>{myBid?.amount ? <FormattedAmount amount={myBid.amount} /> : '-'}</InfoValue>
      </InfoWrapper>
      <NotesAndReserveWrapper>
        <ReservePriceLabel fitContent size="sm" activeVehicle={vehicle} />
        <NotesWrapper>
          <NotesSection Icon={NotesIcon} vehicle={vehicle} />
        </NotesWrapper>
      </NotesAndReserveWrapper>

      {canPlaceBid && <PlaceBidForm minAmount={minBidAmount} id={vehicle.id} />}
    </Container>
  );
};

const PlaceBidForm = ({ minAmount, id }: { minAmount: number; id: number }) => {
  const [placeBid] = usePlaceSidePanelBidMutation();

  const onSubmit = (values: { amount: string }) => {
    placeBid({ vehicle_id: id, amount: Number(minAmount) });
  };

  return (
    <Form onSubmit={onSubmit} name={`place-side-bid-${id}`}>
      {() => <IncreaseAmountMobile type="bid" initialAmount={minAmount} />}
    </Form>
  );
};
