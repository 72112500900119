import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency, normalizeFormattedField } from 'utils';
import { Button, Icon, Popover } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';
import { Value } from 'sass';
import { FormFormattedField } from '../Form';

type Props = {
  type: 'bid' | 'offer';
  initialAmount: number;
  isProxyAmount: boolean;
};

const Wrapper = styled.div`
  display: flex;
  padding: 30px 16px 16px 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 30px;
  background: rgba(249, 249, 249, 1);
`;

const ErrorWrapper = styled.div`
  border-radius: 4px;
  background: rgba(175, 41, 48, 0.1);
  color: rgba(175, 41, 48, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 2px 0 2px 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 18.75px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: rgba(33, 33, 33, 1);
`;

const FromAmount = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: rgba(135, 135, 135, 1);
`;

const HowItWorks = styled.div`
  text-decoration: underline;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(135, 135, 135, 1);
`;

const StyledPopoverBody = styled.div`
  padding: 5px 10px;
  max-width: 100%;
  width: 290px;
  font-size: 14px;
`;

const PlusMinusButton = styled.button<{ disabled?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100%;
  background: ${props => (props.disabled ? 'rgba(217, 217, 217, 1)' : '#000')};

  & > span {
    color: #fff;
  }
`;

const BiddingWrapper = styled.div`
  border: 1px solid rgba(204, 204, 204, 0.8);
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px 2px 16px;
`;

const SubmitButton = styled(Button)`
  min-width: 125px;
  height: 44px;
  border-radius: 20px;
`;

const isFloored = (value: number) => {
  const flooredNumber = Math.floor(value / 100) * 100;
  return flooredNumber !== value;
};

const validate = (value: number) => {
  if (value == null) return;

  if (isFloored(Number(value))) {
    return 'Use bidding increments of min bid amount';
  }
};

export const IncreaseAmount = ({ initialAmount, type, isProxyAmount }: Props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const TextMap = {
    bid: {
      title: isProxyAmount ? 'Increase Max Bid' : 'Enter Max Bid',
      howItWorks: 'How Max Bid Works'
    },
    offer: {
      title: isProxyAmount ? 'Increase Max Offer' : 'Enter Max Offer',
      howItWorks: 'How Max Offer Works'
    }
  };

  const openPopover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorEl(e.target);
  const closePopover = () => setAnchorEl(null);

  const { title, howItWorks } = TextMap[type];

  const form = useForm();
  const formState = form.getState();

  return (
    <Field name="amount" parse={normalizeFormattedField} validate={validate}>
      {props => {
        const handleIncrement = () => {
          if (!props.input.value) {
            props.input.onChange(initialAmount + 100);
          } else {
            props.input.onChange(Number(props.input.value) + 100);
          }
        };

        const handleDecrement = () => props.input.onChange(Number(props.input.value) - 100);

        return (
          <Wrapper>
            <HeaderRow>
              <Header>
                {title} <FromAmount>{`(from ${formatCurrency(initialAmount)})`}</FromAmount>
              </Header>
              <HowItWorks onClick={openPopover}>{howItWorks}</HowItWorks>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <StyledPopoverBody>
                  <p>Your max bid cannot be seen by the seller.</p>
                  <p>
                    {`By entering your Max Bid, the system will auto bid for you in $${initialAmount}
              increments up to your max bid (while the timer is active)`}
                    .
                  </p>
                </StyledPopoverBody>
              </Popover>
            </HeaderRow>
            <BiddingWrapper>
              <FormFormattedField
                placeholder={`${formatCurrency(initialAmount + 100)} min`}
                input={{
                  ...props.input,
                  value: props.meta.dirty || formState.submitSucceeded ? props.input.value : ''
                }}
                meta={{ ...props.meta, error: '' }}
                prefix="$"
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                thousandSeparator
              />
              <PlusMinusButton
                type="button"
                onClick={handleDecrement}
                disabled={props.input.value <= 100 || props.input.value <= initialAmount}
              >
                <Icon>remove</Icon>
              </PlusMinusButton>
              <PlusMinusButton type="button" onClick={handleIncrement}>
                <Icon>add</Icon>
              </PlusMinusButton>
              <SubmitButton
                onClick={form.submit}
                disabled={!props.input.value}
                type="submit"
                color="primary"
                variant="contained"
              >
                Submit
              </SubmitButton>
            </BiddingWrapper>
            {props.meta.error && (
              <ErrorWrapper>
                <span>{props.meta.error}</span>
              </ErrorWrapper>
            )}
          </Wrapper>
        );
      }}
    </Field>
  );
};
