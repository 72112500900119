import { ButtonBase, Icon } from '@material-ui/core';
import { PageHeader } from '../../../../components';
import CounterTabs from './CounterTabs';

const Header = () => (
  <PageHeader>
    <CounterTabs />
    <span className="flex-grow" />
    <ButtonBase onClick={() => {}}>
      <Icon>refresh</Icon>
    </ButtonBase>
  </PageHeader>
);

export default Header;
