import React from 'react';
import { Typography, Icon, ButtonBase } from '@material-ui/core';
import styled from 'styled-components';
import { PageWrapper, PageHeader } from '../../../../components';

const StyledTypography = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
  border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
`;

const StyledLogoutText = styled(StyledTypography)`
  padding: 20px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 8px;
  }
`;

const StyledPageWrapper = styled(PageWrapper)`
  display: flex;
  width: 100%;
  @media (max-width: 560px) {
    flex-direction: column;
    > button {
      flex: 1;
      h2 {
        width: 100%;
      }
    }
  }
`;

const Header = ({ tabs, currentTab, onClick }) => (
  <PageHeader>
    <StyledPageWrapper>
      {tabs.map((tab, index) => (
        <ButtonBase onClick={() => onClick(tab.name)} key={index}>
          <StyledTypography variant="title" isActive={currentTab === tab.name}>
            {tab.title}
          </StyledTypography>
        </ButtonBase>
      ))}
      <span className="flex-grow" />
      <ButtonBase
        onClick={() => {
          localStorage.clear();
          window.location.href = '/';
        }}
      >
        <StyledLogoutText variant="title">
          Log Out
          <Icon>exit_to_app</Icon>
        </StyledLogoutText>
      </ButtonBase>
    </StyledPageWrapper>
  </PageHeader>
);

export default Header;
