/* eslint-disable no-else-return */
import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { buyerAcceptCounter, modalsToggle } from '../../../../../actions';
import SectionTitle from '../../components/SectionTitle';
import ModalWrapper from '../../../../../components/ModalWrapper';

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
  }
`;

const SectionTitleWithStatus = styled(SectionTitle)`
  span {
    color: #e47e00;
  }
`;

const StyledButton = styled(Button)`
  && {
    max-width: 160px;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const ConfirmCounterModal = ({
  amount,
  currentVehicle,
  acceptCounter,
  vehicleId,
  closeModal
}) => (
  <ModalWrapper
    title="Accept Counter"
    handleSubmit={() => {
      acceptCounter(vehicleId);
      closeModal();
    }}
    modalId="acceptCounterModal"
    submitText="Accept"
  >
    <Typography variant="title">
      By clicking accept, you agree to purchase the vehicle
      {` ${currentVehicle.year} ${currentVehicle.make} ${
        currentVehicle.model
      } `}
      for the amount of{' '}
      <NumberFormat
        displayType="text"
        prefix="$"
        value={amount}
        thousandSeparator
      />
      .
    </Typography>
  </ModalWrapper>
);

const CounteredComponent = ({
  amount,
  currentVehicle,
  acceptCounter,
  vehicleId,
  toggleModal
}) => (
  <Grid container justify="space-between" alignItems="center">
    <div>
      <SectionTitleWithStatus variant="title">
        Bid Status — <span>Countered</span>
      </SectionTitleWithStatus>
      <StyledDescription>
        Countered Amount —{' '}
        <NumberFormat
          displayType="text"
          prefix="$"
          value={amount}
          thousandSeparator
        />
      </StyledDescription>
    </div>
    <ButtonWrapper>
      <StyledButton
        color="primary"
        variant="contained"
        onClick={() => {
          toggleModal('acceptCounterModal');
        }}
      >
        Accept
      </StyledButton>
    </ButtonWrapper>
    <ConfirmCounterModal
      amount={amount}
      acceptCounter={acceptCounter}
      vehicleId={vehicleId}
      currentVehicle={currentVehicle}
      closeModal={() => {
        toggleModal('acceptCounterModal');
      }}
    />
  </Grid>
);

export default connect(
  null,
  dispatch => ({
    acceptCounter: vehicleId =>
      dispatch(buyerAcceptCounter({ vehicle_id: vehicleId }).request),
    toggleModal: modalId => dispatch(modalsToggle(modalId))
  })
)(CounteredComponent);
