import styled from 'styled-components';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { DeleteImageButton } from '../DeleteImageButton';
import { useDndContext, useDroppable } from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import {
  FieldLocalImage,
  removeImageField,
  setFieldImageFile
} from '../../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { ChangeEvent } from 'react';
import { DamagedCheckbox } from './DamagedCheckbox';
import { useGetSubSectionConfig } from '../../../hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../../../services/api/vehicle-sections/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const ImageWithLabel = styled.div<{ isDragging?: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  opacity: ${props => (props.isDragging ? '0.7' : '1')};
`;

const ImageWrapper = styled.label<{ isOver: boolean }>`
  height: auto;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 1);
  justify-content: center;
  opacity: ${props => (props.isOver ? '0.5' : '1')};

  @media (min-width: 1024px) {
    height: 108px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const PlaceholderWrapper = styled.div`
  min-height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 108px;
  object-fit: cover;

  @media (min-width: 1024px) {
    height: 100%;
    object-fit: contain;
  }
`;

const Label = styled.span`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
`;

type Props = {
  formImage: FieldLocalImage;
  sectionName: string;
  i: number;
};

export const FormImageField = ({ formImage, sectionName, i }: Props) => {
  const { attributes, listeners, setNodeRef: sortableSetRef, transform, transition, isDragging } = useSortable({
    id: `FormImage-${i}`,
    data: {
      type: 'form',
      file: formImage.file,
      url: formImage.url
    }
  });

  const dndContext = useDndContext();

  const { setNodeRef, isOver } = useDroppable({
    id: sectionName + '//' + i,
    disabled: !!formImage.url || (!!dndContext.active && dndContext.active.id.toString().startsWith('FormImage'))
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [name, subSectionName] = sectionName.split('.');

  const config = useGetSubSectionConfig(name as keyof SectionConfig, subSectionName);

  const dispatch = useDispatch();

  const setImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.[0]) {
      dispatch(setFieldImageFile({ index: i, file: e.target?.files?.[0] }));
    }
  };

  const handleDelete = () => {
    dispatch(removeImageField(i));
  };

  const hasImage = formImage.file || formImage.url;

  return (
    <ImageWithLabel isDragging={isDragging} ref={sortableSetRef} style={style} {...attributes} {...listeners}>
      <ImageContainer ref={setNodeRef}>
        <ImageWrapper isOver={isOver} htmlFor={`${sectionName}-${i}-upload-dialog`}>
          {hasImage ? (
            <StyledImage src={formImage.file ? URL.createObjectURL(formImage.file) : formImage.url} />
          ) : (
            <PlaceholderWrapper>
              <ImagePlaceholderIcon />
            </PlaceholderWrapper>
          )}
          <input id={`${sectionName}-${i}-upload-dialog`} style={{ display: 'none' }} type="file" onChange={setImage} />
        </ImageWrapper>
        {hasImage && <DeleteImageButton onClick={handleDelete} />}
      </ImageContainer>
      <Label>{formImage.label}</Label>
      {hasImage && config?.imageConfig?.withDamageCheckbox && <DamagedCheckbox formImage={formImage} i={i} />}
    </ImageWithLabel>
  );
};
