import { TextField } from '@material-ui/core';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import Select, { Creatable } from 'react-select';
import { FieldRenderProps } from 'react-final-form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledLabel = styled.label<{ isReadonly?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  color: #757575;
  line-height: 1;
`;

const getStyledSelect = (SelectComponent: any) => styled(SelectComponent)`
  & .relist-select__control {
    border: none;
    border-radius: 0;
    background: #f5f5f5;
    height: 43px;
  }

  & .relist-select__control:hover {
    border: none;
    border-radius: 0;
  }

  & .relist-select__value-container {
    padding: 12px 6px;
    position: static;
    font-weight: 500;
    color: #000;
    font-size: 16px;

    & > div {
      padding: 0;
      margin: 0;
    }
  }

  & .relist-select__input {
    position: static;
    margin: 0;
  }

  & .relist-select__control--is-focused {
    border: none;
    border-bottom: 1px solid #225a91;
    border-radius: 0;
    box-shadow: none;
  }

  & .relist-select__dropdown-indicator {
    padding: 6px;
  }

  & .relist-select__control {
    min-height: unset;
  }

  & .relist-select__menu-list {
    padding: 4px 0;
  }

  & .relist-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .relist-select__option--is-selected {
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.14) !important;
    color: inherit !important;
  }

  & .relist-select__indicator-separator {
    display: none;
  }

  & .relist-select__placeholder {
    margin: 0;
  }

  & .relist-select__option--is-selected 
`;

type Props = FieldRenderProps<{ value: string | number; label: string }> & {
  isCreatable?: boolean;
  options: Array<{ value: string | number; label: string }>;
};

export const RelistInputField = ({ label, input, meta, options, isCreatable }: Props) => {
  const { value, onChange } = input ?? {};
  const StyledSelect = isCreatable ? getStyledSelect(Creatable) : getStyledSelect(Select);
  console.log(value, options, 'options')
  return (
    <Wrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect onChange={onChange} value={value} options={options} isClearable={isCreatable} classNamePrefix="relist-select" />
    </Wrapper>
  );
};
