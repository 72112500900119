import React, { Component } from 'react';
import { Grid, Typography, MenuItem } from '@material-ui/core';

import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { TimePicker } from 'material-ui-pickers';
import moment from 'moment';
import ChipInput from 'material-ui-chip-input';

import ModalWrapper from '../ModalWrapper';
import FormTextField from '../Form/FormTextField';
import FormSelect from '../Form/FormSelect';
import FormError from '../Form/FormError';

import { dayOfWeek, MINUTES, SECONDS } from '../../constants';
import {
  modalsToggle,
  addLocation,
  snackShow,
  locationsUnsetSuccess
} from '../../actions';

const validate = values => {
  const errors = {};
  [
    'name',
    'regions',
    'startDay',
    'timeHours',
    'timeMinutes',
    'timeSeconds',
    'endTimeMinutes',
    'endTimeSeconds'
  ].forEach(item => {
    if (!Object.prototype.hasOwnProperty.call(values, item)) {
      errors[item] = 'Required';
    }
  });
  return errors;
};

const formName = 'LocationCreateModal';

const TimePickerField = ({ input: { onChange, value }, ...custom }) => (
  <TimePicker
    label=""
    value={value || moment().format()}
    onChange={onChange}
    {...custom}
  />
);

const FieldChipInput = inputProps => (
  <ChipInput {...inputProps} onChange={inputProps.input.onChange} />
);

class LocationCreateModal extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.created) {
      const { toggleModal, showSnack, resetForm, unsetSuccess } = this.props;
      toggleModal(formName);
      resetForm(formName);
      showSnack({ message: 'Location created' });
      unsetSuccess();
    }
  }

  submit = values => {
    const { createLocation } = this.props;
    createLocation(values);
  };

  render() {
    const { handleSubmit, submitting, formSubmitting, formError } = this.props;
    return (
      <ModalWrapper
        title="Create New Location"
        handleSubmit={handleSubmit(this.submit)}
        submitting={submitting || formSubmitting}
        modalId="newLocationModal"
      >
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={16} alignItems="center">
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Location
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Field
                name="name"
                component={FormTextField}
                margin="dense"
                placeholder="Enter new location name"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Region
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              {/* <Field
                name="regions"
                component={FormTextField}
                margin="dense"
                placeholder="Enter new region"
                type="text"
                fullWidth
              /> */}
              <Field
                name="regions"
                component={FieldChipInput}
                margin="dense"
                placeholder="Enter new region"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Day
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Field
                name="startDay"
                label="Day of week"
                component={FormSelect}
                margin="dense"
                placeholder="Prefered day of auction"
                fullWidth
              >
                {dayOfWeek.map((item, i) => (
                  <MenuItem key={i} value={i}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Time
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Field
                name="time"
                component={TimePickerField}
                margin="dense"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Auction End Time Spacing
              </Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                name="endTimeMinutes"
                label="Min"
                component={FormSelect}
                margin="dense"
                fullWidth
              >
                {MINUTES.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                name="endTimeSeconds"
                label="Sec"
                component={FormSelect}
                margin="dense"
                fullWidth
              >
                {SECONDS.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} lg={4} />
            <Grid item xs={12} lg={4}>
              <Typography align="right" variant="body1">
                Final Auction Shuffle
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Field
                name="shuffleDay"
                component={FormSelect}
                margin="dense"
                placeholder="Day"
                fullWidth
              >
                {dayOfWeek.map((item, i) => (
                  <MenuItem key={i} value={i}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Field
                name="shuffleTime"
                component={TimePickerField}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
          {formError && <FormError msg={formError} />}
        </form>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => ({
  formSubmitting: state.locations.submitting,
  formError: state.locations.error,
  created: state.locations.success
});

const mapDispatchToProps = dispatch => ({
  toggleModal: key => dispatch(modalsToggle(key)),
  createLocation: data => dispatch(addLocation(data).request),
  showSnack: message => dispatch(snackShow(message)),
  resetForm: () => dispatch(reset(formName)),
  unsetSuccess: () => dispatch(locationsUnsetSuccess())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: formName,
    validate
  })(LocationCreateModal)
);
