import React from 'react';
import { Paper, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import { FormTextField, FormError } from '../../../components/Form';
import { snackShow } from '../../../actions';
import {
  maxLength,
  minLength,
  required,
  isSame,
  passwordRegExp
} from '../../../utils/validators';
import { user } from '../../../utils/api';

const StyledPaper = styled(Paper)`
  padding: 20px;
  width: 400px;
  margin: 15px auto;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const passwordFieldValidation = [
  required,
  minLength(8),
  maxLength(25),
  passwordRegExp
];

const confirmPasswordValidation = [required, isSame('password')];

const submitForgotPasswordForm = (values, dispatch, props) => {
  const { token } = props.match.params || {};
  return user
    .resetPassword({ ...values, token })
    .then(() => {
      dispatch(
        snackShow({
          message:
            'Your password has been reset successfully! You are welcome to login now.'
        })
      );
      dispatch(push('/login'));
    })
    .catch(({ response }) => {
      const errors = response.data.data;
      const { message } = response.data;
      if (errors && errors.length) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        throw new SubmissionError(validationErrors);
      }
      throw new SubmissionError({
        _error: message
      });
    });
};

const resetPasswordForm = ({ handleSubmit, error }) => (
  <StyledPaper>
    <StyledForm onSubmit={handleSubmit(submitForgotPasswordForm)}>
      <Typography gutterBottom variant="headline">
        Create New Password
      </Typography>
      <Field
        name="password"
        label="Password"
        type="password"
        fullWidth
        component={FormTextField}
        validate={passwordFieldValidation}
      />
      <Field
        name="password_confirmation"
        label="Password confirmation"
        type="password"
        fullWidth
        component={FormTextField}
        validate={confirmPasswordValidation}
      />
      <StyledButton variant="raised" color="primary" type="submit" fullWidth>
        Reset
      </StyledButton>
      {error && <FormError msg={error} />}
    </StyledForm>
  </StyledPaper>
);

export default reduxForm({
  form: 'resetPasswordForm'
})(resetPasswordForm);
