import styled from 'styled-components';

const FormWrapper = styled.div`
  width: 100%;
  padding: 0 60px;
  @media (max-width: 600px) {
    padding: 0 28px;
  }
`;

export default FormWrapper;
