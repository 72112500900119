import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled('div')`
  && {
    color: #616161;
    border: solid 1px #0c4376;
    border-radius: 8px;
    padding: 10px;
    background: #0c43760d;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export function IconInfo(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z" />
      <path d="M12 16v-4M12 8h.01" />
    </svg>
  );
}

const AlertIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M11.4463 17H13.4463V11H11.4463V17ZM12.4463 9C12.7296 9 12.9671 8.90417 13.1588 8.7125C13.3505 8.52083 13.4463 8.28333 13.4463 8C13.4463 7.71667 13.3505 7.47917 13.1588 7.2875C12.9671 7.09583 12.7296 7 12.4463 7C12.163 7 11.9255 7.09583 11.7338 7.2875C11.5421 7.47917 11.4463 7.71667 11.4463 8C11.4463 8.28333 11.5421 8.52083 11.7338 8.7125C11.9255 8.90417 12.163 9 12.4463 9ZM12.4463 22C11.063 22 9.76296 21.7375 8.54629 21.2125C7.32962 20.6875 6.27129 19.975 5.37129 19.075C4.47129 18.175 3.75879 17.1167 3.23379 15.9C2.70879 14.6833 2.44629 13.3833 2.44629 12C2.44629 10.6167 2.70879 9.31667 3.23379 8.1C3.75879 6.88333 4.47129 5.825 5.37129 4.925C6.27129 4.025 7.32962 3.3125 8.54629 2.7875C9.76296 2.2625 11.063 2 12.4463 2C13.8296 2 15.1296 2.2625 16.3463 2.7875C17.563 3.3125 18.6213 4.025 19.5213 4.925C20.4213 5.825 21.1338 6.88333 21.6588 8.1C22.1838 9.31667 22.4463 10.6167 22.4463 12C22.4463 13.3833 22.1838 14.6833 21.6588 15.9C21.1338 17.1167 20.4213 18.175 19.5213 19.075C18.6213 19.975 17.563 20.6875 16.3463 21.2125C15.1296 21.7375 13.8296 22 12.4463 22ZM12.4463 20C14.6796 20 16.5713 19.225 18.1213 17.675C19.6713 16.125 20.4463 14.2333 20.4463 12C20.4463 9.76667 19.6713 7.875 18.1213 6.325C16.5713 4.775 14.6796 4 12.4463 4C10.213 4 8.32129 4.775 6.77129 6.325C5.22129 7.875 4.44629 9.76667 4.44629 12C4.44629 14.2333 5.22129 16.125 6.77129 17.675C8.32129 19.225 10.213 20 12.4463 20Z"
      fill="#FF0000"
    />
  </svg>
);

const InfoAlert = ({ children }) => (
  <StyledDiv>
    <AlertIcon />
    {children}
  </StyledDiv>
);

export default InfoAlert;
