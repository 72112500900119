import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';

const Wrapper = styled.div`
  display: flex;
  padding: 14px 24px 22px 14px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  display: flex;
  gap: 14px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
`;

const TermsText = styled.div`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  display: inline;
  padding: 0 20px;
`;

const HighlightedText = styled.span<{ isGuaranteed?: boolean }>`
  font-size: 12px;
  display: inline;
  font-weight: 700;
  line-height: 20px;
  color: ${props => (props.isGuaranteed ? 'rgba(69, 155, 74, 1)' : 'rgba(175, 41, 48, 1)')};
`;

export const SaleTerms = () => {
  const vehicle = useViewedVehicle();

  if (!vehicle) return null;

  return (
    <Wrapper>
      <Title>Vehicle Sale Terms</Title>
      {vehicle.is_guaranteed ? (
        <TermsText>
          Vehicle is <HighlightedText isGuaranteed>Green Light</HighlightedText>. Engine, transmission and rear end
          guaranteed for 24hrs (Vehicles being transported over 300 miles are guaranteed until 5pm on the Wednesday
          following the auction).
        </TermsText>
      ) : (
        <TermsText>
          Vehicle is being sold <HighlightedText>AS-IS:</HighlightedText> Buyer is responsible for viewing vehicle prior
          to bidding
        </TermsText>
      )}
    </Wrapper>
  );
};
