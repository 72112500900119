import { Field, Form } from 'react-final-form';
import { FormFormattedField, FormInput, ModalWrapper } from '../../../../components';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { updateVehicle } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { cutTextElipsis, normalizeFormattedField } from '../../../../utils';
import { salesAdapter, salesApiSlice as api } from '../../../../services/api/sales';
import { useMemo, useState } from 'react';
import { ConfirmDropReserveModal } from './ConfirmDropReservePriceModal';
import moment from 'moment';
import { validateReservePrice } from '../../../../utils/validators';

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;

  & > * {
    flex: 1 1 100%;
  }
`;

const StyledLabel = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
`;

const StyledField = styled(Field)`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
  height: 60px;
  div {
    flex-grow: 1;
  }
`;

const InfoText = styled.span`
  color: #757575;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
`;

export const DropReservePriceModal = ({ currentVehicle }: any) => {
  const [dropReserveModalOpen, setDropReserveModalOpen] = useState(false);
  const dispatch = useDispatch();

  const modalName = `dropReserveModal-${currentVehicle.id}`;

  const modalTitle = `Drop Reserve for ${cutTextElipsis(
    `${currentVehicle.year} ${currentVehicle.make} ${currentVehicle.model}`,
    20
  )}`;

  const { filters, page, perPage } = useSelector((state: any) => state.mySales);

  const shouldRenderConfirmModal = (reserve_price: string, current_bid: string) => {
    const normalizedReservePrice = Number(normalizeFormattedField(reserve_price ?? ''));
    const normalizedStartingBid = Number(normalizeFormattedField(current_bid ?? ''));
    return normalizedReservePrice <= normalizedStartingBid;
  };

  const submitForm = (values: any) => {
    const { bids, highestBid, highest_bid, current_bid, awarded_user, auction, ...vehicle } = values;

    dispatch(
      updateVehicle({
        ...vehicle,
        reserve_price: normalizeFormattedField(values.reserve_price ?? ''),
        starting_bid: normalizeFormattedField(values.starting_bid ?? ''),
        notes: values.notes ?? '',
        cost: normalizeFormattedField(values.cost ?? ''),
        stock: values.stock ?? '',
        updateOptions: false,
        updateText: 'Vehicle updated',
        redirect: false
      }).request
    );

    dispatch(
      // @ts-ignore
      api.util.updateQueryData(
        'getMySales',
        {
          limit: perPage,
          offset: page,
          ...filters
        },
        draft => {
          salesAdapter.updateOne(draft.rows, {
            id: vehicle.id,
            changes: {
              reserve_price: values.reserve_price ? normalizeFormattedField(values.reserve_price ?? '') : null
            }
          });
        }
      )
    );

    setDropReserveModalOpen(false);
  };

  const handleSubmitButtonClick = (values: any) => {
    const renderConfirmModal = shouldRenderConfirmModal(values.reserve_price, values.current_bid);
    if (!renderConfirmModal) {
      return submitForm(values);
    }
    setDropReserveModalOpen(true);
  };

  const initialValues = useMemo(() => ({
    ...currentVehicle,
    id: currentVehicle.id,
    reserve_price: currentVehicle.reserve_price,
    current_bid: currentVehicle.highest_bid?.amount ?? 0,
    starting_bid: currentVehicle.starting_bid
  }), [currentVehicle]);

  const vehicleReservedPriceMet = initialValues?.reserve_price_met;
  const vehicleTimeEnded = initialValues?.date_end && moment(initialValues.date_end).isBefore(moment());

  return (
    <Form onSubmit={submitForm} initialValues={initialValues} keepDirtyOnReinitialize>
      {({ handleSubmit, submitting, values }) => {
        return (
          <form id={modalName} onSubmit={handleSubmit}>
            <ModalWrapper
              // @ts-ignore
              title={modalTitle}
              submitting={submitting}
              handleSubmit={() => handleSubmitButtonClick(values)}
              modalId={modalName}
              // cancelAdditionalAction={reset}
              className="edit-vehicle-bid-modal"
              actionsClassName="edit-vehicle-bid-modal-actions"
              submitText={'Save'}
              cancelText={'Cancel'}
            >
              <Grid container>
                <StyledGrid item xs={12}>
                  <StyledLabel>Current Bid</StyledLabel>
                  <StyledField
                    name="current_bid"
                    component={FormFormattedField}
                    placeholder="100$"
                    // @ts-ignore
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale
                    prefix="$"
                    customInput={FormInput}
                    normalize={normalizeFormattedField}
                    disableUnderline
                    disabled={true}
                  />
                </StyledGrid>
                <StyledGrid item xs={12}>
                  <StyledLabel>Reserve price</StyledLabel>
                  <StyledField
                    name="reserve_price"
                    component={FormFormattedField}
                    placeholder=""
                    // @ts-ignore
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale
                    prefix="$"
                    customInput={FormInput}
                    disableUnderline
                    normalize={normalizeFormattedField}
                    validate={validateReservePrice}
                    disabled={vehicleTimeEnded || vehicleReservedPriceMet}
                  />
                </StyledGrid>
                <InfoText>
                  You’re dropping your reserve and the car will be sold. Buyers will see reserve is met. Any changes in
                  the final 5 minutes resets the timer to 5 minutes
                </InfoText>
              </Grid>
            </ModalWrapper>
            <ConfirmDropReserveModal
              isOpen={dropReserveModalOpen}
              closeModal={() => setDropReserveModalOpen(false)}
              buttonsDisabled={submitting}
              handleSubmit={handleSubmit}
            />
          </form>
        );
      }}
    </Form>
  );
};
