import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import moment from 'moment';
import { getFormValues } from 'redux-form';
import {
  listAuctions,
  addAuction,
  getCurrentAuction,
  getAuctionById,
  listAuctionVehicles,
  exportSellerReport,
  exportBuyerReport,
  filterAuctions,
  getNextAuction,
  snackShow
} from '../actions';
import {
  LIST_AUCTIONS_REQUEST,
  CREATE_AUCTION_REQUEST,
  AUCTIONS_GET_CURRENT_REQUEST,
  AUCTIONS_GET_BY_ID_REQUEST,
  LIST_AUCTION_VEHICLES_REQUEST,
  AUCTION_EXPORT_SELLER_REPORT_REQUEST,
  AUCTION_EXPORT_BUYER_REPORT_REQUEST,
  FILTER_AUCTIONS_REQUEST,
  AUCTIONS_GET_NEXT_REQUEST
} from '../constants/actionTypes';
import { Api } from '../utils';
import {
  filterAuctionVehicles,
  FILTER_AUCTION_VEHICLES_REQUEST,
  AUCTION_DETAILS_BIDDERS_REQUEST,
  getAuctionDetailsBidders
} from '../actions/auctions';

const getFetchQuery = state => {
  const values = getFormValues('auctionsListFilterForm')(state) || {};
  return {
    ...values,
    searchDate: values.searchDate
      ? moment(values.searchDate)
          .set({ h: 12 })
          .utc()
          .format()
      : null
  };
};

function* fetchAuctions({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.auctions.fetchAuctions, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listAuctions(data).success);
  } catch (e) {
    yield put(listAuctions(e).failure);
  }
}

function* filterAuctionsSaga({ payload }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.auctions.fetchAuctions, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(filterAuctions(data).success);
  } catch (e) {
    yield put(filterAuctions(e).failure);
  }
}

function* fetchAuctionById({ payload }) {
  try {
    const { data } = yield call(Api.auctions.fetchAuctionById, payload);
    yield put(getAuctionById(data).success);
  } catch (e) {
    yield put(getAuctionById(e).failure);
  }
}

function* createAuction({ payload }) {
  try {
    const createData = {
      date_start: moment.utc(payload.date_start).format(),
      auction_type: payload.auctionType,
      location_id: 1 /* TODO link to locations on form via dropdown */
    };
    const { data } = yield call(Api.auctions.createAuction, createData);
    yield put(addAuction(data).success);
  } catch (e) {
    yield put(addAuction(e).failure);
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
  }
}

function* fetchCurrentAuction({ payload }) {
  try {
    const { data } = yield call(Api.auctions.fetchCurrentAuction, payload);
    yield put(getCurrentAuction(data).success);
  } catch (e) {
    yield put(getCurrentAuction(e).failure);
  }
}

function* fetchNextAuction({ payload }) {
  try {
    const { data } = yield call(Api.auctions.fetchNextAuction, payload);
    yield put(getNextAuction(data).success);
  } catch (e) {
    yield put(getNextAuction(e).failure);
  }
}

const getAuctionVehiclesQuery = state => {
  const filterValues = { ...getFormValues('auctionVehiclesFilterForm')(state) };

  const { bidded_buyer_id: biddedBuyer } = filterValues;

  if (biddedBuyer && biddedBuyer.value) {
    filterValues.bidded_buyer_id = biddedBuyer.value;
  }

  return filterValues;
};

function* filterAuctionVehiclesSaga({ payload }) {
  try {
    const query = yield select(getAuctionVehiclesQuery);
    const { data } = yield call(Api.auctions.fetchAuctionVehicles, payload.id, {
      ...query,
      ...payload.params
    });
    yield put(filterAuctionVehicles(data).success);
  } catch (e) {
    yield put(filterAuctionVehicles(e).failure);
  }
}

function* fetchAuctionVehicles({ payload }) {
  try {
    const query = yield select(getAuctionVehiclesQuery);
    const { data } = yield call(Api.auctions.fetchAuctionVehicles, payload.id, {
      ...query,
      ...payload.params
    });
    yield put(listAuctionVehicles(data).success);
  } catch (e) {
    yield put(listAuctionVehicles(e).failure);
  }
}

function* fetchAuctionReportSeller({ payload }) {
  try {
    const { data } = yield call(Api.auctions.fetchReportSeller, payload.id, payload.params);
    yield put(exportSellerReport(data).success);
    // console.log(data);
    yield window.open(data.data);
  } catch (e) {
    yield put(exportSellerReport(e).failure);
  }
}

function* fetchAuctionReportBuyer({ payload }) {
  try {
    const { data } = yield call(Api.auctions.fetchReportBuyer, payload.id, payload.params);
    yield put(exportBuyerReport(data).success);
    // console.log(data);
    yield window.open(data.data);
  } catch (e) {
    yield put(exportBuyerReport(e).failure);
  }
}

function* fetchAuctionDetailsBidders({ payload }) {
  try {
    const { id, ...params } = payload;
    const { data } = yield call(Api.auctions.fetchBiddersByAuctionId, id, params);
    yield put(getAuctionDetailsBidders(data.data).success);
  } catch (e) {
    yield put(getAuctionDetailsBidders(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_AUCTIONS_REQUEST, fetchAuctions),
    takeLatest(CREATE_AUCTION_REQUEST, createAuction),
    takeLatest(AUCTIONS_GET_CURRENT_REQUEST, fetchCurrentAuction),
    takeLatest(AUCTIONS_GET_NEXT_REQUEST, fetchNextAuction),
    takeLatest(AUCTIONS_GET_BY_ID_REQUEST, fetchAuctionById),
    takeLatest(LIST_AUCTION_VEHICLES_REQUEST, fetchAuctionVehicles),
    takeLatest(AUCTION_EXPORT_SELLER_REPORT_REQUEST, fetchAuctionReportSeller),
    takeLatest(AUCTION_EXPORT_BUYER_REPORT_REQUEST, fetchAuctionReportBuyer),
    takeLatest(FILTER_AUCTIONS_REQUEST, filterAuctionsSaga),
    takeLatest(FILTER_AUCTION_VEHICLES_REQUEST, filterAuctionVehiclesSaga),
    takeLatest(AUCTION_DETAILS_BIDDERS_REQUEST, fetchAuctionDetailsBidders)
  ]);
}
