import React from 'react';
import { ManageDocumentsSection } from '../sections';

const ManageDocumentsTab = ({ user }) => (
  <>
    <ManageDocumentsSection initialValues={user} />
  </>
);

export default ManageDocumentsTab;
