import React, { useState } from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';
import { GalleryIcon } from '../../icons/GalleryIcon';
import { RetakeCameraImage } from '../../icons/RetakeCameraImage';
import { CloseCamera } from '../../icons/CloseCamera';
import { Dialog } from '@material-ui/core';
import {
  closeCameraDialog,
  closeMobileImagesDialog,
  useOpenedCameraDialog
} from '../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import { ConfirmIcon } from '../../../../components/Icons/ConfirmIcon';
import { useHandleImageChange } from '../../hooks/useHandleImageChange';
import { generateRandomKey } from '../../../../utils';
import { LoaderModal } from '../../../../components';
import { openAssignImageDialog } from '../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { useFieldArray } from 'react-final-form-arrays';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const TakeImageButton = styled.div`
  width: 83px;
  height: 83px;
  opacity: 0.8;
  background: rgba(255, 255, 255, 1);
  border-radius: 100%;
  outline: 3px solid rgba(255, 255, 255, 1);
  outline-offset: 12px;
  padding: 13px;
`;

const Controls = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 22px;
`;

const UpperControls = styled(Controls)`
  top: 0;
  height: 130px;
`;

const LowerControls = styled(Controls)`
  bottom: 0;
  height: 156px;
`;

const IconWrapper = styled.label<{ whiteBackground?: boolean }>`
  background: ${props => (props.whiteBackground ? '#fff' : 'rgba(255, 255, 255, 0.5)')};
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

const ImageCount = styled.div`
  display: flex;
  border: 3px solid #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #fff;
  top: -20px;
  right: 25px;
  font-weight: 700;
`;

const StyledDialog = styled(Dialog)`
  .camera-upload {
    height: 100%;
    width: 100%;
    margin: 0;
    max-height: none;
  }
`;

const WarningMessage = styled.div`
  background: rgba(175, 41, 48, 1);
  position: absolute;
  border-radius: 30px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  padding: 1px 6px;
  color: #fff;
  right: 24px;
  top: -63px;
`;

function dataURItoFile(dataURI: string) {
  // Split the dataURI string to get the base64 data
  let arr = dataURI.split(',');

  // Extract the Base64 content
  let mime = arr?.[0].match(/:(.*?);/)?.[1];
  let bstr = atob(arr[1]);

  // Convert base64 to binary format
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], generateRandomKey(), { type: mime });
}

export const CameraImageUpload = () => {
  const modalId = useOpenedCameraDialog();

  return (
    <StyledDialog disableBackdropClick open={!!modalId} classes={{ paperScrollPaper: 'camera-upload' }}>
      {modalId && <CameraImageUploadContent modalId={modalId} />}
    </StyledDialog>
  );
};

const CameraImageUploadContent = ({ modalId }: { modalId: string }) => {
  const [fieldName, type] = (modalId || '').split('-');
  const isMulti = type === 'multi';

  const [hasTooManyImagesError, setTooManyImagesError] = useState(false);

  const webcamRef = React.useRef<Webcam>(null);
  const [images, setImages] = useState<(string | File)[]>([]);

  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: 4 / 3
  };

  const limit = isMulti ? 10 : 1;

  const capture = React.useCallback(() => {
    if (!webcamRef.current) return;
    if (images.length + 1 > limit) {
      setTooManyImagesError(true);
      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImages(curr => [...curr, imageSrc]);
    }
  }, [webcamRef, limit, images.length]);

  const handleAddGalleryImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files || [];
    if (images.length + files.length > limit) {
      setTooManyImagesError(true);
      return;
    }

    setImages(curr => [...curr, ...Array.from(files)]);
  };

  const retakeImage = () => {
    // setLastCapturedImage(null);
    setImages(curr => {
      curr.pop();
      return [...curr];
    });
  };

  const dispatch = useDispatch();

  const close = () => dispatch(closeCameraDialog());
  const closeMobileImages = () => dispatch(closeMobileImagesDialog());

  const { handleImageChange, imageLoading } = useHandleImageChange(fieldName);

  const handleSingleConfirm = async () => {
    if (images.length === 0) return;
    const file = typeof images[0] === 'string' ? dataURItoFile(images[0]) : images[0];
    await handleImageChange(file);
    close();
    closeMobileImages();
  };

  const { fields } = useFieldArray(`${fieldName}.images`, { subscription: { value: true } });

  const handleMultiConfirm = () => {
    if (images.length === 0) return;
    const files = images.map(image => (typeof image === 'string' ? dataURItoFile(image) : image));
    dispatch(openAssignImageDialog({ sectionId: fieldName, localImages: files, formImages: fields.value }));
    close();
    closeMobileImages();
  };

  return (
    <Wrapper>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        videoConstraints={videoConstraints}
      />
      <UpperControls>
        <IconWrapper onClick={close}>
          <CloseCamera />
        </IconWrapper>
        <IconWrapper onClick={retakeImage}>
          <RetakeCameraImage />
        </IconWrapper>
      </UpperControls>
      <LowerControls>
        <IconWrapper htmlFor={`${fieldName}-upload-gallery`}>
          <GalleryIcon />
          <input
            id={`${fieldName}-upload-gallery`}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            multiple={isMulti}
            onChange={handleAddGalleryImages}
          />
        </IconWrapper>
        <TakeImageButton onClick={capture} />
        {images.length > 0 ? (
          <IconWrapper whiteBackground onClick={isMulti ? handleMultiConfirm : handleSingleConfirm}>
            <ConfirmIcon />
          </IconWrapper>
        ) : (
          <div style={{ width: 52, height: 52 }} />
        )}
        {hasTooManyImagesError && <WarningMessage>{`You can upload up to ${limit} photos.`}</WarningMessage>}
        {images.length > 0 && <ImageCount>{images.length}</ImageCount>}
      </LowerControls>
      <LoaderModal modalId={fieldName} forceOpen={imageLoading} loaderText="Saving image..." />
    </Wrapper>
  );
};
