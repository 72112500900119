import styled from 'styled-components';
import { FormImageField } from './FormImageField';
import { useGetSectionConfigById } from '../../../hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../../../services/api/vehicle-sections/types';
import { useFormImages } from '../../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { SortableContext } from '@dnd-kit/sortable';

type Props = {
  name: string;
};

const Wrapper = styled.div`
  margin-top: 12px;
  display: grid;
  column-gap: 8px;
  row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, 102px);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 144px);
  }
`;

const Label = styled.span`
  margin-top: 8px;
  font-size: 14px;
  line-height: 16.41px;
  color: rgba(102, 102, 102, 1);
`;

const FormWrapper = styled.div`
display: flex
flex-direction: column;
gap: 12px
`;

export const FormImages = ({ name: sectionName }: Props) => {
  const [sectionId, subSectionId] = sectionName.split('.');
  const config = useGetSectionConfigById(sectionId as keyof SectionConfig);

  const formImages = useFormImages();

  if (!config) return null;

  return (
    <FormWrapper>
      <Label>{config.subsections[subSectionId].label}</Label>
      <Wrapper>
        <SortableContext id={`FormImage`} items={formImages.map((_, i) => `FormImage-${i}`)}>
          {formImages.map((formImage, i) => {
            return <FormImageField key={i} formImage={formImage} sectionName={sectionName} i={i} />;
          })}
        </SortableContext>
      </Wrapper>
    </FormWrapper>
  );
};
