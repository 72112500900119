import styled from 'styled-components';
import { VehicleSection } from '../../../../../../../types/vehicle';
import { gradeColorMap } from '../../../../../../CreateVehicle/components/Grades';

type Props = {
  item: VehicleSection;
};

const OuterContainer = styled.div`
  background: rgba(249, 249, 249, 1);
  border-radius: 8px;
  padding: 16px;
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  flex-direction: column;
  border: 1px solid rgba(204, 204, 204, 1);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  font-size: 16px;
  margin-top: 18px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
`;

const GradeLabel = styled.div<{ color: string }>`
  border-radius: 15px;
  font-size: 14px;
  line-height: 16.5px;
  width: fit-content;
  padding: 5px 8px;
  color: #fff;
  background: ${props => props.color};
`;

const NotesWrapper = styled.div`
  border: 2px solid rgba(166, 215, 255, 1);
  border-radius: 20px;
  border-top-left-radius: 0;
  padding: 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 4px;
  text-align: center;
  width: fit-content;
`;

export const CheckboxSection = ({ item }: Props) => {
  const checkboxes = item.checkboxes || [];

  const itemsInColumn = Math.floor(checkboxes.length / 5);

  return (
    <OuterContainer>
      <Container>
        {item.grade && <GradeLabel color={gradeColorMap[item.grade.color]}>{item.grade.label}</GradeLabel>}
        <Wrapper>
          {checkboxes
            .filter(c => c.selected)
            .map((checkbox, i) => (
              <div>
                <Label>{checkbox.label}</Label>
                {checkbox.notes && <NotesWrapper>{checkbox.notes}</NotesWrapper>}
              </div>
            ))}
        </Wrapper>
      </Container>
    </OuterContainer>
  );
};
