export const LeftNavIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
    <path
      d="M3.96949 7.89894L10.1582 13.9001L8.27468 15.7266L0.20245 7.89894L8.27468 0.0713207L10.1582 1.89777L3.96949 7.89894Z"
      fill="#E8EAED"
    />
  </svg>
);

export const RightNavIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
    <path
      d="M6.59691 8.39255L0.408203 2.20383L2.29172 0.320312L10.364 8.39255L2.29173 16.4648L0.408206 14.5813L6.59691 8.39255Z"
      fill="#E8EAED"
    />
  </svg>
);
