import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVehicleBuyerDocs } from '../../../actions/vehicles';
import { selectedVehicleBuyerDocsSelector } from '../../../selectors';
import { Table, PageWrapper } from '../../../components';
import { SELLER_DOCUMENT_NAMES } from '../../../constants';
import { Header } from './components';

class VehicleBuyerDocs extends Component {
  componentDidMount() {
    const { match, fetchDocs } = this.props;
    const { vehicleId } = match.params;
    fetchDocs(vehicleId);
  }

  renderDocs = () => {
    const { docs } = this.props;
    return docs.map(doc => ({
      name: SELLER_DOCUMENT_NAMES[doc.name],
      url: doc.url
    }));
  }

  render() {
    const buttons = [
      { 
        title: 'View',
        color: 'primary',
        handleClick: item => window.open(item.url)
      }
    ];
    return (
      <>
        <Header />
        <PageWrapper>
          <Table
            columnsHead={[
              {
                title: 'Document',
                key: 'name'
              },
            ]}
            columnsData={this.renderDocs()}
            buttons={buttons}
            noDataText="Buyer doesn't have any docs or vehicle wasn't awarded"
          />
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = state => ({
  docs: selectedVehicleBuyerDocsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchDocs: payload => dispatch(getVehicleBuyerDocs(payload).request)
})

export default connect(mapStateToProps, mapDispatchToProps)(VehicleBuyerDocs);
