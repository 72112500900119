import React from 'react';
import { Input, FormHelperText } from '@material-ui/core';

const FormInput = ({
  input,
  label = '',
  meta: { touched, error, invalid },
  ...custom
}) => (
  <div>
    <Input
      error={touched && invalid}
      placeholder={label}
      label={label}
      helperText={touched && invalid && error}
      {...input}
      {...custom}
    />
    {touched && invalid && error && !custom.hideErrors && (
      <FormHelperText error>{error}</FormHelperText>
    )}
  </div>
);

export default FormInput;
