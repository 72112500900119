import { ButtonBase, Icon, Input } from '@material-ui/core';
import styled from 'styled-components';
import { useGetOffersLogQuery } from 'services/api/admin-auction-vehicles';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterByName } from '../../../../services/offerLogSlice/offerLogSlice';
import { useDebounce } from 'usehooks-ts';
import { useGetOffersLogFilterOptionsQuery } from 'services/api/admin-auction-vehicles';
// @ts-ignore
import ReactSelect from 'react-select';
import { useEffect, useState } from 'react';

const FilterRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  align-items: center;
`;

const StyledInput = styled(Input)`
  width: 170px;
  height: 33px;
  align-self: flex-end;
`;

const StyledSelect = styled(ReactSelect)`
  & > div {
    width: 170px;
  }
  & .offer-log-select__control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .offer-log-select__control:hover {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }

  & .offer-log-select__control--is-focused {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0;
    box-shadow: none;
  }

  & .offer-log-select__dropdown-indicator {
    padding: 6px;
  }

  & .offer-log-select__control {
    min-height: unset;
  }

  & .offer-log-select__menu-list {
    padding: 4px 4px 4px 0;
  }

  & .offer-log-select__option--is-focused {
    background-color: rgba(0, 0, 0, 0.14) !important;
  }

  & .offer-log-select__option--is-selected {
    font-weight: 500;
    background-color: #fff !important;
    color: inherit !important;
  }
`;

const customSearch = (option: { value: string; label: string }, searchText: string) => {
  return option?.label && option.label.toLowerCase().includes(searchText.toLowerCase());
};

const transformOptions = (options: Array<{ id: number; dealership_name: string }>) => {
  return options.map(({ id, dealership_name }) => ({ value: id, label: dealership_name }));
};

export const LogFilters = ({ auctionId }: { auctionId: number }) => {
  const { filters, page, perPage } = useSelector((state: any) => state.offerLog);

  const { refetch: refetchBidLog } = useGetOffersLogQuery({
    auctionId,
    sellerId: filters.seller?.value,
    buyerId: filters.buyer?.value,
    orderIndex: filters.orderIndex,
    page,
    perPage
  });

  const dispatch = useDispatch();

  const [localOrderIndex, setLocalOrderIndex] = useState<string>(filters.orderIndex);

  const debouncedValue = useDebounce<string>(localOrderIndex, 250);

  useEffect(() => {
    dispatch(setFilterByName(['orderIndex', debouncedValue]));
  }, [debouncedValue, dispatch]);

  const { data, refetch: refetchFilters } = useGetOffersLogFilterOptionsQuery({ auctionId });

  const refetchQuery = () => {
    refetchBidLog()
    refetchFilters();
  }

  const { sellers = [], buyers = [] } = data || {};

  const buyerOptions = transformOptions(buyers);
  const sellerOptions = transformOptions(sellers);

  return (
    <FilterRow>
      <ButtonBase onClick={refetchQuery}>
        <Icon style={{ color: '#757575' }}>refresh</Icon>
      </ButtonBase>
      <StyledInput
        name="orderIndex"
        value={localOrderIndex}
        onChange={e => setLocalOrderIndex(e.target.value)}
        placeholder="By Number"
      />
      <StyledSelect
        isClearable
        classNamePrefix="offer-log-select"
        name="seller"
        value={filters.seller}
        onChange={(data: any) => dispatch(setFilterByName(['seller', data]))}
        options={sellerOptions}
        placeholder="Select Seller..."
        filterOption={customSearch}
      />
      <StyledSelect
        isClearable
        classNamePrefix="offer-log-select"
        name="buyer"
        value={filters.buyer}
        onChange={(data: any) => dispatch(setFilterByName(['buyer', data]))}
        options={buyerOptions}
        placeholder="Select Buyer..."
        filterOption={customSearch}
      />
    </FilterRow>
  );
};
