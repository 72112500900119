import styled from 'styled-components';

export const getTableRow = (component: 'form' | 'div') => styled(component)`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr 1.5fr 1fr 1.5fr 1fr 1fr;
  grid-gap: 16px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 12px 24px;
  min-height: 56px;
  align-items: center;
  font-size: 14px;
  position: relative;
  min-height: 124px;
  border: ${() => (component === 'form' ? '2px solid #3994DE' : 'none')};
`;
