/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Button, Typography, Icon, FormHelperText} from '@material-ui/core';
import styled from 'styled-components';
import {ModalWrapper} from '../../../../components';
import {modalsToggle} from '../../../../actions';
import {DATE_FORMAT} from '../../../../constants';

const StyledFieldWrapper = styled.div`
  label,
  button {
    margin-left: 5px;
    cursor: pointer;
    span {
      color: #fff;
    }
  }
`;

const StyledLabel = styled(Typography)`
  && {
    font-family: Roboto-Medium;
    color: #212121;
  }
`;

const StyledFileText = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 12px;
    background: rgba(0, 90, 250, 0.2);
    padding: 5px 10px;
    border-radius: 34px;
    letter-spacing: 0.4px;
    display: flex;
    max-width: 170px;
    span:last-child {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: 400px) {
      max-width: 85px;
    }
  }
`;

const StyledUploadButton = styled(Button)`
  && {
    margin-top: 5px;
  }
`;

const StyledFileControls = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
  @media (max-width: 550px) {
    flex-flow: row wrap;
    justify-content: space-between;
    .flex-grow {
      width: 100%;
      display: block;
      margin: 5px 0;
    }
  }
`;

const StyledFileIcon = styled(Icon)`
  && {
    font-size: 16px;
    margin-right: 3.5px;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;
const StyledModalWrapper = styled(ModalWrapper)`
  & > div:nth-child(2) {
    max-width: 100vw;
  }
`;

const StyledError = styled(FormHelperText)`
  && {
    margin-top: 5px;
  }
`;

const StyledStatus = styled(Typography)`
  && {
    font-family: Roboto-Medium;
    color: #009f0f;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    line-height: 20px;
    margin: 0 0 2.5px 16px;
    color: ${props => props.statusColor || '#000'};
  }
`;

const statusColor = {
  approved: '#009F0F',
  pending: '#E47E00',
  declined: '#D50808'
};

const renderDocumentFile = doc => {
  const isImage =
    doc.url && ['jpg', 'png', 'jpeg'].includes(doc.url.split('.').pop());

  return isImage ? (
    <StyledImg alt={doc.name} src={doc.url} />
  ) : (
    <iframe
      style={{height: '79vh', width: '100%'}}
      title={doc.name}
      src={doc.url}
      allowFullScreen
    />
  );
};

class FormFileField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fileSizeError: false
    };
  }


  adaptFileEventToValue = delegate => e => {
    const fileSize = e.target.files[0].size;

    this.setState({
      fileSizeError: false
    });

    if (fileSize > 31457280) {
      return this.setState({
        fileSizeError: true
      })
    }
    return delegate(e.target.files[0]);
  };

  render() {
    const {
      input: {value: omitValue, onChange, onBlur, ...inputProps},
      toggleModal,
      id,
      name,
      label,
      meta: {initial, error}
    } = this.props;
    const {fileSizeError} = this.state;
    return (
      <StyledFieldWrapper>
        <StyledLabel>{label}</StyledLabel>
        <input
          key={omitValue ? (omitValue || {}).name : +new Date()}
          onChange={this.adaptFileEventToValue(onChange)}
          onBlur={this.adaptFileEventToValue(onBlur)}
          type="file"
          style={{display: 'none'}}
          id={id}
          name={name}
          accept="image/png, image/jpeg, application/pdf, msword/doc"
          {...inputProps}
        />
        {!omitValue && (
          <label htmlFor={id}>
            <StyledUploadButton
              variant="contained"
              color="primary"
              component="span"
            >
              Upload
            </StyledUploadButton>
          </label>
        )}
        {omitValue && (
          <StyledFileControls>
            <StyledFileText component="span">
              <StyledFileIcon>insert_drive_file</StyledFileIcon>
              {' '}
              <span>{omitValue.file_name || omitValue.name}</span>
            </StyledFileText>
            <StyledStatus
              statusColor={statusColor[initial ? initial.status : 'pending']}
            >
              {initial ? initial.status : 'Pending'}
              {initial && initial.status !== 'pending'
                ? ` ${moment().format(DATE_FORMAT)}`
                : ''}
            </StyledStatus>
            <span className="flex-grow" />
            <label htmlFor={id}>
              <Button component="div" variant="contained" color="secondary">
                Edit
              </Button>
            </label>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => toggleModal(`view-doc-modal-${omitValue.file_name}`)}
            >
              View
            </Button>
            <StyledModalWrapper
              dialogProps={{fullScreen: true}}
              fullScreen
              modalId={`view-doc-modal-${omitValue.file_name}`}
              title={`Document: ${omitValue.file_name}`}
              showCancel={false}
              showSubmit={false}
            >
              {renderDocumentFile(omitValue)}
            </StyledModalWrapper>
          </StyledFileControls>
        )}
        {fileSizeError && <StyledError error>Maximum file size is 30MB</StyledError>}
        {error && <StyledError error>{error}</StyledError>}
      </StyledFieldWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  toggleModal: id => dispatch(modalsToggle(id))
});

export default connect(
  null,
  mapDispatchToProps
)(FormFileField);
