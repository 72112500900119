import { useAcceptPrivateOfferMutation, useLazyGetPrivateOfferAmountQuery } from 'services/api/offer-auction';
import ConfirmModal from '../Modals/ConfirmModal';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../utils';
import { useEffect } from 'react';

type Props = {
  vehicleId: number;
  vehicleTitle?: string;
};

export const AcceptOfferModal = ({ vehicleId, vehicleTitle }: Props) => {
  const [acceptOffer] = useAcceptPrivateOfferMutation();
  const user = useSelector((state: any) => state.user?.user);

  const [fetchAmount, { data: amount }] = useLazyGetPrivateOfferAmountQuery();

  const modal = useSelector((state: any) => state.modals);

  useEffect(() => {
    if (modal.open && modal.modalId === `acceptPrivateOffer-${vehicleId}`) {
      fetchAmount({ vehicleId });
    }
  }, [modal, vehicleId, fetchAmount]);

  const isBuyer = user?.role === 'buyer';
  const offerFrom = isBuyer ? 'seller' : 'top buyer';

  const parts = [
    `Are you sure you want to accept the offer`,
    vehicleTitle && `for ${vehicleTitle}`,
    amount && `for ${formatCurrency(amount)}`,
    offerFrom !== 'seller' && `from ${offerFrom}`
  ];

  return (
    <ConfirmModal
      submitText="Accept"
      title={parts.filter(Boolean).join(' ')}
      modalId={`acceptPrivateOffer-${vehicleId}`}
      handleSubmit={() => {
        acceptOffer({ vehicleId });
      }}
    />
  );
};
