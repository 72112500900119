import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styled from 'styled-components';
import { PageHeader } from '../../../../../components';
import {
  sellersInvoicesSetPage,
  sellersInvoicesSetPerPage,
  filterSellersInvoices
} from '../../../../../actions/financials';

const StyledTypography = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
    border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
  }
`;

const Header = ({ refreshList, setPage, filterValues, perPage }) => (
  <PageHeader>
    <StyledTypography variant="title">Invoices / Sellers</StyledTypography>
    <span className="flex-grow" />
    <ButtonBase
      onClick={() => {
        setPage(1);
        refreshList({ params: { ...filterValues, limit: perPage, offset: 1 } });
        setPage(2);
      }}
    >
      <Icon>refresh</Icon>
    </ButtonBase>
  </PageHeader>
);

const mapStateToProps = state => ({
  filterValues: getFormValues('invoicesFilterForm')(state)
});

const mapDispatchToProps = dispatch => ({
  refreshList: data => dispatch(filterSellersInvoices(data).request),
  setPage: page => dispatch(sellersInvoicesSetPage(page)),
  setPerPage: perPage => dispatch(sellersInvoicesSetPerPage(perPage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
