import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid, Divider } from '@material-ui/core';
import {
  modalsToggle,
  pullVehicle,
  relistVehicle,
  deactivateVehicle,
  listAuctions
} from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import AuctionCreateModal from '../../../../../components/Modals/AuctionCreateModal';
import RelistVehicleModal from '../../RelistVehicleModal';
import {
  VEHICLE_STATUSES,
  AUCTION_STATUSES,
  DATE_FORMAT_FULL
} from '../../../../../constants';
import { openRelistDialog } from '../../../../../services/auctionVehicles/auctionVehiclesSlice';
import { RelistDialog } from '../../../../../components/RelistDialog/RelistDialog';

const modalPullTitle = 'pullVehicleModal';
const modalDeactivateTitle = 'deactivateVehicleModal';
const modalRelistTitle = 'relistVehicleModal';
const modalNewAuction = 'newAuctionModal';

const StyledPullSpan = styled.span`
  color: #d50808;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;
const StyledRelistSpan = styled.span`
  color: #06a000;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
  margin-bottom: 16px;
`;

const StyledDeactivateSpan = styled.span`
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
`;

const StyledDusabledSpan = styled.span`
  color: rgba(0, 0, 0, 0.12);
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledSmallerSpan = styled.span`
  font-size: 14px;
`;

class PullVehicleBlock extends Component {
  componentDidMount() {
    const { fetchAuctions } = this.props;
    fetchAuctions();
  }

  handleClickPull = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalPullTitle);
  };

  handlePull = () => {
    const {
      pullCurrentVehicle,
      currentVehicle,
      modalsToggle: toggle
    } = this.props;
    pullCurrentVehicle({
      vehicle_id: currentVehicle.id
    });
    toggle(modalPullTitle);
  };

  handleClickDeactivate = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalDeactivateTitle);
  };

  handleClickRelist = () => {
    const { currentVehicle, openVehicleRelistDialog } = this.props;
    openVehicleRelistDialog(currentVehicle.id);
  };

  handleClickNewAuction = () => {
    const { modalsToggle: toggle } = this.props;
    toggle(modalNewAuction);
  };

  handleRelist = () => {
    const {
      currentVehicle,
      relistCurrentVehicle,
      relistForm,
      modalsToggle: toggle
    } = this.props;
    relistCurrentVehicle({
      vehicle_id: currentVehicle.id,
      auctionId: relistForm.auction
    });
    toggle(modalRelistTitle);
  };

  handleDeactivate = () => {
    const {
      deactivateCurrentVehicle,
      currentVehicle,
      modalsToggle: toggle
    } = this.props;
    deactivateCurrentVehicle({
      vehicle_id: currentVehicle.id
    });
    toggle(modalDeactivateTitle);
  };

  render() {
    const { currentVehicle, isVehicleTimerEnded, auctions } = this.props;
    return (
      <Grid item xs={12}>
        {currentVehicle.status !== VEHICLE_STATUSES.PULLED && (
          <Fragment>
            <StyledPullSpan onClick={this.handleClickPull}>
              Pull vehicle from the auction
            </StyledPullSpan>
            <ConfirmModal
              modalId={modalPullTitle}
              title={`Are you sure you want to pull this vehicle from the auction?${
                !isVehicleTimerEnded
                  ? ' Once it is pulled, no more bids can be made.'
                  : ''
              }`}
              handleSubmit={() => {
                this.handlePull();
              }}
            />
          </Fragment>
        )}

        {currentVehicle.status === VEHICLE_STATUSES.PULLED && (
          <Fragment>
            {auctions.length > 0 && (
              <StyledRelistSpan onClick={this.handleClickRelist}>
                Re-list vehicle in auction
              </StyledRelistSpan>
            )}
            {auctions.length === 0 && (
              <>
                <StyledDusabledSpan>
                  Re-list vehicle in auction
                </StyledDusabledSpan>
                <br />
                <StyledSmallerSpan>
                  There is no an active auction to relist the vehicle in.
                  <br />
                  <StyledDeactivateSpan onClick={this.handleClickNewAuction}>
                    Create a New auction
                  </StyledDeactivateSpan>
                  <br />
                </StyledSmallerSpan>
              </>
            )}
            <StyledDeactivateSpan onClick={this.handleClickDeactivate}>
              Deactivate vehicle
            </StyledDeactivateSpan>
            <ConfirmModal
              modalId={modalDeactivateTitle}
              title={`Are you sure you want to deactivate the vehicle ${
                currentVehicle.year
              } ${currentVehicle.make} ${
                currentVehicle.model
              }? Once it is deactivated, the vehicle has to be reactivated before taking any other action on it.`}
              handleSubmit={() => {
                this.handleDeactivate();
              }}
              submitText="Deactivate"
            />
            <RelistVehicleModal
              handleSubmit={this.handleRelist}
              {...this.props}
            />
            <AuctionCreateModal />
          </Fragment>
        )}
        <StyledDivider />
        <RelistDialog vehicle={this.props.currentVehicle} />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.vehicles.loading,
  currentVehicle: state.vehicles.selectedVehicle.data,
  relistForm: state.form.RelistVehicleModal
    ? state.form.RelistVehicleModal.values || []
    : [],
  auctions: (state.auctions.itemsData.rows || [])
    .filter(item => item.status !== AUCTION_STATUSES.ENDED)
    .map(item => ({
      ...item,
      formatedStartDate: moment
        .utc(item.date_start)
        .local()
        .format(DATE_FORMAT_FULL),
      isCurrent:
        moment
          .utc(item.date_end)
          .local()
          .diff() > 0 &&
        moment
          .utc(item.date_start)
          .local()
          .diff() < 0
    }))
});

const mapDispatchToProps = dispatch => ({
  modalsToggle: key => dispatch(modalsToggle(key)),
  pullCurrentVehicle: data => dispatch(pullVehicle(data).request),
  deactivateCurrentVehicle: data => dispatch(deactivateVehicle(data).request),
  relistCurrentVehicle: data => dispatch(relistVehicle(data).request),
  fetchAuctions: params => dispatch(listAuctions(params).request),
  openVehicleRelistDialog: (vehicleId) => dispatch(openRelistDialog(vehicleId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PullVehicleBlock);
