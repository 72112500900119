import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { FormResetButton, FormTextField } from '../../../../components/Form';
import { filterEasyPayList, filterTransactions } from '../../../../actions/financials';
import { setEasyPayStatusFilter, setFilter } from 'services/transactions/transactionsSlice';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1180px) {
    flex-direction: column;
    > div > div {
      width: 100%;
    }
    > button {
      width: 100%;
      margin-top: 10px;
    }
  }
  > div > div {
    min-width: 160px;
  }
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  > div:not(:last-child) {
    margin-right: 15px;
  }
  button {
    margin: 0 0 5px 5px;
  }
  @media (max-width: 1180px) {
    && {
      flex-direction: column;
      width: 100%;
      > div {
        margin-right: 0;
        margin-top: 10px;
      }
      button {
        margin-top: 10px;
      }
    }
  }
`;

const onChange = (values, dispatch, { perPage, setPage }) => {
  dispatch(setFilter({ tab: 'card', key: 'status', value: values?.status}))
};

const onChangeEasyPay = (values, dispatch, { perPage, setPage }) => {
  dispatch(setEasyPayStatusFilter(values?.combinedStatus));
};

const BuyerTransactionFilterForm = ({ dirty, reset }) => (
  <StyledFilterWrapper>
    <StyledFieldWrapper>
      <Field component={FormTextField} name="status" label="Filter by Status" select>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="successful">Charged</MenuItem>
        <MenuItem value="declined">Declined</MenuItem>
        <MenuItem value="voided">Voided</MenuItem>
        <MenuItem value="uncharged">UnCharged</MenuItem>
      </Field>
      {dirty && <FormResetButton onClick={reset} />}
    </StyledFieldWrapper>
  </StyledFilterWrapper>
);

const EasyPayForm = ({ dirty, reset }) => (
  <StyledFilterWrapper>
    <StyledFieldWrapper>
      <Field component={FormTextField} name="combinedStatus" label="Filter by Status" select>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="ach_approved">ACH</MenuItem>
        <MenuItem value="ach_pending">ACH Pending Approval</MenuItem>
        <MenuItem value="ach_declined">ACH Declined</MenuItem>
        <MenuItem value="flooring_approved">Flooring</MenuItem>
        <MenuItem value="flooring_pending">Flooring Pending Approval</MenuItem>
        <MenuItem value="flooring_declined">Flooring Declined</MenuItem>
      </Field>
      {dirty && <FormResetButton onClick={reset} />}
    </StyledFieldWrapper>
  </StyledFilterWrapper>
);

const BuyerCardTransactionFilter = reduxForm({
  form: 'buyerTransactionFilterForm',
  onChange,
  destroyOnUnmount: false
})(BuyerTransactionFilterForm);

const BuyerEasyPayTransactionFilter = reduxForm({
  form: 'buyerEasyPayTransactionFilterForm',
  onChange: onChangeEasyPay,
  destroyOnUnmount: false
})(EasyPayForm);

const Filter = ({ tab, ...rest }) => {
  if (tab === 'easy_pay') {
    return <BuyerEasyPayTransactionFilter {...rest} />;
  }
  if (tab === 'card') {
    return <BuyerCardTransactionFilter {...rest} />;
  }
  return null;
};

export default Filter;
