import React from "react";
import styled from "styled-components";
import {
  AUTSlider,
  PageWrapper,
} from "../../../components";

import QuoteIcon from "../../../img/quote.svg";
import MantecaFord from "../../../img/Manteca Ford.png";
import TurlockChryslerDodge from "../../../img/Turlock Chrysler Dodge.png";
import MaitaMazda from "../../../img/Maita Mazda.png";
import GMCentralValleyAutomotiveGroup from "../../../img/GM Central Valley Automotive group.png";
import CabralChryslerJeep from "../../../img/Cabral Chrysler-Jeep.png";
import HaidlenFord from "../../../img/Haidlen Ford.png";

import ArrowLeft from "../../../img/arrow-left.svg";
import ArrowRight from "../../../img/arrow-right.svg";

const ClientsSayData = [
  {
    text: "We are a small dealer, so costs are super important.  AutoAxess gets me better bids at 1/3 the cost.",
    name: "Valerie",
    company: "Manteca Ford",
    avatar: MantecaFord
  },
  {
    text: "Our dealership is very remote.  The AutoAxess staff comes to me to auction my cars so I avoid expensive transport!",
    name: "Martin",
    company: "Turlock Chrysler Dodge",
    avatar: TurlockChryslerDodge
  },
  {
    text: "I tried AutoAxess and CarWave side by side, and my profits get boosted with AutoAxess!",
    name: "",
    company: "Maita Mazda",
    avatar: MaitaMazda
  },
  {
    text: "We are a large multi-point dealer group. AutoAxess is a way we can consolidate our wholesale offerings through one easy-to-use platform. And the fees are the lowest we've ever seen!",
    name: "John",
    company: "GM Central Valley Automotive group",
    avatar: GMCentralValleyAutomotiveGroup
  },
  {
    text: "My AutoAxess rep is by far my best salesperson!  #Truth!",
    name: "Jason",
    company: "Cabral Chrysler-Jeep",
    avatar: CabralChryslerJeep
  },
  {
    text: "Autoxess is easy, inexpensive, and gets me maximum money for my wholesale cars. It’s a no-brainer!",
    name: "David",
    company: "Haidlen Ford",
    avatar: HaidlenFord
  },
];

const ClientsSaySectionHolder = styled.section`
  padding: 0 0 32px;
  @media only screen and (min-width: 1026px) {
    padding: 18px 0 94px ;
  }
`;

const QuoteHolder = styled.div`
  margin: 10px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: margin-bottom 250ms;

  @media only screen and (min-width: 900px) {
    margin: 10px 33px;
  }

  @media only screen and (min-width: 1126px) {
    margin: 10px;
  }

  @media only screen and (
    min-width: 1400px) {
    margin: 10px 33px;
  }
`;

const QuoteTextHolder = styled.div`
  min-height: 250px;
  padding: 36px 40px;

  img {
    width: 38px !important;
    height: 38px !important;
  }
`;

const QuoteText = styled.p`
  margin: 20px 0 0;
  font-size: 14px;
  line-height: 24px;
  color: #54607D;
`;

const QuoteAuthorInfoHolder = styled.div`
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 10px 40px;
  background: #F5FAFD;
`;

const QuoteAuthorAvatar = styled.div`
  margin-right: 20px;

  img {
    width: 50px !important;
    height: 50px !important;
  }
`;

const QuoteAuthorName = styled.p`
  margin: 4px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const QuoteAuthorCompany = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3994DE;
`;

const Quote = ({ data }) => (
  <QuoteHolder className="quote-holder">
    <QuoteTextHolder>
      <img src={QuoteIcon} alt="Quote" />
      <QuoteText>{data.text}</QuoteText>
    </QuoteTextHolder>
    <QuoteAuthorInfoHolder>
      <QuoteAuthorAvatar>
        <img src={data.avatar} alt="Avatar" />
      </QuoteAuthorAvatar>
      <div>
        <QuoteAuthorName>{data.name}</QuoteAuthorName>
        <QuoteAuthorCompany>{data.company}</QuoteAuthorCompany>
      </div>
    </QuoteAuthorInfoHolder>
  </QuoteHolder>
);

const ClientsSaySection = () => (
  <ClientsSaySectionHolder>
    <PageWrapper>
      <div className="quotes">
        <h2 className="section-title">What Our Clients Say</h2>
        <AUTSlider
          settings={{
            responsive: [
              {
                breakpoint: 1126,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              }
            ],
            arrows: true,
            dots: true,
            draggable: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <img src={ArrowRight} alt="next" />,
            prevArrow: <img src={ArrowLeft} alt="prev" />,
            // centerMode: true
          }}
        >
          {ClientsSayData.map(item => (
            <Quote data={item} key={item.text} />
          ))}
        </AUTSlider>
      </div>
    </PageWrapper>
  </ClientsSaySectionHolder>
);

export default ClientsSaySection;
