import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import { Vehicle } from '../../../../types/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import {  modalsToggle, pullVehicle } from '../../../../actions';
import { openRelistDialog } from '../../../../services/auctionVehicles/auctionVehiclesSlice';

type Props = {
  vehicle: Vehicle;
};

export const PullVehicleModal = ({ vehicle }: Props) => {
  const dispatch = useDispatch();

  const { shouldRelistAfterPull } = useSelector((state: any) => state.auctionVehicles);

  const handlePull = () => {
    dispatch(pullVehicle({ vehicle_id: vehicle.id }).request);
    dispatch(modalsToggle(`confirm-pull-${vehicle.id}`));
    if (shouldRelistAfterPull) {
      dispatch(openRelistDialog(vehicle.id));
    }
  };

  return (
    <ConfirmModal
      modalId={`confirm-pull-${vehicle.id}`}
      title={`Are you sure you want to pull this vehicle from the auction?`}
      submitText={'Pull vehicle'}
      handleSubmit={handlePull}
    />
  );
};
