import React from 'react';
import { Typography } from '@material-ui/core';

const FormError = ({ msg, ...custom }) => (
  <div className="form-error-message" {...custom}>
    <Typography component="p">{msg}</Typography>
  </div>
);

export default FormError;
