import React from 'react';
import { connect } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { green, amber } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { snackUnsetMessage } from '../../actions';

const styles = theme => ({
  success: {
    backgroundColor: green[600],
    justifyContent: 'center'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    justifyContent: 'center'
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
    justifyContent: 'center'
  },
  warning: {
    backgroundColor: amber[700],
    justifyContent: 'center'
  }
});

const SnackContent = props => {
  const { classes, variant, message } = props;
  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={message}
    />
  );
};

const SnackContentWrapped = withStyles(styles)(SnackContent);

const Snack = props => {
  const { open, hideTime = 2000, message, unsetSnack, type } = props;
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={hideTime}
      onClose={() => unsetSnack()}
    >
      <SnackContentWrapped variant={type} message={message} />
    </Snackbar>
  );
};

SnackContent.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
};

SnackContent.defaultProps = {
  variant: 'success'
};

const mapStateToProps = state => ({
  message: state.utils.message,
  open: state.utils.open,
  type: state.utils.type
});

const mapDispatchToProps = dispatch => ({
  unsetSnack: () => dispatch(snackUnsetMessage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snack);
