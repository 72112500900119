import styled from 'styled-components';
import { DragImage, FieldLocalImage, LocalImage } from '../../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { Icon } from '@material-ui/core';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';

const Wrapper = styled.div`
  display: flex;
  width: 143px
  height: 109px;
  position: relative;
  border: 4px solid rgba(57, 148, 222, 1);
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const DragIconContainer = styled.div`
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

const PlaceholderWrapper = styled.div`
  min-height: 105px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgb(217, 217, 217);
`;

export const DragItem = ({ item }: { item: DragImage }) => {
  return (
    <Wrapper>
      <RenderImage item={item} />
      <DragIconContainer>
        <Icon>drag_indicator</Icon>
      </DragIconContainer>
    </Wrapper>
  );
};

const RenderImage = ({ item }: { item: DragImage }) => {
  if (item.file) {
    return <StyledImage src={URL.createObjectURL(item.file)} alt={'drag-item'} />;
  }
  if (item.url) {
    return <StyledImage src={item.url} alt={'drag-item'} />;
  }

  return (
    <PlaceholderWrapper>
      <ImagePlaceholderIcon />
    </PlaceholderWrapper>
  );
};
