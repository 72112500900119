import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const SectionTitle = styled(Typography)`
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  margin-bottom: ${props => (props.noGutterBottom ? 0 : '16px')};
`;

export default SectionTitle;
