import { useEffect, useState, RefObject, useCallback } from 'react';

export const useIsTextOverflown = (ref: RefObject<HTMLElement>) => {
  const [isOverflown, setIsOverflown] = useState(false);

  const compareSize = () => {
    if (!ref || !ref.current) return;
    const compare = ref.current.scrollWidth > ref.current.clientWidth;
    setIsOverflown(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => window.removeEventListener('resize', compareSize);
  });

  return isOverflown;
};
