import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useUpdateSearchParams() {
  const history = useHistory();
  const location = useLocation();

  const updater = React.useCallback(
    (newParams: Record<string, string>) => {
      const params = new URLSearchParams(newParams).toString();
      history.replace({ pathname: location.pathname, search: params });
    },
    [history, location.pathname]
  );

  return updater;
}
