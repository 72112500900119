import { SubSection } from 'services/api/vehicle-sections/types/common';
import styled from 'styled-components';
import { DesktopImageComponent, MobileImageComponent } from './ImageView';
import { useFieldArray } from 'react-final-form-arrays';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { ImageDragOverlay } from './components/ImageDragOverlay';
import { useState } from 'react';
import { ImageField, useUpdateSectionImageMutation } from '../../../../../services/api/upload-vehicle';
import { useForm } from 'react-final-form';
import { useGetVehicleId } from '../../../hooks/useGetVehicleId';
import { useWindowSize } from 'usehooks-ts';

type Props = {
  subSectionConfig: SubSection;
  name: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  color: #000;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const ImagesWrapper = styled.div<{ numberOfImages: number }>`
  display: grid;
  grid-template-columns: ${props => {
    if (props.numberOfImages > 2) {
      return '1fr 1fr';
    }
    return '1fr';
  }};

  grid-template-rows: auto;
  gap: 8px;

  @media (min-width: 1024px) {
    grid-template-columns: ${props => {
      if (props.numberOfImages >= 4) {
        return '1fr 1fr 1fr 1fr';
      }
      const columns = props.numberOfImages % 4;
      return `repeat(${columns}, 1fr)`;
    }};
  }
`;

export const PhotoUploadComponent = ({ subSectionConfig, name }: Props) => {
  const [draggedImage, setDraggedImage] = useState<ImageField | null>(null);

  const [updateSectionImage] = useUpdateSectionImageMutation();

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const { fields } = useFieldArray(`${name}.images`);
  const form = useForm();

  const vehicleId = useGetVehicleId();

  const isTiresAndWheelsSections = name.startsWith('tires_and_wheels');

  const onDragStart = (e: DragStartEvent) => {
    setDraggedImage(e.active.data.current as ImageField);
  };

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over && active.id !== over.id) {
      const copy = structuredClone(fields.value);
      const fromIndex = copy.findIndex((i: any) => i.id === active.id);
      const toIndex = copy.findIndex((i: any) => i.id === over.id);

      const moved: ImageField[] = arrayMove(copy, fromIndex, toIndex);

      form.change(
        `${name}.images`,
        moved.map((e, i) => ({ ...e, order: i }))
      );
      for (const i in moved) {
        const element = moved[i];
        updateSectionImage({
          vehicleId,
          id: element.id,
          order: Number(i)
        });
      }
    }

    setDraggedImage(null);
  };

  const { width = 0 } = useWindowSize();

  const ImageComponent = width > 1024 ? DesktopImageComponent : MobileImageComponent;

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext disabled={!fields.length || fields.length <= 1} items={fields.value.map(item => item.id)}>
        <Wrapper>
          {!!fields.length && fields.length > 0 && !isTiresAndWheelsSections && <Title>Upload Photos</Title>}
          <ImagesWrapper numberOfImages={!!fields.length ? fields.length : 0}>
            {fields.map((name, index) => (
              <ImageComponent
                renderDamageCheckbox={
                  fields.value[index].id &&
                  fields.value[index].url &&
                  Boolean(subSectionConfig.imageConfig?.withDamageCheckbox)
                }
                key={name}
                name={name}
              />
            ))}
          </ImagesWrapper>
        </Wrapper>
      </SortableContext>
      <DragOverlay>{!!draggedImage && <ImageDragOverlay image={draggedImage} />}</DragOverlay>
    </DndContext>
  );
};
