import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SectionWrapper, SectionTitle } from './index';
import KBB from '../../../../img/kbb_logo.png';

const KBBIcon = styled.img`
  height: 62.5px;
  width: 106.5px;
`;

const StyledKarPowerLink = styled.a`
  font-weight: 500;
`;

const StyledAdaptiveWrapper = styled(SectionWrapper)`
  ${props =>
    props.mobileOnly
      ? `
    @media (min-width: 1280px) {
      display: none;
    }
  `
      : `
    @media (max-width: 1279px) {
      display: none;
    }
  `}
`;

const Equipment = ({ kbbReportUrl, mobileOnly = false, options }) => (
  <StyledAdaptiveWrapper mobileOnly={mobileOnly}>
    <SectionTitle>Equipment</SectionTitle>
    <Grid container>
      {(options || [])
        .filter(option => option.is_typical)
        .map(option => (
          <Grid key={option.id} item xs={6}>
            <Typography paragraph variant="body1">
              {option.option_name}
            </Typography>
          </Grid>
        ))}
    </Grid>
    <Grid container justify="space-between" alignItems="center">
      <Typography color="primary" gutterBottom>
        <StyledKarPowerLink
          href={kbbReportUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Vehicle Report
        </StyledKarPowerLink>
      </Typography>
      <KBBIcon alt="provided_by_kbb" src={KBB} />
    </Grid>
  </StyledAdaptiveWrapper>
);

export default Equipment;
