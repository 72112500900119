import styled from 'styled-components';
import { MapPinIcon } from 'components/Icons/MapPin';

export const dayToColorMap: Record<number, string> = {
  0: '#959595',
  1: '#225A91',
  2: '#229162',
  3: '#8F9122',
  4: '#C98568',
  5: '#91225E',
  6: '#912222',
  7: '#228A91'
};

const Wrapper = styled.div`
  position: relative;
`;

const NumberWrapper = styled.div<{ type: 'web' | 'map' }>`
  position: absolute;
  top: ${props => (props.type === 'map' ? '16px' : '12px')};
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

export const MapPin = ({
  dayNumber,
  orderIndex,
  type = 'web'
}: {
  dayNumber: number;
  orderIndex: number;
  type?: 'web' | 'map';
}) => {
  return (
    <Wrapper>
      <MapPinIcon type={type} fillColor={dayToColorMap[dayNumber]} />
      <NumberWrapper type={type}>{orderIndex}</NumberWrapper>
    </Wrapper>
  );
};
