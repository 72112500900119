import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { cutTextElipsis } from '../../../../utils';

const StyledBidderAccount = styled.p`
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const HighestBidder = ({ highestBid = {}, currentUser, cutText = 12, hideNameForTopBuyer = false }) => {
  const { dealership_name: dealerName } = highestBid?.bidder ?? {};
  const isTopBuyer = highestBid?.bidder?.id === currentUser.id;

  return (
    <Wrapper>
      <NumberFormat value={highestBid.amount} displayType="text" thousandSeparator prefix="$" />
      <span>/</span>
      <StyledBidderAccount title={dealerName}>
        {`${isTopBuyer && hideNameForTopBuyer ? '' : cutTextElipsis(dealerName, cutText)} ${isTopBuyer ? '(You)' : ''}`}
      </StyledBidderAccount>
    </Wrapper>
  );
};

export default HighestBidder;
