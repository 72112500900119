/* eslint-disable no-else-return */
import React from 'react';
import { connect } from 'react-redux';
import BuyerRestrictionSection from './BuyerRestrictionSection/index';
import AdminControlsBlock from '../AdminControlsBlock';
import SellerRestrictedSection from './SellerRestrictedSection';

const RoleRestrictedSection = props => {
  const { role } = props;
  if (role === 'buyer') {
    return <BuyerRestrictionSection {...props} />;
  } else if (role === 'admin') {
    return <AdminControlsBlock {...props} />;
  } else if (role === 'seller') {
    return <SellerRestrictedSection />;
  }
  return null;
};

export default connect(state => ({
  role: (state.user.user || {}).role
}))(RoleRestrictedSection);
