import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import HeaderTitle from '../components/HeaderTitle';

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
  }
`;

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
  }
`;

const BiddingHeader = ({ currentBid, timeRemaining }) => (
  <StyledHeaderWrapper>
    <HeaderTitle
      subTitleJSX={
        <StyledHeaderSubTitle>
          Time Remaining - {timeRemaining}
        </StyledHeaderSubTitle>
      }
      titleJSX={
        <StyledHeaderTitle>
          Current Bid —{' '}
          <NumberFormat
            displayType="text"
            prefix="$"
            value={currentBid.amount}
            thousandSeparator
          />
        </StyledHeaderTitle>
      }
    />
  </StyledHeaderWrapper>
);

export default BiddingHeader;
