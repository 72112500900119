import styled from 'styled-components';
import { DeleteLocalImage } from '../../../icons/DeleteLocalImage';

const DeleteIconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 16px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

type Props = {
  onClick: () => void;
};

export const DeleteImageButton = ({ onClick }: Props) => {
  return (
    <DeleteIconWrapper onClick={onClick}>
      <DeleteLocalImage />
    </DeleteIconWrapper>
  );
};
