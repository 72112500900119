import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import moment from 'moment';
import PageWrapper from '../PageWrapper';
import Table from '../Table';
import { snackShow } from '../../actions';

import LocationCreateModal from '../Modals/LocationCreateModal';
import LocationEditModal from '../Modals/LocationEditModal';

import './index.scss';
import {
  listLocations,
  locationsSetPage,
  locationsSetPerPage,
  locationsSetEndDay,
  locationsSetLocationName,
  modalsToggle,
  setEditLocation
} from '../../actions';
import { LOCATIONS_PER_PAGE, dayOfWeek } from '../../constants';
import apiSlice from '../../services/api';
import auctionApiSlice from '../../services/api/live-auction';
import { minutesToMs, msToMinutes } from '../../utils';

class LocationsList extends Component {
  state = {
    params: {},
    selectedAuctionType: ''
  };

  componentDidMount() {
    const { fetchLocations, getActiveAuction, page, perPage } = this.props;
    getActiveAuction();

    this.setState({ params: { limit: perPage, offset: page * perPage } }, () => {
      // fetchLocations({ params: { limit: perPage, offset: page * perPage }});
      const { params } = this.state;
      fetchLocations({ params });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { page, perPage, setPage, fetchLocations, endDay, locationName } = this.props;
    if (page !== nextProps.page) {
      const { params } = this.state;
      params.offset = nextProps.page * perPage;
      this.setState({ params }, () => {
        fetchLocations({ params });
      });
      // fetchLocations({ params: { limit: perPage, offset: nextProps.page * perPage }});
    }
    if (perPage !== nextProps.perPage) {
      setPage(0);
      const { params } = this.state;
      params.limit = nextProps.perPage;
      params.offset = 0;
      this.setState({ params }, () => {
        fetchLocations({ params });
      });
      // fetchLocations({ params: { limit: nextProps.perPage, offset: 0 }});
    }
    if (endDay !== nextProps.endDay) {
      setPage(0);
      const { params } = this.state;
      if (nextProps.endDay === '') {
        delete params.endDay;
      } else {
        params.endDay = +nextProps.endDay - 1;
      }
      this.setState({ params }, () => {
        fetchLocations({ params });
      });
      // fetchLocations({ params: { limit: nextProps.perPage, offset: 0 }});
    }
    if (locationName !== nextProps.locationName) {
      setPage(0);
      const { params } = this.state;
      if (nextProps.locationName === '') {
        delete params.locationName;
      } else {
        params.locationName = nextProps.locationName;
      }
      this.setState({ params }, () => {
        fetchLocations({ params });
      });
    }
  }

  handleChangePage = (event, page) => {
    if (event) {
      const { setPage } = this.props;
      setPage(page);
    }
  };

  loadMore = () => {
    const { fetchLocations, setPage, perPage, page } = this.props;
    fetchLocations({ params: { limit: perPage, offset: page * perPage } });
    setPage(page + 1);
  };

  handleChangeRowsPerPage = event => {
    if (event) {
      const { setPerPage } = this.props;
      setPerPage(event.target.value);
    }
  };

  handleChangeFilterDay = event => {
    const { setStartDay } = this.props;
    setStartDay(event.target.value);
  };

  handleChangeFilterLocationName = event => {
    const { setLocationName } = this.props;
    setLocationName(event.target.value);
  };

  handleNewLocationClick = () => {
    const { modalsToggle: toggle } = this.props;
    toggle('newLocationModal');
  };

  handleEditLocationClick = () => {
    const { modalsToggle: toggle } = this.props;
    toggle('editLocationModal');
  };

  // TODO: FIX THIS ASAP
  formatEndTimeSpacing = (endTime = '') => {
    const splittedSpacing = endTime.split(':');
    let [firstSpacing, secondSpacing] = splittedSpacing;
    if (+firstSpacing < 10) {
      firstSpacing = `0${+firstSpacing}`;
    }
    if (+secondSpacing < 10) {
      secondSpacing = `0${+secondSpacing}`;
    }
    return `${firstSpacing}:${secondSpacing}`;
  };

  handleStopAuction = async item => {
    const { activeAuctions, stopAuction, successfulAuctionEnd } = this.props;

    const activeAuction = activeAuctions?.data?.[item.auction_type.toLowerCase()];
    if (!activeAuction) return;

    const { data } = await stopAuction(activeAuction.id);
    if (data && data.success) {
      successfulAuctionEnd();
      // hacky force page reload to update all cached state
      // TODO: invalidate cache queries instead after saga -> rtk migration
      window.location.reload(false);
    }
  };

  render() {
    const {
      locations,
      locationsCount,
      page,
      perPage,
      loading,
      setLocationData,
      changeFormField,
      activeAuctions
    } = this.props;

    const buttons = [
      {
        title: 'Stop Live Auction',
        handleClick: item => this.handleStopAuction(item),
        conditionalRendering: item => {
          return Boolean(activeAuctions?.data?.[item.auction_type.toLowerCase()]);
        }
      },
      {
        title: 'Edit',
        handleClick: item => {
          const formName = 'LocationEditModal';
          const endTime = item.end_time_spacing.split(':');
          this.setState({
            selectedAuctionType: item.auction_type
          });

          const [extension_time_1, extension_time_2, extension_time_3, extension_time_4] = item.extension_times || [];

          console.log(item, 'location_data');

          setLocationData(item);
          // changeFormField(formName, 'auction_type', item.auction_type);
          changeFormField(formName, 'end_day', item.end_day);
          changeFormField(formName, 'end_time', moment.utc(item.end_time, 'HH:mm:ss').local());
          changeFormField(formName, 'endTimeMinutes', endTime[0]);
          changeFormField(formName, 'endTimeSeconds', endTime[1]);
          changeFormField(formName, 'shuffleDay', item.shuffle_day);
          changeFormField(formName, 'shuffleTime', moment.utc(item.shuffle_time, 'HH:mm:ss').local());
          changeFormField(formName, 'buyer_response_time', msToMinutes(item.buyer_response_time));
          changeFormField(formName, 'seller_response_time', msToMinutes(item.seller_response_time));
          changeFormField(formName, 'extension_time_1', extension_time_1);
          changeFormField(formName, 'extension_time_2', extension_time_2);
          changeFormField(formName, 'extension_time_3', extension_time_3);
          changeFormField(formName, 'extension_time_4', extension_time_4);
          changeFormField(
            formName,
            'frontline_end_date_time',
            item.frontline_end_date_time ? moment(item.frontline_end_date_time) : moment()
          );
          changeFormField(formName, 'id', item.id);
          this.handleEditLocationClick();
        }
      }
    ].filter(Boolean);
    // TODO: FIX THIS ASAP
    const formattedLocationsInterval = (locations || []).map(location => {
      return {
        ...location,
        end_time_spacing: this.formatEndTimeSpacing(location.end_time_spacing)
      };
    });
    return (
      <PageWrapper>
        <Table
          columnsHead={[
            { title: 'Location', key: 'name' },
            { title: 'Auction', key: 'auction_type' },
            { title: 'End Day', key: 'formatted_end_day' },
            { title: 'End Time', key: 'formatted_end_time' },
            { title: 'Interval [Minutes:Seconds]', key: 'end_time_spacing_view' },
            { title: 'Final Auction Shuffle', key: 'shuffle' },
            { title: 'Buyer Response Time', key: 'buyer_response_time_view' },
            { title: 'Seller Response Time', key: 'seller_response_time_view' },
            { title: '', key: '' }
          ]}
          columnsData={formattedLocationsInterval}
          rowsCount={locationsCount}
          buttons={buttons}
          page={page}
          rowsPerPage={perPage}
          loadMore={this.loadMore}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          loading={loading}
          noDataText={
            <>
              No locations found
              <br />
              Add new location or change filter
            </>
          }
        />
        <LocationCreateModal />
        <LocationEditModal selectedAuctionType={this.state.selectedAuctionType} />
      </PageWrapper>
    );
  }
}

const mapStateToProps = state => {
  let locations = [];
  if (state.locations.itemsData.rows) {
    locations = state.locations.itemsData.rows.flatMap(item => {
      return [
        {
          ...item,
          auction_type: 'Autoaxess',
          formatted_end_day: dayOfWeek[item.end_day],
          formatted_end_time: moment
            .utc(item.end_time, 'HH:mm')
            .local()
            .format('hh:mm A'),
          shuffle: `${dayOfWeek[item.shuffle_day]} ${moment
            .utc(item.shuffle_time, 'HH:mm')
            .local()
            .format('hh:mm A')}`,
          end_time_spacing_view: item.end_time_spacing,
          buyer_response_time_view: msToMinutes(item.buyer_response_time),
          seller_response_time_view: msToMinutes(item.seller_response_time)
        },
        {
          ...item,
          auction_type: 'Frontline',
          formatted_end_day: moment
            .utc(item.frontline_end_date_time ?? new Date())
            .local()
            .format('dddd DD.MM'),
          formatted_end_time: moment
            .utc(item.frontline_end_date_time)
            .local()
            .format('hh:mm A'),
          shuffle: '-',
          end_time_spacing_view: '-',
          buyer_response_time_view: '-',
          seller_response_time_view: '-'
        }
      ];
    });
  }
  return {
    locations,
    locationsCount: parseInt(state.locations.itemsData.count, 10) || 0,
    page: parseInt(state.locations.page, 10) || 0,
    perPage: parseInt(state.locations.perPage, 10) || LOCATIONS_PER_PAGE,
    locationName: state.locations.locationName || '',
    loading: state.locations.loading,
    currentAuction: state.auctions.current,
    activeAuctions: auctionApiSlice.endpoints.getActiveAuctions.select()(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchLocations: params => dispatch(listLocations(params).request),
  setPage: page => dispatch(locationsSetPage(page)),
  setPerPage: perPage => dispatch(locationsSetPerPage(perPage)),
  setStartDay: endDay => dispatch(locationsSetEndDay(endDay)),
  setLocationName: locationName => dispatch(locationsSetLocationName(locationName)),
  modalsToggle: key => dispatch(modalsToggle(key)),
  setLocationData: data => dispatch(setEditLocation(data)),
  changeFormField: (form, field, value) => dispatch(change(form, field, value)),
  getActiveAuction: () => dispatch(auctionApiSlice.endpoints.getActiveAuctions.initiate()),
  stopAuction: auctionId => dispatch(apiSlice.endpoints.stopAuction.initiate(auctionId)),
  successfulAuctionEnd: () =>
    dispatch(
      snackShow({
        message: 'Successfully stopped auction'
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsList);
