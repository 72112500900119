import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  SEND_CONTACT_FORM_REQUEST,
  SEND_CONTACT_FORM_SUCCESS,
  SEND_CONTACT_FORM_FAILURE,
  SEND_QUESTION_FORM_REQUEST,
  SEND_QUESTION_FORM_SUCCESS,
  SEND_QUESTION_FORM_FAILURE
} from '../constants/actionTypes';

export const DETAIL_PROFILE_FAILURE = 'DETAIL_PROFILE_FAILURE';
export const DETAIL_PROFILE_SUCCESS = 'DETAIL_PROFILE_SUCCESS';
export const DETAIL_PROFILE_REQUEST = 'DETAIL_PROFILE_REQUEST';

export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';

export const UPDATE_PROFILE_DOC_FAILURE = 'UPDATE_PROFILE_DOC_FAILURE';
export const UPDATE_PROFILE_DOC_REQUEST = 'UPDATE_PROFILE_DOC_REQUEST';
export const UPDATE_PROFILE_DOC_SUCCESS = 'UPDATE_PROFILE_DOC_SUCCESS';

export const detailProfile = data => ({
  request: { type: DETAIL_PROFILE_REQUEST, payload: data },
  success: { type: DETAIL_PROFILE_SUCCESS, payload: data },
  failure: { type: DETAIL_PROFILE_FAILURE, payload: data }
});

export const updateProfile = data => ({
  request: { type: UPDATE_PROFILE_REQUEST, payload: data },
  success: { type: UPDATE_PROFILE_SUCCESS, payload: data },
  failure: { type: UPDATE_PROFILE_FAILURE, payload: data }
});

export const updateProfileDoc = data => ({
  request: { type: UPDATE_PROFILE_DOC_REQUEST, payload: data },
  success: { type: UPDATE_PROFILE_DOC_SUCCESS, payload: data },
  failure: { type: UPDATE_PROFILE_DOC_FAILURE, payload: data }
});

export const addUser = data => ({
  request: { type: CREATE_USER_REQUEST, payload: data },
  success: { type: CREATE_USER_SUCCESS, payload: data },
  failure: { type: CREATE_USER_FAILURE, payload: data }
});

export const loginUser = data => ({
  request: { type: LOGIN_USER_REQUEST, payload: data },
  success: { type: LOGIN_USER_SUCCESS, payload: data },
});

export const sendContactForm = data => ({
  request: { type: SEND_CONTACT_FORM_REQUEST, payload: data },
  success: { type: SEND_CONTACT_FORM_SUCCESS, payload: data },
  failure: { type: SEND_CONTACT_FORM_FAILURE, payload: data }
});

export const sendQuestionForm = data => ({
  request: { type: SEND_QUESTION_FORM_REQUEST, payload: data },
  success: { type: SEND_QUESTION_FORM_SUCCESS, payload: data },
  failure: { type: SEND_QUESTION_FORM_FAILURE, payload: data }
});
