import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { Field, useField } from 'react-final-form';
import { AddCheckboxIcon } from '../../icons/AddCheckboxIcon';
import { NotesField } from '../NotesField';
import { useUpdateCheckboxMutation } from '../../../../services/api/upload-vehicle';
import { EditingCheckboxField } from './EditingCheckboxField';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';

const FieldWrapper = styled.div<{ index: number; totalItems: number; gridArea?: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  grid-area: ${props => props.gridArea};
`;

const AddButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: 8px;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: rgba(102, 102, 102, 1);
`;

type Props = {
  name: string;
  isLast: boolean;
  i: number;
  sectionId: keyof SectionConfig;
  gridArea?: string;
};

export const CheckboxField = ({ sectionId, name, isLast, i, gridArea }: Props) => {
  const { fields } = useFieldArray(`${sectionId}.checkboxes`);

  const isEditing = isLast && fields.length && fields.value[fields.length - 1].isEditing;

  const [updateCheckbox] = useUpdateCheckboxMutation();

  const {
    input: { value: checkboxId }
  } = useField(`${name}.id`);

  if (isEditing) {
    return <EditingCheckboxField sectionId={sectionId} name={name} />;
  }

  return (
    <FieldWrapper gridArea={gridArea} totalItems={fields.length!} index={i}>
      <NotesField name={name} />
      <Field name={`${name}.selected`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '24px', height: '24px', padding: 0 }}
            checked={props.input.value}
            onChange={e => {
              updateCheckbox({ checkboxId, payload: { selected: e.target.checked } });
              props.input.onChange(e.target.checked);
            }}
          />
        )}
      </Field>
      <Field name={`${name}.label`}>{props => <Label>{props.input.value}</Label>}</Field>
      {isLast && (
        <AddButtonWrapper
          onClick={() =>
            fields.push({
              selected: true,
              label: '',
              notes: '',
              isEditing: true
            })
          }
        >
          <AddCheckboxIcon />
        </AddButtonWrapper>
      )}
    </FieldWrapper>
  );
};
