import { DragEndEvent } from '@dnd-kit/core';
import { useDispatch, useSelector } from 'react-redux';
import api, {
  useAddRequestToPoolMutation,
  useGetListerRequestsByDayQuery,
  useListerCreatePoolMutation,
  useSetListRequestOrderMutation
} from 'services/api/list-vehicles';
import moment from 'moment';
import { clearDraggableItem } from 'services/listerDashboard/listerDashboardSlice';

export const useHandleDragEnd = () => {
  const dispatch = useDispatch<any>();
  const [setListRequestOrder] = useSetListRequestOrderMutation();
  const user = useSelector((state: any) => state.user.user);
  const { data } = useGetListerRequestsByDayQuery();
  const [createNewPool] = useListerCreatePoolMutation();

  const [addRequestToPool] = useAddRequestToPoolMutation();

  return (e: DragEndEvent) => {
    const { active, over } = e;
    const sortableId = active.data.current?.sortable?.containerId;

    dispatch(clearDraggableItem());

    if (!sortableId) return;

    const [, dayNumber] = sortableId.split('-');

    if (!over || !active) return;

    if (typeof over.id === 'string' && over.id.startsWith('NoDataDropZone')) {
      const [, overDayNumber] = over.id.split('-').map(Number);
      const activeListItem = data?.[dayNumber]?.[0]?.listRequests?.find(e => e.id === active.id);

      const startOfTheWeek = moment()
        .locale('en-us')
        .startOf('week');

      const date = startOfTheWeek.add(overDayNumber - 1, 'days');

      dispatch(
        api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
          if (draft[overDayNumber][0]) {
            draft[overDayNumber][0].listRequests = [activeListItem!];
            addRequestToPool({ poolId: draft[overDayNumber][0].id, requestId: active.id as number });
          } else {
            draft[overDayNumber][0] = {
              id: -1,
              lister_id: user.id,
              listRequests: [activeListItem!]
            };

            createNewPool({
              date: date.format('YYYY-MM-DD'),
              listRequestId: active.id,
              listerId: user.id
            });
          }

          draft[dayNumber][0].listRequests = draft[dayNumber][0].listRequests.filter(e => e.id !== active.id);
        })
      );

      return;
    }

    dispatch(
      api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
        const pool = draft[dayNumber]?.[0];
        if (!pool) return;

        const items = [...pool.listRequests];
        addRequestToPool({ poolId: pool.id, requestId: active.id as number });

        const orderMap = items.reduce((acc, curr, i) => {
          return { ...acc, [curr.id]: i };
        }, {} as Record<number, number>);

        setListRequestOrder(orderMap);
      })
    );
  };
};
