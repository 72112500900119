import styled from 'styled-components';
import { VehicleSection } from '../../../../../../types/vehicle';

const colorMap: Record<string, string> = {
  average: 'rgba(243, 175, 103, 1)',
  bad: 'rgba(175, 41, 48, 1)',
  good: 'rgba(69, 155, 74, 1)',
  blue: 'rgba(57, 148, 222, 1)'
};

const Wrapper = styled.div`
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
`;

const BarWrapper = styled.div<{ basis: number }>`
  display: flex;
  flex-direction: column;
  gap: 3.8px;
  align-items: center;
  flex: 1 1 ${props => `${props.basis}%`};
`;

const Bar = styled.div<{ color: string }>`
  height: 2.85px;
  width: 100%;
  background: ${props => props.color};
`;

const Percent = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
`;

type GroupedGrades = { bad: number; good: number; average: number; blue: number };

const badGradeColor = ['red', 'light_red'];
const averageGradeColor = ['yellow'];
const blueGrade = ['dark_blue', 'blue'];
const goodGrade = ['dark_green', 'green'];

export const GradePercentage = ({ vehicleSections }: { vehicleSections: VehicleSection[] }) => {
  const { good, bad, average, blue } = vehicleSections.reduce(
    (acc, curr) => {
      if (!curr.grade) return acc;
      if (badGradeColor.includes(curr.grade.color)) {
        acc.bad += 1;
      }
      if (averageGradeColor.includes(curr.grade.color)) {
        acc.average += 1;
      }
      if (goodGrade.includes(curr.grade.color)) {
        acc.good += 1;
      }
      if (blueGrade.includes(curr.grade.color)) {
        acc.blue += 1;
      }

      return acc;
    },
    { bad: 0, good: 0, average: 0, blue: 0 } as GroupedGrades
  );

  const total = bad + good + average + blue || 1;

  if (total === 0) {
    return null;
  }

  const badPercentage = bad / total;
  const goodPercentage = good / total;
  const averagePercentage = average / total;
  const bluePercentage = blue / total;

  const orderedPercents = [
    { type: 'good', value: Math.round(goodPercentage * 100) },
    { type: 'average', value: Math.round(averagePercentage * 100) },
    { type: 'blue', value: Math.round(bluePercentage * 100) },
    { type: 'bad', value: Math.round(badPercentage * 100) }
  ].filter(e => e.value !== 0);

  return (
    <Wrapper>
      {orderedPercents.map(({ type, value }) => (
        <BarWrapper basis={value}>
          <Bar color={colorMap[type]} />
          <Percent color={colorMap[type]}>{`${value}%`}</Percent>
        </BarWrapper>
      ))}
    </Wrapper>
  );
};
