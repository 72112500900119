import { MenuItem } from '@material-ui/core';
import { FormTextField } from 'components';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { setStatusFilter } from 'services/sellerVehicles/sellerVehiclesSlice';
import styled from 'styled-components';

const StyledForm = styled.form`
  padding: 0 16px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledField = styled(Field)`
  & * input::placeholder {
    font-size: 18px;
  }
`;

export const MobileVehicleListFilters = () => {
  const onSubmit = () => {};
  const dispatch = useDispatch();

  return (
    <Form onSubmit={onSubmit}>
      {props => {
        return (
          <StyledForm>
            <StyledField fullWidth component={FormTextField} name="status" label="Filter by Car Status" select>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="awarded">Awarded</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="pulled">Pulled</MenuItem>
              <MenuItem value="deactivated">Deactivated</MenuItem>
            </StyledField>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                dispatch(setStatusFilter(values.status));
              }}
            />
          </StyledForm>
        );
      }}
    </Form>
  );
};
