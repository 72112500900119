import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form';
import styled from 'styled-components';
import { required as validateRequired } from '../../../../utils/validators';
import { listUsers } from '../../../../actions';
import { roles, USER_STATUSES } from '../../../../constants';
import FormAutocompleteSelect from '../../../../components/Form/FormAutocompleteSelect';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { toggleIsSellerLocked } from 'services/sellerLock/sellerLockSlice';

const StyledSelectField = styled(Field)`
  overflow: visible;
`;
const formName = 'sellersForm';

const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 60px;

  div:first-child {
    flex: 1 1 80%;
  }
`;

class SellersForm extends Component {
  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  updateLockedSeller = sellerObj => {
    const { isSellerLocked, isEdit } = this.props;
    if (!isSellerLocked || isEdit) return;
    localStorage.setItem('locked_seller', sellerObj ? sellerObj?.value : null);
  };

  render() {
    const { users, sellerId, toggleSellerLocked, isSellerLocked, isEdit, data1 } = this.props;
    let selectedUser;
    if (sellerId && sellerId.value) {
      selectedUser = users.find(seller => seller.value === sellerId.value);
    } else {
      selectedUser = users.find(seller => seller.value === sellerId);
    }

    return (
      <StyledForm id="sellers-form">
        <StyledSelectField
          name="seller"
          value={selectedUser}
          onChange={e => this.updateLockedSeller(e)}
          component={FormAutocompleteSelect}
          label="Select seller"
          options={users}
          fullWidth
          validate={[validateRequired]}
          select
          props={{
            defaultValue: selectedUser || ''
          }}
        />
        {!isEdit && (
          <FormControlLabel
            label="Lock seller"
            control={
              <Checkbox
                checked={isSellerLocked}
                style={{ padding: 0, paddingRight: 6 }}
                onChange={() => toggleSellerLocked(sellerId)}
              />
            }
          />
        )}
      </StyledForm>
    );
  }
}

const selector = formValueSelector(formName);

const mapStateToProps = state => ({
  users: (
    state.users.items.filter(item => item.role === roles.SELLER && item.status !== USER_STATUSES.DEACTIVATED) || []
  ).map(user => ({
    value: user.id,
    label: user.dealership_name
  })),
  currentForm: state.form[formName],
  sellerId: selector(state, 'seller'),
  isSellerLocked: state.sellerLock.is_seller_locked,
  // auctionType: getFormValues('vinForm')(state)?.auction_type,
  data1: getFormValues('addVehicleForm')(state)
});

const mapDispatchToProps = dispatch => ({
  toggleSellerLocked: sellerId => dispatch(toggleIsSellerLocked(sellerId)),
  fetchUsers: params => dispatch(listUsers(params).request)
});

export default reduxForm({
  form: formName
})(connect(mapStateToProps, mapDispatchToProps)(SellersForm));
