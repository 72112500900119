import { useDispatch } from 'react-redux';
import { setDraggableItem } from '../../../services/listVehiclesSlice/listVehiclesSlice';
import { DragStartEvent } from '@dnd-kit/core';

export const useHandleDragStart = () => {
  const dispatch = useDispatch();

  return (e: DragStartEvent) => {
    dispatch(
      setDraggableItem(e.active.data.current as any)
    );
  };
};
