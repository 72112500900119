import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Grid, Button, Hidden, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import { FormTextField } from '../../../../components/Form';
import { sendQuestionForm } from '../../../../actions';
import { required, maxLength } from '../../../../utils/validators';
import { SectionTitle, SectionWrapper } from './index';

const maxMessageLength = maxLength(255);

const StyledFlexGrowGrid = styled(Grid)`
  flex-grow: 1;
  padding-right: 5px;
`;

const StyledLoader = styled(CircularProgress)`
  margin-right: 8px;
`;

const QuestionBlock = ({
  handleSubmit,
  submitting,
  reset,
  buyer,
  vehicleId,
  submitQuestion,
  currentVehicle
}) => {
  const submit = ({ message }) => {
    const data = {
      vehicle: `${currentVehicle.year} ${currentVehicle.make} ${
        currentVehicle.model
      } ${currentVehicle.vin}`,
      fullName: `${buyer.first_name} ${buyer.last_name}`,
      email: buyer.email,
      phone: buyer.buyer.phone_number,
      vehicleId,
      message
    };

    return submitQuestion(data).then(reset);
  };

  return (
    <SectionWrapper>
      <form onSubmit={handleSubmit(submit)}>
        <SectionTitle>Ask a Question</SectionTitle>
        <Grid container justify="space-between" alignItems="center">
          <Hidden mdDown>
            <Grid item>
              {submitting ? (
                <StyledLoader size={30} />
              ) : (
                <i className="material-icons" style={{ marginRight: '10px' }}>
                  add_comment
                </i>
              )}
            </Grid>
          </Hidden>
          <StyledFlexGrowGrid item>
            <Field
              multiline
              fullWidth
              name="message"
              label="Ask your question"
              placeholder="Ask your question"
              component={FormTextField}
              validate={[required, maxMessageLength]}
            />
          </StyledFlexGrowGrid>
          <Grid item>
            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </SectionWrapper>
  );
};

const mapDispatchtoProps = dispatch => ({
  submitQuestion: data =>
    new Promise((resolve, reject) => {
      dispatch(sendQuestionForm({ data, resolve, reject }).request);
    })
});

export default compose(
  reduxForm({
    form: 'questionForm'
  }),
  connect(
    null,
    mapDispatchtoProps
  )
)(QuestionBlock);
