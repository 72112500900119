/* eslint-disable no-else-return */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Stepper } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { addUser as addUserRequest } from '../../../actions';
import { USER_STATUSES, ONBOARDING_STEPS, DATE_FORMAT } from '../../../constants';
import {
  FirstStep,
  SecondBuyerStep,
  SecondSellerStep,
  ThirdSellerStep,
  ThirdBuyerStep,
  FourthBuyerStep,
  FifthBuyerStep
} from './containers/index';
import OfficePhoneNumberBanner from './components/OfficePhoneNumberBanner';
import TermsAndConditionsModal from './components/TermsAndConditionsModal';
import { AutoaxessLogo } from '../components/AutoaxessLogo';
import { useSearchParams } from '../../../hooks';
import RegistrationStepper from './containers/stepper';
import { useMeQuery } from 'services/api/auth';

const StyledPaper = styled(Paper)`
  && {
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0;
    padding-bottom: 40px;

    @media (max-width: 768px) {
      max-width: unset;
      margin: 0;
      box-shadow: none;
    }
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StyledStepper = styled(Stepper)`
  && {
    justify-content: center;
    max-width: ${props => (props.role === 'buyer' ? '100%' : '860px')};
    margin: 0 auto;
    @media (max-width: 760px) {
      display: none;
    }
  }
`;

const lastStepToCurrentStep = {
  [ONBOARDING_STEPS.SECOND_SELLER_STEP]: 1,
  [ONBOARDING_STEPS.THIRD_SELLER_STEP]: 2,
  [ONBOARDING_STEPS.SECOND_BUYER_STEP]: 1,
  [ONBOARDING_STEPS.THIRD_BUYER_STEP]: 2,
  [ONBOARDING_STEPS.FOURTH_BUYER_STEP]: 3,
  [ONBOARDING_STEPS.FIFTH_BUYER_STEP]: 4
};

const RegistrationFormFC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const { data: user } = useMeQuery();

  const searchParams = useSearchParams();

  const role = (() => {
    if (user && user.role) return user.role;
    const searchParamsRole = searchParams.get('role');
    if (searchParamsRole) return searchParamsRole;
    return 'buyer';
  })();

  useEffect(() => {
    if (user && user.status === USER_STATUSES.NEED_TO_COMPLETE_SIGN_UP) {
      setCurrentStep(lastStepToCurrentStep[user.last_step] || 0);
    } else {
      setCurrentStep(0);
    }
  }, [user]);

  const renderStep = () => {
    if (role === 'buyer') {
      return buyerSteps();
    } else if (role === 'seller') {
      return sellerSteps();
    }
    return <FirstStep nextStep={() => setStep(0)} role={role} />;
  };

  const sellerSteps = () => {
    switch (currentStep) {
      case 0:
        return <FirstStep nextStep={() => setStep(1)} role={role} />;
      case 1:
        return <SecondSellerStep nextStep={() => setStep(2)} />;
      case 2:
        return <ThirdSellerStep prevStep={() => setStep(1)} />;
      default:
        return null;
    }
  };

  const buyerSteps = () => {
    switch (currentStep) {
      case 0:
        return <FirstStep nextStep={() => setStep(1)} role={role} />;
      case 1:
        return <SecondBuyerStep nextStep={() => setStep(2)} />;
      case 2:
        return <ThirdBuyerStep nextStep={() => setStep(3)} prevStep={() => setStep(1)} />;
      case 3:
        return <FourthBuyerStep nextStep={() => setStep(4)} prevStep={() => setStep(2)} />;
      case 4:
        return <FifthBuyerStep prevStep={() => setStep(3)} nextStep={finishOnboarding} />;
      default:
        return null;
    }
  };

  const setStep = newStep => {
    setCurrentStep(newStep);
    window.scrollTo(0, 0);
  };

  const finishOnboarding = values => {
    if (values.dealer_license_expiry_date) {
      // eslint-disable-next-line
      values = {
        ...values,
        dealer_license_expiry_date: moment.utc(values.dealer_license_expiry_date, DATE_FORMAT).format()
      };
    }
    if (values.credit_card_number) {
      // eslint-disable-next-line
      values = {
        ...values,
        credit_card_number: (values.credit_card_number || '').replace(/\s/g, '')
      };
    }
    if (values.credit_card_expiry_date) {
      // eslint-disable-next-line
      values = {
        ...values,
        credit_card_expiry_date: values.credit_card_expiry_date
          ? moment.utc(values.credit_card_expiry_date || '', 'MM/YY').format('YYYY-MM')
          : ''
      };
    }
    const fieldsToFormat = ['phone_number', 'secondary_contact_phone', 'store_number'];
    fieldsToFormat.forEach(field => {
      if (values[field]) {
        // eslint-disable-next-line
        values = {
          ...values,
          [field]: (values[field] || '').replace(/[\D]/g, '')
        };
      }
    });
    addUser({
      ...values,
      // Replace unused spaces from redux-form normalizer
      purchase_option: getSelectedMultipleCheckboxes(values.purchase_option),
      purchase_method: getSelectedMultipleCheckboxes(values.purchase_method),
      finished: true
    });
  };

  const getSelectedMultipleCheckboxes = (checkboxes = {}) =>
    Object.keys(checkboxes).reduce((acc, key) => {
      if (checkboxes[key]) {
        return [...acc, key];
      }
      return acc;
    }, []);

  const isMobile = true;

  return (
    <>
      <StyledPaper>
        <AutoaxessLogo />
        <RegistrationStepper role={role} activeStep={currentStep} />
        <StyledFormWrapper>{renderStep()}</StyledFormWrapper>
      </StyledPaper>
      {!isMobile && <OfficePhoneNumberBanner />}
      <TermsAndConditionsModal />
    </>
  );
};

export default RegistrationFormFC;
