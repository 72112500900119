import { OfferAuction } from '../types/vehicle';

export const usePublicOfferAmount = (offerAuction?: OfferAuction | null) => {
  const adminOffer = offerAuction?.privateOffers?.find(offer => offer.type === 'public' && offer.is_admin);
  const sellerOffer = offerAuction?.privateOffers?.find(offer => offer.sender.role === 'seller' && !offer.is_admin);

  return {
    adminAmount: adminOffer?.amount ?? 0,
    sellerAmount: sellerOffer?.amount ?? 0
  };
};
