import React from 'react';
import styled from 'styled-components';
import { ADMIN_VEHICLE_STATUS, DATE_FORMAT, PAYMENT_METHODS, VEHICLE_STATUSES } from '../../../../constants';
import { EasyPayStatus } from '../../../../components/EasyPay';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';
import { useSelector } from 'react-redux';
import { useAdminOfferAuction } from '../hooks';
import { FormattedAmount, Timer } from 'components';
import { useTimerConfig } from 'hooks';
import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  min-width: 132px;
`;

const AmountWrapper = styled.span`
  font-weight: 500;
`;

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const statusText = {
  awarded: 'Awarded',
  pulled: 'Pulled',
  active: 'Active',
  in_progress: 'In Progress',
  bid_undecided: 'Bid Undecided',
  seller_countered: 'Seller Countered',
  seller_not_countered: 'Seller Counter Expired',
  seller_counter_sent: 'Seller Counter Sent to Buyer',
  admin_counter_sent: 'AutoAxess Counter Sent to Buyer',
  seller_accepted: 'Seller Accepted',
  undecided: 'Undecided',
  no_bids: 'No Highest Bid',
  buyer_counter_sent: 'Counter Sent to Seller',
  buyer_not_countered: 'Buyer Counter Expired',
  public_admin_counter_sent: 'Autoaxess Counter Sent to Buyers'
};

const WrapperComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const statusColor = {
  in_progress: '#009F0F',
  awarded: '#009F0F',
  active: '#009F0F',
  pulled: '#E47E00',
  bid_undecided: '#E47E00',
  seller_countered: '#E47E00',
  seller_counter_sent: '#E47E00',
  admin_counter_sent: '#E47E00',
  seller_accepted: '#E47E00',
  undecided: '#E47E00',
  no_bids: '#E47E00',
  buyer_counter_sent: '#E47E00',
  buyer_not_countered: '#E47E00',
  public_admin_counter_sent: '#E47E00',
  seller_not_countered: '#E47E00'
};

const BidStatus = ({ vehicle }) => {
  const user = useSelector(state => state.user.user);

  const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;
  const awarded = vehicle.status === VEHICLE_STATUSES.AWARDED;
  const buyFeeCharged = vehicle.charge_completed;

  const offerAuction = useAdminOfferAuction(vehicle.id);
  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  const sellerAcceptedAmount = (() => {
    if (status !== ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED) return 0;
    if (vehicle.buyer_id) return vehicle.highest_bid?.amount ?? 0;
    return offerAuction?.offerBids?.find(el => el.status === 'seller_accepted')?.amount ?? 0;
  })();

  const { timer } = useTimerConfig(vehicle, offerAuction);

  const timerColorsConfig = {
    active: true,
    stale: true,
    activeColor: statusColor[status],
    staleColor: '#000',
    staleColorThreshold: 1
  };

  return (
    <>
      {easyPaid && <EasyPayStatus amountHidden vehicle={vehicle} />}

      <Wrapper>
        {!easyPaid && (
          <StyledStatus style={{ minWidth: 'fit-content' }} statusColor={statusColor[status]}>
            {statusText[status] || status}
          </StyledStatus>
        )}
        {status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED && (
          <AmountWrapper>
            <FormattedAmount amount={sellerAcceptedAmount} />
          </AmountWrapper>
        )}
        {awarded && !buyFeeCharged && (
          <StyledStatus statusColor={statusColor.undecided}>(Fee not charged)</StyledStatus>
        )}
      </Wrapper>
      {awarded ? (
        <div>{moment(vehicle.date_status).format(DATE_FORMAT)}</div>
      ) : (
        <Timer toDate={timer} withColors colorsConfig={timerColorsConfig} />
      )}
    </>
  );
};

export default BidStatus;
