import styled from 'styled-components';
import { useGetVehicleByIdQuery } from '../../../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../../../../CreateVehicle/hooks/useGetVehicleId';
import { useGetSectionConfigQuery } from '../../../../../../services/api/vehicle-sections';
import { SectionConfig } from '../../../../../../services/api/vehicle-sections/types';
import { useViewVehicleContext } from '../../../context/ViewVehicleContext';
import { GradePercentage } from './GradePercentage';
import Slider from 'react-slick';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div<{ showAfterFade: boolean; showBeforeFade: boolean }>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;
  gap: 32px;
  padding-right: 20px;

  &:before {
    content: '';
    display: ${props => (props.showBeforeFade ? 'block' : 'none')};
    right: 0;
    left: 0;
    width: 250px;
    position: absolute;
    height: 18px;
    pointer-events: none;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:after {
    content: '';
    display: ${props => (props.showAfterFade ? 'block' : 'none')};
    right: 0;
    top: 0;
    width: 250px;
    position: absolute;
    height: 18px;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.div<{ isActive: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  min-width: fit-content;
  line-height: 18.75px;
  cursor: pointer;
  color: ${props => (props.isActive ? '#000' : 'rgba(153, 153, 153, 1)')};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50px;
  min-width: fit-content;
`;

export const ViewVehicleSectionsHeader = () => {
  const { activeSection, setActiveSection } = useViewVehicleContext();

  const { data: config } = useGetSectionConfigQuery();

  const [scrollOffset, setScrollOffset] = useState(0);
  const [widthDiff, setWidthDiff] = useState(0);

  const vehicleId = useGetVehicleId();
  const { data: vehicle } = useGetVehicleByIdQuery(vehicleId);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && vehicle) {
      setWidthDiff(ref.current.scrollWidth - ref.current.offsetWidth);
    }
  }, [vehicle, config]);

  useEffect(() => {
    const checkScrollPosition = (e: any) => {
      if (!ref.current) return;
      setScrollOffset(ref.current.scrollLeft);
    };
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', checkScrollPosition, false);
      return () => ref.current?.removeEventListener('scroll', checkScrollPosition, false);
    }
  }, [ref.current]);

  if (!config || !vehicle) return null;

  return (
    <Wrapper>
      <Container
        id="section-header-container"
        ref={ref}
        showBeforeFade={scrollOffset > 20}
        showAfterFade={scrollOffset < widthDiff}
      >
        {Object.entries(vehicle.groupedSections).map(([key, vehicleSections]) => {
          const section = key as keyof SectionConfig;
          const isActive = activeSection === section;

          const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveSection(section);
            (e.target as any).scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
          };

          const isCheckboxSection = section === 'title_issues' || section === 'power_equipment';
          const hasSelectedCheckboxes = isCheckboxSection && vehicleSections?.[0]?.checkboxes?.some(c => c.selected);

          if (isCheckboxSection && !hasSelectedCheckboxes) {
            return null;
          }

          return (
            <TitleContainer>
              <Title onClick={handleClick} isActive={isActive} key={section}>
                {config[section].label}
              </Title>
              {isActive && <GradePercentage vehicleSections={vehicleSections} />}
            </TitleContainer>
          );
        })}
      </Container>
    </Wrapper>
  );
};
