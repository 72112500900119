import React from 'react';
import { Typography, Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  button {
    margin-left: 5px;
  }
`;

export default props => {
  let result = null;
  if (props.error) {
    // When the loader has errored
    result = (
      <StyledLoaderWrapper>
        <Typography>Loading failed!</Typography>{' '}
        <Button
          onClick={props.retry}
          type="button"
          variant="contained"
          color="primary"
        >
          Retry
        </Button>
      </StyledLoaderWrapper>
    );
  } else if (props.timedOut) {
    // When the loader has taken longer than the timeout
    result = (
      <StyledLoaderWrapper>
        <Typography>Taking a long time...</Typography>{' '}
        <Button
          onClick={props.retry}
          type="button"
          variant="contained"
          color="primary"
        >
          Retry
        </Button>
      </StyledLoaderWrapper>
    );
  } else if (props.pastDelay) {
    // When the loader has taken longer than the delay
    result = (
      <StyledLoaderWrapper>
        <Typography>Loading...</Typography>
      </StyledLoaderWrapper>
    );
  }
  return result;
};
