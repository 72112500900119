import { useGetChatMessagesQuery } from '../../../../services/api/list-vehicles';
import { useGetSelectedRequest } from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import { NotesIcon } from '../../../Icons/NotesIcon';
import styled from 'styled-components';
import { Popover, withStyles } from '@material-ui/core';
import { ListVehicleChat } from '../Chat';
import { useState } from 'react';

type Props = {
  isEditable?: boolean;
  poolId: number;
  value?: string;
  onChange?: (value: string) => void;
  classes?: any;
};

const styles = () => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#616161',
    fontSize: 14,
    opacity: 1,
    width: 400,
    maxWidth: '100vw',
    marginTop: 6
  },
  popper: {
    backgroundColor: '#ffffff',
    opacity: 1,
    wordBreak: 'break-word',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    top: '20px !important'
  }
});

const Wrapper = styled.div<{ isEditable: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px;
  background: ${props => (props.isEditable ? '#f5f5f5' : 'transparent')};
`;

export const NotesComponent = withStyles(styles as any)(({ poolId }: Props) => {
  const selectedRequest = useGetSelectedRequest();
  const { data: messages } = useGetChatMessagesQuery(selectedRequest?.id!, {
    skip: selectedRequest == null
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const renderNotes = Boolean(selectedRequest && selectedRequest.list_vehicle_pool_id === poolId);

  const handleMouseOver = (e: any) => {
    if (!renderNotes) return;

    setAnchorEl(e.currentTarget);
  };

  const handleMouseOut = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} isEditable={false}>
      <NotesIcon isActive={renderNotes} />
      <Popover
        id="chat-popover"
        // classes={{ paper: classes.tooltip }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        disableRestoreFocus
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ListVehicleChat listRequestId={selectedRequest?.id} messages={messages || []} />
      </Popover>
    </Wrapper>
  );
});
