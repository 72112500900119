import styled from 'styled-components';
import { Field, useField } from 'react-final-form';
import { useUpdateSectionImageMutation } from 'services/api/upload-vehicle';
import { ImageField } from '../../../../types';
import { Checkbox } from '@material-ui/core';
import { useGetVehicleId } from '../../../../hooks/useGetVehicleId';

const DamageCheckboxWrapper = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 4px 8px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #fff;
  z-index: 999;
`;

type Props = {
  name: string;
};

export const RenderDamagedCheckbox = ({ name }: Props) => {
  const { input } = useField<ImageField>(name);

  const vehicleId = useGetVehicleId();

  const [updateImage] = useUpdateSectionImageMutation();

  const handleChange = async (newIsDamaged: boolean) => {
    if (!input.value.id) return;

    await updateImage({
      id: input.value.id,
      vehicleId,
      is_damaged: newIsDamaged
    });
  };

  return (
    <DamageCheckboxWrapper>
      <Field type="checkbox" name={`${name}.is_damaged`}>
        {props => {
          return (
            <Checkbox
              checkedIcon={<CheckedIcon />}
              icon={<UnCheckedIcon />}
              style={{
                padding: 0,
                borderRadius: '4px'
              }}
              inputProps={{
                style: {
                  width: '16px',
                  height: '16px'
                }
              }}
              value={String(props.input.checked)}
              checked={props.input.checked}
              onChange={e => {
                props.input.onChange(e);
                handleChange(e.target.checked);
              }}
            />
          );
        }}
      </Field>
      <span>Damage</span>
    </DamageCheckboxWrapper>
  );
};

const CheckedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.06667 10.8L11.7667 6.1L10.8333 5.16667L7.06667 8.93333L5.16667 7.03333L4.23333 7.96667L7.06667 10.8ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333Z"
      fill="white"
    />
  </svg>
);

const UnCheckedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5C5.44772 5 5 5.44772 5 6V13V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V13V6C19 5.44772 18.5523 5 18 5H6ZM3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V13V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V13V6Z"
      fill="#fff"
    />
  </svg>
);
