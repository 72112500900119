import React from 'react';
import { Field } from 'react-final-form';
import { MenuItem } from '@material-ui/core';
import { FieldWrapper } from './index';
import { FormTextField } from '../../../../components/Form/index';
import { composeValidators, maxLength, required } from '../../../../utils/validators';
import { ADDRESS_MAX_STRING_LENGTH, REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import STATES from '../../../../constants/states';
import OutlinedFormTextField from 'components/Form/FinalForm/OutlinedFormTextField';
import styled from 'styled-components';
import { ErrorIcon } from 'components/Icons/ErrorIcon';
import OutlinedFormSelectField from 'components/Form/FinalForm/OutlinedFormSelectField';

const addressMaxStringLength = maxLength(ADDRESS_MAX_STRING_LENGTH);
const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);
const zipCodeValidation = value =>
  value && !/^[0-9]{5}(?:-[0-9]{4})?$/.test(value) ? 'ZIP Code have invalid format' : undefined;

const GridWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
`;

const AddressComponent = () => (
  <>
    <OutlinedFormTextField
      name="street"
      label="Street Address*"
      placeholder="Street Address"
      validate={composeValidators(required, addressMaxStringLength)}
    />
    <OutlinedFormTextField
      name="city"
      label="City*"
      placeholder="City"
      validate={composeValidators(required, maxStringLength)}
    />
    <GridWrapper>
      <OutlinedFormSelectField
        name="state"
        label={'State*'}
        placeholder={'State'}
        validate={required}
        options={STATES.map(state => ({ label: state.name, value: state.abbreviation }))}
      />
      {/* <Field name="state" validate={required}>
        {fieldProps => {
          const hasError = (fieldProps.meta.touched && fieldProps.meta.error) || fieldProps.meta.submitError;
          return (
            <FormTextField
              input={fieldProps.input}
              meta={fieldProps.meta}
              label={'State*'}
              placeholder={'State'}
              fullWidth
              Icon={hasError ? ErrorIcon : null}
              iconOptions={{
                position: 'end'
              }}
              select
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 400
                  }
                }
              }}
              styleVariant={hasError ? 'outlined_error' : 'outlined'}
            >
              {STATES.map(state => (
                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </MenuItem>
              ))}
            </FormTextField>
          );
        }}
      </Field> */}

      <OutlinedFormTextField
        name="zip"
        label="ZIP*"
        placeholder="ZIP code"
        fullWidth
        validate={composeValidators(required, zipCodeValidation)}
      />
    </GridWrapper>
  </>
);

export default AddressComponent;
