import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { FormattedAmount, Table } from '../../../../components';
import { EasyPayStatus } from '../../../../components/EasyPay';
import { PAYMENT_METHODS_STATUSES, DATE_FORMAT } from '../../../../constants';
import { Filter } from '../components';
import TransactionVehiclePopover from '../components/TransactionVehiclePopover';
import { useGetEasyPayBuyerTransactionsQuery } from 'services/api/transactions';
import { setPage } from 'services/transactions/transactionsSlice';
import { MobileHorizontalCard } from '../components/MobileHorizontalCard';
import { useWindowSize } from 'usehooks-ts';
import { cutTextElipsis } from 'utils';
import { Delimiter } from 'components/Table/common';

const StyledTableWrapper = styled.div`
  tr td:first-child {
    width: 80px;
  }
  tr {
    height: 105px;
  }
  tr td:last-child {
    width: 130px;
  }
  tr td {
    font-weight: 700;
  }

  tr td:nth-child(2) {
    max-width: ${props => (props.isMobilePortrait ? '20px' : 'none')};
  }

  @media (max-width: 800px) {
    thead tr {
      display: none;
    }
  }
`;

const EasyPayTabFC = props => {
  const { page, perPage, filters } = useSelector(state => state.transactions.easyPay);
  const { data, isFetching } = useGetEasyPayBuyerTransactionsQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const dispatch = useDispatch();

  const transactions = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const transactionsCount = useMemo(() => data?.count ?? 0, [data]);

  const loadMore = () => {
    if (perPage * page > transactionsCount) return;
    dispatch(setPage({ page: page + 1, tab: 'easyPay' }));
  };

  const history = useHistory();

  const buttons = [
    {
      title: 'View',
      color: 'secondary',
      handleClick: item => history.push(`/vehicles/${item.id}/view`),
      isRounded: true,
      withShadow: true,
      fullWidth: true
    },
    {
      title: 'Easy Pay',
      color: 'secondary',
      conditionalRendering: transaction => transaction.payment_status === PAYMENT_METHODS_STATUSES.DECLINED,
      handleClick: item => history.push(`/buyer/bids/${item.id}/easy-pay`),
      isRounded: true,
      withShadow: true
    }
  ];

  const { width = 0, height = 0 } = useWindowSize();

  const columns = (() => {
    if (width > 800) {
      return [
        { title: 'Auction ID', key: 'auction_id' },
        { title: 'Car Details / Description', key: 'vehicle_detail' },
        { title: 'Seller', key: 'seller_dealership_name' },
        { title: 'Date Approved', key: 'approval_date' },
        { title: 'Award Price', key: 'amount' },
        { title: 'Status', key: 'status' },
        { title: '', key: 'actions', cellClass: 'text-right' }
      ];
    }

    if (width > height) {
      return [
        {
          title: '',
          key: 'mobile_vertical_view_main'
        },
        {
          title: '',
          key: 'delimiter'
        },
        {
          title: '',
          key: 'mobile_vertical_view_secondary'
        },
        {
          title: '',
          key: 'actions'
        }
      ];
    }

    return [
      {
        title: '',
        key: 'mobile_horizontal_view'
      },
      {
        title: '',
        key: 'actions'
      }
    ];
  })();

  const isMobilePortrait = width <= 800 && width > height;

  return (
    <>
      <Filter tab="easy_pay" {...props} />
      <StyledTableWrapper isMobilePortrait={isMobilePortrait}>
        <Table
          withMobileView
          columnsHead={columns}
          columnsData={mapTransactions(transactions)}
          rowsCount={transactionsCount}
          buttons={buttons}
          page={page}
          rowsPerPage={perPage}
          loadMore={loadMore}
          loading={isFetching}
        />
      </StyledTableWrapper>
    </>
  );
};

const mapTransactions = transactions =>
  transactions.map(vehicle => {
    const { id, payment_status, user } = vehicle;
    const auctionColumn = { label: 'Auction ID:', value: vehicle.auction_id };
    const detailsColumn = {
      label: (
        <>
          <span>Car Details/</span>
          <span>Description:</span>
        </>
      ),
      value: <TransactionVehiclePopover vehicleData={vehicle} />
    };
    const sellerColumn = { label: 'Seller:', value: user ? cutTextElipsis(user.dealership_name, 15) : 'N/A' };
    const dateColumn = {
      label: 'Date Approved:',
      value: !vehicle.payment_approval_date
        ? 'Pending'
        : moment
            .utc(vehicle.payment_approval_date)
            .local()
            .format(DATE_FORMAT)
    };
    const awardPriceColumn = { label: 'Award Price:', value: <FormattedAmount amount={vehicle.vehicle_price} /> };
    const easyPayColumn = {
      label: 'Status:',
      value: <EasyPayStatus vehicle={vehicle} statusHidden amountHidden />
    };
    return {
      id,
      payment_status,
      auction_id: vehicle.auction_id || 'N/A',
      vehicle_detail: <TransactionVehiclePopover vehicleData={vehicle} />,
      seller_dealership_name: user ? user.dealership_name : 'N/A',
      approval_date: !vehicle.payment_approval_date
        ? 'Pending'
        : moment
            .utc(vehicle.payment_approval_date)
            .local()
            .format(DATE_FORMAT),
      amount: <FormattedAmount amount={vehicle.vehicle_price} />,
      status: <EasyPayStatus vehicle={vehicle} statusHidden amountHidden />,
      mobile_horizontal_view: (
        <MobileHorizontalCard
          columns={[auctionColumn, detailsColumn, sellerColumn, dateColumn, awardPriceColumn, easyPayColumn]}
        />
      ),
      mobile_vertical_view_main: (
        <MobileHorizontalCard
          textSize="sm"
          columns={[auctionColumn, { ...detailsColumn, styles: { minWidth: '260px' } }, sellerColumn]}
        />
      ),
      mobile_vertical_view_secondary: (
        <MobileHorizontalCard textSize="sm" columns={[dateColumn, awardPriceColumn, easyPayColumn]} />
      ),
      delimiter: <Delimiter />
    };
  });

export default EasyPayTabFC;
