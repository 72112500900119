import { DragOverEvent } from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import api from 'services/api/list-vehicles';
import { arrayMove } from '@dnd-kit/sortable';
import {
  setDraggableDayNumber,
  setDraggableOrderIndex
} from '../../../../services/listerDashboard/listerDashboardSlice';

export const useHandleDragOver = () => {
  const dispatch = useDispatch<any>();

  return ({ over, active }: DragOverEvent) => {
    const overId = over?.id;

    if (!overId || !active.data.current) {
      return;
    }

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId;

    if (!overContainer) {
      return;
    }

    const [, activeDayNumber] = activeContainer.split('-');
    const [, overDayNumber] = overContainer.split('-');

    const activeIndex = active.data.current!.sortable.index;
    const overIndex = over.data.current?.sortable.index || 0;

    if (activeDayNumber === overDayNumber) {
      setTimeout(() => {
        dispatch(
          api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
            const overPool = draft[overDayNumber][0];
            if (!overPool || overPool.listRequests.length < 2) return;

            overPool.listRequests = arrayMove(overPool.listRequests, activeIndex, overIndex);
          })
        );

        dispatch(setDraggableOrderIndex(overIndex + 1));
      }, 50);

      return;
    }

    dispatch(
      api.util.updateQueryData('getListerRequestsByDay', undefined, draft => {
        const overPool = draft[overDayNumber][0];
        const activePool = draft[activeDayNumber][0];
        if (!overPool || !activePool) return;

        overPool.listRequests.splice(overIndex, 0, activePool.listRequests[activeIndex]);
        activePool.listRequests.splice(activeIndex, 1);
        dispatch(setDraggableDayNumber(overDayNumber));
        dispatch(setDraggableOrderIndex(overIndex + 1));
      })
    );
  };
};
