import styled from 'styled-components';
import InfoElement from './InfoElement';
import { getHighestBid, getVehicleTimer, getMinBitAmount, getUserHighestBid } from '../../utils/vehicle';
import { Typography, Button } from '@material-ui/core';
import { getSliderTextValue, getWindShieldTextValue } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import Timer from '../Timer';
import { VEHICLE_STATUSES, BID_INCREMENT } from '../../constants';
import { NotesSection } from '../../components/Notes';
import { IncreaseHighBid } from '../../pages/Buyer/BidsList/containers';
import { cutTextElipsis } from '../../utils';
import { initVehicleDetailsData } from '../../actions/vehicles';
import { push } from 'connected-react-router';
import { CurrentBidComponent } from '../Typography';
import moment from 'moment';
import { ReservePriceLabel } from 'components/ReservePriceMet';

const StyledTyporgraphyPerks = styled(Typography)`
  && {
    font-size: 14px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
  padding-bottom: 16px;
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 8px;
  padding-top: 8px;
  gap: 8px;
`;

const Title = styled.div`
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CurrentBidSection = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainContentWrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ scale }) => `1 1 ${scale}%`};
  justify-content: ${({ justifyBetween }) => (justifyBetween ? 'space-between' : 'space-around')};

  align-items: flex-start;
  padding: 0 8px;
  border-right: 1px solid #dcdcdc;
  &:first-child {
    padding: 0;
  }
  &:last-child {
    border: none;
  }
  .more {
    padding: 0;
    min-height: 0;
    text-transform: none;
    min-width: 0;
  }
  .info-cta {
    width: 100%;
  }
  position: relative;
  & * {
    white-space: wrap;
  }
`;

const TimerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const BidInfoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 250px;
`;

const ReservePlaceholder = styled.div`
  height: 24px;
  width: 100%;
`;

const NotesSectionWrapper = styled.div`
  max-width: 180px;
`;

export const CarListView = ({ vehicle }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user?.user);
  const isLoggedIn = !!user;

  const vehicleTimer = getVehicleTimer(vehicle);
  const canPlaceBid =
    !!user &&
    user.role === 'buyer' &&
    vehicle.status === VEHICLE_STATUSES.ACTIVE &&
    moment(vehicleTimer).isAfter(moment());

  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
  const highestBid = getHighestBid(vehicle);
  const myBid = vehicle.my_bid;
  const userHighestBid = getUserHighestBid(vehicle, user?.id);
  const vehicleOptions = (vehicle.options || []).filter(option => option.is_typical).slice(0, 6);

  const minBidAmount = getMinBitAmount(vehicle, user);

  const sellerName = `${vehicle.user.first_name} ${vehicle.user.last_name}`;
  const buyerName = highestBid.bidder?.dealership_name
    ? `${cutTextElipsis(highestBid.bidder.dealership_name, 15)} ${highestBid.bidder.id === user?.id ? '(You)' : ''}`
    : '';

  const handleClick = () => {
    dispatch(push(`/vehicles/${vehicle.id}/view`));
    dispatch(initVehicleDetailsData(vehicle));
  };

  const bidComponentTitle = highestBid?.amount ? 'Current bid' : 'Starting/Min Bid';
  const bidComponentAmount = highestBid?.amount || vehicle?.starting_bid || 100;
  const highBidTitle = userHighestBid.isProxy ? 'High Bid (proxy)' : 'High Bid';

  const RenderReservePriceComponent = () => {
    if (user.role !== 'buyer') return null;

    return (
      <>
        {vehicle.reserve_price_set ? <ReservePriceLabel size="lg" activeVehicle={vehicle} /> : <ReservePlaceholder />}
        <div />
      </>
    );
  };

  return (
    <Container>
      <HeaderWrapper>
        <Title>{vehicleName}</Title>
      </HeaderWrapper>
      <MainContentWrapper>
        <ContentWrapper scale={14}>
          <InfoElement justify="flex-start" type="info" item="Make" value={`${vehicle.make}`} />
          <InfoElement justify="flex-start" type="info" item="Model" value={`${vehicle.model}`} />
          <InfoElement justify="flex-start" type="info" item="Year" value={`${vehicle.year}`} />
          <InfoElement justify="flex-start" type="info" item="Miles" value={`${vehicle.mileage}`} />
          <InfoElement justify="flex-start" type="info" item="VIN" value={`${vehicle.vin}`} />
          <InfoElement justify="flex-start" type="info" item="Engine" value={`${vehicle.engine}`} />
          <InfoElement justify="flex-start" type="info" item="Transmission" value={`${vehicle.transmission}`} />
        </ContentWrapper>
        <ContentWrapper scale={13}>
          {vehicleOptions.map(option => (
            <StyledTyporgraphyPerks>{option.option_name}</StyledTyporgraphyPerks>
          ))}
          <Button color="primary" className="more" onClick={handleClick}>
            More info
          </Button>
        </ContentWrapper>
        <ContentWrapper scale={21}>
          <InfoElement
            justify="flex-start"
            type="info"
            item="Windshield"
            value={getWindShieldTextValue(vehicle.windshield)}
          />
          <InfoElement justify="flex-start" type="info" item="Tires" value={getSliderTextValue(vehicle.tiers)} />
          <InfoElement justify="flex-start" type="info" item="Paint" value={getSliderTextValue(vehicle.paint)} />
          <InfoElement justify="flex-start" type="info" item="Interior" value={getSliderTextValue(vehicle.interior)} />
          <InfoElement
            justify="flex-start"
            type="info"
            item="Air Conditioning Blows"
            value={getSliderTextValue(vehicle.air_conditioning_blows, ['Cold', 'Cool', 'Warm', '', 'N/A'])}
          />
          <InfoElement
            justify="flex-start"
            type="info"
            item="Engine Oil"
            value={getSliderTextValue(vehicle.engine_oil, ['Very Clean', 'Clean', 'Dirty', 'Milky'])}
          />
          <InfoElement
            justify="flex-start"
            type="info"
            item="Engine Coolant"
            value={getSliderTextValue(vehicle.engine_coolant, ['Very Clean', 'Clean', 'Dirty', 'Milky'])}
          />
          <InfoElement
            justify="flex-start"
            type="info"
            item="Check engine light"
            value={vehicle.check_engine_light ? 'On' : 'Off'}
          />
          <InfoElement justify="flex-start" type="info" item="ABS light" value={vehicle.abs_light ? 'On' : 'Off'} />
          <InfoElement
            justify="flex-start"
            type="info"
            item="Air bag light"
            value={vehicle.airbag_light ? 'On' : 'Off'}
          />
        </ContentWrapper>
        <ContentWrapper scale={27} justifyBetween>
          <TimerContentWrapper>
            <CurrentBidSection>
              <span>{bidComponentTitle}</span>
              <CurrentBidComponent amount={bidComponentAmount} />
            </CurrentBidSection>
            {isLoggedIn ? (
              <>
                <InfoElement
                  highlighted
                  type="currency"
                  item="Value Without Miles Adj"
                  value={vehicle.bluebook_value}
                />
                <InfoElement
                  highlighted
                  type="currency"
                  item="Blue Book® Lending Value"
                  value={vehicle.bluebook_mileage}
                />
              </>
            ) : null}
            <InfoElement highlighted type="time" item="Time left" value={<Timer toDate={vehicleTimer} />} />
          </TimerContentWrapper>

          <Button variant="contained" color="primary" size="large" className="info-cta" onClick={handleClick}>
            View
          </Button>
        </ContentWrapper>
        {user && user.role === 'buyer' && (
          <ContentWrapper scale={25} justifyBetween>
            <BidInfoContentWrapper>
              <InfoElement type="info" item="Seller" value={sellerName} highlighted />
              <InfoElement type="currency" item="Your Bid" value={myBid?.amount ?? '-'} highlighted />
              <InfoElement
                type="currency"
                item={highBidTitle}
                helperText={buyerName}
                value={userHighestBid?.amount ?? '-'}
                highlighted
              />
              <NotesSectionWrapper>
                <NotesSection vehicle={vehicle} columnView />
              </NotesSectionWrapper>
              <RenderReservePriceComponent />
              {/* <AddNotesButton vehicleId={vehicle.id} />
              <NotesComponent vehicle={vehicle} /> */}
            </BidInfoContentWrapper>
            {canPlaceBid ? (
              <IncreaseHighBid
                form={`biddingForm_${vehicle.id}`}
                vehicleId={vehicle.id}
                minAmount={minBidAmount}
                bidIncrement={BID_INCREMENT}
              />
            ) : null}
          </ContentWrapper>
        )}
      </MainContentWrapper>
    </Container>
  );
};
