import React from 'react';
import { Field } from 'redux-form';
import { FormControlLabel, Radio, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { required } from '../../../../utils/validators';
import InlineFormRadioGroup from './InlineFormRadioGroup';

const StyledInlineFormRadioGroup = styled(InlineFormRadioGroup)`
  label:last-child {
    margin-left: 30px;
  }

  & * span {
    color: #939393;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & span:last-child {
    font-size: 12px;
    color: #939393;
  }
`;

const styles = () => ({
  label: {
    fontSize: '12px',
    color: 'green'
  }
});

const DocumentsUploadMethodPicker = ({ classes }) => {
  console.log(classes, 'classes');
  return (
    <>
      <Field name="documents_upload_method" component={StyledInlineFormRadioGroup} row validate={required}>
        <StyledFormControlLabel
          classes={{
            root: classes.root
          }}
          value="upload"
          control={<Radio color="primary" />}
          label="Upload documents now"
          error
        />
        <StyledFormControlLabel
          classes={{
            label: classes.label
          }}
          value="email"
          control={<Radio inputProps={{ style: { color: '#939393' } }} color="primary" />}
          label="Send documents via Email"
        />
      </Field>
    </>
  );
};

export default withStyles(styles)(DocumentsUploadMethodPicker);
