export const paragraphs = [
  'Welcome to AutoAxess.com! By using the services on this website, you are agreeing to the following terms. The access and contents of this site is limited to the residents of the fifty states of the United States of America. International access is prohibited at all times.',
  'Before becoming a member of AutoAxess.com, you must read and accept all of the terms and conditions in this User Agreement. While using this site, you will not sell vehicles that do not belong to you or your dealership; violate any laws, third party rights; fail to deliver items awarded by you; circumvent or manipulate our fee structure and/or billing process; transfer your AutoAxess.com account to another party without our consent; copy, modify or distribute content from this site and its trademarks and copyrights or harvest or otherwise collect information about users including their email and mailing addresses without their consent.',
  'You agree that this web-site, in its own discretion, may terminate any auction or use of the service immediately and without notice if (a) We believe that you are not abiding by the general rules of this web-site (b) You have repeatedly broken a certain term (c) You have listed a suspicious item (d) We believe you to be a non paying bidder. In most cases when we suspect foul play we will suspend your account/listing whilst we investigate further. If our investigation is conclusive then your account with us will be terminated.',
  'You are solely responsible for the contents of your actions through this web-site. You must ensure that your participation in the selling or buying of items does not violate any applicable laws or regulations. By this we mean that you must check that you entitled to sell or buy the relevant item and that you are not prohibited from doing so by any law or regulation.',
  'You must not transmit through the service any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene or otherwise objectionable material. You must also not transmit any material that encourages conduct that could constitute a criminal offence, give rise to civil liability or otherwise violate any applicable law or regulation.',
  'Membership to this web-site is only available to those you can form a legally binding contract. This web-site does not permit minors (under 16) to register. If any member fails to signup with their correct age the account will be terminated immediately.',
  "This site acts as the window for sellers to conduct auctions and for bidders to bid on sellers' auctions. We are not involved in the actual transaction between buyers and sellers. As a result, we have no control over the quality, safety or legality of the items listed the truth or accuracy of the listings, the ability of sellers to sell items or the ability of buyers to buy items. We cannot and do not control whether or not sellers will complete the sale of items they offer or buyers will complete the purchases of items they have bid on. In addition, note that there are risks of dealing with foreign nationals, underage persons or people acting under false pretence."
];

export const buyerTerms = [
  {
    title: 'Bids',
    items: [
      'A bid is the “maximum bid” you enter. If your maximum bid is your proxy bid it is still your maximum bid.',
      'When you place a bid you are putting that bid up there until Friday at 5:00 or until the dealer responds to it whichever is longest.',
      'You are responsible for your full bid amount, so be aware that if you place a bid on a vehicle you could own it for up to your max bid.',
      'If you make an offer to AutoAxess online, by a phone call, in text or email and it exceeds your prior maximum bid that is your new maximum bid.',
      'If you make an offer on a vehicle after it has run and you are not the current top offer, that offer will only be presented if the top bid has been declined',
      'If the dealer counters a vehicle for more than your maximum bid, that car is available to anyone else that wants it for that amount or they can make offers on it. AutoAxess is under no obligation to contact the top bidder first.',
      'If a dealer turns down your maximum bid and decides it within your proxy that vehicle is awarded to you and that sale is final.',
      'Buyers will not be notified in advance of a vehicle that can be sold within their maximum bid or proxy or offer.',
      'Use great care when bidding as your bid, once placed is final and cannot be removed and you are responsible for that bid in its entirety.'
    ]
  },
  {
    title: 'Green Light Cars',
    items: [
      'Greenlight cars are Greenlight for 24 hours from the time of pick up or until the next sale (whichever is first).  Greeen light period expires one week from the day the car was offered on the auction, at which time the vehicle becomes As-Is.',
      'A greenlight car is guaranteed for engine (internals, not oil leaks or external parts), Transmission (internals, not oil leaks or external parts), Rear end (internals, not oil leaks or external parts).',
      'Any disputes with a listed vehicle that is greenlight must be made in writing to seller or AutoAxess prior to the next auction or in 24 hours after pick-up whichever comes first.'
    ]
  },
  {
    title: 'As-Is Cars',
    items: [
      'An “As-Is” car is just that “As-is.”  Buyer is responsible to view car at selling dealer before bidding.  If buyer does not view the car beforehand, he risks the condition of the vehicle being as described.  Descriptions are subjective. AutoAxess is not held responsible if buyer feels that the description, equipment, condition, or book value of the vehicle is inaccurate.',
      'AutoAxess drives each vehicle a short distance (less than a mile) and makes notes of how it runs, shifts and drives and any faults that are noticed by inspector and makes comments in dealer comments.',
      'AutoAxess does not check engine codes but may mention them if seller knows the codes',
      'AutoAxess does not check or guarantee frame of any listed vehicles (Green light or As-is) other than what shows up in Autocheck.',
      'No adjustments will be made on As-Is cars'
    ]
  },
  {
    title: 'Autocheck',
    items: [
      'AutoAxess provides Autocheck free to the buyer and buyers are encouraged to use it.',
      'AutoAxess is not responsible for incorrect information provided by Autocheck',
      'The information on Autocheck is an extension of the written description, meaning if Autocheck shows it and we provide Autocheck it is disclosed'
    ]
  },
  {
    title: 'Picking up vehicles',
    items: [
      "It is the buyer's responsibility to arrange payment and pick up of units awarded to them",
      'Buyers should pick up vehicles as soon as possible as that car is yours and you are responsible for its condition',
      'All cars need to be picked up prior to the next auction unless written exception from the selling dealer',
      'Neither AutoAxess nor the seller is not responsible for the car after it is sold even if it is not picked up yet',
      'A late fee equal to your buy fee can and will be charged for vehicles not picked up prior to the next sale, and every subsequent week thereafter',
      'If you are unable to get a vehicle transported for any reason, that is not a valid excuse for cars being picked up late',
      'If a selling dealer moves a vehicle to a different location the buyer will be notified and the vehicle will need to be picked up there.',
      'Buyers (and their representatives) must be respectful when they or any of their representatives go to view or pick up cars as they are at a new car dealer.'
    ]
  },
  {
    title: 'Fees',
    items: [
      "Buy fees are charged at the time the vehicle is awarded to the buyer and charged to buyer's credit card on file.",
      'All sales are final once the seller accepts the buyers bid.',
      'Once a vehicle is awarded the vehicle must be picked up promptly.',
      'If a vehicle is not picked up the buy fee is still due;  additionally buyer is responsible for making the selling dealer “whole” on that vehicle including paying the fee and covering any shortage there may be if sold to a different buyer either at our auction or a different one up to the purchase price of that car.',
      'Buyers will not be refunded buy fees for a purchased vehicle not being picked up for any reason. And additional fees may be due.',
      'If a vehicle is not picked up on time (which is prior to the next auction) the seller has the option to run the vehicle again or dispose of it in any manner that he sees fit.',
      'If a vehicle is not picked up on time AutoAxess may (at our discretion) charge the amount of the vehicle on the buyer’s credit card, flooring line or ACH funds and do the same with the transportation fees to get the vehicle delivered and get the selling dealer paid with or without the buyer being notified.',
      'If the buyer or seller has a debt to AutoAxess, or anyone one that AutoAxess represents, which can be (but not limited to) buyer fees, seller or listing fees, payment (in part or in full) for a purchased vehicle to any buyer, seller or AutoAxess, then AutoAxess can collect those fees by any manner possible including but not limited to, a claim against your bond or obtaining title for any car purchased and holding the title until payment is made, and/or obtaining any purchased vehicle from AutoAxess in their inventory. The vehicle (or vehicles) can be sold by AutoAxess to pay the debt.'
    ]
  },
  {
    title: 'Login Information',
    items: [
      'Each buyer signs up and creates his or hers own private sign in and password.',
      'AutoAxess does not retain your password.',
      'As a buyer you should use great care with your sign in and password.',
      'If you give another person your sign and password you are giving them full access to your account and you are responsible for any bids or obligations that person may or may not make.',
      "If a buyer gives a person access to his account and do not want them to have it any more, it is the buyer's responsibility to remove that person. The easiest way is by changing the password but the buying dealer is still responsible for any actions made by an unauthorized buyer"
    ]
  },
  {
    title: 'Vehicle payment',
    items: [
      'Buyer is responsible for payment of all vehicles in accordance with the payment terms listed on the site for that vehicle.',
      'If Cashier’s check is the method of payment and buyer attempts to pay with a company check and your drivers are turned around go back with a cashier’s check.',
      'If picking up car using a third party transporter, buyer is responsible to make sure seller has received payment before transporter arrives at seller for pick up, or risk transporter being turned away.',
      'If your funds are not available, insufficient, or buyer doesn’t pay for a purchased vehicle, AutoAxess and/or the selling dealer will use any and all means available to collect that debt. We may charge your flooring account, ACH, Credit card on file and/or file a claim against your bond.',
      'Any returned checks for “non-sufficient funds” or “cancelled checks” will be charged a dishonored check fee equal to your buy fee and will be charged again every 24 hours until that payment is made.',
      'If a buyer is awarded a car, the buy fee is due immediately.'
    ]
  }
];

export const sellerTerms = [
  {
    items: [
      'Selling party is responsible for all legal disclosures, including (but not limited to) Frame, Salvage, TMU Lemon Law buyback, etc.',
      'AutoAxess is not responsible for buyer actions or language while at the selling dealership.',
      'When car is awarded to buyer, sell fee is due from the seller.  New car dealer sellers will be billed at the end of each month for sell fees for that month.',
      'For accuracy, it is best for seller to provide AutoAxess inspector with book sheets and any necessary disclosure information/',
      'Once a vehicle is placed for sale with AutoAxess and is listed that vehicle must remain available to AutoAxess buyers only until the auction is over and vehicle is sold or not sold. Outside offers cannot be entertained until the auction is over.',
      'Vehicles that are listed with AutoAxess and are “pulled prior to, or during the sale may be charged a “sale fee.',
      'Vehicles that are sold through AutoAxess sales are final and if sold “outside” the sale to a different buyer or seller decides to keep vehicle the seller is responsible for a sale fee and reimbursing the buyer his buy fee and transportation if the buyer is out those fees',
      'If a vehicle sold through AutoAxess payment is not made or funds are not sufficient the vehicle can be resold in any manner the seller wants to use.  Sellers will not be responsible for a sell fee on a vehicle that has not been picked up or paid for as long as that time is past the next auction',
      'If a listed vehicle is sold “outside the sale”, within 30 days of listing, to a buyer that has an account with AutoAxess that will be considered an AutoAxess sale and the buyer and seller will be charged a fee'
    ]
  }
];

export default {
  paragraphs,
  buyerTerms,
  sellerTerms
};
