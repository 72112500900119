import React from 'react';
import NumberFormat from 'react-number-format';

const PhoneNumberText = ({ text, ...rest }) => (
  <NumberFormat
    value={text}
    displayType="text"
    format="(###) ###-####"
    mask="_"
    {...rest}
  />
);

export default PhoneNumberText;
