import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormFormattedField, FormTextField } from '../../../../components/Form';
import StyledGrid from './StyledGrid';
import './CreditCardPickerStyle.scss';

const DEFAULT_CARD_FORMAT = '#### #### #### ####';

export const CARD_TYPES = [
  {
    format: '#### ###### #####',
    startPattern: /^3[47]/
  }
];

const creditCardComponent = ({ creditCardNumber, isDisabled }) => {
  if (isDisabled) {
    return (
      <Field
        name="credit_card_last_numbers"
        component={FormTextField}
        label="Current Credit Card"
        fullWidth
        InputProps={{
          disabled: true,
          disabledUnderline: true
        }}
      />
    );
  }

  let cardFormat = DEFAULT_CARD_FORMAT;
  CARD_TYPES.forEach(type => {
    if (type && type.startPattern.test(creditCardNumber)) {
      cardFormat = type.format;
    }
  });

  return (
    <>
      <StyledGrid item xs={12}>
        <Field
          name="credit_card_number"
          component={FormFormattedField}
          label="Credit Card Number"
          fullWidth
          maskProps={{
            format: cardFormat,
            mask: '_'
          }}
          placeholderOnFocus={cardFormat}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          name="credit_card_expiry_date"
          component={FormFormattedField}
          label="Credit Card Expiry Date"
          fullWidth
          maskProps={{
            format: '##/##',
            placeholder: 'MM/YY',
            mask: ['M', 'M', 'Y', 'Y']
          }}
          placeholderOnFocus="MM/YY"
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          name="credit_card_cvc"
          component={FormFormattedField}
          label="CVV"
          fullWidth
          maskProps={{
            format: '###',
            mask: '_'
          }}
          placeholderOnFocus="###"
        />
      </StyledGrid>
    </>
  );
};

const selector = formValueSelector('creditCardSectionForm');

const mapStateToProps = state => ({
  creditCardNumber: selector(state, 'credit_card_number')
});

export default connect(
  mapStateToProps,
  null
)(creditCardComponent);
