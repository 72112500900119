import { AcceptOfferModal, SendOfferModal } from '../../../../../components/Offers';
import { AcceptHighBidModal } from '../AcceptHighBidModal';
import { AcceptTopOfferModal } from '../AcceptTopOfferModal';
import { DropReservePriceModal } from '../DropReservePriceModal';
import EditVehicleBidModal from '../EditVehicleBidModal';

export const DashboardModals = ({ currentVehicle: vehicle }) => {
  return (
    <>
      <EditVehicleBidModal form={`vehicleEditModal-${vehicle.id}`} initialValues={vehicle} />
      <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
      <AcceptHighBidModal vehicle={vehicle} />
      <AcceptOfferModal vehicleId={vehicle.id} />
      <AcceptTopOfferModal currentVehicle={vehicle} />
      <DropReservePriceModal currentVehicle={vehicle} />
    </>
  );
};
