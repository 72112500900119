/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import moment from 'moment';
import { Header, Filter } from './components';
import { Table } from '../../../../components';
import {
  listSellerPastInvoices,
  modalsToggle,
  getSingleInvoice,
  sellersInvoicesSetDate
} from '../../../../actions';
import CurrentInvoiceModal from '../CurrentInvoiceModal';
import { DATE_FORMAT } from '../../../../constants';

const StyledTableWrapper = styled.div`
  && {
    tr td:first-child {
      width: 200px;
    }
  }
`;

class PastInvoices extends Component {
  componentDidMount() {
    const { filter, getInvoices, history, currentSeller, match } = this.props;
    if (!currentSeller || Object.keys(currentSeller).length === 0) {
      history.push('/admin/financials/invoices');
    } else {
      getInvoices({ seller: match.params.sellerId, params: filter });
    }
  }

  // TODO fix this
  loadMore = () => false;

  render() {
    const { invoices } = this.props;
    const buttons = [
      {
        title: 'View',
        color: 'secondary',
        handleClick: item => {
          window.open(item.file, '_blank');
        }
      }
    ];
    return (
      <div>
        <Header {...this.props} />
        <div className="pageWrapper">
          <Filter {...this.props} />
          <StyledTableWrapper>
            <Table
              columnsHead={[
                { title: 'Seller', key: 'dealership_name' },
                { title: 'Generation Date', key: 'date' },
                { title: 'Invoice Month', key: 'month_string' },
                { title: 'Number of Vehicles', key: 'vehiclesCount' },
                { title: '', key: 'actions', cellClass: 'text-right' }
              ]}
              columnsData={invoices}
              rowsCount={0}
              buttons={buttons}
              loadMore={this.loadMore}
              loading={false}
            />
          </StyledTableWrapper>
          <CurrentInvoiceModal />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let invoices = [];
  if (state.financials.pastInvoices) {
    invoices = state.financials.pastInvoices.map(item => ({
      ...item,
      id: state.financials.currentSeller.id,
      dealership_name: state.financials.currentSeller.dealership_name,
      date: moment
        .utc(item.created_at)
        .local()
        .format(DATE_FORMAT),
      month: item.date,
      month_string: moment(item.date, 'MM-YYYY').format('MMMM'),
      vehiclesCount: item.vehiclesCount.toString()
    }));
  }
  return {
    invoices,
    currentSeller: state.financials.currentSeller,
    filter: getFormValues('pastInvoicesFilterForm')(state)
  };
};

const mapDispatchToProps = dispatch => ({
  getInvoices: data => dispatch(listSellerPastInvoices(data).request),
  toggleModal: key => dispatch(modalsToggle(key)),
  getInvoice: data => dispatch(getSingleInvoice(data).request),
  setDate: data => dispatch(sellersInvoicesSetDate(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PastInvoices);
