import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Divider, Grid, Button, Typography, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { snackShow, detailProfile, easyPayBuyVehicle, vehicleDetails, listBuyerFees } from '../../../actions';
import { PageWrapper } from '../../../components';
import { Header } from './components';
import { SellerInformation, PurchaseMethods, PurchaseMethodsForm, AwardPrice, VehicleInformation } from './containers';
import { BUYER_PURCHASE_METHODS, SNACK_MESSAGE_TYPES, EASYPAY_REQUEST_BUY_FEE_METHODS } from '../../../constants';
import { EASY_PAY_PURCHASE_FORM } from '../../../constants/forms';
import { userStateSelector, selectedVehicleSelector } from '../../../selectors';
import BuyFee from "./containers/BuyFee";
import BuyFeeMethods from "./containers/BuyFeeMethods";
import TransportFee from "./containers/TransportFee";

const SectionTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
`;

const StyledSection = styled(Paper)`
  max-width: 1024px;
  border-radius: 0;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  border: 1px solid #dcdcdc;
  display: flex;
  min-height: 70px;
  align-items: center;
  padding: 16px 72px;
  button:last-child {
    margin-left: 10px;
  }
  @media (max-width: 680px) {
    padding: 15px;
  }
`;

const ContentWrapper = styled.div`
  padding: 32px 72px 48px 72px;
  @media (max-width: 680px) {
    padding: 15px;
  }
`;

class EasyPayPurchase extends Component {
  async componentDidMount() {
    const { vehicleNotFound, history, fetchProfile, fetchDetails, match, getFees } = this.props;
    const { vehicleId } = match.params;
    try {
      await fetchDetails(vehicleId, { notDraft: true });
      fetchProfile();
      getFees();
    } catch (e) {
      if (e.response.status === 404) {
        vehicleNotFound();
        history.push('/');
      }
    }
  }

  render() {
    const { handleSubmit, vehicle, profile } = this.props;
    return (
      <>
        <Header />
        <PageWrapper>
          <StyledSection>
            <TitleWrapper>
              <SectionTitle>EasyPay Request</SectionTitle>
              <span className="flex-grow" />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </TitleWrapper>
            {vehicle && (
              <ContentWrapper>
                <Grid container>
                  <VehicleInformation />
                  <SellerInformation />
                  <AwardPrice />
                  <BuyFee />
                  <TransportFee />
                </Grid>
                <Divider />
                <form onSubmit={handleSubmit}>
                  <PurchaseMethods />
                </form>
                <PurchaseMethodsForm />
                <br />
                {!vehicle.charge_completed &&
                  <>
                    {profile && profile.buyer && profile.buyer.customer_profile_id
                      ? <BuyFeeMethods creditCardDigits={profile.buyer.credit_card_last_numbers} />
                      : <span>Note: the buy fee will be ACH/Floored</span>
                    }
                  </>
                }
              </ContentWrapper>
            )}
          </StyledSection>
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = state => ({
  vehicle: selectedVehicleSelector(state),
  profile: userStateSelector(state)
});

const mapDispatchToProps = dispatch => ({
  fetchDetails: (vehicleId, options = {}) =>
    new Promise((resolve, reject) => dispatch(vehicleDetails({ vehicleId, options }, resolve, reject).request)),
  vehicleNotFound: () =>
    dispatch(
      snackShow({
        hideTime: 3000,
        type: SNACK_MESSAGE_TYPES.ERROR,
        message: 'Vehicle not found.'
      })
    ),
  fetchProfile: () => dispatch(detailProfile().request),
  getFees: () => dispatch(listBuyerFees().request),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: EASY_PAY_PURCHASE_FORM,
    initialValues: {
      type: BUYER_PURCHASE_METHODS.ACH,
      request_buy_fee_method: EASYPAY_REQUEST_BUY_FEE_METHODS.INCLUDE_IN_ACH_FLOORING,
    },
    onSubmit: (values, dispatch, { vehicle }) => {
      const { id } = vehicle;
      return new Promise((resolve, reject) =>
        dispatch(
          easyPayBuyVehicle({
            data: {
              id,
              ...values
            },
            resolve,
            reject
          }).request
        )
      );
    }
  })
)(EasyPayPurchase);
