import styled from 'styled-components';
import { MapPinIcon } from 'components/Icons/MapPin';

const Wrapper = styled.div`
  position: relative;
`;

const NumberWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

export const MapPin = ({ color, orderIndex }: { color: string; orderIndex: number | string }) => {
  return (
    <Wrapper>
      <MapPinIcon type="map" fillColor={color} />
      <NumberWrapper>{orderIndex}</NumberWrapper>
    </Wrapper>
  );
};
