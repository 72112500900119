import { FormControlLabel, Radio } from '@material-ui/core';
import InlineFormRadioGroup from 'pages/Auth/Registration/components/InlineFormRadioGroup';
import { Field } from 'redux-form';
import styled from 'styled-components';

const AuctionTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;

  @media (max-width: 567px) {
    margin-left: 0;
  }
`;

export const AuctionTypeComponent = ({ disabled = false }) => {
  return (
    <AuctionTypeWrapper>
      <div>Auctions</div>
      <Field name="auction_type" component={InlineFormRadioGroup}>
        <FormControlLabel disabled={disabled} value={'autoaxess'} control={<Radio />} label="AutoAxess" />
        <FormControlLabel disabled={disabled} value={'frontline'} control={<Radio />} label="Frontline" />
      </Field>
    </AuctionTypeWrapper>
  );
};
