import { FC, ReactNode } from 'react';
import { useDroppable, UniqueIdentifier } from '@dnd-kit/core';

export const DropZone: FC<{ children: ReactNode; id: UniqueIdentifier; disabled?: boolean }> = ({
  children,
  id,
  disabled = false
}) => {
  const { setNodeRef, isOver } = useDroppable({ id, disabled });

  const style = {
    backgroundColor: isOver ? '#dcdcdc' : 'inherit'
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};
