import React from 'react';
import { connect } from 'react-redux';
import { Dialog, CircularProgress, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { modalsToggle } from '../../actions';

const StyledLoadingWrapper = styled.div`
  padding: 25px;
`;

const StyledLoader = styled(CircularProgress)`
  && {
    margin: 0 auto;
    display: block;
  }
`;

const StyledLoadingText = styled(Typography)`
  && {
    margin-top: 5px;
  }
`;

const ModalWrapper = ({ open, modalId, loaderText = 'Loading', selectedModalId, forceOpen = false }) => (
  <Dialog open={forceOpen || (open && modalId === selectedModalId)}>
    <StyledLoadingWrapper>
      <StyledLoader />
      <StyledLoadingText>{loaderText}</StyledLoadingText>
    </StyledLoadingWrapper>
  </Dialog>
);

const mapStateToProps = state => ({
  open: state.modals.open,
  selectedModalId: state.modals.modalId
});

const mapDispatchToProps = dispatch => ({
  toggleModal: modalId => dispatch(modalsToggle(modalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
