import { useField } from 'react-final-form';

export const useIS4WDVehicle = (skip?: boolean) => {
  const {
    input: { value: driveTrain }
  } = useField('drive_train', { subscription: { value: !skip } });

  if (skip) return false;

  if (!driveTrain) return false;

  return driveTrain.includes('4WD');
};
