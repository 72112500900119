import styled from 'styled-components';
import { VehicleBidInfoBlock } from './BidInfoBlock';
import { useSalesActions } from '../hooks/useSalesActions';
import { useSalesOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useSelector } from 'react-redux';
import { TableButton } from '../../../../../components/Table/common';
import { usePrivateOfferAmount } from '../../../../../hooks';
import { StyledLabel, StyledText } from './styled';
import { FormattedAmount } from '../../../../../components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 800px) { 
    max-width: 300px;
  }
`;

const ButtonWrapper = styled.div`
  width: ${({ buttonWidth }) => `${buttonWidth}px`};
  display: flex;
  align-items: flex-end;
`;

const OfferComponent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const DataBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
`;

const CurrentBidWrapper = styled.div`
  background-color: #3994de;
  color: #fff;
  border-radius: 2px;
  padding: 2px;
`;

export const TabletDashboardBlock = ({ currentVehicle }) => {
  const user = useSelector(state => state?.user?.user);
  const offerAuction = useSalesOfferAuction(currentVehicle.id);
  const offerAuctionStatus = auctionOfferStatuses(currentVehicle, offerAuction, user);
  const buttons = useSalesActions(currentVehicle, offerAuction, offerAuctionStatus);

  const { adminAmount } = usePrivateOfferAmount(offerAuction);

  const currentBid = currentVehicle?.highest_bid?.amount || currentVehicle?.starting_bid || 100;
  const startingBid = currentVehicle?.starting_bid || 100;
  const reservePrice = currentVehicle?.reserve_price;

  const { filters } = useSelector((state) => state.mySales);
  const activeTab = filters.auctionStatus;

  const buttonWidth = activeTab === 'active' ? 160 : 120;

  return (
    <Wrapper>
      <OfferComponent>
        <DataBlock>
          <StyledLabel>Current Bid:</StyledLabel>
          <StyledText>
            <CurrentBidWrapper>
              <FormattedAmount amount={currentBid} />
            </CurrentBidWrapper>
          </StyledText>

          <StyledText>{currentBid ? <FormattedAmount amount={currentBid?.amount} /> : '-'}</StyledText>
        </DataBlock>
        <DataBlock>
          <StyledLabel>Reserve:</StyledLabel>
          <StyledText>{reservePrice ? <FormattedAmount amount={reservePrice} /> : '-'}</StyledText>
        </DataBlock>
        <DataBlock>
          <StyledLabel>AutoAxess Counter:</StyledLabel>
          <StyledText>{adminAmount ? <FormattedAmount amount={adminAmount} /> : '-'}</StyledText>
        </DataBlock>
        <DataBlock>
          <StyledLabel>Starting/Min Bid:</StyledLabel>
          <StyledText>{startingBid ? <FormattedAmount amount={startingBid} /> : '-'}</StyledText>
        </DataBlock>
      </OfferComponent>
      <ActionsWrapper>
        {buttons.map((btn, i) => {
          const renderButton = !btn.conditionalRendering || btn.conditionalRendering(currentVehicle);
          if (!renderButton) return null;

          return (
            <ButtonWrapper buttonWidth={buttonWidth}>
              <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
            </ButtonWrapper>
          );
        })}
      </ActionsWrapper>
    </Wrapper>
  );
};
