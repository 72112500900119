import { call, put, takeLatest, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  vehicleDetails,
  getDeliveryEstimate,
  snackShow,
} from '../actions';
import {
  VEHICLE_BUYER_DOCS_REQUEST,
  VEHICLE_DELIVERY_ESTIMATE_REQUEST,
  getVehicleBuyerDocs
} from '../actions/vehicles';
import {
  GET_VEHICLE_DETAILS_REQUEST,
} from '../constants/actionTypes';
import { VEHICLE_STATUSES } from '../constants';
import { Api } from '../utils';

function* fetchVehicleDetails({ payload }) {
  try {
    const { vehicleId, options = {} } = payload.data;
    const { data } = yield call(Api.vehicles.fetchVehicleDetails, vehicleId);
    if (options.notDraft && data.data.status === VEHICLE_STATUSES.DRAFT) {
      yield put(snackShow({ message: 'This vehicle is in a draft state. You cannot view it right now', type: 'error' }));
      yield put(push('/'));
    } else {
      yield put(vehicleDetails(data).success);
      payload.resolve(data);
    }
  } catch (e) {
    yield put(vehicleDetails(e).failure);
    payload.reject(e);
  }
}

function* requestDeliveryEstimate({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.getDeliveryEstimate, payload);
    yield put(getDeliveryEstimate(data).success);
  } catch (e) {
    yield put(getDeliveryEstimate(e).failure);
  }
}

function* fetchVehicleBuyerDocuments({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.fetchBuyerDocs, payload);
    const user = data.data;
    const { docs } = user.buyer;
    yield put(getVehicleBuyerDocs({ docs, buyer: user }).success);
  } catch (e) {
    yield put(snackShow({ message: e.response.data.message, type: 'error' }));
    yield put(getVehicleBuyerDocs(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(GET_VEHICLE_DETAILS_REQUEST, fetchVehicleDetails),
    takeLatest(VEHICLE_DELIVERY_ESTIMATE_REQUEST, requestDeliveryEstimate),
    takeLatest(VEHICLE_BUYER_DOCS_REQUEST, fetchVehicleBuyerDocuments)
  ]);
}
