import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { RegionSectionForm } from '../forms';
import { listRegions } from '../../../../actions';

class RegionSection extends Component {
  state = {
    isEditable: false
  };

  componentDidMount() {
    const { getRegions } = this.props;
    getRegions();
  }

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm, regions } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    const { region, region_id } = initialValues[initialValues.role];
    const extendedInitialValues = { region, region_id };
    return (
      <SectionBlock
        canBeEdited={false}
        title="Region"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <RegionSectionForm
          regions={regions}
          InputProps={InputProps}
          initialValues={extendedInitialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapStateToProps = state => ({
  regions: state.regions.itemsData.rows || []
});

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('regionSectionForm')),
  resetForm: () => dispatch(reset('regionSectionForm')),
  getRegions: () => dispatch(listRegions().request)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionSection);
