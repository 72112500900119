import styled from 'styled-components';
import { DeleteImageButton } from '../DeleteImageButton';
import { useDispatch } from 'react-redux';
import { useDraggable } from '@dnd-kit/core';
import { LocalImage, removeLocalImage } from '../../../../../services/uploadVehicle/assignVehicleImagesSlice';

const LoadedImage = styled.div<{ isDragging: boolean }>`
  position: relative;
  height: auto;
  opacity: ${props => (props.isDragging ? '0.5' : '1')};

  @media (min-width: 1024px) {
    height: 108px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const UploadedImage = styled.div`
  background: #479747;
  color: #fff;
  position: absolute;
  border-radius: 4px;
  font-size: 8px;
  left: 4px;
  padding: 4px;
  top: 8px;
`;

type Props = {
  img: LocalImage;
  i: number;
  section: string;
};

export const DraggableImage = ({ img, i, section }: Props) => {
  const dispatch = useDispatch();

  const handleDeleteImage = () => {
    dispatch(removeLocalImage(i));
  };

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: img.id,
    data: img,
    disabled: img.isUploaded
  });

  return (
    <LoadedImage isDragging={isDragging} key={img.id} {...attributes} {...listeners} ref={setNodeRef}>
      <StyledImage src={URL.createObjectURL(img.file)} alt={img.file.name} />
      <DeleteImageButton onClick={handleDeleteImage} />
      {img.isUploaded && <UploadedImage>Uploaded</UploadedImage>}
    </LoadedImage>
  );
};
