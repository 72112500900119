import { Field } from 'react-final-form';
import styled from 'styled-components';
import { SoundIcon } from '../../../icons/SoundIcon';
import { AddImageIcon } from '../../../icons/AddImageIcon';
import { SubSection } from '../../../../../services/api/vehicle-sections/types/common';
import { openMobileImagesDialog } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import { FooterNotesField } from './FooterNotesField';
import { SoundRecorder } from '../SoundRecorder';
import { useWindowSize } from 'usehooks-ts';
import { openAssignImageDialog } from '../../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { useFieldArray } from 'react-final-form-arrays';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const IconWrapper = styled.div`
  background: rgba(34, 34, 34, 0.04);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
`;

type Props = {
  name: string;
  subSectionConfig: SubSection;
};

export const Footer = ({ name, subSectionConfig }: Props) => {
  const dispatch = useDispatch();
  const { fields } = useFieldArray(`${name}.images`, { subscription: { value: true } });
  const openImageUploadDialog = () => dispatch(openAssignImageDialog({ sectionId: name, formImages: fields.value }));
  const openMobileImageUploadDialog = () => dispatch(openMobileImagesDialog(`${name}-multi`));

  const { width = 0 } = useWindowSize();

  return (
    <Wrapper>
      <Field name={`${name}.notes`}>{props => <FooterNotesField name={name} />}</Field>
      {subSectionConfig.withSound && (
        <IconWrapper>
          <SoundRecorder />
        </IconWrapper>
      )}
      <IconWrapper onClick={width > 1024 ? openImageUploadDialog : openMobileImageUploadDialog}>
        <AddImageIcon />
      </IconWrapper>
    </Wrapper>
  );
};
