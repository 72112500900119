import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { ContactsSectionForm } from '../forms';

class ContactsSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    const {
      id,
      first_name,
      last_name,
      job_title,
      email,
      contacts = []
    } = initialValues;
    const {
      phone_number,
      secondary_contact_phone,
      secondary_contact_name,
      secondary_contact_email,
      secondary_contact_job_title
    } = initialValues[initialValues.role];
    const extendedInitialValues = {
      id,
      first_name,
      last_name,
      job_title,
      email,
      secondary_contact_name,
      secondary_contact_email,
      secondary_contact_phone,
      secondary_contact_job_title,
      phone_number,
      contacts
    };
    return (
      <SectionBlock
        title="Contacts List"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <ContactsSectionForm
          InputProps={InputProps}
          initialValues={extendedInitialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('contactsSectionForm')),
  resetForm: () => dispatch(reset('contactsSectionForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(ContactsSection);
