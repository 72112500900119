import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const StyledText = styled.div<{ maxWidth?: number }>`
  font-weight: 500;
  font-size: 12px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};

  @media (max-width: 567px) {
    font-size: 16px;
  }
`;

export const StyledLabel = styled(Typography)<{ isBold: boolean }>`
  color: ${({ isBold }) => (isBold ? '#000' : '#757575')};
  white-space: nowrap;
  font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
  font-size: 12px;

  @media (max-width: 567px) {
    font-size: 16px;
  }
`;

export const Wrapper = styled.div<{ justifyBetween: boolean }>`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: ${({ justifyBetween }) => (justifyBetween ? 'space-between' : 'flex-start')};

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
