import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import ModalWrapper from '../../../../components/ModalWrapper';
import { FormSelect } from '../../../../components/Form';
import { required } from '../../../../utils/validators';

const ReactivateVehicleModal = ({
  handleSubmit,
  loading,
  invalid,
  auctions
}) => (
  <ModalWrapper
    title="Reactivate Vehicle"
    handleSubmit={handleSubmit}
    modalId="reactivateVehicleModal"
    submitText="Confirm"
    submitting={invalid || loading}
  >
    <Typography>
      Please choose an auction in which you’d like to reactivate the vehicle
    </Typography>
    <Field
      component={FormSelect}
      id="auction"
      name="auction"
      label="Auction"
      validate={[required]}
      fullWidth
    >
      {auctions.map((e, i) => (
        <MenuItem key={i} value={e.id}>
          {`#${e.id} ${e.formatedStartDate}`}
          {e.isCurrent && ` (current)`}
        </MenuItem>
      ))}
    </Field>
  </ModalWrapper>
);

ReactivateVehicleModal.propTypes = {
  handleSubmit: PropTypes.func
};
ReactivateVehicleModal.defaultProps = {
  handleSubmit: () => {}
};

export default reduxForm({
  form: 'ReactivateVehicleModal'
})(ReactivateVehicleModal);
