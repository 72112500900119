import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';

type ScrollPositionState = {
    is_seller_locked: boolean;
}

const initialState: ScrollPositionState = {
    is_seller_locked: localStorage.getItem('is_seller_locked') === 'true'
};

type SellerIdPayload =  number | { value: number } | null

const getSellerIdFromAction = (sellerId: SellerIdPayload) => {
    if (sellerId == null) return null
    if (typeof sellerId === 'number') return sellerId
    return sellerId.value
}

const sellerLockSlice = createSlice({
  name: 'sellerLock',
  initialState,
  reducers: {
      toggleIsSellerLocked(state, action: PayloadAction<SellerIdPayload>) {
        const newIsSellerLocked = !state.is_seller_locked
        localStorage.removeItem('locked_seller');
        const sellerId = getSellerIdFromAction(action.payload)

        if (newIsSellerLocked && sellerId) {
            localStorage.setItem('locked_seller', String(sellerId));
        }
        
        localStorage.setItem('is_seller_locked', String(newIsSellerLocked));
        state.is_seller_locked = newIsSellerLocked
      }
    }
});

export const useIsSellerLocked = () => {
    const isLocked = useSelector<{ sellerLock: { is_seller_locked: boolean } }, boolean>(
        state => state.sellerLock.is_seller_locked
    )

    return isLocked
}

export const { toggleIsSellerLocked } = sellerLockSlice.actions
export default sellerLockSlice;
