import React, { Component } from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { AddressSectionForm } from '../forms';

class AddressSection extends Component {
  state = {
    isEditable: false
  };

  toggleEdit = () =>
    this.setState(oldState => ({ isEditable: !oldState.isEditable }));

  onCancel = () => {
    const { resetForm } = this.props;
    resetForm();
    this.toggleEdit();
  };

  render() {
    const { initialValues, submitForm } = this.props;
    const { isEditable } = this.state;
    const InputProps = isEditable
      ? {}
      : { disableUnderline: true, disabled: true };
    return (
      <SectionBlock
        title="Address"
        isEditable={isEditable}
        onCancel={this.onCancel}
        toggleEdit={this.toggleEdit}
        submit={submitForm}
      >
        <AddressSectionForm
          name="addressSectionForm"
          InputProps={InputProps}
          initialValues={initialValues}
          onSubmitSuccess={this.toggleEdit}
        />
      </SectionBlock>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('addressSectionForm')),
  resetForm: () => dispatch(reset('addressSectionForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(AddressSection);
