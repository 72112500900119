import styled from 'styled-components';
import { Vehicle } from '../../../../types/vehicle';
import { formatCurrency } from '../../../../utils';

type Props = {
  vehicle: Vehicle;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  font-weight: 500;
`;

type FormatLaneProps = {
  label: string;
  data: {
    value: number | null;
    color: string;
  };
};

const FormatLane = ({ label, data: { value, color } }: FormatLaneProps) => {
  if (!value) return <span>{`${label} -`}</span>;
  console.log(value, color);
  return (
    <span>
      {label} - <span style={{ color }}>{formatCurrency(value)}</span>
    </span>
  );
};

const extractDisplayValue = (offers: number | Record<string, number>) => {
  if (!offers) return { value: null, color: '' };
  if (typeof offers === 'number') return { value: offers, color: '#757575' };
  if (offers.sold) {
    return {
      value: offers.sold,
      color: '#479747'
    };
  }
  if (offers.bid) {
    return {
      value: offers.bid,
      color: '#3994de'
    };
  }
  if (offers.opening_bid) {
    return {
      value: offers.opening_bid,
      color: '#757575'
    };
  }
  return {
    color: '',
    value: null
  };
  // return offers.sold ?? offers.bid ?? offers.opening_bid
};

export const OtherOffers = ({ vehicle }: Props) => {
  const openLaneOffer = extractDisplayValue(vehicle.other_offers?.open_lane);
  const acvOffer = extractDisplayValue(vehicle.other_offers?.acv);
  const otherOffer = extractDisplayValue(vehicle.other_offers?.other);

  return (
    <Wrapper>
      <FormatLane label="OL" data={openLaneOffer} />
      <FormatLane label="ACV" data={acvOffer} />
      <FormatLane label="O/r" data={otherOffer} />
    </Wrapper>
  );
};
