import styled from 'styled-components';
import { MainCharacteristics } from './MainCharacteristics';
import { Equipment } from './Equipment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
`;

export const VehicleCharacteristics = () => {
  return (
    <Wrapper>
      <Title>Vehicle Information</Title>
      <MainCharacteristics />
      <Equipment />
    </Wrapper>
  );
};
