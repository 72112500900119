import moment from 'moment';
import { BidHistorySection } from '../../../../Shared/VehicleDetails/components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { InfoBlock } from '../InfoBlock';
import { useShowOfferSection } from '../OffersHistory';

const formatDate = (date: string) => {
  return moment(date).format('MM/DD/YY, hh:mm A');
};

export const BidsHistory = () => {
  const vehicle = useViewedVehicle();
  const initiallyCollapsed = useShowOfferSection(vehicle);
  if (!vehicle) return null;

  const bids = vehicle.bids.map((bid: any) => ({
    id: bid.id,
    bid: bid.amount,
    bidder: bid.bidder?.dealership_name,
    date: formatDate(bid.created_at),
    created_at: bid.created_at
  }));

  return (
    <InfoBlock>
      <BidHistorySection initiallyCollapsed={initiallyCollapsed} bids={bids} proxyBid={vehicle.proxy_bid} />
    </InfoBlock>
  );
};
