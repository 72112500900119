import React from 'react';
import { Typography, ButtonBase, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PageWrapper, PageHeader } from '../../../../components';
import { filterTransactions } from '../../../../actions/financials';

const StyledTypography = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  padding: ${props => (props.isActive ? '23px 13px 20px 13px' : '23px 13px')};
  border-bottom: ${props => (props.isActive ? '3px solid #212121' : 'none')};
`;

const StyledPageWrapper = styled(PageWrapper)`
  display: flex;
  width: 100%;
  @media (max-width: 560px) {
    flex-direction: column;
    > button {
      flex: 1;
      h2 {
        width: 100%;
      }
    }
  }
`;

const tabs = [
  { name: 'all', title: 'All' },
  { name: 'transactions', title: 'Card Transactions' },
  { name: 'easy_pay', title: 'Easy Pay' },
];

const Header = ({ refreshList, setPage, currentTab, changeTab, perPage }) => (
  <PageHeader>
    <StyledPageWrapper>
      {tabs.map((tab, index) => (
        <ButtonBase onClick={() => changeTab(tab.name)} key={index}>
          <StyledTypography variant="title" isActive={currentTab === tab.name}>
            {tab.title}
          </StyledTypography>
        </ButtonBase>
      ))}
    </StyledPageWrapper>
    <span className="flex-grow" />
    <ButtonBase
      onClick={() => {
        setPage(1);
        refreshList({ params: { limit: perPage, offset: 1 } });
        setPage(2);
      }}
    >
      <Icon>refresh</Icon>
    </ButtonBase>
  </PageHeader>
);

const mapDispatchToProps = dispatch => ({
  refreshList: data => dispatch(filterTransactions(data).request)
});

export default connect(
  null,
  mapDispatchToProps
)(Header);
