import { useSelector } from 'react-redux';
import { useGetAuctionVehiclesQuery } from 'services/api/admin-auction-vehicles';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export const useAdminOfferAuction = (vehicleId: number) => {
  const { page, perPage, filters } = useSelector((state: any) => state.auctionVehicles);
  const { id: auctionId } = useParams<any>();
  const { data: vehicles } = useGetAuctionVehiclesQuery({
    limit: perPage,
    offset: page,
    ...filters,
    auction_id: auctionId
  });
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: (vehicles?.rows?.ids as number[]) ?? [] },
    {
      skip: !vehicles?.rows?.ids || vehicles.rows.ids.length === 0
    }
  );

  const offerAuction = offerAuctions?.entities?.[vehicleId] ?? null;
  return offerAuction;
};

export const useHasOfferAuction = () => {
  const { page, perPage, filters } = useSelector((state: any) => state.auctionVehicles);
  const { id: auctionId } = useParams<any>();
  const { data: vehicles } = useGetAuctionVehiclesQuery({
    limit: perPage,
    offset: page,
    ...filters,
    auction_id: auctionId
  });
  const { data: offerAuctions } = useFetchActiveOffersQuery(
    { vehicleIds: (vehicles?.rows?.ids as number[]) ?? [] },
    {
      skip: !vehicles?.rows?.ids || vehicles.rows.ids.length === 0
    }
  );

  return offerAuctions && offerAuctions.ids.length > 0;
};

export const useCustomerHoverStateHandlers = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return { handleOpen, handleClose, isOpen };
};
