import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SectionWrapper, SectionTitle } from './index';

const StyledLongText = styled(Typography)`
  word-wrap: break-word;
`;

const PaymentSection = ({ seller }) => (
  <SectionWrapper>
    <SectionTitle>
      Payment
      {seller.pickup_instruction && ' / Pick Up Instructions'}
    </SectionTitle>
    {seller.purchase_options && (
      <Typography paragraph variant="body1">
        <b>Payment: </b>
        {seller.purchase_options.map(option => option.displayName).join(', ')}
      </Typography>
    )}
    {seller.pickup_instruction && (
      <StyledLongText>
        <b>Pick Up: </b>
        {seller.pickup_instruction}
      </StyledLongText>
    )}
  </SectionWrapper>
);

export default PaymentSection;
