import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';

const VehicleValues = ({ currentVehicle }) => (
  <Fragment>
    Value Without Miles Adj —{' '}
    <NumberFormat
      value={currentVehicle.bluebook_value}
      displayType="text"
      thousandSeparator
      prefix="$"
    />{' '}
    Blue Book® Lending Value —{' '}
    <NumberFormat
      value={currentVehicle.bluebook_mileage}
      displayType="text"
      thousandSeparator
      prefix="$"
    />{' '}
    Miles —{' '}
    <NumberFormat
      value={currentVehicle.mileage}
      displayType="text"
      thousandSeparator
      prefix=""
    />
  </Fragment>
);

export default VehicleValues;
