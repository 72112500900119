import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { COLOR_SUCCESSFUL, COLOR_ERROR } from '../../constants';

const StyledColoredTimer = styled.span`
  color: ${props => {
    if (props.seconds > props.colorsConfig.staleColorThreshold && props.colorsConfig.active)
      return props.colorsConfig.activeColor ?? COLOR_SUCCESSFUL;
    if (props.seconds <= props.colorsConfig.staleColorThreshold && props.colorsConfig.stale)
      return props.colorsConfig.staleColor ?? COLOR_ERROR;
    return 'currentcolor';
  }};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`;

class Timer extends Component {
  state = {
    duration: moment.duration(0, 'milliseconds')
  };

  componentDidMount() {
    const duration = this.calculateDuration();

    this.setState({ duration }, () => {
      if (duration.as('milliseconds') > 0) {
        this.initTimer();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { toDate } = this.props;
    const endDateChanged = prevProps.toDate !== toDate;

    if (endDateChanged) {
      const duration = this.calculateDuration();

      // eslint-disable-next-line
      this.setState({ duration }, () => {
        if (duration.as('milliseconds') > 0) {
          clearInterval(this.timer);
          this.initTimer();
        }
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  calculateDuration = () => {
    const { toDate } = this.props;

    const fromDate = moment()
      .utc()
      .format();
    const duration = moment(fromDate).isBefore(moment(toDate))
      ? moment.duration(
          moment(toDate)
            .utc()
            .diff(moment(fromDate).utc()),
          'milliseconds'
        )
      : moment.duration(0, 'milliseconds');
    return duration;
  };

  initTimer = () => {
    const { onTimerEnded } = this.props;
    this.timer = setInterval(() => {
      const { toDate } = this.props;
      const fromDate = moment()
        .utc()
        .format();
      const duration = moment(fromDate).isBefore(moment(toDate))
        ? moment.duration(
            moment(toDate)
              .utc()
              .diff(moment(fromDate).utc()),
            'milliseconds'
          )
        : moment.duration(0, 'milliseconds');
      if (duration.as('milliseconds') >= 0) {
        this.setState({ duration });
      } else {
        onTimerEnded();
        clearInterval(this.timer);
      }
    }, 1000);
  };

  render() {
    const { duration } = this.state;
    const {
      extraStyles = {},
      withColors,
      showDays,
      colorsConfig = { active: true, stale: true },
      fullWidth = true
    } = this.props;
    const days = showDays && `${duration.days()}d `;
    const hours = `${duration.hours()}h ${duration.minutes()}m `;
    const seconds = showDays && `${duration.seconds()}s`;
    if (colorsConfig.staleColorThreshold == null) {
      colorsConfig.staleColorThreshold = 10;
    }

    if (withColors) {
      return (
        <StyledColoredTimer
          fullWidth={fullWidth}
          colorsConfig={colorsConfig}
          title={`${days}${hours}${seconds}`}
          seconds={duration.asSeconds()}
          style={{ ...extraStyles }}
        >
          {days}
          {hours}
          {seconds}
        </StyledColoredTimer>
      );
    }
    return (
      <>
        {days}
        {hours}
        {seconds}
      </>
    );
  }
}

Timer.propTypes = {
  toDate: PropTypes.any.isRequired,
  showDays: PropTypes.bool,
  onTimerEnded: PropTypes.func,
  withColors: PropTypes.bool
};

Timer.defaultProps = {
  showDays: true,
  onTimerEnded: () => {},
  withColors: false
};

export default Timer;
