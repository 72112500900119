import { Form, Field, FormSpy } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import { ModalWrapper } from '../../components';
import { FormFormattedField, FormTextAreaField } from '../../components/Form';
import { divisibleBy, maxLength, maxValue, minValue, required } from '../../utils/validators';
import styled from 'styled-components';
import { InfoIcon } from '../../components/Icons';
import {
  useMakePrivateOfferMutation,
  useStopNegotiationMutation,
  useUnawardWithCounterMutation
} from 'services/api/offer-auction';
import './index.scss';
import { cutTextElipsis, normalizeFormattedField } from '../../utils';
import { useNoSelectorVehicleOfferAuctionById } from '../../hooks';
import { useMemo } from 'react';
import { OfferAuction, Vehicle } from '../../types/vehicle';
import { User } from '../../types/user';
import { useSelector } from 'react-redux';
import { useBidsOfferAuction } from '../../pages/Buyer/BidsList/components/hooks';
import setFieldData from 'final-form-set-field-data';

const notesMaxLen = maxLength(50);

const StyledTextAreaField = styled(Field)`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  padding-left: 8px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
`;

const StyledLabel = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
`;

const StyledHelperText = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 12px;
`;

const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-end;
`;

const OfferAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AmountBlock = styled.div`
  border-radius: 4px;
  background: #f5f5f5;
  padding: 14px 12px;
  color: #757575;
  font-size: 14px;
  max-width: 120px;
`;

const OfferedAmount = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  width: 140px;
`;

const SendOfferText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const calculateMinOfferAmount = (vehicle: Vehicle, offerAuction?: OfferAuction | null, user?: User) => {
  if (!offerAuction || !user) return 0;
  if (user.role !== 'buyer') return 0;
  const privateOffers = offerAuction.privateOffers;
  const lastBuyerOffer = privateOffers.find(el => el.sender.role === 'buyer');
  if (!lastBuyerOffer) return (vehicle.highest_bid?.amount || 0) + 100;
  return lastBuyerOffer.amount + 100;
};

const SendOfferModal = ({
  form: modalName,
  reset,
  maxAmount,
  handleSubmit,
  vehicle,
  offerAuction: propsOfferAuction,
  type = 'offer'
}: any) => {
  const divisibleBy100Validator = divisibleBy(100);

  const user = useSelector((state: any) => state.user?.user);
  const offerAuction = (() => {
    if (user.role !== 'buyer') return null;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return propsOfferAuction ?? useBidsOfferAuction(vehicle.id);
  })();
  const maxValueValidator = maxValue(maxAmount, user);

  const [makePrivateOffer] = useMakePrivateOfferMutation();
  const [stopNegotiation] = useStopNegotiationMutation();
  const [unaward] = useUnawardWithCounterMutation();

  const minOfferAmount = useMemo(() => calculateMinOfferAmount(vehicle, offerAuction, user), [
    offerAuction,
    user,
    vehicle
  ]);

  const minValueValidator = minValue(minOfferAmount);

  const initialValues = {
    notes: '',
    id: vehicle.id
  };

  const vehicleTitle = cutTextElipsis(`${vehicle.year} ${vehicle.make} ${vehicle.model}`, 20);

  const onSubmit = async (values: any) => {
    console.log('handling submit', type);
    if (type === 'stop-negotiation') {
      return await stopNegotiation({
        vehicleId: values.id,
        notes: values.notes,
        amount: values.amount
      });
    }
    if (type === 'unaward') {
      return await unaward({
        vehicleId: values.id,
        notes: values.notes,
        amount: values.amount
      });
    }
    await makePrivateOffer({
      vehicleId: values.id,
      notes: values.notes,
      amount: values.amount
    });
  };

  return (
    <Form mutators={{ setFieldData }} onSubmit={onSubmit} initialValues={initialValues} destroyOnUnregister>
      {({ handleSubmit, submitting, form }) => (
        <ModalWrapper
          // @ts-ignore
          title={`Send Counter to ${vehicleTitle}`}
          submitting={submitting}
          handleSubmit={handleSubmit}
          modalId={modalName}
          cancelAdditionalAction={reset}
          className="send-offer-modal"
          actionsClassName="send-offer-modal-actions"
        >
          <form name={modalName} onSubmit={handleSubmit} style={{ maxWidth: '100%' }}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SendOfferText>Do you want to send a counter offer?</SendOfferText>
              </Grid>
              <Grid item xs={12}>
                <OfferAmountContainer>
                  <span>Offered Amount</span>
                  <AmountBlock>
                    <OfferedAmount>
                      <Field
                        name="amount"
                        placeholder={user?.role === 'buyer' ? `$${minOfferAmount} min` : '$0'}
                        component={FormFormattedField}
                        thousandSeparator
                        allowNegative={false}
                        fixedDecimalScale
                        prefix="$"
                        disableUnderline
                        validate={composeValidators(
                          required,
                          maxValueValidator,
                          divisibleBy100Validator,
                          minValueValidator
                        )}
                        parse={normalizeFormattedField}
                        fullWidth
                        hideErrors
                      />
                    </OfferedAmount>
                  </AmountBlock>
                </OfferAmountContainer>
              </Grid>
              <Grid item xs={12}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <StyledLabel>Notes</StyledLabel>
                  <HelperTextContainer>
                    <StyledHelperText>Max. 50 characters</StyledHelperText>
                    <InfoIcon />
                  </HelperTextContainer>
                </Grid>
                <StyledTextAreaField
                  name="notes"
                  // @ts-ignore
                  component={FormTextAreaField}
                  disableUnderline
                  fullWidth
                  validate={notesMaxLen}
                  rows={2}
                />
              </Grid>
            </Grid>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }: any) => {
                if (user.role !== 'admin' || !maxAmount) return;
                const amount = Number(values?.amount ?? 0);
                const warning = amount >= maxAmount ? 'Your counter exceeds reserve.' : undefined;
                form.mutators.setFieldData('amount', {
                  warning
                });
              }}
            />
          </form>
        </ModalWrapper>
      )}
    </Form>
  );
};

export default SendOfferModal;
