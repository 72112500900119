import { useSelector } from 'react-redux';
import { SectionBlock } from '../components';
import { Switch } from '@material-ui/core';
import styled from 'styled-components';
import { useEditNotificationsSettingsMutation } from 'services/api';
import { Field, Form, FormSpy } from 'react-final-form';
import { useMemo } from 'react';

const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const SwitchComponent = styled.div`
  display: flex;
  color: #757575;
  font-size: 14px;
  align-items: center;

  & > span {
    min-width: fit-content;
  }
`;

export const NotificationSettings = () => {
  const user = useSelector(state => state.user?.user);
  const [updateNotificationSettings, { isLoading }] = useEditNotificationsSettingsMutation();

  const initialValues = useMemo(
    () => ({
      visualNotificationsEnabled: user?.visual_notifications_enabled,
      audioNotificationsEnabled: user?.audio_notifications_enabled
    }),
    [user?.audio_notifications_enabled, user?.visual_notifications_enabled]
  );

  if (!user) return null;

  const renderCheckbox = ({ input }) => {
    return <Switch color="primary" {...input} />;
  };

  const submitForm = async values => {
    if (isLoading) return;
    try {
      await updateNotificationSettings({
        userId: user.id,
        audioNotificationsEnabled: values.audioNotificationsEnabled,
        visualNotificationsEnabled: values.visualNotificationsEnabled,
      });
    } catch (error) {
      console.error('Cant submit form', error);
    }
  };

  return (
    <Form onSubmit={submitForm} initialValues={initialValues}>
      {({ form }) => (
        <SectionBlock title="Notifications" isEditable={true} showControlButtons={false} submit={() => {}}>
          <ContentWrapper>
            <SwitchComponent>
              <span>Turn off/on visual notifications</span>
              <Field
                name="visualNotificationsEnabled"
                type="checkbox"
                render={renderCheckbox}
              />
            </SwitchComponent>
            <SwitchComponent>
              <span>Turn off/on audio notifications</span>
              <Field
                name="audioNotificationsEnabled"
                type="checkbox"
                render={renderCheckbox}
              />
            </SwitchComponent>
          </ContentWrapper>
          <FormSpy
            subscription={{ values: true }}
            onChange={async () => {
              try {
                await form.submit();
              } catch (e) {
                console.error(e);
              }
            }}
          />
        </SectionBlock>
      )}
    </Form>
  );
};
