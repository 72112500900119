import { ChargeBuyerModal } from '../../../../components';
import { Vehicle } from '../../../../types/vehicle';
import { useDispatch } from 'react-redux';
import { adminApproveBid } from '../../../../actions';

type Props = {
  vehicle: Vehicle;
};

export const AdminAcceptHighBidModal = ({ vehicle }: Props) => {
  const dispatch = useDispatch();
  const chargeAction = (data: any) => dispatch(adminApproveBid(data).request)

  return (
    <ChargeBuyerModal modalId={`acceptHighBid-${vehicle.id}`} vehicle={vehicle} chargeAction={chargeAction} />
  );
};
