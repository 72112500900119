import { createEntityAdapter } from '@reduxjs/toolkit';
import { Vehicle } from '../../../types/vehicle';
import apiSlice from '..';
import { EntityWithCount } from '../types';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/dist/query';

type SalesQueryArgs = {
  [x: string]: any;
};

export const sellerManageVehiclesAdapter = createEntityAdapter<Vehicle>();

export const sellerManageVehiclesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSellerManageVehicles: builder.query<EntityWithCount<Vehicle>, SalesQueryArgs>({
      query: ({ limit, offset, ...filters }) => ({
        url: 'seller/vehicles',
        method: 'GET',
        params: { limit, offset, ...filters }
      }),
      transformResponse: (response: any) => {
        return {
          rows: sellerManageVehiclesAdapter.addMany(sellerManageVehiclesAdapter.getInitialState(), response.data.rows),
          count: Number(response.data.count)
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        const { offset, ...restArgs } = queryArgs;
        return defaultSerializeQueryArgs({ queryArgs: restArgs, endpointDefinition, endpointName });
      },
      merge: (currentCache, newItems, { arg }) => {
        const newVehicles = Object.values(newItems.rows.entities) as Vehicle[];
        currentCache.count = newItems.count;
        if (arg.offset === 1) {
          sellerManageVehiclesAdapter.setAll(currentCache.rows, newVehicles);
        } else {
          sellerManageVehiclesAdapter.addMany(currentCache.rows, newVehicles);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      }
    })
  }),
  overrideExisting: true
});

export const { useGetSellerManageVehiclesQuery } = sellerManageVehiclesApi;
