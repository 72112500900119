import { connect } from 'react-redux';
import './CreditCardPickerStyle.scss';
import OutlinedFormFormattedField from 'components/Form/FinalForm/OutlinedFormFormattedField';
import styled from 'styled-components';

const DEFAULT_CARD_FORMAT = '#### #### #### ####';

export const CARD_TYPES = [
  {
    format: '#### ###### #####',
    startPattern: /^3[47]/
  }
];

const Wrapper = styled.div`
  display: grid;
  gap: 6px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
`;

const getMaskProps = value => {
  return {
    format: CARD_TYPES.find(t => t.startPattern.test(value))?.format || DEFAULT_CARD_FORMAT,
    mask: '_'
  };
};

const creditCardComponent = () => {
  return (
    <>
      <OutlinedFormFormattedField
        name="credit_card_number"
        label="Credit Card Number*"
        getMaskProps={getMaskProps}
        placeholder={DEFAULT_CARD_FORMAT}
      />

      <Wrapper xs={12} sm={6}>
        <OutlinedFormFormattedField
          name="credit_card_expiry_date"
          label="Credit Card Expiry Date*"
          maskProps={{
            format: '##/##',
            placeholder: 'MM/YY',
            mask: ['M', 'M', 'Y', 'Y']
          }}
          placeholder=""
        />
        <OutlinedFormFormattedField
          name="credit_card_cvc"
          label="CVV*"
          maskProps={{
            format: '###',
            mask: '_'
          }}
          placeholder=""
        />
      </Wrapper>
    </>
  );
};

export default creditCardComponent;
