import styled from 'styled-components';
import { Typography, Button, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FormResetButton, FormTextField } from '../../../../components/Form';
import { Form, Field, FormSpy } from 'react-final-form';
import { setStatusFilter } from 'services/sellerVehicles/sellerVehiclesSlice';
import { useHistory } from 'react-router-dom';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 660px) {
    flex-direction: column;
    button {
      width: 100%;
      margin: 10px 0 0 0 !important;
    }
  }
  div div {
    min-width: 240px;
  }
  button {
    margin-left: 10px;
  }
`;

const StyledButton = styled(Button)`
  h2 {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  button {
    margin: 0 0 5px 5px;
  }
`;

const Filter = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Form onSubmit={() => {}}>
      {props => (
        <StyledFilterWrapper>
          <StyledFieldWrapper>
            <Field component={FormTextField} name="status" label="Filter by Car Status" select>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="awarded">Awarded</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="pulled">Pulled</MenuItem>
              <MenuItem value="deactivated">Deactivated</MenuItem>
            </Field>
            {props.dirty && <FormResetButton onClick={props.form.reset} />}
          </StyledFieldWrapper>
          <span className="flex-grow" />
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/vehicles/add');
            }}
          >
            <Typography variant="title">Upload Vehicle</Typography>
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/upload-vehicle?upload_type=manual');
            }}
          >
            <Typography variant="title">Manual Upload</Typography>
          </StyledButton>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              dispatch(setStatusFilter(values.status));
            }}
          />
        </StyledFilterWrapper>
      )}
    </Form>
  );
};

export default Filter;
