import styled from 'styled-components';
import { VehicleSideCard } from './VehicleSideCard';
import { useGetSidePanelBidsQuery } from 'services/api/my-bids';
import { VEHICLE_STATUSES } from '../../constants';
import { useSelector } from 'react-redux';

const Container = styled.div<{ cropHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px 8px;
  height: 100%;
  gap: 12px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  overflow-y: auto;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const VehicleSidePanel = () => {
  const user = useSelector((state: any) => state.user?.user);
  const { data } = useGetSidePanelBidsQuery(undefined, { skip: !user || user.role !== 'buyer' });
  const items =
    data?.rows?.ids.map(id => data?.rows?.entities[id]!)?.filter(item => item.status === VEHICLE_STATUSES.ACTIVE) ?? [];

  if (items.length === 0) return null;

  return (
    <Container>
      {items.map(vehicle => {
        return <VehicleSideCard vehicle={vehicle} key={vehicle.id} />;
      })}
    </Container>
  );
};
