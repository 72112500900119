import styled from 'styled-components';
import {
  OtherBuyersNegotiationLabel,
  SellerNegotiationLabel,
  BuyerNegotiationLabel
} from '../../img/negotiation-label';
import { OfferAuctionStatus } from '../../types/vehicle';
import { VEHICLE_STATUSES } from '../../constants';
import moment from 'moment';
import { useSelector } from 'react-redux';

const NegotiationLabelWrapper = styled.div<{ position: string }>`
  position: absolute;
  bottom: ${({ position }) => (position === 'bottom' ? '0.3px' : 'initial')};
  top: ${({ position }) => (position === 'top' ? '0' : 'initial')};
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const NegotiationLabel = ({ vehicle, offerAuction, position = 'bottom' }: any) => {
  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(moment.utc());
  const user = useSelector((state: any) => state.user?.user);

  const renderNegotiationLabel =
    isVehicleTimerEnded &&
    user?.role !== 'admin' &&
    offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS &&
    vehicle?.status === VEHICLE_STATUSES.ACTIVE;

  if (!renderNegotiationLabel) return null;

  const LabelComponent = () => {
    if (user.role === 'buyer') {
      return user.id === offerAuction?.top_bidder_id ? <SellerNegotiationLabel /> : <OtherBuyersNegotiationLabel />;
    }

    return <BuyerNegotiationLabel />;
  };

  return (
    <NegotiationLabelWrapper position={position}>
      <LabelComponent />
    </NegotiationLabelWrapper>
  );
};
