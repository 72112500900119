import { MODAL_TOGGLE, MODAL_HIDE } from '../constants/actionTypes';

const initialState = {
  open: false,
  modalId: ''
};

const modals = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL_TOGGLE:
      return {
        ...state,
        loading: false,
        modalId: payload,
        open: state.modalId === payload ? !state.open : true
      };
    case MODAL_HIDE:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default modals;
