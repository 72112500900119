import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { SectionWrapper, SectionTitle } from './index';

const StyledDealerComments = styled(Typography)`
  word-wrap: break-word;
`;

const DealerCommentsSection = ({ dealerComments }) => {
  if (!dealerComments) return null;
  return (
    <SectionWrapper>
      <SectionTitle>Inspector Comments</SectionTitle>
      <StyledDealerComments>{dealerComments}</StyledDealerComments>
    </SectionWrapper>
  );
};

export default DealerCommentsSection;
