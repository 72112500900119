import { Field, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { UploadIcon } from '../../Icons/UploadIcon';
import { FileIcon } from '../../Icons/FileIcon';
import { BinIcon } from '../../Icons';
import { RefreshIcon } from '../../Icons/RefreshIcon';
import { required } from '../../../utils/validators';

type Props = {
  name: string;
  label: string;
  validate?: any;
};

const NoFileWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3994de;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  border-radius: 8px;
  color: #3994de;
  cursor: pointer;
  gap: 8px;
  padding: 8px 6px;
  height: 36px;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #999999;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  border-radius: 8px;
  color: #3994de;
  cursor: pointer;
  padding: 8px 6px;
  height: 36px;
`;

const SidePartWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
`;

const Label = styled.div<{ hasError: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: ${props => (props.hasError ? '#AF2930' : '#b9b9b9')};
`;

const InputComponent = (inputProps: FieldRenderProps<any>['input']) => (
  <input
    type="file"
    style={{ display: 'none' }}
    accept=".jpg,.jpeg,.png,.pdf"
    id={inputProps.name}
    onChange={({ target }) => inputProps.onChange(target.files)}
  />
);

export const OutlinedFormFileUpload = ({ name, label, validate = undefined }: Props) => {
  return (
    <Field name={name} validate={validate}>
      {fieldProps => {
        const hasError = fieldProps.meta.touched && fieldProps.meta.error;
        console.log(fieldProps.meta.error, 'error');
        if (!fieldProps.input.value) {
          return (
            <Wrapper>
              <Label hasError={hasError}>{label}</Label>
              <NoFileWrapper htmlFor={name}>
                <UploadIcon />
                <span>Upload</span>
                <InputComponent {...fieldProps.input} />
              </NoFileWrapper>
            </Wrapper>
          );
        }

        const [file] = fieldProps.input.value;

        return (
          <Wrapper>
            <Label hasError={hasError}>{label}</Label>
            <FileWrapper>
              <SidePartWrapper>
                <FileIcon />
                <span>{file.name}</span>
              </SidePartWrapper>
              <SidePartWrapper style={{ cursor: 'pointer' }}>
                <label htmlFor={name}>
                  <RefreshIcon />
                </label>
                <div onClick={() => fieldProps.input.onChange(null)}>
                  <BinIcon />
                </div>
              </SidePartWrapper>
              <InputComponent {...fieldProps.input} />
            </FileWrapper>
          </Wrapper>
        );
      }}
    </Field>
  );
};
