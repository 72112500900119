import styled from 'styled-components';
import { useGetFormControlButtons } from '../../hooks/useGetFormControlButtons';
import { useField } from 'react-final-form';
import { useGetCurrentAuctionsQuery } from '../../../../services/api/auctions-list';

const Container = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Label = styled.span`
  margin-left: auto;
  font-size: 16px;
  color: rgba(102, 102, 102, 1);

  @media (min-width: 1024px) {
    width: 600px;
    font-size: 22px;
    line-height: 24px;
  }
`;

export const Footer = () => {
  const { CancelButton, SubmitForReviewButton, SubmitButton } = useGetFormControlButtons();

  const {
    input: { value: auctionType }
  } = useField('auction_type', { subscription: { value: true } });

  const { data } = useGetCurrentAuctionsQuery();
  const { autoaxess: currentAutoaxessAuction, frontline: currentFlAuction } = data || {};
  const currentAuction = auctionType === 'autoaxess' ? currentAutoaxessAuction : currentFlAuction;

  return (
    <Container>
      <ButtonContainer>
        <CancelButton />
        <SubmitForReviewButton />
      </ButtonContainer>

      {currentAuction ? (
        <div style={{ marginLeft: 'auto' }}>
          <SubmitButton />
        </div>
      ) : (
        <Label>
          Currently, there is not an active auction to list the vehicle in. Please save as a draft until AutoAxess
          creates a new auction.
        </Label>
      )}
    </Container>
  );
};
