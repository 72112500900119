import {
  LOGS_COUNT_SUCCESS,
  LOGS_COUNT_REQUEST,
  LOGS_COUNT_FAILURE,
  LOGS_REQUEST,
  LOGS_FAILURE,
  LOGS_SUCCESS,
  LOGS_SET_PAGE,
  FILTER_LOGS_FAILURE,
  FILTER_LOGS_REQUEST,
  FILTER_LOGS_SUCCESS
} from '../actions/logs';
import { LOGS_PER_PAGE } from '../constants';

const initialState = {
  loading: true,
  itemsData: {
    rows: [],
    count: 0
  },
  unreadLogs: 0,
  page: 0,
  perPage: LOGS_PER_PAGE
};

const statistics = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGS_COUNT_REQUEST:
    case FILTER_LOGS_REQUEST:
    case LOGS_REQUEST:
      return { ...state, loading: true };
    case LOGS_COUNT_FAILURE:
    case LOGS_FAILURE:
    case FILTER_LOGS_FAILURE:
      return { ...state, loading: false };
    case LOGS_SET_PAGE:
      return { ...state, page: payload };
    case LOGS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadLogs: payload
      };
    case LOGS_SUCCESS:

      // eslint-disable-next-line
      const alredyExisting = state.itemsData.rows.map(
        item => item.id
      );
      return {
        ...state,
        loading: false,
        itemsData: {
          count: payload.data.count,
          rows: [
            ...state.itemsData.rows,
            ...payload.data.rows.filter(
              ({ id }) => !alredyExisting.includes(id)
            )
          ]
        }
      };

    case FILTER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        unreadLogs: 0,
        itemsData: {
          count: payload.data.count,
          rows: payload.data.rows
        }
      };
    default:
      return state;
  }
};

export default statistics;
