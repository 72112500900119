import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const styles = () => ({
  root: {
    fontWeight: 500,
    color: '#000'
  },
  selectRoot: {
    background: '#f5f5f5',
    padding: '4px 6px 4px'
  },
  select: {
    background: '#f5f5f5 !important'
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
      background: '#f5f5f5'
    },
    '&:after': {
      borderBottom: 'none',
      background: '#f5f5f5'
    }
  },
});

function FormSelect({ useClasses, classes, input, label = '', meta: { touched, error, invalid }, children, ...custom }) {
  return (
    <TextField
      className="mui-text-select"
      error={touched && invalid}
      label={label}
      helperText={touched && error}
      select
      readOnly
      classes={useClasses ? {
        root: classes.root
      } : {}}
      InputProps={useClasses ? {
        underline: classes.underline
      } : {}}
      SelectProps={useClasses ? {
        classes: {
          underline: classes.underline,
          root: classes.selectRoot,
          select: classes.select
        }
      }: {}}
      {...input}
      {...custom}
    >
      {children}
    </TextField>
  );
}

export default withStyles(styles)(FormSelect);
