import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export const RoleRestricted = props => {
  const { role, jobTitle, allowed, allowedJobs = [], component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={p =>
        allowed.includes(role) && (allowedJobs.length === 0 || allowedJobs.includes(jobTitle)) ? (
          <Component {...p} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

RoleRestricted.propTypes = {
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  role: PropTypes.string.isRequired
};

export default connect(
  state => ({
    role: state.user.user.role,
    jobTitle: state.user.user.job_title
  }),
  null
)(RoleRestricted);
