import React from 'react';
import NumberFormat from 'react-number-format';

const FormattedAmount = ({ amount, ...rest }) => (
  <NumberFormat
    value={amount}
    displayType="text"
    prefix="$"
    thousandSeparator
    {...rest}
  />
);

export default FormattedAmount;
