/* eslint-disable jsx-a11y/label-has-for */
import React, {Component} from 'react';
import {change} from 'redux-form';
import {Button, Typography, Icon, FormHelperText} from '@material-ui/core';
import styled from 'styled-components';

const StyledFieldWrapper = styled.div``;

const StyledLabel = styled(Typography)`
  font-size: 16px;
`;

const StyledFileText = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 12px;
    background: rgba(0, 90, 250, 0.2);
    padding: 5px 10px;
    border-radius: 34px;
    letter-spacing: 0.4px;
    display: flex;
  }
`;

const StyledFileControls = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const StyledUploadButton = styled(Button)`
  && {
    margin-top: 5px;
  }
`;

const StyledControlIcon = styled(Icon)`
  && {
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
  }
`;

const StyledFileIcon = styled(Icon)`
  && {
    font-size: 16px;
    margin-right: 3.5px;
  }
`;

const StyledDeleteButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`;

const StyledError = styled(FormHelperText)`
  && {
    margin-top: 5px;
  }
`;

class FormFileField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fileSizeError: false
    };
  }


  adaptFileEventToValue = delegate => e => {
    const fileSize = e.target.files[0].size;

    this.setState({
      fileSizeError: false
    });

    if (fileSize > 31457280) {
      return this.setState({
        fileSizeError: true
      })
    }
    return delegate(e.target.files[0]);
  };

  render() {
    const {
      input: {value: omitValue, onChange, onBlur, ...inputProps},
      id,
      name,
      label,
      buttonText,
      meta: {touched, error, invalid, dispatch},
      ...custom
    } = this.props;
    const {fileSizeError} = this.state;

    return (

      <StyledFieldWrapper>
        <StyledLabel>{label}</StyledLabel>
        <input
          key={omitValue ? (omitValue || {}).name : +new Date()}
          onChange={this.adaptFileEventToValue(onChange)}
          onBlur={this.adaptFileEventToValue(onBlur)}
          type="file"
          style={{display: 'none'}}
          id={id}
          name={name}
          accept="image/png, image/jpeg, application/pdf, msword/doc"
          {...inputProps}
        />
        {!omitValue && (
          <label htmlFor={id}>
            {buttonText && (
              <StyledUploadButton
                variant="contained"
                color="primary"
                component="span"
                {...custom}
              >
                {buttonText}
              </StyledUploadButton>
            )}
            {!buttonText && (
              <StyledUploadButton
                variant="contained"
                color="primary"
                component="span"
                {...custom}
              >
                Upload
              </StyledUploadButton>
            )}

          </label>
        )}
        {omitValue && (
          <StyledFileControls>
            <StyledFileText component="span">
              <StyledFileIcon>insert_drive_file</StyledFileIcon> {omitValue.name}
            </StyledFileText>
            <label htmlFor={id}>
              <StyledControlIcon>cached</StyledControlIcon>
            </label>
            <StyledDeleteButton
              type="button"
              onClick={() =>
                dispatch(change('registrationForm', inputProps.name, ''))
              }
            >
              <StyledControlIcon>delete</StyledControlIcon>
            </StyledDeleteButton>
          </StyledFileControls>
        )}
        {fileSizeError && <StyledError error>Maximum file size is 30MB</StyledError>}
        {touched && invalid && error && <StyledError error>{error}</StyledError>}
      </StyledFieldWrapper>
    )
  };

}

export default FormFileField;
