import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import { composeValidators, required, validateEmail } from '../../../utils/validators';
import { user } from '../../../utils/api';
import { AutoaxessLogo } from '../components/AutoaxessLogo';
import { Form } from 'react-final-form';
import { useForgotPasswordMutation } from 'services/api/auth';
import { useDispatch } from 'react-redux';
import { StyledPaper } from '../components/PageCotainer';
import { hasVisibleFormError } from '../../../utils/form';
import OutlinedFormTextField from '../../../components/Form/FinalForm/OutlinedFormTextField';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StyledButton = styled(Button)`
  && {
    margin-bottom: 10px;
    border-radius: 8px;
    text-transform: none;
  }

  &:disabled {
    background: #225a91;
    opacity: 0.5;
    color: #fff;
  }
`;

const HeaderText = styled.div`
  align-self: flex-start;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000;
  margin: 12px 0;
`;

const AccountHelperText = styled.div`
  font-size: 12px;
  font-weight: 500;
  align-self: flex-start;
  color: #b9b9b9;

  & > span {
    color: #3994de;
    cursor: pointer;
  }

  & > span:hover {
    text-decoration: underline;
  }
`;

const emailFieldValidation = composeValidators(required, validateEmail);

const submitForgotPasswordForm = (values, dispatch) =>
  user
    .forgotPassword(values)
    .then(() => {
      dispatch(push('/success/forgot-password'));
    })
    .catch(({ response }) => {
      const errors = response.data.data;
      if (errors) {
        const validationErrors = errors.reduce(
          (acc, error) => ({
            ...acc,
            [error.field]: error.message
          }),
          {}
        );
        throw new SubmissionError(validationErrors);
      }
    });

const forgotPasswordForm = () => {
  const [forgotPassword] = useForgotPasswordMutation();
  const dispatch = useDispatch();

  const onSubmit = async values => {
    const { error } = await forgotPassword(values);
    if (error) {
      return {
        email: 'Server failed. Please try again'
      };
    }

    dispatch(push('/success/forgot-password'));
  };

  return (
    <StyledPaper>
      <Form initialValues={{ email: '' }} onSubmit={onSubmit}>
        {props => {
          const disableSubmitButton = hasVisibleFormError(props);
          return (
            <StyledForm onSubmit={props.handleSubmit}>
              <AutoaxessLogo />
              <HeaderText>Forgot Password</HeaderText>
              <OutlinedFormTextField
                name="email"
                validate={emailFieldValidation}
                type="email"
                placeholder="John.smith@gmail.com"
                label="E-mail*"
              />
              <StyledButton disabled={disableSubmitButton} variant="raised" color="primary" type="submit" fullWidth>
                Send Email
              </StyledButton>
              <AccountHelperText>
                Have an account? <span onClick={() => dispatch(push('/login'))}>Login</span>
              </AccountHelperText>
            </StyledForm>
          );
        }}
      </Form>
    </StyledPaper>
  );
};

export default forgotPasswordForm;
