import { useField } from 'react-final-form';

export const useIsManualTransmissionVehicle = (skip?: boolean) => {
  const {
    input: { value: transmission }
  } = useField('transmission', { subscription: { value: !skip } });

  if (skip) return false;

  if (!transmission) return false;

  return transmission.toLowerCase().includes('manual');
};
