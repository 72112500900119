import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import ModalWrapper from '../../../../components/ModalWrapper';
import { FormSelect } from '../../../../components/Form';
import { required } from '../../../../utils/validators';

const RelistVehicleModal = ({ handleSubmit, loading, invalid, auctions }) => (
  <ModalWrapper
    title="Re-list Vehicle"
    handleSubmit={handleSubmit}
    modalId="relistVehicleModal"
    submitText="Confirm"
    submitting={invalid || loading}
  >
    <Typography>
      Please choose an auction in which you’d like to re-list the vehicle
    </Typography>
    <Field
      component={FormSelect}
      id="auction"
      name="auction"
      label="Auction"
      validate={[required]}
      fullWidth
    >
      {auctions.map((e, i) => (
        <MenuItem key={i} value={e.id}>
          {`#${e.id} ${e.formatedStartDate}`}
          {e.isCurrent && ` (current)`}
        </MenuItem>
      ))}
    </Field>
  </ModalWrapper>
);

RelistVehicleModal.propTypes = {
  handleSubmit: PropTypes.func
};
RelistVehicleModal.defaultProps = {
  handleSubmit: () => {}
};

export default reduxForm({
  form: 'RelistVehicleModal'
})(RelistVehicleModal);
