import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useScreen } from 'usehooks-ts';
import { DesktopCheckboxGrid } from './DesktopCheckboxGrid';
import { MobileCheckboxGrid } from './MobileCheckboxGrid';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 1024px) {
    gap: 32px;
  }
`;

type Props = {
  sectionId: keyof SectionConfig;
};

export const Checkboxes = ({ sectionId }: Props) => {
  const { width = 0 } = useScreen() || { width: 0 };

  const CheckboxGrid = width > 1024 ? DesktopCheckboxGrid : MobileCheckboxGrid;

  return (
    <Container>
      <FieldArray name={`${sectionId}.checkboxes`}>{() => <CheckboxGrid sectionId={sectionId} />}</FieldArray>
    </Container>
  );
};
