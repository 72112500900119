import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: ${props => (props.textSize === 'lg' ? '16px' : '14px')};
  color: #757575;
  display: flex;
  flex-direction: column;
`;

const Value = styled.span`
  font-size: ${props => (props.textSize === 'lg' ? '16px' : '14px')};
  font-weight: 500;
  color: #000;
`;

export const MobileHorizontalCard = ({ columns, textSize = 'lg' }) => {
  return (
    <Wrapper>
      {columns.map(({ label, value, styles={} }) => {
        console.log(styles, 'styles')
        return (
          <Content style={styles}>
            <Label textSize={textSize}>{label}</Label>
            <Value textSize={textSize}>{value}</Value>
          </Content>
        );
      })}
    </Wrapper>
  );
};
