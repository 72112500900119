import { Field, useField } from 'react-final-form';
import { PageSection } from '../../../components/VehicleCreation';
import { Checkbox, Collapse, FormControlLabel } from '@material-ui/core';
import { FormAutocompleteSelect } from '../../../components';
import { useGetSellersQuery } from '../../../services/api';
import { RegularSectionContainer } from '../components/RegularSectionContainer';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleIsSellerLocked } from '../../../services/sellerLock/sellerLockSlice';

export const SellerSection = () => {
  return <Field name="seller_id" render={SellerField} />;
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 60px;
  div:first-child {
    flex: 1 1 80%;
  }
`;

export const SellerField = () => {
  const { input, meta } = useField('seller_id');

  const { data: users } = useGetSellersQuery();

  const userOptions =
    users?.map(u => ({
      value: u.id,
      label: u.dealership_name
    })) || [];

  const selectedUser = userOptions.find(seller => {
    return seller.value === input?.value;
  });

  const isSellerLocked = useSelector((state: any) => state.sellerLock.is_seller_locked);
  const dispatch = useDispatch();

  const updateSeller = (sellerObj: any) => {
    const newValue = sellerObj?.value || null;
    input.onChange(newValue);
    if (!isSellerLocked) return;
    localStorage.setItem('locked_seller', newValue);
  };

  return (
    <RegularSectionContainer title="Seller">
      <Wrapper>
        <FormAutocompleteSelect
          label="Select seller"
          name="seller_id"
          defaultValue={selectedUser}
          value={selectedUser}
          options={userOptions}
          onChange={updateSeller}
          fullWidth
          style={{ width: '100%' }}
          select
          meta={meta}
          input={input}
        />

        <FormControlLabel
          label="Lock seller"
          control={
            <Checkbox
              checked={isSellerLocked}
              style={{ padding: 0, paddingRight: 6 }}
              onChange={() => dispatch(toggleIsSellerLocked(input.value))}
            />
          }
        />
      </Wrapper>
    </RegularSectionContainer>
  );
};
