import React from 'react';
import styled from 'styled-components';
import {
  ContactsSection,
  AddressSection,
  RegionSection,
  DealerLicenseSection,
  AlternativeAddressSection
} from '../sections';

const SectionWrapper = styled.div`
  > div:not(:first-child) {
    margin-top: 16px;
  }
`;

const AboutYourCompanyTab = ({ user }) => (
  <SectionWrapper>
    <DealerLicenseSection initialValues={user} />
    <AddressSection initialValues={user} />
    <AlternativeAddressSection initialValues={user} />
    <RegionSection initialValues={user} />
    <ContactsSection initialValues={user} />
  </SectionWrapper>
);

export default AboutYourCompanyTab;
