import { FormattedAmount } from '../../../../../components';
import { StyledLabel, StyledText, Wrapper } from './styled';
import Tooltip from '../../../../../components/Tooltip';
import { useRef } from 'react';
import { useIsTextOverflown } from '../../../../../hooks';
import { useDeviceSelectors, useMobileOrientation } from 'react-device-detect';
import { useScreen } from 'usehooks-ts';

export const VehicleCostInfoBlock = ({ currentVehicle }) => {
  const costRef = useRef(null);
  const stockRef = useRef(null);
  const notesRef = useRef(null);

  const isCostTextOverflown = useIsTextOverflown(costRef);
  const isStockTextOverflown = useIsTextOverflown(stockRef);
  const isNotesTextOverflown = useIsTextOverflown(notesRef);

  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isPortrait } = useMobileOrientation();

  const { isTablet } = selectors;

  const { width = 0 } = useScreen();

  const lowerWidth = width < 1200 && width > 567;

  const maxWidth = (() => {
    if (width < 567) return undefined;
    return lowerWidth ? 80 : 120;
  })();

  return (
    <div className="cost-info-block">
      <Wrapper>
        <StyledLabel>Cost:</StyledLabel>
        <Tooltip
          isTextOverflown={isCostTextOverflown}
          disableFocusListener
          title={currentVehicle.cost ?? null}
          arrow
          light
        >
          <StyledText maxWidth={maxWidth} ref={costRef}>
            {currentVehicle.cost ? <FormattedAmount amount={currentVehicle.cost || ''} /> : '-'}
          </StyledText>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Stock#:</StyledLabel>
        <Tooltip
          isTextOverflown={isStockTextOverflown}
          disableFocusListener
          title={currentVehicle.stock ?? null}
          arrow
          light
        >
          <StyledText maxWidth={maxWidth} ref={stockRef}>
            {currentVehicle.stock || '-'}
          </StyledText>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <StyledLabel>Notes:</StyledLabel>
        <Tooltip
          isTextOverflown={isNotesTextOverflown}
          disableFocusListener
          title={currentVehicle.notes ?? null}
          arrow
          light
        >
          <StyledText maxWidth={maxWidth} ref={notesRef}>
            {currentVehicle.notes || '-'}
          </StyledText>
        </Tooltip>
      </Wrapper>
    </div>
  );
};
