import styled from 'styled-components';
import { ActiveItem } from '../../../../services/listVehiclesSlice/listVehiclesSlice';
import { MapPin } from './MapPin';
import { formatAddress } from '../utils/formatAddress';
import { ListerSaveIcon } from '../../../../components/Icons/ListerSaveIcon';
import { ConfirmIcon } from '../../../../components/Icons/ConfirmIcon';

const RequestWrapper = styled.div`
  display: flex;
  background: #757575;
  padding: 14px 16px 14px 20px;
  align-items: center;
  gap: 8px;
  height: 76px;
`;

const AddressBlock = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #222222;
  max-width: 190px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const EmptySpace = styled.div`
  flex-grow: 1;
`;

const NumberOfVehicles = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #222222;
  min-width: fit-content;
`;

export const DragItem = ({ item }: { item: ActiveItem }) => {
  const confirmIconColor = item.status === 'done' ? '#479747' : '#757575';
  return (
    <RequestWrapper>
      <MapPin dayNumber={item.dayNumber || 0} orderIndex={item.orderIndex || 1} />
      <AddressBlock>{`${item.user.dealership_name} - ${formatAddress(item.user)}`}</AddressBlock>
      <EmptySpace />
      <NumberOfVehicles>{item.number_of_vehicles || '-'}</NumberOfVehicles>
      <IconWrapper>
        <ListerSaveIcon />
      </IconWrapper>
      <IconWrapper>
        <ConfirmIcon fillColor={confirmIconColor} />
      </IconWrapper>
    </RequestWrapper>
  );
};
