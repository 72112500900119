export const getForm = () => JSON.parse(localStorage.getItem('form') || "{}");

export const setForm = (data) => localStorage.setItem('form', JSON.stringify(data));

export const removeForm = () => localStorage.removeItem('form');

export default {
  getForm,
  setForm,
  removeForm
}