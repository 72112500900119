import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Header, Filter } from './components';
import {
  VehicleCostInfoBlock,
  VehicleSecondaryInfoBlock,
  VehicleBidInfoBlock,
  VehicleOptionsBlock,
  ButtonsBlock
} from './components/tableBlocks';
import { Delimiter } from '../../../components/Table/common';
import NotFoundDescription from './containers/NotFoundDescription';
import { Table } from '../../../components';
import { modalsToggle } from '../../../actions';
import { EditIcon as EditIconSvg } from '../../../components/Icons';
import { useFetchActiveOffersQuery } from 'services/api/offer-auction';
import { useGetMySalesQuery } from '../../../services/api/sales';
import { setPage } from '../../../services/mySales/salesSlice';
import { useMySalesSocketEffect } from 'services/socket/handleSaleEvents';
import { DashboardModals } from './components/tableBlocks/Modals';
import { TabletDashboardBlock } from './components/tableBlocks/TabletBlock';
import { useWindowSize } from 'usehooks-ts';
import { Button } from '@material-ui/core';
import ListVehiclesDialog from '../../../components/ListVehicles/ListVehiclesDialog';
import { isMobile } from 'react-device-detect';

const EditIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 16px;
  width: 20px;
  height: 20px;
  color: #757575;

  @media only screen and (max-width: 768px) {
    right: 8px;
  }
`;

const columns = [
  { title: '', key: 'details' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'secondary_info' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'cost_info' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'bid_info' },
  { title: '', key: 'vehicle_buttons' },
  { title: '', key: '' },
  { title: '', key: 'modals' }
];

const tabletHorizontalColumns = [
  { title: '', key: 'details' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'secondary_info' },
  { title: '', key: 'delimiter' },
  { title: '', key: 'cost_info' },
  { title: '', key: 'modals' }
];

const footerColumns = [{ title: '', key: 'tablet_footer', size: 'sm' }];

const StyledTableWrapper = styled.div`
  div {
    td:first-child {
      width: 80px;
      padding-left: 8px;
    }

    td {
      padding: 8px 0 8px 8px;
    }

    td:nth-child(5) {
      max-width: ${props => (props.isMobilePortraitView ? '85px' : 'none')};
    }
  }

  div td {
    vertical-align: ${({ isTabletPortraitView }) => (isTabletPortraitView ? 'top' : 'middle')};
  }

  tbody > div {
    height: ${({ isTabletPortraitView }) => (isTabletPortraitView ? '200px' : '142px')};
  }

  .delimeter {
    margin: ${({ isTabletPortraitView }) => (isTabletPortraitView ? '24px 10px' : '0 10px')};
  }

  .secondary-info-block {
    margin: ${({ isTabletPortraitView }) => (isTabletPortraitView ? '35px 0' : '0')};
  }

  .cost-info-block {
    margin: ${({ isTabletPortraitView }) => (isTabletPortraitView ? '44px 0' : '0')};
  }

  @media (max-width: 1200px) {
    tr td {
      padding: 8px 0;
    }
  }

  table {
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      width: auto;
      align-items: center;
    }
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const VehicleSalesFC = () => {
  const [isListVehiclesOpen, setIsListVehiclesOpen] = useState();
  const dispatch = useDispatch();

  const { page, perPage, filters } = useSelector(state => state.mySales);

  const { data, isFetching: isLoading } = useGetMySalesQuery({
    limit: perPage,
    offset: page,
    ...filters
  });

  const vehicles = useMemo(() => data?.rows?.ids?.map(id => data?.rows?.entities?.[id]) ?? [], [data]);
  const vehiclesCount = useMemo(() => data?.count ?? 0, [data]);

  useFetchActiveOffersQuery(
    { vehicleIds: vehicles?.map(el => el.id) },
    {
      skip: !vehicles || vehicles.length === 0
    }
  );

  useMySalesSocketEffect(data);

  const toggleModal = modalId => dispatch(modalsToggle(modalId));

  const loadMore = () => {
    if (page * perPage > vehiclesCount) return;
    dispatch(setPage(page + 1));
  };

  const { width = 0, height = 0 } = useWindowSize();
  const isTabletPortraitView = width > height && width < 1024;
  const isMobilePortraitView = isTabletPortraitView && width < 800;

  const columnsHead = isTabletPortraitView ? tabletHorizontalColumns : columns;

  const EditIcon = ({ id }) => (
    <EditIconWrapper
      onClick={() => {
        toggleModal(`vehicleEditModal-${id}`);
      }}
    >
      <EditIconSvg />
    </EditIconWrapper>
  );

  const icons = [EditIcon];

  const getRowBackgroundColor = item => {
    if (item.status === 'pulled') {
      return '#B0B0B04D';
    }

    return '#fff';
  };

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <div className="pageWrapper">
        <FilterWrapper>
          <Button onClick={() => setIsListVehiclesOpen(true)} variant="contained" color="primary">
            List Vehicles
          </Button>
          {!isMobile && <Filter />}
          <ListVehiclesDialog isOpen={isListVehiclesOpen} close={() => setIsListVehiclesOpen(false)} />
        </FilterWrapper>
        <StyledTableWrapper isMobilePortraitView={isMobilePortraitView} isTabletPortraitView={isTabletPortraitView}>
          <Table
            icons={icons}
            withMobileView
            columnsHead={columnsHead}
            columnsData={convertVehicles(vehicles)}
            rowsCount={vehiclesCount}
            footerColumns={isTabletPortraitView ? footerColumns : []}
            buttons={undefined}
            buttonPosition="center"
            page={page}
            rowsPerPage={perPage}
            loadMore={loadMore}
            rowElement="div"
            loading={isLoading}
            getRowBackgroundColor={getRowBackgroundColor}
            noDataText={<NotFoundDescription loading={isLoading} />}
          />
        </StyledTableWrapper>
      </div>
    </div>
  );
};

const convertVehicles = rawVehicles => {
  if (!rawVehicles) return [];
  return rawVehicles.map(vehicle => {
    return {
      ...vehicle,
      secondary_info: <VehicleSecondaryInfoBlock currentVehicle={vehicle} />,
      cost_info: <VehicleCostInfoBlock currentVehicle={vehicle} />,
      bid_info: <VehicleBidInfoBlock currentVehicle={vehicle} />,
      details: <VehicleOptionsBlock currentVehicle={vehicle} includeOrderIndex />,
      delimiter: <Delimiter />,
      vehicle_buttons: <ButtonsBlock currentVehicle={vehicle} />,
      modals: <DashboardModals currentVehicle={vehicle} />,
      tablet_footer: <TabletDashboardBlock currentVehicle={vehicle} />
    };
  });
};

export default VehicleSalesFC;
