import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { modalsToggle, resetVinInfo } from '../../../../actions';
import { VinInput } from './components/VinInput';

import FormTextField from '../../../../components/Form/FormTextField';

import { required as validateRequired, vinLength } from '../../../../utils/validators';
import ConfirmModal from '../../../../components/Modals/ReUploadModal';
import { getBackupToken } from '../../../../utils/auth';
import { AuctionTypeComponent } from 'components/VehicleCreation/AuctionTypeComponent/AuctionTypeComponent';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  @media (min-width: 600px) {
    max-width: 50%;
  }
`;

const upper = value => value && value.toUpperCase();

const styles = theme => ({
  formHolder: {
    marginTop: 21,
    marginBottom: 24
  },
  vinField: {
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 120px)'
    }
  },
  spanHolder: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  spanOr: {
    marginLeft: 4,
    marginRight: 16
  },
  loader: {
    alignSelf: 'flex-end'
  }
});

const { navigator } = window;

const isIOSOrAddroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return false;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};

const VinForm = ({
  handleSubmit,
  getVinInfo,
  submitting,
  classes,
  vin,
  vehicles,
  // toggleScanner,
  change,
  resetVinInfoDispatch,
  isAdmin,
  toggleModal,
  isActive = false
}) => {
  const isAdminLoggedIn = getBackupToken();

  return (
    <form id="vin-form" onSubmit={handleSubmit(getVinInfo)} className={classes.formHolder}>
      <div style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap' }}>
        <Field
          name="vin"
          id="vin"
          label="Enter VIN Number"
          component={FormTextField}
          type="text"
          validate={[validateRequired, vinLength]}
          className={classes.vinField}
          normalize={upper}
          onChange={(event, newValue) => {
            if (vin && (isAdmin || isAdminLoggedIn) && vehicles.vinInfo.duplicate) {
              resetVinInfoDispatch();
              return newValue;
            }

            return newValue;
          }}
        />
        {isAdmin && <AuctionTypeComponent disabled={isActive} />}
      </div>
      {submitting && <CircularProgress size={30} className={classes.loader} />}
      {!submitting &&
        ((vin && vehicles.vinInfo.success && (
          <StyledButton variant="contained" color="secondary" type="submit">
            CHANGE
          </StyledButton>
        )) ||
          (vin && vehicles.vinInfo.duplicate && (isAdmin || isAdminLoggedIn) && (
            <StyledButton variant="contained" color="primary" onClick={toggleModal}>
              RE-UPLOAD
            </StyledButton>
          )) || (
            <StyledButton variant="contained" color="primary" type="submit">
              SUBMIT
            </StyledButton>
          ))}
      {!submitting && isIOSOrAddroid() && (
        <Typography component="span" className={classes.spanHolder}>
          <span className={classes.spanOr}>or</span>
          <VinInput change={change} />
        </Typography>
      )}
      <ConfirmModal
        modalId="reupload"
        title={
          vehicles.vinInfo && vehicles.vinInfo.data
            ? `You are about to re-upload the ${vehicles.vinInfo.data.year} ${vehicles.vinInfo.data.make} ${vehicles.vinInfo.data.model},\n
            VIN: ${vehicles.vinInfo.data.vin} \n.
            Click "CONFIRM" to fill up the form with the existing data (excluding images) or "NEW" if you want to start from scratch.
            `
            : ''
        }
        handleSubmit={() => {
          getVinInfo({ vin: vehicles.vinInfo.data.vin, vehicleId: vehicles.vinInfo.data.id });
          toggleModal();
        }}
        newText="New"
        handlePressNew={() => {
          getVinInfo({ vin: vehicles.vinInfo.data.vin, vehicleId: '0' });
          toggleModal();
        }}
      />
    </form>
  );
};

const mapStateToProps = state => ({
  vin: (state.form.vinForm && state.form.vinForm.values && state.form.vinForm.values.vin) || '',
  vinError: state.vehicles.vinInfoError,
  vehicles: state.vehicles,
  loading: state.vehicles.loading
});

const mapDispatchToProps = dispatch => ({
  toggleScanner: () => dispatch(modalsToggle('vinScannerModal')),
  resetVinInfoDispatch: () => dispatch(resetVinInfo()),
  toggleModal: () => dispatch(modalsToggle('reupload'))
});

export default reduxForm({
  form: 'vinForm'
})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(VinForm)));
