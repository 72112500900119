import { SubmissionError } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormCheckbox } from '../../../../components/Form/index';
import {
  OfficePhoneLabel,
  AdditionalFlooringForms,
  StepControl,
  FormWrapper,
  FieldWrapper,
  StepperTitle
} from '../components';
import { composeValidators, maxLength, required, validateDoc } from '../../../../utils/validators';
import { FLOORING_FORM_COMPANIES, REGISTRATION_MAX_STRING_LENGTH, SIGNUP_ACH_METHODS } from '../../../../constants';
import AchMethodPicker from '../components/AchMethodPicker';
import { modalsToggle } from '../../../../actions';
import { Form } from 'react-final-form';
import { useOnboardingMutation } from 'services/api/auth';
import { OutlinedFormFileUpload } from 'components/Form/FinalForm/OutlinedFormFileUpload';
import OutlinedFormTextField from 'components/Form/FinalForm/OutlinedFormTextField';
import arrayMutators from 'final-form-arrays';
import { useHistory } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import OutlinedFormSelectField from 'components/Form/FinalForm/OutlinedFormSelectField';
import { StyledFormControlLabel } from './formControlLabel';
import api from 'services/api';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const renderBasicFields = fields =>
  fields.map(
    (
      { name = '', label = '', placeholder = '', validation = composeValidators(required, maxStringLength), ...rest },
      index
    ) => <OutlinedFormTextField key={index} name={name} placeholder={label} label={label} validate={validation} />
  );

const achFormFields = [
  {
    name: 'ach_form.full_account_name',
    label: 'Full account name*',
    placeholder: 'Full account name'
  },
  {
    name: 'ach_form.bank_account',
    label: 'Bank account #*',
    placeholder: 'Bank account'
  },
  {
    name: 'ach_form.bank_routing',
    label: 'Bank routing #*',
    placeholder: 'Bank routing'
  },
  {
    name: 'ach_form.bank_name',
    label: 'Bank name*',
    placeholder: 'Bank name'
  }
];

const flooringFormFields = [
  {
    name: 'flooring_form.name_act',
    label: 'Name on Acct'
  },
  {
    name: 'flooring_form.act',
    label: 'Acct #'
  },
  {
    name: 'flooring_form.auction_access',
    label: 'Auction Access #',
    validation: maxStringLength
  }
];

const checkBoxes = [
  {
    name: 'purchase_method.cash',
    label: 'Cash'
  },
  {
    name: 'purchase_method.company_check',
    label: 'Company Check'
  },
  {
    name: 'purchase_method.cashiers_check',
    label: "Cashier's check"
  },
  {
    name: 'purchase_method.ach',
    label: 'ACH'
  },
  {
    name: 'purchase_method.flooring',
    label: 'Flooring'
  }
];

const FormError = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #af2930;
  height: 20px;
`;

const renderCheckboxes = (array, disabledNames = []) =>
  array.map(({ label, name }, index) => (
    <StyledFormControlLabel
      control={<Field name={name} component={FormCheckbox} color="primary" disabled={disabledNames.includes(name)} />}
      label={label}
      key={index}
    />
  ));

const StyledDisclosure = styled(Typography)`
  margin-top: 10px;
  font-size: 14px;
  line-height: 16.41px;
  text-align: left;
  color: #757575;
`;

const AchFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const FormTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: #000;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & * span {
    font-size: 14px;
  }
`;

const fifthBuyerStep = ({ prevStep }) => {
  const [onboarding] = useOnboardingMutation();

  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async values => {
    if (!('purchase_method' in values) || Object.values(values.purchase_method).every(v => v === false)) {
      return { [FORM_ERROR]: 'At lease on purchase method should be selected' };
    }

    let ach_voided_check_image;
    if (values.ach_method === 'upload_voided_check') {
      ach_voided_check_image = values.ach_voided_check_image[0];
    }

    const { error } = await onboarding({
      ...values,
      purchase_method: Object.keys(values.purchase_method).filter(key => values.purchase_method[key] === true),
      ach_voided_check_image,
      finished: true
    });

    if (error) {
      console.error('error');
      return;
    }

    localStorage.removeItem('token');
    dispatch(api.util.invalidateTags(['Me']));
    history.push('/success/registration');
  };

  const toggleModal = id => dispatch(modalsToggle(id));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      mutators={{
        ...arrayMutators
      }}
    >
      {props => {
        const paymentMethod = props.values.purchase_method;
        const achMethod = props.values.ach_method;
        const flooringForm = props.values.flooring_form;
        const { push } = props.form.mutators;
        return (
          <form onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle
                title="Purchase Method"
                description="We highly recommend you choosing as many purchase methods as possible - the more methods you choose,
                    the more vehicle options you will have."
              />
              <Grid container direction="column">
                <CheckboxWrapper>{renderCheckboxes(checkBoxes)}</CheckboxWrapper>
                <FormError>{!props.dirtySinceLastSubmit && props.submitError ? props.submitError : ' '}</FormError>
                {paymentMethod && paymentMethod.ach && (
                  <AchFormWrapper>
                    <FormTitle>ACH Form</FormTitle>

                    <AchMethodPicker />

                    {achMethod === SIGNUP_ACH_METHODS.ENTER_ACH_INFORMATION && (
                      <StyledDisclosure>
                        By filling out my (our) banking and flooring information below I (we) have authority to and
                        authorize AutoAxess to Electronically debit my (our) account (and if necessary, electronically
                        credit my (our) account to correct erroneous debits) as follows:
                      </StyledDisclosure>
                    )}

                    {achMethod === SIGNUP_ACH_METHODS.UPLOAD_VOIDED_CHECK && (
                      <StyledDisclosure>
                        By uploading a voided check and entering my flooring information below I (we) have authority to
                        and authorize AutoAxess to Electronically debit my (our) account (and if necessary,
                        electronically credit my (our) account to correct erroneous debits) as follows:
                      </StyledDisclosure>
                    )}

                    {achMethod === SIGNUP_ACH_METHODS.UPLOAD_VOIDED_CHECK && (
                      <div style={{ marginTop: '32px' }}>
                        <OutlinedFormFileUpload
                          label="Voided Check*"
                          name="ach_voided_check_image"
                          validate={composeValidators(required, validateDoc)}
                        />
                      </div>
                    )}

                    {achMethod === SIGNUP_ACH_METHODS.ENTER_ACH_INFORMATION && (
                      <OutlinedFormSelectField
                        name="ach_form.account_type"
                        validate={required}
                        options={[
                          {
                            label: 'Checking',
                            value: 'checking'
                          },
                          {
                            label: 'Savings',
                            value: 'savings'
                          }
                        ]}
                        label={'Select Account Type*'}
                        placeholder={'Account Type'}
                      />
                    )}

                    {achMethod === SIGNUP_ACH_METHODS.ENTER_ACH_INFORMATION && renderBasicFields(achFormFields)}
                  </AchFormWrapper>
                )}
                {paymentMethod && paymentMethod.flooring && (
                  <AchFormWrapper>
                    <FormTitle>Flooring Form</FormTitle>
                    <div style={{ height: '32px' }} />
                    <OutlinedFormSelectField
                      name="flooring_form.flooring_company"
                      validate={required}
                      label={'Select Flooring Company*'}
                      placeholder={'Select Flooring Company'}
                      options={FLOORING_FORM_COMPANIES.map(c => ({ value: c.value, label: c.name }))}
                    />
                    {renderBasicFields(flooringFormFields)}
                    <AdditionalFlooringForms
                      pushMutator={push}
                      auction_access={flooringForm && flooringForm.auction_access ? flooringForm.auction_access : ''}
                      name_act={flooringForm && flooringForm.name_act ? flooringForm.name_act : ''}
                    />
                  </AchFormWrapper>
                )}
                {/* {error && <FormError msg={error} />} */}
                {paymentMethod && (paymentMethod.ach || paymentMethod.flooring) && (
                  <FieldWrapper>
                    <StyledDisclosure>
                      I (we) understand that this authorization will remain in full force and effect until I (we) notify
                      AutoAxess in writing or by phone that I (we) wish to revoke this authorization. I (we) understand
                      that AutoAxess requires at least 30 days notice to cancel this authorization.
                    </StyledDisclosure>
                  </FieldWrapper>
                )}
                <FieldWrapper>
                  <StyledDisclosure>
                    By clicking on the Finish button, you agree to our{' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#terms" onClick={() => toggleModal('termsAndConditionsModal')}>
                      Terms & Conditions
                    </a>
                    .
                  </StyledDisclosure>
                </FieldWrapper>
              </Grid>
              <StepControl prevStep={prevStep} nextStep={true} nextLabelText="Finish" />
            </FormWrapper>
            <OfficePhoneLabel />
          </form>
        );
      }}
    </Form>
  );
};

export default fifthBuyerStep;
