import { Form } from 'react-final-form';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { IncreaseAmount } from '../../../../../components/IncreaseAmount';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../../../actions';
import { useNoSelectorVehicleOfferAuctionById, useOfferAuctionBidsInfo } from '../../../../../hooks';
import { usePlaceOfferBidMutation } from '../../../../../services/api/offer-auction';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { AUCTION_STATUSES, BUYER_VEHICLE_STATUS } from '../../../../../constants';

export const IncreaseMaxOffer = () => {
  const vehicle = useViewedVehicle();
  const user = useCurrentUser();
  const [placeOffer] = usePlaceOfferBidMutation();
  const dispatch = useDispatch();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const { minBidOfferAmount: initialAmount } = useOfferAuctionBidsInfo(offerAuction);

  if (!vehicle) return null;
  if (user.role !== 'buyer') return null;

  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  const canPlaceOffer =
    [BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER, BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS].includes(
      status
    ) && vehicle.auction?.status === AUCTION_STATUSES.ACTIVE;

  if (!canPlaceOffer) {
    return null;
  }

  const onSubmit = async (values: { amount: string }) => {
    const response = await placeOffer({
      vehicle_id: vehicle.id,
      amount: Number(values.amount)
    });

    if ('data' in response) {
      dispatch(snackShow({ message: 'Offer successfully placed' }));
    } else {
      dispatch(
        snackShow({ type: 'error', message: (response.error as any)?.data?.message || 'Failed to place offer' })
      );
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={{ amount: initialAmount }} name="increase-max-offer">
      {props => (
        <IncreaseAmount
          isProxyAmount={vehicle.proxy_bid?.user_id === user.id}
          type="offer"
          initialAmount={initialAmount}
        />
      )}
    </Form>
  );
};
