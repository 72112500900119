import { useSortable } from '@dnd-kit/sortable';
import { Icon } from '@material-ui/core';
import styled from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import { useGetSectionConfigQuery } from '../../../../services/api/vehicle-sections';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { SectionWithCollapsibleBlocks } from '../generic/SectionWithCollapsibleBlocks';
import { SectionWithCheckboxes } from '../generic/SectionWithCheckboxes';
import { useState } from 'react';
import { useDndContext } from '@dnd-kit/core';
import { TiresAndWheelsSection } from '../TiresAndWheels';
import { InspectorComments } from '../InspectorComments';
import { DragIndicator } from '../../icons/DragIndicator';
import { ExpandIcon } from '../../../../components/Icons/ExpandIcon';
import { CollapseIcon } from '../../../../components/Icons/CollapseIcon';

type Props = {
  sectionId: keyof SectionConfig | 'dealer_comments';
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  flex-grow: 1;
  line-height: 23.44px;
  padding-left: 8px;
  padding-top: 12px;
  color: #000;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const MoveIconWithTooltip = ({ sectionId }: Props) => (
  <StyledIcon id={`activator-${sectionId}`}>
    <DragIndicator />
  </StyledIcon>
);

export const MovableSection = ({ sectionId }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: sectionId });
  const { data: config } = useGetSectionConfigQuery();

  const isPlainSection = sectionId === 'dealer_comments';

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => setIsCollapsed(c => !c);

  const dndContext = useDndContext();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  if (!isPlainSection && (!config || !config[sectionId])) {
    return null;
  }

  const label = isPlainSection ? 'Inspector Comments' : config![sectionId].label;

  if (isCollapsed || dndContext.active) {
    return (
      <Wrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Header>
          <Title>{label}</Title>
          <MoveIconWithTooltip sectionId={sectionId} />
          <StyledIcon onClick={toggleCollapse}>{<ExpandIcon />}</StyledIcon>
        </Header>
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Header>
        <Title>{label}</Title>
        <StyledIcon id={`activator-${sectionId}`}>
          <DragIndicator />
        </StyledIcon>
        <StyledIcon onClick={toggleCollapse}>{isCollapsed ? <ExpandIcon /> : <CollapseIcon />}</StyledIcon>
      </Header>
      <RenderSection sectionId={sectionId} />
    </Wrapper>
  );
};

const RenderSection = ({ sectionId }: { sectionId: keyof SectionConfig | 'dealer_comments' }) => {
  if (sectionId === 'dealer_comments') {
    return <InspectorComments />;
  }

  if (sectionId === 'tires_and_wheels') {
    return <TiresAndWheelsSection />;
  }

  if (['title_issues', 'power_equipment'].includes(sectionId)) {
    return <SectionWithCheckboxes sectionId={sectionId} />;
  }

  return <SectionWithCollapsibleBlocks sectionId={sectionId} />;
};
