import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import { listVehicles, getHomeFilters } from '../../actions';
import {
  LIST_VEHICLES_ADMIN_REQUEST,
  FILTER_VEHICLES_ADMIN_REQUEST,
  LIST_VEHICLES_SALES_HISTORY_REQUEST,
  FILTER_VEHICLE_SALES_HISTORY_REQUEST,
  listVehicleSalesHistory,
  listVehiclesAdmin,
  filterVehiclesAdmin,
  filterVehicleSalesHistory,
  vehiclesSetPage,
  filterSellerVehicles
} from '../../actions/vehicles';
import {
  LIST_VEHICLES_REQUEST,
  VEHICLES_HOME_FILTERS_REQUEST,
  FILTER_SELLER_VEHICLES_REQUEST
} from '../../constants/actionTypes';
import { Api } from '../../utils';

function* fetchVehicles({ payload }) {
  try {
    if (payload.params && payload.params.offset === 0) {
      yield put(vehiclesSetPage(1));
    }
    const { data } = yield call(Api.vehicles.fetchVehicles, payload);
    yield put(listVehicles(data).success);
  } catch (e) {
    yield put(listVehicles(e).failure);
  }
}

function* filterVehiclesSellerSaga({ payload }) {
  try {
    const query = yield select(getSellerVehiclesQuery);

    const { data } = yield call(Api.vehicles.fetchSellerVehicles, {
      ...payload,
      params: { ...query, ...payload.params }
    });

    yield put(filterSellerVehicles(data).success);
  } catch (e) {
    yield put(filterSellerVehicles(e).failure);
  }
}

const getAuctionStatus = state => state.vehicles.auctionStatus;

function* getCurrentFiler(auctionState) {
  if (auctionState === 'active') {
    return yield select(state => getFormValues('vehicleSalesActiveFilterForm')(state));
  }
  if (auctionState === 'pending') {
    return yield select(state => getFormValues('vehicleSalesPendingFilterForm')(state));
  }
  if (auctionState === 'ended') {
    return yield select(state => getFormValues('vehicleSalesHistoryFilterForm')(state));
  }
  return {};
}

const getVehicleSalesHistoryQuery = state => getFormValues('vehicleSalesHistoryFilterForm')(state);

function* fetchVehicleSalesHistory({ payload }) {
  try {
    const query = yield select(getVehicleSalesHistoryQuery);
    const { data } = yield call(Api.vehicles.fetchVehicleSalesHistory, {
      ...payload,
      params: { ...query, ...(payload || {}).params }
    });
    yield put(listVehicleSalesHistory(data).success);
  } catch (e) {
    console.warn(e);
    yield put(listVehicleSalesHistory(e).failure);
  }
}

function* filterVehicleSalesHistorySaga({ payload }) {
  try {
    const query = yield select(getVehicleSalesHistoryQuery);
    const { data } = yield call(Api.vehicles.fetchVehicleSalesHistory, {
      ...payload,
      params: { ...query, ...(payload || {}).params }
    });
    yield put(filterVehicleSalesHistory(data).success);
  } catch (e) {
    console.warn(e);
    yield put(filterVehicleSalesHistory(e).failure);
  }
}

const getVehiclesAdminQuery = state => getFormValues('vehiclesAdminFilterForm')(state);

function* fetchVehiclesAdmin({ payload }) {
  try {
    const query = yield select(getVehiclesAdminQuery);

    const { data } = yield call(Api.vehicles.fetchVehiclesAdmin, {
      ...payload,
      params: { ...query, ...payload.params }
    });

    yield put(listVehiclesAdmin(data).success);
  } catch (e) {
    yield put(listVehiclesAdmin(e).failure);
  }
}

function* filterVehiclesAdminSaga({ payload }) {
  try {
    const query = yield select(getVehiclesAdminQuery);

    const { data } = yield call(Api.vehicles.fetchVehiclesAdmin, {
      ...payload,
      params: { ...query, ...payload.params }
    });

    yield put(filterVehiclesAdmin(data).success);
  } catch (e) {
    yield put(filterVehiclesAdmin(e).failure);
  }
}

const getSellerVehiclesQuery = state => getFormValues('sellerVehiclesFilterForm')(state);

function* requestHomeFilters({ payload }) {
  try {
    const { data } = yield call(Api.vehicles.getHomeFilters, payload);
    yield put(getHomeFilters(data).success);
  } catch (e) {
    payload.reject(e);
    yield put(getHomeFilters(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_VEHICLES_REQUEST, fetchVehicles),
    takeLatest(FILTER_SELLER_VEHICLES_REQUEST, filterVehiclesSellerSaga),
    takeLatest(FILTER_VEHICLE_SALES_HISTORY_REQUEST, filterVehicleSalesHistorySaga),
    takeLatest(LIST_VEHICLES_ADMIN_REQUEST, fetchVehiclesAdmin),
    takeLatest(FILTER_VEHICLES_ADMIN_REQUEST, filterVehiclesAdminSaga),
    takeLatest(LIST_VEHICLES_SALES_HISTORY_REQUEST, fetchVehicleSalesHistory),
    takeLatest(VEHICLES_HOME_FILTERS_REQUEST, requestHomeFilters)
  ]);
}
