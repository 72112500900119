import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { VEHICLE_TABS_TYPES, AUCTION_STATUSES } from '../../../../constants';
import { useGetMySalesCountersQuery } from 'services/api/sales';

const NotFoundDescription = ({ loading }) => {
  const { data, loading: countersLoading } = useGetMySalesCountersQuery();
  const auction = useSelector(state => state.auctions.current);
  const dataLoaded = !loading && !countersLoading;

  if (!dataLoaded) return null;

  let text = null;
  const { [VEHICLE_TABS_TYPES.ACTIVE]: inProgressBids, [VEHICLE_TABS_TYPES.PENDING]: pendingBids } = data ?? {
    active: 0,
    pending: 0
  };
  const isAuctionActive = auction && auction.status === AUCTION_STATUSES.ACTIVE;
  const isAuctionEnded = auction && auction.status === AUCTION_STATUSES.ENDED;
  const isInProgressBids = Boolean(inProgressBids);
  const isPendingBids = Boolean(pendingBids);

  const noBidsActiveAuction = !isInProgressBids && !isPendingBids && isAuctionActive;
  const noBidsEndedAuction = !isInProgressBids && !isPendingBids && isAuctionEnded;

  if (noBidsActiveAuction) {
    text = (
      <>
        You haven’t listed any vehicles on this auction.
        <br />
        Please visit your <Link to="/seller/vehicles">manage vehicles</Link> page to upload vehicles and come back here
        to manage the sales.
        <br />
        For help, please contact your AutoAxess rep or call us at <a href="tel:9253154033">925-315-4033</a> to have an
        inspector sent out.
      </>
    );
  } else if (noBidsEndedAuction) {
    text = (
      <>
        The auction has ended.
        <br />
        When our next auction begins, please visit your <Link to="/seller/vehicles">manage vehicles</Link> page to
        upload new vehicles.
        <br />
        For help, please contact your AutoAxess rep or call us at <a href="tel:9253154033">925-315-4033</a> to have an
        inspector sent out.
      </>
    );
  }

  return text;
};

export default compose(withRouter)(NotFoundDescription);
