import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, FormControlLabel, FormLabel, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormCheckbox, FormError } from '../../../../components/Form/index';
import { OfficePhoneLabel, StepControl, FormWrapper, StepperTitle, FieldWrapper } from '../components';
import scrollToError from '../../../../utils/scrollToError';
import { modalsToggle } from '../../../../actions';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useOnboardingMutation } from 'services/api/auth';
import { useHistory } from 'react-router-dom';

const renderCheckboxes = array =>
  array.map(({ label, name }, index) => (
    <FormControlLabel
      control={<Field name={name} component={FormCheckbox} color="primary" />}
      label={label}
      key={index}
    />
  ));

const checkBoxes = [
  {
    name: 'purchase_option.cash',
    label: 'Cash'
  },
  {
    name: 'purchase_option.company_check',
    label: 'Company Check'
  },
  {
    name: 'purchase_option.cashiers_check',
    label: "Cashier's check"
  },
  {
    name: 'purchase_option.ach_flooring',
    label: 'ACH/Flooring'
  }
];

const GridWithPadding = styled(Grid)`
  padding: 12px;
`;

const ThirdSellerStep = ({ prevStep }) => {
  const [onboarding] = useOnboardingMutation();
  const history = useHistory();

  const onSubmit = async values => {
    console.log(values);

    const isPurchaseOptionChosen = Object.values(values.purchase_option || {}).filter(i => i).length;
    if (!isPurchaseOptionChosen) {
      return { [FORM_ERROR]: 'At least one purchase option should be chosen' };
    }

    const { error } = await onboarding({
      ...values,
      purchase_option: Object.keys(values.purchase_option).filter(key => values.purchase_option[key] === true),
      finished: true
    });

    if (error) {
      console.error('error');
      return;
    }

    history.push('/success/registration');
  };

  const dispatch = useDispatch();
  const toggleModal = key => dispatch(modalsToggle(key));

  return (
    <Form onSubmit={onSubmit} initialValues={{ purchase_option: { ach_flooring: true } }}>
      {props => {
        return (
          <form onSubmit={props.handleSubmit}>
            <FormWrapper>
              <StepperTitle
                title="Purchase Method"
                description="We highly recommend you choosing as many purchase methods as possible - the more methods you choose, the more vehicle options you will have."
              />
              <Grid container>
                <Grid container>
                  <Grid container direction="column">
                    {renderCheckboxes(checkBoxes)}
                  </Grid>
                </Grid>
                {!props.dirtySinceLastSubmit && props.submitError && <FormError msg={props.submitError} />}
                <FieldWrapper>
                  <Typography>
                    By clicking on the Finish button, you agree to our{' '}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#terms" onClick={() => toggleModal('termsAndConditionsModal')}>
                      Terms & Conditions
                    </a>
                    .
                  </Typography>
                </FieldWrapper>
              </Grid>
              <StepControl prevStep={prevStep} nextStep={true} nextLabelText="Finish" />
            </FormWrapper>
            <OfficePhoneLabel />
          </form>
        );
      }}
    </Form>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleModal: key => dispatch(modalsToggle(key))
});

// export default reduxForm({
//   form: 'registrationForm',
//   destroyOnUnmount: false,
//   fields: [
//     'purchase_option',
//     'street',
//     'city',
//     'state',
//     'zip',
//     'region',
//     'phone_number',
//     'store_number',
//     'secondary_contact_name',
//     'secondary_contact_phone',
//     'dealer_license_number',
//     'dealer_license_expiry_date',
//     'dealership_name',
//     // 'username',
//     'first_name',
//     'last_name',
//     'email',
//     'password',
//     'confirm_password',
//     'role',
//     'referrer'
//   ],
//   onSubmitFail: scrollToError
// })(connect(null, mapDispatchToProps)());

export default ThirdSellerStep;
