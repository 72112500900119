export const UpArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11 20V7.825L5.4 13.425L4 12L12 4L20 12L18.6 13.425L13 7.825V20H11Z" fill="#757575" />
  </svg>
);

export const DownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M13 4L13 16.175L18.6 10.575L20 12L12 20L4 12L5.4 10.575L11 16.175L11 4L13 4Z" fill="#757575" />
  </svg>
);
