import React from 'react';
import { Paper, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

const SectionTitle = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
`;

const StyledSection = styled(Paper)`
  max-width: 640px;
  border-radius: 0;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  border: 1px solid #dcdcdc;
  display: flex;
  min-height: 70px;
  align-items: center;
  padding: 16px 72px;
  button:last-child {
    margin-left: 10px;
  }
  @media (max-width: 680px) {
    padding: 15px;
  }
`;

const ContentWrapper = styled.div`
  padding: 32px 72px 48px 72px;
  @media (max-width: 680px) {
    padding: 15px;
  }
  ${props =>
    props.isEditable
      ? `
    input:disabled {
      color: rgba(0, 0, 0, 0.12)!important;
    }
  `
      : `
    input + svg {
      display: none;
    }
  `}
`;

const SectionBlock = ({
  canBeEdited = true,
  editButtonLabel = 'Edit',
  title,
  children,
  toggleEdit,
  submit,
  onCancel,
  isEditable,
  showControlButtons = true
}) => (
  <StyledSection>
    <TitleWrapper>
      <SectionTitle>{title}</SectionTitle>
      <span className="flex-grow" />
      {canBeEdited && showControlButtons &&
        (isEditable ? (
          <>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={submit}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="contained" color="secondary" onClick={toggleEdit}>
            {editButtonLabel}
          </Button>
        ))}
    </TitleWrapper>
    <ContentWrapper isEditable={isEditable}>{children}</ContentWrapper>
  </StyledSection>
);

export default SectionBlock;
