import {
  LIST_REGIONS_REQUEST,
  LIST_REGIONS_SUCCESS,
  LIST_REGIONS_FAILURE
} from '../constants/actionTypes';

const initialState = {
  loading: true,
  submiting: false,
  itemsData: {
    rows: []
  },
  error: '',
  success: false
};

const regions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_REGIONS_REQUEST:
      return { ...state, loading: true };
    case LIST_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemsData: {
          rows: payload.data.rows
        }
      };
    case LIST_REGIONS_FAILURE:
      return {
        ...state,
        loading: false,
        submitting: false,
        itemsData: (payload && payload.data) || {}
      };
    default:
      return state;
  }
};

export default regions;
