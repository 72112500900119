import React from 'react';
import { Checkbox } from '@material-ui/core';

const CheckboxAdapter = ({
  input: { onChange, value, ...inputProps },
  meta,
  onChange: ignoredOnChange,
  defaultChecked,
  ...props
}) => (
  <Checkbox
    {...inputProps}
    {...props}
    // eslint-disable-next-line no-unneeded-ternary
    checked={value ? true : false}
    onChange={(event, isInputChecked) => {
      onChange(isInputChecked);
    }}
  />
);

export default CheckboxAdapter;
