import axios from 'axios';
import { removeToken, removeUser, getToken } from './auth';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Cache-Control'] = 'no-cache';

const API_URL = import.meta.env.VITE_API_URL;

console.log(API_URL, 'api_url 2');

export const convertToFormData = (obj, form, fieldNameSpace) => {
  const formData = form || new FormData();
  let formKey;

  Object.keys(obj).forEach(property => {
    if (fieldNameSpace) {
      formKey = `${fieldNameSpace}[${property}]`;
    } else {
      formKey = property;
    }
    // if the property is an object, but not a File,
    // map recursively.
    if (typeof obj[property] === 'object' && obj[property] !== null && !(obj[property] instanceof File)) {
      convertToFormData(obj[property], formData, formKey);
    } else {
      // if it's a string or a File object
      formData.append(formKey, obj[property]);
    }
  });
  return formData;
};

const mainInstance = axios.create({
  baseURL: API_URL
});

const makeRequest = instance => (method, url, ...params) => {
  const token = getToken();
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  return instance[method](url, ...params);
};
const request = (method, url) => (...params) =>
  makeRequest(mainInstance)(method, url, ...params).catch(e => {
    if (e.response.status === 403 || e.response.status === 401) {
      removeUser();
      removeToken();
      window.location = '/login';
    }
    throw e;
  });

export const vehicles = {
  fetchVehicles: (...params) => request('get', '/vehicles')(...params),
  fetchBuyerDocs: (id, ...params) => request('get', `/vehicles/${id}/buyer-docs`)(...params),
  fetchVehiclesAdmin: (...params) => request('get', '/admin/vehicles')(...params),
  fetchVehicleSales: (...params) => request('get', '/seller/vehicles/sales')(...params),
  fetchVehicleSalesHistory: (...params) => request('get', '/seller/vehicles/sales-history')(...params),
  fetchSellerVehicles: (...params) => request('get', '/seller/vehicles')(...params),
  fetchVehicleDetails: vehicleId => request('get', `/vehicles/${vehicleId}`)(),
  getVinInfo: (vin, ...params) => request('get', `/vin_info/${vin}`)({ params: params[0] }),
  getCarEstimate: (...data) => request('post', '/car_estimate')(...data),
  getDeliveryEstimate: vehicleId => request('get', `/vehicles/${vehicleId}/delivery-estimate`)(),
  createVehicle: (...params) =>
    axios.post(`${API_URL}/vehicles`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  createManualVehicle: (...params) =>
    axios.post(`${API_URL}/vehicles/manual-upload`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  deleteVehicle: id => request('delete', `/vehicles/${id}`)(),
  updateAdminNotes: params => request('post', '/vehicles/adminNotes')(params),
  updateVehicle: (id, params, queryParams) =>
    axios.put(`${API_URL}/vehicles/${id}`, convertToFormData(params), {
      'Content-Type': 'multipart/form-data',
      params: queryParams
    }),
  awardBuyer: (vehicleId, data) => request('post', `/vehicles/${vehicleId}/award`)(data),
  unawardVehicle: vehicleId => request('post', `/vehicles/${vehicleId}/unaward`)(),
  pullVehicle: vehicleId => request('post', `/vehicles/${vehicleId}/pull`)(),
  relistVehicle: (vehicleId, ...params) => request('post', `/vehicles/${vehicleId}/relist`)(...params),
  deactivateVehicle: vehicleId => request('post', `/vehicles/${vehicleId}/deactivate`)(),
  createBid: (...params) => request('post', '/bids')(...params),
  acceptBid: vehicleId => request('post', `/vehicles/${vehicleId}/accept-bid`)(),
  approveBid: (...params) => request('post', `/vehicles/${params[0].vehicle_id}/approve-bid`)(...params),
  sellerSetCounter: (vehicleId, data) => request('post', `/vehicles/${vehicleId}/seller-set-counter`)(data),
  adminSetCounter: (vehicleId, data) => request('post', `/vehicles/${vehicleId}/admin-set-counter`)(data),
  buyerAcceptCounter: vehicleId => request('post', `/vehicles/${vehicleId}/buyer-accept-counter`)(),
  getSuggestions: vehicleId => request('post', `/vehicles/${vehicleId}/suggestions`)(),
  getTimeSuggestions: (vehicleId, ...params) => request('get', `/vehicles/${vehicleId}/time-suggestions`)(...params),
  getAnotherActiveVehicles: vehicleId => request('get', `/vehicles/${vehicleId}/another-active-vehicles`)(),
  getHomeFilters: (...params) => request('get', '/home-filters')(...params),
  autocheckReport: vin => request('post', `/autocheck/${vin}/get-report`)(),
  uploadVehicleImage: (...params) =>
    axios.post(`${API_URL}/vehicles/images`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  purchaseVehicleUsingEasyPay: (vehicleId, data) => request('post', `/vehicles/${vehicleId}/easy-pay`)(data)
};

const locations = {
  fetchLocations: (...params) => request('get', '/locations')(...params),
  fetchLocation: (id, ...params) => request('get', `/locations/${id}`)(...params),
  createLocation: (...params) => request('post', '/locations')(...params),
  updateLocation: (id, ...params) => request('put', `/locations/${id}`)(...params)
};

const auctions = {
  fetchAuctions: (...params) => request('get', '/auctions')(...params),
  fetchAuctionById: id => request('get', `/auctions/${id}`)(),
  fetchBiddersByAuctionId: (id, ...params) => request('get', `/auctions/${id}/bidders`)(...params),
  createAuction: (...params) => request('post', '/auctions')(...params),
  fetchCurrentAuction: (...params) => request('get', '/current-auction')(...params),
  fetchNextAuction: (...params) => request('get', '/next-auction')(...params),
  fetchAuctionVehicles: (id, ...params) => request('get', `/auctions/${id}/vehicles`)({ params: params[0] }),
  fetchReportSeller: (id, ...params) => request('get', `/auctions/${id}/report-seller`)({ params: params[0] }),
  fetchReportBuyer: (id, ...params) => request('get', `/auctions/${id}/report-buyer`)({ params: params[0] })
};

export const user = {
  createUser: (...params) =>
    axios.post(`${API_URL}/auth/register`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  continueOnboarding: (...params) => {
    const token = getToken();
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return axios.post(`${API_URL}/auth/onboarding`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    });
  },
  forgotPassword: (...params) => request('post', '/auth/forgot-password')(...params),
  resetPassword: (...params) => request('post', '/auth/restore-password')(...params),
  validateForm: (...params) => request('post', '/auth/validate')(...params),
  activateAccount: (...params) => request('post', '/auth/activate')(...params),
  fetchUsers: (...params) => request('get', '/users')(...params),
  fetchUser: userId => request('get', `/users/${userId}`)(),
  updateUserDoc: (...params) => request('post', '/admin/docs')(...params),
  updateUserDocFile: (...params) =>
    axios.post(`${API_URL}/admin/docs/file`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  deleteDoc: (id, ...params) => request('delete', `/admin/docs/${id}`)(...params),
  fetchProfile: (...params) => request('get', '/profile')(...params),
  updateProfile: (...params) => request('patch', '/profile')(...params),
  updateProfileDoc: (...params) =>
    axios.post(`${API_URL}/profile/doc`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  sendContactForm: (...payload) => request('post', '/auth/contact-form-message')(...payload),
  sendQuestionForm: (...payload) => request('post', '/auth/contact-question')(...payload)
};

export const financials = {
  fethcEasyPayTransactionList: (...params) => request('get', '/buyer/easy-pay')(...params),
  fetchAwardedVehiclesList: (...params) => request('get', '/buyer/awarded-vehicles')(...params),
  updateEasyPayTransaction: params => request('patch', `/vehicles/${params.id}/easy-pay`)(params),
  updateTransaction: params => request('patch', `/financials/transactions/${params.id}/void`)(params),
  fetchFundingNotice: id => request('get', `/vehicles/${id}/funding-notice`)(),
  fetchSellersInvoices: (...params) => request('get', '/financials/sellers-invoices')(...params),
  fetchTransactions: (...params) => request('get', '/financials/transactions')(...params),
  fetchSingleInvoice: (seller, date, ...params) =>
    request('get', `/financials/sellers-invoices/${seller}/${date}`)(...params),
  fetchSingleInvoiceByUploadedCars: (seller, date, ...params) =>
    request('get', `/financials/sellers-invoices/${seller}/${date}/uploaded-cars`)(...params),
  fetchSellerPastInvoices: (seller, ...params) =>
    request(
      'get',
      `/financials/sellers-invoices/${seller}`
    )({
      params: params[0]
    }),
  createInvoiceRecord: (...params) => request('post', '/financials/sellers-invoices')(...params),
  fetchTotalInvoices: (...params) => request('get', '/financials/total-invoices')(...params),
  fetchBuyerFee: (...params) => request('get', '/financials/buyer-fee')({ params: { ...params[0] } }),
  fetchBuyerFees: (...params) => request('get', '/financials/buyer-fees')(...params),
  fetchAuctionSummaryReport: (...params) =>
    request('get', '/admin/auction-summary-report')({ params: { ...params[0] } }),
  fetchListerSummaryReport: (...params) => request('get', '/admin/lister-summary-report')({ params: { ...params[0] } }),
  updateBuyerFees: (...params) => request('post', '/financials/buyer-fees')(...params),
  generateSellerInvoicePdf: (...params) => request('post', '/financials/sellers-invoices/generate-pdf')(...params),
  fetchCurrentSellerInvoices: (...params) => request('get', '/seller/invoices')(...params),
  getDeclinedTransactions: (...params) => request('get', '/financials/declined-transactions')(...params),
  deleteDeclinedTransactions: id => request('delete', `/financials/declined-transactions/${id}`)(),
  retryTransaction: (...params) => request('post', '/financials/retry-fee-charging')(...params),
  chargeAdditionalFees: (...params) => request('post', '/financials/additional-fee-charging')(...params)
};

export const users = {
  fetchCustomers: (...params) => request('get', '/admin/customers')(...params),
  fetchAccounts: (...params) => request('get', '/admin/accounts')(...params),
  fetchAccountLogs: (...params) => request('get', '/admin/account-logs')(...params),
  fetchAdminSellerSellers: (...params) => request('get', '/admin/seller-admin-sellers')(...params),
  listOfCustomerEmails: (...params) => request('get', '/admin/customers/generate/list-of-emails')(...params),
  fetchCustomer: id => request('get', `/admin/customers/${id}`)(),
  fetchReferrers: (...params) => request('get', `/referrers`)(...params),
  loginAsUser: id => request('get', `/admin/customers/${id}/login`)(),
  updateCustomer: (id, ...params) => request('patch', `/admin/customers/${id}`)(...params),
  createAccount: (...params) =>
    request('post', `/admin/accounts`)(convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  updateAccount: (id, ...params) =>
    request('patch', `/admin/accounts/${id}`)(convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  deleteAccount: (id, ...params) => request('delete', `/admin/accounts/${id}`)(...params),
  deleteUser: (id, ...params) => request('delete', `/admin/customers/${id}`)(...params)
};

export const bids = {
  fetchBids: (...params) => request('get', '/buyer/bids')(...params),
  fetchBidsCounters: (...params) => request('get', '/buyer/bids-counters')(...params),
  editBid: (id, ...params) => request('put', `/bids/${id}`)(...params),
  editProxyBid: (id, ...params) => request('put', `/proxy-bids/${id}`)(...params),
  deleteBid: id => request('delete', `/bids/${id}`)(),
  deleteProxyBid: id => request('delete', `/proxy-bids/${id}`)()
};

export const banners = {
  fetchBanners: (...params) => request('get', '/admin/banners')(...params),
  fetchActiveBanners: (...params) => request('get', '/banners')(...params),
  deleteBanner: id => request('delete', `/admin/banners/${id}`)(),
  createBanner: (...params) =>
    axios.post(`${API_URL}/admin/banners`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    }),
  editBanner: (id, ...params) =>
    axios.patch(`${API_URL}/admin/banners/${id}`, convertToFormData(...params), {
      'Content-Type': 'multipart/form-data'
    })
};

export const statistics = {
  fetchStatistics: (...params) => request('get', '/admin/statistics')(...params),
  fetchStatistic: (id, ...params) => request('get', `/admin/statistics/${id}`)(...params)
};

const regions = {
  fetchRegions: (...params) => request('get', '/regions')(...params)
};

const logs = {
  fetchLogs: (...params) => request('get', '/admin/logs')(...params),
  fetchLogsCount: (...params) => request('get', '/admin/logs/count')(...params)
};

export default {
  vehicles,
  locations,
  auctions,
  user,
  bids,
  financials,
  users,
  regions,
  banners,
  statistics,
  logs
};
