import { CompletedStepperIcon, StepperIndicatorIcon } from '../../../../components/Icons/StepperIndicator';
import styled from 'styled-components';

const getSteps = role => {
  if (!role) {
    return ['User Info'];
  } else if (role === 'buyer') {
    return ['User Info', 'Buyer Info', 'Credit Card Info', 'Docs', 'Purchase Met.'];
  }
  return ['User Info', 'Seller Info', 'Purchase Met.'];
};

const ConnectorLine = styled.div`
  width: 69px;
  height: 2px;
  background: ${props => (props.isActive ? '#3994de' : '#E0E0E7')};
`;

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

const ControlsRow = styled.div`
  display: flex;
  align-items: center;
`;

const StepperIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const minWidthByLabelIndex = {
  buyer: { 0: 42, 1: 46, 2: 70, 3: 24, 4: 65 },
  seller: { 0: 42, 1: 46, 2: 70 }
};

const Label = styled.span`
  font-size: 10px;
  font-weight: 400;
  position: absolute;
  top: 20px;
  min-width: ${props => `${props.minWidth ?? 0}px`};
  left: ${props => (props.moveLeft ? '4px' : 'auto')};
  right: ${props => (props.moveRight ? '4px' : 'right')};
  line-height: 18px;
  color: ${props => (props.isActive ? '#3994de' : '#898f8f')};
`;

const CustomRegistrationStepper = ({ role, activeStep }) => {
  const steps = getSteps(role);
  return (
    <StepperWrapper>
      <ControlsRow>
        {steps.map((step, i) => {
          const isCompleted = activeStep > i;
          const isActive = i === activeStep;
          return (
            <>
              <StepperIndicatorWrapper>
                {isCompleted ? <CompletedStepperIcon /> : <StepperIndicatorIcon isActive={isActive} />}
                <Label
                  isActive={isCompleted || isActive}
                  moveLeft={role === 'buyer' && i === 0}
                  moveRight={role === 'buyer' && i === steps.length - 1}
                  minWidth={minWidthByLabelIndex[role]?.[i]}
                >
                  {step}
                </Label>
              </StepperIndicatorWrapper>
              {i !== steps.length - 1 && <ConnectorLine isActive={isCompleted} />}
            </>
          );
        })}
      </ControlsRow>
    </StepperWrapper>
  );
};

export default CustomRegistrationStepper;
