import { call, put, takeLatest, all } from 'redux-saga/effects';
import { stopSubmit, reset } from 'redux-form';
import {
  listBanners,
  listActiveBanners,
  addBanner,
  editBanner,
  removeBanner,
  CREATE_BANNERS_REQUEST,
  LIST_BANNERS_REQUEST,
  CHANGE_BANNERS_REQUEST,
  DELETE_BANNERS_REQUEST,
  LIST_ACTIVE_BANNERS_REQUEST
} from '../actions/banners';
import { modalsToggle } from '../actions';
import { Api } from '../utils';

function* fetchBanners({ payload = {} }) {
  try {
    const { data } = yield call(Api.banners.fetchBanners, payload);
    yield put(listBanners(data).success);
  } catch (e) {
    yield put(listBanners(e).failure);
  }
}

function* createBanner({ payload }) {
  try {
    const { data } = yield call(Api.banners.createBanner, payload);
    yield put(reset('createBannerForm'));
    yield put(modalsToggle('newBannerModal'));
    yield put(addBanner(data).success);
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield put(stopSubmit('createBannerForm', validationErrors));
    }
    yield put(addBanner(e).failure);
  }
}

function* deleteBanner({ payload }) {
  try {
    const { data } = yield call(Api.banners.deleteBanner, payload.id);
    yield put(modalsToggle('deleteBannerModal'));
    yield put(removeBanner(data).success);
  } catch (e) {
    yield put(removeBanner(e).failure);
  }
}

function* changeBanner({ payload }) {
  try {
    const { data } = yield call(Api.banners.editBanner, payload.id, payload);
    yield put(reset('editBannerForm'));
    yield put(modalsToggle('editBannerModal'));
    yield put(editBanner(data).success);
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield put(stopSubmit('editBannerForm', validationErrors));
    }
    yield put(editBanner(e).failure);
  }
}

function* fetchHomePageBanners({ payload }) {
  try {
    const { data } = yield call(Api.banners.fetchActiveBanners, payload);
    yield put(listActiveBanners(data).success);
  } catch (e) {
    yield put(listActiveBanners(e).failure);
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_BANNERS_REQUEST, fetchBanners),
    takeLatest(LIST_ACTIVE_BANNERS_REQUEST, fetchHomePageBanners),
    takeLatest(CREATE_BANNERS_REQUEST, createBanner),
    takeLatest(DELETE_BANNERS_REQUEST, deleteBanner),
    takeLatest(CHANGE_BANNERS_REQUEST, changeBanner)
  ]);
}
