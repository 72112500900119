import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type MySalesState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
}

const initialState: MySalesState = {
  page: 1,
  perPage: 24,
  filters: {
    auctionStatus: 'active'
  }
};

const mySalesSlice = createSlice({
  name: 'mySales',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      setFilters(state, action: PayloadAction<Record<string, any>>) {
        state.filters = action.payload
      },
      setStatusFilter(state, action: PayloadAction<string>) {
        if (action.payload === '') { 
          delete state.filters.status
        } else {
          state.filters.status = action.payload
        }
        
        state.page = 1
      },
      setSearchFilter(state, action: PayloadAction<string>) { 
        if (action.payload === '') { 
          delete state.filters.search
        } else {
          state.filters.search = action.payload
        }
        
        state.page = 1
      },
      resetFilters(state) {
        state.page = 1
        state.perPage = 24
        state.filters = {
          auctionStatus: 'active'
        }
      }
    }
});

export const { setPage, setFilters, setStatusFilter, resetFilters, setSearchFilter } = mySalesSlice.actions
export default mySalesSlice;
