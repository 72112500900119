import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const StyledPageWrapper = styled(Paper)`
  width: 800px;
  height: 1035px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 80px 120px 80px;
  box-sizing: border-box;
  border: none;
`;

const StyledBackground = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
`;


const DocumentLayout = ({ children, style = {} }) => (
  <StyledBackground>
    <StyledPageWrapper style={style}>
      {children}
    </StyledPageWrapper>
  </StyledBackground>
);

export default DocumentLayout;

