import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'redux-form';
import FormTextField from '../../../../../components/Form/FormTextField';
import FormSelect from '../../../../../components/Form/FormSelect';
import { required as validateRequired, validateMinBid, validateReservePrice } from '../../../../../utils/validators';
import FormFormattedField from '../../../../../components/Form/FormFormattedField';
import { normalizeFormattedField } from '../../../../../utils';
import { roles } from '../../../../../constants';
import moment from 'moment'

const VehicleInformation = ({ colors, trim, engines, transmissions, drive_trains, trims }) => {
  const vehicleDateEnd = useSelector(state => state?.vehicles?.selectedVehicle?.data?.date_end);
  const vehicleBids = useSelector(state => state?.vehicles?.selectedVehicle?.data?.bids);
  const vehicleReservedPriceMet = useSelector(state => state?.vehicles?.selectedVehicle?.data?.reserve_price_met);
  const currentTime = moment.utc();
  const isVehicleTimerEnded = vehicleDateEnd && moment.utc(vehicleDateEnd).isBefore(currentTime);
  const userRole = useSelector(state => state?.user?.user?.role);

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Field
          className="text-field"
          name="year"
          label="Year"
          component={FormTextField}
          type="text"
          fullWidth
          margin="normal"
          validate={validateRequired}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          className="text-field"
          name="make"
          label="Make"
          component={FormTextField}
          type="text"
          fullWidth
          margin="normal"
          validate={validateRequired}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          className="text-field"
          name="model"
          label="Model"
          component={FormTextField}
          type="text"
          fullWidth
          margin="normal"
          validate={validateRequired}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field
          className="text-field"
          name="trim"
          label="Trim"
          component={FormSelect}
          type="text"
          fullWidth
          margin="normal"
          validate={validateRequired}
        >
          {trims.map((option, i) => (
            <MenuItem value={option.trim} key={i}>
              {option.trim}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      {trim && (
        <>
          <Grid item xs={12} sm={6}>
            <Field
              className="text-field"
              name="engine"
              label="Engine"
              component={FormSelect}
              type="text"
              fullWidth
              margin="normal"
              validate={validateRequired}
            >
              {engines.map((option, i) => (
                <MenuItem value={option.optionName} key={i}>
                  {option.optionName}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              className="text-field"
              name="transmission"
              label="Transmission"
              component={FormSelect}
              type="text"
              fullWidth
              margin="normal"
              validate={validateRequired}
            >
              {transmissions.map((option, i) => (
                <MenuItem value={option.optionName} key={i}>
                  {option.optionName}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              className="text-field"
              name="drive_train"
              label="Drive Train"
              component={FormSelect}
              type="text"
              fullWidth
              margin="normal"
              validate={validateRequired}
            >
              {drive_trains.map((option, i) => (
                <MenuItem value={option.optionName} key={i}>
                  {option.optionName}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              className="text-field"
              name="ext_color"
              label="Ext. color"
              component={FormSelect}
              fullWidth
              margin="normal"
              validate={validateRequired}
            >
              {colors.map((color, i) => (
                <MenuItem value={color.optionName} key={i}>
                  {color.optionName}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              className="text-field"
              name="starting_bid"
              label="Starting Bid"
              type="text"
              component={FormFormattedField}
              decimalScale={0}
              thousandSeparator
              prefix="$"
              fullWidth
              allowNegative={false}
              margin="normal"
              validate={validateMinBid}
              normalize={normalizeFormattedField}
              disabled={vehicleBids && vehicleBids.length > 0}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              className="text-field"
              name="reserve_price"
              label="Reserve price"
              type="text"
              component={FormFormattedField}
              decimalScale={0}
              allowNegative={false}
              prefix="$"
              thousandSeparator
              fullWidth
              normalize={normalizeFormattedField}
              margin="normal"
              disabled={(vehicleReservedPriceMet || isVehicleTimerEnded) && userRole === roles.SELLER}
              validate={validateReservePrice}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default connect(state => ({
  trims: state.vehicles.vinInfoTrims,
  engines: state.vehicles.vinInfo.data.engines,
  transmissions: state.vehicles.vinInfo.data.transmissions,
  drive_trains: state.vehicles.vinInfo.data.drive_trains,
  colors: state.vehicles.vinInfo.data.colors
}))(VehicleInformation);
