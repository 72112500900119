import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";

export const StyledFormControlLabel = styled(FormControlLabel)`
  & > span:last-child {
    font-size: 12px;
    line-height: 14.06px;
    color: #939393;
    margin-left: -6px;
  }
`;
