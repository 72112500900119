import React from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ModalWrapper } from '../index';
import { FormFormattedField } from '../Form';
import { sellerSetCounter } from '../../actions/vehicles';
import { required } from '../../utils/validators';

const StyledFormWrapper = styled.form`
  min-width: 300px;
`;

const minAmount = value =>
  value.replace(/[\D]/g, '') > 0
    ? undefined
    : 'Min amount cannot be less than 0';

const StyledModalTitle = styled(Typography)`
  && {
    margin-top: 10px;
    font-weight: 500;
  }
`;

const StyledFieldWrapper = styled.div`
  margin-top: 15px;
`;

const SetCounterModal = ({
  vehicle,
  highestBid,
  handleSubmit,
  reset,
  submitting
}) => (
  <ModalWrapper
    title="Counter High Bid"
    submitting={submitting}
    handleSubmit={handleSubmit}
    modalId="setCounterModal"
    cancelAdditionalAction={reset}
  >
    <StyledFormWrapper onSubmit={handleSubmit}>
      <StyledModalTitle>{`${vehicle.year} ${vehicle.make} ${
        vehicle.model
      }`}</StyledModalTitle>
      <Typography>
        VIN:
        {` ${vehicle.vin}`}
      </Typography>
      <Typography>
        {`Value Without Miles Adj: `}
        <NumberFormat
          value={vehicle.bluebook_value}
          prefix="$"
          thousandSeparator
          displayType="text"
        />
      </Typography>
      <Typography>
        {` Blue Book® Lending Value: `}
        <NumberFormat
          value={vehicle.bluebook_mileage}
          prefix="$"
          thousandSeparator
          displayType="text"
        />
      </Typography>
      <Typography>
        {` Mileage: `}
        <NumberFormat
          value={vehicle.mileage}
          thousandSeparator
          displayType="text"
        />
      </Typography>
      {highestBid && (
        <>
          <StyledModalTitle>High Bid</StyledModalTitle>
          <Typography>{`Name: ${
            highestBid.bidder.dealership_name
          }`}</Typography>
          <Typography>
            Amount:{' '}
            <NumberFormat
              value={highestBid.amount}
              prefix="$"
              thousandSeparator
              displayType="text"
            />
          </Typography>
        </>
      )}
      <StyledFieldWrapper>
        <Field
          name="amount"
          placeholder="Enter Counter Amount"
          label="Enter Counter Amount"
          component={FormFormattedField}
          thousandSeparator
          allowNegative={false}
          fixedDecimalScale
          prefix="$"
          disableUnderline
          validate={[required, minAmount]}
          fullWidth
          hideErrors
        />
      </StyledFieldWrapper>
    </StyledFormWrapper>
  </ModalWrapper>
);

export default reduxForm({
  form: 'setCounterForm',
  onSubmit: (values, dispatch) => {
    dispatch(
      sellerSetCounter({
        ...values,
        amount: values.amount.replace(/[\D]/g, '')
      }).request
    );
  },
  enableReinitialize: true
})(SetCounterModal);
