import { Divider } from '@material-ui/core';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  && {
    background: #dcdcdc;
  }
`;

export default StyledDivider;
