import { useDispatch } from 'react-redux';
import {
  useUpdateRequestPreferableDateMutation,
  useAddRequestToPoolMutation
} from '../../../services/api/list-vehicles';
import {
  ActiveItem,
  clearDraggableItem,
  useGetDraggableItem,
  useSelectedWeekType
} from '../../../services/listVehiclesSlice/listVehiclesSlice';
import api from 'services/api/list-vehicles';
import { DragEndEvent } from '@dnd-kit/core';
import { Dispatch } from 'redux';
import { UpdateCb, useUpdatePoolQueryData } from './useUpdatePoolsQuery';

export const useHandleDragEnd = () => {
  const [updatePreferableDate] = useUpdateRequestPreferableDateMutation();
  const [addRequestToPool] = useAddRequestToPoolMutation();
  const updatePoolQueryCache = useUpdatePoolQueryData();
  const activeItem = useGetDraggableItem();
  const dispatch = useDispatch();
  const selectedWeekType = useSelectedWeekType();

  return (e: DragEndEvent) => {
    if (!activeItem) {
      return;
    }
    const currItem = { ...activeItem };
    dispatch(clearDraggableItem());

    if (!e.over || typeof e.over.id !== 'string' || currItem.dayNumber == null) {
      return;
    }

    const [dropZoneType, dropZoneId] = e.over.id.split('-');
    if (dropZoneType === 'dayBlock') {
      handleDayBlockDrop(dispatch, Number(dropZoneId), currItem, updatePreferableDate, selectedWeekType);
    }
    if (dropZoneType === 'idleRow') {
      handleIdleRowDrop(
        dispatch,
        updatePoolQueryCache,
        Number(dropZoneId),
        currItem,
        addRequestToPool,
        selectedWeekType
      );
    }
    if (dropZoneType === 'draftRow') {
      handleIdleRowDrop(
        dispatch,
        updatePoolQueryCache,
        Number(dropZoneId),
        currItem,
        addRequestToPool,
        selectedWeekType
      );
    }
  };
};

const handleDayBlockDrop = (
  dispatch: Dispatch<any>,
  dropZoneId: number,
  activeItem: ActiveItem,
  updatePreferableDate: any,
  selectedWeekType: string
) => {
  if (dropZoneId === activeItem.dayNumber) {
    return;
  }

  dispatch(
    api.util.updateQueryData('getRequestsByDay', selectedWeekType, draft => {
      const previousGroup = draft[activeItem.dayNumber!];
      const newGroup = draft[dropZoneId];
      const previousItemIndex = previousGroup.findIndex(el => el.id === activeItem.id);
      if (previousItemIndex === -1) {
        return;
      }
      previousGroup.splice(previousItemIndex, 1);
      draft[activeItem.dayNumber!] = previousGroup;
      newGroup.push(activeItem);
    })
  );

  updatePreferableDate({ requestId: activeItem.id as number, dayNumber: dropZoneId });
};

const handleIdleRowDrop = (
  dispatch: Dispatch<any>,
  updatePoolQueryCache: (cb: UpdateCb) => void,
  dropZoneId: number,
  activeItem: ActiveItem,
  addRequestToPool: any,
  selectedWeekType: string,
) => {
  updatePoolQueryCache(draft => {
    const row = draft.find(el => el.id === dropZoneId);
    if (!row) return;
    row.listRequests.push({ ...activeItem, list_vehicle_pool_id: dropZoneId });
  });

  removeFromDayBlock(dispatch, activeItem, selectedWeekType);

  addRequestToPool({ poolId: dropZoneId, requestId: activeItem.id });
};

const removeFromDayBlock = (dispatch: Dispatch, activeItem: ActiveItem, selectedWeekType: string) => {
  dispatch(
    // @ts-ignore
    api.util.updateQueryData('getRequestsByDay', selectedWeekType, draft => {
      const previousGroup = draft[activeItem.dayNumber!];
      const previousItemIndex = previousGroup.findIndex(el => el.id === activeItem.id);
      if (previousItemIndex === -1) {
        return;
      }
      previousGroup.splice(previousItemIndex, 1);
    })
  );
};
