import React from 'react';
import styled from 'styled-components';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { MenuItem } from '@material-ui/core';
import { FormResetButton, FormSelect } from '../../../../components/Form';
import { listCurrentSellerInvoices } from '../../../../actions';
import { MONTHS } from '../../../../constants';

const StyledFilterWrapper = styled.form`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  div {
    padding-right: 16px;
    div {
      @media (max-width: 768px) {
        min-width: initial;
      }
      min-width: 240px;
    }
    @media (max-width: 768px) {
      flex-grow: 1;
    }
  }
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
  div:last-child {
    padding-right: 0;
  }
  @media (max-width: 600px) {
    padding: 0 12px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    > div {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
`;

const onChange = (values, dispatch) =>
  dispatch(listCurrentSellerInvoices({ params: { ...values } }).request);

const Filter = ({ dirty, reset, years }) => (
  <StyledFilterWrapper>
    <Field component={FormSelect} id="month" name="month" label="Month">
      <MenuItem value="">All</MenuItem>
      {MONTHS.map((e, i) => (
        <MenuItem key={i} value={i}>
          {e}
        </MenuItem>
      ))}
    </Field>
    <Field component={FormSelect} id="year" name="year" label="Year">
      <MenuItem value="">All</MenuItem>
      {years.map((e, i) => (
        <MenuItem key={i} value={e}>
          {e}
        </MenuItem>
      ))}
    </Field>
    {dirty && <FormResetButton onClick={reset} />}
  </StyledFilterWrapper>
);

const mapStateToProps = state => ({
  years: state.financials.invoices.rows.reduce((arr, item) => {
    const year = moment(item.date).format('YYYY');
    if (!arr.includes(year)) {
      arr.push(year);
    }
    return arr;
  }, [])
});

export default compose(
  reduxForm({
    form: 'invoicesFilterForm',
    onChange
  }),
  connect(mapStateToProps)
)(Filter);
