import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import HeaderTitle from '../components/HeaderTitle';
import { VEHICLE_STATUSES, AUCTION_STATUSES, SELLER_VEHICLE_STATUS } from '../../../../../constants';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { useSelector } from 'react-redux';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { getHighestBid } from '../../../../../utils/vehicle';

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
`;

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
    span {
      color: ${props => props.statusColor || '#000'};
    }
  }
`;

const statusColor = {
  deactivated: '#D50808',
  pulled: '#D50808',
  undecided: '#E47E00',
  pending_approval: '#E47E00',
  need_seller_review: '#E47E00',
  pending_admin_approval: '#E47E00',
  bid_accepted: '#E47E00',
  counter_sent_to_buyers: '#E47E00',
  buyer_counter_sent: '#E47E00',
  awarded: '#06A000',
  pending: '#E47E00',
  pending_autoaxess_review: '#E47E00',
  public_counter_sent_to_buyers: '#E47E00',
  accepted_offer_pending_admin_approval: '#E47E00'
};

const statusText = {
  pulled: 'Pulled',
  undecided: 'Undecided',
  awarded: 'Awarded',
  pending: 'Pending',
  pending_approval: 'Pending Approval',
  need_seller_review: 'Needs Seller Review',
  pending_admin_approval: 'Pending Admin Approval',
  bid_accepted: 'Pending AutoAxess Review',
  deactivated: 'Deactivated',
  counter_sent_to_buyers: 'Counter Sent to Buyer',
  public_counter_sent_to_buyers: 'Counter Sent to Buyers',
  accepted_offer_pending_admin_approval: 'Pending Admin Approval',
  pending_autoaxess_review: 'Pending AutoAxess Review',
  buyer_counter_sent: 'Counter Sent to Seller'
};

const statusTextWhenEnded = {
  need_seller_review: 'Processing',
  bid_accepted: 'Undecided',
  undecided: 'Undecided',
  pulled: 'Pulled',
  awarded: 'Awarded',
  deactivated: 'Deactivated',
  pending: 'Pending'
};

const StatusHeader = ({ vehicle }) => {
  const user = useSelector(state => state.user?.user);
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);

  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  const currentBid = getHighestBid(vehicle);
  const auctionEnded = vehicle.auction.status === AUCTION_STATUSES.ENDED;

  const headerTitle = (() => {
    if (status === VEHICLE_STATUSES.AWARDED) {
      return 'Amount —';
    }
    if (
      status === SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT ||
      status === SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS
    ) {
      return 'Countered Amount —';
    }
    return 'Highest Bid —';
  })();

  const headerAmount = (() => {
    if (status === VEHICLE_STATUSES.AWARDED) {
      return vehicle.vehicle_price;
    }
    if (
      status === SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS ||
      status === SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT
    ) {
      return offerAuction?.privateOffers?.find(el => el.status === 'sent')?.amount ?? 0;
    }
    return currentBid.amount;
  })();

  return (
    <StyledHeaderWrapper>
      <HeaderTitle
        titleJSX={
          <StyledHeaderTitle>
            {headerTitle} <NumberFormat displayType="text" prefix="$" value={headerAmount} thousandSeparator />
          </StyledHeaderTitle>
        }
        subTitleJSX={
          <StyledHeaderSubTitle statusColor={statusColor[status]}>
            Bid Status - <span>{!auctionEnded ? statusText[status] : statusTextWhenEnded[status]}</span>
          </StyledHeaderSubTitle>
        }
      />
    </StyledHeaderWrapper>
  );
};

export default StatusHeader;
