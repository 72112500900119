import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PageWrapper from "../PageWrapper";
import "./index.scss";
import facebook from '../../img/facebook.svg';
import linkedin from '../../img/linkedin.svg';

const currentYear = moment().year();

const Footer = ({noMargin}) => (
  <div className={`footerHolder ${noMargin ? "noMargin" : ""}`}>
    <PageWrapper>
      <div className="footerInner">
        <p className="copyright">
          Copyright ©
          {` ${currentYear} `}
          AutoAxess
        </p>
        <div className="contentHolder">
          <div className="footerLinksHolder">
            <Link className="footerLink" to="/about-us">
              About Us
            </Link>
            <Link className="footerLink" to="/faqs">
              FAQ
            </Link>
            <Link className="footerLink" to="/contact-us">
              Contact Us
            </Link>
          </div>
          <div className="footerLinksHolder">
            <Link className="footerLink" to="/terms-of-use">
              Terms & Conditions
            </Link>
            <Link className="footerLink" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="socialContainer">
            <a
              href="https://www.facebook.com/autoaxess/"
              rel="noopener noreferrer"
              target="_blank"
              className="socialItem"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/autoaxess/"
              rel="noopener noreferrer"
              target="_blank"
              className="socialItem"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </PageWrapper>
  </div>
);

export default Footer;
