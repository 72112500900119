import { Vehicle } from '../../../../types/vehicle';
import NumberFormat from 'react-number-format';
import { useAdminOfferAuction } from '../hooks';
import { useOfferAuctionBidsInfo } from '../../../../hooks';

type Props = {
  vehicle: Vehicle;
};

export const AdminOffers = ({ vehicle }: Props) => {
  const offerAuction = useAdminOfferAuction(vehicle.id);
  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction)
  return (
   <span style={{ fontWeight: 500 }}><NumberFormat displayType="text" prefix="$" value={highestBidOffer?.amount ?? 0} thousandSeparator /></span> 
  );
};
