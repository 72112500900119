import { OfferAuction } from '../types/vehicle';

export const usePrivateOfferAmount = (offerAuction?: OfferAuction | null) => {
  const adminOffer = offerAuction?.privateOffers?.find(offer => offer.is_admin);
  const sellerOffer = offerAuction?.privateOffers?.find(offer => offer.sender.role === 'seller' && !offer.is_admin);
  const buyerOffer = offerAuction?.privateOffers?.find(offer => offer.sender.role === 'buyer' && !offer.is_admin);
  const adminOrSellerOffer = offerAuction?.privateOffers?.find(offer => offer.sender.role !== 'buyer' && offer.type === 'private');

  return {
    adminAmount: adminOffer?.amount ?? 0,
    sellerAmount: sellerOffer?.amount ?? 0,
    buyerAmount: buyerOffer?.amount ?? 0,
    adminOrSellerAmount: adminOrSellerOffer?.amount ?? 0
  };
};
