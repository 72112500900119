import React, {Component} from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PageWrapper } from "../../../components";

import FreeIcon from '../../../img/benefits/free.svg'
import WalletIcon from '../../../img/benefits/wallet.svg'
import LowPriceIcon from '../../../img/benefits/low-price.svg'
import CategoryIcon from '../../../img/benefits/category.svg'
import CarIcon from '../../../img/benefits/car.svg'
import PhoneIcon from '../../../img/benefits/phone.svg'
import WorldIcon from '../../../img/benefits/world.svg'
import CallingIcon from '../../../img/benefits/calling.svg'
import ShieldDoneIcon from '../../../img/benefits/shield-done.svg'
import buyer from '../../../img/benefits/2.png'
import seller from '../../../img/benefits/1.png'

const buyerBenefitsDataLeft = [
  {
    title: 'Free registration',
    description: 'No registration fees. No cost to bid on unlimited cars each week.',
    iconUrl: FreeIcon
  },
  {
    title: 'Easy Pay',
    description: 'Complete your ACH/Flooring payment with few clicks, go pick up your car immediately!',
    iconUrl: WalletIcon
  },
  {
    title: 'Lowest buy fees in the market',
    description: 'Fees are a fraction of other car auctions. Absolutely NO hidden fees!',
    iconUrl: LowPriceIcon
  },
]

const buyerBenefitsDataRight = [
  {
    title: 'Car variety & transparency',
    description: 'We have a wide variety of franchised dealer sellers state-wide, and the most accurate descriptions by seasoned vehicle inspectors.',
    iconUrl: CategoryIcon
  },
  {
    title: 'Choose your transportation',
    description: 'Transport you cars any way you desire; we don\'t force you to use our transport.',
    iconUrl: CarIcon
  },
  {
    title: 'Bid from any device',
    description: 'Bid and buy from anywhere, on any device.  No more driving to regional auctions. Our superior technology assists you in finding and buying the cars you need.',
    iconUrl: PhoneIcon
  },
]

const sellerBenefitsDataLeft = [
  {
    title: 'Free registration',
    description: 'No registration fees. No charge to list unlimited vehicles in our weekly auctions.',
    iconUrl: FreeIcon
  },
  {
    title: 'Lowest sell fees ever',
    description: 'Fees are a fraction of other car auctions. Absolutely NO hidden fees!',
    iconUrl: LowPriceIcon
  },
  {
    title: 'Reach buyers from different states',
    description: 'AutoAxess’ auctions are open to all buyers no matter where they are located.',
    iconUrl: WorldIcon
  },
]

const sellerBenefitsDataRight = [
  {
    title: 'AutoAxess support',
    description: 'We send seasoned vehicle inspectors to your dealership each week to inspect and upload your wholesale vehicles for you, all at no charge.  After the auction, our expert negotiators go in to action to maximize your sales.',
    iconUrl: CallingIcon
  },
  {
    title: 'Your funding is guaranteed',
    description: 'AutoAxess ensures your funding on any of your sales.',
    iconUrl: ShieldDoneIcon
  },
  {
    title: 'Sell from any device',
    description: 'The technology that allows to follow the auction in our platform from your home or office and manage your sales.',
    iconUrl: PhoneIcon
  },
]

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    boxShadow: 'none'
  },
  tabsRoot: {
    borderBottom: '1px solid #DBDBDB',
    backgroundColor: '#fff',
    boxShadow: 'none'
  },
  tabsIndicator: {
    backgroundColor: '#3994DE',
    borderRadius: 10,
  },
  tabRoot: {
    descriptionTransform: 'initial',
    width: '50%',
    maxWidth: 200,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    fontFamily: 'Roboto',
    color: '#424141',
    fontSize: 20,
    opacity: 1,
    '&:hover': {
      color: '#3994DE',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#3994DE',
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:focus': {
      color: '#3994DE',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});

const BenefitsSectionHolder = styled.section`
  padding: 60px 0 52px;
  
  @media only screen and (min-width: 1026px) {
    padding: 95px 0;
  }
`;

const TabContainer = styled.div`
  padding-top: 48px;

  @media only screen and (min-width: 1026px) {
    display: flex;
    align-items: center;
  }
`;

const BenefitsList = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;

  @media only screen and (min-width: 1026px) {
    display: flex;
    flex-direction: column;
  }
`;

const BenefitItemHolder = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 36px -20px;

  @media only screen and (min-width: 1026px) {
    flex: 1;
    position: relative;
    
    &.left {
      justify-content: flex-end;
      margin: 0 0 50px 0;
      text-align: right;
      
      &:nth-child(2) {
        margin-right: 50px;
      }
    }

    &.right {
      margin: 0 0 50px 0;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    &.left {
      margin: 0 -20px 50px 0;
    }

    &.right {
      margin: 0 0 50px -20px;
    }
  }
`;

const BenefitIconHolder = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  
  img {
    width: 18px;
    height: 18px;
  }

  @media only screen and (min-width: 1400px) {
    width: 90px;
    height: 90px;

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

const BenefitInfoHolder = styled.div`
  @media only screen and (min-width: 1026px) {
    position: absolute;

    &.left {
      right: 100px;
      width: 270px;
    }

    &.right {
      left: 100px;
      width: 310px;
    }
  }

  @media only screen and (min-width: 1400px) {
    &.left {
      right: 130px;
    }

    &.right {
      left: 130px;
    }
  }
`;

const BenefitTitle = styled.h3`
  margin: 0 0 10px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
`;

const BenefitDescription = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: #54607D;
`;

const ImageHolder = styled.div`
  display: none;

  @media only screen and (min-width: 1400px) {
    display: block;
    margin: 0 55px;
    
    img {
      display: block;
      width: 317px;
    }
  }
`;

const BenefitItem = ({ data, position =  'left'}) => ( // position: 'left' | 'right'
  <BenefitItemHolder className={position}>
    <BenefitIconHolder>
      <img src={data.iconUrl} alt={data.title} />
    </BenefitIconHolder>
    <BenefitInfoHolder className={position}>
      <BenefitTitle>{data.title}</BenefitTitle>
      <BenefitDescription>{data.description}</BenefitDescription>
    </BenefitInfoHolder>
  </BenefitItemHolder>
)

class BenefitsSection extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <BenefitsSectionHolder>
        <h2 className="section-title">Buyer/Seller Benefits</h2>
        <Tabs
          centered
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            label="Buyer"
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label="Seller"
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
        </Tabs>
        <PageWrapper>
          {value === 0 && (
            <TabContainer>
              <BenefitsList>
                {
                  buyerBenefitsDataLeft.map(item => (
                    <BenefitItem data={item} key={item.title} />
                  ))
                }
              </BenefitsList>
              <ImageHolder>
                <img src={buyer} alt="buyer" />
              </ImageHolder>
              <BenefitsList>
                {
                  buyerBenefitsDataRight.map(item => (
                    <BenefitItem data={item} position="right" key={item.title} />
                  ))
                }
              </BenefitsList>
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <BenefitsList>
                {
                  sellerBenefitsDataLeft.map(item => (
                    <BenefitItem data={item} key={item.title} />
                  ))
                }
              </BenefitsList>
              <ImageHolder>
                <img src={seller} alt="seller" />
              </ImageHolder>
              <BenefitsList>
                {
                  sellerBenefitsDataRight.map(item => (
                    <BenefitItem data={item} position="right" key={item.title} />
                  ))
                }
              </BenefitsList>
            </TabContainer>
          )}
        </PageWrapper>
      </BenefitsSectionHolder>
    );
  }
}

export default withStyles(styles)(BenefitsSection);
