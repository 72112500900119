import { GradeColor } from '../../../../services/api/vehicle-sections/types/common';

const LightRedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13108)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13108">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13110)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13110">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const YellowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13112)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13112">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const GreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13114)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13114">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const BlueIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13116)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13116">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DarkBlueIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13118)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13118">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DarkGreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
    <g clip-path="url(#clip0_7854_13120)">
      <path
        d="M3.975 5.175L3.16875 4.36875C3.1 4.3 3.0125 4.26562 2.90625 4.26562C2.8 4.26562 2.7125 4.3 2.64375 4.36875C2.575 4.4375 2.54062 4.525 2.54062 4.63125C2.54062 4.7375 2.575 4.825 2.64375 4.89375L3.7125 5.9625C3.7875 6.0375 3.875 6.075 3.975 6.075C4.075 6.075 4.1625 6.0375 4.2375 5.9625L6.35625 3.84375C6.425 3.775 6.45937 3.6875 6.45937 3.58125C6.45937 3.475 6.425 3.3875 6.35625 3.31875C6.2875 3.25 6.2 3.21562 6.09375 3.21562C5.9875 3.21562 5.9 3.25 5.83125 3.31875L3.975 5.175ZM4.5 8.25C3.98125 8.25 3.49375 8.15156 3.0375 7.95469C2.58125 7.75781 2.18438 7.49062 1.84687 7.15312C1.50938 6.81562 1.24219 6.41875 1.04531 5.9625C0.848438 5.50625 0.75 5.01875 0.75 4.5C0.75 3.98125 0.848438 3.49375 1.04531 3.0375C1.24219 2.58125 1.50938 2.18438 1.84687 1.84687C2.18438 1.50938 2.58125 1.24219 3.0375 1.04531C3.49375 0.848438 3.98125 0.75 4.5 0.75C5.01875 0.75 5.50625 0.848438 5.9625 1.04531C6.41875 1.24219 6.81562 1.50938 7.15312 1.84687C7.49062 2.18438 7.75781 2.58125 7.95469 3.0375C8.15156 3.49375 8.25 3.98125 8.25 4.5C8.25 5.01875 8.15156 5.50625 7.95469 5.9625C7.75781 6.41875 7.49062 6.81562 7.15312 7.15312C6.81562 7.49062 6.41875 7.75781 5.9625 7.95469C5.50625 8.15156 5.01875 8.25 4.5 8.25Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7854_13120">
        <rect width="9" height="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const iconMap: Record<GradeColor, () => JSX.Element> = {
  red: RedIcon,
  yellow: YellowIcon,
  green: GreenIcon,
  blue: BlueIcon,
  dark_blue: DarkBlueIcon,
  light_red: LightRedIcon,
  dark_green: DarkGreenIcon
};
