import { ActiveOfferInfo } from '../../../../../components/Offers';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { useCurrentUser } from '../../../../../hooks/selectors';
import StatusComponent from '../../../../Shared/VehicleDetails/RoleRestrictedSection/BuyerRestrictionSection/StatusComponent';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { BuyerActiveOffers } from './Buyer';

export const ActiveOffers = () => {
  const vehicle = useViewedVehicle();
  const currentUser = useCurrentUser();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  if (!vehicle || !offerAuction) return null;

  if (currentUser.role === 'buyer') {
    return <BuyerActiveOffers vehicle={vehicle} />;
  }

  return null;
};
