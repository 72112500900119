import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  position: relative;
`;

const RowLine = styled.hr`
  margin: 0 -24px 0 -24px;
  height: 1px;
  border: none;
  background-color: rgba(0, 0, 0, 0.12);
`;

const Header = styled.h2`
  margin: 0;
  margin-bottom: 24px;
  font-size: 1.3125rem;
  font-weight: 500;
`;

type Props = {
  children: React.ReactNode;
  title: string;
};

export const RegularSectionContainer = ({ children, title }: Props) => {
  return (
    <Wrapper>
      <Header>{title}</Header>
      <RowLine />
      {children}
    </Wrapper>
  );
};
