import { Form, Field } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import { ModalWrapper } from '../../../../components';
import { FormTextAreaField } from '../../../../components/Form';
import { maxLength } from '../../../../utils/validators';
import { listVehicles, snackShow, updateVehicle } from '../../../../actions';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import './index.scss';
import { InfoIcon } from '../../../../components/Icons';
import { modalsToggle } from '../../../../actions/modals';
import apiSlice, { useUpdateVehicleNotesMutation } from '../../../../services/api';
import { setActiveVehicleId } from '../../../../services/sidePanel/sidePanel';
import { useDispatch } from 'react-redux';

const notesMaxLen = maxLength(50);

const StyledTextAreaField = styled(Field)`
  background: #f5f5f5;
  width: 100%;
  display: flex;
  padding-left: 8px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
`;

const StyledLabel = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
`;

const StyledHelperText = styled(Typography)`
  color: #757575;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 12px;
`;

const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-end;
`;

/**
 * @deprecated should be replaced with EditNotesModal
 */
const MyBidEditModal = ({ useRtk, form: modalName, handleSubmit, reset, initialValues }: any) => {
  const dispatch = useDispatch();
  const [updateVehicleNotes] = useUpdateVehicleNotesMutation();
  const onSubmit = (values: any) => {
    if (useRtk) {
      updateVehicleNotes({ id: values.id, notes: values.notes });
      return;
    }

    dispatch(
      updateVehicle({
        ...values,
        notes: values.notes ?? '',
        updateText: 'Vehicle updated',
        redirect: false,
        refetchMyBids: true,
        refetchLiveAuction: true
      }).request
    );
  };

  return (
    <Form name={modalName} onSubmit={onSubmit} style={{ maxWidth: '100%' }} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => (
        <ModalWrapper
          // @ts-ignore
          title="Add Notes"
          submitting={submitting}
          handleSubmit={handleSubmit}
          modalId={modalName}
          cancelAdditionalAction={reset}
          className="edit-vehicle-bid-modal"
          actionsClassName="edit-vehicle-bid-modal-actions"
        >
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <StyledLabel>Notes</StyledLabel>
                  <HelperTextContainer>
                    <StyledHelperText>Max. 50 characters</StyledHelperText>
                    <InfoIcon />
                  </HelperTextContainer>
                </Grid>
                <StyledTextAreaField
                  name="notes"
                  // @ts-ignore
                  component={FormTextAreaField}
                  disableUnderline
                  fullWidth
                  validate={notesMaxLen}
                  rows={4}
                />
              </Grid>
            </Grid>
        </ModalWrapper>
      )}
    </Form>
  );
};

export default MyBidEditModal;
