/* eslint-disable */
import {
  LIST_REGIONS_REQUEST,
  LIST_REGIONS_SUCCESS,
  LIST_REGIONS_FAILURE
} from '../constants/actionTypes';

export const listRegions = data => ({
  request: { type: LIST_REGIONS_REQUEST, payload: data },
  success: { type: LIST_REGIONS_SUCCESS, payload: data },
  failure: { type: LIST_REGIONS_FAILURE, payload: data }
});
