import React from 'react';
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles
} from '@material-ui/core';

const styles = {
  expanded: {
    borderBottom: '1px solid #dcdcdc',
    margin: 0
  },
  content: {
    margin: '24px 0',
    paddingRight: '20px'
  },
  summaryRoot: {
    paddingRight: 0,
    paddingLeft: 0
  },
  detailsRoot: {
    paddingRight: 0,
    paddingLeft: 0
  }
};

const ExpansionPanel = ({
  name,
  question,
  expanded,
  handleChange,
  children,
  classes
}) => (
  <MuiExpansionPanel
    elevation={0}
    classes={{ expanded: classes.expanded }}
    expanded={expanded === name}
    onChange={handleChange(name)}
  >
    <ExpansionPanelSummary
      classes={{ content: classes.content, root: classes.summaryRoot }}
      expandIcon={<i className="material-icons">expand_more</i>}
    >
      <Typography variant="display1">{question}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
      <Typography variant="body1">{children}</Typography>
    </ExpansionPanelDetails>
  </MuiExpansionPanel>
);

export default withStyles(styles)(ExpansionPanel);
