import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import HeaderTitle from '../components/HeaderTitle';

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
    span {
      color: #e47e00;
    }
  }
`;

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  > div {
    margin-right: 24px;
  }
`;

const statusText = {
  pending_admin_approval: 'Pending',
  seller_counter_approved: 'Counter Sent to Buyers',
  admin_counter: 'Counter Sent to Buyers'
};

const statusTextWhenEnded = {
  pending_admin_approval: 'Countered',
  seller_counter_approved: 'Countered',
  admin_counter: 'Countered'
};

const CounterHeader = ({ auctionEnded, counteredAmount, status }) => (
  <StyledHeaderWrapper>
    <HeaderTitle
      titleJSX={
        <StyledHeaderTitle>
          Counter Amount —{' '}
          <NumberFormat
            displayType="text"
            prefix="$"
            value={counteredAmount}
            thousandSeparator
          />
        </StyledHeaderTitle>
      }
      subTitleJSX={
        <StyledHeaderSubTitle>
          Bid Status —{' '}
          <span>
            {!auctionEnded ? statusText[status] : statusTextWhenEnded[status]}
          </span>
        </StyledHeaderSubTitle>
      }
    />
  </StyledHeaderWrapper>
);

export default CounterHeader;
