/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Filter, Table, Modals } from './components';
import {
  listSellersInvoices,
  sellersInvoicesSetPage,
} from '../../../../actions';

class Invoices extends Component {
  componentDidMount() {
    const { getInvoices, page, perPage, setPage } = this.props;
    getInvoices({ params: { limit: perPage, offset: page } });
    setPage(page + 1);
  }

  loadMore = () => {
    const { getInvoices, setPage, perPage, page } = this.props;
    getInvoices({ params: { limit: perPage, offset: page } });
    setPage(page + 1);
  };

  render() {
    const { history } = this.props;
    return (
      <div>
        <Header {...this.props} />
        <div className="pageWrapper">
          <Filter {...this.props} />
          <Table history={history} loadMore={this.loadMore} />
          <Modals />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  page: state.financials.page,
  perPage: state.financials.perPage,
});

const mapDispatchToProps = dispatch => ({
  getInvoices: data => dispatch(listSellersInvoices(data).request),
  setPage: page => dispatch(sellersInvoicesSetPage(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoices);
