import { Button, Grid } from '@material-ui/core';
import { usePublicOfferAmount } from '../../../../../hooks';
import styled from 'styled-components';
import { FormattedAmount } from '../../../../../components';
import { AcceptOfferModal } from '../../../../../components/Offers';
import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../../actions';
import { Divider } from '@material-ui/core';

const Label = styled.span`
  color: #212121;
  font-size: 18px;
  font-weight: 500;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const AcceptButton = styled(Button)`
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 140px;
  height: 44px;
  cursor: pointer;
  background: #225a91;
  color: #fff;

  &:hover {
    background: #225a91;
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-top: 8px;
  }
`;

export const AcceptPublicOfferSection = ({ offerAuction, vehicleId, vehicleTitle }) => {
  const { adminAmount } = usePublicOfferAmount(offerAuction);
  const dispatch = useDispatch();

  if (adminAmount === 0) return null;

  const handleAccept = () => dispatch(modalsToggle(`acceptPrivateOffer-${vehicleId}`));

  return (
    <>
      <StyledDivider />
      <Grid container justify="space-between" alignItems="center">
        <Label>
          {`Counter amount - `}
          <FormattedAmount amount={adminAmount} />
        </Label>
        <AcceptButton onClick={handleAccept}>ACCEPT</AcceptButton>
        <AcceptOfferModal vehicleTitle={vehicleTitle} vehicleId={vehicleId} />
      </Grid>
    </>
  );
};
