import React from 'react';
import { IconButton, Icon } from '@material-ui/core';
import styled from 'styled-components';

const StyledIcon = styled(IconButton)`
  padding: 0;
  span {
    padding-bottom: 0;
    margin-right: 0;
  }
`;

const FormResetButton = ({ onClick, ...rest }) => (
  <StyledIcon onClick={onClick} {...rest}>
    <Icon>cancel</Icon>
  </StyledIcon>
);

export default FormResetButton;
