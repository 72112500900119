import styled from 'styled-components';
import { TableButton } from '../../../../../components/Table/common';
import { useSelector } from 'react-redux';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useSalesOfferAuction } from '../hooks';
import { useSalesActions } from '../hooks/useSalesActions';

const Container = styled.div`
  padding-left: 40px;
  display: flex;
  min-width: 160px;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const ButtonsBlock = ({ currentVehicle }) => {
  const user = useSelector(state => state?.user?.user);

  const offerAuction = useSalesOfferAuction(currentVehicle.id);
  const offerAuctionStatus = auctionOfferStatuses(currentVehicle, offerAuction, user);

  const buttons = useSalesActions(currentVehicle, offerAuction, offerAuctionStatus);
  return (
    <Container>
      {buttons.map((btn, i) => (
        <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
      ))}
    </Container>
  );
};
