import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'usehooks-ts';

const VerticalLine = styled.div`
  width: 1px;
  height: 92px;
  background: #b0b0b0;
  margin: 0 10px;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #b0b0b0;
`;

export const Delimiter = () => {
  const screen = useWindowSize();

  return screen && window.innerWidth <= 576 ? <HorizontalLine className='delimeter' /> : <VerticalLine className='delimeter' />;
};
