import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { OfferAuction, Vehicle } from '../../../../../types/vehicle';
import { modalsToggle } from '../../../../../actions';
import { BUYER_VEHICLE_STATUS } from '../../../../../constants';
import { usePublicOfferAmount } from 'hooks';

export const useVehicleBidsActions = (
  vehicle: Vehicle,
  vehicleOfferAuction: OfferAuction | null | undefined,
  status: string
) => {
  const dispatch = useDispatch();
  const { adminAmount } = usePublicOfferAmount(vehicleOfferAuction);
  const history = useHistory<any>();

  const buttons = [
    {
      title: 'Counter Offer',
      color: 'light',
      conditionalRendering: (item: any) => status === BUYER_VEHICLE_STATUS.COUNTERED,
      handleClick: (item: any) => {
        dispatch(modalsToggle(`sendVehicleOffer-${item.id}`));
      },
      fullWidth: true,
      withShadow: true,
      isRounded: true
    },
    {
      title: 'Accept',
      color: 'primary',
      conditionalRendering: () =>
        [BUYER_VEHICLE_STATUS.COUNTERED, BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT].includes(status) ||
        ([BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER, BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS].includes(
          status
        ) &&
          adminAmount > 0),
      handleClick: (item: any) => {
        dispatch(modalsToggle(`acceptPrivateOffer-${item.id}`));
      },
      fullWidth: true,
      withShadow: true,
      isRounded: true
    },
    {
      title: 'View',
      color: 'secondary',
      handleClick: (item: any) => history.push(`/vehicles/${item.id}/view`),
      fullWidth: true,
      withShadow: true,
      isRounded: true,
      conditionalRendering: (item: any) => {
        if (
          [
            BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS,
            BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER
          ].includes(status) &&
          adminAmount > 0
        ) {
          return false;
        }

        return true;
      }
    }
  ];

  return buttons;
};
