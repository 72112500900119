import React from 'react';
import { reduxForm, Field } from 'redux-form';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const renderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    valueSelected={input.value}
    defaultSelected="responsible"
    onChange={(event, value) => input.onChange(value)}
  />
);

const DisclaimersForm = () => (
  <form>
    <Field name="disclamer" component={renderRadioGroup}>
      <FormControlLabel
        value="responsible"
        control={<Radio value="responsible" />}
        label="Buyer responsible for viewing vehicle prior to placing bid (Sold As Is)"
      />
      <FormControlLabel
        value="guaranteed"
        control={<Radio value="guaranteed" />}
        label="Vehicle is green light. Motor, tranny and rear end guaranteed for 24hrs. (Vehicles being transported over 300 miles are guaranteed until 5pm on the Wednesday following the auction)"
      />
    </Field>
  </form>
);

export default reduxForm({
  form: 'disclaimersForm'
})(DisclaimersForm);
