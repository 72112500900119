import React from 'react';
import styled from 'styled-components';
import { Vehicle } from '../../../../../types/vehicle';
import { FormattedAmount } from '../../../../../components';
import { ReservePriceLabel } from '../../../../../components/ReservePriceMet';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';

type Props = {
  vehicle: Vehicle;
  includeDivider?: boolean;
  includeTopDivider?: boolean;
};

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  color: #212121;
  font-size: 18px;
  font-weight: 500;
`;

const LabelWrapper = styled.div`
  min-width: 160px;
`;

export const ReservePriceSection = ({ vehicle, includeDivider = false, includeTopDivider = false }: Props) => {
  const user = useSelector((state: any) => state.user?.user);

  if (user && user.role === 'buyer' && !vehicle.reserve_price_set) return null;

  return (
    <>
      {includeTopDivider && <StyledDivider />}
      <Wrapper>
        {vehicle.reserve_price && (
          <TextWrapper>
            <span>Reserve price - </span>
            <FormattedAmount amount={vehicle.reserve_price ?? 0} />
          </TextWrapper>
        )}
        <LabelWrapper>
          <ReservePriceLabel activeVehicle={vehicle} />
        </LabelWrapper>
      </Wrapper>
      {includeDivider && <StyledDivider />}
    </>
  );
};
