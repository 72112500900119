import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import AdminTopOfferBlock from '../TopOfferBlock';
import { useAcceptBidMutation } from 'services/api/offer-auction';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 8px 32px 8px 8px;
  font-weight: 500;
  padding-left: 0;
`;

const StyledBold = styled.span`
  font-weight: 500;
  font-size: 18px;
`;


const ButtonControls = styled(Grid)`
  width: 100%;
  margin-top: 12px;
  gap: 16px;

  & > button {
    flex: 1 1 100%;
  }
`;

const ControlButton = styled(Button)`
  border-radius: 6px;
  width: 94px;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const SellerAcceptedPublicOfferBlock = ({ offerAuction, vehicle }) => {
  const dispatch = useDispatch();
  const openModal = () => dispatch(modalsToggle(`acceptTopOffer-${vehicle.id}`));
  const sellerAcceptBid = offerAuction?.offerBids?.find(el => el.status === 'seller_accepted');
  const [acceptTopBid] = useAcceptBidMutation();

  if (!sellerAcceptBid) return null;

  const handleAccept = () => {
    acceptTopBid({ vehicle_id: vehicle.id, offer_id: sellerAcceptBid.id });
    dispatch(modalsToggle(`acceptTopOffer-${vehicle.id}`));
  };

  return (
    <Fragment>
      <Grid container item>
        <AdminTopOfferBlock offerAuction={offerAuction} />
        <Grid item container>
          <Grid container alignItems="center" justify="space-between">
            <StyledGrid item xs={12} sm={8}>
              <StyledBold>
                <span>Seller Accepted </span>
                <NumberFormat value={sellerAcceptBid.amount} displayType="text" thousandSeparator prefix="$" />
              </StyledBold>
            </StyledGrid>
          </Grid>
        </Grid>
        <ButtonControls container wrap="nowrap">
          {/* <ControlButton onClick={handleCounter} color="#3994DE" textColor="#fff">
            Counter
          </ControlButton> */}
          <ControlButton color="#225A91" onClick={openModal} textColor="#fff">
            Accept
          </ControlButton>
        </ButtonControls>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      </Grid>
      <ConfirmModal
        submitText="Accept"
        title="Are you sure you want to accept the offer?"
        modalId={`acceptTopOffer-${vehicle.id}`}
        handleSubmit={handleAccept}
      />
    </Fragment>
  );
};

export default SellerAcceptedPublicOfferBlock;
