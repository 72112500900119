import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { usePublicOfferAmount } from '../../../../../hooks';
import { useAcceptBidMutation } from 'services/api/offer-auction';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { modalsToggle } from '../../../../../actions';
import { useOfferAuctionBidsInfo } from '../../../../../hooks';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const TopOfferWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  width: 100%;

  & > button {
    width: 200px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    & > button {
      width: 100%;
    }
  }
`;

const ControlButton = styled(Button)`
  border-radius: 6px;
  min-width: 200px;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const TopOfferBlock = ({ offerAuction, vehicle }) => {
  const { adminAmount } = usePublicOfferAmount(offerAuction);
  const dispatch = useDispatch();
  const [acceptBid] = useAcceptBidMutation();
  const openModal = () => {
    dispatch(modalsToggle('acceptTopOffer'));
  };

  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction);

  const handleSubmit = () => {
    acceptBid({ vehicle_id: vehicle.id, offer_id: highestBidOffer.id });
    dispatch(modalsToggle('acceptTopOffer'));
  };

  if (adminAmount === 0) return null;
  if (!highestBidOffer || highestBidOffer.amount === 0) return null;

  const sellerAcceptedOffer = offerAuction.offerBids?.find(el => el.status === 'seller_accepted');

  return (
    <Fragment>
      <Grid container item>
        <TopOfferWrapper>
          <div>
            <span>Top Offer — </span>
            <NumberFormat value={highestBidOffer.amount} displayType="text" thousandSeparator prefix="$" />
          </div>
          {!sellerAcceptedOffer && (
            <ControlButton onClick={openModal} color="#225A91" textColor="#fff">
              Accept Offer
            </ControlButton>
          )}
        </TopOfferWrapper>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      </Grid>
      <ConfirmModal
        modalId={'acceptTopOffer'}
        title={
          <>
            You are about to accept the offer of{' '}
            <NumberFormat displayType="text" prefix="$" value={highestBidOffer.amount} thousandSeparator /> from{' '}{highestBidOffer.bidder?.dealership_name ?? ''}
          </>
        }
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default TopOfferBlock;
