import { ListVehiclePoolRowFromServer, ListVehiclesRequest } from './types';

const parseRange = (range: string) => {
  const [lower, upper] = range.split('-').map(Number);
  return [lower, upper ?? lower];
};

export const NumberOfVehicles = ({ listRequests }: { listRequests: ListVehiclesRequest[] }) => {
  const data = listRequests.map(e => e.number_of_vehicles).filter(Boolean) as string[];

  if (data.length === 0) {
    return <span>-</span>;
  }

  const [lower, upper] = data.reduce<[number, number]>(
    (acc, curr) => {
      const [localLower, localUpper = 0] = parseRange(curr);
      const [currLower, currUpper] = acc;
      return [currLower + localLower, localUpper + currUpper];
    },
    [0, 0]
  );

  if (lower === upper) {
    return <span>{lower}</span>;
  }

  return <span>{`${lower}-${upper}`}</span>;
};
