import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const StyledImage = styled.img`
  width: 96px;
  height: 72px;
  border-radius: 3px;
  cursor: pointer;
`;

const ImagePreview = ({ url, vehicleId, history }) => (
  <StyledImage src={url} onClick={() => history.push(`/vehicles/${vehicleId}/view`)} />
);

export default withRouter(ImagePreview);
