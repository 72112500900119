import styled from 'styled-components';
import { BUYER_VEHICLE_STATUS } from '../../../../../../constants';
import {
  useBuyerCounterControls,
  useNoSelectorVehicleOfferAuctionById,
  usePrivateOfferAmount,
  useTimerConfig
} from '../../../../../../hooks';
import { useCurrentUser } from '../../../../../../hooks/selectors';
import { OfferAuctionStatus, OfferStatus, Vehicle } from '../../../../../../types/vehicle';
import { auctionOfferStatuses } from '../../../../../../utils/statusCalculator';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ReservePriceSection } from '../../../../../Shared/VehicleDetails/components';
import { AcceptPublicOfferSection } from './AcceptPublicOfferSection';
import { AcceptOfferModal, SendOfferModal } from '../../../../../../components/Offers';
import { FormattedAmount, Timer } from '../../../../../../components';

type Props = {
  vehicle: Vehicle;
};

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
  }
`;

const CounterAmountWrapper = styled.div`
  color: rgba(33, 33, 33, 1);
  font-size: 16px;
  line-height: 18.75px;
  padding-bottom: 12px;
`;

const TimerWrapper = styled(Grid)`
  margin-bottom: 12px;
  & > h2 {
    margin: 0;
  }

  & > span {
    width: unset;
  }
`;
const StyledViewReportOfSale = styled(Typography)`
  && {
    color: #005a96;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    font-weight: 500;
    margin-top: 17px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const statusDescription = {
  [BUYER_VEHICLE_STATUS.PULLED]: 'The vehicle has been pulled and it is no longer available for bidding',
  [BUYER_VEHICLE_STATUS.UNDECIDED]: `Congratulations! You are the Top Buyer and have a solo chance to negotiate with the seller.
    You will see the updates here.`,
  [BUYER_VEHICLE_STATUS.PENDING_APPROVAL]:
    'The high bid is being reviewed. Once a counter or acceptance is processed, you will see the update here.',
  [BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS]: `The high bid is being reviewed. Once a counter or acceptance is processed, you will see the update here.`
};

const CounterControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 20px;
`;

const CounterButton = styled(Button)<{ textColor?: string; color: string }>`
  border-radius: 6px;
  flex: 1 1 100%;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

export const BuyerActiveOffers = ({ vehicle }: Props) => {
  const currentUser = useCurrentUser();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id)!;
  const isBuyerAwarded = currentUser.id === vehicle.buyer_id;
  const isCharged = vehicle.charge_completed;
  const amount = vehicle.vehicle_price;
  const awardedUser = vehicle.awarded_user;
  const counterControlButtons = useBuyerCounterControls(vehicle.id);
  const [, acceptButton] = counterControlButtons;
  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);
  const { timer, colorsConfig } = useTimerConfig(vehicle, offerAuction);
  const { buyerAmount, adminOrSellerAmount } = usePrivateOfferAmount(offerAuction);

  const AwardedDescriptionComponent = () => {
    if (status === BUYER_VEHICLE_STATUS.AWARDED) {
      return (
        <StyledDescription>
          {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
          <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator />
        </StyledDescription>
      );
    }

    if (BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE) {
      return (
        <StyledDescription>
          {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
          <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator />{' '}
        </StyledDescription>
      );
    }

    return (
      <StyledDescription>
        {isBuyerAwarded ? 'You' : awardedUser.dealership_name} were awarded the vehicle for the amount of{' '}
        <NumberFormat displayType="text" prefix="$" value={amount} thousandSeparator /> but your credit card was
        declined during fee charging. Please update your credit card information on your Profile.
      </StyledDescription>
    );
  };

  switch (status) {
    case BUYER_VEHICLE_STATUS.PULLED:
    case BUYER_VEHICLE_STATUS.UNDECIDED:
    case BUYER_VEHICLE_STATUS.PENDING_APPROVAL: {
      return (
        <Grid container direction="column">
          <StyledDescription>{statusDescription[status]}</StyledDescription>
          <ReservePriceSection vehicle={vehicle} includeTopDivider />
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS:
    case BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER: {
      const descriptionText = (() => {
        if (
          status === BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS &&
          offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS
        ) {
          return 'Seller accepting backup offers if top buyer and seller don’t come to an agreement.';
        }

        return statusDescription[status];
      })();
      return (
        <Grid container direction="column">
          {descriptionText && <StyledDescription>{descriptionText}</StyledDescription>}
          <ReservePriceSection vehicle={vehicle} includeTopDivider />
          <AcceptPublicOfferSection
            offerAuction={offerAuction}
            vehicleId={vehicle.id}
            vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          />
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT:
    case BUYER_VEHICLE_STATUS.COUNTERED: {
      const lastOffer = offerAuction.privateOffers?.find(el => el.status === OfferStatus.SENT);
      return (
        <Grid container direction="column">
          <TimerWrapper container justify="space-between" alignItems="center">
            {lastOffer && lastOffer.sender.role !== 'buyer' && timer && (
              <Timer withColors colorsConfig={colorsConfig} toDate={timer} />
            )}
          </TimerWrapper>
          <ReservePriceSection vehicle={vehicle} />
          <StyledDivider />
          <CounterAmountWrapper>
            {`Your countered Amount - `}
            <FormattedAmount amount={buyerAmount} />
          </CounterAmountWrapper>
          {status === BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT && (
            <StyledDescription style={{ marginBottom: '12px' }}>
              The counter was sent to seller. You will see updates here.
            </StyledDescription>
          )}
          <CounterAmountWrapper>
            {`Seller’s countered Amount - `}
            <FormattedAmount amount={adminOrSellerAmount} />
          </CounterAmountWrapper>
          {status === BUYER_VEHICLE_STATUS.COUNTERED && (
            <StyledDescription style={{ marginBottom: '12px' }}>
              The counter was set by seller. You will see updates here.
            </StyledDescription>
          )}
          {status === BUYER_VEHICLE_STATUS.COUNTERED && (
            <CounterControlsWrapper>
              {counterControlButtons.map(btn => (
                <CounterButton key={btn.title} color={btn.color as any} textColor={btn.textColor} onClick={btn.onClick}>
                  {btn.title}
                </CounterButton>
              ))}
            </CounterControlsWrapper>
          )}
          {status === BUYER_VEHICLE_STATUS.BUYER_COUNTER_SENT && (
            <CounterControlsWrapper>
              <CounterButton
                key={acceptButton.title}
                color={acceptButton.color as any}
                textColor={acceptButton.textColor}
                onClick={acceptButton.onClick}
              >
                {acceptButton.title}
              </CounterButton>
            </CounterControlsWrapper>
          )}
          <SendOfferModal
            offerAuction={offerAuction}
            maxAmount={vehicle.reserve_price}
            form={`sendVehicleOffer-${vehicle.id}`}
            vehicle={vehicle}
          />
          <AcceptOfferModal vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} vehicleId={vehicle.id} />
        </Grid>
      );
    }
    // TODO: move this to the top of the switch
    case BUYER_VEHICLE_STATUS.DECLINED: {
      return (
        <Grid container direction="column">
          <StyledDescription>The car was awarded to {awardedUser.dealership_name}.</StyledDescription>
        </Grid>
      );
    }
    case BUYER_VEHICLE_STATUS.AWARDED:
    case BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE:
    case BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED:
      return (
        <Grid container direction="column">
          <AwardedDescriptionComponent />
          <StyledViewReportOfSale
            onClick={() => {
              window.open(vehicle.buyer_report_of_sale);
            }}
          >
            View Report of Sale
          </StyledViewReportOfSale>
        </Grid>
      );
    default:
      return null;
  }
};
