import React from 'react';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  SectionGroupTitle,
  SectionGroupDivider,
  StyledGrid
} from '../components';
import { updateProfile } from '../../../../actions/user';
import { FormFormattedField, FormTextField } from '../../../../components';
import {
  validateEmail,
  required,
  maxLength
} from '../../../../utils/validators';
import { REGISTRATION_MAX_STRING_LENGTH } from '../../../../constants';
import scrollToError from '../../../../utils/scrollToError';

const maxStringLength = maxLength(REGISTRATION_MAX_STRING_LENGTH);

const phoneNumberValidation = value =>
  value && value.replace(/[\D]/g, '').length === 10
    ? undefined
    : 'Phone number must contain 10 digits';

const notRequiredPhoneNumberValidation = value =>
  (value && value.replace(/[\D]/g, '').length === 10) ||
  (value || '').length === 0
    ? undefined
    : 'Phone number must contain 10 digits';

const renderContacts = contacts => (
  <>
    <StyledGrid item xs={12}>
      <SectionGroupTitle>All Contacts</SectionGroupTitle>
    </StyledGrid>
    {contacts.map((contact, index) => (
      <React.Fragment key={index}>
        <StyledGrid item xs={12}>
          <Field
            component={FormTextField}
            name={`contacts[${index}].name`}
            label="Name"
            InputProps={{
              disabled: true,
              disableUnderline: true
            }}
            fullWidth
            validate={maxStringLength}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Field
            component={FormTextField}
            name={`contacts[${index}].job_title`}
            label="Job Title"
            InputProps={{
              disabled: true,
              disableUnderline: true
            }}
            fullWidth
            validate={maxStringLength}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Field
            name={`contacts[${index}].phone`}
            component={FormFormattedField}
            label="Mobile phone number"
            fullWidth
            InputProps={{
              disabled: true,
              disableUnderline: true
            }}
            validate={notRequiredPhoneNumberValidation}
            maskProps={{
              format: '(###) ###-####',
              mask: '_'
            }}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Field
            component={FormTextField}
            name={`contacts[${index}].email`}
            label="Email"
            InputProps={{
              disabled: true,
              disableUnderline: true
            }}
            fullWidth
            validate={validateEmail}
          />
        </StyledGrid>
        {(contacts || []).length !== index + 1 && (
          <StyledGrid item xs={12}>
            <SectionGroupDivider />
          </StyledGrid>
        )}
      </React.Fragment>
    ))}
  </>
);

const ContactsSectionForm = ({ handleSubmit, contacts, InputProps }) => (
  <form onSubmit={handleSubmit}>
    <StyledGrid container spacing={24}>
      <StyledGrid item xs={12}>
        <SectionGroupTitle>Primary Contact</SectionGroupTitle>
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          component={FormTextField}
          name="first_name"
          label="First Name"
          InputProps={InputProps}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid>
      <StyledGrid item xs={12} sm={6}>
        <Field
          component={FormTextField}
          name="last_name"
          label="Last Name"
          InputProps={InputProps}
          fullWidth
          validate={[required, maxStringLength]}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="job_title"
          label="Job Title"
          InputProps={InputProps}
          fullWidth
          validate={maxStringLength}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          name="phone_number"
          component={FormFormattedField}
          label="Mobile phone number"
          fullWidth
          InputProps={InputProps}
          validate={[required, phoneNumberValidation]}
          maskProps={{
            format: '(###) ###-####',
            mask: '_'
          }}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="email"
          label="Email"
          InputProps={InputProps}
          fullWidth
          validate={[required, validateEmail]}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <SectionGroupDivider />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <SectionGroupTitle>Secondary Contact</SectionGroupTitle>
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="secondary_contact_name"
          label="Name"
          InputProps={InputProps}
          fullWidth
          validate={maxStringLength}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="secondary_contact_job_title"
          label="Job Title"
          InputProps={InputProps}
          fullWidth
          validate={maxStringLength}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          name="secondary_contact_phone"
          component={FormFormattedField}
          label="Mobile phone number"
          fullWidth
          InputProps={InputProps}
          validate={notRequiredPhoneNumberValidation}
          maskProps={{
            format: '(###) ###-####',
            mask: '_'
          }}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Field
          component={FormTextField}
          name="secondary_contact_email"
          label="Email"
          InputProps={InputProps}
          fullWidth
          validate={validateEmail}
        />
      </StyledGrid>
      <StyledGrid item xs={12}>
        <SectionGroupDivider />
      </StyledGrid>
      {(contacts || []).length ? renderContacts(contacts) : null}
    </StyledGrid>
  </form>
);

const selector = formValueSelector('contactsSectionForm');

const mapStateToProps = state => ({
  contacts: selector(state, 'contacts')
});

export default compose(
  reduxForm({
    form: 'contactsSectionForm',
    onSubmit: (values, dispatch) =>
      new Promise((resolve, reject) =>
        dispatch(
          updateProfile({
            payload: {
              ...values,
              phone_number: values.phone_number.toString().replace(/[\D]/g, ''),
              secondary_contact_phone: (values.secondary_contact_phone || '')
                .toString()
                .replace(/[\D]/g, '')
            },
            resolve,
            reject
          }).request
        )
      ),
    enableReinitialize: true,
    updateUnregisteredFields: true,
    onSubmitFail: scrollToError
  }),
  connect(mapStateToProps)
)(ContactsSectionForm);
