import React from 'react';
import { Button, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import styled from 'styled-components';
import { FormFormattedField, FormInput } from '../../../../components/Form';
import { snackShow } from '../../../../actions';
import { BID_INCREMENT, SNACK_MESSAGE_TYPES } from '../../../../constants';
import { usePlaceBidMutation, usePlaceSidePanelBidMutation } from 'services/api/my-bids';
import { usePlaceOfferBidMutation } from 'services/api/offer-auction';
import { TableButton } from '../../../../components/Table/common';
import { useHistory } from 'react-router-dom';

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: ${({ variant }) => (variant === 'mobile-panel' ? 'row' : 'column')};
  margin-top: 5px;
  border-radius: 4px;

  & > * {
    flex: ${({ variant }) => (variant === 'mobile-panel' ? '1 1 100%' : 'initial')};
    border-radius: ${({ variant }) => (variant === 'mobile-panel' ? '4px' : 'initial')};
  }

  & * {
    font-size: ${({ variant }) => (variant === 'mobile-panel' ? '12px' : '16px')};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: ${({ variant }) => (variant === 'mobile-panel' ? '0 8px' : '8px')};

  justify-content: space-between;
`;

const StyledSubmitButton = styled(Button)`
  margin: 0;
  border-radius: 4px;
  width: 100%;
`;

const StyledControlButton = styled(Button)`
  min-height: 0;
  width: 24px;
  height: 24px;
  font-size: 16px;
  box-shadow: none;
`;

const StyledIcon = styled(Icon)`
  font-size: 16px;
`;

const StyledFormFormattedField = styled(FormFormattedField)`
  font-size: 16px;
  input {
    width: 93px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const isFloored = value => {
  const flooredNumber = Math.floor(value / 100) * 100;
  return flooredNumber !== value;
};

const validate = values => {
  const errors = {};
  if (values.amount && isFloored(values.amount)) {
    // eslint-disable-next-line
    errors._error = 'Use bidding increments of min bid amount';
  }
  return errors;
};

const toInt = value => value && Number(value.replace(/\D/g, ''));

const IncreaseHighBid = ({
  vehicleId,
  change,
  error,
  minAmount,
  amount,
  handleSubmit,
  vehicle = null,
  buttons = [],
  withButtons = false,
  bidIncrement = BID_INCREMENT,
  variant = 'default',
  type = 'bid',
  isBackupOffer = false,
  isSidePanelBid = false,
  classNamePrefix = ''
}) => {
  const [placeSidePanelBid] = usePlaceSidePanelBidMutation();
  const [placeBid] = usePlaceBidMutation();
  const [placeOfferBid] = usePlaceOfferBidMutation();

  const offerLabel = (() => {
    if (withButtons) {
      return 'submit';
    }
    return isBackupOffer ? 'submit backup offer' : 'submit offer';
  })();

  const config = {
    bid: {
      placeBidFn: isSidePanelBid ? placeSidePanelBid : placeBid,
      buttonLabel: 'submit',
      successMessage: 'Bid successfully placed'
    },
    offer: {
      placeBidFn: placeOfferBid,
      buttonLabel: offerLabel,
      successMessage: 'Offer successfully placed'
    }
  }[type];

  const onSubmit = () => ({ amount }, dispatch, { showErrorNotification, change, showSuccessNotification }) => {
    const bidAmount = amount - minAmount;
    if (isFloored(amount)) {
      showErrorNotification(`Use bidding increments of $${bidIncrement}`);
    } else if (amount < minAmount) {
      showErrorNotification('Bid is lesser than minimum bid amount!');
    } else if (bidAmount > 0 && bidAmount < bidIncrement) {
      showErrorNotification(`Minimum bid amount is ${minAmount + bidIncrement}`);
    }
    return config
      .placeBidFn({
        vehicle_id: vehicleId,
        amount
      })
      .then(() => {
        showSuccessNotification(config.successMessage);
        change('amount', '');
      })
      .catch(({ response }) => {
        if (response.data && response.data.message) {
          showErrorNotification(response.data.message);
        } else {
          showErrorNotification('Server exception');
        }
      });
  };

  const getClassName = name => `${classNamePrefix}_${name}`;

  const isSubmitDisabled = amount < minAmount || error || !amount;
  return (
    <StyledForm
      className={getClassName('form')}
      variant={variant}
      onSubmit={handleSubmit(onSubmit(vehicleId, minAmount))}
      id="bidding"
    >
      <InputWrapper className={getClassName('input-wrapper')} variant={variant}>
        <Field
          name="amount"
          placeholder={`$${minAmount} min`}
          label={`$${minAmount} min`}
          component={StyledFormFormattedField}
          thousandSeparator
          allowNegative={false}
          fixedDecimalScale
          prefix="$"
          customInput={FormInput}
          disableUnderline
          normalize={toInt}
          className={getClassName('input')}
        />
        <ButtonWrapper>
          <StyledControlButton
            className={getClassName('control-button')}
            variant="fab"
            color="secondary"
            onClick={() => {
              const newValue = amount - bidIncrement;
              if (newValue >= 0) {
                change('amount', newValue);
              }
            }}
            disabled={!amount || amount - bidIncrement < minAmount}
          >
            <StyledIcon>remove</StyledIcon>
          </StyledControlButton>
          <StyledControlButton
            className={getClassName('control-button')}
            variant="fab"
            color="secondary"
            onClick={() => {
              if (amount) {
                change('amount', amount + bidIncrement);
              } else {
                change('amount', minAmount);
              }
            }}
          >
            <StyledIcon>add</StyledIcon>
          </StyledControlButton>
        </ButtonWrapper>
      </InputWrapper>

      <ButtonWrapper className={getClassName('submit-button-wrapper')}>
        <StyledSubmitButton
          className={`${getClassName('submit-button')}  ${
            !isSubmitDisabled ? getClassName('submit-button-active') : ''
          }`}
          type="submit"
          color={isSubmitDisabled ? 'secondary' : 'primary'}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          {config.buttonLabel}
        </StyledSubmitButton>
        <ButtonBlock withButtons={withButtons} buttons={buttons} currentVehicle={vehicle} />
      </ButtonWrapper>
    </StyledForm>
  );
};

const ButtonBlock = ({ currentVehicle, withButtons = false, buttons = [] }) => {
  if (!withButtons) {
    return null;
  }

  return buttons.map((btn, i) => {
    const renderButton = !btn.conditionalRendering || btn.conditionalRendering(currentVehicle);
    if (!renderButton) return null;

    return (
      <ButtonWrapper>
        <TableButton key={`b${i}`} btn={btn} itemData={currentVehicle} />
      </ButtonWrapper>
    );
  });
};

const mapStateToProps = (state, { form }) => {
  const amount = form ? formValueSelector(form)(state, 'amount') : '';
  return {
    amount: amount === '' ? 0 : amount
  };
};

const mapDispatchToProps = dispatch => ({
  showErrorNotification: value => dispatch(snackShow({ message: value, type: SNACK_MESSAGE_TYPES.ERROR })),
  showSuccessNotification: value => dispatch(snackShow({ message: value, type: SNACK_MESSAGE_TYPES.SUCCESS }))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    validate,
    enableReinitialize: true
  })
)(IncreaseHighBid);
