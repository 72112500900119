import { TableButton } from 'components/Table/common';
import { VEHICLE_STATUSES } from 'constants';
import { UPLOAD_TYPES } from 'constants';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useScreen } from 'usehooks-ts';

const Container = styled.div`
  display: flex;
  gap: 8px;
  min-width: 220px;

  @media (max-width: 800px) {
    padding-left: 0;
    min-width: 100px;
  }
`;

export const ButtonsBlock = ({ vehicle }) => {
  const history = useHistory();
  const { width = 0 } = useScreen();
  const isMobile = width < 580;

  const redirectOnEdit = item => {
    history.push(`/upload-vehicle/${item.id}`);
  };
  // history.push(
  //   item.upload_type === UPLOAD_TYPES.AUTOMATED ? `/vehicles/${item.id}/edit` : `/vehicles/${item.id}/edit-manual`
  // );

  const handleVehicleEdit = item => {
    if (item.hasDuplicate) {
      // eslint-disable-next-line no-restricted-globals
      const duplicateConfirmation = confirm('You are about to edit a car that has been duplicated?');
      if (duplicateConfirmation) {
        return redirectOnEdit(item);
      }
    } else if (item.status === VEHICLE_STATUSES.AWARDED) {
      // eslint-disable-next-line no-restricted-globals
      const soldConfirmation = confirm('You are about to edit a car that has been sold');
      if (soldConfirmation) {
        return redirectOnEdit(item);
      }
    } else {
      return redirectOnEdit(item);
    }
  };
  const buttons = [
    {
      title: 'View',
      handleClick: item => history.push(`/vehicles/${item.id}/view`),
      conditionalRendering: item => item.status !== VEHICLE_STATUSES.DRAFT,
      color: isMobile ? 'light' : 'primary',
      fullWidth: isMobile,
      size: isMobile ? 'lg' : 'auto',
      withShadow: isMobile
    },
    {
      title: 'Edit',
      color: 'secondary',
      conditionalRendering: item => !!item,
      handleClick: item => handleVehicleEdit(item),
      fullWidth: isMobile,
      size: isMobile ? 'lg' : 'auto',
      withShadow: isMobile
    }
  ];

  return (
    <Container>
      {buttons.map((btn, i) => (
        <TableButton key={`b${i}`} btn={btn} itemData={vehicle} />
      ))}
    </Container>
  );
};
