import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import HeaderTitle from '../components/HeaderTitle';
import VehicleValues from './VehicleValues';

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: ${props => props.statusColor || '#000'};
  }
`;

const colorPalette = {
  red: '#D50808',
  orange: '#E47E00',
  green: '#06A000'
};

const StyledHeaderWrapper = styled.div`
  display: flex;
`;

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
  }
`;

const CounterHeader = props => {
  const { color, title } = props;
  return (
    <StyledHeaderWrapper>
      <HeaderTitle
        titleJSX={
          <StyledHeaderTitle statusColor={colorPalette[color]}>
            {title}
          </StyledHeaderTitle>
        }
        subTitleJSX={
          <StyledHeaderSubTitle>
            <VehicleValues {...props} />
          </StyledHeaderSubTitle>
        }
      />
    </StyledHeaderWrapper>
  );
};

export default CounterHeader;
