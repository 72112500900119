import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, reduxForm, reset } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DateTimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ModalWrapper from '../ModalWrapper';
import FormError from '../Form/FormError';
import { modalsToggle, addAuction, snackShow, getLocation, auctionsUnsetSuccess, modalsHide } from '../../actions';
import { DATE_FORMAT_FULL, dayOfWeek } from '../../constants';
import { useCreateAuctionMutation, useGetLocationQuery } from 'services/api/auctions-list';

const validate = values => {
  const errors = {};
  ['date_start'].forEach(item => {
    if (!Object.prototype.hasOwnProperty.call(values, item)) {
      errors[item] = 'Required';
    }
  });
  return errors;
};

const formName = 'AuctionCreateModal';

const DatePickerField = ({ input: { onChange, value } }) => (
  <DateTimePicker
    label=""
    showTodayButton
    minDate={moment().format()}
    value={value || moment().format()}
    onChange={onChange}
    format={DATE_FORMAT_FULL}
  />
);

const styles = {
  spanLabel: {
    marginRight: 15
  },
  modalHolder: {
    maxWidth: 360
  }
};

const AuctionCreateModal = ({ handleSubmit, submitting, classes, formError }) => {
  const [createAuction, { isLoading }] = useCreateAuctionMutation();
  const dispatch = useDispatch();

  const auctionType = useSelector(state => state.auctionsList.auctionType);

  const { data: locationItem } = useGetLocationQuery();

  const submit = values => {
    dispatch(modalsHide('newAuctionModal'));
    createAuction({ startDate: moment(values.date_start ?? new Date()).toISOString(), auctionType });
    window.location.reload();
  };

  const spacingTime = (locationItem && locationItem.end_time_spacing && locationItem.end_time_spacing.split(':')) || [
    0,
    0
  ];

  if (!locationItem) return null;

  return (
    <ModalWrapper
      title="Create New Auction"
      handleSubmit={handleSubmit(submit)}
      submitting={submitting || isLoading}
      modalId="newAuctionModal"
      contentClassName={classes.modalHolder}
    >
      <form>
        <Grid container spacing={16} alignItems="center">
          <Grid item xs={12} lg={12}>
            <span className={classes.spanLabel}>Start:</span>
            <Field name="date_start" component={DatePickerField} margin="dense" type="text" fullWidth />
          </Grid>
          {auctionType === 'autoaxess' ? (
            <Grid item xs={12} lg={12}>
              <Typography>{`Auctions end ${dayOfWeek[locationItem.end_day]} at ${moment
                .utc(locationItem.end_time, 'HH:mm')
                .local()
                .format('hh:mm A')}`}</Typography>
              <Typography>{`Final Auction Shuffle - ${dayOfWeek[locationItem.shuffle_day]} at ${moment
                .utc(locationItem.shuffle_time, 'HH:mm')
                .local()
                .format('hh:mm A')}`}</Typography>
              <Typography>{`Spacing between end times - ${spacingTime[0]} mins, ${spacingTime[1]} seconds`}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography>{`Auctions end date and time: ${moment(locationItem.frontline_date_end).format(
                DATE_FORMAT_FULL
              )}`}</Typography>
            </Grid>
          )}
        </Grid>
        {formError && <FormError msg={formError} />}
      </form>
    </ModalWrapper>
  );
};

export default reduxForm({
  form: formName,
  initialValues: {
    date_start: moment().format()
  },
  validate
})(withStyles(styles)(AuctionCreateModal));
