import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledHeaderWrapper = styled.div`
  text-align: right;
`;

const StyledHeaderTitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 16px;
    color: #212121;
  }
`;

const StyledHeaderSubTitle = styled(Typography)`
  && {
    font-size: 14px;
    color: #616161;
  }
`;

const HeaderTitle = ({ title, subTitle, titleJSX, subTitleJSX }) => (
  <StyledHeaderWrapper>
    {titleJSX || <StyledHeaderTitle>{title}</StyledHeaderTitle>}
    {subTitleJSX || <StyledHeaderSubTitle>{subTitle}</StyledHeaderSubTitle>}
  </StyledHeaderWrapper>
);

export default HeaderTitle;
