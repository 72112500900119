/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';
import { numberFormatString } from '../../../../utils';
import { EasyPayStatus } from '../../../../components/EasyPay';
import { BUYER_VEHICLE_LABELS, BUYER_VEHICLE_STATUS, PAYMENT_METHODS } from '../../../../constants';
import { useBidsHistoryOfferAuction } from '../hooks';
import { auctionOfferStatuses } from '../../../../utils/statusCalculator';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};
`;

const StyledDescription = styled.div`
  color: #757575;
`;

const BidStatus = ({ status, vehicle, currentUser }) => {
  const offerAuction = useBidsHistoryOfferAuction(vehicle.id)
  const offerAuctionStatus = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  const isAwarded = status === 'awarded';
  const isDeclined = status === 'declined';
  const isUserAwarded = vehicle.buyer_id === currentUser.id;
  const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;

  if (isAwarded && isUserAwarded && easyPaid) {
    return <EasyPayStatus vehicle={vehicle} />
  } else if (isAwarded && isUserAwarded) {
    return (
      <>
        <StyledStatus statusColor="#009F0F">Awarded</StyledStatus>
        {/* <StyledDescription>
          {`(${numberFormatString(vehicle.vehicle_price || 0)})`}
        </StyledDescription> */}
      </>
    );
  } else if (isDeclined) {
    return (
      <>
        <StyledStatus statusColor="#D50808">Declined</StyledStatus>
        {/* <StyledDescription>
          {vehicle.awarded_user.dealership_name}
        </StyledDescription> */}
      </>
    );
  } else {
    const { label, color } = BUYER_VEHICLE_LABELS[offerAuctionStatus ?? BUYER_VEHICLE_STATUS.UNDECIDED];
    return <StyledStatus statusColor={color}>{label}</StyledStatus>;
  }
};

export default BidStatus;
