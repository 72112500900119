import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  TouchSensorOptions,
  closestCorners,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  useGetSectionConfigQuery,
  useGetSectionsOrderQuery,
  useUpdateSectionsOrderMutation
} from '../../../../services/api/vehicle-sections';
import styled from 'styled-components';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { MovableSection } from './MovableSection';
import { TouchEvent, useState } from 'react';
import { MovableSectionOverlay } from '../overlay/MovableSectionOverlay';
import { useDispatch } from 'react-redux';
import { MovableSubSectionOverlay } from '../overlay/MovableSubSectionOverlay';
import api from 'services/api/vehicle-sections';

const MovableSectionsWrapper = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  gap: 24px;
  padding: 8px;
  padding-bottom: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const shouldStartDrag = (target?: Node) => {
  if (!target) return false;
  const activators = document.querySelectorAll('[id^="activator"]');
  return !![...activators].find(node => node.contains(target));
};

class ExtendedTouchSensor extends TouchSensor {
  static activators: Array<{ eventName: 'onTouchStart'; handler: any }> = [
    {
      eventName: 'onTouchStart',
      handler: ({ nativeEvent: event }: TouchEvent, {}: TouchSensorOptions) => {
        return shouldStartDrag(event.target as Node);
      }
    }
  ];
}

class ExtendedMouseSensor extends MouseSensor {
  static activators: Array<{ eventName: 'onMouseDown'; handler: any }> = [
    {
      eventName: 'onMouseDown',
      handler: ({ nativeEvent: event }: TouchEvent, {}: TouchSensorOptions) => {
        return shouldStartDrag(event.target as Node);
      }
    }
  ];
}

export const MovableSections = () => {
  const { data: sectionsConfig } = useGetSectionConfigQuery();
  const [updateSectionOrder] = useUpdateSectionsOrderMutation();
  const [movedSection, setMovedSection] = useState<string | null>(null);
  const [movedSubSection, setMovedSubSection] = useState<string | null>(null);

  const dispatch = useDispatch<any>();

  const { data: sectionOrder = [] } = useGetSectionsOrderQuery();

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });

  const mouseSensor = useSensor(ExtendedMouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  if (!sectionsConfig) return null;

  const onDragStart = (e: DragStartEvent) => {
    const dragId = e.active.id as string;
    const split = dragId.split('.');
    if (split.length === 1) {
      setMovedSection(dragId);
    } else {
      setMovedSubSection(dragId);
    }
  };

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    setMovedSection(null);
    setMovedSubSection(null);

    if (over && active.id !== over.id) {
      const oldI = sectionOrder.findIndex(e => e[0] === (active.id as string));
      const newI = sectionOrder.findIndex(e => e[0] === (over.id as string));

      const newArray = arrayMove(sectionOrder, oldI, newI);

      dispatch(
        api.util.updateQueryData('getSectionsOrder', undefined, draft => {
          return newArray;
        })
      );

      updateSectionOrder(newArray);
    }
  };

  const firstLevelSections = sectionOrder.map(o => o[0]);

  return (
    <DndContext collisionDetection={closestCorners} sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext strategy={verticalListSortingStrategy} items={firstLevelSections} id="outer-sortable">
        <MovableSectionsWrapper>
          {firstLevelSections.map(sectionId => {
            return <MovableSection key={sectionId} sectionId={sectionId} />;
          })}
        </MovableSectionsWrapper>
      </SortableContext>
      <DragOverlay>
        {movedSection ? (
          <MovableSectionOverlay sectionId={movedSection as any} />
        ) : (
          movedSubSection && <MovableSubSectionOverlay name={movedSubSection} />
        )}
      </DragOverlay>
    </DndContext>
  );
};
