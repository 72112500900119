import React from 'react';
import { Field, FieldArray } from 'redux-form';
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography
} from '@material-ui/core';
import styled from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  > span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CheckboxAdapter = inputProps => (
  <Checkbox
    {...inputProps}
    checked={inputProps.input.value}
    value={inputProps.input.value}
    onChange={inputProps.input.onChange}
  />
);

const VehicleOption = ({ fields }) => (
  <FormGroup row>
    {fields.map((option, i) => {
      const { optionName, secondaryName } = fields.get(i);
      const label = secondaryName
        ? `${optionName} - ${secondaryName}`
        : optionName;
      return (
        <Grid key={i} item sm={4} xs={12} md={3}>
          <StyledFormControlLabel
            control={
              <Field
                key={option.vehicleOptionId}
                name={`${option}.isTypical`}
                color="primary"
                component={CheckboxAdapter}
              />
            }
            label={label}
            title={label}
          />
        </Grid>
      );
    })}
  </FormGroup>
);

const VehicleOptions = ({ options }) => (
  <Grid item xs={12}>
    <FormControl fullWidth component="fieldset">
      <Typography variant="subheading">Optional Equipment</Typography>
      <FieldArray name="options" component={VehicleOption} options={options} />
    </FormControl>
  </Grid>
);

export default VehicleOptions;
