import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import { change, destroy, stopSubmit, reset, getFormValues } from 'redux-form';
import { push } from 'connected-react-router';
import moment from 'moment';
import {
  updateCustomer,
  listCustomers,
  detailCustomer,
  disableBuyer,
  filterCustomers,
  loginAsUser,
  LOGIN_AS_USER_REQUEST,
  deleteUser,
  DELETE_CUSTOMER_REQUEST,
  enableUser,
  ENABLE_USER_REQUEST,
  listAccounts,
  updateAccount,
  createAccount,
  deleteAccount,
  accountsSetPage,
  listReferrers,
  listAdminSellerSellers,
  listAccountLogs
} from '../actions/users';
import { modalsHide, snackShow } from '../actions';
import {
  UPDATE_CUSTOMER_REQUEST,
  LIST_CUSTOMERS_REQUEST,
  LIST_ACCOUNTS_REQUEST,
  DETAIL_CUSTOMER_REQUEST,
  DISABLE_BUYER_REQUEST,
  FILTER_CUSTOMERS_REQUEST,
  UPDATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  LIST_REFERRERS_REQUEST,
  LIST_ADMIN_SELLER_SELLERS_REQUEST,
  LIST_ACCOUNT_LOGS_REQUEST
} from '../constants/actionTypes';
import { Api } from '../utils';
import { ACCOUNTS_PER_PAGE, USER_STATUSES } from '../constants';
import { setAnotherUserCredentials } from '../utils/auth';

const getFetchQuery = state => {
  const values = getFormValues('listCustomersFilterForm')(state) || {};
  return {
    ...values,
    created_at_from: values.created_at_from
      ? moment(values.created_at_from)
          .utc()
          .format()
      : null,
    created_at_to: values.created_at_to
      ? moment(values.created_at_to)
          .set({ h: 24 })
          .utc()
          .format()
      : null
  };
};

function* fetchCustomers({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.users.fetchCustomers, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listCustomers(data).success);
  } catch (e) {
    yield put(listCustomers(e).failure);
  }
}

function* fetchAccounts({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.users.fetchAccounts, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listAccounts(data).success);
  } catch (e) {
    yield put(listAccounts(e).failure);
  }
}

function* fetchAccountLogs({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.users.fetchAccountLogs, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listAccountLogs(data).success);
  } catch (e) {
    yield put(listAccountLogs(e).failure);
  }
}

function* fetchAdminSellerSellers({ payload = {} }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.users.fetchAdminSellerSellers, {
      ...payload,
      params: { ...query, ...payload.params }
    });
    yield put(listAdminSellerSellers(data).success);
  } catch (e) {
    yield put(listAdminSellerSellers(e).failure);
  }
}

function* fetchReferrers({ payload }) {
  try {
    const { data } = yield call(Api.users.fetchReferrers, payload);
    yield put(listReferrers(data).success);
  } catch (e) {
    yield put(listReferrers(e).failure);
  }
}

function* filterCustomersSaga({ payload }) {
  try {
    const query = yield select(getFetchQuery);
    const { data } = yield call(Api.users.fetchCustomers, {
      ...payload,
      params: { ...query, ...payload.params }
    });

    yield put(filterCustomers(data).success);
  } catch (e) {
    yield put(filterCustomers(e).failure);
  }
}

function* fetchCustomer({ payload }) {
  try {
    const { data } = yield call(Api.users.fetchCustomer, payload);
    yield put(detailCustomer(data).success);
  } catch (e) {
    yield put(detailCustomer(e).failure);
  }
}

function* editCustomer({ payload }) {
  try {
    const { customerId } = yield select(state => ({
      customerId: state.users.customer.id
    }));
    const { seller_permit_number } = payload?.options ?? {};
    const { data } = yield call(Api.users.updateCustomer, customerId, { seller_permit_number, ...payload });
    yield put(modalsHide());
    yield put(reset('customerDisableForm'));
    yield put(reset('customerDeactivateForm'));
    yield put(reset('customerSellerFeeForm'));
    yield put(snackShow({ message: 'Successfully Updated User' }));
    yield put(updateCustomer(data).success);
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      const validationErrors = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: error.message
        }),
        {}
      );
      yield put(stopSubmit('customerContactsForm', validationErrors));
      yield put(
        snackShow({
          message: 'Validation failed on some fields',
          type: 'error'
        })
      );
    }
    yield put(updateCustomer(e).failure);
  }
}

function* editAccount({ payload }) {
  try {
    const { id, account } = payload;
    const { data } = yield call(Api.users.updateAccount, id, account);
    const perPage = ACCOUNTS_PER_PAGE;
    yield put(modalsHide());
    yield put(snackShow({ message: 'Successfully Updated Account' }));
    yield put(updateAccount(data).success);
    yield put(accountsSetPage(1));
    yield put(listAccounts({ params: { limit: perPage, offset: 1 } }).request);
    yield put(accountsSetPage(2));
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      yield put(
        snackShow({
          message: 'Validation failed on some fields',
          type: 'error'
        })
      );
    }
    yield put(updateAccount(e).failure);
  }
}

function* storeAccount({ payload }) {
  try {
    const { account } = payload;
    const { data } = yield call(Api.users.createAccount, account);
    const perPage = ACCOUNTS_PER_PAGE;
    yield put(modalsHide());
    yield put(snackShow({ message: 'Successfully Created Account' }));
    yield put(createAccount(data).success);
    yield put(accountsSetPage(1));
    yield put(listAccounts({ params: { limit: perPage, offset: 1 } }).request);
    yield put(accountsSetPage(2));
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      yield put(
        snackShow({
          message: 'Validation failed on some fields',
          type: 'error'
        })
      );
    }
    yield put(createAccount(e).failure);
  }
}

function* destroyAccount({ payload }) {
  try {
    const { id, account } = payload;
    const { data } = yield call(Api.users.deleteAccount, id, account);
    const perPage = ACCOUNTS_PER_PAGE;
    yield put(modalsHide());
    yield put(snackShow({ message: 'Successfully Deleted Account' }));
    yield put(deleteAccount(data).success);
    yield put(accountsSetPage(1));
    yield put(listAccounts({ params: { limit: perPage, offset: 1 } }).request);
    yield put(accountsSetPage(2));
  } catch (e) {
    const errors = e.response.data.data;
    if (errors) {
      yield put(
        snackShow({
          message: 'Validation failed on some fields',
          type: 'error'
        })
      );
    }
    yield put(deleteAccount(e).failure);
  }
}

function* buyerDisable({ payload }) {
  try {
    const { data } = yield call(Api.users.updateCustomer, payload.userId, payload);
    yield put(modalsHide());
    yield put(reset('buyerDisableForm'));
    yield put(snackShow({ message: 'Buyer disabled' }));
    yield put(disableBuyer(data).success);
  } catch (e) {
    yield put(snackShow({ message: 'Failed', type: 'error' }));
    yield put(disableBuyer(e).failure);
  }
}

function* loginAsUserSaga({ payload }) {
  try {
    const { id, initialPage = '/admin/customers' } = payload;
    const { data } = yield call(Api.users.loginAsUser, id);
    const { current } = yield select(state => ({
      current: state.auctions.current
    }));
    yield put(loginAsUser(data.data).success);
    const { token, user } = data.data;
    setAnotherUserCredentials(user, token, initialPage);
    if (user.status === USER_STATUSES.NEED_TO_COMPLETE_SIGN_UP) {
      yield put(snackShow({ message: 'You need to complete registration' }));
      yield put(destroy('registrationForm'));
      yield put(change('registrationForm', 'role', user.role));
      yield put(
        push({
          pathname: '/register',
          state: {
            step: user.last_step
          }
        })
      );
    } else if (user.role === 'seller') {
      window.location.href = '/seller/dashboard';
    } else {
      window.location.href = current && current.status === 'active' ? '/live-auction' : '/';
    }
  } catch (e) {
    const { message } = e.response.data;
    yield put(snackShow({ message, type: 'error' }));
    yield put(loginAsUser(e).failure);
  }
}

function* deleteUserSaga({ payload }) {
  try {
    const { customerId } = yield select(state => ({
      customerId: state.users.customer.id
    }));
    const { data } = yield call(Api.users.deleteUser, customerId, payload);
    yield put(deleteUser(data).success);
    yield put(push('/admin/customers'));
    yield put(modalsHide());
  } catch (e) {
    const { message } = e.response.data;
    yield put(snackShow({ message, type: 'error' }));
    yield put(deleteUser(e).failure);
  }
}

function* enableUserSaga({ payload }) {
  try {
    const { data } = yield call(Api.users.updateCustomer, payload, {
      status: USER_STATUSES.ACTIVE
    });
    yield put(enableUser(data).success);
    yield put(snackShow({ message: 'User successfully enabled' }));
    yield put(modalsHide());
  } catch (e) {
    const { message } = e.response.data;
    yield put(snackShow({ message, type: 'error' }));
    yield put(enableUser(e).failure);
    yield put(modalsHide());
  }
}

export default function*() {
  yield all([
    takeLatest(LIST_CUSTOMERS_REQUEST, fetchCustomers),
    takeLatest(LIST_ACCOUNTS_REQUEST, fetchAccounts),
    takeLatest(LIST_ACCOUNT_LOGS_REQUEST, fetchAccountLogs),
    takeLatest(LIST_ADMIN_SELLER_SELLERS_REQUEST, fetchAdminSellerSellers),
    takeLatest(LIST_REFERRERS_REQUEST, fetchReferrers),
    takeLatest(DETAIL_CUSTOMER_REQUEST, fetchCustomer),
    takeLatest(UPDATE_CUSTOMER_REQUEST, editCustomer),
    takeLatest(UPDATE_ACCOUNT_REQUEST, editAccount),
    takeLatest(CREATE_ACCOUNT_REQUEST, storeAccount),
    takeLatest(DELETE_ACCOUNT_REQUEST, destroyAccount),
    takeLatest(DISABLE_BUYER_REQUEST, buyerDisable),
    takeLatest(FILTER_CUSTOMERS_REQUEST, filterCustomersSaga),
    takeLatest(LOGIN_AS_USER_REQUEST, loginAsUserSaga),
    takeLatest(DELETE_CUSTOMER_REQUEST, deleteUserSaga),
    takeLatest(ENABLE_USER_REQUEST, enableUserSaga)
  ]);
}
