import { useWavesurfer } from '@wavesurfer/react';
import { useCallback, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import { PauseIcon, PlayIcon } from '../../../icons/AudioIcons';
import { useLoadSectionAudioMutation } from '../../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../../hooks/useGetVehicleId';
import Tooltip from '../../../../../components/Tooltip';

type Props = {
  name: string;
};

const Container = styled.div<{ removeHovered?: boolean }>`
  height: 50;
  width: 100%;
  align-items: center;
  display: flex;
  border: ${props => (props.removeHovered ? '1px solid rgba(175, 41, 48, 1)' : '1px solid rgba(204, 204, 204, 0.8)')};
  border-radius: 8px;
  gap: 8px;
  padding: 0px 8px;
  background: ${props => (props.removeHovered ? 'rgba(175, 41, 48, 1)' : 'transparent')};
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const PlayPauseIcon = styled.div<{ removeHovered?: boolean }>`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background: ${props => (props.removeHovered ? 'rgba(226, 110, 116, 1)' : '#666666')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeContainer = styled.div<{ removeHovered?: boolean }>`
  border-radius: 8px;
  background: ${props => (props.removeHovered ? 'rgba(226, 110, 116, 1)' : 'rgba(204, 204, 204, 0.8)')};
  color: ${props => (props.removeHovered ? '#fff' : 'rgba(102, 102, 102, 1)')};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 24px;
`;

const formatTime = (seconds: number) => [seconds / 60, seconds % 60].map(v => `0${Math.floor(v)}`.slice(-2)).join(':');

export const SoundPlayer = ({ name }: Props) => {
  const [loadAudio] = useLoadSectionAudioMutation();
  const vehicleId = useGetVehicleId();

  const containerRef = useRef<any>(null);
  const [removeHovered, setRemoveHovered] = useState(false);

  const onRemoveHovered = () => setRemoveHovered(true);
  const onRemoveHoveredOut = () => setRemoveHovered(false);

  const { input } = useField(`${name}.audio_url`);

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 50,
    fillParent: true,
    barWidth: 1,
    barHeight: 1,
    barGap: 2,
    waveColor: removeHovered ? '#fff' : 'rgba(204, 204, 204, 1)',
    progressColor: removeHovered ? '#fff' : 'rgba(71, 151, 71, 1)',
    cursorWidth: 0,
    url: input.value,
    backend: 'WebAudio'
  });

  const handleRemove = () => {
    const [sectionName, subSectionName] = name.split('.');
    loadAudio({ sectionName, subSectionName, vehicleId, audio: null });
    input.onChange(null);
  };

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  const duration = wavesurfer?.getDuration() || 0;

  return (
    <Container removeHovered={removeHovered}>
      <PlayPauseIcon removeHovered={removeHovered} onClick={onPlayPause}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </PlayPauseIcon>
      <div style={{ flexGrow: 1 }} ref={containerRef} />
      <TimeContainer removeHovered={removeHovered}>{formatTime(isPlaying ? currentTime : duration)}</TimeContainer>
      <Tooltip title="Remove" titleStyle="uploadVehicle" isTextOverflown>
        <IconWrapper onMouseOver={onRemoveHovered} onMouseOut={onRemoveHoveredOut} onClick={handleRemove}>
          <BinIcon color={removeHovered ? '#fff' : '#999999'} />
        </IconWrapper>
      </Tooltip>
    </Container>
  );
};

const BinIcon = ({ color }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
      fill={color || '#999999'}
    />
  </svg>
);
