import React from 'react';
import { submit, reset } from 'redux-form';
import { connect } from 'react-redux';
import { SectionBlock } from '../components';
import { ManageDocumentsForm } from '../forms';

const ManageDocumentsSection = ({ initialValues, submitForm }) => (
  <SectionBlock title="Dealer License" submit={submitForm} canBeEdited={false}>
    <ManageDocumentsForm
      initialValues={(initialValues.buyer.docs || []).reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item
        }),
        {}
      )}
    />
  </SectionBlock>
);

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('manageDocumentsForm')),
  resetForm: () => dispatch(reset('manageDocumentsForm'))
});

export default connect(
  null,
  mapDispatchToProps
)(ManageDocumentsSection);
