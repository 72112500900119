import React, { Component } from 'react';
import { Button, Icon, Drawer } from '@material-ui/core';
import styled from 'styled-components';
import { PageHeader } from '../index';

const StyledDrawer = styled(Drawer)`
  > div {
    top: 81px;
    position: fixed;
    height: calc(100vh - 81px);
    width: ${props => `${props.width}px`};
    > div {
      height: 100%;
    }
  }
  .pageWrapper {
    height: 63px;
  }
`;

const StyledButton = styled(Button)`
  justify-content: flex-start;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px 15px;
  > div,
  > button {
    margin-bottom: 10px;
  }
`;

class FilterDrawer extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    // fix for React Portals
    if (this.node.contains(e.target) || e.target.matches('body > div:last-child:not(:only-child) *')) {
      console.log('MATCH');
      return;
    }
    const { handleClose } = this.props;
    handleClose();
  };

  render() {
    const { children, open, handleClose, width = 260 } = this.props;
    return (
      <StyledDrawer variant="persistent" anchor="right" open={open} width={width}>
        <div
          ref={node => {
            this.node = node;
          }}
        >
          <PageHeader>
            <StyledButton onClick={handleClose}>
              <Icon>chevron_right</Icon>
            </StyledButton>
          </PageHeader>
          <FilterWrapper>{children}</FilterWrapper>
        </div>
      </StyledDrawer>
    );
  }
}

export default FilterDrawer;
