import React from 'react';
import { ButtonBase, Icon } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { PageHeader } from '../../../../components';
import { selectedVehicleBuyerSelector } from '../../../../selectors';

const StyledBackButton = styled(ButtonBase)`
  padding-right: 10px;
  span {
    font-size: 20px;
  }
`;

const Header = ({ buyerDealershipName, history }) => (
  <PageHeader>
    <StyledBackButton onClick={history.goBack}>
      <Icon>arrow_back</Icon>
    </StyledBackButton>
    {`${buyerDealershipName ? `${buyerDealershipName} Documents` : 'Documents'}`}
  </PageHeader>
);

const mapStateToProps = state => ({
  buyerDealershipName: selectedVehicleBuyerSelector(state).dealership_name
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Header);
