import React from 'react';
import { connect } from 'react-redux';
import {
  generateSellerInvoiceFile,
} from '../../../../../actions';
import { INVOICE_TYPES } from '../../../../../constants';
import CurrentInvoiceModal from '../../CurrentInvoiceModal';
import InvoiceByUploadedVehiclesModalfrom from './InvoiceByUploadedVehiclesModal';


const Modals = ({ seller, invoiceDate, generateInvoice }) => (
  <>
    <CurrentInvoiceModal
      canAddRecord
      handleSubmit={() => {
        generateInvoice({
          seller: seller.id,
          date: invoiceDate
        });
      }}
    />
    <InvoiceByUploadedVehiclesModalfrom
      handleSubmit={() => {
        generateInvoice({
          type: INVOICE_TYPES.BY_UPLOADED_VEHICLES,
          seller: seller.id,
          date: invoiceDate
        });
      }}
    />
  </>
);

const mapStateToProps = state => ({
  seller: state.financials.currentSeller,
  invoiceDate: state.financials.currentInvoiceDate,
});

const mapDispatchToProps = dispatch => ({
  generateInvoice: data => dispatch(generateSellerInvoiceFile(data).request),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
