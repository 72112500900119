import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle, auctionVehiclesSetModal } from '../../../../../actions';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import { usePublicOfferAmount } from '../../../../../hooks';
import { PublicOfferInfo, SendOfferModal } from '../../../../../components/Offers';
import AdminTopOfferBlock from '../TopOfferBlock';


const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 8px 32px 8px 8px;
  font-weight: 500;
  padding-left: 0;
`;

const TopOfferWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  width: 100%;

  & > button {
    width: 200px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    & > button {
      width: 100%;
    }
  }
`;

const ButtonControls = styled(Grid)`
  width: 100%;
  margin-top: 12px;
  gap: 16px;

  & > button {
    flex: 1 1 100%;
  }
`;

const ControlButton = styled(Button)`
  border-radius: 6px;
  width: 94px;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const PublicOfferBlock = ({ offerAuction, vehicle }) => {
  const dispatch = useDispatch();
  const handleCounter = () => dispatch(modalsToggle(`sendVehicleOffer-${vehicle.id}`))

  return (
    <Fragment>
      <Grid container item>
        <AdminTopOfferBlock offerAuction={offerAuction} />
        <Grid item container>
          <PublicOfferInfo offerAuction={offerAuction} />
        </Grid>
        <ButtonControls container wrap="nowrap">
          <ControlButton onClick={handleCounter} color="#3994DE" textColor="#fff">
            Counter
          </ControlButton>
          {/* <ControlButton color="#225A91" textColor="#fff">
            Accept
          </ControlButton> */}
        </ButtonControls>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      </Grid>
      <SendOfferModal
        maxAmount={vehicle.reserve_price}
        form={`sendVehicleOffer-${vehicle.id}`}
        vehicle={vehicle}
      />
    </Fragment>
  );
};

export default PublicOfferBlock;
