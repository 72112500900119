import Io from 'socket.io-client';

const WEBSOCKET_URL = import.meta.env.VITE_WS_API_URL ?? '';

let socket = Io(WEBSOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 100,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ['websocket']
});

const getSocket = () => {
  if (!socket) {
    socket = Io(WEBSOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 100,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket']
    });
    socket.on('hello', data => console.log(`Hello data ${data}`));
  }

  return socket;
};

export default getSocket;
