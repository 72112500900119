export const STATISTICS_REQUEST = 'STATISTICS_REQUEST';
export const STATISTICS_SUCCESS = 'STATISTICS_SUCCESS';
export const STATISTICS_FAILURE = 'STATISTICS_FAILURE';
export const FILTER_STATISTICS_REQUEST = 'FILTER_STATISTICS_REQUEST';
export const FILTER_STATISTICS_SUCCESS = 'FILTER_STATISTICS_SUCCESS';
export const FILTER_STATISTICS_FAILURE = 'FILTER_STATISTICS_FAILURE';
export const STATISTICS_SET_PAGE = 'STATISTICS_SET_PAGE';

export const listStatistics = data => ({
  request: { type: STATISTICS_REQUEST, payload: data },
  success: { type: STATISTICS_SUCCESS, payload: data },
  failure: { type: STATISTICS_FAILURE, payload: data }
});

export const filterStatistics = data => ({
  request: { type: FILTER_STATISTICS_REQUEST, payload: data },
  success: { type: FILTER_STATISTICS_SUCCESS, payload: data },
  failure: { type: FILTER_STATISTICS_FAILURE, payload: data }
});

export const statisticsSetPage = data => ({
  type: STATISTICS_SET_PAGE,
  payload: data
});
