import { useDispatch, useSelector } from 'react-redux';
import { Vehicle } from '../../types/vehicle';
import styled from 'styled-components';
import { User } from '../../types/user';
import { getHighestBid, getMinBitAmount, getVehicleTimer } from '../../utils/vehicle';
import { NotesSection } from '../Notes';
import { VEHICLE_STATUSES } from '../../constants';
import { IncreaseHighBid } from '../../pages/Buyer/BidsList/containers';
import FormattedAmount from '../FormattedAmount';
import Timer from '../Timer';
import { InfoValue, Title, InfoWrapper } from './styled';
import { CurrentBidComponent } from '../Typography';
import { initVehicleDetailsData } from '../../actions/vehicles';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { BidInfoLabel } from '../../pages/Buyer/BidsList/components/tableBlocks';
import { ReservePriceLabel } from '../ReservePriceMet';

type Props = { vehicle: Vehicle; includeIndents?: boolean };

const Container = styled.div<{ includeIndents?: boolean }>`
  display: flex;
  width: ${({ includeIndents }) => (includeIndents ? '400px' : '357px')};

  @media (max-width: 567px) {
    width: unset;
    max-width: 450px;
    justify-content: center;
  }
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 357px;
  position: relative;

  @media (max-width: 567px) {
    width: unset;
    max-width: 450px;
  }
`;

const BorderElement = styled.div`
  width: 1px;
  height: 80%;
  background-color: #b0b0b0;
  position: absolute;
  bottom: 10%;
  right: -22px;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;

  & * {
    font-size: 10px;
  }
`;

const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

const VehicleImage = styled.div<{ imageUrl: string }>`
  background-repeat: no-repeat;
  width: 120px;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

const VehicleInfoLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 8px;
  & > div {
    flex: 1 1 100%;
  }
`;

const InfoLabel = styled.div`
  color: #757575;
  font-size: 10px;
  font-weight: 400;
`;

const ValueWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledNotesSection = styled.div`
  & .notes-container {
    @media (max-width: 400px) {
      max-width: 280px;
    }
  }
`;

const BidInfoLabelWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 998;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const VehicleSideCardMobile = ({ vehicle, includeIndents }: Props) => {
  const user: User = useSelector((state: any) => state.user?.user);

  const { vehicleId } = useParams<{ vehicleId?: string }>();

  const highestBid = getHighestBid(vehicle);
  const myBid = vehicle.my_bid;
  const vehicleTimer = getVehicleTimer(vehicle);
  const minBidAmount = getMinBitAmount(vehicle, user);

  const vehicleImageSrc = vehicle.images?.[0]?.url ?? '';
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

  const canPlaceBid = !!user && user.role === 'buyer' && vehicle.status === VEHICLE_STATUSES.ACTIVE;
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (vehicleId && Number(vehicleId) === vehicle.id) return;
    dispatch(push(`/vehicles/${vehicle.id}/view`));
    dispatch(initVehicleDetailsData(vehicle));
  };

  const VehicleValueInfo = () => (
    <VehicleInfoLabelWrapper>
      <ValueWrapper>
        <InfoLabel>Book: </InfoLabel>
        <InfoValue size={10}>
          <FormattedAmount amount={vehicle.bluebook_value} />
        </InfoValue>
      </ValueWrapper>

      <InfoLabel>/ </InfoLabel>
      <ValueWrapper>
        <InfoLabel>miles: </InfoLabel>
        <InfoValue size={10}>
          <FormattedAmount prefix="" amount={vehicle.bluebook_mileage} />
        </InfoValue>
      </ValueWrapper>
    </VehicleInfoLabelWrapper>
  );

  return (
    <Container includeIndents={includeIndents}>
      <InnerContent>
        <BidInfoLabelWrapper>
          <BidInfoLabel id={vehicle.id} item={vehicle} labelType={'left'} />
        </BidInfoLabelWrapper>
        <ContentWrapper>
          <VehicleImage imageUrl={vehicleImageSrc} onClick={handleOnClick} />
          <ContentInfoContainer>
            <Header>
              <Title>{vehicleName}</Title>
              <ReservePriceLabel size="sm" activeVehicle={vehicle} />
            </Header>
            <ContentContainer>
              <VehicleValueInfo />
              <InfoWrapper>
                <InfoLabel>Time left:</InfoLabel>
                <InfoValue size={10}>
                  <Timer toDate={vehicleTimer} />
                </InfoValue>
              </InfoWrapper>
            </ContentContainer>

            <ContentContainer>
              <InfoWrapper>
                <Title>Current bid:</Title>
                <InfoValue size={10}>
                  {myBid?.amount ? <CurrentBidComponent amount={highestBid.amount} /> : '-'}
                </InfoValue>
              </InfoWrapper>
              <InfoWrapper>
                <InfoLabel>Your bid:</InfoLabel>
                <InfoValue size={10}>{myBid?.amount ? <FormattedAmount amount={myBid.amount} /> : '-'}</InfoValue>
              </InfoWrapper>
            </ContentContainer>
            <StyledNotesSection>
              <NotesSection vehicle={vehicle} />
            </StyledNotesSection>
          </ContentInfoContainer>
        </ContentWrapper>
        {canPlaceBid && (
          <IncreaseHighBid
            variant="mobile-panel"
            form={`biddingForm_${vehicle.id}`}
            vehicleId={vehicle.id}
            minAmount={minBidAmount}
            isSidePanelBid
          />
        )}
        {includeIndents && <BorderElement />}
      </InnerContent>
    </Container>
  );
};
