import { useMapsLibrary, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef } from 'react';

export const MapRoutes = ({ encodedPolyline }: { encodedPolyline: string }) => {
  const map = useMap();
  const mapsLib = useMapsLibrary('maps');
  const geometryLib = useMapsLibrary('geometry');

  useEffect(() => {
    if (!geometryLib || !mapsLib || !map) return;
    const decoded = geometryLib?.encoding.decodePath(encodedPolyline);

    const polyline = new mapsLib.Polyline({
      path: decoded,
      geodesic: true,
      strokeColor: '#6A47A9',
      strokeOpacity: 1.0,
      strokeWeight: 4
    });

    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [geometryLib, mapsLib, map, encodedPolyline]);

  return null;
};
